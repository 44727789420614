/* Libraries */
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Components */
import Icon from "components/Icon";
/* -Components */

/* Selectors */
/* -Selectors */

/* Hooks */
/* -Hooks */

import { paths } from "routes/PageRoutes";
import styles from "./index.module.scss";

const BackNavItemSecondaryNav = props => {
  const { showHomeLink, to } = props;
  const history = useHistory();
  const doNavigation = useCallback(() => {
    if (showHomeLink) {
      history.push(paths.home());
    } else {
      if (to) {
        history.replace(to);
      } else {
        history.goBack();
      }
    }
  }, [history, showHomeLink, to]);

  return (
    <button
      onClick={doNavigation}
      className={classnames(
        "button is-ghost",
        styles.settingsNav,
        styles.backNavEdit,
        "pl-0"
      )}
    >
      <Icon className="mr-05" icon="arrow-left" size="24" />{" "}
      {showHomeLink ? "Home" : ""}
    </button>
  );
};

export default React.memo(BackNavItemSecondaryNav);
