/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {};

const setRole = (state, { payload }) => payload || INITIAL_STATE;

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.currentRoleFetched]: setRole,
  [actions.clearRole]: reset,
  [authActions.logoutInit]: reset
});
