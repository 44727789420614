/* Libraries */
import React, { useCallback, useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Actions */
import { acceptCookies } from "redux/cookie/actions";
/* -Actions */

/* Selectors */
import { getCookieAccepted } from "redux/cookie/selectors";
/* -Selectors */

/* Components */
import Banner from "../Banner";
import { CoreButton } from "components/_v2/Button";
import Icon from "components/Icon";
/* -Components */

/* Hooks */
import useBreakpoint from "hooks/useBreakpoint";
import useModals from "hooks/useModals";
/* -Hooks */

import analytics from "utils/analyticsUtils";
import { modals } from "routes/ModalRoutes";

import styles from "./index.module.scss";

const selector = createSelector(getCookieAccepted, cookieAccepted => {
  return {
    cookieAccepted,
  };
});

const CookieBanner = props => {
  const { cookieAccepted } = useSelector(selector);

  const dispatch = useDispatch();

  const [showBannerContent, setShowBannerContent] = useState(false);
  useEffect(() => {
    // avoid showing banner immediately because banners are also shown
    window.setTimeout(() => {
      setShowBannerContent(true);
    }, 200);
  }, []);

  const onAccept = useCallback(() => {
    dispatch(acceptCookies(true));
    analytics.event("Accepted Cookies");
  }, [dispatch]);

  return (
    <Banner className={styles.cookieBanner} padBody>
      {!cookieAccepted && showBannerContent && (
        <BannerContent {...{ onAccept }} />
      )}
    </Banner>
  );
};

const BannerContent = React.memo(props => {
  const { onAccept } = props;
  const { linkModal } = useModals();
  const { isMobile } = useBreakpoint();

  const onDetails = useCallback(() => {
    analytics.event("Clicked Cookies Learn More");
  }, []);

  return (
    <div className={classnames("container", styles.content)} key="cokie-banner">
      <Icon
        className={styles.icon}
        icon="cookie"
        size={isMobile ? "32" : "40"}
      />
      <div className={styles.notice}>
        <p>
          To give you the best experience Kindeo uses cookies.{" "}
          <Link
            to={linkModal(modals.privacy())}
            onClick={onDetails}
            className={styles.privacyLink}
          >
            Learn more
          </Link>
        </p>
      </div>
      <CoreButton design="tertiary" onClick={onAccept}>
        Got it
      </CoreButton>
    </div>
  );
});

export default React.memo(CookieBanner);
