/* Libraries */
// import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import {
  DEFAULT_CARD_THEME,
  getCardTheme,
  getCardThemeColours,
  getCardThemeVersion,
} from "./selectors";
import axios from "axios";
import { createThumbnail, actions as storyActions } from "redux/story/actions";
import {
  getCardSetupComplete,
  getId,
  getSlides,
  getStory,
} from "redux/story/selectors";
import { isCreditSlide } from "redux/slide/selectors";
/* -Selectors */

/* -Actions */
/* -Actions */

export const actions = {
  clearCardTheme: createAction("CARDTHEME_CLEAR"),
  setCardTheme: createAction("CARDTHEME_SET"),
  modifyCardTheme: createAction("CARDTHEME_MODIFY"),
};

export const fetchCardTheme = cardId => async dispatch => {
  return axios.get(`/story/${cardId}/theme`).then(({ data: { theme } }) => {
    dispatch(actions.setCardTheme(theme?.theme_object || DEFAULT_CARD_THEME));
  });
};

export const saveCardTheme = cardId => async (dispatch, getState) => {
  const cardTheme = getCardTheme(getState());
  const regulatedCardTheme = regulateCardTheme(cardTheme);
  const card = getStory(getState());
  cardId = cardId || getId(card);

  return axios
    .put(`/story/${cardId}/theme`, {
      theme: regulatedCardTheme,
    })
    .then(({ data: { theme } }) => {
      dispatch(actions.setCardTheme(theme.theme_object || DEFAULT_CARD_THEME));

      if (getCardSetupComplete(card)) {
        const finalSlide = getSlides(card, -1)?.[0];

        if (isCreditSlide(finalSlide)) {
          // get the new thumbnail, but don't hold up the UI for it
          // send parameter forces an update to the slide in API
          dispatch(createThumbnail(finalSlide)).then(slide => {
            dispatch(storyActions.createThumbnailSuccess(slide));
          });
        }
      }

      return Promise.resolve(theme.theme_object);
    });
};

export const setCardTheme = payload => async (dispatch, getState) => {
  return dispatch(actions.setCardTheme(regulateCardTheme(payload)));
};

export const modifyCardTheme = payload => async (dispatch, getState) => {
  const currentTheme = getCardTheme(getState());
  return dispatch(
    actions.modifyCardTheme(regulateCardTheme({ ...currentTheme, ...payload }))
  );
};

export const setCardThemeColours = payload => async dispatch => {
  return dispatch(
    actions.modifyCardTheme({
      colours: payload || getCardThemeColours(DEFAULT_CARD_THEME),
    })
  );
};

export const clearCardTheme = () => async dispatch => {
  return dispatch(actions.clearCardTheme());
};

const regulateCardTheme = cardTheme => {
  const themeColours = getCardThemeColours(cardTheme);

  return {
    schema_version:
      getCardThemeVersion(cardTheme) || getCardThemeVersion(DEFAULT_CARD_THEME),
    colours:
      themeColours?.length > 0
        ? themeColours
        : getCardThemeColours(DEFAULT_CARD_THEME),
  };
};
