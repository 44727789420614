/* Libraries */
import PropTypes from "prop-types";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
/* -Libraries */

/* Hooks */
import usePrevious from "hooks/usePrevious";
/* -Hooks */

/* Components */
/* -Components */

/**
 * One gotcha here :spaghetti:
 * I had an issue with the position of the div always starting of screen and transitioning to it's position in a very bubbly fashion.
 * (Even though I set the initial to be the final position with a bit of an offset on the "y")
 * The reason is - the `style.x`, `style.y` on mount are often VERY wrong, but by the time we get the right value, the initial values {{ x: style.x, y: style.y - 20 }} are already based on the wrong one :sad:
 * The only way I could find to force reset the framer animation (and set again the initial values) is to change manually its key!
 *
 * Added a bit of logic in the useEffect => if the new prop style.x, style.y are very different from the previous one, reset the animation, otherwise, just animate smoothly (on scroll, resize, etc.)
 */
let ind = 0;
const MotionXYElement = React.forwardRef((props, ref) => {
  const { children, className, style = {} } = props;

  const [key, setKey] = useState(ind);

  const previousStyleX = usePrevious(style.x);
  const previousStyleY = usePrevious(style.y);

  useEffect(() => {
    if (
      Math.abs(style.x - previousStyleX) > 80 ||
      Math.abs(style.y - previousStyleY) > 80
    ) {
      ind++;
      setKey(ind);
    }
  }, [previousStyleX, previousStyleY, style.x, style.y]);

  return (
    <motion.div
      ref={ref}
      key={key}
      className={classnames(className, styles.elementContainer)}
      initial={{ x: style.x, y: style.y - 20, opacity: 0 }}
      animate={{ x: style.x, y: style.y, opacity: 1 }}
    >
      <div style={{ ...style }}>{children}</div>
    </motion.div>
  );
});

MotionXYElement.propTypes = {
  style: PropTypes.object,
};

export default React.memo(MotionXYElement);
