/* Libraries */
/* -Libraries */

export const noop = () => {};

export const shouldUseMp4Playback = () => {
  // See comments in VideoStreamLoader for MP4 choices
  const { IS_IOS, IS_SAFARI } = getBrowserDetails();
  return IS_IOS || IS_SAFARI;
};

export const getBrowserDetails = () => {
  const ua = navigator.userAgent || navigator.vendor;

  const IS_ANDROID = /Android/i.test(ua);
  const IS_FIREFOX = /Firefox/i.test(ua);
  const IS_EDGE = /Edg/i.test(ua);
  const IS_CHROME = !IS_EDGE && (/Chrome/i.test(ua) || /CriOS/i.test(ua));
  const IS_SAFARI = /Safari/i.test(ua) && !IS_CHROME && !IS_ANDROID && !IS_EDGE;
  const TOUCH_ENABLED = Boolean(
    document === window.document &&
      ("ontouchstart" in window ||
        window.navigator.maxTouchPoints ||
        (window.DocumentTouch &&
          window.document instanceof window.DocumentTouch))
  );
  const IS_IPAD =
    /iPad/i.test(ua) || (IS_SAFARI && TOUCH_ENABLED && !/iPhone/i.test(ua));
  const IS_KINDEO_BOT = /KINDEO-BOT/i.test(ua);
  const IS_IPHONE = /iPhone/i.test(ua) && !IS_IPAD;
  const IS_IOS = IS_IPHONE || IS_IPAD;
  const IOS_VERSION = (function () {
    const match =
      ua.match(/OS (\d+)_/i) || ua.match(/Version\/(\d+)\.(\d+)\.(\d+)/i);
    return match && match[1] ? parseInt(match[1], 10) : null;
  })();

  return {
    IS_ANDROID,
    IS_FIREFOX,
    IS_EDGE,
    IS_CHROME,
    IS_KINDEO_BOT,
    IS_SAFARI,
    IS_IPAD,
    IS_IPHONE,
    IS_IOS,
    IOS_VERSION,
    TOUCH_ENABLED,
  };
};
