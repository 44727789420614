/* Libraries */
import { useCallback, useEffect, useRef, useState } from "react";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Hooks */
import useIsMounted from "./useIsMounted";
import usePrevious from "hooks/usePrevious";
import useTabFocus from "./useTabFocus";
/* -Hooks */

import { noop } from "utils/clientUtils";

const usePolling = (
  doPolling,
  pollEndpoint,
  onSuccess = noop,
  onFailure = noop,
  pollFrequencyMs = 5000
) => {
  // latestFetch is used to trigger a re-check
  const [latestFetch, setLatestFetch] = useState();

  const isMounted = useIsMounted();
  const tabIsFocused = useTabFocus();
  const tabIsFocusedPrev = usePrevious(tabIsFocused);

  const pollTimeout = useRef();

  // run the data check
  const checkAndFetch = useCallback(() => {
    if (doPolling && tabIsFocused) {
      pollEndpoint()
        .then(onSuccess)
        .catch(onFailure)
        .finally(() => {
          if (isMounted()) {
            setLatestFetch(Date.now());
          }
        });
    }
  }, [doPolling, isMounted, onFailure, onSuccess, pollEndpoint, tabIsFocused]);

  // when the tab receives focus, check immediately
  // otherwise schedule a check for updates
  useEffect(() => {
    window.clearTimeout(pollTimeout.current);
    if (tabIsFocused && !tabIsFocusedPrev) {
      checkAndFetch();
    } else {
      pollTimeout.current = window.setTimeout(checkAndFetch, pollFrequencyMs);
    }
    return () => {
      window.clearTimeout(pollTimeout.current);
    };
  }, [
    checkAndFetch,
    latestFetch,
    pollFrequencyMs,
    tabIsFocused,
    tabIsFocusedPrev,
  ]);
};

export default usePolling;
