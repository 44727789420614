/* Libraries */
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
/* -Libraries */

// Attach a `resize` event listener to window object
// and return and function to remove it on unmount
// pass an optional dependencyArray to update the cached callback function
export default function useOnResize(
  onResize,
  ms = 200,
  options = {},
  targetElement = window
) {
  const callback = useDebouncedCallback(
    e => {
      if (e?.target === targetElement || !e) {
        onResize(e);
      }
    },
    ms,
    options
  );

  useEffect(() => {
    callback();
    targetElement.addEventListener("resize", callback, {
      capture: true,
      passive: true,
    });

    // return the method to clean up
    return () => {
      targetElement.removeEventListener("resize", callback, {
        capture: true,
        passive: true,
      });
    };
  }, [callback, ms, targetElement]);
}
