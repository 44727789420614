export const PROMPTS_IDS = {
  // creator
  COVER_PHOTO: "cover_photo",
  INVITE_PEOPLE: "invite_people",
  SEND_KINDEO: "send_kindeo",
  PLAY_KINDEO: "play_kindeo",
  INVITE_SPLASH: "invite_splash",
  // contributors only
  ADD_CONTRIBUTIONS: "contributor_add",
  VIEW_GROUP: "contributor_view_group",
  // monetisation
  PAID_SERVICE_NOTICE: "paid_service_notice",
  PRICING_UPDATE_NOTICE: "pricing_update",
  PRICING_BUNDLE_NOTICE: "pricing_bundles",
};
// prompt priority is ascending numerical
// i.e. priority 1 is more important than and appears before priority 10
export const PROMPTS = {
  // creator
  [PROMPTS_IDS.COVER_PHOTO]: {
    id: PROMPTS_IDS.COVER_PHOTO,
    priority: 100,
  },
  [PROMPTS_IDS.PLAY_KINDEO]: {
    id: PROMPTS_IDS.PLAY_KINDEO,
    priority: 120,
  },
  [PROMPTS_IDS.INVITE_PEOPLE]: {
    id: PROMPTS_IDS.INVITE_PEOPLE,
    priority: 200,
  },
  [PROMPTS_IDS.SEND_KINDEO]: {
    id: PROMPTS_IDS.SEND_KINDEO,
    priority: 220,
  },
  [PROMPTS_IDS.INVITE_SPLASH]: {
    id: PROMPTS_IDS.INVITE_SPLASH,
    priority: 200,
  },
  // contributor
  [PROMPTS_IDS.ADD_CONTRIBUTIONS]: {
    id: PROMPTS_IDS.ADD_CONTRIBUTIONS,
    priority: 170,
  },
  [PROMPTS_IDS.VIEW_GROUP]: {
    id: PROMPTS_IDS.VIEW_GROUP,
    priority: 180,
  },
  // monetisation
  [PROMPTS_IDS.PAID_SERVICE_NOTICE]: {
    id: PROMPTS_IDS.PAID_SERVICE_NOTICE,
    priority: 90,
  },
  [PROMPTS_IDS.PRICING_UPDATE_NOTICE]: {
    id: PROMPTS_IDS.PRICING_UPDATE_NOTICE,
    priority: 200,
  },
  [PROMPTS_IDS.PRICING_BUNDLE_NOTICE]: {
    id: PROMPTS_IDS.PRICING_BUNDLE_NOTICE,
    priority: 300,
  },
};

export const getPrompts = state => state.prompts.prompts || [];
export const getCurrentPrompt = state => {
  return state.prompts.currentPrompt || "";
};

export const getPromptsQueue = state => {
  return state.prompts.queue || [];
};
