/* Libraries */
import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
/* -Libraries */

/* Selectors */
import * as loaderSelectors from "redux/loaders/selectors";
/* -Selectors */

const LoaderDroplet = lazy(() => import("components/LoaderDroplet"));
const LoaderEnvelope = lazy(() => import("components/LoaderEnvelope"));
const LoaderComets = lazy(() => import("components/LoaderComets"));

const selector = loaderSelectors.getAnimation;

const Loader = props => {
  const {
    className,
    loaded,
    size = "medium",
    visible = true,
    animation: propAnimation,
  } = props;
  const selectorAnimation = useSelector(selector);

  const animation = propAnimation || selectorAnimation;

  let Element;
  switch (animation) {
    case loaderSelectors.LOADER_ANIMATIONS.COMETS:
      Element = LoaderComets;
      break;
    case loaderSelectors.LOADER_ANIMATIONS.ENVELOPE:
      Element = LoaderEnvelope;
      break;
    case loaderSelectors.LOADER_ANIMATIONS.DROPLET:
    default:
      Element = LoaderDroplet;
  }

  return (
    <Suspense fallback={<div></div>}>
      <Element {...{ className, loaded, size, visible }} />
    </Suspense>
  );
};

Loader.propTypes = {
  animation: PropTypes.oneOf(Object.values(loaderSelectors.LOADER_ANIMATIONS)),
  /** Displays a loaded animation instead of spinner. */
  loaded: PropTypes.bool,
  /** Spinner size */
  size: PropTypes.oneOf(["medium", "large"]),
  /** Show or hide the loader. */
  visible: PropTypes.bool,
};

export default React.memo(Loader);
