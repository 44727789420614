/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
/* -Selectors */

export const actions = {
  setProgress: createAction("PROGRESS_SET"),
  setComplete: createAction("PROGRESS_RESET")
};

export const setProgress = (id, progress) => dispatch => {
  dispatch(
    actions.setProgress({
      id,
      progress
    })
  );
};

export const setComplete = id => dispatch => {
  dispatch(
    actions.setComplete({
      id
    })
  );
};
