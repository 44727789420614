/* Libraries */
import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
/* -Libraries */

/* Components */
import EmojiPicker from "components/EmojiPicker";
import IconButton from "components/IconButton";
/* -Components */

/* Hooks */
import useConditionalRef from "hooks/useConditionalRef";
/* -Hooks */

import styles from "./index.module.scss";

const FormFieldEmoji = React.forwardRef((props, inputRef) => {
  const { children, name, onChange } = props;

  const [wrapperRef, setWrapperRef] = useConditionalRef(null);
  const pickerRef = useRef();
  const buttonRef = useRef();
  const formTools = useFormContext();
  const { setValue } = formTools || {};

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const getValueWithEmoji = useCallback(
    (emoji, start, end) => {
      const currentValue = inputRef.current.value;
      const beforeEmoji = currentValue.slice(0, start);
      const afterEmoji = currentValue.slice(end);

      return beforeEmoji + emoji + afterEmoji;
    },
    [inputRef]
  );
  const setInputValue = useCallback(
    value => {
      setValue ? setValue(name, value) : (inputRef.current.value = value);
    },
    [inputRef, name, setValue]
  );

  const onEmojiPicked = useCallback(
    ({ native }) => {
      // get text selection size in case we are replacing text
      const selectionStart = inputRef.current.selectionStart;
      const selectionEnd = inputRef.current.selectionEnd;
      setInputValue(getValueWithEmoji(native, selectionStart, selectionEnd));

      // I hate this, you hate this, but the onChange requires an object with a target
      // like a change event from an input, so we have to fake it
      onChange({ target: inputRef.current });
      // set the cursor position in the input in case we have replaced a selection
      inputRef.current.setSelectionRange(
        selectionStart + native.length,
        selectionStart + native.length
      );
      inputRef.current.focus();
    },
    [getValueWithEmoji, inputRef, onChange, setInputValue]
  );

  const togglePicker = useCallback(() => {
    setShowEmojiPicker(current => !current);
  }, []);

  const closePicker = useCallback(
    e => {
      if (showEmojiPicker) {
        if (!wrapperRef.contains(e.target)) {
          setShowEmojiPicker(false);
        }
      }
    },
    [showEmojiPicker, wrapperRef]
  );

  if (name) {
    return (
      <>
        <div className={styles.formFieldEmoji} ref={setWrapperRef}>
          {children}
          <IconButton
            className={styles.button}
            icon="emoji"
            iconSize="22"
            ref={buttonRef}
            onClick={togglePicker}
          />
        </div>
        <EmojiPicker
          onPicked={onEmojiPicked}
          onBlur={closePicker}
          ref={pickerRef}
          targetRef={wrapperRef}
          isOpen={showEmojiPicker}
        />
      </>
    );
  }

  return null;
});

FormFieldEmoji.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default React.memo(FormFieldEmoji);
