/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions, setAuthHeader } from "./actions";
import { actions as accountActions } from "redux/account/actions";
/* -Actions */

/* Selectors */
import { authTokenTypes } from "./selectors";
/* -Selectors */

const INITIAL_STATE = {
  contextId: null,
};

const updateAuth = (state, { payload }) => {
  const { [authTokenTypes.accessToken]: accessToken } = payload;
  if (accessToken) {
    setAuthHeader(accessToken);
    return { ...state, ...payload };
  }
};

const setContextId = (state, { payload }) => {
  return { ...state, contextId: payload };
};

const setUsername = (state, { payload }) => {
  // also reset contextId - should be cleared when we have authed
  return { ...state, contextId: null, username: payload };
};

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [accountActions.profileUpdateSuccess]: updateAuth,
  [actions.loginSuccess]: updateAuth,
  [actions.registerSuccess]: updateAuth,
  [actions.renewSuccess]: updateAuth,
  [actions.setContextId]: setContextId,
  [actions.setUsername]: setUsername,
  [actions.logoutInit]: reset,
});
