import useInterval from "hooks/useInterval";
import useIsMounted from "hooks/useIsMounted";
import { useCallback, useEffect, useState } from "react";

const useScrubber = ({ duration, playing, videoEl } = {}) => {
  const isMounted = useIsMounted();

  // get initial value and update it when new play state comes in
  const [time, timeSet] = useState(0);
  const [displayTime, displayTimeSet] = useState(time);
  useEffect(() => {
    displayTimeSet(time);
  }, [time]);

  const [isScrubbing, isScrubbingSet] = useState(false);

  // update the scrub display while scrubbing
  const onScrubChange = useCallback(
    value => {
      displayTimeSet(value);
      videoEl.currentTime = value;
    },
    [videoEl]
  );

  // when scrubbing starts, prevent track from playing
  const onScrubStart = useCallback(
    value => {
      isScrubbingSet(true);
      onScrubChange(value);
    },
    [onScrubChange]
  );
  // when scrubbing stops, allow
  const onScrubEnd = useCallback(
    value => {
      timeSet(value);
      isScrubbingSet(false);
    },
    [timeSet]
  );

  useInterval(
    () => {
      if (isMounted() && !!videoEl) {
        displayTimeSet(videoEl.currentTime);
      }
    },
    playing && !isScrubbing ? 100 : null
  );

  return {
    min: 0,
    max: duration,
    onScrubChange,
    onScrubEnd,
    onScrubStart,
    displayTime,
    scrubbing: isScrubbing,
  };
};

export default useScrubber;
