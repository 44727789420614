/* Libraries */
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import CoreButton from "../CoreButton";
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

export const TextButton = React.memo(
  ({
    children,
    className,
    color,
    danger,
    primary,
    text,
    underlined = true,
    ...restProps
  }) => {
    color = color || (danger ? "red5" : primary ? "primary" : "secondary");

    return (
      <CoreButton
        design="text"
        {...restProps}
        className={classnames(
          `has-text-${color}`,
          underlined && styles.underlined,
          className
        )}
      >
        {text}
        {children}
      </CoreButton>
    );
  }
);

TextButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colour: PropTypes.string,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  text: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string }),
  ]),
  underlined: PropTypes.bool,
};
