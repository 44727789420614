import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";

import { mediaTypes } from "redux/media/selectors";
import thumbnail from "images/slides/thumbnails/photos.png";

const type = slideTypes.PHOTOS;
const photoVariants = slideVariants[type];

export default {
  type,
  icon: "photo-fill",
  label: "Photo",
  descriptionBuilder: "Add a picture-perfect message",
  colour: "pink5",
  thumbnail,
  variants: [
    {
      name: photoVariants.SIMPLE,
      label: "Simple",
      default: false, //default: true sets this specific variant by default
    },
    {
      name: photoVariants.FULLSCREEN,
      label: "Fullscreen",
      default: false,
    },
    {
      name: photoVariants.POLAROIDS,
      label: "Snaps",
    },
    {
      name: photoVariants.HOVER,
      label: "Hover",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Message",
      placeholder: "e.g. Our holiday",
      validation: {
        maxLength: {
          value: 60,
          message: `Please keep your message to 60 characters or less`,
        },
      },
    },
    {
      key: "subtitle",
      type: inputTypes.INPUT,
      label: "From",
      placeholder: "e.g. From your best friend",
      validation: {
        maxLength: {
          value: 30,
          message: `Please keep this to 30 characters or less`,
        },
      },
    },
    {
      key: "photos",
      type: inputTypes.MEDIA,
      label: "Photos",
      icon: "camera",
      mediaType: mediaTypes.PHOTO,
      validation: {
        minLength: {
          value: 1,
          message: `Please choose at least one photo`,
        },
      },
    },
  ],
};
