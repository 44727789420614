/* Libraries */
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import { Badge } from "../Badge";
/* -Components */

import styles from "./index.module.scss";

export const BadgeText = React.memo(props => {
  const { alignLeft, className, icon, size = "small", text, type } = props;
  const color =
    type === "success" ? "green5" : type === "error" ? "red5" : props.color;

  return (
    <div
      className={classnames(styles.message, className, {
        [styles.alignLeft]: alignLeft,
      })}
    >
      <Badge {...{ color, icon, size, type }} className={"mr-05"} />{" "}
      <div className={`has-text-${color}`}>{text}</div>
    </div>
  );
});

BadgeText.propTypes = {
  alignLeft: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.oneOf(["error", "success"]),
};
