/* Libraries */
import React, { useCallback } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
import Icon from "components/Icon";
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";
import { noop } from "utils/clientUtils";

const CoreButton = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    design = "primary",
    icon,
    iconClassName,
    iconPosition = "left",
    iconSize,
    isActive,
    loading,
    onClick = noop,
    size = "medium",
    underline,
    ...restProps
  } = props;
  const { href, to } = restProps;

  const Element = href ? "a" : to ? Link : "button";

  const handleClick = useCallback(
    e => {
      if (loading) {
        e.preventDefault();
        return;
      }
      onClick(e);
    },
    [loading, onClick]
  );

  return (
    <Element
      type="button"
      {...restProps}
      onClick={handleClick}
      className={classnames(styles.button, className, `type-${design}`, {
        "is-loading": loading,
        "is-small": size === "small",
        "is-large": size === "large",
        noUnderline: underline === false,
      })}
      ref={ref}
    >
      {iconPosition === "left" && (
        <ButtonIcon
          {...{ icon, iconClassName, iconPosition, iconSize, size }}
        />
      )}
      {children}
      {iconPosition === "right" && (
        <ButtonIcon
          {...{ icon, iconClassName, iconPosition, iconSize, size }}
        />
      )}
    </Element>
  );
});

const ButtonIcon = React.memo(props => {
  const { icon, iconClassName, iconPosition, iconSize, size } = props;

  if (icon) {
    return (
      <Icon
        icon={icon}
        size={iconSize ? iconSize : size === "small" ? 20 : 24}
        className={classnames(
          styles.icon,
          {
            "ml--025": iconPosition === "left" && React.Children.count > 0,
            "mr--025": iconPosition === "right" && React.Children.count > 0,
          },
          iconClassName
        )}
      />
    );
  }

  return null;
});

CoreButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string }),
  ]),
  design: PropTypes.oneOf([
    "primary",
    "primary-purple",
    "primary-work",
    "secondary",
    "secondary-work",
    "tertiary",
    "ghost",
    "dark",
    "danger",
    "text",
    "text-danger",
    "warning",
  ]),
};

export default React.memo(CoreButton);
