/* Libraries */
import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
/* -Libraries */

/* Selectors */
import { PAYMENT_TYPES } from "./selectors";
/* -Selectors */

/* Actions */
/* -Actions */

export const actions = {
  clearPaymentDetails: createAction("PAYMENT_DETAILS_CLEAR"),
  setClientSecret: createAction("STRIPE_CLIENTSECRET_SUCCESS"),
  setPaywallDisplay: createAction("PAYMENT_WALL_SET"),
  setPaymentDetails: createAction("PAYMENT_DETAILS_SET"),
  setPaymentSettings: createAction("PAYMENT_OPTIONS_SUCCESS"),
  setUpgradeOptions: createAction("PAYMENT_UPGRADEPRODUCTS_SET"),
  clearUpgradeOptions: createAction("PAYMENT_UPGRADEPRODUCTS_CLEAR"),
};

export const initiateProductCheckoutSession =
  (storyId, productTier, sendMethod) => dispatch => {
    return axios
      .post(`/payments`, {
        product_tier: productTier,
        payment_type: PAYMENT_TYPES.CAPACITY,
        send_method: sendMethod,
        story_id: storyId,
      })
      .then(response => {
        const { client_secret } = response.data;
        dispatch(actions.setClientSecret(client_secret));
        return client_secret;
      });
  };

export const redeemBundleCredit =
  (storyId, productTier, creditType) => dispatch => {
    return axios.post(`/payments`, {
      payment_type: PAYMENT_TYPES.CAPACITY,
      product_tier: productTier,
      story_id: storyId,
      use_credit_type: creditType,
    });
  };

export const initiateBundleCheckoutSession =
  (bundleType, quantity) => dispatch => {
    return axios
      .post(`/payments`, {
        bundle_type: bundleType,
        payment_type: PAYMENT_TYPES.BUNDLE,
        bundle_quantity: quantity,
      })
      .then(response => {
        const { client_secret } = response.data;
        dispatch(actions.setClientSecret(client_secret));
        return client_secret;
      });
  };

export const getPaymentSettings = () => dispatch => {
  return axios.get(`/locale/products`).then(response => {
    // response.data is of the form { digital, postal }
    const products = response.data;
    dispatch(actions.setPaymentSettings({ products }));
    return products;
  });
};

export const getUpgradeOptions = storyId => dispatch => {
  return axios.get(`/locale/products?story_id=${storyId}`).then(response => {
    // response.data is of the form { digital, postal }
    const products = response.data;
    dispatch(actions.setUpgradeOptions({ products }));
    return products;
  });
};
export const clearUpgradeOptions = () => dispatch => {
  dispatch(actions.clearUpgradeOptions());
};

export const setPaymentDetails = (upgradeTier, creditType) => dispatch => {
  dispatch(actions.setPaymentDetails({ upgradeTier, creditType }));
};

export const setCreditType = creditType => dispatch => {
  dispatch(actions.setCreditType(creditType));
};

export const clearPaymentDetails = () => dispatch => {
  dispatch(actions.clearPaymentDetails());
};
export const hidePaywallNotification = () => dispatch => {
  dispatch(actions.setPaywallDisplay(false));
};
export const showPaywallNotification = returnPath => dispatch => {
  dispatch(actions.setPaywallDisplay(returnPath || true));
};

export const fetchPaymentStatus = stripeSessionId => dispatch => {
  return axios.get(`/payments/status/${stripeSessionId}`).then(response => {
    const { payment_status } = response.data;
    return { success: payment_status === "succeeded", ...response.data };
  });
};
