import { noop } from "../../../../utils/clientUtils";
export const defaultProps = {
  name: null,
  /** DOM element that will hold the PIXI canvas  */
  element: null,
  /** Preload the first slide before playback begins. */
  preload: true,
  /** Loop playback and start again when the last slide is complete. */
  loop: false,
  /** Start playback immediately. */
  autoplay: false,
  /** Interactive slideshows will display read more/continue watching buttons. */
  interactive: true,
  /** Quality value that individual slides can use to load optimised assets. */
  quality: "high", // 'low', 'medium', 'high'
  /** Number of slides either side of the current playing slide that should be loaded. */
  loadViewThreshold: 2,
  /** Callback fired when playback starts. */
  onPlay: noop,
  /** Callback fired when playback is paused. */
  onPause: noop,
  /** Callback fired when playback ends/is complete. */
  onEnd: noop,
  /** Callback fired when next slide is requested. */
  onNext: noop,
  /** Callback fired when previous slide is requested. */
  onPrevious: noop,
  /** Callback fired when a slide is displayed. */
  onSlide: noop,
  /** Callback fired when slide.showDetail is called, this should be when an interactive element is pressed. */
  onShowDetail: noop,
};
