export const themes = [
  {
    name: "xmas-1",
    text: { color: "0x42324e" },
    background: {
      color: "0xfdf7ff",
      properties: {
        type: "Dots",
        alphaMin: 0.09782608695652174,
        alphaGlobal: 0.29347826086956524,
        scaleValue: 1.141304347826087,
        alphaValue: 0.8,
        radius: 0.01,
        columnWidth: 39.34782608695652,
        color: [0.792156862745098, 0.5294117647058824, 0.8117647058823529],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 171, g: 109, b: 176 },
          { r: 96, g: 48, b: 99 },
        ],
        colorsBackLayer: [
          { r: 89, g: 37, b: 94 },
          {
            r: 105.12863708496094,
            g: 45.34031982421874,
            b: 108.27539062499999,
          },
        ],
        colorsMidLayer: [
          { r: 116.39270894752372, g: 41.24326171875, b: 117.83789062500001 },
          { r: 65, g: 24, b: 65 },
        ],
        colorsFrontLayer: [
          { r: 124, g: 75, b: 128 },
          { r: 59, g: 24, b: 60 },
        ],
        shadowOffset: 0.17,
        shadowOpacity: 0.16999999999999998,
        deformationFrequency: 1.2,
        deformationAmplitude: 1.5999999999999999,
        deformationSpeed: 0.6,
        effect: {},
      },
      scene: {
        type: "ChristmasScene",
        colors: { back: 16111607, middle: 16639487, front: 16639487 },
      },
    },
  },
  {
    name: "xmas-2",
    text: { color: "0x42324e" },
    background: {
      color: "0xf3ffff",
      properties: {
        type: "Dots",
        alphaMin: 0.09782608695652174,
        alphaGlobal: 0.29347826086956524,
        scaleValue: 1.141304347826087,
        alphaValue: 0.8,
        radius: 0.01,
        columnWidth: 31.521739130434785,
        color: [0.16470588235294117, 0.3137254901960784, 0.3176470588235294],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 100.52391357421875, g: 161.5780480957031, b: 162.462890625 },
          { r: 58, g: 103, b: 105 },
        ],
        colorsBackLayer: [
          { r: 47, g: 87, b: 87 },
          { r: 37, g: 75, b: 78 },
        ],
        colorsMidLayer: [
          { r: 65, g: 121, b: 118 },
          { r: 47, g: 92, b: 95 },
        ],
        colorsFrontLayer: [
          { r: 56, g: 105, b: 105 },
          { r: 42, g: 80, b: 81 },
        ],
        shadowOffset: 0.11,
        shadowOpacity: 0.24,
        deformationFrequency: 1.3,
        deformationAmplitude: 2,
        deformationSpeed: 0.7000000000000001,
        effect: {},
      },
      scene: {
        type: "ChristmasScene",
        colors: { back: 12770267, middle: 14215657, front: 16777215 },
      },
    },
  },
  {
    name: "xmas-3",
    text: { color: "0x4f6f91" },
    background: {
      color: "0xe4eef8",
      properties: {
        type: "Squares",
        alpha: 0.06521739130434782,
        size: { x: 0.2, y: 0.2 },
        columnWidth: 100,
        color: [0.16862745098039217, 0.37254901960784315, 0.5882352941176471],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 165.75, g: 208.93548387096772, b: 255 },
          { r: 53.442919921875, g: 121.81842041015625, b: 194.337890625 },
        ],
        colorsBackLayer: [
          { r: 90.72172851562502, g: 150.23259499289782, b: 213.462890625 },
          { r: 77.53905029296877, g: 141.76631174395155, b: 210.275390625 },
        ],
        colorsMidLayer: [
          { r: 131, g: 191, b: 255 },
          { r: 73.51918945312498, g: 147.40323840725796, b: 226.212890625 },
        ],
        colorsFrontLayer: [
          { r: 130.6875, g: 190.70187922297305, b: 255 },
          { r: 102.346142578125, g: 161.36590221774188, b: 224.3203125 },
        ],
        shadowOffset: 0.07,
        shadowOpacity: 0.12999999999999998,
        deformationFrequency: 1.3,
        deformationAmplitude: 2,
        deformationSpeed: 0.7000000000000001,
        effect: {},
      },
      scene: {
        type: "ChristmasScene",
        colors: { back: 13690622, middle: 15069695, front: 16120575 },
      },
    },
  },
  {
    name: "xmas-4",
    text: { color: "0x0b1248" },
    background: {
      color: "0xffffff",
      properties: {
        type: "Dots",
        alphaMin: 0,
        alphaGlobal: 0.3695652173913043,
        scaleValue: 1.532608695652174,
        alphaValue: 0.8,
        radius: 0.01,
        columnWidth: 28.58695652173913,
        color: [0.36470588235294116, 0.5450980392156862, 0.9254901960784314],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 34, g: 71, b: 150 },
          { r: 1, g: 28, b: 64 },
        ],
        colorsBackLayer: [
          { r: 1, g: 28, b: 64 },
          { r: 1, g: 17, b: 38 },
        ],
        colorsMidLayer: [
          { r: 25, g: 64, b: 115 },
          { r: 1, g: 28, b: 64 },
        ],
        colorsFrontLayer: [
          { r: 100.555, g: 46.152, b: 150 },
          { r: 75.17021484375002, g: 109.03811383928569, b: 154.1953125 },
        ],
        shadowOffset: 0.1,
        shadowOpacity: 0.35,
        deformationFrequency: 1.5,
        deformationAmplitude: 2,
        deformationSpeed: 1,
        effect: { type: "BorealEffect" },
      },
      scene: {
        type: "ChristmasScene",
        colors: { back: 14803958, middle: 15527679, front: 16777215 },
      },
    },
  },
  {
    name: "xmas-5",
    text: { color: "0xffffff" },
    background: {
      color: "0x10162f",
      properties: {
        type: "Dots",
        alphaMin: 0,
        alphaGlobal: 0.3695652173913043,
        scaleValue: 1.532608695652174,
        alphaValue: 0.8,
        radius: 0.01,
        columnWidth: 28.58695652173913,
        color: [0.36470588235294116, 0.5450980392156862, 0.9254901960784314],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 34, g: 71, b: 150 },
          { r: 1, g: 28, b: 64 },
        ],
        colorsBackLayer: [
          { r: 1, g: 28, b: 64 },
          { r: 1, g: 17, b: 38 },
        ],
        colorsMidLayer: [
          { r: 25, g: 64, b: 115 },
          { r: 1, g: 28, b: 64 },
        ],
        colorsFrontLayer: [
          { r: 34, g: 120.51, b: 146.949 },
          { r: 18, g: 24, b: 51 },
        ],
        shadowOffset: 0.1,
        shadowOpacity: 0.35,
        deformationFrequency: 1.5,
        deformationAmplitude: 2,
        deformationSpeed: 1,
        effect: { type: "BorealEffect" },
      },
      scene: {
        type: "ChristmasScene",
        colors: { back: 14803958, middle: 15527679, front: 16777215 },
      },
    },
  },
  {
    name: "xmas-6",
    text: { color: "0xfdfdff" },
    background: {
      color: "0xbc3624",
      properties: {
        type: "Dots",
        alphaMin: 0.44565217391304346,
        alphaGlobal: 1,
        scaleValue: 1.532608695652174,
        alphaValue: 0.3304347826086957,
        radius: 0.02076086956521739,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 15922683,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 247.0500950168919, g: 247.03125, b: 255 },
          { r: 248.625, g: 251.35714285714283, b: 255 },
        ],
        colorsBackLayer: [
          { r: 226.3125, g: 238.60714285714283, b: 255 },
          { r: 255, g: 255, b: 255 },
        ],
        colorsMidLayer: [
          { r: 237.648046875, g: 241.62302628800674, b: 251.8125 },
          { r: 255, g: 255, b: 255 },
        ],
        colorsFrontLayer: [
          { r: 226.3125, g: 247.120629222973, b: 255 },
          { r: 224.71875, g: 237.69642857142856, b: 255 },
        ],
        shadowOffset: 3.469446951953614e-18,
        shadowOpacity: 0,
        deformationFrequency: 3,
        deformationAmplitude: 2.8000000000000003,
        deformationSpeed: 1.7,
        effect: {},
      },
      scene: {
        type: "ChristmasScene",
        colors: { back: 16777215, middle: 15067647, front: 15398911 },
      },
    },
  },
  {
    name: "xmas-7",
    text: { color: "0x42324e" },
    background: {
      color: "0xffffff",
      properties: {
        type: "Dots",
        alphaMin: 0.09782608695652174,
        alphaGlobal: 0.29347826086956524,
        scaleValue: 1.141304347826087,
        alphaValue: 0.8,
        radius: 0.01,
        columnWidth: 39.34782608695652,
        color: [0.792156862745098, 0.5294117647058824, 0.8117647058823529],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 251.22525069962686, g: 243.943359375, b: 251.8125 },
          { r: 255, g: 255, b: 255 },
        ],
        colorsBackLayer: [
          { r: 248.625, g: 248.625, b: 248.625 },
          {
            r: 200.45390625000002,
            g: 223.70947819890208,
            b: 248.62500000000003,
          },
        ],
        colorsMidLayer: [
          { r: 217.82578125, g: 243.43502850506758, b: 245.4375 },
          { r: 207.42656250000002, g: 228.46180109797297, b: 242.25 },
        ],
        colorsFrontLayer: [
          { r: 255, g: 255, b: 255 },
          { r: 255, g: 255, b: 255 },
        ],
        shadowOffset: 3.469446951953614e-18,
        shadowOpacity: 0.16999999999999998,
        deformationFrequency: 0,
        deformationAmplitude: 4,
        deformationSpeed: 2.9000000000000004,
        effect: {},
      },
      scene: {
        type: "ChristmasScene",
        colors: { back: 12772341, middle: 14939381, front: 16777215 },
      },
    },
  },
];
