export const themesText = [
  {
    name: "flowers-text-0",
    background: {
      colors: [
        [0.9803921568627451, 0.9411764705882353, 0.9058823529411765],
        [0.9921568627450981, 0.5764705882352941, 0.5568627450980392],
      ],
    },
    font: "Delius Unicase:700",
    scene: {
      name: "ParticlesText",
      shadow: { alpha: 0.45 },
      particles: {
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
          ],
          nb: 40000,
          nbMobile: 40000,
          scale: 0.8400000000000001,
          scaleMobile: 0.39999999999999997,
          scaleDots: 0.39999999999999997,
          dotsColors: [
            [249.22265625, 204.07648426777595, 54.517456054687486],
            [246.03515625, 154.04211260821378, 12.301757812500023],
            [255, 214.27083333333334, 151.40625],
            [255, 242.06105025773198, 199.21875],
          ],
        },
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
            "blue-flower-4.png",
            "blue-flower-5.png",
            "blue-flower-3.png",
          ],
          nb: 110,
          nbMobile: 100,
          scale: 0.8400000000000001,
          scaleMobile: 0.52,
          scaleDots: 1,
          dotsColors: [
            [255, 255, 255],
            [252, 248, 248],
            [255, 255, 255],
            [255, 255, 255],
          ],
        },
      },
    },
  },
  {
    name: "flowers-text-1",
    background: {
      colors: [
        [0.9254901960784314, 0.9529411764705882, 0.9803921568627451],
        [0.7137254901960784, 0.9490196078431372, 0.9921568627450981],
      ],
    },
    font: "Delius Unicase:700",
    scene: {
      name: "ParticlesText",
      shadow: { alpha: 0.29 },
      particles: {
        particles: {
          assets: [
            "blue-flower-8.png",
            "blue-flower-9.png",
            "blue-flower-10.png",
            "blue-flower-11.png",
            "blue-flower-12.png",
            "blue-flower-14.png",
          ],
          nb: 30000,
          nbMobile: 40000,
          scale: 1,
          scaleMobile: 0.52,
          scaleDots: 0.29999999999999993,
          dotsColors: [
            [65.96630859375, 132.37833548880909, 175.91015625],
            [159.6486486486486, 65.99999999999999, 176],
            [66, 132, 176],
            [66, 132, 176],
          ],
        },
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "blue-flower-5.png",
            "blue-flower-3.png",
            "blue-flower-2.png",
            "blue-flower-1.png",
            "blue-flower-13.png",
            "blue-flower-14.png",
          ],
          nb: 400,
          nbMobile: 100,
          scale: 0.63,
          scaleMobile: 1.1600000000000001,
          scaleDots: 1,
          dotsColors: [
            [138.1196044921875, 203.57463684082032, 242.84765625],
            [208.7202392578125, 225.35066476004465, 235.177734375],
            [138, 204, 243],
            [138, 204, 243],
          ],
        },
      },
    },
  },
  {
    name: "flowers-text-2",
    background: {
      colors: [
        [0.2235294117647059, 0.3568627450980392, 0.3803921568627451],
        [0.06274509803921569, 0.06666666666666667, 0.06666666666666667],
      ],
    },
    font: "Delius Unicase:700",
    scene: {
      name: "ParticlesText",
      shadow: { alpha: 0.32 },
      particles: {
        particles: {
          assets: ["gold-flower-21.png", "gold-flower19.png"],
          nb: 30000,
          nbMobile: 40000,
          scale: 1.1,
          scaleMobile: 0.7000000000000001,
          scaleDots: 0.29999999999999993,
          dotsColors: [
            [242.008447265625, 244.8018138328758, 246.6328125],
            [242, 245, 247],
            [242, 245, 247],
            [242, 245, 247],
          ],
        },
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "gold-flower19.png",
            "gold-flower18.png",
            "gold-flower16.png",
            "gold-flower17.png",
            "gold-flower13.png",
            "gold-flower14.png",
            "gold-flower15.png",
            "gold-flower11.png",
            "gold-flower12.png",
            "gold-flower7.png",
            "gold-flower8.png",
            "gold-flower9.png",
            "gold-flower5.png",
            "gold-flower6.png",
            "gold-flower3.png",
            "gold-flower4.png",
            "gold-flower1.png",
            "gold-flower2.png",
          ],
          nb: 400,
          nbMobile: 100,
          scale: 0.9500000000000001,
          scaleMobile: 1.37,
          scaleDots: 0.45999999999999996,
          dotsColors: [
            [207.78515625, 172.06945010623426, 76.62077636718752],
            [238.365234375, 217.8949375298187, 163.8760986328125],
            [208, 172, 77],
            [208, 172, 77],
          ],
        },
      },
    },
  },
  {
    name: "flowers-text-3",
    background: {
      colors: [
        [0.9294117647058824, 0.9725490196078431, 0.9803921568627451],
        [0.3764705882352941, 0.396078431372549, 0.6431372549019608],
      ],
    },
    font: "Delius Unicase:700",
    scene: {
      name: "ParticlesText",
      shadow: { alpha: 0.1 },
      particles: {
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
            "blue-flower-6.png",
            "blue-flower-4.png",
            "blue-flower-3.png",
            "blue-flower-9.png",
          ],
          nb: 30000,
          nbMobile: 40000,
          scale: 1.1,
          scaleMobile: 0.5,
          scaleDots: 0.54,
          dotsColors: [
            [252.41015625, 248.37671017208612, 116.73969726562503],
            [106.89305815825588, 230.09765625, 71.905517578125],
            [43.99248046875, 159.97265625, 46.47403828388931],
            [172.72265625, 3.2385498046874988, 149.9530009584168],
          ],
        },
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
            "blue-flower-4.png",
            "blue-flower-5.png",
            "blue-flower-3.png",
          ],
          nb: 110,
          nbMobile: 100,
          scale: 0.8400000000000001,
          scaleMobile: 0.52,
          scaleDots: 1,
          dotsColors: [
            [255, 255, 255],
            [252, 248, 248],
            [255, 255, 255],
            [255, 255, 255],
          ],
        },
      },
    },
  },
];
export const themesShape = [
  {
    name: "flowers-shape-0",
    background: {
      colors: [
        [0.9803921568627451, 0.9411764705882353, 0.9058823529411765],
        [0.9921568627450981, 0.5764705882352941, 0.5568627450980392],
      ],
    },
    font: "Orelega One:400",
    scene: {
      name: "ShapeFrame",
      shadow: { alpha: 0.07 },
      photo: { url: "", visible: false },
      particles: {
        shape: { id: "heart" },
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
            "blue-flower-6.png",
            "blue-flower-4.png",
            "blue-flower-3.png",
            "blue-flower-9.png",
          ],
          nb: 8760,
          nbMobile: 6170,
          scale: 1.48,
          scaleMobile: 0.9500000000000001,
          scaleDots: 0.54,
          dotsColors: [
            [252.41015625, 248.37671017208612, 116.73969726562503],
            [106.89305815825588, 230.09765625, 71.905517578125],
            [43.99248046875, 159.97265625, 46.47403828388931],
            [172.72265625, 3.2385498046874988, 149.9530009584168],
          ],
        },
      },
      text: {
        fontFamily: "Orelega One",
        fontSize: 70,
        fontWeight: "400",
        colors: [
          "#834664",
          "#915573",
          "#914269",
          "#834664",
          "#834664",
          "#834664",
        ],
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
            "blue-flower-4.png",
            "blue-flower-5.png",
            "blue-flower-3.png",
          ],
          nb: 110,
          nbMobile: 100,
          scale: 0.8400000000000001,
          scaleMobile: 0.52,
          scaleDots: 1,
          dotsColors: [
            [255, 255, 255],
            [252, 248, 248],
            [255, 255, 255],
            [255, 255, 255],
          ],
        },
      },
    },
  },
  {
    name: "flowers-shape-1",
    background: {
      colors: [
        [0.1843137254901961, 0.19215686274509805, 0.29411764705882354],
        [0.023529411764705882, 0.023529411764705882, 0.03137254901960784],
      ],
    },
    font: "Rochester:400",
    scene: {
      name: "ShapeFrame",
      shadow: { alpha: 0.1 },
      photo: { url: "", visible: true },
      particles: {
        shape: { id: "circle" },
        particles: {
          assets: [
            "gold-flower-22.png",
            "gold-flower-20.png",
            "gold-flower-21.png",
            "gold-flower19.png",
            "gold-flower18.png",
            "gold-flower16.png",
            "gold-flower17.png",
            "gold-flower13.png",
            "gold-flower14.png",
            "gold-flower15.png",
            "gold-flower10.png",
            "gold-flower11.png",
            "gold-flower12.png",
            "gold-flower7.png",
            "gold-flower8.png",
            "gold-flower9.png",
            "gold-flower5.png",
            "gold-flower6.png",
            "gold-flower3.png",
            "gold-flower4.png",
            "gold-flower1.png",
            "gold-flower2.png",
          ],
          nb: 12000,
          nbMobile: 6610,
          scale: 1.5,
          scaleMobile: 1.4,
          scaleDots: 1,
          dotsColors: [
            [255, 255, 255],
            [252.41015625, 247.6774658203125, 247.6774658203125],
            [255, 255, 255],
            [255, 255, 255],
          ],
        },
      },
      text: {
        fontFamily: "Rochester",
        fontSize: 80,
        fontWeight: "400",
        colors: [
          "#f5e3bb",
          "#ecdbb5",
          "#f8e6c9",
          "#e8d5ab",
          "#f5ecdc",
          "#e8d5ab",
        ],
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "gold-flower-22.png",
            "gold-flower-20.png",
            "gold-flower-21.png",
            "gold-flower19.png",
            "gold-flower18.png",
            "gold-flower16.png",
            "gold-flower17.png",
            "gold-flower13.png",
            "gold-flower14.png",
            "gold-flower15.png",
            "gold-flower10.png",
            "gold-flower11.png",
            "gold-flower12.png",
            "gold-flower7.png",
            "gold-flower8.png",
            "gold-flower9.png",
            "gold-flower5.png",
            "gold-flower6.png",
            "gold-flower3.png",
            "gold-flower4.png",
            "gold-flower1.png",
            "gold-flower2.png",
          ],
          nb: 110,
          nbMobile: 100,
          scale: 0.8400000000000001,
          scaleMobile: 0.9500000000000001,
          scaleDots: 1,
          dotsColors: [
            [255, 255, 255],
            [252, 248, 248],
            [255, 255, 255],
            [255, 255, 255],
          ],
        },
      },
    },
  },

  {
    name: "flowers-shape-2",
    background: {
      colors: [
        [0.9803921568627451, 0.984313725490196, 1],
        [0.6431372549019608, 0.7137254901960784, 1],
      ],
    },
    font: "Sansita Swashed:400",
    scene: {
      name: "ShapeFrame",
      shadow: { alpha: 0.1 },
      photo: { url: "", visible: true },
      particles: {
        shape: { id: "heart" },
        particles: {
          assets: [
            "blue-flower-7.png",
            "blue-flower-9.png",
            "blue-flower-11.png",
            "blue-flower-12.png",
            "blue-flower-14.png",
          ],
          nb: 3000,
          nbMobile: 3000,
          scale: 1.9,
          scaleMobile: 1.27,
          scaleDots: 1,
          dotsColors: [
            [147.97595214843747, 209.1344342205975, 249.22265625],
            [191.25, 231.14795918367346, 255],
            [252.41015624999997, 156.17878417968748, 156.17878417968748],
            [249.22265625, 200.9357666015625, 200.9357666015625],
          ],
        },
      },
      text: {
        fontFamily: "Sansita Swashed",
        fontSize: 75,
        fontWeight: 400,
        colors: [
          "#6d4d99",
          "#604389",
          "#64458f",
          "#604389",
          "#604389",
          "#604389",
        ],
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "blue-flower-6.png",
            "blue-flower-12.png",
            "blue-flower-14.png",
          ],
          nb: 100,
          nbMobile: 100,
          scale: 1,
          scaleMobile: 1.05,
          scaleDots: 0,
          dotsColors: [
            [56, 61, 64],
            [82, 89, 66],
            [140, 129, 70],
            [89, 46, 21],
          ],
        },
      },
    },
  },
  {
    name: "flowers-shape-3",
    background: {
      colors: [
        [0.9254901960784314, 0.9529411764705882, 0.9803921568627451],
        [0.7137254901960784, 0.9490196078431372, 0.9921568627450981],
      ],
    },
    font: "Delius Swash Caps:400",
    scene: {
      name: "ShapeFrame",
      shadow: { alpha: 0.22 },
      photo: { url: "", visible: true },
      particles: {
        shape: { id: "circle" },
        particles: {
          assets: [
            "blue-flower-8.png",
            "blue-flower-9.png",
            "blue-flower-10.png",
            "blue-flower-11.png",
            "blue-flower-12.png",
          ],
          nb: 2270,
          nbMobile: 1830,
          scale: 1.4000000000000001,
          scaleMobile: 1.69,
          scaleDots: 0.54,
          dotsColors: [
            [64.82204589843751, 106.45278534941615, 131.28515625],
            [61.51003417968751, 85.05404940518464, 99.41015625000001],
            [240.95507812499997, 134.03126220703123, 134.03126220703123],
            [247.330078125, 173.1310546875, 173.1310546875],
          ],
        },
      },
      text: {
        fontFamily: "Delius Swash Caps",
        fontSize: 80,
        fontWeight: "400",
        colors: [
          "#704ea3",
          "#634392",
          "#7658a3",
          "#634392",
          "#634392",
          "#634392",
        ],
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "blue-flower-7.png",
            "blue-flower-6.png",
            "blue-flower-4.png",
            "blue-flower-5.png",
            "blue-flower-3.png",
            "blue-flower-2.png",
            "blue-flower-1.png",
          ],
          nb: 100,
          nbMobile: 100,
          scale: 0.900000000000001,
          scaleMobile: 1,
          scaleDots: 1,
          dotsColors: [
            [56, 61, 64],
            [82, 89, 66],
            [140, 129, 70],
            [89, 46, 21],
          ],
        },
      },
    },
  },
  {
    name: "flowers-shape-4",
    background: {
      colors: [
        [0.9294117647058824, 0.9725490196078431, 0.9803921568627451],
        [0.3764705882352941, 0.396078431372549, 0.6431372549019608],
      ],
    },
    font: "Spicy Rice:400",
    scene: {
      name: "ShapeFrame",
      shadow: { alpha: 0.07 },
      photo: { url: "", visible: false },
      particles: {
        shape: { id: "heart" },
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
            "blue-flower-6.png",
            "blue-flower-4.png",
            "blue-flower-3.png",
            "blue-flower-9.png",
          ],
          nb: 8760,
          nbMobile: 6000,
          scale: 1.48,
          scaleMobile: 0.9500000000000001,
          scaleDots: 0.54,
          dotsColors: [
            [252.41015625, 248.37671017208612, 116.73969726562503],
            [106.89305815825588, 230.09765625, 71.905517578125],
            [43.99248046875, 159.97265625, 46.47403828388931],
            [172.72265625, 3.2385498046874988, 149.9530009584168],
          ],
        },
      },
      text: {
        fontFamily: "Spicy Rice",
        fontSize: 70,
        fontWeight: "400",
        colors: [
          "#4b7796",
          "#4e7a99",
          "#4f80a3",
          "#4b7796",
          "#4b7796",
          "#4b7796",
        ],
      },
      border: {
        name: "BorderEdge",
        particles: {
          assets: [
            "flower-1.png",
            "flower-2.png",
            "flower-3.png",
            "flower-4.png",
            "flower-5.png",
            "flower-6.png",
            "blue-flower-4.png",
            "blue-flower-5.png",
            "blue-flower-3.png",
          ],
          nb: 110,
          nbMobile: 100,
          scale: 0.8400000000000001,
          scaleMobile: 0.52,
          scaleDots: 1,
          dotsColors: [
            [255, 255, 255],
            [252, 248, 248],
            [255, 255, 255],
            [255, 255, 255],
          ],
        },
      },
    },
  },
];
