/* Libraries */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
/* -Components */

/* Selectors */
import { IconButton, IconButtonCircle } from "components/_v2/Button";
/* -Selectors */

/* Hooks */
/* -Hooks */

/* Actions */
/* -Actions */

/* Components */
/* -Components */

import styles from "./index.module.scss";

const NavBarButton = props => {
  const {
    buttonRef,
    className,
    count,
    icon,
    iconSelected,
    iconSize = "38",
    isCircle,
    reducedSpace,
    label,
    link,
    onClick,
    selected,
    title,
  } = props;

  const Element = isCircle ? IconButtonCircle : IconButton;

  return (
    <Element
      className={classnames(styles.button, className, {
        [styles.reducedSpace]: reducedSpace,
        [styles.selected]: selected,
      })}
      to={link}
      icon={selected ? iconSelected : icon}
      iconSize={iconSize}
      ref={buttonRef}
      onClick={onClick}
      title={title}
    >
      <span className={styles.buttonLabel}>{label}</span>
      {count > 0 && <div className={styles.count}>{count}</div>}
    </Element>
  );
};

NavBarButton.propTypes = {
  buttonRef: PropTypes.func,
  className: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.string,
  iconSelected: PropTypes.string,
  iconSize: PropTypes.string,
  isCircle: PropTypes.bool,
  reducedSpace: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string }),
  ]),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.string,
};

export default React.memo(NavBarButton);
