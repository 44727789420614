/* Libraries */
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import Icon from "components/Icon";
/* -Components */

import styles from "./index.module.scss";

export const Badge = React.memo(props => {
  const { className, color = "primary", size = "small", type } = props;

  const icon =
    type === "success"
      ? "circle-check"
      : type === "error"
        ? "alert-circle"
        : props.icon;

  let iconSize = "14";
  if (size === "medium") {
    iconSize = "18";
  } else if (type === "success" || type === "error") {
    iconSize = "18";
  }

  return (
    <span
      className={classnames(className, styles.iconContainer, {
        [styles.isSmall]: size === "small",
        [styles.isMedium]: size === "medium",
        [`has-text-${color}`]: color,
      })}
    >
      <Icon icon={icon} size={iconSize} className={styles.icon} />
    </span>
  );
});

Badge.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  type: PropTypes.oneOf(["error", "success"]),
};
