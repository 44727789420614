/* Libraries */
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
/* -Libraries */

/* Components */
import { InverseButton } from "components/_v2/Button";
import { Number } from "components/FormField";
import FormSubmissionError from "components/FormSubmissionError";
import ResendAuthCode from "components/ResendAuthCode";
/* -Components */

/* Hooks */
import useIsMounted from "hooks/useIsMounted";
/* -Hooks */

/* Actions */
import {
  login,
  register,
  requestLoginCode,
  requestRegistrationCode,
} from "redux/auth/actions";
/* -Actions */

/* Selectors */
import { getEmail } from "redux/anonymousData/selectors";
/* -Selectors */

import { noop } from "utils/clientUtils";
import { getErrorStatus } from "utils/apiUtils";
import { EVENTS } from "utils/analyticsUtils";

const fieldName = "authCode";
const returnPromise = () => Promise.resolve();

const selector = createSelector(getEmail, email => {
  return { anonymousEmail: email || "" };
});

const AuthCodeForm = props => {
  const {
    autoFocus,
    buttonText,
    className,
    emailSentToClassName,
    onLocked,
    onResend = noop,
  } = props;

  const { anonymousEmail } = useSelector(selector);

  const isMounted = useIsMounted();
  const formTools = useForm({
    defaultValues: { [fieldName]: "" },
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = formTools;

  const inputRef = useRef();
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const authCode = watch(fieldName);
  const [formError, formErrorSetter] = useState("");
  // this looks like overkill, but keeps the submit button spinning after successful
  // authentication before the site redirects the user to whatever is next
  const [formSuccess, formSuccessSetter] = useState(false);
  const authCodeFilled = authCode.length === 6;

  const onSubmit = async () => {
    formErrorSetter(false);
    const {
      onError = noop,
      onSubmit = returnPromise,
      onSuccess = noop,
    } = props;

    if (authCodeFilled) {
      try {
        const submitResult = await onSubmit({ code: authCode });

        if (isMounted()) {
          formSuccessSetter(true);
          onSuccess(submitResult);
        }
      } catch (error) {
        onError(error);
        let message;
        switch (getErrorStatus(error)) {
          case 403: //forbidden
            message = "Code is invalid. Please request another.";
            break;
          case 410: // valid but expired
            message = "Code is expired. Please request another.";
            break;
          case 423: // account locked
            onLocked();
            break;
          default:
            message = true;
        }

        formErrorSetter(message);
      }
    }
  };

  return (
    <FormProvider {...formTools}>
      {anonymousEmail && (
        <p
          className={classnames(
            "mb-1_5",
            "has-text-centered",
            emailSentToClassName
          )}
        >
          We've sent a one-time passcode to <b>{anonymousEmail}</b>. Check the
          email and enter the code below.
        </p>
      )}
      <form
        className={classnames("has-text-centered", className)}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="field">
          <div className="control mb-05">
            <Number
              placeholder={"Enter 6 digit code"}
              id={fieldName}
              name={fieldName}
              maxLength={6}
              autoComplete="off"
              inputRef={inputRef}
            />
          </div>
        </div>

        <FormSubmissionError
          show={!!formError}
          text={formError !== true ? formError : ""}
          action={EVENTS.errors.authCode}
        />

        <div className="buttons is-centered mt-3">
          <InverseButton
            className="is-fullwidth"
            colour="teal4"
            type="submit"
            disabled={isSubmitting || !authCodeFilled}
            loading={isSubmitting || formSuccess}
            filled
          >
            {buttonText}
          </InverseButton>
        </div>

        <ResendAuthCode onResend={onResend} />
      </form>
    </FormProvider>
  );
};

AuthCodeForm.propTypes = {
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onResend: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onWhite: PropTypes.bool,
  resendClassName: PropTypes.string,
};

const LoginAuthCodeForm = props => {
  const dispatch = useDispatch();

  const onResend = useCallback(() => {
    return dispatch(requestLoginCode());
  }, [dispatch]);

  const onSubmit = useCallback(
    ({ code }) => {
      return dispatch(login({ code }));
    },
    [dispatch]
  );

  return (
    <AuthCodeForm
      {...props}
      {...{ onSubmit, onResend }}
      buttonText="Let's go"
    />
  );
};
AuthCodeForm.Login = LoginAuthCodeForm;

const RegisterAuthCodeForm = props => {
  const dispatch = useDispatch();

  const onResend = useCallback(() => {
    return dispatch(requestRegistrationCode());
  }, [dispatch]);

  const onSubmit = useCallback(
    ({ code }) => {
      return dispatch(register({ code }));
    },
    [dispatch]
  );

  return (
    <AuthCodeForm
      {...props}
      {...{ onSubmit, onResend }}
      buttonText="Let's go!"
    />
  );
};
AuthCodeForm.Register = RegisterAuthCodeForm;

export default AuthCodeForm;
