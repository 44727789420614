/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
/* -Selectors */

/* -Actions */
/* -Actions */

export const actions = {
  play: createAction("KINDEO_PLAY"),
  stop: createAction("KINDEO_STOP")
};

export const playKindeo = () => dispatch => {
  dispatch(actions.play());
};

export const stopKindeo = () => dispatch => {
  dispatch(actions.stop());
};
