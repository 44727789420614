/* Libraries */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import * as contributionsSelectors from "redux/contributions/selectors";
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

/* Actions */
import * as editorActions from "redux/editor/actions";
/* -Actions */

/* Hooks */
import usePrevious from "hooks/usePrevious";
/* -Hooks */

const provideSelector = contributionId => {
  // the slide may be in the contributions store (most users)
  // or the story slides (when the owner)
  return createSelector(
    [
      contributionsSelectors.getContributions,
      state => storySelectors.getSlide(state, contributionId),
    ],
    (contributions, creatorSlide) => {
      return (
        contributionsSelectors.getContributionById(
          contributions,
          contributionId
        ) || creatorSlide
      );
    }
  );
};

const useLoadContributionSlideData = contributionId => {
  const dispatch = useDispatch();

  const slide = useSelector(provideSelector(contributionId));

  const [editorDataLoaded, setEditorDataLoaded] = useState(false);

  // Clone and load the provided slide data into stores
  const loadEditorData = useCallback(
    (overrides = {}) => {
      // clear the editor first
      dispatch(editorActions.resetEditor());

      setEditorDataLoaded(false);

      dispatch(
        editorActions.editSlideProperty({
          ...JSON.parse(JSON.stringify(slide || {})), // Clone the current slide
          ...overrides,
        })
      );
      setEditorDataLoaded(true);
    },
    [dispatch, slide]
  );

  // Listen for changes in contributionId
  const contributionIdPrev = usePrevious(contributionId);
  useEffect(() => {
    if (contributionIdPrev !== contributionId) {
      loadEditorData();
    }
  }, [contributionIdPrev, contributionId, loadEditorData]);

  return { loadData: loadEditorData, loaded: editorDataLoaded };
};

export default useLoadContributionSlideData;
