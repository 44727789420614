import { useCallback, useEffect, useRef, useState } from "react";
import { noop } from "utils/clientUtils";

const useCountdown = ({ nb = 3, ms = 600, onReady = noop } = {}) => {
  const [countdown, setCountdown] = useState(nb); // state system (see STATES)
  const intervalCountdown = useRef();

  const clear = useCallback(() => {
    if (intervalCountdown.current) {
      clearInterval(intervalCountdown.current);
      intervalCountdown.current = null;
    }
  }, []);

  const start = useCallback(() => {
    setCountdown(nb);
    intervalCountdown.current = setInterval(() => {
      setCountdown(c => c - 1);
    }, ms);
  }, [ms, nb]);

  useEffect(() => {
    if (countdown < 1) {
      intervalCountdown.current && clearInterval(intervalCountdown.current);
      intervalCountdown.current = null;

      onReady();
    }
  }, [countdown, onReady]);

  return { count: countdown, clear, start };
};

export default useCountdown;
