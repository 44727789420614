/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

export const setLoaderAnimation = createAction("SET_LOADER_ANIMATION");

export const setAppLoader = createAction("SET_APP_LOADER");
export const setPageLoader = createAction("SET_PAGE_LOADER");

export const setMediaPoolLoaded = createAction("SET_MEDIAPOOL_LOADED");
