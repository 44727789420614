/* Libraries */
import axios from "axios";
/* -Libraries */

// get individual slide data
export const getSlide = slideId => dispatch => {
  return axios.get(`slide/${slideId}`).then(result => {
    return result.data.slide;
  });
};

// check if local contribution slide data is current or out of date
export const getLastSlideUpdate = slideId => (dispatch, getState) => {
  return axios.get(`slide/${slideId}/last_update`).then(result => {
    return result.data.slide;
  });
};

// set the file to be used as the slide thumbnail
export const setSlideThumbnail = (slideId, key) => dispatch => {
  return axios
    .post(`/slide/${slideId}/thumbnail`, {
      s3_key: key,
    })
    .then(response => {
      return response.data;
    });
};
