/* 
  with the new subnavigation system on the edit page using hash (#slides, #contribution, etc.)
  some problem started with the React router location: sometimes the hash would be in 1) location.hash, 2) location.pathname

  These utils are here to easily get access to the pathname WITHOUT the hash, and get the hash itself!

  Usually, they are used when opening / linking modals, and only for the edit page.
*/

import { matchPath } from "react-router-dom";

const rx = /#.*$/;

export const getHash = location =>
  location.hash || rx.exec(location.pathname)?.[0] || "";

export const getPathnameHash = location => {
  const hash = getHash(location);
  const pathname = location.pathname.replace(hash, "");

  return { hash, pathname };
};

export const removeHash = str => str?.replace(rx, "") || "";

export const matchAnyPath = (pathname, paths) => {
  if (typeof paths === "string") {
    paths = [paths];
  }
  if (Array.isArray(paths)) {
    return paths
      .map(pathname => ({ path: pathname, exact: true }))
      .find(pathObj => matchPath(pathname, pathObj));
  }

  return false;
};
