/* Libraries */
import { IconButton } from "components/_v2/Button";
import React from "react";
/* -Libraries */

/* Components */
/* -Components */

const IconCloseButton = props => {
  const { className, ...otherProps } = props;

  return (
    <IconButton
      className={className}
      icon="cross-1"
      iconSize="24"
      {...otherProps}
    />
  );
};

export default React.memo(IconCloseButton);
