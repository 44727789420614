import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import thumbnail from "images/slides/thumbnails/location.png";
import { mapDesigns } from "libs/kindeo-play/webgl/scenes/kindeo/views/location/config";

const type = slideTypes.LOCATION;
const locationVariants = slideVariants[type];

export default {
  type,
  icon: "location-fill",
  label: "Location",
  descriptionBuilder: "Pin your message on the map",
  colour: "purple5",
  thumbnail,
  variants: [
    {
      name: locationVariants.POSTCARD,
      label: "Default",
      validation: {
        required: `Slide requires a location`,
      },
    },
  ],
  fields: [
    {
      key: "location",
      type: inputTypes.LOCATION,
      placeholder: "e.g. London",
      label: "Location",
      label2: "Results",
      validation: {
        required: "Please add a location",
      },
    },
    {
      key: "design",
      type: inputTypes.MAP,
      label: "Style",
      defaultValue: mapDesigns[0].name,
      designs: mapDesigns,
      variants: [locationVariants.POSTCARD],
    },
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Message",
      placeholder: "e.g. Here in",
      validation: {
        maxLength: {
          value: 20,
          message: `Please keep your message to 20 characters or less`,
        },
      },
    },
  ],
};
