/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
/* -Selectors */

export const actions = {
  set: createAction("PERSISTED_SET"),
  unset: createAction("PERSISTED_UNSET"),
  reset: createAction("PERSISTED_RESET"),
};

// set an individual peristed data item
export const setPersistedData = (id, value) => dispatch => {
  dispatch(
    actions.set({
      id,
      value,
    })
  );
};

// unset either an individual persisted data item or an array of items
export const unsetPersistedData = ids => dispatch => {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  ids.forEach(id => {
    dispatch(
      actions.unset({
        id,
      })
    );
  });
};
