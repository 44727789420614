/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import * as selectors from "./selectors";
/* Selectors */

export const actions = {
  setBoundingBoxes: createAction("BOUNDINGBOX_SET"),
  resetBoundingBoxes: createAction("BOUNDINGBOX_RESET"),
};

export const setBoundingBoxes = boxes => (dispatch, getState) => {
  const validBoxes = boxes?.reduce((acc, box) => {
    if (selectors.isValidBox(box)) {
      acc.push(selectors.getClampedBox(box));
    }
    return acc;
  }, []);
  // sort by y value so that pencil icons aren't oveerlapped by other boxes
  const sortedBoxes = validBoxes.sort(
    (a, b) => selectors.getY(a) - selectors.getY(b)
  );

  const currentBoxes = selectors.getBoundingBoxes(getState());
  if (JSON.stringify(currentBoxes) !== JSON.stringify(sortedBoxes)) {
    return dispatch(actions.setBoundingBoxes(sortedBoxes));
  }

  return Promise.resolve();
};

export const resetBoundingBoxes = () => dispatch => {
  return dispatch(actions.resetBoundingBoxes());
};
