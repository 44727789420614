/* Selectors */
import * as promptsSelectors from "redux/prompts/selectors";
import * as webglSelectors from "redux/webgl/selectors";
/* -Selectors */

/* Actions */
import {
  completePrompt,
  addToPromptQueue,
  removeFromPromptQueue,
} from "redux/prompts/actions";
/* -Actions */

/* Libraries */
import { createSelector } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
/* -Libraries */

/* 

  Pass the id of the prompt to show. 
  Returns a tupple with [show, complete] 
  - show -> true if the id matches the currentPrompt from the redux store `prompts`
  ```
  const [showPrompt, completePrompt] = usePrompts(PROMPTS_IDS.id1);

  ...
  return (
    { showPrompt && <button onClick={completePrompt} /> }
  )
  ```
  
*/
const selector = createSelector(
  [promptsSelectors.getCurrentPrompt, webglSelectors.getIsPlaying],
  (currentPrompt, webglIsPlaying, prompts) => {
    return {
      currentPrompt,
      webglIsPlaying,
    };
  }
);

const testFnPlaceholder = () => true;

/**
 * testFn: a function the component can implement to run some extra test / run some specific logic to show or hide the prompt
 * props: an array of dependancies that will retrigger the testFn() when they change.
 */
export default function usePrompts(
  id,
  { testFn = testFnPlaceholder, props = [], onlyDismiss = false } = {}
) {
  const { currentPrompt, webglIsPlaying } = useSelector(selector);

  /**
   * I _think_ we need this because the props are not memoized and change every render.
   * Does it bulletproof it? (Seems like it because there is no error anymore)
   */
  const prevProps = useRef(props);
  const passConditions = useMemo(() => {
    if (props !== prevProps.current) {
      return testFn();
    } else {
      return false;
    }
  }, [props, testFn]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!id || !passConditions) return;
    if (!onlyDismiss) {
      dispatch(addToPromptQueue(id));
    }

    return () => {
      if (!onlyDismiss) {
        dispatch(removeFromPromptQueue(id));
      }
    };
  }, [dispatch, id, onlyDismiss, passConditions]);

  const show = currentPrompt === id && !webglIsPlaying;

  const complete = useCallback(() => {
    if (!id || !show) return;

    dispatch(completePrompt(id));
  }, [dispatch, id, show]);

  return [show, complete];
}
