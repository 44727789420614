/* Libraries */
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
/* -Libraries */

/* Selectors */
import * as accountSelectors from "redux/account/selectors";
/* -Selectors */

const authSelector = createSelector(accountSelectors.getProfile, profile => {
  return {
    isLoggedIn: accountSelectors.isLoaded(profile),
  };
});

const useAuthStatus = () => {
  const { isLoggedIn } = useSelector(authSelector);

  return isLoggedIn;
};

export default useAuthStatus;
