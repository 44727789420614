/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
/* -Selectors */

/* Actions */
/* -Actions */

export const actions = {
  acceptCookies: createAction("SET_COOKIE_ACCEPTED")
};

export const acceptCookies = (accept = true) => dispatch => {
  return dispatch(actions.acceptCookies(accept));
};
