/* Libraries */
import { useCallback, useContext, useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
/* -Libraries */

/* Actions */
import { updatePrompts } from "redux/account/actions";
import { clearAnonymousData } from "redux/anonymousData/actions";
import { processPostAuthData } from "redux/auth/actions";
import { acceptCookies } from "redux/cookie/actions";
/* -Actions */

/* Selectors */
import {
  getAuthScenario,
  getBundleQuantity,
  getBundleType,
  getContributionId,
  getGroupToken,
  getProductTier,
  getPromptsCompleted,
  getRecipientToken,
  getSendMethod,
  getStoryId,
} from "redux/anonymousData/selectors";
import { authScenarios } from "redux/auth/selectors";
import { isLoaded } from "redux/story/selectors";
/* -Selectors */

/* Hooks */
import useGetSendMethodUrl from "./useGetSendMethodUrl";
import useModals from "hooks/useModals";
import usePrevious from "hooks/usePrevious";
/* -Hooks */

/* Context */
import { BroadcastContext, MESSAGE_IDS } from "context/BroadcastContext";
/* -Context */

import { paths } from "routes/PageRoutes";
import { modals } from "routes/ModalRoutes";
import { getHash } from "utils/url";

const selector = createSelector(
  [
    getAuthScenario,
    getBundleQuantity,
    getBundleType,
    getContributionId,
    getGroupToken,
    getProductTier,
    getPromptsCompleted,
    getRecipientToken,
    getSendMethod,
    getStoryId,
    isLoaded,
  ],
  (
    authScenario,
    bundleQuantity,
    bundleType,

    contributionId,
    groupToken,
    productTier,
    promptsCompleted,
    recipientToken,
    sendMethod,
    storyId,
    storyIsLoadedInSession
  ) => {
    return {
      authScenario,
      bundleQuantity,
      bundleType,
      contributionId,
      groupToken,
      productTier,
      promptsCompleted,
      recipientToken,
      sendMethod,
      storyId,
      storyIsLoadedInSession,
    };
  }
);

const reconstructUrlFromLocation = location => {
  if (typeof location === "object") {
    return location?.pathname + location?.search + location?.hash;
  }
  return location;
};

function usePostAuthProcess(isLoggedIn, forwardTo) {
  const {
    authScenario,
    bundleQuantity,
    bundleType,
    groupToken,
    productTier,
    promptsCompleted,
    recipientToken,
    sendMethod,
    storyId,
    storyIsLoadedInSession,
  } = useSelector(selector);

  const dispatch = useDispatch();
  const { sendMessage } = useContext(BroadcastContext);
  const { linkModal } = useModals();

  const location = useLocation();
  const hash = getHash(location);
  const [redirectRoute, setRedirectRoute] = useState();
  const [postAuthComplete, setPostAuthComplete] = useState(false);

  const sendMethodUrl = useGetSendMethodUrl(sendMethod, storyId);

  // when login state changes, determine redirect path based on anonymous data
  const establishRedirect = useCallback(
    (actionResult, actionError) => {
      // if a route has been specified, use that
      let forwardUrl;
      if (forwardTo) {
        forwardUrl = reconstructUrlFromLocation(forwardTo).replace(
          ":storyId",
          storyId
        );
      }

      let newRoute;
      switch (authScenario) {
        case authScenarios.ANON_SAVE_EXIT:
        case authScenarios.CREATOR_SAVE:
        case authScenarios.CREATOR_SEND:
        case authScenarios.CREATOR_SETTINGS:
        case authScenarios.CREATOR_INVITE:
          // if authed with an anonymous story ID, show the modal with a story ID in the URL
          if (storyId) {
            let contextUrl = paths.home();
            if (authScenario === authScenarios.CREATOR_INVITE) {
              contextUrl = paths.editGroup(storyId);
            } else if (authScenario === authScenarios.CREATOR_SAVE) {
              contextUrl = paths.edit(storyId);
            } else if (authScenario === authScenarios.CREATOR_SEND) {
              contextUrl = sendMethodUrl;
            } else if (authScenario === authScenarios.CREATOR_SETTINGS) {
              contextUrl = paths.editSettings(storyId);
            }

            newRoute = linkModal(
              modals.postAuthCreatorSaved(),
              { search: "" },
              // As the creator has access to side panel routes, sometimes we may have a forwardUrl here
              forwardUrl ? forwardUrl : contextUrl + hash
            );
          }
          break;

        case authScenarios.CREATOR_PAYMENT:
          // if authed with an anonymous story ID, show the upgrade modal with
          // a story ID in the URL and payment tier in params
          if (storyId) {
            newRoute = linkModal(
              modals.upgrade(),
              { productTier, unlockDigitalSend: !!sendMethod },
              sendMethod
                ? paths.editSendSetupRecipient(storyId)
                : paths.edit(storyId) + hash
            );
          }
          break;

        case authScenarios.CONTRIBUTION:
          // if authed with a contribution ID, but there's
          // no story in session we've authed by link
          // so forward on to the group page
          // otherwise we've authed by code, all data is in store
          // and handled by ContributionBuilderContext
          if (!storyIsLoadedInSession) {
            newRoute = linkModal(
              modals.postAuthContributionSent(),
              { search: "", ...(actionError ? { alreadySent: true } : {}) },
              paths.group(groupToken) + hash
            );
          }
          break;

        case authScenarios.RECIPIENT_SAVE:
          if (recipientToken) {
            newRoute = linkModal(
              modals.postAuthRecipientSaved(),
              { search: "" },
              paths.recipientSlides(recipientToken) + hash
            );
          }
          break;

        case authScenarios.RECIPIENT_THANKS:
          if (recipientToken) {
            newRoute = linkModal(
              modals.postAuthRecipientSaved(),
              { search: "", thanksSent: true },
              paths.recipientSlides(recipientToken) + hash
            );
          }
          break;

        case authScenarios.SWITCH_DEVICE:
          // if authed with an anonymous story ID, show the slide builder with a story ID in the URL
          if (storyId) {
            newRoute = linkModal(
              modals.slideBuilder(),
              { search: "" },
              paths.edit(storyId) + hash
            );
          }
          break;

        case authScenarios.PRIVATE_JOIN:
          if (recipientToken) {
            newRoute = paths.recipient(recipientToken) + hash;
          } else if (groupToken) {
            newRoute = paths.group(groupToken) + hash;
          }
          break;

        case authScenarios.BUNDLE_PAYMENT:
          if (bundleType && bundleQuantity) {
            newRoute =
              paths.landingPricingBundles() +
              "?purchase&type=" +
              bundleType +
              "&quantity=" +
              bundleQuantity;
          } else {
            newRoute = paths.landingPricingBundles() + "nope";
          }
          break;

        // default to the summary page
        default:
          newRoute = paths.home();
          break;
      }

      if (newRoute || forwardUrl) {
        setRedirectRoute(newRoute ? newRoute : forwardUrl);
      }

      // let the calling function know everything is done
      // even if there was no redirect route set
      setPostAuthComplete(true);
    },
    [
      authScenario,
      bundleQuantity,
      bundleType,
      forwardTo,
      groupToken,
      hash,
      linkModal,
      productTier,
      recipientToken,
      sendMethod,
      sendMethodUrl,
      storyId,
      storyIsLoadedInSession,
    ]
  );

  // if the post auth redirect changes, get all kindeo browser tabs to redirect
  useEffect(() => {
    if (redirectRoute) {
      // reconstruct a path from a location object if necessary
      const redirectPath = reconstructUrlFromLocation(redirectRoute);

      sendMessage(
        MESSAGE_IDS.AUTH,
        process.env.REACT_APP_DOMAIN + redirectPath
      );
    }
  }, [redirectRoute, sendMessage]);

  // after logging in
  // update tooltip state
  // run postAuth processing then establish where to forward on
  // then tidy up anonymous data
  const isLoggedInPrev = usePrevious(isLoggedIn);
  useEffect(() => {
    if (isLoggedIn && !isLoggedInPrev) {
      // automatically accept cookies if authenticated
      dispatch(acceptCookies());
      // update the profile with any tooltips completed while being anonymous
      dispatch(updatePrompts(promptsCompleted));
      // pass the forwardTo as a parameter to indicate we are authing
      // in the same browser session as the message is written
      dispatch(processPostAuthData(forwardTo))
        .then(result => establishRedirect(result))
        .catch(error => establishRedirect(null, error))
        // finally clear anonymous data
        .finally(() => {
          dispatch(clearAnonymousData());
        });
    }
  }, [
    dispatch,
    forwardTo,
    isLoggedIn,
    isLoggedInPrev,
    promptsCompleted,
    establishRedirect,
  ]);

  return { postAuthComplete, redirectRoute };
}

export default usePostAuthProcess;
