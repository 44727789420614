/* Libraries */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* -Components */
import MediaCapture from "components/MediaCapture";
import Modal from "components/Modal";
import ModalCloseButton from "components/ModalCloseButton";
import ModalContents from "components/ModalContents";
import SlidePanel, { panels } from "components/SlidePanel";
import StaleSlideHandler from "components/StaleSlideHandler";
/* -Components */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useHasLatestEditorData from "hooks/useHasLatestEditorData";
import useLoadContributionSlideData from "hooks/useLoadContributionSlideData";
/* -Hooks */

/* Actions */
import { setAnonymousContributionDetails } from "redux/anonymousData/actions";
import * as contributionsActions from "redux/contributions/actions";
import { fetchGroupCurrentRole } from "redux/currentRole/actions";
import * as editorActions from "redux/editor/actions";
import { createError } from "redux/errors/actions";
import { getSlide } from "redux/slide/actions";
import { updateLocalSlideData } from "redux/story/actions";
import { getRoleMedia } from "redux/userRoles/actions";
/* -Actions */

/* Selectors */
import * as currentRoleSelectors from "redux/currentRole/selectors";
import * as editorSelectors from "redux/editor/selectors";
import * as roleSelectors from "redux/roles/selectors";
import * as slideSelectors from "redux/slide/selectors";
import * as configSelectors from "redux/slideConfig/selectors";
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

/* Context */
import {
  MediaUploadContextProvider,
  MediaUploadContext,
} from "context/MediaUploadContext";
import {
  SlideBuilderContextProvider,
  SlideBuilderContext,
} from "context/SlideBuilderContext";
/* Context */

import { paths } from "routes/PageRoutes";
import styles from "./index.module.scss";

const editorSelector = createSelector(editorSelectors.getEditor, editor => {
  const slide = editorSelectors.getSlide(editor);
  return {
    isEmptySlide: slideSelectors.isEmptySlide(slide),
    isNewSlide: slideSelectors.isNewSlide(slide),
    slideId: slideSelectors.getId(slide),
    slideType: slideSelectors.getType(slide),
  };
});

const currentRoleSelector = createSelector(
  currentRoleSelectors.getCurrentRole,
  currentRole => {
    return {
      roleId: roleSelectors.getId(currentRole),
      roleIsActive: roleSelectors.getRoleIsActive(currentRole),
    };
  }
);

const storySelector = createSelector(storySelectors.getStory, story => {
  return {
    contentModeration: storySelectors.getContentModeration(story),
    storyId: storySelectors.getId(story),
    contributorLink: storySelectors.getContributorLink(story),
  };
});

const selector = createSelector(
  [editorSelector, currentRoleSelector, storySelector],
  (slideData, currentRoleData, storyData) => {
    return {
      ...slideData,
      ...currentRoleData,
      ...storyData,
    };
  }
);

const ContributionBuilderModal = props => {
  const { groupToken, params } = props;
  const { storyId, contributorLink } = useSelector(storySelector);
  const { contributionId } = params;

  // load slide details into editor store
  const { loadData, loaded: slideDataLoaded } =
    useLoadContributionSlideData(contributionId);
  const loadDataRef = useRef(loadData);
  useEffect(() => {
    loadDataRef.current();
  }, []);

  if (slideDataLoaded) {
    return (
      <MediaUploadWrapper storyId={storyId}>
        <SlideBuilderWrapper>
          <ModalComponent
            {...props}
            {...{ contributionId, groupToken, storyId, contributorLink }}
          />
        </SlideBuilderWrapper>
      </MediaUploadWrapper>
    );
  }

  return null;
};

const MediaUploadWrapper = props => {
  const { storyId } = props;

  return (
    <MediaUploadContextProvider storyId={storyId}>
      {props.children}
    </MediaUploadContextProvider>
  );
};

const SlideBuilderWrapper = props => {
  const { uploadsInProgress } = useContext(MediaUploadContext);

  return (
    <>
      <SlideBuilderContextProvider uploadsInProgress={uploadsInProgress}>
        {props.children}
      </SlideBuilderContextProvider>
    </>
  );
};

const ModalComponent = React.memo(props => {
  const {
    groupToken,
    contributionId,
    contributorLink,
    onClose,
    onSlideDeleted,
    onSlideSaved,
    params,
    ...restProps
  } = props;

  const {
    contentModeration,
    isEmptySlide,
    isNewSlide,
    roleId,
    roleIsActive,
    slideId,
    slideType,
  } = useSelector(selector);

  const {
    currentPanel,
    goToCustomise,
    goToTypeSelector,
    modalControl,
    onRequestModalClose,
    setOnClose,
    showingCapture,
  } = useContext(SlideBuilderContext);
  useEffect(() => {
    setOnClose(() => onClose);
  }, [onClose, setOnClose]);

  const dispatch = useDispatch();
  const isLoggedIn = useAuthStatus();

  const { duration } = modalControl;

  // load slide details into editor store
  const { loadData, loaded: slideDataLoaded } =
    useLoadContributionSlideData(contributionId);
  useEffect(loadData, [loadData]);

  // check we have latest data
  const { checkLatestData, hasLatestData, slideDeleted } =
    useHasLatestEditorData(slideDataLoaded && contributionId);

  // function gets the latest slide data from API and loads into the editor
  // called when we detect stale data while editing
  const reloadData = useCallback(() => {
    dispatch(getSlide(contributionId))
      .then(slide => {
        dispatch(contributionsActions.updateContributionData(slide));
        dispatch(updateLocalSlideData(slide));
        loadData();
        checkLatestData();
      })
      .catch(() => {
        onClose();
      });
  }, [checkLatestData, contributionId, dispatch, loadData, onClose]);

  // depending on state decide which panel to begin with
  const chooseInitialPanel = useCallback(() => {
    if (contributionId || !isNewSlide) {
      goToCustomise();
    } else if (isEmptySlide) {
      goToTypeSelector();
    }
  }, [
    goToCustomise,
    goToTypeSelector,
    isEmptySlide,
    isNewSlide,
    contributionId,
  ]);

  // fetch any previously added media for this role
  const setupModal = useCallback(() => {
    if (roleId && roleIsActive) {
      dispatch(getRoleMedia(roleId));
    }
    chooseInitialPanel();
  }, [chooseInitialPanel, dispatch, roleId, roleIsActive]);
  // on role change, get fresh data
  useEffect(() => {
    if (roleId) {
      setupModal();
    }
  }, [roleId, setupModal]);

  // on mount setup the modal with data
  const doLoad = useRef(setupModal);
  useEffect(() => {
    doLoad.current();
  }, []);

  // clear the editor data after we close the modal
  const afterClose = useCallback(() => {
    // clear out the editor if it has not already been saved & cleared
    // and if the user is not authenticated - login modal shows when this closes
    if (isLoggedIn) {
      dispatch(editorActions.resetEditor());
    } else {
      dispatch(setAnonymousContributionDetails(null));
    }
  }, [dispatch, isLoggedIn]);

  const commonPanelProps = useRef({
    className: styles.panel,
  });

  const submitSlide = useCallback(() => {
    if (!isLoggedIn) {
      // send slide data to be pending contribution
      // and save the contribution ID in anonymous data
      // to be picked up after authentication and contribution completed
      return dispatch(
        editorActions.addEditorAsPendingContribution({
          groupToken,
        })
      ).then(contributionId => {
        dispatch(setAnonymousContributionDetails(contributionId, groupToken));
        onClose({ contributionRequiresAuth: true });
        return Promise.reject();
      });
    } else {
      // firstly check if we have latest contribution data before saving
      return checkLatestData()
        .then(haveLatestData => {
          // if we have latest data, build a promise chain to save the slide
          if (haveLatestData) {
            const contributionId = slideId;
            let contributionPromise;
            // if an existing contribution
            if (contributionId) {
              // if moderation is off,
              // this is already a slide so update it as a slide
              if (!contentModeration) {
                contributionPromise = dispatch(
                  editorActions.addEditorAsUpdatedSlide()
                );
              }
              // if an existing contribution and moderation is on,
              // this contribution will be removed from slides
              // back into contributions
              else {
                contributionPromise = dispatch(
                  editorActions.addEditorAsUpdatedContribution()
                );
              }
            }
            // if a new contribution, create it
            else {
              contributionPromise = dispatch(
                editorActions.addEditorAsContribution({ groupToken })
              );
            }

            if (contributionPromise) {
              return contributionPromise.then(result => {
                // after contributing, update current role
                // which may have been assigned as a consequence
                dispatch(fetchGroupCurrentRole(groupToken));

                onSlideSaved(
                  contributionId,
                  slideSelectors.isModerated(result)
                );
                return Promise.resolve();
              });
            }
          }

          // if we have stale data, reject the promise
          return Promise.reject();
        })
        .catch(error => {
          dispatch(
            createError({ type: "api-error", source: "contribution builder" })
          );
          throw error;
        });
    }
  }, [
    checkLatestData,
    contentModeration,
    dispatch,
    groupToken,
    isLoggedIn,
    onClose,
    onSlideSaved,
    slideId,
  ]);

  const deleteSlide = useCallback(() => {
    onSlideDeleted(contributionId);
    onClose();
  }, [contributionId, onClose, onSlideDeleted]);

  // fetch the config for this slide
  // if we need it but it doesn't exist we should quit
  const slideConfig = configSelectors.getConfigByType(slideType);
  useEffect(() => {
    if (slideType && currentPanel !== panels.TYPESELECTOR && !slideConfig) {
      onClose();
    }
  }, [onClose, currentPanel, slideConfig, slideType]);

  const trackingProps = useMemo(() => ({ page: "group" }), []);

  const typeSelectorRef = useRef();
  const addMediaRef = useRef();
  const libraryRef = useRef();
  const customiseRef = useRef();

  if (slideDataLoaded) {
    return (
      <Switch>
        <Route path={paths.group(":groupToken")}>
          <Modal
            className={styles.slideBuilderModal}
            trackingId="Slide Modal"
            trackingProps={trackingProps}
            {...{
              afterClose,
              doClose: onRequestModalClose,
              duration,
              // isOpen,
            }}
            {...restProps}
            wide
          >
            <ModalCloseButton
              className={styles.close}
              onClick={onRequestModalClose}
            />
            <ModalContents
              className={classnames(styles.modalContents)}
              expand
              fullHeight
            >
              <TransitionGroup component={null}>
                {currentPanel === panels.TYPESELECTOR && (
                  <CSSTransition
                    timeout={350}
                    classNames="panel"
                    nodeRef={typeSelectorRef}
                  >
                    <SlidePanel.TypeSelector
                      {...commonPanelProps.current}
                      contribution
                      contributorLink={contributorLink}
                      ref={typeSelectorRef}
                    />
                  </CSSTransition>
                )}

                {currentPanel === panels.ADDMEDIA && (
                  <CSSTransition
                    timeout={350}
                    classNames="panel"
                    nodeRef={addMediaRef}
                  >
                    <SlidePanel.AddMedia
                      {...commonPanelProps.current}
                      config={slideConfig}
                      ref={typeSelectorRef}
                    />
                  </CSSTransition>
                )}

                {currentPanel === panels.LIBRARY && (
                  <CSSTransition
                    timeout={350}
                    classNames="panel"
                    nodeRef={libraryRef}
                  >
                    <SlidePanel.Library
                      {...commonPanelProps.current}
                      config={slideConfig}
                      ref={libraryRef}
                    />
                  </CSSTransition>
                )}

                {currentPanel === panels.CUSTOMISE && (
                  <CSSTransition
                    timeout={350}
                    classNames="panel"
                    nodeRef={customiseRef}
                  >
                    <SlidePanel.Customise
                      {...commonPanelProps.current}
                      isContribution
                      slideTypeConfig={slideConfig}
                      onSuccess={onRequestModalClose}
                      onDelete={deleteSlide}
                      submitSlide={submitSlide}
                      staleData={false && !hasLatestData}
                      staleHandler={
                        <StaleSlideHandler
                          disabled
                          {...{
                            doClose: onRequestModalClose,
                            reloadData,
                            slideDeleted,
                          }}
                        />
                      }
                      ref={customiseRef}
                    />
                  </CSSTransition>
                )}
              </TransitionGroup>

              {showingCapture && <MediaCapture />}
            </ModalContents>
          </Modal>
        </Route>
      </Switch>
    );
  }

  return null;
});

ContributionBuilderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSlideSaved: PropTypes.func.isRequired,
  params: PropTypes.any,
};

export default React.memo(ContributionBuilderModal);
