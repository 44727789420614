/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {
  searchFilters: [],
  searchOrder: null,
  searchTerm: "",
};

const setSearchFilter = (state, { payload: { property, value } }) => ({
  ...state,
  searchFilters: value
    ? [...state.searchFilters, property]
    : [...state.searchFilters.filter(current => current !== property)],
});

const setSearchTerm = (state, { payload }) => ({
  ...state,
  searchTerm: payload,
});

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.clearSearch]: reset,
  [actions.setSearchFilter]: setSearchFilter,
  [actions.setSearchTerm]: setSearchTerm,
  [authActions.logoutInit]: reset,
});
