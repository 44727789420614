import { inputTypes } from "redux/slide/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import thumbnail from "images/slides/thumbnails/music.png";

const type = slideTypes.GIF;
const gifVariants = slideVariants[type];

export default {
  type,
  icon: "icon-giphy-fill",
  label: "GIF",
  descriptionBuilder: "Animate your message with a GIF",
  colour: "green5",
  thumbnail,
  variants: [
    {
      name: gifVariants.SIMPLE,
      label: "Simple",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Message",
      placeholder: "e.g. You know who...",
      validation: {
        maxLength: {
          value: 50,
          message: `Please keep your message to 50 characters or less`,
        },
      },
    },

    {
      key: "subtitle",
      type: inputTypes.INPUT,
      label: "From",
      placeholder: "e.g. From your best friend",
      validation: {
        maxLength: {
          value: 30,
          message: `Please keep this to 30 characters or less`,
        },
      },
    },
    {
      key: "gif",
      type: inputTypes.GIF,
      label: "GIF",
      icon: "icon-giphy",
      validation: {
        minLength: {
          value: 1,
          message: "Please add a gif",
        },
      },
    },
  ],
};
