/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {};

const setProgressById = (state, { payload }) => {
  const { id, progress } = payload;
  return { ...state, [id]: progress };
};

const resetProgressById = (state, { payload }) => {
  const { id } = payload;
  return setProgressById(state, { payload: { id, progress: null } });
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.setProgress]: setProgressById,
  [actions.setComplete]: resetProgressById,
  [authActions.logoutInit]: reset
});
