import { useEffect, useRef } from "react";

function usePrevious(property) {
  const ref = useRef();

  // useEffect - i.e. don't do this now, schedule it for after the component has rendered
  useEffect(() => {
    ref.current = property;
  });

  return ref.current;
}

export default usePrevious;
