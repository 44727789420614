/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {
  occasion: "",
  occasion_date: null,
  recipient_first_name: "",
  recipient_last_name: "",
  reminderId: null,
  storyId: null,
  title_exported_id: null,
  title_variant: "",
};

const setStoryId = (state, { payload }) => {
  return { ...state, storyId: payload };
};

const setStory = (state, { payload }) => payload;

const reset = state => {
  return {
    ...INITIAL_STATE,
    storyId: state.storyId,
  };
};

export default createReducer(INITIAL_STATE, {
  [actions.clearStoryBuilder]: reset,
  [actions.updateStory]: setStory,
  [actions.setStoryId]: setStoryId,
  [authActions.logoutInit]: reset,
});
