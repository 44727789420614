/* Libraries */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Components */
import { CoreButton } from "components/_v2/Button";
import HeaderNavLink from "./HeaderNavLink";
import MobileNavigation from "./MobileNavigation";
import SignOutModal from "modals/SignOutModal";
/* -Components */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useBreakpoint from "hooks/useBreakpoint";
import useModals from "hooks/useModals";
/* -Hooks */

import { matchAnyPath } from "utils/url";
import { modals } from "routes/ModalRoutes";
import { accountPaths, paths, reminderPaths } from "routes/PageRoutes";

import styles from "./index.module.scss";

const PublicLinks = React.memo(props => {
  const { isLoggedIn } = props;
  const { linkModal } = useModals();

  const loginLink = isLoggedIn ? paths.home() : linkModal(modals.auth());

  return (
    <div className="navbar-item">
      <CoreButton design="primary" to={loginLink}>
        Sign In
      </CoreButton>
    </div>
  );
});

const PrivateRightLinks = React.memo(props => {
  const { isLoggedIn, mobileLinks } = props;
  const [showLogout, showLogoutSet] = useState(false);

  const { isMobile } = useBreakpoint();

  if (isMobile) {
    return (
      <>
        <MobileNavigation>
          <div className={styles.menuButtons}>
            {mobileLinks && (
              <>
                {mobileLinks}
                <div className={classnames(styles.divider, "mv-1")} />
              </>
            )}
            <GlobalNavItems />
          </div>
          {isLoggedIn && (
            <div className={classnames(styles.menuButtons, "mt-3")}>
              <HeaderNavLink
                icon="sign-out"
                title="Sign Out"
                onClick={() => {
                  showLogoutSet(true);
                }}
              />
            </div>
          )}
        </MobileNavigation>

        <SignOutModal
          isOpen={showLogout}
          onClose={() => {
            showLogoutSet(false);
          }}
        />
      </>
    );
  }

  return <GlobalNavItems />;
});

export const GlobalNavItems = props => {
  const isLoggedIn = useAuthStatus();
  const { pathname } = useLocation();
  const { linkModal } = useModals();

  const accountNavSelected = !!matchAnyPath(pathname, accountPaths);
  const homeNavSelected = !!matchAnyPath(pathname, paths.home());
  const remindersNavSelected = !!matchAnyPath(pathname, reminderPaths);
  const shopNavSelected = !!matchAnyPath(pathname, [
    paths.shop(),
    paths.shopFromReminder(":id"),
  ]);

  const accountLink = isLoggedIn ? paths.account() : linkModal(modals.auth());

  return (
    <>
      <HeaderNavLink
        to={paths.shop()}
        icon="store"
        isActive={shopNavSelected}
        title="Shop"
      />
      {isLoggedIn && (
        <>
          <HeaderNavLink
            to={paths.home()}
            icon="card"
            isActive={homeNavSelected}
            title="My Cards"
          />
          <HeaderNavLink
            to={paths.reminders()}
            icon="date"
            isActive={remindersNavSelected}
            title="Reminders"
          />
        </>
      )}
      <HeaderNavLink
        to={accountLink}
        icon="account"
        isActive={accountNavSelected}
        title={isLoggedIn ? "Account" : "Sign in"}
      />
    </>
  );
};

const PrivateLeftLinks = React.memo(props => {
  return null;
});

export { PrivateLeftLinks, PrivateRightLinks, PublicLinks };
