// import { slideConfigs as configs } from "@kindeo/Slides";
import { slideTypes } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import * as slideSelectors from "redux/slide/selectors";
import { slideConfigs as configs } from "webgl/scenes/kindeo/configs";
export const slideConfigs = configs;

export const getConfigByType = slideType =>
  slideConfigs.find(config => getType(config) === slideType);

export const filterConfigs = filter => slideConfigs.filter(filter);

export const getType = config => config?.type;
export const getIcon = config => config?.icon;
export const getLabel = config => config?.label;
export const getDescriptionBuilder = config => config?.descriptionBuilder || "";
export const getColour = config => config?.colour;
export const getThumbnail = config => config?.thumbnail;

export const getVariants = config => config?.variants || [];
export const getFields = config => config?.fields || [];

export const getVariantName = variant => variant?.name;
export const getVariantOccasion = variant => variant?.occasion;
export const getVariantDisabled = variant => variant?.disabled;
export const getVariantThumbnail = variant => variant?.thumb;
export const getVariantVideoThumbnail = variant => variant?.videoThumb;
export const getVariantGifThumbnail = variant => variant?.gifThumb;
export const getVariantLabel = variant => variant?.label;
export const getVariantTransition = variant => variant?.transition;
export const getVariantView = variant => variant?.view;
export const getVariantColour = variant => variant?.colour;
export const getVariantExportedId = variant => variant?.exportedId;

export const getVariantRelatedCards = config => config?.relatedCards;
export const getVariantRelatedCardIds = config => config?.relatedCardIds || [];
export const getVariantThemes = variant => variant?.themes || [];
export const getVariantDefaultThemeOutputs = theme => {
  return theme?.outputs;
};
export const getVariantDefaultThemeIndex = variant =>
  variant?.defaultThemeIndex || 0;

export const getVariantTheme = (variant, id) => {
  const themes = getVariantThemes(variant);
  return (
    themes[id] ||
    themes.find(t => {
      return getThemeId(t) === id || getVariantExportedId(t) === id;
    })
  );
};
export const getVariantDefaultTheme = variant => {
  const variantDefaultId = getVariantDefaultThemeIndex(variant);
  return getVariantTheme(variant, variantDefaultId);
};

export const getVariantExportedContent = variant =>
  variant?.exportedContent || {};
export const getVariantSku = variant =>
  getVariantExportedContent(variant)?.exported_sku;
export const getVariantCardTheme = variant =>
  getVariantExportedContent(variant)?.card_theme;

export const getExportedContentId = exportedContent =>
  exportedContent?.exported_id;
export const getExportedContentCardTheme = exportedContent =>
  exportedContent?.card_theme;

export const getFieldKey = field => field?.key;
export const getFieldType = field => field?.type;
export const getFieldLabel = field => field?.label;
export const getFieldPlaceholder = field => field?.placeholder;
export const getFieldIcon = field => field?.icon;
export const getFieldMediaType = field => field?.mediaType;
export const getFieldValidation = field => field?.validation;
export const getFieldVariants = field => field?.variants;
export const getFieldStyle = field => field?.style;
export const getFieldOptions = field => field?.options;
export const getFieldDefault = field => field?.defaultValue;

export const getThemeId = theme =>
  theme?.id || getThemeName(theme) || getExportedContentId(theme);
export const getThemeColours = theme =>
  theme?.themeColours || theme?.theme_colours || [];
export const getThemeName = theme => theme?.name;

export const getValidationBySlideType = (config, slideType) => {
  const isMusic = slideType === slideTypes.MUSIC;
  const isGif = slideType === slideTypes.GIF;
  let inputType = inputTypes.MEDIA;
  if (isMusic) {
    inputType = inputTypes.MUSIC;
  } else if (isGif) {
    inputType = inputTypes.GIF;
  }

  const mediaType = slideSelectors.getMediaTypeForSlide(slideType);

  // find out what kind of input is specified in the config
  const field = getFields(config).find(field => {
    if (getFieldType(field) === inputType) {
      if (isMusic || isGif || getFieldMediaType(field) === mediaType) {
        return true;
      }
    }
    return false;
  });

  return getFieldValidation(field);
};
