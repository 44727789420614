/* Libraries */
import React, { createContext } from "react";
import PropTypes from "prop-types";
import useLibraryUpload from "hooks/useLibraryUpload";
/* -Libraries */

export const MediaUploadContext = createContext();

export const MediaUploadContextProvider = props => {
  const { storyId } = props;
  // mediaUploadProps = {
  // cancelUpload,
  // logCaptureSelected,
  // logUploadSelected,
  // processing,
  // setUploadError,
  // setUploadMediaType,
  // setUploadValidation,
  // uploadCredentials,
  // uploadError,
  // uploadLibraryMedia,
  // uploadsInProgress,
  // uploadMediaType,
  // uploadProgress,
  // uploadValidation,
  // }
  const mediaUploadProps = useLibraryUpload(storyId);

  return (
    <MediaUploadContext.Provider value={mediaUploadProps}>
      {props.children}
    </MediaUploadContext.Provider>
  );
};

MediaUploadContextProvider.propTypes = {
  children: PropTypes.element,
  storyId: PropTypes.string,
};
