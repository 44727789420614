export const viewAs = {
  grid_2x: "grid_2x",
  grid_3x: "grid_3x",
  list: "list",
};

export const analyticsNames = {
  [viewAs.grid_2x]: "large",
  [viewAs.grid_3x]: "small",
  [viewAs.list]: "list",
};

export const getKindeosViewAs = state => {
  return state.preferences?.view_as_kindeos;
};

export const getSlidesViewAs = state => {
  return state.preferences?.view_as_slides;
};

export const getGroupSlidesViewAs = state => {
  return state.preferences?.view_as_group_slides;
};
