/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import { getCurrentRole } from "./selectors";
import { roleTokenTypes } from "redux/roles/selectors";
import * as rolesSelectors from "redux/roles/selectors";
import { storyTokenTypes } from "redux/story/selectors";
/* -Selectors */

/* Actions */
import { actions as storyActions } from "redux/story/actions";
/* -Actions */

export const actions = {
  currentRoleFetched: createAction("CURRENTROLE_FETCH_SUCCESS"),
  clearRole: createAction("CURRENTROLE_CLEAR"),
};

const getRole = () => (dispatch, getState) => getCurrentRole(getState());

const fetchCurrentRole = (tokenType, token, joinToken) => dispatch => {
  return axios
    .get(
      `/story/${tokenType}/${token}/role${
        joinToken ? `?${roleTokenTypes.joinToken}=${joinToken}` : ""
      }`
    )
    .then(response => {
      const { role, ...story } = response.data.story;
      dispatch(actions.currentRoleFetched(role));
      dispatch(storyActions.updateLocalStory(story));
      return { role, story };
    });
};

export const fetchOwnerCurrentRole = token => dispatch => {
  return dispatch(fetchCurrentRole("id", token));
};

export const fetchRecipientCurrentRole = (token, joinToken) => dispatch => {
  return dispatch(
    fetchCurrentRole(storyTokenTypes.recipientToken, token, joinToken)
  );
};

export const fetchGroupCurrentRole = (token, joinToken) => dispatch => {
  return dispatch(
    fetchCurrentRole(storyTokenTypes.groupToken, token, joinToken)
  );
};

// ensure a verified role invite is valid
export const verifyJoinTokenRole = token => dispatch => {
  return axios
    .get(`/role/${roleTokenTypes.joinToken}/${token}`)
    .then(result => {
      return result.data.role;
    });
};

// request a role with a particular type
const requestRole = (tokenType, token) => dispatch => {
  return axios.post(`/story/${tokenType}/${token}/role`).then(result => {
    const { role } = result.data;

    dispatch(actions.currentRoleFetched(role));
    return role;
  });
};
export const requestContributorRole = token => dispatch => {
  return dispatch(requestRole(storyTokenTypes.groupToken, token));
};
export const requestRecipientRole = token => dispatch => {
  return dispatch(requestRole(storyTokenTypes.recipientToken, token));
};

// load a specified role into current role
export const setCurrentRole = role => dispatch => {
  dispatch(actions.currentRoleFetched(role));
};

export const clearCurrentRole = () => dispatch => {
  dispatch(actions.clearRole());
};

const updateRoleDetails = (roleId, payload) => dispatch => {
  return axios.put(`/story/role/${roleId}`, payload).then(result => {
    const { role } = result.data;
    dispatch(setCurrentRole(role));
    return role;
  });
};

// Mark a Kindeo as having been watched by the recipient if this user is the recipient
export const markKindeoAsWatchedByRecipient = () => dispatch => {
  const currentRole = dispatch(getRole());

  if (
    rolesSelectors.getRoleIsRecipient(currentRole) &&
    rolesSelectors.getRoleIsActive(currentRole)
  ) {
    return dispatch(
      updateRoleDetails(rolesSelectors.getId(currentRole), {
        has_watched: true,
      })
    );
  }

  return Promise.reject("User is not a recipient for this story");
};
