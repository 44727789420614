/* Libraries */
import { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
/* -Libraries */

/* Actions */
import { fetchProfile } from "redux/account/actions";
import { trackProfile } from "redux/analytics/actions";
import { actions, initAutoRenew, setAuthHeader } from "redux/auth/actions";
import { acceptCookies } from "redux/cookie/actions";
import { createError, resetErrors } from "redux/errors/actions";
import { fetchMediaLimits } from "redux/media/actions";
import { getPaymentSettings } from "redux/payment/actions";
import { fetchPromptsAnonymous } from "redux/prompts/actions";
/* -Actions */

/* Hooks */
import useIsMounted from "./useIsMounted";
/* -Hooks */

/* Selectors */
import { errorTypes } from "redux/errors/selectors";
/* -Selectors */

import * as apiUtils from "utils/apiUtils";

const useInitNetwork = () => {
  const dispatch = useDispatch();
  const [networkInit, setNetworkInit] = useState(false);

  useEffect(() => {
    // Set the base URL for the API...
    axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;

    // Prevent caching in IE11 – https://stackoverflow.com/questions/45830531
    axios.defaults.headers.common["Pragma"] = "no-cache";

    dispatch(initAutoRenew());
    setNetworkInit(true);
  }, [dispatch]); // called only on mount

  return networkInit;
};

const useInitAuth = ({ authAccessToken, authUsername, networkInitialised }) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const [apiAuthed, setApiAuthed] = useState(false);
  const [profileFetched, setProfileFetched] = useState(false);

  // if the user was previously logged in
  const havePreviousCredentials = authAccessToken && authUsername;

  // set the authentication header on API requests
  useEffect(() => {
    if (networkInitialised) {
      setAuthHeader(authAccessToken);
      setApiAuthed(true);
    }
  }, [authAccessToken, networkInitialised]);

  // get the user profile from the API
  useEffect(() => {
    const fetchProfileData = () => {
      return dispatch(fetchProfile())
        .then(() => {
          if (isMounted()) {
            setProfileFetched(true);

            // automatically accept cookies if authenticated
            dispatch(acceptCookies());
            dispatch(trackProfile());

            // clear any previous network error
            dispatch(resetErrors());
          }
        })
        .catch(e => {
          const setupFailStatus = apiUtils.getErrorStatus(e);
          // if a data/auth error from the API, force a logout
          if ([400, 401, 403].includes(setupFailStatus)) {
            dispatch(actions.logoutInit());
          }
          // otherwise handle the error and poll again
          else {
            dispatch(
              createError({ type: errorTypes.server, source: "app setup" })
            );
            window.setTimeout(fetchProfileData, 5000);
          }
        });
    };

    if (apiAuthed) {
      if (authUsername) {
        fetchProfileData();
      } else {
        dispatch(fetchPromptsAnonymous());
      }
    }
  }, [apiAuthed, authUsername, dispatch, isMounted]);

  // get the media size limits from the API
  useEffect(() => {
    if (apiAuthed) {
      dispatch(fetchMediaLimits());
    }
  }, [apiAuthed, authUsername, dispatch]);

  return (
    // if the user was never logged in
    !havePreviousCredentials ||
    // or if the user was logged in and we've authenticated with the API
    (havePreviousCredentials && apiAuthed && profileFetched)
  );
};

// attach ReactModal elements to the root
const useAttachModalsToDom = () => {
  const [modalsAttached, setModalsAttached] = useState(false);

  useEffect(() => {
    Modal.setAppElement(`#${process.env.REACT_APP_ROOT_ELEMENT}`);
    setModalsAttached(true);
  }, []);

  return modalsAttached;
};

const useFetchPaymentSettings = authInitialised => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (authInitialised) {
      dispatch(getPaymentSettings());
    }
  }, [authInitialised, dispatch]);
};

const useAppSetup = ({ authAccessToken, authUsername }) => {
  const [setupComplete, setSetupComplete] = useState(false);
  const networkInitialised = useInitNetwork();
  const authInitialised = useInitAuth({
    authAccessToken,
    authUsername,
    networkInitialised,
  });
  const modalsAttached = useAttachModalsToDom();

  // don't need to hold up the app setup complete flag for this
  useFetchPaymentSettings(authInitialised);

  if (
    !setupComplete &&
    networkInitialised &&
    authInitialised &&
    modalsAttached
  ) {
    setSetupComplete(true);
  }

  return setupComplete;
};

export default useAppSetup;
