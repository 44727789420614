/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as actions from "./actions";
/* -Actions */

const INITIAL_STATE = {
  app: true,
  page: false,
  mediaPool: false,
  animation: null,
};

export const setAppLoader = (state, { payload }) => ({
  ...state,
  app: payload,
});

export const setPageLoader = (state, { payload }) => ({
  ...state,
  page: payload,
});

export const setMediaPoolLoaded = state => ({
  ...state,
  mediaPool: true,
});

export const setLoaderAnimation = (state, { payload }) => ({
  ...state,
  animation: payload,
});

export default createReducer(INITIAL_STATE, {
  [actions.setLoaderAnimation]: setLoaderAnimation,
  [actions.setAppLoader]: setAppLoader,
  [actions.setPageLoader]: setPageLoader,
  [actions.setMediaPoolLoaded]: setMediaPoolLoaded,
});
