import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import thumbnail from "images/slides/thumbnails/photos.png";

const type = slideTypes.CREDITS;
const creditsVariants = slideVariants[type];

export default {
  type,
  icon: "camera",
  label: "Credits",
  colour: "credits",
  thumbnail,
  variants: [
    {
      name: creditsVariants.DEFAULT,
      label: "Default",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Created by",
      placeholder: "Add your name here",
      validation: {
        maxLength: {
          value: 40,
          message: `Please keep this to 40 characters or less`,
        },
      },
    },
    {
      key: "description",
      type: inputTypes.TAGLIST,
      label: "Who contributed?",
      placeholder: "e.g. Tina",
      delimiter: "±±",
      validation: {
        maxLength: {
          value: 40,
        },
      },
    },
  ],
};
