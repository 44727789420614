/* Libraries */
import React, { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
/* -Libraries */

export const THEMES = {
  WORK: "work",
};

export const ThemeContext = createContext();

export const ThemeContextProvider = props => {
  const [theme, setTheme] = useState();

  // Assign React state and constants to context object
  const ThemeContextObject = useMemo(() => {
    return {
      isWork: theme === THEMES.WORK,
      setTheme,
      theme,
    };
  }, [theme]);

  return (
    <ThemeContext.Provider value={ThemeContextObject}>
      {props.children}
    </ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node,
};
