import mapDesign18thc from "../../../../../images/slides/map/design-18thc.png";
// import mapDesignA2z from "../../../../../images/slides/map/design-a2z.png";
// import mapDesignBlueprint from "../../../../../images/slides/map/design-blueprint.png";
import mapDesignSatellite from "../../../../../images/slides/map/design-satellite.png";
import mapDesignPrint from "../../../../../images/slides/map/design-print.png";

export const mapDesigns = [
  {
    name: "print",
    icon: mapDesignPrint,
    themeId: "ck6qime631yw21is5dbh4gak6",
    label: "Print",
  },
  // {
  //   name: "a2z",
  //   icon: mapDesignA2z,
  //   themeId: "ck892zpjb0rga1inuby137xnc",
  //   label: "A-to-Z",
  // },
  // {
  //   name: "blueprint",
  //   icon: mapDesignBlueprint,
  //   themeId: "ckyx5qrun000j15ptxbu52eob",
  //   label: "Blueprint",
  // },
  {
    name: "18thc",
    icon: mapDesign18thc,
    themeId: "ckyyjidzl000h14qoslllx5ht",
    label: "Historic",
  },
  {
    name: "satellite",
    icon: mapDesignSatellite,
    themeId: "cl1algbuk004t14ukgq0gm994",
    label: "Satellite",
  },
];
