/* Libraries */
import React, { useCallback, useEffect, useMemo, useState } from "react";
/* -Libraries */

/* Components */
import {
  AccountLockedContent,
  AuthCodeContent,
  EmailContent,
  LinkSentContent,
  NameContent,
  PrivacyContent,
  TermsContent,
} from "components/AuthFlow";
import IconBackButton from "components/IconBackButton";
import Modal from "components/Modal";
import ModalContents from "components/ModalContents";
import ModalHeader from "components/ModalHeader";
import ModalPanels from "components/ModalPanels";
import TermsAgreement from "components/TermsAgreement";
/* -Components */

/* Selectors */
import { AUTH_PANELS, authScenarios, verifyTypes } from "redux/auth/selectors";
/* -Selectors */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useModalDisplay from "hooks/useModalDisplay";
import useModalPanelAnimation from "hooks/useModalPanelAnimation";
import useModals from "hooks/useModals";
import useSetAuthScenario from "hooks/useSetAuthScenario";
/* -Hooks */

import styles from "./index.module.scss";

import { modals } from "routes/ModalRoutes";
import classNames from "classnames";

const panelNames = [
  AUTH_PANELS.EMAIL,
  AUTH_PANELS.NAME,
  AUTH_PANELS.LINK_SENT,
  AUTH_PANELS.VERIFY_CODE,
  AUTH_PANELS.LOCKED,
  AUTH_PANELS.TERMS,
  AUTH_PANELS.PRIVACY,
];

// In params, forwardTo is applicable when the user has authed using a code
// and retained the same session
const AuthModal = props => {
  const { isOpen: isOpenParam, onClose, params, restProps } = props;
  const { authScenario } = params;

  // set the authScenario in anonymous data
  // so that we can refer to it after login using code
  useSetAuthScenario(authScenario);

  const isLoggedIn = useAuthStatus();
  const { getModal } = useModals();
  const [openParamSupplied] = useState(isOpenParam !== undefined);
  const { doClose, duration, isOpen } = useModalDisplay(
    openParamSupplied ? isOpenParam : true
  );
  const preventClose = params.persist;
  const authFailed = params.authFailed;
  let verificationType = "auth";
  if (params.verify_type === verifyTypes.LOGIN) {
    verificationType = "login";
  } else if (params.verify_type === verifyTypes.REGISTER) {
    verificationType = "registration";
  }

  const [registration, setRegistration] = useState(false);
  const [modalTitle, setModalTitle] = useState("Sign up or log in");

  const {
    animationComplete,
    clipOverflow,
    defaultPanel,
    currentPanel,
    goTo,
    onComplete,
  } = useModalPanelAnimation(panelNames);

  // don't need to show this if we are logged in
  const closeModal = useCallback(() => {
    onClose && onClose();
    if (!openParamSupplied) {
      doClose();
    }
  }, [doClose, onClose, openParamSupplied]);

  useEffect(() => {
    if (isLoggedIn) {
      if (currentPanel !== AUTH_PANELS.VERIFY_CODE) {
        closeModal();
      }
    }
  }, [closeModal, currentPanel, isLoggedIn]);

  // set the modal title based on the current panel
  useEffect(() => {
    switch (currentPanel) {
      case AUTH_PANELS.EMAIL:
        let modalTitle = "Welcome";
        if (authFailed) {
          modalTitle = `Your ${verificationType} link expired`;
        } else if (
          [
            authScenarios.ANON_SAVE_EXIT,
            authScenarios.CREATOR_PAYMENT,
            authScenarios.CREATOR_SEND,
            authScenarios.CREATOR_SETTINGS,
          ].includes(authScenario)
        ) {
          modalTitle = "Sign in or register";
        }
        setModalTitle(modalTitle);
        break;
      case AUTH_PANELS.NAME:
        setModalTitle(registration ? "Welcome to Kindeo!" : "Check your email");
        break;
      case AUTH_PANELS.LINK_SENT:
        setModalTitle("Check your email");
        break;
      case AUTH_PANELS.VERIFY_CODE:
        setModalTitle("Check your email");
        break;
      case AUTH_PANELS.LOCKED:
        setModalTitle("Account locked");
        break;
      case AUTH_PANELS.TERMS:
        setModalTitle("Terms of Service");
        break;
      case AUTH_PANELS.PRIVACY:
        setModalTitle("Privacy Policy");
        break;

      default:
        break;
    }
  }, [authFailed, authScenario, currentPanel, registration, verificationType]);

  const goToEmail = useCallback(() => {
    goTo(AUTH_PANELS.EMAIL);
  }, [goTo]);
  const goToName = useCallback(() => {
    setRegistration(true);
    goTo(AUTH_PANELS.NAME);
  }, [goTo]);
  const goToLinkSent = useCallback(() => {
    goTo(AUTH_PANELS.LINK_SENT);
  }, [goTo]);
  const goToVerifyCode = useCallback(() => {
    goTo(AUTH_PANELS.VERIFY_CODE);
  }, [goTo]);
  const goToLocked = useCallback(() => {
    goTo(AUTH_PANELS.LOCKED);
  }, [goTo]);
  const goToTerms = useCallback(() => {
    goTo(AUTH_PANELS.TERMS);
  }, [goTo]);
  const goToPrivacy = useCallback(() => {
    goTo(AUTH_PANELS.PRIVACY);
  }, [goTo]);

  // allow links to privacy or terms modals to actually switch modal panels here
  const linkDelegate = e => {
    const url = e.currentTarget.href;

    const [, query] = url.split("?");
    if (query) {
      const { modal } = getModal(query);
      // if this is a modal link we want to show in-situ, prevent default
      if ([modals.privacy(), modals.terms()].includes(modal)) {
        e.preventDefault();
        switch (modal) {
          case modals.privacy():
            goToPrivacy();
            break;
          case modals.terms():
            goToTerms();
            break;
          default:
            break;
        }
      }
    }
  };

  const onRegistered = useCallback(() => {}, []);

  const commonPanelProps = useMemo(() => {
    return {
      autoFocus: animationComplete,
      params,
      registration,
    };
  }, [animationComplete, params, registration]);

  return (
    <Modal
      trackingId="Auth Modal"
      {...{
        doClose: closeModal,
        duration,
        isOpen: openParamSupplied ? isOpenParam : isOpen,
      }}
      {...restProps}
      shouldCloseOnOverlayClick={!preventClose}
      clipped={clipOverflow}
      narrow
      modalOnModal={openParamSupplied}
    >
      <ModalHeader
        className={styles.headerAuth}
        title={modalTitle}
        onClose={!preventClose ? closeModal : null}
        renderBackButton={({ className }) =>
          currentPanel !== defaultPanel && (
            <IconBackButton
              className={classNames(className, styles.backButtonAuth)}
              icon={"arrow-left"}
              onClick={goToEmail}
              text=""
            />
          )
        }
      />
      <ModalContents expand>
        <div className="is-relative pb-1">
          <ModalPanels
            {...{
              panelNames,
              currentPanel,
              goTo,
              onComplete,
            }}
          >
            <EmailContent
              {...commonPanelProps}
              {...{ goToLinkSent, goToLocked, goToName, setRegistration }}
              closeAuthFlow={closeModal}
            >
              <TermsAgreement
                id="terms-agreement"
                className="mt-2 has-text-centered"
                onClick={linkDelegate}
              />
            </EmailContent>
            <NameContent
              {...commonPanelProps}
              {...{ goToEmail, goToLinkSent, goToLocked, onRegistered }}
            >
              <TermsAgreement
                id="terms-agreement"
                className="mt-2 has-text-centered"
                onClick={linkDelegate}
              />
            </NameContent>
            <LinkSentContent {...commonPanelProps} {...{ goToVerifyCode }} />
            <AuthCodeContent
              {...commonPanelProps}
              {...{ goToEmail, goToLocked }}
              closeAuthFlow={closeModal}
            />
            <AccountLockedContent {...commonPanelProps} {...{ goToEmail }} />
            <TermsContent {...commonPanelProps} {...{ linkDelegate }} />
            <PrivacyContent {...commonPanelProps} {...{ linkDelegate }} />
          </ModalPanels>
        </div>
      </ModalContents>
    </Modal>
  );
};

export default React.memo(AuthModal);
