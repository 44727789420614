/* Libraries */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import classnames from "classnames";
/* -Libraries */

/* Components */
import Loader from "components/Loader";
/* -Components */

/* Selectors */
import * as loaderSelectors from "redux/loaders/selectors";
/* -Selectors */

/* Hooks */
import usePageClipping from "hooks/usePageClipping";
/* -Hooks */

import styles from "./index.module.scss";

const selector = createSelector(loaderSelectors.isLoading, isLoading => {
  return {
    isLoading,
  };
});

const SiteLoader = props => {
  const { isLoading } = useSelector(selector);

  const clipPage = usePageClipping("loader");
  useEffect(() => {
    // don't restore scroll position on mobile when loader is removed
    clipPage(isLoading, false);
  }, [clipPage, isLoading]);

  return (
    <div
      className={classnames("is-fixed-fill", styles.siteLoader, {
        "is-pointer-events-none": !isLoading,
      })}
    >
      <Loader visible={isLoading} size="large" />
    </div>
  );
};

export default React.memo(SiteLoader);
