// This seemingly unneccessary hook adds a class to the body which
// allows us to identify touchscreens that should not have hover
// states.
// iOS does, and can apply it to DOM elements underneath the one receiving
// touch events, which is not what we want.

import { useEffect } from "react";
import { addRootClass, hasRootClass } from "utils/domUtils";

const noHover = "no-hover";

const usePreventTouchscreenHover = () => {
  useEffect(() => {
    var touchscreenHover = matchMedia("(hover: none)").matches;
    if (touchscreenHover) {
      if (!hasRootClass(noHover)) {
        addRootClass(noHover);
      }
    }
  }, []);
};

export default usePreventTouchscreenHover;
