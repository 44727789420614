/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as storyActions from "redux/story/actions";
/* -Actions */

/* Selectors */
import * as builderSelectors from "./selectors";
import * as slideSelectors from "redux/slide/selectors";
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

import analytics, { EVENTS } from "utils/analyticsUtils";
import { getErrorMessage } from "utils/apiUtils";

export const actions = {
  clearStoryBuilder: createAction("STORY_BUILDER_CLEAR"),
  setStoryId: createAction("STORY_BUILDER_STORYID_SET"),
  updateStory: createAction("STORY_BUILDER_UPDATE"),
};

export const updateStoryBuilder = storyProps => (dispatch, getState) => {
  const story = {
    ...builderSelectors.getStoryBuilder(getState()),
    ...storyProps,
  };
  dispatch(actions.updateStory(story));
};

export const createStoryFromBuilder = () => (dispatch, getState) => {
  const story = builderSelectors.getStoryBuilder(getState());
  const reminderId = builderSelectors.getReminderId(story);

  const submissionData = {
    occasion: builderSelectors.getStoryOccasionType(story),
    title_style: builderSelectors.getStyle(story),
    title_theme: builderSelectors.getStoryTitleTheme(story),
    title_variant: builderSelectors.getStoryTitleVariant(story),
    title_exported_id: builderSelectors.getStoryTitleExportedId(story),
    title_slide_data: builderSelectors.getSlideData(story),
    ...(reminderId ? { from_reminder_id: reminderId } : {}),
  };

  return axios
    .post(`/story`, submissionData)
    .then(response => {
      const { story } = response.data;
      dispatch(storyActions.actions.storyFetchSuccess(story));

      // remove storyBuilder data except for storyId
      dispatch(clearStoryBuilder());

      return story;
    })
    .catch(e => {
      analytics.error(EVENTS.errors.createCard, {
        message: getErrorMessage(e),
      });
      return Promise.reject(e);
    });
};

export const finishSetupFromBuilder = isLoggedIn => (dispatch, getState) => {
  const story = builderSelectors.getStoryBuilder(getState());

  const titleSlide = storySelectors.getTitleSlide(story);
  const exportedId = slideSelectors.getExportedId(titleSlide);
  const reminderId = storySelectors.getFromReminderId(story);
  analytics.event(EVENTS.create.kindeo, {
    type: storySelectors.getStoryOccasionType(story),
    is_preview: !isLoggedIn,
    title_variant: slideSelectors.getVariant(titleSlide),
    ...(exportedId ? { exported_cover: exportedId } : {}),
    ...(reminderId ? { from_reminder: true } : {}),
  });

  // remove all storyBuilder data
  dispatch(clearStoryBuilder());
  dispatch(setStoryId(null));

  return story;
};

export const setSlideData = (exportedId, slideData) => dispatch => {
  return dispatch(actions.setSlideData({ exportedId, slideData }));
};

export const setStoryId = storyId => dispatch => {
  return dispatch(actions.setStoryId(storyId));
};

export const clearStoryBuilder = () => dispatch => {
  return dispatch(actions.clearStoryBuilder());
};
