/* Libraries */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { Scrubber } from "react-scrubber";
/* -Libraries */

/* Selectors */
import { hdConstraints, mediaDeviceAvailable } from "redux/media/selectors";
import * as mediaSelectors from "redux/media/selectors";
/* Selectors */

/* Components */
import { BadgeText } from "components/_v2/BadgeText";
import { InverseButton, TextButton } from "components/_v2/Button";
// import CaptureMask from "components/CaptureMask";
import Collapse from "components/Collapse";
import DeleteRecordingModal from "modals/DeleteRecordingModal";
import Icon from "components/Icon";
import IconBackButton from "components/IconBackButton";
import IconButton from "components/IconButton";
import IconCloseButton from "components/IconCloseButton";
/* -Components */

/* Hooks */
import useCountdown from "hooks/useCountdown";
import useInterval from "hooks/useInterval";
import useIsMounted from "hooks/useIsMounted";
import useProcessingProgress from "hooks/useProcessingProgress";
import useScrubber from "hooks/useScrubber";
/* -Hooks */

/* Context */
import { MediaUploadContext } from "context/MediaUploadContext";
import { SlideBuilderContext } from "context/SlideBuilderContext";
/* -Context */

import { noop, shouldUseMp4Playback } from "utils/clientUtils";
import { secondsToHMS } from "utils/numberUtils";

import styles from "./index.module.scss";
import "react-scrubber/lib/scrubber.css";

import analytics, { EVENTS } from "utils/analyticsUtils";

const STATES = {
  IDLE: 1,
  COUNTDOWN: 2,
  RECORDING: 3,
  PLAYBACK: 4,
  PREPARE_RECORDING: 6,
  ERROR: 7,
  ERROR_FATAL: 8,
};

const BUTTON_ICONS = {
  [STATES.ERROR]: "record",
  [STATES.ERROR_FATAL]: "cross",
  [STATES.IDLE]: "video-record",
  [STATES.PLAYBACK]: "player-skip",
  [STATES.RECORDING]: "icon-player-stop",
};

const captureContraints = {
  audio: true,
  ...hdConstraints,
};
const format = shouldUseMp4Playback() ? "mp4" : "webm";

/** not super proud of this, but wondering how we can do the React way, this is called onunmount, and React doesn't like to use reference during unmount */
let currentStreams;

const removeStreams = () => {
  if (currentStreams) {
    currentStreams.getTracks().forEach(track => {
      track.stop();
      track = null;
    });
    currentStreams = null;
  }
};

const VideoCapture = props => {
  const { onBack, onContinue, onDiscard, onUpload } = props;

  const { processing, uploadsInProgress, uploadLibraryMedia, uploadProgress } =
    useContext(MediaUploadContext);
  const { onRequestModalClose } = useContext(SlideBuilderContext);

  const isMounted = useIsMounted();

  const [currentState, setCurrentState] = useState(STATES.IDLE);
  const [capturedMediaUrl, setCapturedMediaUrl] = useState(null);
  const [ready, setReady] = useState(null);
  const [addedMedia, setAddedMedia] = useState();
  const [durationRecording, setDurationRecording] = useState(0);
  const [replaying, setReplaying] = useState(false);
  const [sourceCropped, setSourceCropped] = useState(null);
  const sourceCropDetected = sourceCropped !== null;

  const containerRef = useRef();
  const blobRef = useRef();
  const replayRef = useRef();
  const webcamRef = useRef();
  const stateRef = useRef();
  const mediaRecorderRef = useRef(null);

  // Analytics
  useEffect(() => {
    analytics.event(EVENTS.slideBuilder.videoCaptureShown);
  }, []);

  /**
   * ERROR Management
   */
  const [error, setError] = useState(null);
  const setErrorMessage = message => {
    const msgLower = message.toLowerCase();
    setCurrentState(STATES.ERROR);
    if (msgLower.indexOf("permission") + msgLower.indexOf("not allowed") > -1) {
      setError(
        "We’re having a problem accessing your webcam. Please allow access to the webcam and reload this page to try again."
      );
    } else if (
      msgLower.indexOf("media device") + msgLower.indexOf("not found") >
      -1
    ) {
      setError("We can’t find a webcam.");
      setCurrentState(STATES.ERROR_FATAL);
    } else {
      setError("Something went wrong when we tried to find your webcam.");
    }
  };

  /**
   * Countdown
   */
  const onCountdownReady = useCallback(() => {
    if (stateRef.current === STATES.COUNTDOWN) {
      setCurrentState(STATES.RECORDING);
    }
  }, []);
  const {
    count: countdownValue,
    start: startCountdown,
    clear: clearCountdown,
  } = useCountdown({
    nb: 3,
    ms: 1000,
    onReady: onCountdownReady,
  });

  /**
   * Recording, upload, etc.
   */
  const upload = useCallback(
    files => {
      return uploadLibraryMedia(files)
        .then(media => {
          setAddedMedia(media);
          return onUpload ? onUpload(media) : Promise.resolve(media);
        })
        .catch(err => {
          onBack();
          return null;
        });
    },
    [onBack, onUpload, uploadLibraryMedia]
  );

  // when we start the recording, show the recording duration
  useInterval(
    () => {
      setDurationRecording(durationRecording + 1);
    },
    currentState === STATES.RECORDING ? 1000 : null
  );

  const startRecording = useCallback(() => {
    if (
      stateRef.current === STATES.RECORDING ||
      stateRef.current === STATES.COUNTDOWN
    ) {
      return;
    }

    setError(null);
    setCurrentState(STATES.COUNTDOWN);
    setDurationRecording(0);

    navigator.mediaDevices.getUserMedia(captureContraints).then(mediaStream => {
      const video = webcamRef.current;
      video.srcObject = mediaStream;
      video.onloadedmetadata = () => {
        video.play();
      };
    });

    mediaRecorderRef.current = new window.MediaRecorder(
      webcamRef.current.srcObject,
      {
        mimeType: `video/${format}`,
      }
    );

    // recording stops
    mediaRecorderRef.current.addEventListener("dataavailable", e => {
      if (stateRef.current !== STATES.RECORDING) return;
      setCurrentState(STATES.PLAYBACK);

      blobRef.current = new Blob([e.data], {
        type: "video/mp4",
      });

      // get content as blob and upload it as a file
      const now = Date.now();
      upload(
        new File([blobRef.current], `upload${now}.mp4`, { type: "video/mp4" })
      );

      // set the URL for the <video> element used as a replay
      setCapturedMediaUrl(URL.createObjectURL(blobRef.current));
    });
  }, [upload]);

  const stopRecording = useCallback(() => {
    if (!mediaRecorderRef.current) return;
    if (mediaRecorderRef.current?.state !== "inactive") {
      mediaRecorderRef.current.stop();
    }
    mediaRecorderRef.current.stream.getTracks().forEach(track => {
      track.stop();
      track = null;
    });
    mediaRecorderRef.current = null;

    // Analytics
    analytics.event(EVENTS.slideBuilder.videoCaptureEnded);
  }, []);

  const getMediaStream = useCallback(() => {
    if (mediaDeviceAvailable) {
      if (sourceCropDetected) {
        return navigator.mediaDevices
          .getUserMedia(captureContraints)
          .then(stream => {
            if (currentStreams) {
              // clear existing streams
              removeStreams();
            }

            currentStreams = stream;
            if (isMounted() && webcamRef.current) {
              webcamRef.current.srcObject = stream;

              return new Promise(
                resolve => (webcamRef.current.onplaying = resolve)
              ).then(() => {
                if (isMounted()) {
                  return new Promise(res => {
                    const recorder = new window.MediaRecorder(
                      webcamRef.current.srcObject,
                      {
                        mimeType: `video/${format}`,
                      }
                    );
                    recorder.start();
                    recorder.onstart = () => {
                      recorder.stop();
                      res();
                    };
                  });
                }
              });
            } else {
              removeStreams();
            }
          })
          .catch(e => {
            setErrorMessage(e.message);
          });
      }
    } else {
      setErrorMessage("no media device");
    }
  }, [isMounted, sourceCropDetected]);

  const startVideoCapture = useCallback(() => {
    mediaRecorderRef.current.start();
    analytics.event(EVENTS.slideBuilder.videoCaptureStarted);
  }, []);

  const cancel = useCallback(() => {
    if (!!addedMedia) {
      addedMedia.map(m => onDiscard(m));
    } else {
      onDiscard();
    }
    setAddedMedia(null);
  }, [onDiscard, addedMedia]);

  /**
   * state management, when currentState changes, do things
   */
  useEffect(() => {
    if (currentState === stateRef.current || !sourceCropDetected) return;

    stateRef.current = currentState;

    if (currentState !== STATES.COUNTDOWN) {
      clearCountdown();
    }

    if (currentState === STATES.IDLE || currentState === STATES.ERROR) {
      // RESET state
      !!capturedMediaUrl && window.URL.revokeObjectURL(capturedMediaUrl);
      setCapturedMediaUrl(null);
      setReplaying(false);
      stopRecording();
      setReady(false);
      // get the media stream to show the user what's recording
      getMediaStream()?.then(() => {
        if (isMounted()) {
          setReady(true);
        } else {
          // manage asynchronous possibility
          removeStreams();
        }
      });
    } else if (currentState === STATES.PREPARE_RECORDING) {
      startRecording(); // will create the MediaRecorder and start the countdown
    } else if (currentState === STATES.COUNTDOWN) {
      startCountdown();
    } else if (currentState === STATES.RECORDING) {
      startVideoCapture();
    }
  }, [
    capturedMediaUrl,
    clearCountdown,
    currentState,
    getMediaStream,
    isMounted,
    sourceCropDetected,
    startCountdown,
    startRecording,
    startVideoCapture,
    stopRecording,
  ]);

  // called at the beginning, to check if everythig is ready and working properly
  useEffect(() => {
    if (mediaDeviceAvailable) {
      navigator.mediaDevices
        .getUserMedia(captureContraints)
        .then(stream => {
          const settings = stream.getVideoTracks()[0].getSettings();
          setSourceCropped(settings.resizeMode === "crop-and-scale");

          stream.getTracks().forEach(track => {
            track.stop();
            track = null;
          });
          stream = null;
        })
        .catch(({ message }) => {
          setErrorMessage(message);
        });
    } else {
      setErrorMessage("no media device");
    }

    // Not showing vendor prefixes.
    return () => {
      // CLEAN UP EVERYTHING (manually because the useEffects are not updating at this point)
      stateRef.current = null;
      blobRef.current = null;
      clearCountdown();
      stopRecording();
      removeStreams();
    };
  }, [clearCountdown, stopRecording]);

  /**
   * UI management
   */
  // MAIN
  const onClickClose = useCallback(() => {
    if (currentState === STATES.PLAYBACK) {
      setShowDeleteModal({
        title: "Are you sure you want to close?",
        action: () => {
          cancel();
          onRequestModalClose();
        },
      });
    } else {
      onRequestModalClose();
    }
  }, [cancel, currentState, onRequestModalClose]);

  const onClickBack = useCallback(() => {
    if (currentState === STATES.PLAYBACK) {
      setShowDeleteModal({
        title: "Are you sure you want to leave?",
        action: () => {
          cancel();
          onBack();
        },
      });
    } else {
      onBack();
    }
  }, [cancel, currentState, onBack]);

  // REPLAY
  const togglePlay = useCallback(() => {
    setReplaying(!replaying);
  }, [replaying]);

  useEffect(() => {
    if (!capturedMediaUrl) return;

    if (replaying) {
      replayRef.current.play().catch(noop);
    } else {
      replayRef.current.pause();
    }
  }, [replaying, capturedMediaUrl]);

  const restart = useCallback(() => {
    setShowDeleteModal({
      action: () => {
        setShowDeleteModal({});
        cancel();
        replayRef.current.pause();
        replayRef.current.currentTime = 0;
        setCurrentState(STATES.IDLE);
      },
      title: "Delete this recording?",
    });
  }, [cancel]);

  // manage the main button click and what it should be doing
  const onMainButtonClicked = useCallback(() => {
    if (currentState === STATES.IDLE || currentState === STATES.ERROR) {
      setCurrentState(STATES.PREPARE_RECORDING);
    } else if (currentState === STATES.RECORDING) {
      stopRecording();
    } else if (currentState === STATES.COUNTDOWN) {
      setCurrentState(STATES.IDLE);
    }
  }, [currentState, stopRecording]);

  const onVideoReplayEnded = useCallback(() => {
    if (replaying) {
      togglePlay();
    }
  }, [replaying, togglePlay]);

  /**
   * SCRUBBER
   */
  const { displayTime, scrubbing, ...scrubberParams } = useScrubber({
    duration: durationRecording,
    videoEl: replayRef.current,
    playing: replaying && currentState === STATES.PLAYBACK,
  });

  const [showDeleteModal, setShowDeleteModal] = useState({
    title: "",
    action: null,
  });

  const onConfirmDelete = useCallback(
    (...params) => {
      showDeleteModal.action(...params);

      // Analytics
      analytics.event(EVENTS.slideBuilder.videoCaptureDeleted);
    },
    [showDeleteModal]
  );

  const onConfirmCapture = useCallback(() => {
    onContinue();

    // Analytics
    analytics.event(EVENTS.slideBuilder.videoCaptureConfirmed);
  }, [onContinue]);

  return (
    <div className={styles.containerRecording}>
      {!!showDeleteModal.action && (
        <DeleteRecordingModal
          title={showDeleteModal.title || ""}
          message="This video will be deleted"
          onClose={setShowDeleteModal.bind(null, {})}
          onDelete={onConfirmDelete}
        />
      )}
      <div className={styles.headerRecording}>
        <div className={styles.backWrapper}>
          <IconBackButton
            className={styles.backButton}
            onClick={onClickBack}
            text="Back"
          />
        </div>

        <h3 className={classnames(styles.title, "h3 has-text-centered")}>
          Record video
        </h3>

        <IconCloseButton
          className={classnames(styles.close)}
          onClick={onClickClose}
        />
      </div>
      <div className={styles.containerVideo} ref={containerRef}>
        {/* {sourceCropDetected && (
          <CaptureMask sourceCropped={sourceCropped}> */}
        {currentState === STATES.PLAYBACK && (
          <video
            className={styles.videoEl}
            src={capturedMediaUrl}
            ref={replayRef}
            onEnded={onVideoReplayEnded}
          />
        )}
        <video
          ref={webcamRef}
          autoPlay
          muted
          className={classnames(styles.videoEl, {
            [styles.hidden]: currentState === STATES.PLAYBACK,
          })}
        ></video>
        {/* </CaptureMask>
        )} */}
        {!error && !ready && currentState === STATES.IDLE && (
          <div className={styles.placeholder}>
            <p>Accessing your camera...</p>
          </div>
        )}
      </div>
      <div className={styles.footerRecording}>
        {currentState === STATES.PLAYBACK ? (
          <>
            <div className={styles.containerButtons}>
              <div className={styles.buttonLeft}>
                <TextButton onClick={restart} className={styles.deleteButton}>
                  <Icon icon="bin" className="mr-05" size="24" />
                  Delete
                </TextButton>
              </div>

              <div className={styles.scrubberContainer}>
                <IconButton
                  className={styles.playPauseIcon}
                  onClick={togglePlay}
                  icon={replaying ? "icon-player-pause" : "icon-player-play"}
                  iconSize="22"
                />

                <Scrubber
                  value={displayTime}
                  className={classnames(styles.scrubber, {
                    [styles.animated]: !scrubbing,
                  })}
                  {...scrubberParams}
                />
                <span className={styles.playbackPosition}>
                  {secondsToHMS(scrubberParams.displayTime)}
                </span>
              </div>
              <div className={styles.buttonRight}>
                {uploadsInProgress === 0 && !processing && (
                  <InverseButton
                    className={styles.buttonProcessing}
                    colour="green5"
                    filled
                    onClick={onConfirmCapture}
                  >
                    Continue
                  </InverseButton>
                )}
                {uploadsInProgress > 0 && (
                  <UploadProgressButton
                    uploadProgress={uploadProgress}
                    onClick={onConfirmCapture}
                  />
                )}
                {processing &&
                  addedMedia?.length > 0 &&
                  mediaSelectors.getId(addedMedia[0]) && (
                    <ProcessingProgressButton
                      mediaId={mediaSelectors.getId(addedMedia[0])}
                      onClick={onConfirmCapture}
                      uploadProgress={uploadProgress}
                    />
                  )}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.containerRecordButton}>
            <InverseButton
              onClick={onMainButtonClicked}
              colour=""
              filled
              className={classnames(styles.toggleRecordingButton)}
              disabled={!ready || currentState === STATES.ERROR_FATAL}
            >
              {currentState === STATES.COUNTDOWN ? (
                <span className={classnames("h1")}>{countdownValue}</span>
              ) : BUTTON_ICONS[currentState] ? (
                <Icon
                  icon={BUTTON_ICONS[currentState]}
                  size="50"
                  className={classnames({
                    [styles.isRecordIcon]:
                      currentState === STATES.IDLE ||
                      currentState === STATES.ERROR,
                  })}
                />
              ) : null}
            </InverseButton>

            {currentState === STATES.RECORDING && (
              <span className={styles.nbSeconds}>
                {secondsToHMS(durationRecording)}
              </span>
            )}
          </div>
        )}
      </div>

      <Collapse show={!!error} className={styles.error}>
        <BadgeText type="error" text={error} />
      </Collapse>
    </div>
  );
};

const UploadProgressButton = React.memo(props => {
  const { uploadProgress } = props;

  return (
    <ProgressButton {...props}>Uploading ({uploadProgress}%)</ProgressButton>
  );
});

const ProcessingProgressButton = React.memo(props => {
  const { mediaId } = props;

  const { processingProgress, startProcessingProgress } =
    useProcessingProgress(mediaId);
  useEffect(() => {
    startProcessingProgress();
  }, [startProcessingProgress]);

  return (
    <ProgressButton {...props}>
      Processing ({processingProgress}%)
    </ProgressButton>
  );
});

const ProgressButton = React.memo(props => {
  const { children, onClick, uploadProgress } = props;

  return (
    <div className={styles.progressBar} onClick={onClick}>
      <div
        className={styles.progressBarFill}
        style={{ width: `${uploadProgress}%` }}
      />
      <span className="h7">{children}</span>
    </div>
  );
});

export default React.memo(VideoCapture);
