export const authTokenTypes = {
  accessToken: "access_token",
  refreshToken: "refresh_token",
};

export const authScenarios = {
  ANON_SAVE_EXIT: "anonymous_save_exit",
  BUNDLE_PAYMENT: "bundle_payment",
  CONTRIBUTION: "contribution",
  CREATOR_PAYMENT: "creator_payment",
  CREATOR_SAVE: "creator_save",
  CREATOR_SEND: "creator_send",
  CREATOR_SETTINGS: "creator_settings",
  CREATOR_INVITE: "creator_invite_group",
  PRIVATE_JOIN: "private_join",
  RECIPIENT_SAVE: "recipient_save",
  RECIPIENT_THANKS: "recipient_thanks",
  SWITCH_DEVICE: "switch_device",
};

export const AUTH_PANELS = {
  EMAIL: "EMAIL",
  NAME: "NAME",
  LINK_SENT: "LINK_SENT",
  VERIFY_CODE: "VERIFY_CODE",
  LOCKED: "LOCKED",
  TERMS: "TERMS",
  PRIVACY: "PRIVACY",
};

export const verifyTypes = {
  LOGIN: "login",
  REGISTER: "register",
  EMAILCHANGE: "emailchange",
  GOOGLE: "google",
};

export const getAuth = state => state.auth;
export const getAuthAccessToken = state =>
  getAuth(state)[authTokenTypes.accessToken];
export const getAuthRefreshToken = state =>
  getAuth(state)[authTokenTypes.refreshToken];
export const getAuthUsername = state => getAuth(state)?.username;
export const getContextId = state => getAuth(state)?.contextId;

export const getAuthContextId = authContext => authContext?.id;
export const getAuthContextScenario = authContext => authContext?.auth_scenario;
export const getAuthContextStatus = authContext => authContext?.status;
