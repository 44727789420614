/* Libraries */
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer, purgeStoredState } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import { authTokenTypes } from "./auth/selectors";
/* -Selectors */

/* Reducers */
import account from "./account/reducer";
import addresses from "./addresses/reducer";
import anonymousData from "./anonymousData/reducer";
import auth from "./auth/reducer";
import boundingBox from "./boundingBox/reducer";
import breakpoint from "./breakpoint/reducer";
import cardTheme from "./cardTheme/reducer";
import contributions from "./contributions/reducer";
import cookie from "./cookie/reducer";
import currentRole from "./currentRole/reducer";
import editor from "./editor/reducer";
import enabledFeatures from "./enabledFeatures/reducer";
import errors from "./errors/reducers";
import gif from "./gif/reducer";
import loaders from "./loaders/reducers";
import media from "./media/reducer";
import music from "./music/reducer";
import persisted from "./persisted/reducer";
import platform from "./platform/reducer";
import playback from "./playback/reducer";
import postalOrder from "./postalOrder/reducer";
import preferences from "./preferences/reducer";
import progress from "./progress/reducer";
import prompts from "./prompts/reducer";
import reminders from "./reminders/reducer";
import search from "./search/reducer";
import soundtrack from "./soundtrack/reducer";
import story from "./story/reducer";
import storyBuilder from "./storyBuilder/reducer";
import storyRoles from "./storyRoles/reducer";
import payment from "./payment/reducer";
import updateStatus from "./updateStatus/reducer";
import userRoles from "./userRoles/reducer";
import userRolesPending from "./userRolesPending/reducer";
import webgl from "./webgl/reducer";
/* -Reducers */

import { paths } from "routes/PageRoutes";

const initialState = {};

const preferencesPersistConfig = {
  key: "preferences-4",
  storage,
  // whitelist: [], // Persist these stores in localStorage
};

const rootPersistConfig = {
  key: "root-4",
  storage,
  whitelist: ["anonymousData", "cookie"], // Persist these stores in localStorage
};

const authPersistConfig = {
  key: "auth-4",
  storage,
  whitelist: [
    authTokenTypes.accessToken,
    authTokenTypes.refreshToken,
    "username",
  ], // Persist these auth items in localStorage
};

const appReducer = combineReducers({
  account,
  addresses,
  anonymousData,
  auth: persistReducer(authPersistConfig, auth),
  boundingBox,
  breakpoint,
  cardTheme,
  contributions,
  cookie,
  currentRole,
  editor,
  enabledFeatures,
  errors,
  gif,
  loaders,
  media,
  music,
  payment,
  persisted,
  platform,
  playback,
  postalOrder,
  preferences: persistReducer(preferencesPersistConfig, preferences),
  progress,
  prompts,
  reminders,
  search,
  soundtrack,
  story,
  storyBuilder,
  storyRoles,
  updateStatus,
  userRoles,
  userRolesPending,
  webgl,
});

const rootReducer = (state, action) => {
  // On logout, clear all persisted state and redirect...
  if (action.type === authActions.logoutInit.type) {
    purgeStoredState(rootPersistConfig);
    purgeStoredState(authPersistConfig);
    window.location = paths.landing();
  }

  return appReducer(state, action);
};

export const store = createStore(
  persistReducer(rootPersistConfig, rootReducer),
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
