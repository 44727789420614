import { slideTypes, slideVariants } from "../../../../consts/slides.js";

// Logic to use this is in ThumbnailPlugin
export const thumbnail = {
  widthPrint: 480,
  heightPrint: 672,
  resolution: 0.5, // used by non-Title slides
  resolutionHigh: 1, // used by Title
};

const titleType = slideTypes.TITLE;
const titleVariants = slideVariants[titleType];

// Setting to 3 globally as 4 or higher creates issues in the cloud
export const printResolutionDefault = 3;
export const printResolutions = {
  [titleVariants.PARTICLES_TEXT]: 4,
};
export const getPrintResolution = slide => {
  const { variant } = slide;
  if (printResolutions[variant]) {
    return printResolutions[variant];
  } else if (
    variant === titleVariants.EXPORTED &&
    (slide?.theme?.exported_sku?.includes("KIN_TRAIL") ||
      slide?.theme?.exported_sku?.includes("KIN_RIPP") ||
      slide?.theme?.exported_sku?.includes("KIN_STAR") ||
      slide?.theme?.exported_sku?.includes("KIN_JUGGLE") ||
      slide?.theme?.exported_sku?.includes("KIN_EGG") ||
      slide?.theme?.exported_sku?.includes("KIN_WRKANI") ||
      slide?.theme?.exported_sku?.includes("KIN_FUZZ") ||
      slide?.theme?.exported_sku?.includes("KIN_SIMPLE") ||
      slide?.theme?.exported_sku?.includes("KIN_FLOWER") ||
      slide?.theme?.exported_sku?.includes("PIC_TRAIL") ||
      slide?.theme?.exported_sku?.includes("KIN_TOAST"))
  ) {
    return 3;
  } else {
    return printResolutionDefault;
  }
};

export const isInteractiveCard = (slide = {}) => {
  const { data } = slide;
  const { variant } = data;

  if (variant === titleVariants.EXPORTED) {
    return data?.theme?.interactive;
  }

  return false;
};

export const maxHeight = 660;
export const minimumSecondsReading = 2;

export const isLandscapeSmallHeight = (w, h) => {
  return h < maxHeight && w / h > 1.25;
};

export { defaultProps } from "./propsSlideshow.js";
export { qualityType, sizes } from "./imagesResolutions.js";
