/* Libraries */
import React from "react";
import classnames from "classnames";
/* -Libraries */

/* Components */
import BackNavItem from "./BackNavItem";
import BackNavItemSecondaryNav from "./BackNavItemSecondaryNav";
import Header from "./Header";
import {
  PrivateLeftLinks,
  PrivateRightLinks,
  PublicLinks,
} from "./HeaderLinks";
/* -Components */

/* Selectors */
/* -Selectors */

/* Hooks */
import useBreakpoint from "hooks/useBreakpoint";
/* -Hooks */

import { paths } from "routes/PageRoutes";

const HeaderPrivate = React.memo(props => {
  const { isMobile } = useBreakpoint();

  return (
    <Header
      rightNav={<PrivateRightLinks {...props} />}
      leftNav={!isMobile ? <PrivateLeftLinks {...props} /> : null}
      {...props}
    />
  );
});

const HeaderPublic = React.memo(props => {
  return <Header rightNav={<PublicLinks {...props} />} {...props} />;
});

export const headerEditGroupPageRoutes = [
  {
    path: paths.editGroupInvite(":id"),
    component: () => <HeaderBackGroup title="Add people" />,
  },
];

export const headerEditSoundtrackPageRoutes = [
  {
    path: paths.editSoundtrackLibrary(":id"),
    component: () => {
      return <HeaderBackGroup title={"Add Tracks"} />;
    },
  },
];

const HeaderAccount = React.memo(props => {
  return <HeaderPrivate {...props} />;
});

const HeaderAccountMobile = React.memo(props => {
  return (
    <Header
      rightNav={null}
      title={props.title}
      leftNav={<BackNavItemSecondaryNav />}
    />
  );
});

const HeaderBackGroup = React.memo(props => {
  const { rightNav, title } = props;

  return (
    <Header
      className="ph-0"
      noLogo
      title={title}
      rightNav={rightNav}
      isSubNav
      leftNav={<BackNavItem />}
    />
  );
});

export const HeaderSlidingPanel = React.memo(props => {
  const { className, title, leftNav, rightNav } = props;

  return (
    <Header
      className={className}
      noLogo
      title={title}
      leftNav={leftNav}
      rightNav={rightNav}
      isSubNav
    />
  );
});

export const HeaderSubnavDesktop = React.memo(props => {
  const { headerClassName, title, leftNav, rightNav } = props;

  return (
    <div className={classnames("is-hidden-mobile", props.className)}>
      <Header
        className={headerClassName}
        title={title}
        leftNav={leftNav}
        rightNav={rightNav}
        noLogo
        isSubNav
      />
    </div>
  );
});

export { HeaderLanding } from "./HeaderLanding";

export {
  Header,
  HeaderAccount,
  HeaderAccountMobile,
  HeaderPrivate,
  HeaderPublic,
};
