/* Libraries */
import React, { createContext, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import { panels } from "components/SlidePanel";
import CancelUploadConfirmationModal from "modals/CancelUploadConfirmationModal";
/* -Components */

/* Hooks */
import useModalDisplay from "hooks/useModalDisplay";
/* -Hooks */

export const SlideBuilderContext = createContext();

export const SlideBuilderContextProvider = props => {
  const { uploadsInProgress } = props;

  const modalControl = useModalDisplay();
  const { doClose } = modalControl;

  const [onClose, setOnClose] = useState();
  const onRequestClose = useCallback(
    (...params) => {
      if (onClose) {
        onClose(...params);
      }
      doClose();
    },
    [doClose, onClose]
  );

  // handle movement between panels
  const [currentPanel, setCurrentPanel] = useState(panels.TYPESELECTOR);
  const goToTypeSelector = useCallback(() => {
    setCurrentPanel(panels.TYPESELECTOR);
  }, []);
  const goToAddMedia = useCallback(() => {
    setCurrentPanel(panels.ADDMEDIA);
  }, []);
  const goToLibrary = useCallback(() => {
    setCurrentPanel(panels.LIBRARY);
  }, []);
  const goToCustomise = useCallback(() => {
    setCurrentPanel(panels.CUSTOMISE);
  }, []);

  // handle modal close when uploads are in progress
  const [showCancelUploadConfirmation, setShowCancelUploadConfirmation] =
    useState(false);
  const onRequestModalClose = useCallback(() => {
    if (uploadsInProgress) {
      setShowCancelUploadConfirmation(true);
    } else {
      onRequestClose();
    }
  }, [onRequestClose, uploadsInProgress]);
  const onConfirmUploadCancel = useCallback(() => {
    setShowCancelUploadConfirmation(false);
    onRequestClose();
  }, [onRequestClose]);

  // handle request to show media capture screen
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const [showCapture, setShowCapture] = useState(false);
  const [onDiscardCapture, setOnDiscardCapture] = useState(null);
  const [onUploadCapture, setOnUploadCapture] = useState(null);
  const closeCapture = useCallback(() => {
    setShowCapture(false);
  }, []);

  const handleShowCapture = useCallback((onDiscard, onUpload) => {
    setOnDiscardCapture(() => onDiscard);
    setOnUploadCapture(() => onUpload);
    setShowCapture(true);
  }, []);

  // Assign React state and constants to context object
  const SlideBuilderContextObject = useMemo(() => {
    return {
      closeCapture,
      currentMediaType,
      currentPanel,
      goToAddMedia,
      goToCustomise,
      goToLibrary,
      goToTypeSelector,
      modalControl,
      onDiscardCapture,
      onRequestModalClose,
      onUploadCapture,
      setCurrentMediaType,
      setOnClose,
      showCapture: handleShowCapture,
      showingCapture: showCapture,
    };
  }, [
    closeCapture,
    currentMediaType,
    currentPanel,
    goToAddMedia,
    goToCustomise,
    goToLibrary,
    goToTypeSelector,
    handleShowCapture,
    modalControl,
    onDiscardCapture,
    onRequestModalClose,
    onUploadCapture,
    showCapture,
  ]);

  return (
    <SlideBuilderContext.Provider value={SlideBuilderContextObject}>
      {props.children}

      <CancelUploadConfirmationModal
        isOpen={showCancelUploadConfirmation}
        onClose={setShowCancelUploadConfirmation.bind(null, false)}
        onCancel={setShowCancelUploadConfirmation.bind(null, false)}
        onConfirm={onConfirmUploadCancel}
      />
    </SlideBuilderContext.Provider>
  );
};

SlideBuilderContextProvider.propTypes = {
  children: PropTypes.element,
  uploadsInProgress: PropTypes.number,
};
