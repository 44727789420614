/* Libraries */
import React, { useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
import { BadgeText } from "components/_v2/BadgeText";
import Collapse from "components/Collapse";
import SupportLink from "components/SupportLink";
/* -Components */

/* Hooks */
/* -Hooks */

import analytics from "utils/analyticsUtils";

const FormSubmissionError = props => {
  const { action, className, show, text } = props;

  useEffect(() => {
    if (show) {
      analytics.event("Form Error", { action });
    }
  }, [action, show]);

  return (
    <Collapse show={show}>
      <BadgeText
        type="error"
        text={
          text || (
            <>
              Sorry, something went wrong. Please try again later or email us at{" "}
              <SupportLink /> if the problem persists.
            </>
          )
        }
        className={classnames("mv-1", className)}
      />
    </Collapse>
  );
};

FormSubmissionError.propTypes = {
  action: PropTypes.string.isRequired,
  show: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default React.memo(FormSubmissionError);
