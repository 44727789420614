import * as slideSelectors from "../slide/selectors";

export const getContributionStore = state => state.contributions;
export const getLatestContributionAccepted = state =>
  getContributionStore(state)?.latestContributionAccepted || 0;
export const getContributions = state =>
  getContributionStore(state)?.contributions || [];
export const getLastContributionUpdate = state =>
  getContributionStore(state)?.last_contribution_update;

export const getContributionById = (contributions, id) =>
  contributions.find(contribution => slideSelectors.getId(contribution) === id);
export const getContributionsByRole = (contributions, roleId) =>
  contributions.filter(slide => slideSelectors.getCreatorId(slide) === roleId);

export const getId = contribution => contribution.id;
export const getName = contribution => contribution?.creator?.name;
export const getEmail = contribution => contribution?.creator?.email;
export const getSlides = contribution => contribution.slides;
