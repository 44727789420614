import { removeHash } from "utils/url";
import { getDaysDifferences, isSameDay } from "utils/dateUtils";
import { getDurations } from "libs/kindeo-play/webgl/scenes/kindeo/views/durations";

/* Selectors */
import * as currentRoleSelectors from "redux/currentRole/selectors";
import { PRODUCT_TIERS } from "redux/payment/selectors";
import * as paymentSelectors from "redux/payment/selectors";
import * as rolesSelectors from "redux/roles/selectors";
import * as searchSelectors from "redux/search/selectors";
import * as slideSelectors from "redux/slide/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
/* -Selectors */

import { paths } from "routes/PageRoutes";

export const storySlidesFilters = {
  DATE: {
    "": "ALL TIME",
    TODAY: "TODAY",
    WEEK: "THIS WEEK",
  },
  CREATOR: {
    "": "ALL",
    ME: "ME",
    OTHERS: "OTHERS",
    UNSEEN: "UNSEEN",
  },
};

export const storyGroupSlidesFilters = {
  "": "ALL",
  ME: "ME",
  OTHERS: "OTHERS",
};

export const storyTokenTypes = {
  groupToken: "group_token",
  inviteToken: "invite_token",
  recipientToken: "recipient_token",
  watchToken: "watch_token",
};

export const anonymousStoryId = "draft";

export const kindeoPrivacyLinks = {
  group: "group_link_privacy",
  recipient: "recipient_link_privacy",
};

export const kindeoVisibility = {
  GROUP: "group",
  OPEN: "open",
  PRIVATE: "private",
};

export const savedStoryType = {
  GROUP: "group",
  RECIPIENT: "recipient",
  VIEWER: "viewer",
};

export const postalOrderStatus = {
  DISPATCHED: "dispatched",
  PROCESSING: "processing",
  PRINTING: "printing",
};

export const getStory = state => state.story;

// with the new edit page sub navigation by hash, the story `id` could potentially get the navigation hash inside it
// -> hence the use of `removeHash`
export const getId = story => removeHash(story.id);

export const isLoaded = state => getId(getStory(state));

export const getSlideCount = state => getSlides(getStory(state)).length;

export const getSlide = (state, slideId) =>
  getStorySlideById(getStory(state), slideId);

export const getSlideWithTitle = state =>
  getSlides(getStory(state)).find(slide => slide.title !== null);

export const getTextSlide = state =>
  getSlides(getStory(state)).find(slide => slide.type === slideTypes.TEXT);

export const getPhotoSlide = state =>
  getSlides(getStory(state)).find(slide => slide.type === slideTypes.PHOTOS);

export const getInviteFrom = story => story.invite_from;

export const getSlides = (story, index, end) =>
  getSlidesCount(story) ? story.slides.slice(index, end) : [];

export const getGreetingSlide = story => {
  if (hasGreetingContent(story)) {
    return {
      id: undefined,
      type: slideTypes.TEXT,
      variant: slideVariants[slideTypes.TEXT].GREETINGS,
      greeting_to: getGreetingTo(story),
      greeting_message: getGreetingMessage(story),
      greeting_from: getGreetingFrom(story),
      style: {
        colours: ["#68d8e2", "#ffffff"],
      },
    };
  }

  return null;
};

export const includeGreetingInSlides = (story, slides) => {
  if (!slides) {
    slides = getSlides(story);
  }

  const greetingSlide = getGreetingSlide(story);
  if (!greetingSlide) return slides;

  let slidesCopy = [...slides];
  slidesCopy.splice(1, 0, greetingSlide);
  return slidesCopy;
};

export const getSlidesCount = story => story.slides?.length || 0;
export const getDraggableSlides = story =>
  story.slides?.filter(
    slide =>
      !slideSelectors.isCreditSlide(slide) &&
      !slideSelectors.isTitleSlide(slide)
  ) || [];

export const getDraggableSlidesCount = story => {
  return getDraggableSlides(story)?.length || 0;
};
export const getListingSlidesCount = story => story.slide_count || 0;
export const getListingRolesCount = story => story.role_active_count || 0;
export const getListingReceivedOn = story => story.received_on;
export const getStoryDuration = story => {
  if (story.slides) {
    return getDurations(story.slides);
  } else {
    return 0;
  }
};

export const getTitleSlide = story => {
  return getSlides(story)[0];
};
export const getStoryTitle = story => {
  return story?.title;
};
export const getStoryCover = story => {
  return story?.cover_url;
};
export const getEditingStoryCover = story => {
  const titleSlide = getTitleSlide(story);
  return !slideSelectors.isGeneratingThumbnail(titleSlide)
    ? slideSelectors.getThumbnailUrl(titleSlide)
    : null;
};
export const hasLandscapeCover = story => {
  return !!story?.has_landscape_cover;
};

export const getRecipientName = story => story?.recipient_name || "";
export const getRecipientFirstName = story => story?.recipient_first_name || "";
export const getRecipientLastName = story => story?.recipient_last_name || "";
export const getRecipientFullName = story => {
  return (
    story?.recipient_first_name +
    (story?.recipient_last_name ? ` ${story?.recipient_last_name}` : "")
  );
};
export const getStoryOccasionType = story => story?.occasion;
export const getCreatorName = story => story?.owner?.first_name;
export const getCreatorFullName = story => {
  return (
    story?.owner?.first_name +
    (story?.owner?.last_name ? ` ${story?.owner?.last_name}` : "")
  );
};
export const getCreatorFirstName = story => story?.owner?.first_name;
export const getCreatorLastName = story => story?.owner?.last_name;
export const getCreatorUsername = story => story?.owner?.username;

export const getGreetingFrom = story => story?.greeting_from || "";
export const getGreetingMessage = story => story?.greeting_message || "";
export const getGreetingTo = story => story?.greeting_to || "";

export const hasGreetingContent = story =>
  !!(
    getGreetingFrom(story) ||
    getGreetingTo(story) ||
    getGreetingMessage(story)
  );

export const hasPostalCredit = story => story?.has_sbp_credit;

export const getHasConfettis = story => story.fx_confetti;
export const getHasSnow = story => story.fx_snow;

export const getContributorNames = story => {
  return story.contributors
    ? story.contributors.filter((c, i) => story.contributors.indexOf(c) === i)
    : [];
};

export const getContributions = story => story?.contributions;
export const getContributionCount = story => story?.contribution_count;
export const getUnseenContributions = story => story?.contributions_unseen;
export const getHasUpdates = story => story?.has_updates;
export const getSavedType = story => story?.savedType;
export const getCreatedOn = story => story?.created_on;

// a flag "saved" and "savedType" is set on saved story objects in the storiesSelectors method "getSavedStories"
export const getIsSaved = story => !!story.saved;
export const getIsSavedRecipient = story =>
  getSavedType(story) === savedStoryType.RECIPIENT;
export const getIsSavedGroup = story =>
  getSavedType(story) === savedStoryType.GROUP;
export const getIsSavedViewer = story =>
  getSavedType(story) === savedStoryType.VIEWER;

export const getLastStoryUpdate = story => story.last_story_update;
export const getLastRoleUpdate = story => story.last_role_update;

export const getStorySlideById = (story, slideId) =>
  getSlides(story).find(slide => slide.id === slideId);
export const getStorySlidesByRole = (story, roleId) =>
  getSlides(story).filter(
    slide => slideSelectors.getCreatorId(slide) === roleId
  );

export const getHasChanged = story => story.hasChanged;

export const getGroupToken = story => story?.[storyTokenTypes.groupToken];
export const getRecipientToken = story =>
  story?.[storyTokenTypes.recipientToken];
export const getNextShare = story => story?.next_share;

export const getOccasionDate = story => story?.occasion_date;
export const getCompletionDate = story => story?.completion_date;
export const getContentModeration = story => story?.content_moderation;
export const getPrivacy = story => story?.privacy;
export const getPrivacyRecipient = story =>
  story?.[kindeoPrivacyLinks.recipient];
export const getPrivacyGroup = story => story?.[kindeoPrivacyLinks.group];
export const getShowCreditSlide = story => story?.has_credits_slide;
export const getMembersCanWatch = story => story?.show_as_you_go;
export const getGroupMessage = story => story?.group_message || "";
export const getThanksMessage = story => story?.thanks_message || "";
export const getThanksSent = story => story?.thanks_sent_on;

export const getFxConfetti = story => story?.fx_confetti;
export const getFxSnow = story => story?.fx_snow;

export const getCardSetupComplete = story => story?.setup_card_complete;
export const getInviteSetupComplete = story => story?.setup_invite_complete;
export const getSendSetupComplete = story => story?.setup_send_complete;

export const getOwnerEmailNotifications = story =>
  story?.owner_email_notifications;

export const isOpen = story => getPrivacy(story) === kindeoVisibility.OPEN;
export const isCompleted = story => {
  const completionDate = new Date(getCompletionDate(story));
  if (!completionDate) return true;
  const today = new Date();
  // the story completion date has no time component so is effectively midnight
  today.setHours(0, 0, 0, 0);
  return completionDate.getTime() < today.getTime();
};

export const getProductTier = story => story?.product_tier;
export const getIsFree = story => getProductTier(story) === PRODUCT_TIERS.FREE;
export const getIsStarter = story =>
  getProductTier(story) === PRODUCT_TIERS.STARTER;
export const getIsUnlimited = story =>
  getProductTier(story) === PRODUCT_TIERS.UNLIMITED;

// 1) is payment enabled,
// 2) is the story not yet premium,
// 3) is the number of slides at or beyond the free slide limit
export const isAtPaywall = state => {
  const story = getStory(state);
  const storySlideLimit = getStorySlideLimit(state);

  return storySlideLimit <= getDraggableSlidesCount(story);
};

export const getStorySlideLimit = state => {
  const story = getStory(state);
  const storyTier = getProductTier(story) || PRODUCT_TIERS.FREE;
  const storyProduct = paymentSelectors.getProductByName(
    paymentSelectors.getDigitalProducts(state),
    storyTier
  );

  return paymentSelectors.getProductSlideLimit(storyProduct);
};

export const getRecipientLink = story =>
  `${process.env.REACT_APP_DOMAIN}${paths.recipient(getRecipientToken(story))}`;
export const getContributorLink = story =>
  `${process.env.REACT_APP_DOMAIN}${paths.group(getGroupToken(story))}`;

export const getContributionCountForRecipient = story =>
  story.contribution_count;
export const getContributionCountForContributor = story =>
  story.my_contribution_count;
export const getRoleCountForRecipient = story => story.role_count;
export const getThumbnailPreviewsForRecipient = story =>
  story.preview_thumbnails;

export const getFromReminderId = story => story?.from_reminder_id;
export const getPreviouslySentByPost = story => !story?.is_card_editable;

// post order status
export const getRecentPostalOrderStatus = story =>
  story?.recent_postal_order_status;
export const getRecentPostalIsProcessing = story =>
  getRecentPostalOrderStatus(story) === postalOrderStatus.PROCESSING;
export const getRecentPostalIsPrinting = story =>
  getRecentPostalOrderStatus(story) === postalOrderStatus.PRINTING;
export const getRecentPostalIsDispatched = story =>
  getRecentPostalOrderStatus(story) === postalOrderStatus.DISPATCHED;

export const searchStoryGroupSlides = state => {
  return searchStorySlides(state, applyFiltersGroup);
};

export const searchStorySlides = (state, filters = applyFilters) => {
  const searchTerm = searchSelectors.getSearchTerm(state);
  const searchFilters = searchSelectors.getSearchFilters(state);
  const currentRole = currentRoleSelectors.getCurrentRole(state);
  const slides = getSlides(getStory(state));

  if (searchSelectors.getIsFiltered(state)) {
    return filters(
      applySearch(slides, searchTerm),
      searchFilters,
      rolesSelectors.getId(currentRole)
    );
  }

  return slides;
};

const applySearch = (slides, searchTerm) => {
  if (searchTerm.length > 2) {
    return slides.filter(slide => {
      const searchValues = [
        slideSelectors.getCreatorName(slide),
        slideSelectors.getCreatorEmail(slide),
        ...slideSelectors.getAllTexts(slide), // title, description, subtitle...
      ];

      return (
        searchValues
          .join("±±")
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) !== -1
      );
    });
  }
  return slides;
};

const applyFiltersGroup = (slides, filters, currentRoleId) => {
  if (filters.length > 0) {
    // check each role against each each item in filters
    return slides.filter(slide => {
      const creatorId = slideSelectors.getCreatorId(slide);
      const isSlideCreator = currentRoleId === creatorId;

      if (filters.includes(storyGroupSlidesFilters.ME) && isSlideCreator) {
        return true;
      } else if (
        filters.includes(storyGroupSlidesFilters.OTHERS) &&
        !isSlideCreator
      ) {
        return true;
      }
      return false;
    });
  }

  return slides;
};

const applyFilters = (slides, filters, currentRoleId) => {
  const today = new Date();
  if (filters.length > 0) {
    // check each role against each each item in filters
    return slides.filter(slide => {
      if (filters.includes(storySlidesFilters.DATE.TODAY)) {
        const d = new Date(slideSelectors.getCreated(slide));
        if (isSameDay(today, d)) {
          return true;
        }
      } else if (filters.includes(storySlidesFilters.DATE.WEEK)) {
        const d = new Date(slideSelectors.getCreated(slide));
        if (getDaysDifferences(today, d) <= 7) {
          return true;
        }
      }

      const slideCreatorId = slideSelectors.getCreatorId(slide);
      const isSlideCreator = currentRoleId === slideCreatorId;

      if (filters.includes(storySlidesFilters.CREATOR.ME) && isSlideCreator) {
        return true;
      } else if (
        filters.includes(storySlidesFilters.CREATOR.OTHERS) &&
        !isSlideCreator
      ) {
        return true;
      } else if (
        filters.includes(storySlidesFilters.CREATOR.UNSEEN) &&
        slideSelectors.getIsUnseen(slide)
      ) {
        return true;
      }

      return false;
    });
  }

  return slides;
};
