export const themes = [
  {
    name: "curvey-0",
    text: "You. Are. A STAR!",
    debug: false,
    hasGlare: true,
    colorLetters: [
      "#f8e70d",
      "#aba226",
      "#a59900",
      "#f8e70d",
      "#f8e70d",
      "#f8e70d",
    ],
    margin: 8,
    bgColor: "#00132c",
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Roboto Serif",
        fontSize: 100,
        fontWeight: "400",
        active: true,
        useOnce: false,
      },
      {
        fill: "white",
        fontFamily: "Barlow Condensed",
        fontWeight: 800,
        fontSize: 100,
        active: true,
        useOnce: false,
      },
      {
        fill: "white",
        fontFamily: "Barlow Condensed",
        fontWeight: "400",
        fontSize: 120,
        active: true,
        useOnce: false,
      },
      // {
      //   fontFamily: "Indie Flower",
      //   fontSize: 80,
      //   fontWeight: "400",
      //   active: true,
      //   useOnce: true,
      // },
    ],
  },
  {
    name: "curvey-1",
    text: "Happy birthday Bob. This is your day :D",
    debug: false,
    hasGlare: false,
    colorLetters: [
      "#e97778",
      "#89c7b6",
      "#ffc54e",
      "#ad84c7",
      "#7998c9",
      "#7998c9",
    ],
    margin: 7,
    bgColor: "#fffbe8",
    useDifferentFonts: true,
    fonts: [
      {
        fill: "white",
        fontFamily: "Barlow Condensed",
        fontWeight: 800,
        fontSize: 120,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Barlow Condensed",
        fontSize: 100,
        fontWeight: "400",
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Barlow Condensed",
        fontSize: 95,
        fontWeight: 600,
        active: true,
        useOnce: false,
      },
    ],
  },
  {
    debug: false,
    hasGlare: true,
    colorLetters: [
      "#fdf7cc",
      "#f6ecaf",
      "#fcffe0",
      "#fdfff2",
      "#fcfdf2",
      "#fdffef",
    ],
    margin: 8,
    bgColor: "#043174",
    glareAlpha: 0.08,
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Raleway",
        fontSize: 100,
        fontWeight: 800,
        italic: false,
        active: true,
        useOnce: true,
      },
      {
        fontFamily: "Montserrat",
        fontSize: 100,
        fontWeight: "200",
        italic: false,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Roboto Serif",
        fontSize: 100,
        fontWeight: 400,
        italic: false,
        active: true,
        useOnce: false,
      },
    ],
    name: "curvey-2",
  },
  {
    name: "curvey-3",
    text: "So sad you're leaving... not!!!",
    debug: false,
    hasGlare: false,
    colorLetters: [
      "#f8efb6",
      "#febac5",
      "#6cd1ea",
      "#facfd7",
      "#c2eae9",
      "#c2eae9",
    ],
    margin: 8,
    bgColor: "#023535",
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Barlow Condensed",
        fontSize: 120,
        fontWeight: 600,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Barlow Condensed",
        fontSize: 80,
        fontWeight: "400",
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Roboto Serif",
        fontSize: 100,
        fontWeight: "400",
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Barlow Condensed",
        fontSize: 100,
        fontWeight: 800,
        active: true,
        useOnce: false,
      },
    ],
  },
  // {
  //   name: "curvey-3",
  //   text: "Happy Valentine's, my love! \\nTo many more years of happiness...",
  //   debug: false,
  //   hasGlare: false,
  //   colorLetters: [
  //     "#f87a7c",
  //     "#e55558",
  //     "#e5575f",
  //     "#f87a7c",
  //     "#f87a7c",
  //     "#f87a7c",
  //   ],
  //   margin: 17,
  //   bgColor: "#fffbf3",
  //   useDifferentFonts: true,
  //   fonts: [
  //     {
  //       fontFamily: "Caveat",
  //       fontSize: 105,
  //       fontWeight: 700,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //     {
  //       fontFamily: "Caveat",
  //       fontSize: 135,
  //       fontWeight: 400,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //     {
  //       fontFamily: "Indie Flower",
  //       fontSize: 110,
  //       fontWeight: "400",
  //       italic: false,
  //       active: true,
  //       useOnce: true,
  //     },
  //   ],
  // },
  // {
  //   name: "curvey-4",
  //   text: "Our awesome trip to Disney, florida!!!",
  //   debug: false,
  //   hasGlare: false,
  //   colorLetters: [
  //     "#0f7cbf",
  //     "#3098d9",
  //     "#3dadd9",
  //     "#79bed9",
  //     "#79bed9",
  //     "#79bed9",
  //   ],
  //   margin: 13,
  //   bgColor: "#fffefa",
  //   useDifferentFonts: true,
  //   fonts: [
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 400,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //     {
  //       fontFamily: "Fredoka One",
  //       fontSize: 100,
  //       fontWeight: "400",
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //       fontStyle: "",
  //     },
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 700,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //   ],
  // },
  // {
  //   name: "curvey-5",
  //   text: "Welcome to the team, John!",
  //   debug: false,
  //   hasGlare: false,
  //   colorLetters: [
  //     "#e97778",
  //     "#89c7b6",
  //     "#f8c356",
  //     "#ad84c7",
  //     "#7998c9",
  //     "#7998c9",
  //   ],
  //   margin: 13,
  //   bgColor: "#fffefa",
  //   useDifferentFonts: true,
  //   fonts: [
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 700,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //     {
  //       fontFamily: "Indie Flower",
  //       fontSize: 100,
  //       fontWeight: "400",
  //       italic: false,
  //       active: true,
  //       useOnce: true,
  //     },
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 900,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //     {
  //       fontFamily: "Barlow Condensed",
  //       fontSize: 150,
  //       fontWeight: "400",
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //   ],
  // },
  // {
  //   name: "curvey-6",
  //   text: "Welcome to the team, John!",
  //   debug: false,
  //   hasGlare: false,
  //   colorLetters: [
  //     "#b37f9f",
  //     "#f9afc4",
  //     "#f9afc4",
  //     "#b9d1dc",
  //     "#b9d1dc",
  //     "#b9d1dc",
  //   ],
  //   margin: 13,
  //   bgColor: "#2e3352",
  //   useDifferentFonts: true,
  //   fonts: [
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 400,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //     {
  //       fontFamily: "Indie Flower",
  //       fontSize: 100,
  //       fontWeight: "400",
  //       italic: false,
  //       active: true,
  //       useOnce: true,
  //     },
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 700,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //   ],
  // },

  {
    debug: false,
    hasGlare: true,
    colorLetters: [
      "#ebedee",
      "#b3b4b5",
      "#c2c5c8",
      "#ebedee",
      "#ebedee",
      "#ebedee",
    ],
    margin: 13,
    bgColor: "#191919",
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Montserrat",
        fontSize: 100,
        fontWeight: 400,
        italic: false,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Montserrat",
        fontSize: 100,
        fontWeight: 700,
        italic: false,
        active: true,
        useOnce: false,
      },
    ],
    name: "curvey-4",
  },
  // {
  //   name: "curvey-8",
  //   text: "Oh, welcome!",
  //   debug: false,
  //   hasGlare: true,
  //   colorLetters: [
  //     "#cdcece",
  //     "#b3b4b5",
  //     "#c2c5c8",
  //     "#ebedee",
  //     "#ebedee",
  //     "#ebedee",
  //   ],
  //   margin: 13,
  //   bgColor: "#de652b",
  //   useDifferentFonts: true,
  //   fonts: [
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 400,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //     {
  //       fontFamily: "Montserrat",
  //       fontSize: 100,
  //       fontWeight: 700,
  //       italic: false,
  //       active: true,
  //       useOnce: false,
  //     },
  //   ],
  // },
  {
    debug: false,
    hasGlare: false,
    colorLetters: [
      "#f0da91",
      "#e39ca8",
      "#6cd1ea",
      "#e0a7b1",
      "#92bdbc",
      "#92bdbc",
    ],
    margin: 8,
    bgColor: "#fffdfa",
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Dancing Script",
        fontSize: 100,
        fontWeight: "400",
        italic: false,
        active: true,
        useOnce: false,
      },
    ],
    name: "curvey-5",
  },
  {
    debug: false,
    hasGlare: true,
    colorLetters: [
      "#0487d9",
      "#049dd9",
      "#04b2d9",
      "#04c4d9",
      "#04c4d9",
      "#04c4d9",
    ],
    margin: 8,
    bgColor: "#f2e6d8",
    glareAlpha: 0.08,
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Raleway",
        fontSize: 100,
        fontWeight: 800,
        italic: false,
        active: true,
        useOnce: true,
      },
      {
        fontFamily: "Montserrat",
        fontSize: 100,
        fontWeight: "200",
        italic: false,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Raleway",
        fontSize: 100,
        fontWeight: 300,
        italic: false,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Roboto Serif",
        fontSize: 100,
        fontWeight: 600,
        italic: false,
        active: true,
        useOnce: false,
      },
    ],
    name: "curvey-6",
  },

  {
    debug: false,
    hasGlare: true,
    colorLetters: [
      "#dd797a",
      "#eb7475",
      "#eb6e70",
      "#eb6e70",
      "#eb6e70",
      "#eb6e70",
    ],
    margin: 8,
    bgColor: "#fff6f5",
    glareAlpha: 0.08,
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Caveat Brush",
        fontSize: 110,
        fontWeight: "400",
        italic: false,
        active: true,
        useOnce: false,
      },
    ],
    name: "curvey-7",
  },
  {
    debug: false,
    hasGlare: true,
    colorLetters: [
      "#b2edfa",
      "#a7e4f2",
      "#66cdd9",
      "#d9bba0",
      "#f2d5bb",
      "#f2d5bb",
    ],
    margin: 8,
    bgColor: "#258bba",
    glareAlpha: 0.08,
    useDifferentFonts: true,
    fonts: [
      {
        fontFamily: "Raleway",
        fontSize: 100,
        fontWeight: 800,
        italic: false,
        active: true,
        useOnce: true,
      },
      {
        fontFamily: "Montserrat",
        fontSize: 120,
        fontWeight: "200",
        italic: false,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Roboto Serif",
        fontSize: 100,
        fontWeight: "400",
        italic: false,
        active: true,
        useOnce: false,
      },
      {
        fontFamily: "Montserrat",
        fontSize: 60,
        fontWeight: "400",
        italic: false,
        active: true,
        useOnce: false,
      },
    ],
    name: "curvey-8",
  },
];
