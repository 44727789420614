/* Libraries */
import { useCallback } from "react";
/* -Libraries */

/* Hooks */
/* -Hooks */

import { isMobileDevice } from "kindeo-webgl/utils";
import { addRootClass, hasRootClass, removeRootClass } from "utils/domUtils";

const noscrollClassName = "is-clipped";
const isClipped = new Map();

const usePageClipping = source => {
  const setClipping = useCallback(
    (clip, restoreScroll = true) => {
      const currentlyClipped = isClipped.get(source);
      if (clip && !currentlyClipped) {
        // record the current scroll position before clipping
        // because mobile browsers will scroll to the top of the page
        isClipped.set(source, window.scrollY);
      } else {
        if (currentlyClipped !== undefined) {
          isClipped.delete(source);
        }
      }
      const stillClipped = isClipped.size > 0;

      if (stillClipped) {
        if (!hasRootClass(noscrollClassName)) {
          addRootClass(noscrollClassName);
        }
      } else if (hasRootClass(noscrollClassName)) {
        removeRootClass(noscrollClassName);

        // if a mobile browser, restore the previous scroll position
        // when the clipping is removed
        if (isMobileDevice && restoreScroll) {
          window.scrollTo(0, currentlyClipped);
        }
      }
    },
    [source]
  );

  return setClipping;
};

export default usePageClipping;
