/* Libraries */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
/* -Libraries */

/* Actions */
import { actions as breakpointActions } from "redux/breakpoint/actions";
/* -Actions */

/* Hooks */
import useOnResize from "hooks/useOnResize";
/* -Hooks */

const extractBreakpoint = target => {
  const pseudoEl = window.getComputedStyle(target, ":before");
  const breakpoint = pseudoEl.getPropertyValue("content").replace(/"/g, "");
  return breakpoint;
};
const rootElement = document.getElementById(process.env.REACT_APP_ROOT_ELEMENT);

const BreakpointMonitor = props => {
  const dispatch = useDispatch();
  const currentBreakpoint = extractBreakpoint(rootElement);
  const [breakpoint, setBreakpoint] = useState(currentBreakpoint);

  const onResize = useCallback(e => {
    requestAnimationFrame(() => {
      const newBreakpoint = extractBreakpoint(rootElement);
      setBreakpoint(newBreakpoint);
    });
  }, []);

  useOnResize(onResize, 200);

  useEffect(() => {
    dispatch(breakpointActions.setBreakpoint({ breakpoint }));
  }, [breakpoint, dispatch]);

  return null;
};

export default React.memo(BreakpointMonitor);
