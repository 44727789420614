/* Libraries */
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { renewAuth, resetAuthContext } from "redux/auth/actions";
import { clearAnonymousData } from "redux/anonymousData/actions";
/* -Actions */

/* Selectors */
import * as authSelectors from "redux/auth/selectors";
/* -Selectors */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useIsMounted from "hooks/useIsMounted";
import usePrevious from "hooks/usePrevious";
import useModals from "hooks/useModals";
import usePollAuthProcessStatus from "hooks/usePollAuthProcessStatus";
import useTabFocus from "hooks/useTabFocus";
/* --Hooks */

/* Context */
import { ContributionBuilderContext } from "context/ContributionBuilderContext";
/* -Context */

import { modals } from "routes/ModalRoutes";
import useTriggerOnChange from "hooks/useTriggerOnChange";

const selector = createSelector(authSelectors.getContextId, contextId => {
  return contextId;
});

const AuthMonitor = props => {
  const contextId = useSelector(selector);

  const dispatch = useDispatch();
  const isLoggedIn = useAuthStatus();
  const isMounted = useIsMounted();
  const tabFocused = useTabFocus();
  const prevTabFocused = usePrevious(tabFocused);
  const { linkModal } = useModals();

  const { hideContributionBuilder } = useContext(ContributionBuilderContext);

  const [redirect, setRedirect] = useState();

  useEffect(() => {
    const tabReceivedFocus = tabFocused && prevTabFocused === false;
    // if another tab has logged out, this tab still has state saying it's in
    // on receiving focus, a logged in tab should check with the API to make sure
    if (isLoggedIn && tabReceivedFocus) {
      dispatch(renewAuth());
    }
  }, [dispatch, isLoggedIn, isMounted, prevTabFocused, tabFocused]);

  const { authProcessComplete, authContext } =
    usePollAuthProcessStatus(contextId);

  const authProcessChanged = useTriggerOnChange(authProcessComplete);
  useEffect(() => {
    if (authProcessChanged && authProcessComplete) {
      // clear store
      dispatch(resetAuthContext());
      dispatch(clearAnonymousData());

      // show auth success message with scenario context
      const scenario = authSelectors.getAuthContextScenario(authContext);
      let route = undefined;
      switch (scenario) {
        // for contributors remain on the current page but hide the contribution builder
        case authSelectors.authScenarios.CONTRIBUTION:
          hideContributionBuilder();
          break;

        // for a creator saving their kindeo leave the edit page
        case authSelectors.authScenarios.ANON_SAVE_EXIT:
        case authSelectors.authScenarios.CREATOR_PAYMENT:
        case authSelectors.authScenarios.CREATOR_SAVE:
        case authSelectors.authScenarios.CREATOR_SEND:
        case authSelectors.authScenarios.CREATOR_SETTINGS:
        case authSelectors.authScenarios.CREATOR_INVITE:
          route = "/";
          break;

        default:
      }
      setRedirect(
        linkModal(
          modals.postAuthCompleted(),
          {
            search: "",
            scenario: authSelectors.getAuthContextScenario(authContext),
          },
          route
        )
      );
    }
  }, [
    authContext,
    authProcessChanged,
    authProcessComplete,
    dispatch,
    hideContributionBuilder,
    linkModal,
  ]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return null;
};

export default React.memo(AuthMonitor);
