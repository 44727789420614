/* Libraries */
import React from "react";
import classnames from "classnames";
/* -Libraries */

/* Components */
/* -Components */

import styles from "./index.module.scss";

const ModalContentTitle = props => {
  const { className, children } = props;

  return (
    <h2 className={classnames("body", styles.modalContentTitle, className)}>
      {children}
    </h2>
  );
};

export default React.memo(ModalContentTitle);
