/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
/* -Actions */

const INITIAL_STATE = [];

const setEnabledFeatures = (state, { payload }) => {
  return [...payload];
};

export default createReducer(INITIAL_STATE, {
  [actions.setEnabledFeatures]: setEnabledFeatures,
});
