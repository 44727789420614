import { mediaTypes } from "redux/media/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import thumbnail from "images/slides/thumbnails/video.png";

const type = slideTypes.VIDEO;
const videoVariants = slideVariants[type];

export default {
  type,
  icon: "video-fill",
  label: "Video",
  descriptionBuilder: "Capture your message on video",
  colour: "teal5",
  thumbnail,
  variants: [
    {
      name: videoVariants.SIMPLE,
      label: "Simple",
    },
    {
      name: videoVariants.FULLSCREEN,
      label: "Fullscreen",
      default: false, //default: true sets this specific variant by default
    },
    {
      name: videoVariants.TV,
      label: "TV",
    },
    {
      name: videoVariants.FILM,
      label: "Film",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Message",
      placeholder: "e.g. Our holiday",
      validation: {
        maxLength: {
          value: 40,
          message: `Please keep your message to 40 characters or less`,
        },
      },
    },
    {
      key: "subtitle",
      type: inputTypes.INPUT,
      label: "From",
      placeholder: "e.g. From your best friend",
      validation: {
        maxLength: {
          value: 30,
          message: `Please keep this to 30 characters or less`,
        },
      },
    },
    {
      key: "video",
      type: inputTypes.MEDIA,
      label: "Video",
      icon: "slide-video",
      mediaType: mediaTypes.VIDEO,
      validation: {
        minLength: {
          value: 1,
          message: `Please choose a video for the slide`,
        },
        maxLength: { value: 1, message: `You can only add one.` },
      },
    },
  ],
};
