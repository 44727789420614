/* Libraries */
import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Components */
import Icon from "components/Icon";
import Ratio from "components/Ratio";
/* -Components */

import styles from "./index.module.scss";

const Roundel = React.memo(props => {
  const { centered, children, className, elementRef, ...otherProps } = props;
  const Element = otherProps.to ? Link : otherProps.onClick ? "button" : "div";

  return (
    <Element
      className={classnames("roundel", styles.container, className)}
      {...otherProps}
      ref={elementRef}
    >
      <Ratio centered={centered} ratio="100%">
        {children}
      </Ratio>
    </Element>
  );
});

Roundel.Icon = React.memo(props => {
  const { className, icon = "", iconSize = "24", ...otherProps } = props;

  return (
    <Roundel
      className={classnames(className, styles.icon)}
      centered
      {...otherProps}
    >
      <Icon icon={icon} size={iconSize} className={styles.image} />
    </Roundel>
  );
});

Roundel.Initial = React.memo(props => {
  const { className, initial = "", ...otherProps } = props;

  return (
    <Roundel
      className={classnames(className, styles.initial)}
      centered
      {...otherProps}
    >
      <i className={classnames("initial", styles.content)}>
        {initial.toString()[0]}
      </i>
    </Roundel>
  );
});

export default Roundel;
