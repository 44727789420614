/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import {
  getEditor,
  // getPreEditValues,
  getSlide,
  getStory,
  isEditorBeingSubmitted,
  transformSlideDataForApi,
} from "redux/editor/selectors";
import { getId as getMediaId } from "redux/media/selectors";
import { OCCASION_TYPES } from "redux/occasions/selectors";
import * as slideSelectors from "redux/slide/selectors";
import * as configSelectors from "redux/slideConfig/selectors";
import * as storySelectors from "redux/story/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
/* -Selectors */

/* Actions */
import * as contributionsActions from "redux/contributions/actions";
import * as slideActions from "redux/slide/actions";
import * as storyActions from "redux/story/actions";
import axios from "axios";
/* Actions */

import analytics from "utils/analyticsUtils";

export const editSlideProperty = createAction("EDITOR_EDIT_SLIDEPROP");
export const clearSlideData = createAction("EDITOR_CLEAR_SLIDEDATA");
export const editSlideData = createAction("EDITOR_EDIT_SLIDEDATA");
export const editSlideStyle = createAction("EDITOR_EDIT_SLIDESTYLE");
export const setEditorSlideData = createAction("EDITOR_SET_SLIDEDATA");
export const editStoryProperty = createAction("EDITOR_EDIT_STORYPROP");

export const setSlideMedia = createAction("EDITOR_SET_MEDIA");
export const setSpotifyTrack = createAction("EDITOR_SET_SPOTIFY");
export const setGiphyId = createAction("EDITOR_SET_GIPHY");
export const clearSlideMediaType = createAction("EDITOR_CLEAR_MEDIA");

export const setOccasion = createAction("EDITOR_SET_OCCASION");
export const setType = createAction("EDITOR_SET_TYPE");
export const setVariant = createAction("EDITOR_SET_VARIANT");
export const setTheme = createAction("EDITOR_SET_THEME");
export const setRelatedCardId = createAction("EDITOR_SET_RELATEDCARD");
export const setExportedId = createAction("EDITOR_SET_EXPORTEDID");
export const setEditorBeingSubmitted = createAction("EDITOR_SET_SUBMITTED");
export const setEditorSlideshowLoaded = createAction(
  "EDITOR_SET_SLIDESHOW_LOADED"
);
export const setAuthModalOpenValue = createAction("EDITOR_SET_AUTHOPEN");

// export const setPreEditValues = createAction("EDITOR_SET_PREEDITVALUES");
export const setEditsMade = createAction("EDITOR_SET_EDITSMADE");
export const setEditingNewKindeo = createAction("EDITOR_SET_NEWKINDEO");
export const setEditingCover = createAction("EDITOR_SET_COVER");
export const setInitialInputName = createAction("EDITOR_SET_INITIALINPUTNAME");
export const setEditingFieldName = createAction("EDITOR_SET_FIELDNAME");
export const setMobileKeyboardOpen = createAction("EDITOR_SET_KEYBOARDOPEN");

export const clearEditorData = createAction("EDITOR_CLEARDATA");
export const resetEditor = createAction("EDITOR_RESET");

const getRandomItem = items => {
  return items[Math.floor(Math.random() * items.length)];
};

const getDefaultItem = items => items.find(item => item.default);

export const autoSetVariant = variants => (dispatch, getState) => {
  let variantFilter = () => true;

  // Perform slide-specific logic to determine slide variant type
  const state = getState();
  const occasion = storySelectors.getStoryOccasionType(
    storySelectors.getStory(state)
  );
  const slide = getSlide(getEditor(state));
  // const media = slideSelectors.getMedia(slide);
  switch (slideSelectors.getType(slide)) {
    // Required for video - portrait should not default to TV
    // case slideTypes.VIDEO:
    //   if (isPortrait(media[0])) {
    //     variantFilter = variant =>
    //       configSelectors.getVariantName(variant) !== slideVariants[slideTypes.VIDEO].TV;
    //   }
    //   break;
    // Christmas occasion should default to xmas variant
    case slideTypes.TEXT:
      variantFilter = variant => {
        if (occasion === OCCASION_TYPES.CHRISTMAS) {
          return (
            configSelectors.getVariantName(variant) ===
            slideVariants[slideTypes.TEXT].XMAS
          );
        }

        return true;
      };
      break;
    default:
      break;
  }

  const permittedVariants = variants.filter(variant => {
    return (
      (!configSelectors.getVariantOccasion(variant) ||
        configSelectors.getVariantOccasion(variant) === occasion) &&
      !configSelectors.getVariantDisabled(variant) &&
      variantFilter(variant)
    );
  });

  const selectedVariant =
    getDefaultItem(variants) || getRandomItem(permittedVariants);

  dispatch(setVariant(configSelectors.getVariantName(selectedVariant)));
};

// set the updated time on the editor to now
// in order to nullify effect of haveLatestEditorData
export const setUpdatedToNow = () => dispatch => {
  dispatch(editSlideProperty({ updated_on: new Date().toISOString() }));
};

export const removeMediaById = mediaId => (dispatch, getState) => {
  const slide = getSlide(getEditor(getState()));
  const media = slideSelectors
    .getMedia(slide)
    .filter(mediaItem => mediaItem.id !== mediaId);

  dispatch(setSlideMedia(media));
};

// check if local slide data in editor is current or out of date
// default to true so that we don't keep trying to check with dodgy IDs or faulty data
export const haveLatestEditorData = slideId => (dispatch, getState) => {
  if (slideId) {
    const localSlide = getSlide(getEditor(getState()));
    if (slideSelectors.getId(localSlide)) {
      const lastLocalUpdate = slideSelectors.getUpdated(localSlide);

      return dispatch(slideActions.getLastSlideUpdate(slideId)).then(
        remoteSlide => {
          const lastRemoteUpdate = slideSelectors.getUpdated(remoteSlide);

          if (lastRemoteUpdate && lastLocalUpdate) {
            // guard against invalid or null dates
            try {
              return (
                new Date(lastLocalUpdate).getTime() >=
                new Date(lastRemoteUpdate).getTime()
              );
            } catch (e) {
              return Promise.resolve(false);
            }
          }
        }
      );
    }
  }

  return Promise.resolve(true);
};

export const updateEditorMedia = mediaArray => (dispatch, getState) => {
  const slide = getSlide(getEditor(getState()));
  const slideMedia = slideSelectors.getMedia(slide);
  let updatedSlideMedia = [...slideMedia];
  let isUpdated = false;

  mediaArray.forEach(mediaItem => {
    const existingMediaIndex = slideMedia.findIndex(
      item => getMediaId(item) === getMediaId(mediaItem)
    );

    if (existingMediaIndex !== -1) {
      isUpdated = true;
      updatedSlideMedia[existingMediaIndex] = mediaItem;
    }
  });

  if (isUpdated) {
    dispatch(setSlideMedia(updatedSlideMedia));
  }
};

export const setAuthModalOpen = authModalOpen => dispatch => {
  return dispatch(setAuthModalOpenValue({ authModalOpen }));
};

export const updateStoryFromEditor = finalise => (dispatch, getState) => {
  const storyData = getStory(getEditor(getState()));

  dispatch(setEditorBeingSubmitted(true));
  return dispatch(
    storyActions.modifyStoryDetails({
      greeting_from: storySelectors.getGreetingFrom(storyData),
      greeting_to: storySelectors.getGreetingTo(storyData),
      greeting_message: storySelectors.getGreetingMessage(storyData),
      occasion: storySelectors.getStoryOccasionType(storyData),
      ...(finalise ? { setup_card_complete: true } : {}),
    })
  )
    .then(result => {
      dispatch(setEditorBeingSubmitted(false));
      return result;
    })
    .catch(e => {
      dispatch(setEditorBeingSubmitted(false));
      throw e;
    });
};

// export const discardPreEditValues = () => dispatch => {
//   dispatch(setPreEditValues({}));
// };
// export const restorePreEditValues = () => (dispatch, getState) => {
//   const state = getState();
//   const preEditValues = getPreEditValues(state);
//   // this "initialCoverData" is set in the cover editor
//   // this "initialGreetingData" is set in the title greeting editor
//   const { initialCoverData, initialGreetingData, ...restPreEditValues } =
//     preEditValues;
//   const exportedId = slideSelectors.getExportedId(getSlide(getEditor(state)));
//   const preExportedId = slideSelectors.getExportedId(initialCoverData);
//   const preOccasion = storySelectors.getStoryOccasionType(initialCoverData);
//   const preVariant = slideSelectors.getVariant(initialCoverData);

//   // if this was a cover edit
//   if (preVariant) {
//     dispatch(
//       editSlideProperty({ exported_id: preExportedId, variant: preVariant })
//     );
//     dispatch(setOccasion(preOccasion));
//   } else if (initialGreetingData) {
//     dispatch(editStoryProperty({ ...initialGreetingData }));
//   }
//   // otherwise we were changing slide data
//   else {
//     // if this was an exported slide
//     if (exportedId) {
//       dispatch(editSlideData({ exportedId, slideData: restPreEditValues }));
//     }
//     // otherwise set the slide properties directly
//     else {
//       dispatch(editSlideProperty(restPreEditValues));
//     }
//   }

//   dispatch(discardPreEditValues());
// };

// Add the contents of the editor as a new pending contribution
export const addEditorAsPendingContribution = payload => dispatch => {
  const { groupToken } = payload;
  const saveSlide = slideData => {
    return dispatch(
      contributionsActions.addPendingContribution({
        groupToken,
        ...slideData,
      })
    );
  };

  return dispatch(addEditorAsSlide(saveSlide));
};
// Add the contents of the editor as a new approved contribution
export const addEditorAsContribution = payload => dispatch => {
  const { groupToken } = payload;
  const saveSlide = slideData => {
    return dispatch(
      contributionsActions.addPendingContribution({ groupToken, ...slideData })
    ).then(contributionId => {
      return dispatch(
        contributionsActions.completeContribution({
          contributionId,
          groupToken,
        })
      );
    });
  };

  return dispatch(addEditorAsSlide(saveSlide));
};
// Update the contents of the editor as an updated contribution
export const addEditorAsUpdatedContribution = () => dispatch => {
  const saveSlide = slideData => {
    const slideId = slideSelectors.getId(slideData);

    return axios.put(`slide/${slideId}`, { ...slideData });
  };

  return dispatch(addEditorAsSlide(saveSlide)).then(result => {
    const updatedSlide = result.data.slide;
    dispatch(contributionsActions.recreateContributionThumbnail(updatedSlide));
    analytics.event("Contribution Updated", {
      type: updatedSlide.type,
      media_count: updatedSlide.media.length,
    });
    return updatedSlide;
  });
};

export const addEditorAsUpdatedSlide =
  (generateThumbnail = true) =>
  dispatch => {
    const saveSlide = slideData => {
      return dispatch(storyActions.updateSlide(slideData, generateThumbnail));
    };

    return dispatch(addEditorAsSlide(saveSlide));
  };

export const addEditorAsNewSlide = () => dispatch => {
  const saveSlide = slideData => {
    return dispatch(storyActions.newSlide(slideData));
  };

  return dispatch(addEditorAsSlide(saveSlide));
};

const addEditorAsSlide = saveSlide => (dispatch, getState) => {
  const editor = getEditor(getState());
  const slideData = transformSlideDataForApi(getSlide(editor));

  // ensure this is only triggered once from GroupEditorContribute
  // which might be mounted multiple times
  if (!isEditorBeingSubmitted(editor)) {
    dispatch(setEditorBeingSubmitted(true));

    return saveSlide(slideData).then(result => {
      dispatch(setEditorBeingSubmitted(false));
      return result;
    });
  }

  return Promise.resolve(null);
};
