/* Libraries */
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
/* -Libraries */

/* Components */
import { CoreButton } from "components/_v2/Button";
import Modal from "components/Modal";
import ModalContents from "components/ModalContents";
import ModalHeader from "components/ModalHeader";
/* -Components */

/* Actions */
import { logout } from "redux/auth/actions";
import { setPageLoader } from "redux/loaders/actions";
/* -Actions */

/* Hooks */
import useModalDisplay from "hooks/useModalDisplay";
/* -Hooks */

import analytics from "utils/analyticsUtils";
import styles from "./index.module.scss";

const SignOutModal = props => {
  const { isOpen: isOpenParam, onClose, params } = props;
  const { doClose, duration, isOpen } = useModalDisplay(true);

  const dispatch = useDispatch();
  const [inProgress, inProgressSet] = useState();

  const closeModal = useCallback(() => {
    onClose && onClose();
    doClose();
  }, [doClose, onClose]);

  const doLogout = () => {
    inProgressSet(true);
    dispatch(logout());
    dispatch(setPageLoader(true));
    analytics.event("Logout");
  };

  const modal = (
    <Modal
      {...{ doClose: closeModal, duration, isOpen: isOpenParam ?? isOpen }}
      narrow
      {...params}
      trackingId="Logout Modal"
    >
      <ModalHeader title="Sign Out" onClose={closeModal} />

      <ModalContents
        className={classnames(styles.modalContents, "has-text-centered")}
        noMinHeight
      >
        <p className="mb-2_5">Are you sure you want to sign out?</p>
        <div className={classnames("buttons is-centered", styles.buttons)}>
          <CoreButton
            className={styles.signOutBtn}
            design="secondary"
            disabled={inProgress}
            loading={inProgress}
            onClick={doLogout}
          >
            Yes
          </CoreButton>
          <CoreButton
            design="primary"
            disabled={inProgress}
            onClick={closeModal}
          >
            No
          </CoreButton>
        </div>
      </ModalContents>
    </Modal>
  );

  return modal;
};

export default React.memo(SignOutModal);
