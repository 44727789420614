/* Libraries */
import { useEffect, useState } from "react";
/* -Libraries */

// Attach a `resize` event listener to window object
// and return and function to remove it on unmount
// pass an optional dependencyArray to update the cached callback function
export default function useOnInteraction(
  target = document,
  events = ["mousemove", "touchstart"]
) {
  const [interacted, interactedSet] = useState(false);
  useEffect(() => {
    const removeListeners = () => {
      events.forEach(eventType => {
        target.removeEventListener(eventType, detectInteraction, {
          capture: true,
          passive: true,
        });
      });
    };
    const detectInteraction = () => {
      interactedSet(current => {
        if (!current) {
          events.forEach(eventType => {
            target.removeEventListener(eventType, detectInteraction, {
              capture: true,
              passive: true,
            });
          });
        }
        return true;
      });
    };

    events.forEach(eventType => {
      target.addEventListener(eventType, detectInteraction, {
        capture: true,
        passive: true,
      });
    });

    // return the method to clean up
    return () => {
      removeListeners();
    };
  }, [events, target]);

  return interacted;
}
