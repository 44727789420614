export const SEND_METHODS = {
  EMAIL: "email",
  LINK: "link",
  POST: "post",
};

export const getAnonymousData = state => state.anonymousData;

export const getAuthScenario = state => getAnonymousData(state)?.authScenario;

export const getContributionId = state =>
  getAnonymousData(state)?.contributionId;
export const getEmail = state => getAnonymousData(state)?.email;
export const getGroupToken = state => getAnonymousData(state)?.groupToken;
export const getJoinToken = state => getAnonymousData(state)?.joinToken;
export const getFirstName = state => getAnonymousData(state)?.firstName;
export const getLastName = state => getAnonymousData(state)?.lastName;
export const getProductTier = state => getAnonymousData(state)?.productTier;
export const getSharedStoryId = state => getAnonymousData(state)?.sharedStoryId;
export const getSendMethod = state => getAnonymousData(state)?.sendMethod;
export const getPromptsCompleted = state => {
  return getAnonymousData(state).promptsCompleted || [];
};
export const getDidPlayAsRecipient = state =>
  getAnonymousData(state)?.didPlayAsRecipient;
export const getRecipientToken = state =>
  getAnonymousData(state)?.recipientToken;
export const getRecipientThanks = state =>
  getAnonymousData(state)?.recipientThanks;

export const getStoryId = state => getAnonymousData(state).storyId;

export const getBundleType = state => getAnonymousData(state).bundleType;
export const getBundleQuantity = state =>
  getAnonymousData(state).bundleQuantity;
