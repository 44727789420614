/* Libraries */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
import { BadgeText } from "components/_v2/BadgeText";
import Collapse from "components/Collapse";
/* -Components */

/* Hooks */
import useIsMounted from "hooks/useIsMounted";
import useTimeoutState from "hooks/useTimeoutState";
/* -Hooks */

import styles from "./index.module.scss";
import { TextButton } from "components/_v2/Button";

const ResendAuthCode = props => {
  const { className, onResend } = props;

  const isMounted = useIsMounted();
  const [isError, setError] = useTimeoutState(8000);

  const [resendStarted, resendStartedSetter] = useState(false);
  const [resendSuccess, resendSuccessSetter] = useState(false);
  const [resendError, resendErrorSetter] = useState();

  const resendLoginCode = useCallback(() => {
    resendErrorSetter("");
    resendStartedSetter(true);

    onResend()
      .then(() => {
        return new Promise(resolve => {
          resendSuccessSetter(true);
          window.setTimeout(() => {
            resendSuccessSetter(false);
            resolve();
          }, 2000);
        });
      })
      .catch(e => {
        setError(true);
        resendErrorSetter("We couldn’t resend a code.");
      })
      .finally(() => {
        if (isMounted()) {
          resendStartedSetter(false);
        }
      });
  }, [isMounted, onResend, setError]);

  const isResending = resendStarted && !resendSuccess;

  return (
    <div className={classnames("h6", className)}>
      <TextButton
        className={classnames(styles.button, {
          "is-loading": isResending,
          "has-text-link": !resendSuccess,
        })}
        disabled={resendSuccess}
        onClick={resendLoginCode}
        color="teal6"
        underlined={false}
      >
        {resendSuccess ? "New code sent!" : "Re-send code"}
      </TextButton>

      <Collapse show={isError && !!resendError} className="has-text-left">
        <BadgeText type="error" text={resendError} className="mt-1" />
      </Collapse>
    </div>
  );
};

ResendAuthCode.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  onResend: PropTypes.func.isRequired,
};

export default React.memo(ResendAuthCode);
