/* Libraries */
import React from "react";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
/* -Components */

/* Hooks */
/* -Hooks */

const NoOrphan = props => {
  const { children } = props;

  if (typeof children === "string") {
    const words = children.split(" ");
    const lastWord = words.pop();

    return (
      <>
        {words.join(" ")}&nbsp;{lastWord}
      </>
    );
  }
  return children;
};

NoOrphan.propTypes = {};

export default React.memo(NoOrphan);
