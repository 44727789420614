/* Libraries */
import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import classnames from "classnames";
/* -Libraries */

/* Components */
/* -Components */

/* Selectors */
/* -Selectors */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

/**
 * !Important
 * `props.isAbsolute` allows to set all the part of the animation (below - `centre`, `enter` and `exit`)
 * to absolute. It fixes a little jump when going from one tab to another (Edit Page). However, it needs to be optional
 * to not break FAQ, and other "fullHeight" modals.
 */
const positions = {
  centre: ({ isAbsolute }) => {
    return {
      opacity: 1,
      x: 0,
      position: isAbsolute ? "absolute" : "unset",
    };
  },
  enter: ({ direction, isAbsolute }) => {
    return {
      x: `${direction * -100}%`,
      opacity: 0,
      position: isAbsolute ? "absolute" : "unset",
    };
  },
  exit: ({ direction }) => {
    return {
      position: "absolute",
      x: `${direction * 100}%`,
      opacity: 0,
    };
  },
};

const panelTransition = { duration: 0.25 };

const ModalPanels = props => {
  const {
    children,
    className,
    currentPanel,
    onComplete,
    panelNames,
    isAbsolute,
  } = props;

  const prevPanel = useRef();
  const [[indexPanel, direction], setPanel] = useState([0, 0]);

  useEffect(() => {
    const index = panelNames.indexOf(currentPanel);
    if (index !== prevPanel.current) {
      const dir =
        prevPanel.current > index ? 1 : prevPanel.current < index ? -1 : 0;
      setPanel([index, dir]);
      prevPanel.current = panelNames.indexOf(currentPanel);
    }
  }, [children, currentPanel, panelNames]);

  return (
    <div className={classnames(styles.container, className)}>
      <AnimatePresence
        initial={false}
        onExitComplete={onComplete}
        mode="popLayout"
        custom={{ direction }}
      >
        {React.Children.map(
          children.filter(item => !!item),
          (panel, index) => {
            const name = panelNames[index];

            return (
              indexPanel === index && (
                <motion.div
                  key={name}
                  className={styles.modalPanel}
                  custom={{
                    direction,
                    isAbsolute,
                  }}
                  variants={positions}
                  transition={panelTransition}
                  initial="enter"
                  animate="centre"
                  exit="exit"
                >
                  {panel}
                </motion.div>
              )
            );
          }
        )}
      </AnimatePresence>
    </div>
  );
};

ModalPanels.propType = {
  children: PropTypes.node.isRequired,
  currentPanel: PropTypes.string,
  isAbsolute: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  panelNames: PropTypes.array.isRequired,
};

export default React.memo(ModalPanels);
