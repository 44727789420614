/* Selectors */
import * as trackSelectors from "redux/tracks/selectors";
/* -Selectors */

import { parseOrFallback } from "utils/numberUtils";

export const getSoundtrack = state => state.soundtrack.selected;
export const getLibrary = state => state.soundtrack.library;
export const getAction = state => state.soundtrack.action;

const makeGenre = (name, value = "") => {
  return { name, value };
};
export const getGenres = state => {
  const genreList = [
    ...new Set(getLibrary(state).map(trackSelectors.getGenre)),
  ].sort();
  return [
    makeGenre("All"),
    ...genreList.map(genre =>
      makeGenre(trackSelectors.makeReadableGenre(genre), genre)
    ),
  ];
};

export const getTrackById = (state, trackId) => {
  return getLibrary(state).find(
    track => trackSelectors.getId(track) === trackId
  );
};
export const getTrackIsInSoundtrack = (soundtrack, trackId) => {
  return (
    soundtrack.findIndex(track => trackSelectors.getId(track) === trackId) !==
    -1
  );
};

export const getSoundtrackLength = state => getSoundtrack(state).length;

export const getSoundtrackDuration = state =>
  getSoundtrack(state)?.reduce((accum, track) => {
    const trackDuration = parseOrFallback(trackSelectors.getDuration(track), 0);
    return accum + trackDuration;
  }, 0);

export const didAddTrack = action => !!action?.added;
export const didRemoveTrack = action => !!action?.removed;

export const playlistColours = {
  my_soundtrack: "teal5",
  acoustic: "pink6",
  ambient: "indigo6",
  classical: "teal7",
  electronic: "purple3",
  groove: "green6",
  happy: "yellow5",
  hiphop_blues: "indigo5",
  holiday: "green5",
  jazz: "yellow6",
  relaxing: "purple5",
  sentimental: "orange5",
  special_occasion: "red5",
  wedding: "neutral3",
};
