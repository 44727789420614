/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import * as selectors from "./selectors";
/* -Selectors */

const INITIAL_STATE = [];

const setAddresses = (state, { payload }) => payload;

const updateAddress = (state, { payload }) =>
  state.map(address =>
    selectors.getId(address) === selectors.getId(payload)
      ? { ...address, ...payload }
      : address
  );

const addAddress = (state, { payload }) => {
  return [...state, payload];
};

const removeAddress = (state, { payload }) => {
  return state.filter(address => selectors.getId(address) !== payload);
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.addressesFetched]: setAddresses,
  [actions.addressEdited]: updateAddress,
  [actions.addressAdded]: addAddress,
  [actions.addressRemoved]: removeAddress,
  [authActions.logoutInit]: reset,
});
