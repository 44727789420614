/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = { contributions: [], latestContributionAccepted: 0 };

const setContributions = (state, { payload }) => payload;

const setLatestContributionAccepted = (state, { payload }) => {
  return {
    ...state,
    latestContributionAccepted: payload,
  };
};

const updateContribution = (state, { payload }) => {
  return {
    ...state,
    contributions: state.contributions.map(contribution =>
      contribution.id === payload.id
        ? { ...contribution, ...payload }
        : contribution
    ),
  };
};

const markContributionSeen = (state, { payload }) =>
  updateContribution(state, {
    payload: { id: payload.contribution_id, seen: true },
  });

const clearContributionThumbnail = (state, { payload }) =>
  updateContribution(state, {
    payload: { id: payload, thumbnail_url: null, thumbnail_id: null },
  });

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.contributionsSeenSuccess]: markContributionSeen,
  [actions.contributionsFetchSuccess]: setContributions,
  [actions.createThumbnailSuccess]: updateContribution,
  [actions.acceptContribution]: setLatestContributionAccepted,
  [actions.clearContributionThumbnail]: clearContributionThumbnail,
  [actions.updateContribution]: updateContribution,
  [authActions.logoutInit]: reset,
});
