/* Libraries */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import { CoreButton, InverseButton } from "components/_v2/Button";
import Modal from "components/Modal";
import ModalContents from "components/ModalContents";
import ModalContentTitle from "components/ModalContentTitle";
import ModalHeader from "components/ModalHeader";
/* -Components */

/* Hooks */
import useModalDisplay from "hooks/useModalDisplay";
/* -Hooks */

import styles from "./index.module.scss";

const ConfirmationModal = props => {
  const {
    buttonColumn,
    cancelBtnText,
    confirmBtnText,
    isDestructive,
    isOpen,
    message,
    messageHeader,
    onClose,
    params,
    onConfirm,
    onCancel,
    submitting,
    title,
    trackingId,
  } = props;
  const { duration } = useModalDisplay();

  return (
    <Modal
      {...{ duration, isOpen, trackingId }}
      {...params}
      doClose={onClose}
      narrow
      modalOnModal
    >
      <ModalHeader title={title} onClose={onClose} />
      <ModalContents className="has-text-centered">
        {messageHeader && (
          <ModalContentTitle>{messageHeader}</ModalContentTitle>
        )}
        {message && <div className="body mb-2_5">{message}</div>}
        <div
          className={classnames("buttons", styles.buttons, {
            [styles.column]: buttonColumn,
          })}
        >
          {!isDestructive && (
            <CoreButton
              design="primary"
              onClick={onConfirm}
              className="is-fullwidth"
              loading={submitting}
              disabled={submitting}
            >
              {confirmBtnText || "OK"}
            </CoreButton>
          )}
          {isDestructive && (
            <InverseButton
              filled
              colour="delete"
              onClick={onConfirm}
              text={confirmBtnText || "OK"}
              className={classnames(
                submitting ? "is-loading" : "",
                "is-fullwidth"
              )}
              disabled={submitting}
            />
          )}
          <CoreButton
            design="secondary"
            onClick={onCancel}
            disabled={submitting}
            className="is-full-width"
          >
            {cancelBtnText || "Cancel"}
          </CoreButton>
        </div>
      </ModalContents>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  buttonColumn: PropTypes.bool,
  cancelBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  isDestructive: PropTypes.bool,
  isOpen: PropTypes.bool,
  messageHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  params: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  trackingId: PropTypes.string.isRequired,
};

export default React.memo(ConfirmationModal);
