/* Libraries */
import { useCallback, useState } from "react";
/* -Libraries */

const useModalPanelAnimation = (panelNames, defaultPanelName) => {
  const [defaultPanel] = useState(defaultPanelName || panelNames[0]);
  const [currentPanel, currentPanelSetter] = useState(defaultPanel);
  const [animationComplete, animationCompleteSetter] = useState(true);

  const [clipOverflow, clipOverflowSetter] = useState(false);
  const goTo = useCallback(
    panelName => {
      if (defaultPanel !== currentPanel) {
        animationCompleteSetter(false);
      }
      clipOverflowSetter(true);
      currentPanelSetter(panelName);
    },
    [currentPanel, defaultPanel]
  );
  const onComplete = useCallback(() => {
    clipOverflowSetter(false);
    animationCompleteSetter(true);
  }, []);

  return {
    animationComplete,
    clipOverflow,
    currentPanel,
    defaultPanel,
    goTo,
    onComplete
  };
};

export default useModalPanelAnimation;
