/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
/* -Selectors */

/* -Actions */
/* -Actions */

export const actions = {
  clearSearch: createAction("SEARCH_CLEAR"),
  setSearchFilter: createAction("SEARCH_FILTER_SET"),
  setSearchTerm: createAction("SEARCH_TERM_SET"),
};

export const clearSearch = () => dispatch => {
  dispatch(actions.clearSearch());
};

export const setSearchTerm = value => dispatch => {
  dispatch(actions.setSearchTerm(value));
};

export const setSearchFilter = (property, value) => dispatch => {
  dispatch(actions.setSearchFilter({ property, value }));
};
