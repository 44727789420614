import "core-js/features/promise/finally";
import "core-js/features/promise/all-settled";
import "core-js/proposals/string-replace-all-stage-4";
import "intersection-observer";

import AudioRecorder from "audio-recorder-polyfill";
import mpegEncoder from "audio-recorder-polyfill/mpeg-encoder";

if (!window.MediaRecorder) {
  AudioRecorder.encoder = mpegEncoder;
  AudioRecorder.prototype.mimeType = "audio/mpeg";

  window.MediaRecorder = AudioRecorder;
}
