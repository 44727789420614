export const qualityType = {
  low: "low",
  medium: "medium",
  high: "high"
};

export const sizes = {
  low: { w: 512, h: 512 },
  medium: { w: 1024, h: 576 },
  high: { w: 1600, h: 1200 }
};
