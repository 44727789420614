/* Libraries */
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import useLoaderLocation from "hooks/useLoaderLocation";
/* -Libraries */

/* Selectors */
/* -Selectors */

/* Components */
import {
  Header,
  HeaderAccount,
  HeaderAccountMobile,
  HeaderLanding,
  HeaderPrivate,
  HeaderPublic,
} from "components/Header";
/* -Components */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useBreakpoint from "hooks/useBreakpoint";
/* -Hooks */

import {
  paths,
  editPaths,
  editPageWithoutNavBar,
  landingPages,
  createCardPaths,
} from "routes/PageRoutes";

const HeaderSummary = lazy(() => import("components/Header/HeaderSummary"));
const HeaderContributorFlow = lazy(
  () => import("components/Header/HeaderContributorFlow")
);
const HeaderEditPage = lazy(() => import("components/Header/HeaderEditPage"));
const HeaderCreatePage = lazy(
  () => import("components/Header/HeaderCreatePage")
);
const HeaderRecipientFlow = lazy(
  () => import("components/Header/HeaderRecipientFlow")
);
const HeaderReminderPage = lazy(
  () => import("components/Header/HeaderReminderPage")
);

const HeaderRoutes = props => {
  const { webGLSupported } = props;
  const isLoggedIn = useAuthStatus();
  const { isMobile } = useBreakpoint();

  const loaderLocation = useLoaderLocation();

  const params = { isLoggedIn };

  return (
    <Switch location={loaderLocation}>
      {/* unsupported tech can only see this route */}
      {!webGLSupported && <Route></Route>}

      {/* landing specific */}
      <Route
        path={[
          ...landingPages,
          paths.landingHowItWorks(),
          paths.landingPricing(),
          paths.landingPricingBundles(),
          paths.landingBusinessPricing(),
          // paths.landingWhyKindeo(),
        ]}
        exact
      >
        <HeaderLanding isLoggedIn={isLoggedIn} isLp />
      </Route>
      {/* only Kindeo logo, no account nav or login */}
      <Route path={[paths.login(), paths.unsupported()]} exact>
        <Header isLoggedIn={isLoggedIn} />
      </Route>

      {/* recipient flow navigation buttons */}
      <Route path={paths.recipient(":recipientToken")}>
        <Suspense fallback={null}>
          <HeaderRecipientFlow {...params} />
        </Suspense>
      </Route>

      {/* contributor flow navigation buttons */}
      <Route path={paths.group(":groupToken")}>
        <Suspense fallback={null}>
          <HeaderContributorFlow {...params} />
        </Suspense>
      </Route>

      <Route path={paths.thumbnailGenerator()}></Route>
      <Route path={paths.home()}>
        <Suspense fallback={null}>
          <HeaderSummary {...params} />
        </Suspense>
      </Route>
      <Route path={paths.shop()}>
        <Suspense fallback={null}>
          <HeaderLanding isLoggedIn={isLoggedIn} isLp />
        </Suspense>
      </Route>
      <Route path={createCardPaths}>
        <Suspense fallback={null}>
          <HeaderCreatePage isLoggedIn={isLoggedIn} />
        </Suspense>
      </Route>
      <Route path={paths.account()} exact>
        <HeaderAccount {...params} />
      </Route>
      <Route path={paths.account()}>
        {!isMobile ? (
          <HeaderAccount {...params} />
        ) : (
          <Switch>
            <Route path={paths.accountAddresses()} exact>
              <HeaderAccountMobile title="Saved Addresses" {...params} />
            </Route>
            <Route path={paths.accountAddressDetails(":id")}>
              <HeaderAccountMobile title="Edit Address" {...params} />
            </Route>
            <Route path={paths.accountBundles()}>
              <HeaderAccountMobile title="Bundle Credits" {...params} />
            </Route>
            <Route path={paths.accountPayments()} exact>
              <HeaderAccountMobile title="Order History" {...params} />
            </Route>
            <Route path={paths.accountPaymentDetails(":id")}>
              <HeaderAccountMobile title="Order Details" {...params} />
            </Route>
            <Route path={paths.accountProfile()}>
              <HeaderAccountMobile title="Profile" {...params} />
            </Route>
          </Switch>
        )}
      </Route>
      <Route path={paths.reminders()} exact>
        <Suspense fallback={null}>
          <HeaderReminderPage {...params} showHomeLink />
        </Suspense>
      </Route>
      <Route path={paths.reminders()}>
        <Suspense fallback={null}>
          <HeaderReminderPage {...params} />
        </Suspense>
      </Route>
      {/* All main edit pages */}
      <Route
        path={editPaths.filter(p => !editPageWithoutNavBar.includes(p))}
        exact
      >
        <Suspense fallback={null}>
          <HeaderEditPage showHomeLink {...params} />
        </Suspense>
      </Route>
      {/* All other edit sub pages */}
      <Route path={paths.edit(":id")}>
        <Suspense fallback={null}>
          <HeaderEditPage {...params} />
        </Suspense>
      </Route>

      {/* Default: Logo with either account nav or login  */}
      <Route>
        {isLoggedIn ? (
          <HeaderPrivate {...params} />
        ) : (
          <HeaderPublic {...params} />
        )}
      </Route>
    </Switch>
  );
};

export default React.memo(HeaderRoutes);
