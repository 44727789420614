/* Libraries */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { setAnonymousAuthScenario } from "redux/anonymousData/actions";
/* -Actions */

/* Selectors */
import * as anonSelectors from "redux/anonymousData/selectors";
import { authScenarios } from "redux/auth/selectors";
/* -Selectors */

/* Hooks */
import useAuthStatus from "./useAuthStatus";
/* -Hooks */

const selector = createSelector(
  [
    anonSelectors.getContributionId,
    anonSelectors.getJoinToken,
    anonSelectors.getRecipientToken,
    anonSelectors.getRecipientThanks,
    anonSelectors.getStoryId,
  ],
  (contributionId, joinToken, recipientToken, recipientThanks, storyId) => {
    return {
      contributionId,
      joinToken,
      recipientToken,
      recipientThanks,
      storyId,
    };
  }
);

// auth scenarios are almost always provided at the start of the auth
// process but if it wasn't, derive what the scenrio is from the data provided
const useSetAuthScenario = specifiedAuthScenario => {
  const {
    contributionId,
    joinToken,
    recipientToken,
    recipientThanks,
    storyId,
  } = useSelector(selector);
  const dispatch = useDispatch();
  const isLoggedIn = useAuthStatus();

  let [authScenario, setAuthScenario] = useState(specifiedAuthScenario);

  useEffect(() => {
    if (isLoggedIn) {
      setAuthScenario(null);
    } else if (!specifiedAuthScenario) {
      if (contributionId) {
        setAuthScenario(authScenarios.CONTRIBUTION);
      } else if (recipientToken) {
        if (recipientThanks) {
          setAuthScenario(authScenarios.RECIPIENT_THANKS);
        } else {
          setAuthScenario(authScenarios.RECIPIENT_SAVE);
        }
      } else if (joinToken) {
        setAuthScenario(authScenarios.PRIVATE_JOIN);
      } else if (storyId) {
        setAuthScenario(authScenarios.CREATOR_SAVE);
      }
    }
  }, [
    contributionId,
    isLoggedIn,
    joinToken,
    recipientThanks,
    recipientToken,
    specifiedAuthScenario,
    storyId,
  ]);

  useEffect(() => {
    dispatch(setAnonymousAuthScenario(authScenario));
  }, [authScenario, dispatch]);
};

export default useSetAuthScenario;
