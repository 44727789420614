export const themes = [
  {
    name: "boat-0",
    text: { color: "0x393c54" },
    background: {
      color: "0xdef3f5",
      properties: {
        type: "Squares",
        alpha: 0.2,
        size: { x: 0.19117647058823528, y: 0.20909926470588225 },
        columnWidth: 59.891304347826086,
        color: [0.5764705882352941, 0.6705882352941176, 0.8392156862745098],
      },
    },
    scene: {
      miniSceneColor: 12639484,
      type: "BoatScene",
      sea: {
        boat: {
          colors: {
            base: "#e06a50",
            paint: "#eace85",
            post: "#6c4800",
            sails: "#dcf0ff",
          },
        },
      },
    },
  },
  {
    name: "boat-1",
    text: { color: "0x393c54" },
    background: {
      color: "0xf8f4c3",
      properties: {
        type: "Squares",
        alpha: 0.15217391304347827,
        size: { x: 0.22058823529411775, y: 0.23851102941176472 },
        columnWidth: 59.891304347826086,
        color: [0.9490196078431372, 0.6980392156862745, 0.41568627450980394],
      },
    },
    scene: {
      miniSceneColor: 16761458,
      type: "BoatScene",
      sea: {
        boat: {
          colors: {
            base: "#80a3b3",
            paint: "#42496c",
            post: "#6c4800",
            sails: "#ffeed0",
          },
        },
      },
    },
  },
  {
    name: "boat-2",
    text: { color: "0xd0d6fa" },
    background: {
      color: "0x27314c",
      properties: {
        type: "Dots",
        alphaMin: 0.07608695652173914,
        alphaGlobal: 0.358695652173913,
        scaleValue: 0.5,
        alphaValue: 0.591304347826087,
        radius: 0.01,
        columnWidth: 29.565217391304348,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 2696797,
      type: "BoatScene",
      sea: {
        boat: {
          colors: {
            base: "#5e7a8c",
            paint: "#42496c",
            post: "#434343",
            sails: "#cdcbf2",
          },
        },
      },
    },
  },
  {
    name: "boat-3",
    text: { color: "0xefd07f" },
    background: {
      color: "0x0664a2",
      properties: {
        type: "Dots",
        alphaMin: 0.07608695652173914,
        alphaGlobal: 0.358695652173913,
        scaleValue: 0.9782608695652174,
        alphaValue: 0.44347826086956527,
        radius: 1,
        columnWidth: 15.869565217391305,
        color: [0, 0.12549019607843137, 0.28627450980392155],
      },
    },
    scene: {
      miniSceneColor: 15716479,
      type: "BoatScene",
      sea: {
        boat: {
          colors: {
            base: "#3961c8",
            paint: "#98abe8",
            post: "#c5a7ba",
            sails: "#fbfbfb",
          },
        },
      },
    },
  },

  {
    name: "boat-4",
    text: { color: "0xf5f5f5" },
    background: {
      color: "0xbf7eb3",
      properties: {
        type: "Dots",
        alphaMin: 0,
        alphaGlobal: 0.45652173913043476,
        scaleValue: 1.9565217391304348,
        alphaValue: 0.8,
        radius: 1,
        columnWidth: 10,
        color: [0.6, 0.3137254901960784, 0.5137254901960784],
      },
    },
    scene: {
      miniSceneColor: 16757941,
      type: "BoatScene",
      sea: {
        boat: {
          colors: {
            base: "#7858a6",
            paint: "#e8bb68",
            post: "#6c4800",
            sails: "#fdf8ff",
          },
        },
      },
    },
  },
  {
    name: "boat-5",
    text: { color: "0xffffff" },
    background: {
      color: "0x4b81f2",
      properties: {
        type: "Dots",
        alphaMin: 0,
        alphaGlobal: 0.358695652173913,
        scaleValue: 0.9782608695652174,
        alphaValue: 0.42608695652173917,
        radius: 1,
        columnWidth: 15.869565217391305,
        color: [0.09803921568627451, 0.24705882352941178, 0.3607843137254902],
      },
    },
    scene: {
      miniSceneColor: 10870783,
      type: "BoatScene",
      sea: {
        boat: {
          colors: {
            base: "#cc1313",
            paint: "#ffeeed",
            post: "#950202",
            sails: "#e3f6ff",
          },
        },
      },
    },
  },
  {
    name: "boat-6",
    text: { color: "0xffffff" },
    background: {
      color: "0x000000",
      properties: {
        type: "Dots",
        alphaMin: 0.9347826086956522,
        alphaGlobal: 1,
        scaleValue: 0.5,
        alphaValue: 0.591304347826087,
        radius: 0.01,
        columnWidth: 29.565217391304348,
        color: [0.16862745098039217, 0.058823529411764705, 0.9490196078431372],
      },
    },
    scene: {
      miniSceneColor: 4627711,
      type: "BoatScene",
      sea: {
        boat: {
          colors: {
            base: "#f82c00",
            paint: "#ffb800",
            post: "#6c4800",
            sails: "#dcf0ff",
          },
        },
      },
    },
  },
  // {
  //   name: "boat-5",
  //   text: { color: "0x0009ff" },
  //   background: {
  //     color: "0x67ff8f",
  //     properties: {
  //       type: "Squares",
  //       alpha: 0.2,
  //       size: { x: 0.1, y: 0.1 },
  //       columnWidth: 59.891304347826086,
  //       color: [0.5764705882352941, 0.6705882352941176, 0.8392156862745098],
  //     },
  //   },
  //   scene: {
  //     miniSceneColor: 2559,
  //     type: "BoatScene",
  //     sea: {
  //       boat: {
  //         colors: {
  //           base: "#ff6af1",
  //           paint: "#67ff8f",
  //           post: "#6c4800",
  //           sails: "#dcf0ff",
  //         },
  //       },
  //     },
  //   },
  // },
];
