export const LOADER_ANIMATIONS = {
  COMETS: "comets",
  DROPLET: "droplet",
  ENVELOPE: "envelope",
};

export const getAnimation = state => state.loaders.animation;

export const isAppLoading = state => state.loaders.app;
export const isPageLoading = state => state.loaders.page;
export const isLoading = state => isAppLoading(state) || isPageLoading(state);

export const isMediaPoolLoaded = state => state.loaders.mediaPool;

export const pageLoaderFadeDuration = 200;
