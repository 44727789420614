/* Libraries */
import React, { useEffect, useMemo, useState } from "react";
import { map } from "kindeo-webgl/utils";
import useOnResize from "hooks/useOnResize";
import { useCallback } from "react";
import { noop } from "utils/clientUtils";
import { throttle } from "throttle-debounce";
/* -Libraries */

/**
 *
 * RenderProp component to track an element position (on scroll + resize)
 * Exposes:
 * - style: for the div to move to (positions, transforms)
 * - arrow style: for the tooltip nipple (which side it should be on, pointing where, etc.)
 */
const TrackElementPosition = props => {
  const {
    ax = 0,
    ox = 0,
    oy = 0,
    tx = 0,
    ty = 0,
    delay = 0,
    element,
    render = noop,
  } = props;

  const [alpha, setAlpha] = useState(0);
  const [coords, setCoords] = useState({ x: 100, y: 0 });

  const onResize = useCallback(() => {
    if (!element) return;

    const rect = element.getBoundingClientRect();
    setCoords({
      x: rect.x + rect.width * ((tx + 1) / 2) + ax * -tx * rect.width,
      y: rect.y + rect.height * ((ty + 1) / 2),
    });
  }, [ax, element, tx, ty]);

  useOnResize(onResize, 200);

  useEffect(() => {
    let idTimeout;
    if (delay > 0) {
      idTimeout = setTimeout(() => {
        setAlpha(1);
        onResize();
      }, delay);
    } else {
      setAlpha(1);
    }
    return () => {
      idTimeout && clearTimeout(idTimeout);
    };
  }, [delay, onResize]);

  useEffect(() => {
    const throttledCallback = throttle(200, () => {
      onResize();
    });

    document.addEventListener("scroll", throttledCallback, true);

    return () => {
      document.removeEventListener("scroll", throttledCallback, true);
    };
  });

  const { x, y } = coords;

  const style = useMemo(() => {
    return {
      display: x + y > 0 && alpha !== 0 ? "block" : "none",
      y: y + oy,
      x: x + ox,
      transform: `translate(${map(tx, -1, 1, -100, 0)}%, ${map(
        ty,
        -1,
        1,
        -100,
        0
      )}%)`,
    };
  }, [alpha, ox, oy, tx, ty, x, y]);

  const styleArrow = useMemo(() => {
    // tooltip nipple position
    // clem to future clem: I am sorry
    const tooltipWidth = 10;
    let scaleX = tx === 1 ? 1 : -1; // sounds stupid but tx can be = 0.5
    let angle = ty * 45;

    const absTx = Math.abs(tx);
    if (absTx !== 1 || ax !== 0) {
      scaleX = 1;
      angle = 90 * ty;
    }

    let translationX = 0;
    let translationY = ty === 0 ? -tooltipWidth : tx === 0 ? -tooltipWidth : 0;
    if (Math.abs(angle) === 45) {
      translationY = -tooltipWidth;
      translationX = tooltipWidth;
    } else if (ax !== 0) {
      translationY =
        tooltipWidth * 1 * (tx > 0 ? 1 : -2) + (tx < 0 ? -tooltipWidth : 0);
      if (ty === 1) {
        translationY += 20;
        translationY *= -1;
      }
    }
    return {
      left: tx === 1 ? 0 : tx === -1 ? "100%" : `${50 - tx * 100}%`,
      top: ty === 1 ? "0px" : ty === -1 ? "100%" : "50%",
      transform: `scaleX(${scaleX}) rotate(${angle}deg) translate(${translationX}px, ${translationY}px)
      `,
    };
  }, [ax, tx, ty]);

  return render({ style, styleArrow });
};

TrackElementPosition.propTypes = {};

export default React.memo(TrackElementPosition);
