export const themes = [
  // {
  //   name: "ripples-0",
  //   colorsRipples: [0.094, 0.847, 0.945, 0.09, 0.941, 0.866],
  //   colorsText: [
  //     "#7604ef",
  //     "#9d4df2",
  //     "#b780f2",
  //     "#7604ef",
  //     "#9d4df2",
  //     "#7529c5",
  //   ],
  // },
  {
    name: "ripples-1",
    colorsRipples: [0.98, 0.776, 0.913, 0.941, 0.09, 0.415],
    colorsText: [
      "#4646d8",
      "#5c5ceb",
      "#2828ac",
      "#4646d8",
      "#5c5ceb",
      "#2828ac",
    ],
  },

  {
    name: "ripples-2",
    colorsRipples: [0.949, 0.956, 0.325, 0.98, 0.835, 0],
    colorsText: [
      "#f20aa4",
      "#f50fa7",
      "#f800a4",
      "#f815ab",
      "#f513a9",
      "#eb0ea0",
    ],
  },
  // {
  //   name: "ripples-3",
  //   colorsRipples: [1, 0, 0.78, 1, 0.4, 0.87],
  //   colorsText: [
  //     "#2626cc",
  //     "#2c2cd5",
  //     "#3d3ddf",
  //     "#0b0be8",
  //     "#3131db",
  //     "#4e4edf",
  //   ],
  // },
  {
    name: "ripples-4",
    colorsRipples: [0, 0.2, 0.356, 0.141, 0.36, 0.537],
    colorsText: [
      "#cfdcff",
      "#dccfff",
      "#f5cfff",
      "#ffcff5",
      "#ffcfdc",
      "#ffcfdc",
    ],
  },
  {
    name: "ripples-5",
    colorsRipples: [0.968, 0.964, 0.952, 0.905, 0.905, 0.898],
    colorsText: [
      "#ff2ec7",
      "#e000ac",
      "#c20091",
      "#a40078",
      "#87005f",
      "#6a0047",
    ],
  },
  {
    name: "ripples-6",
    colorsRipples: [0.027, 0.047, 0.09, 0.203, 0.341, 0.627],
    colorsText: [
      "#edbb13",
      "#ffd64c",
      "#daa801",
      "#e1b41d",
      "#fde182",
      "#ae9440",
    ],
  },
  // {
  //   name: "ripples-7",
  //   colorsRipples: [0.035, 0.956, 0.019, 0.929, 0.98, 0],
  //   colorsText: [
  //     "#0400db",
  //     "#b000ff",
  //     "#0400db",
  //     "#b000ff",
  //     "#0400db",
  //     "#b000ff",
  //   ],
  // },
];
