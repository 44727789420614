/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
import { actions as storyActions } from "redux/story/actions";
/* -Actions */

/* Selectors */
import { DEFAULT_CARD_THEME } from "./selectors";
/* -Selectors */

const INITIAL_STATE = DEFAULT_CARD_THEME;

const setCardTheme = (state, { payload }) => {
  return payload;
};

const modifyCardTheme = (state, { payload }) => ({
  ...state,
  ...payload,
});

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.clearCardTheme]: reset,
  [actions.setCardTheme]: setCardTheme,
  [actions.modifyCardTheme]: modifyCardTheme,
  [authActions.logoutInit]: reset,
  [storyActions.clearStory]: reset,
});
