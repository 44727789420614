/* Libraries */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
/* -Libraries */

/* Hooks */
import useModals from "hooks/useModals";
import usePageLoader from "hooks/usePageLoader";
import usePrevious from "hooks/usePrevious";
/* -Hooks */

import { paths } from "routes/PageRoutes";

/* Modals */

const AuthModal = lazy(() => import("modals/AuthModal"));
const ContributionBuilderModal = lazy(
  () => import("modals/ContributionBuilderModal")
);
const DeleteAccountModal = lazy(() => import("modals/DeleteAccountModal"));
const DeleteSlideModal = lazy(() => import("modals/DeleteSlideModal"));
const DeleteStoryModal = lazy(() => import("modals/DeleteStoryModal"));
const EffectsModal = lazy(() => import("modals/EffectsModal"));
const FaqModal = lazy(() => import("modals/FaqModal"));
const PostAuthCompletedModal = lazy(
  () => import("modals/PostAuthCompletedModal")
);
const PostAuthContributionSentModal = lazy(
  () => import("modals/PostAuthContributionSentModal")
);
const PostAuthCreatorSavedModal = lazy(
  () => import("modals/PostAuthCreatorSavedModal")
);
const PostAuthRecipientSavedModal = lazy(
  () => import("modals/PostAuthRecipientSavedModal")
);
const PrivacyModal = lazy(() => import("modals/LegalModal/Privacy"));
const DeleteRole = lazy(() => import("modals/DeleteRole"));
const LeaveGroupModal = lazy(() => import("modals/LeaveGroupModal"));
const SlideBuilderModal = lazy(() => import("modals/SlideBuilderModal"));
const SignOutModal = lazy(() => import("modals/SignOutModal"));
const NameChangedSuccessModal = lazy(() => import("modals/SuccessModal"));
const QrCodeModal = lazy(() => import("modals/QrCodeModal"));
const TermsModal = lazy(() => import("modals/LegalModal/Terms"));
const TermsCorporateModal = lazy(
  () => import("modals/LegalModal/TermsCorporate")
);
const PurchaseUpgradeModal = lazy(() => import("modals/PurchaseUpgradeModal"));
/* -Modals */

export const modals = {
  auth: () => "auth",
  bundle: () => "bundle",
  contributionSlideBuilder: () => "contribution",
  deleteAccount: () => "delete-account",
  deleteRole: () => "role-delete",
  deleteSlide: () => "delete-slide",
  deleteStory: () => "delete-kindeo",
  discardChanges: () => "discard-changes",
  effects: () => "special-effects",
  faq: () => "faq",
  invite: () => "invite",
  leaveGroup: () => "leave-group",
  library: () => "library",
  nameChangedSuccess: () => "name-changed-success",
  postAuthCompleted: () => "auth-success",
  postAuthContributionSent: () => "contribution-sent",
  postAuthCreatorSaved: () => "kindeo-saved",
  postAuthRecipientSaved: () => "recipient-saved",
  privacy: () => "privacy",
  qr: () => "qr",
  signOut: () => "sign-out",
  slideBuilder: () => "slide",
  termsCorporate: () => "corporate-terms",
  terms: () => "terms",
  tooltip: () => "tooltip",
  upgrade: () => "upgrade",
};

const ModalRoutes = props => {
  const { getModal } = useModals();
  const { isPageLoading } = usePageLoader();

  const { modal, params } = getModal();

  // avoid showing modals until any loader has gone
  // but allow modals to stay open if the the loader opens on top of it
  const [permitModal, permitModalSetter] = useState(false);
  const isPageLoadingPrev = usePrevious(isPageLoading);
  useEffect(() => {
    permitModalSetter(!isPageLoading || !isPageLoadingPrev);
  }, [isPageLoading, isPageLoadingPrev]);

  if (!modal) return null;

  if (permitModal) {
    return (
      <Suspense fallback={<div />}>
        <Switch>
          {/* route-specific modals can be rendered here */}
          <Route path={paths.editSettings(":id")}>
            <SettingsModals {...{ modal, params }} />
          </Route>
          <Route path={paths.account()}>
            <AccountModals {...{ modal, params }} />
          </Route>
          <Route path={[paths.edit(":id"), paths.editSlides(":id")]}>
            <EditModals {...{ modal, params }} />
          </Route>
          <Route path={paths.group(":groupToken")}>
            <GroupModals {...{ modal, params }} />
          </Route>
          <Route path={paths.recipient(":recipientToken")}>
            <RecipientModals {...{ modal, params }} />
          </Route>
          <Route path={paths.home()}>
            <SummaryModals {...{ modal, params }} />
          </Route>
          <Route>
            <GlobalModals {...{ modal, params }} />
          </Route>
        </Switch>
      </Suspense>
    );
  }

  return null;
};

const AccountModals = React.memo(props => {
  const { modal, params } = props;

  let Modal;
  switch (modal) {
    case modals.deleteAccount():
      Modal = DeleteAccountModal;
      break;
    case modals.discardChanges():
      Modal = DeleteAccountModal;
      break;
    case modals.nameChangedSuccess():
      Modal = NameChangedSuccessModal;
      break;

    default:
  }

  if (Modal) {
    return <Modal params={params} />;
  }

  return <GlobalModals {...props} />;
});

const SettingsModals = React.memo(props => {
  const { modal, params } = props;

  let Modal;
  switch (modal) {
    case modals.deleteStory():
      Modal = DeleteStoryModal;
      break;
    default:
  }

  if (Modal) {
    return <Modal params={params} />;
  }

  return <GlobalModals {...props} />;
});

const EditModals = React.memo(props => {
  const { modal, params } = props;

  let Modal;
  switch (modal) {
    case modals.deleteSlide():
      Modal = DeleteSlideModal;
      break;
    case modals.qr():
      Modal = QrCodeModal;
      break;
    case modals.deleteRole():
      Modal = DeleteRole;
      break;
    case modals.effects():
      Modal = EffectsModal;
      break;
    case modals.upgrade():
      Modal = PurchaseUpgradeModal;
      break;
    default:
  }

  if (Modal) {
    return <Modal params={params} />;
  }

  return <GlobalModals {...props} />;
});

const SummaryModals = React.memo(props => {
  const { modal, params } = props;

  let Modal;
  switch (modal) {
    case modals.deleteStory():
      Modal = DeleteStoryModal;
      break;

    default:
  }

  if (Modal) {
    return <Modal params={params} />;
  }

  return <GlobalModals {...props} />;
});

const GroupModals = React.memo(props => {
  const { modal, params } = props;

  let Modal;
  switch (modal) {
    case modals.contributionSlideBuilder():
      Modal = ContributionBuilderModal;
      break;
    case modals.postAuthContributionSent():
      Modal = PostAuthContributionSentModal;
      break;
    case modals.leaveGroup():
      Modal = LeaveGroupModal;
      break;

    default:
  }

  if (Modal) {
    return <Modal params={params} />;
  }

  return <GlobalModals {...props} />;
});

const RecipientModals = React.memo(props => {
  const { modal, params } = props;

  let Modal;
  switch (modal) {
    case modals.postAuthRecipientSaved():
      Modal = PostAuthRecipientSavedModal;
      break;

    default:
  }

  if (Modal) {
    return <Modal params={params} />;
  }

  return <GlobalModals {...props} />;
});

const GlobalModals = React.memo(props => {
  const { modal, params } = props;

  let Modal;
  switch (modal) {
    case modals.auth():
      Modal = AuthModal;
      break;
    case modals.faq():
      Modal = FaqModal;
      break;
    case modals.postAuthCompleted():
      Modal = PostAuthCompletedModal;
      break;
    case modals.privacy():
      Modal = PrivacyModal;
      break;
    case modals.signOut():
      Modal = SignOutModal;
      break;
    case modals.slideBuilder():
      Modal = SlideBuilderModal;
      break;
    case modals.terms():
      Modal = TermsModal;
      break;
    case modals.termsCorporate():
      Modal = TermsCorporateModal;
      break;
    case modals.postAuthCreatorSaved():
      Modal = PostAuthCreatorSavedModal;
      break;

    default:
      console.error("Uncaught Modal", modal, params);
  }

  if (Modal) {
    return <Modal params={params} />;
  }
  return null;
});

export default React.memo(ModalRoutes);
