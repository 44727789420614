import { isValid } from "date-fns/isValid";
import { lightFormat } from "date-fns/lightFormat";
import { parse } from "date-fns/parse";

import { padInt, theseAreInts } from "utils/numberUtils";

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthNamesFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// check a data string is valid
export const isValidDate = date => {
  try {
    return new Date(date).toISOString();
  } catch (e) {
    return false;
  }
};

export const formatDate = (day, month, year) => {
  return `${year}-${padInt(month)}-${padInt(day)}`;
};

export const getDaysInMonth = (month, year) => {
  // JS months are zero-indexed but normal text representation is 1-indexed so delete 1 from the month
  // then to get the date before the first day of the following month (add 1 to month and get day 0)
  // which is the equivalent of using month value as-is
  const date = new Date(year, parseInt(month, 10), 0);

  return date.getDate();
};

// parse a string representation of a date
// into an object with component values of the date
export const parseDate = dateString => {
  if (!dateString) {
    return {
      day: "DD",
      month: "MMM",
      year: "YYYY",
      daysInMonth: 31,
      formattedDate: "",
    };
  }

  let day,
    month,
    year,
    dateObject,
    daysInMonth = 31;

  // extract values from the dateString after determining its shape
  // if it's a minimal string date
  if (dateString.match && dateString.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    // to convert this string into a date object we need to subtract 1
    // from the provided month because JS months are zero-indexed
    const dateParts = dateString.split("-");
    dateObject = new Date("2000-01-01 00:00");
    dateObject.setFullYear(dateParts[0], dateParts[1] - 1, dateParts[2]);
  } else {
    dateObject = new Date(dateString);
  }

  // now extract the date values from the JS date object
  day = dateObject.getDate();
  month = dateObject.getMonth() + 1;
  year = dateObject.getFullYear();

  if (theseAreInts(month, year)) {
    daysInMonth = getDaysInMonth(month, year);
  }

  return {
    day,
    month,
    year,
    daysInMonth,
    formattedDate: formatDate(day, month, year),
  };
};

const optsDate = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const getReadableDate = (dateString, options) => {
  if (!!!isValidDate(dateString)) {
    return false;
  }

  let d = new Date(dateString);
  return d.toLocaleString(undefined, options || optsDate);
};

export const getDaysDifferences = (date1, date2) =>
  Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));

export const isSameDay = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getYear() === date2.getYear()
  );
};

export const localTimezoneHourOffset = () => {
  return -Math.round(new Date().getTimezoneOffset() / 60);
};

export const toYMD = date => {
  if (date) {
    // if this is a date, convert to YMD
    if (isValid(date)) {
      return lightFormat(date, "yyyy-MM-dd");
    }
    // if the argument was already YMD, return it
    if (isValid(fromYMD(date))) {
      return date;
    }
  }
  return null;
};
export const fromYMD = dateString => {
  if (dateString) {
    // if the argument is already a date, return it
    if (isValid(dateString)) {
      return dateString;
    }
    // if we can successfully convert the argument to a date, return it
    const dateObject = parse(dateString, "yyyy-MM-dd", new Date());
    if (isValid(dateObject)) {
      return dateObject;
    }
  }

  // otherwise return null
  return null;
};
export const removeTime = date => {
  date.setHours(0, 0, 0);
  return date;
};

export const getNextYearDate = date => {
  const nextYearDate = new Date(date);
  nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
  return nextYearDate;
};
