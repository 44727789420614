/* Libraries */
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
/* -Libraries */

/* Actions */
import { getPlatform } from "redux/platform/actions";
/* -Actions */

/* Selectors */
/* -Selectors */

/* Hooks */
import usePolling from "hooks/usePolling";
/* --Hooks */

/* Context */
/* -Context */

const PlatformMonitor = props => {
  const { appWasSetup } = props;

  const dispatch = useDispatch();

  const pollEndpoint = useCallback(() => {
    return dispatch(getPlatform());
  }, [dispatch]);

  // get initial platform data
  useEffect(() => {
    if (appWasSetup) {
      pollEndpoint();
    }
  }, [appWasSetup, pollEndpoint]);

  // set up polling every 30s for subsequent data
  usePolling(appWasSetup, pollEndpoint, null, null, 30000);

  return null;
};

PlatformMonitor.propTypes = {};

export default React.memo(PlatformMonitor);
