/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = [];

const setGroup = (state, { payload }) => payload;

const setRole = (state, { payload }) =>
  state.map(role => (role.id === payload.id ? { ...role, ...payload } : role));

const addStoryRole = (state, { payload }) => {
  return [...state, payload];
};

const removeStoryRole = (state, { payload }) => {
  return state.filter(role => role.id !== payload);
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.storyRolesFetchSuccess]: setGroup,
  [actions.storyRoleFetchSuccess]: setRole,
  [actions.storyRoleAddSuccess]: addStoryRole,
  [actions.storyRoleRemoveSuccess]: removeStoryRole,
  [authActions.logoutInit]: reset
});
