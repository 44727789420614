/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
/* -Actions */

const INITIAL_STATE = {
  prompts: [],
  currentPrompt: "",
  queue: [],
};

const updateCurrentPrompt = (state, { payload }) => {
  const { currentPrompt } = payload;

  return {
    ...state,
    currentPrompt,
  };
};

const updatePrompts = (state, { payload }) => {
  const { prompts } = payload;

  return {
    ...state,
    prompts,
  };
};

const updatePromptsQueue = (state, { payload }) => {
  const { queue } = payload;

  return {
    ...state,
    queue,
  };
};

export default createReducer(INITIAL_STATE, {
  [actions.updateCurrentPrompt]: updateCurrentPrompt,
  [actions.updatePrompts]: updatePrompts,
  [actions.updatePromptsQueue]: updatePromptsQueue,
});
