/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import * as trackSelectors from "redux/tracks/selectors";
/* -Selectors */

const INITIAL_STATE = {
  action: {},
  library: [],
  selected: [],
};

const setLibrary = (state, { payload }) => {
  return {
    ...state,
    library: payload,
  };
};

const setSelected = (state, { payload }) => {
  return {
    ...state,
    selected: payload,
  };
};

const addToSoundtrack = (state, { payload }) => {
  const { id, name } = payload;

  return {
    ...state,
    selected: state.selected.find(track => trackSelectors.getId(track) === id)
      ? state.selected
      : [...state.selected, { id, name }],
  };
};

const removeFromSoundtrackById = (state, { payload }) => {
  const trackId = payload;

  return {
    ...state,
    selected: state.selected.filter(
      track => trackSelectors.getId(track) !== trackId
    ),
  };
};

const setSoundtrackAction = (state, { payload }) => {
  return {
    ...state,
    action: payload,
  };
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.addToSoundtrack]: addToSoundtrack,
  [actions.removeFromSoundtrackById]: removeFromSoundtrackById,
  [actions.setLibrary]: setLibrary,
  [actions.setSelected]: setSelected,
  [actions.setSoundtrackAction]: setSoundtrackAction,
  [authActions.logoutInit]: reset,
});
