/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* --Actions */

import { noop } from "utils/clientUtils";

const INITIAL_STATE = {
  error: null,
  onEnd: noop,
  onStart: noop,
  onStop: noop,
  slides: [],
  cardTheme: {},
};

const update = (state, { payload }) => {
  return { ...INITIAL_STATE, ...payload };
};

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.update]: update,
  [authActions.logoutInit]: reset,
});
