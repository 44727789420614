/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import * as selectors from "./selectors";
import { DELIVERY_TYPES } from "./selectors";
/* -Selectors */

const INITIAL_STATE = {
  creditType: null,
  localeCurrency: null,
  localeCurrencySymbol: null,
  paymentClientSecret: null,
  paymentReturnPath: null,
  paywallDisplay: false,
  products: { [DELIVERY_TYPES.DIGITAL]: [], [DELIVERY_TYPES.POSTAL]: [] },
  upgradeOptions: { [DELIVERY_TYPES.DIGITAL]: [], [DELIVERY_TYPES.POSTAL]: [] },
  upgradeTier: null,
};

const setClientSecret = (state, { payload }) => {
  return {
    ...state,
    paymentClientSecret: payload,
  };
};

const setPaywallDisplay = (state, { payload }) => {
  return {
    ...state,
    paywallDisplay: !!payload,
    paymentReturnPath:
      typeof payload === "string" ? payload : state.paymentReturnPath,
  };
};

const clearPaymentDetails = state => {
  return {
    ...state,
    creditType: null,
    paymentClientSecret: null,
    paymentReturnPath: null,
    upgradeTier: null,
  };
};

const setPaymentSettings = (state, { payload }) => {
  const firstProduct = payload.products?.[0];
  return {
    ...state,
    ...(firstProduct
      ? {
          localeCurrency: selectors.getProductCurrency(firstProduct),
          localeCurrencySymbol:
            selectors.getProductCurrencySymbol(firstProduct),
        }
      : {}),
    products: payload.products,
  };
};

const setUpgradeOptions = (state, { payload }) => {
  return {
    ...state,
    upgradeOptions: payload.products,
  };
};
const clearUpgradeOptions = (state, { payload }) => {
  return {
    ...state,
    upgradeOptions: INITIAL_STATE.upgradeOptions,
  };
};

const setPaymentDetails = (state, { payload }) => {
  return {
    ...state,
    upgradeTier: payload.upgradeTier,
    creditType: payload.creditType,
  };
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.clearPaymentDetails]: clearPaymentDetails,
  [actions.clearUpgradeOptions]: clearUpgradeOptions,
  [actions.setClientSecret]: setClientSecret,
  [actions.setPaywallDisplay]: setPaywallDisplay,
  [actions.setPaymentSettings]: setPaymentSettings,
  [actions.setPaymentDetails]: setPaymentDetails,
  [actions.setUpgradeOptions]: setUpgradeOptions,
  [authActions.logoutInit]: reset,
});
