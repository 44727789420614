import "./utils/polyfills";
import "./utils/why-did-you-render";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import analytics from "./utils/analyticsUtils";
import "./styles/index.scss"; // Must come before App to not override styles!

import App from "./App";

import { persistor, store } from "redux/store";

const container = document.getElementById(process.env.REACT_APP_ROOT_ELEMENT);
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  // what proportion of sessions (0-1) to capture & save
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_CAPTURE_RATE,
  // what proportion of sessions (0-1) with errors to capture & save
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.replayIntegration({
      // user input is automatically masked
      // unmask text and media
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],
});

analytics.loadSegment(process.env.REACT_APP_SEGMENT_KEY);
