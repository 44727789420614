/* Libraries */
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
/* -Libraries */

/* Actions */
import { fetchAuthContextStatus } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import * as authSelectors from "redux/auth/selectors";
/* -Selectors */

/* Hooks */
import useIsMounted from "hooks/useIsMounted";
import usePolling from "hooks/usePolling";
/* -Hooks */

import { noop } from "utils/clientUtils";
import { slideStatus } from "redux/slide/selectors";

const usePollAuthProcessStatus = (
  contextId,
  pollFrequencyMs = 5000,
  handleFailure = noop
) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const [statusComplete, setStatusComplete] = useState(false);
  const [authContext, setAuthContext] = useState();

  const pollEndpoint = useCallback(() => {
    return dispatch(fetchAuthContextStatus(contextId));
  }, [contextId, dispatch]);

  const handleSuccess = useCallback(
    authContextResponse => {
      if (isMounted()) {
        const status = authSelectors.getAuthContextStatus(authContextResponse);
        const scenario =
          authSelectors.getAuthContextScenario(authContextResponse);
        const successState =
          scenario === authSelectors.authScenarios.CONTRIBUTION
            ? status !== slideStatus.PENDING
            : status;

        setStatusComplete(successState);
        setAuthContext(authContextResponse);
      }
    },
    [isMounted]
  );

  usePolling(
    contextId,
    pollEndpoint,
    handleSuccess,
    handleFailure,
    pollFrequencyMs
  );

  return { authProcessComplete: !!statusComplete, authContext };
};

export default usePollAuthProcessStatus;
