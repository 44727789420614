/* Libraries */
import { useEffect, useState } from "react";
import WebFont from "webfontloader";
/* -Libraries */

// load site fonts from all sources and return a completion flag
const useImportFonts = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    WebFont.load({
      // local fonts
      custom: {
        families: [
          "Caveat",
          "Courier Prime",
          "Ds Digital",
          "Poppins",
          "Red Hat Display",
          "Reenie Beanie",
          "Rustico-Regular",
        ],
      },
      active: () => {
        setFontsLoaded(true);
      },
    });
  }, []);

  return fontsLoaded;
};

export default useImportFonts;
