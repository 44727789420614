export const themes = [
  // base themes

  {"sku":"KIN_WARP_01_01","id":"KIN_WARP_01_01","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":0.18,"color":"#000000","scale":1.17,"offset":{"x":8.823529411764724,"y":50},"blur":8},"glare":{"alpha":0.58,"size":0.67,"speed":1,"color":"#0070ff","angle":-160,"smooth":0.27},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.32999999999999996,"active":true,"blendMode":0,"color1":{"color":"#10174B","alpha":1},"color2":{"color":"#3240A9","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":19,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#FFC360","#E4A43A","#DBB43D","#D67E00"],["#EBA4CE","#E27FB9","#D553A0","#D269B8"],["#C02624","#F2312F","#D85B1B","#E85946"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_01_01","themeColours":["#ffb836","#313fa7","#ff3e00"],"assets":[]},

  {"sku":"KIN_WARP_01_02","id":"KIN_WARP_01_02","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%!"},"shadow":{"alpha":0.15,"color":"#000000","scale":1.09,"offset":{"x":0,"y":51.470588235294144},"blur":8},"glare":{"alpha":0.33,"size":0.39999999999999997,"speed":1,"color":"#fffbe2","angle":-157,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.32999999999999996,"active":true,"blendMode":0,"color1":{"color":"#601448","alpha":1},"color2":{"color":"#c13c99","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":16,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#e8ff60","#cbe43a","#b0db3d","#a2e500"],["#aaa4eb","#897fe2","#5f53d5","#aaa4eb"],["#e4004f","#ff2270","#d70006","#ff2f62"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_01_02","themeColours":["#ecff34","#db51b2","#528fff"],"assets":[]},

  {"sku":"KIN_WARP_01_03","id":"KIN_WARP_01_03","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":0.36000000000000004,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":30},"blur":8},"glare":{"alpha":0.33,"size":0.68,"speed":1,"color":"#fff9cf","angle":-164,"smooth":1},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#48413f","alpha":1},"color2":{"color":"#2d2827","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":15,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#ff6a60","#e4453a","#db5b3d","#d60600"],["#ffe890","#ffe062","#ffd329","#ffb73c"],["#24b4c0","#2fe1f2","#107dc7","#46cae8"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_01_03","themeColours":["#ff6a60","#3b2724","#2fbaf1"],"assets":[]},

  {"sku":"KIN_WARP_01_04","id":"KIN_WARP_01_04","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":0.36000000000000004,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":30},"blur":8},"glare":{"alpha":0.28,"size":0.88,"speed":1,"color":"#fffdea","angle":-164,"smooth":0.58},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#232d41","alpha":1},"color2":{"color":"#101522","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":17,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#ff60ab","#e43a89","#db3d73","#d6006c"],["#ffad90","#ff8c62","#ff6129","#ff4f3c"],["#24c079","#2ff29a","#10c7af","#46e8af"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_01_04","themeColours":["#ff6060","#2a3956","#2ff29a"],"assets":[]},

  {"sku":"KIN_WARP_02_01","id":"KIN_WARP_02_01","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#ff0000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":17,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#b41b1b","dropShadowDistance":7}},"coloredLines":[["#5b8897","#d28649","#e595a5","#66806a"],["#e8d9d1","#e8d9d1","#e8d9d1","#e8d9d1"],["#cb6059","#7186b0","#93975b","#5b9597"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_02_01","themeColours":["#b41b1b","#202221","#f8d2b4"],"assets":[]},

  {"sku":"KIN_WARP_02_02","id":"KIN_WARP_02_02","outputs":{"title":"HAPPY BIRTHDAY %RECIPIENT_NAME%!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#00bfb8","scale":0.9999999999999999,"offset":{"x":0,"y":0},"blur":0},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#e4e0cb","alpha":1},"color2":{"color":"#fefbea","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#999999","dropShadowDistance":2}},"coloredLines":[["#f6c764","#dbaa41","#dbb970","#e2a932"],["#f87572","#ff8b0b","#9864c5","#65b59d"],["#7399e2","#7f97c5","#7ba2eb","#687fac"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_02_02","themeColours":["#ffbc42","#4482cf","#c2444f"],"assets":[]},

  {"sku":"KIN_WARP_02_03","id":"KIN_WARP_02_03","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#004052","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#e787a7","#e787a7","#e787a7","#e787a7"],["#b1d8ce","#b1d8ce","#b1d8ce","#b1d8ce"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_02_03","themeColours":["#004052","#e787a7","#b1d8ce"],"assets":[]},
  
  {"sku":"KIN_WARP_02_04","id":"KIN_WARP_02_04","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#2a214b","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#a54c4a","dropShadowDistance":7}},"coloredLines":[["#99e787","#99e787","#99e787","#99e787"],["#e9b29f","#e9b29f","#e9b29f","#e9b29f"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_02_04","themeColours":["#47377f","#99e787","#e9b29f"],"assets":[]},


  {"sku":"KIN_WARP_03_01","id":"KIN_WARP_03_01","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":0.4600000000000001,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-5},"blur":0},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.45999999999999996,"active":true,"blendMode":2,"color1":{"color":"#a75a15","alpha":1},"color2":{"color":"#291200","alpha":1},"threshold":0.09},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":"___COVER_ASSETS_URL___/images/wood-pattern.png"},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#c97e39","dropShadowDistance":3}},"coloredLines":[["#ffdb9b","#ffdb9b","#ffdb9b","#ffdb9b"],["#e8b55f","#e8b55f","#e8b55f","#e8b55f"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_03_01","themeColours":["#ffdb9b","#371901","#5c2e09"],"assets":["___COVER_ASSETS_URL___/images/wood-pattern.png"]},

  {"sku":"KIN_WARP_03_02","id":"KIN_WARP_03_02","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":1,"color":"#492f16","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":2.949029909160572e-17},"glare":{"alpha":0.16,"size":0.2,"speed":1,"color":"#fff8c4","angle":-153,"smooth":1},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.45999999999999996,"active":true,"blendMode":1,"color1":{"color":"#de985b","alpha":1},"color2":{"color":"#000000","alpha":1},"threshold":0.09},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":"___COVER_ASSETS_URL___/images/wood-pattern-2.png"},"effects":[],"colorLetters":[],"margin":10,"useDifferentFonts":true,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#fff6ac","dropShadowDistance":2}},"coloredLines":[["#8d5800","#9d6f23","#9d5823","#9c633e"],["#695432","#634c28","#72533d","#65452d"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_03_02","themeColours":["#5c290b","#ffa761","#ffcd71"],"assets":["___COVER_ASSETS_URL___/images/wood-pattern-2.png"]},

  {"sku":"KIN_WARP_03_03","id":"KIN_WARP_03_03","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":0.4600000000000001,"color":"#000000","scale":1.0199999999999998,"offset":{"x":-2.205882352941174,"y":12.5},"blur":15.22},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.52,"active":true,"blendMode":2,"color1":{"color":"#fff6ef","alpha":1},"color2":{"color":"#bdbdbd","alpha":1},"threshold":0.51},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":2,"color1":{"color":"#613700","alpha":1},"color2":{"color":"#f0e7da","alpha":0.16}},"imageURL":"___COVER_ASSETS_URL___/images/rock-1.png"},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":-1.57,"dropShadowColor":"#dbccbd","dropShadowDistance":3}},"coloredLines":[["#af9f9a","#907d76","#857c79","#8d6c60"],["#b9a297","#aea29d","#bf8f80","#cdb8b1"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_03_03","themeColours":["#907d76","#a29c98","#613700"],"assets":["___COVER_ASSETS_URL___/images/rock-1.png"]},

  {"sku":"KIN_WARP_03_04","id":"KIN_WARP_03_04","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":0.4600000000000001,"color":"#05001c","scale":1.0199999999999998,"offset":{"x":-2.205882352941174,"y":12.5},"blur":11.09},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.52,"active":true,"blendMode":2,"color1":{"color":"#fff6ef","alpha":1},"color2":{"color":"#bdbdbd","alpha":1},"threshold":0.51},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":2,"color1":{"color":"#613700","alpha":1},"color2":{"color":"#f0e7da","alpha":0.16}},"imageURL":"___COVER_ASSETS_URL___/images/rock-2.png"},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":-1.57,"dropShadowColor":"#9f84e1","dropShadowDistance":5}},"coloredLines":[["#98a0a0","#788181","#767879","#687778"],["#80aebd","#7da2ad","#578896","#a5bcc3"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_03_04","themeColours":["#564f4c","#73acc2","#5b4d6c"],"assets":["___COVER_ASSETS_URL___/images/rock-2.png"]},

  {"sku":"KIN_WARP_04_01","id":"KIN_WARP_04_01","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#0f8c5f","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#fffaf8","#fffaf8","#fffaf8","#fffaf8"],["#ffb2cc","#ffb2cc","#ffb2cc","#ffb2cc"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_04_01","themeColours":["#0f8c5f","#ffb2cc","#fffaf8"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  {"sku":"KIN_WARP_04_02","id":"KIN_WARP_04_02","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#fff9d6","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#ffb620","#ffb620","#ffb620","#ffb620"],["#ff6237","#ff6237","#ff6237","#ff6237"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_04_02","themeColours":["#fff9d6","#ffb620","#ff6237"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  {"sku":"KIN_WARP_04_03","id":"KIN_WARP_04_03","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#fff9d6","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#432221","alpha":1},"color2":{"color":"#921c19","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#82ffd8","#82ffd8","#82ffd8","#82ffd8"],["#ff4536","#ff4536","#ff4536","#ff4536"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_04_03","themeColours":["#432221","#82ffd8","#ff4536"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  {"sku":"KIN_WARP_04_04","id":"KIN_WARP_04_04","outputs":{"title":"MESSAGE WITH %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#fff9d6","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#242561","alpha":1},"color2":{"color":"#7447ac","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#b8e836","#b8e836","#b8e836","#b8e836"],["#08ffff","#08ffff","#08ffff","#08ffff"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"KIN_WARP_04_04","themeColours":["#7447ac","#b8e836","#08ffff"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},




  // UNIQUE COVERS




  {"sku":"KIN_WARP_01_01","id":"1701189402547","outputs":{"title":"Happy Birthday %RECIPIENT_NAME%😘"},"shadow":{"alpha":0.18,"color":"#000000","scale":1.17,"offset":{"x":8.823529411764724,"y":50},"blur":8},"glare":{"alpha":0.58,"size":0.67,"speed":1,"color":"#0070ff","angle":-160,"smooth":0.27},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.32999999999999996,"active":true,"blendMode":0,"color1":{"color":"#10174B","alpha":1},"color2":{"color":"#3240A9","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":19,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#FFC360","#E4A43A","#DBB43D","#D67E00"],["#EBA4CE","#E27FB9","#D553A0","#D269B8"],["#C02624","#F2312F","#D85B1B","#E85946"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701189402547","themeColours":["#ffb836","#383ca2","#ff3e00","#000000"],"assets":[]},

  {"sku":"KIN_WARP_02_01","id":"1701190355562","outputs":{"title":"HAPPY BIRTHDAY %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#ff0000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":17,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#b41b1b","dropShadowDistance":7}},"coloredLines":[["#5b8897","#d28649","#e595a5","#66806a"],["#e8d9d1","#e8d9d1","#e8d9d1","#e8d9d1"],["#cb6059","#7186b0","#93975b","#5b9597"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701190355562","themeColours":["#ff3152","#202221","#f8d2b4"],"assets":[]},

  {"sku":"KIN_WARP_03_02","id":"1701191222264","outputs":{"title":"HAPPY BIRTHDAY %RECIPIENT_NAME%🤠"},"shadow":{"alpha":1,"color":"#492f16","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":2.949029909160572e-17},"glare":{"alpha":0.16,"size":0.2,"speed":1,"color":"#fff8c4","angle":-153,"smooth":1},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.45999999999999996,"active":true,"blendMode":1,"color1":{"color":"#de985b","alpha":1},"color2":{"color":"#000000","alpha":1},"threshold":0.09},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":"___COVER_ASSETS_URL___/images/wood-pattern-2.png"},"effects":[],"colorLetters":[],"margin":10,"useDifferentFonts":true,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#fff6ac","dropShadowDistance":2}},"coloredLines":[["#8d5800","#9d6f23","#9d5823","#9c633e"],["#695432","#634c28","#72533d","#65452d"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701191222264","themeColours":["#5c290b","#ffa761","#ffcd71"],"assets":["___COVER_ASSETS_URL___/images/wood-pattern-2.png"]},

  {"sku":"KIN_WARP_04_04","id":"1701192356354","outputs":{"title":"HAPPY B'DAY %RECIPIENT_NAME%🤩"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#fff9d6","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#242561","alpha":1},"color2":{"color":"#7447ac","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#b8e836","#b8e836","#b8e836","#b8e836"],["#08ffff","#08ffff","#08ffff","#08ffff"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701192356354","themeColours":["#7447ac","#b8e836","#08ffff"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  {"sku":"KIN_WARP_01_04","id":"1701192692014","outputs":{"title":"GOOD LUCK %RECIPIENT_NAME%!"},"shadow":{"alpha":0.36000000000000004,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":30},"blur":8},"glare":{"alpha":0.28,"size":0.88,"speed":1,"color":"#fffdea","angle":-164,"smooth":0.58},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#232d41","alpha":1},"color2":{"color":"#101522","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":17,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#ff60ab","#e43a89","#db3d73","#d6006c"],["#ffad90","#ff8c62","#ff6129","#ff4f3c"],["#24c079","#2ff29a","#10c7af","#46e8af"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701192692014","themeColours":["#ff6060","#2a3956","#2ff29a"],"assets":[]},

  {"sku":"KIN_WARP_02_04","id":"1701193065349","outputs":{"title":"ALL THE BEST %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#2a214b","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#a54c4a","dropShadowDistance":7}},"coloredLines":[["#99e787","#99e787","#99e787","#99e787"],["#e9b29f","#e9b29f","#e9b29f","#e9b29f"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701193065349","themeColours":["#47377f","#99e787","#e9b29f"],"assets":[]},

  {"sku":"KIN_WARP_03_04","id":"1701193530839","outputs":{"title":"WE'LL MISS YOU %RECIPIENT_NAME%🥲"},"shadow":{"alpha":0.4600000000000001,"color":"#05001c","scale":1.0199999999999998,"offset":{"x":-2.205882352941174,"y":12.5},"blur":11.09},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.52,"active":true,"blendMode":2,"color1":{"color":"#fff6ef","alpha":1},"color2":{"color":"#bdbdbd","alpha":1},"threshold":0.51},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":2,"color1":{"color":"#613700","alpha":1},"color2":{"color":"#f0e7da","alpha":0.16}},"imageURL":"___COVER_ASSETS_URL___/images/rock-2.png"},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":-1.57,"dropShadowColor":"#9f84e1","dropShadowDistance":5}},"coloredLines":[["#98a0a0","#788181","#767879","#687778"],["#80aebd","#7da2ad","#578896","#a5bcc3"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701193530839","themeColours":["#564f4c","#73acc2","#5b4d6c"],"assets":["___COVER_ASSETS_URL___/images/rock-2.png"]},

  {"sku":"KIN_WARP_04_01","id":"1701193660064","outputs":{"title":"Thank you %RECIPIENT_NAME%! We'll miss you!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#0f8c5f","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#fffaf8","#fffaf8","#fffaf8","#fffaf8"],["#ffb2cc","#ffb2cc","#ffb2cc","#ffb2cc"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701193660064","themeColours":["#0f8c5f","#ffb2cc","#fffaf8"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},


  {"sku":"KIN_WARP_04_05","id":"1701295397786","outputs":{"title":"MERRY CHRISTMAS %RECIPIENT_NAME% FAMILY"},"shadow":{"alpha":0.4600000000000001,"color":"#000000","scale":1.0199999999999998,"offset":{"x":-2.205882352941174,"y":12.5},"blur":15.22},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.63,"active":true,"blendMode":2,"color1":{"color":"#62cba7","alpha":1},"color2":{"color":"#0b5038","alpha":1},"threshold":0.45},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.9999999999999999,"blendMode":2,"color1":{"color":"#135940","alpha":1},"color2":{"color":"#135940","alpha":0}},"imageURL":"___COVER_ASSETS_URL___/images/wood-pattern-2.png"},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":-1.57,"dropShadowColor":"#ffba00","dropShadowDistance":3}},"coloredLines":[["#ffffff","#de635e","#ffffff","#de635e"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701295397786","themeColours":["#18622c","#ffffff","#de635e"],"assets":["___COVER_ASSETS_URL___/images/wood-pattern-2.png"]},

  {"sku":"KIN_WARP_02_01","id":"1701678198757","outputs":{"title":"HAPPY 🎁XMAS🎄 %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#0d00ff","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#2b2c2c","radial":{"center":{"x":0,"y":0},"size":0.5899999999999999,"active":true,"blendMode":0,"color1":{"color":"#0f1f38","alpha":1},"color2":{"color":"#0e4835","alpha":1},"threshold":0.24},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7400000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[{"id":"Bloom","threshold":0.31,"bloomScale":0.93,"brightness":0.89,"blur":5,"quality":7}],"colorLetters":[],"margin":17,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#000000","dropShadowDistance":7}},"coloredLines":[["#51d3ff","#ff7e17","#ff0033","#37ff56"],["#e600ff","#ffed00","#00ffcb","#5947ff"],["#ff2a1c","#b4ff4c","#34bdff","#ffc400"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701678198757","themeColours":["#ff30b6","#0f3321","#6fffa7"],"assets":[]},

  {"sku":"KIN_WARP_04_05","id":"1701679568062","outputs":{"title":"Happy Holidays %RECIPIENT_NAME% family!"},"shadow":{"alpha":0.3600000000000001,"color":"#3c002e","scale":1.0899999999999999,"offset":{"x":0,"y":33},"blur":18.479999999999997},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.63,"active":false,"blendMode":2,"color1":{"color":"#62cba7","alpha":1},"color2":{"color":"#0b5038","alpha":1},"threshold":0.45},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.9999999999999999,"blendMode":2,"color1":{"color":"#135940","alpha":1},"color2":{"color":"#135940","alpha":0}},"imageURL":"https://cdn.kindeo.com/a061c329-eefe-47e2-8b4a-5c156f64f902/photo.jpg"},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.6400000000000001,"dropShadowColor":"#198585","dropShadowDistance":6}},"coloredLines":[["#ffe2fe","#ffbdbd","#f2fcff","#a8ffff"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701679568062","themeColours":["#5fcfc1","#ffeaea","#de5e8c"],"assets":["https://cdn.kindeo.com/a061c329-eefe-47e2-8b4a-5c156f64f902/photo.jpg"]},

  {"sku":"KIN_WARP_01_01","id":"1701680613621","outputs":{"title":"HAPPY CHRISTMAS %RECIPIENT_NAME%🎅🏻"},"shadow":{"alpha":0.13,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":22},"blur":20},"glare":{"alpha":0.15999999999999995,"size":0.33,"speed":1,"color":"#ffffff","angle":-160,"smooth":0.25},"bg":{"flatColor":"#60ae5a","radial":{"center":{"x":0,"y":0},"size":0.6099999999999999,"active":true,"blendMode":0,"color1":{"color":"#60ae5a","alpha":1},"color2":{"color":"#4c8448","alpha":1},"threshold":0.51},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":19,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":0.8200000000000001,"dropShadowColor":"#ffffff","dropShadowDistance":8}},"coloredLines":[["#d5c045","#3168bd","#ff6666","#387966"],["#57df92","#ff9d2f","#ff3e3e","#626dff"],["#d8a3d8","#2feaf1","#ffd95c","#ff4f37"],["#c2d548","#875eff","#4fbfff","#b262a9"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.5700000000000001,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701680613621","themeColours":["#61ac3c","#383ca2","#ff3e00","#000000"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  {"sku":"KIN_WARP_04_06","id":"1701895694738","outputs":{"title":"Happy Holidays and an awesome 2024"},"shadow":{"alpha":0.15000000000000008,"color":"#000000","scale":0.9999999999999998,"offset":{"x":0,"y":22},"blur":9.35},"glare":{"alpha":5.204170427930421e-18,"size":0.4,"speed":1,"color":"#fff9cf","angle":-106,"smooth":1},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.63,"active":false,"blendMode":2,"color1":{"color":"#62cba7","alpha":1},"color2":{"color":"#0b5038","alpha":1},"threshold":0.45},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.9999999999999999,"blendMode":2,"color1":{"color":"#135940","alpha":1},"color2":{"color":"#135940","alpha":0}},"imageURL":"___COVER_ASSETS_URL___/images/xmas-tree.png"},"effects":[],"colorLetters":[],"margin":15,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":110,"fontWeight":400,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.6400000000000001,"dropShadowColor":"#757575","dropShadowDistance":3}},"coloredLines":[["#ffffff","#ffffff","#ffffff","#ffffff"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.29999999999999993,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701895694738","themeColours":["#4d9c62","#b2803e","#ffffff"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png","___COVER_ASSETS_URL___/images/xmas-tree.png"]},


  {"sku":"KIN_WARP_04_06","id":"1701944003068","outputs":{"title":"Happy Holidays %RECIPIENT_NAME% family!"},"shadow":{"alpha":1,"color":"#a81a54","scale":0.9999999999999998,"offset":{"x":0,"y":2},"blur":7.17},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.63,"active":false,"blendMode":11,"color1":{"color":"#1800ff","alpha":1},"color2":{"color":"#ff3989","alpha":1},"threshold":0.45},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.9999999999999999,"blendMode":2,"color1":{"color":"#135940","alpha":1},"color2":{"color":"#135940","alpha":0}},"imageURL":"___COVER_ASSETS_URL___/images/stripes.png"},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":-1.57,"dropShadowColor":"#ffffff","dropShadowDistance":3}},"coloredLines":[["#ff7cfa","#66aeff","#ff9292","#00ddcb"],["#b1cf77","#ff71aa","#7d85f5","#ffbe6a"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701944003068","themeColours":["#5fcfc1","#ffeaea","#e21929"],"assets":["___COVER_ASSETS_URL___/images/stripes.png"]},


  // custom
  {"sku":"KIN_WARP_01_03","id":"1702031024584","outputs":{"title":"Write anything you 🤪😘🤩 WANT!"},"shadow":{"alpha":0.36000000000000004,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":30},"blur":8},"glare":{"alpha":0.33,"size":0.68,"speed":1,"color":"#fff9cf","angle":-164,"smooth":1},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#48413f","alpha":1},"color2":{"color":"#2d2827","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#ff6a60","#e4453a","#db5b3d","#d60600"],["#ffe890","#ffe062","#ffd329","#ffb73c"],["#24b4c0","#2fe1f2","#107dc7","#46cae8"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702031024584","themeColours":["#ff6a60","#3b2724","#2fbaf1"],"assets":[]},

  {"sku":"KIN_WARP_02_02","id":"1702031219168","outputs":{"title":"Say what's on your mind💖"},"shadow":{"alpha":3.469446951953614e-17,"color":"#00bfb8","scale":0.9999999999999999,"offset":{"x":0,"y":0},"blur":0},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#d5b9b2","alpha":1},"color2":{"color":"#f4efde","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#00bfb8","dropShadowDistance":7}},"coloredLines":[["#c79d13","#c72534","#1560bc","#13873e"],["#ffffff","#ffffff","#ffffff","#ffffff"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702031219168","themeColours":["#ffce97","#1560bc","#c72534"],"assets":[]},

  {"sku":"KIN_WARP_02_02","id":"1709031690783","outputs":{"title":"HAPPY BIRTHDAY %RECIPIENT_NAME%!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#00bfb8","scale":0.9999999999999999,"offset":{"x":0,"y":0},"blur":0},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#e4e0cb","alpha":1},"color2":{"color":"#fefbea","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#999999","dropShadowDistance":2}},"coloredLines":[["#f6c764","#dbaa41","#dbb970","#e2a932"],["#f87572","#ff8b0b","#9864c5","#65b59d"],["#7399e2","#7f97c5","#7ba2eb","#687fac"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1709031690783","themeColours":["#ffbc42","#4482cf","#c2444f"],"assets":[]},

  {"sku":"KIN_WARP_03_04","id":"1702031432666","outputs":{"title":"YOU ROCK! 🎸👩🏻‍🎤🎷"},"shadow":{"alpha":0.4600000000000001,"color":"#05001c","scale":1.0199999999999998,"offset":{"x":-2.205882352941174,"y":12.5},"blur":11.09},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.52,"active":true,"blendMode":2,"color1":{"color":"#fff6ef","alpha":1},"color2":{"color":"#bdbdbd","alpha":1},"threshold":0.51},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":2,"color1":{"color":"#613700","alpha":1},"color2":{"color":"#f0e7da","alpha":0.16}},"imageURL":"___COVER_ASSETS_URL___/images/rock-2.png"},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":-1.57,"dropShadowColor":"#9f84e1","dropShadowDistance":5}},"coloredLines":[["#98a0a0","#788181","#767879","#687778"],["#80aebd","#7da2ad","#578896","#a5bcc3"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702031432666","themeColours":["#564f4c","#73acc2","#5b4d6c"],"assets":["___COVER_ASSETS_URL___/images/rock-2.png"]},

  {"sku":"KIN_WARP_04_03","id":"1702031642188","outputs":{"title":"Even the SILLIEST messages can look cool😎"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#fff9d6","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#432221","alpha":1},"color2":{"color":"#921c19","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#82ffd8","#82ffd8","#82ffd8","#82ffd8"],["#ff4536","#ff4536","#ff4536","#ff4536"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702031642188","themeColours":["#432221","#82ffd8","#ff4536"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  // thanks

  {"sku":"KIN_WARP_01_02","id":"1702031889156","outputs":{"title":"Thanks so much %RECIPIENT_NAME%!"},"shadow":{"alpha":0.15,"color":"#000000","scale":1.09,"offset":{"x":0,"y":51.470588235294144},"blur":8},"glare":{"alpha":0.33,"size":0.39999999999999997,"speed":1,"color":"#fffbe2","angle":-157,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.32999999999999996,"active":true,"blendMode":0,"color1":{"color":"#601448","alpha":1},"color2":{"color":"#c13c99","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":16,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#e8ff60","#cbe43a","#b0db3d","#a2e500"],["#aaa4eb","#897fe2","#5f53d5","#aaa4eb"],["#e4004f","#ff2270","#d70006","#ff2f62"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702031889156","themeColours":["#ecff34","#db51b2","#528fff"],"assets":[]},

  {"sku":"KIN_WARP_02_03","id":"1702032074564","outputs":{"title":"%RECIPIENT_NAME%! You're the best!🤘"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#004052","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#e787a7","#e787a7","#e787a7","#e787a7"],["#b1d8ce","#b1d8ce","#b1d8ce","#b1d8ce"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702032074564","themeColours":["#004052","#e787a7","#b1d8ce"],"assets":[]},

  {"sku":"KIN_WARP_03_02","id":"1702032568233","outputs":{"title":"Life 'wooden' be the same without YOU😍"},"shadow":{"alpha":1,"color":"#492f16","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":2.949029909160572e-17},"glare":{"alpha":0.16,"size":0.2,"speed":1,"color":"#fff8c4","angle":-153,"smooth":1},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.45999999999999996,"active":true,"blendMode":1,"color1":{"color":"#de985b","alpha":1},"color2":{"color":"#000000","alpha":1},"threshold":0.09},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":"___COVER_ASSETS_URL___/images/wood-pattern-2.png"},"effects":[],"colorLetters":[],"margin":10,"useDifferentFonts":true,"fonts":[{"fontFamily":"Ultra","fontSize":100,"fontWeight":400,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#fff6ac","dropShadowDistance":2}},"coloredLines":[["#8d5800","#9d6f23","#9d5823","#9c633e"],["#695432","#634c28","#72533d","#65452d"]],"texture":{"alpha":0.38999999999999996,"url":"___COVER_ASSETS_URL___/images/wood-pattern-2.png","scale":1,"blendMode":1},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702032568233","themeColours":["#5c290b","#ffa761","#ffcd71"],"assets":["___COVER_ASSETS_URL___/images/wood-pattern-2.png","___COVER_ASSETS_URL___/images/wood-pattern-2.png"]},

  {"sku":"KIN_WARP_04_04","id":"1701189029776","outputs":{"title":"You're a super⭐️ %RECIPIENT_NAME%"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#fff9d6","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#242561","alpha":1},"color2":{"color":"#7447ac","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#b8e836","#b8e836","#b8e836","#b8e836"],["#08ffff","#08ffff","#08ffff","#08ffff"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1701189029776","themeColours":["#7447ac","#b8e836","#08ffff"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  // congrats

  {"sku":"KIN_WARP_02_03","id":"1702398823609","outputs":{"title":"CONGRATS %RECIPIENT_NAME% That's amazing!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#004052","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#e787a7","#e787a7","#e787a7","#e787a7"],["#b1d8ce","#b1d8ce","#b1d8ce","#b1d8ce"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702398823609","themeColours":["#004052","#e787a7","#b1d8ce"],"assets":[]},

  {"sku":"KIN_WARP_04_01","id":"1702054350835","outputs":{"title":"CONGRATS %RECIPIENT_NAME% that's amazing!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#0f8c5f","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":12,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#fffaf8","#fffaf8","#fffaf8","#fffaf8"],["#ffb2cc","#ffb2cc","#ffb2cc","#ffb2cc"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702054350835","themeColours":["#0f8c5f","#ffb2cc","#fffaf8"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  // anniversary

  {"sku":"KIN_WARP_01_04","id":"1702055169110","outputs":{"title":"Happy Anniversary Alex"},"shadow":{"alpha":0.36000000000000004,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":30},"blur":8},"glare":{"alpha":0.28,"size":0.88,"speed":1,"color":"#fffdea","angle":-164,"smooth":0.58},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#232d41","alpha":1},"color2":{"color":"#101522","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#ff60ab","#e43a89","#db3d73","#d6006c"],["#ffad90","#ff8c62","#ff6129","#ff4f3c"],["#24c079","#2ff29a","#10c7af","#46e8af"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702055169110","themeColours":["#ff6060","#2a3956","#2ff29a"],"assets":[]},

  {"sku":"KIN_WARP_02_02","id":"1702055351811","outputs":{"title":"Happy anniversary George & Babs"},"shadow":{"alpha":3.469446951953614e-17,"color":"#00bfb8","scale":0.9999999999999999,"offset":{"x":0,"y":0},"blur":0},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#d5b9b2","alpha":1},"color2":{"color":"#f4efde","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#00bfb8","dropShadowDistance":7}},"coloredLines":[["#c79d13","#c72534","#1560bc","#13873e"],["#ffffff","#ffffff","#ffffff","#ffffff"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702055351811","themeColours":["#ffce97","#1560bc","#c72534"],"assets":[]},

  {"sku":"KIN_WARP_02_03","id":"1713447219280","outputs":{"title":"Happy anniversary George & Olivia"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#004052","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#e787a7","#e787a7","#e787a7","#e787a7"],["#b1d8ce","#b1d8ce","#b1d8ce","#b1d8ce"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1713447219280","themeColours":["#004052","#e787a7","#b1d8ce"],"assets":[]},

  // wedding

  {"sku":"KIN_WARP_02_03","id":"1702055776753","outputs":{"title":"It's Mr & Mrs %RECIPIENT_NAME%! 💍💒"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#004052","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#e787a7","#e787a7","#e787a7","#e787a7"],["#b1d8ce","#b1d8ce","#b1d8ce","#b1d8ce"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702055776753","themeColours":["#004052","#e787a7","#b1d8ce"],"assets":[]},

  {"sku":"KIN_WARP_04_02","id":"1702057169868","outputs":{"title":"Congrats Ryan & Laura 😍💝🫶💖"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":1.734723475976807e-18,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#fff9d6","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":13,"useDifferentFonts":false,"fonts":[{"fontFamily":"Oi","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":1.57,"dropShadowColor":"#070e44","dropShadowDistance":7}},"coloredLines":[["#ffb620","#ffb620","#ffb620","#ffb620"],["#ff6237","#ff6237","#ff6237","#ff6237"]],"texture":{"alpha":1,"url":"___COVER_ASSETS_URL___/images/paper-tile.png","scale":0.49999999999999994,"blendMode":2},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702057169868","themeColours":["#fff9d6","#ffb620","#ff6237"],"assets":["___COVER_ASSETS_URL___/images/paper-tile.png"]},

  // welcome

  {"sku":"KIN_WARP_02_01","id":"1702058034876","outputs":{"title":"Welcome aboard Greg!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#ff0000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#202221","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":17,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#b41b1b","dropShadowDistance":7}},"coloredLines":[["#5b8897","#d28649","#e595a5","#66806a"],["#e8d9d1","#e8d9d1","#e8d9d1","#e8d9d1"],["#cb6059","#7186b0","#93975b","#5b9597"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702058034876","themeColours":["#b41b1b","#202221","#f8d2b4"],"assets":[]},

  {"sku":"KIN_WARP_01_02","id":"1702058285975","outputs":{"title":"Welcome to the club %RECIPIENT_NAME%!"},"shadow":{"alpha":0.15,"color":"#000000","scale":1.09,"offset":{"x":0,"y":51.470588235294144},"blur":8},"glare":{"alpha":0.33,"size":0.39999999999999997,"speed":1,"color":"#fffbe2","angle":-157,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.32999999999999996,"active":true,"blendMode":0,"color1":{"color":"#601448","alpha":1},"color2":{"color":"#c13c99","alpha":1},"threshold":0},"linear":{"active":true,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":16,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#e8ff60","#cbe43a","#b0db3d","#a2e500"],["#aaa4eb","#897fe2","#5f53d5","#aaa4eb"],["#e4004f","#ff2270","#d70006","#ff2f62"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1702058285975","themeColours":["#ecff34","#db51b2","#528fff"],"assets":[]},

  
  {"sku":"KIN_WARP_02_03","id":"1705957011407","outputs":{"title":"To %RECIPIENT_NAME% the best wife in the world ❤️😍💖"},"shadow":{"alpha":0.26000000000000006,"color":"#ffffff","scale":1.0699999999999998,"offset":{"x":0,"y":0},"blur":20},"glare":{"alpha":0.14,"size":0.9500000000000001,"speed":1,"color":"#ffbda4","angle":-145,"smooth":1},"bg":{"flatColor":"#ffcafd","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":0.75,"dropShadowColor":"#894f82","dropShadowDistance":10}},"coloredLines":[["#ff91b5","#ffa0d6","#ffb5cd","#ffa4c2"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1705957011407","themeColours":["#ffbd9d","#e787a7","#995787"],"assets":[]},

  {"sku":"KIN_WARP_01_01","id":"1713362807735","outputs":{"title":"We're excited to get to know you David"},"shadow":{"alpha":0.13,"color":"#000000","scale":1.17,"offset":{"x":8.823529411764724,"y":50},"blur":8},"glare":{"alpha":0,"size":1,"speed":1,"color":"#FFFFFF","angle":0,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.48,"active":true,"blendMode":0,"color1":{"color":"#10174B","alpha":1},"color2":{"color":"#3240A9","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.53,"blendMode":0,"color1":{"color":"#000000","alpha":0.6},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":19,"useDifferentFonts":false,"fonts":[{"fontFamily":"Poppins","fontSize":100,"fontWeight":800,"italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":false,"dropShadowAngle":0,"dropShadowColor":"#ffffff","dropShadowDistance":0}},"coloredLines":[["#FFC360","#E4A43A","#DBB43D","#D67E00"],["#EBA4CE","#E27FB9","#D553A0","#D269B8"],["#C02624","#F2312F","#D85B1B","#E85946"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1713362807735","themeColours":["#e4a43a"],"assets":[]},

  // mothers

  {"sku":"KIN_WARP_02_05","id":"1708090526357","outputs":{"title":"Happy Mother's Day🥰"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8.7},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#e4cdf5","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#e787a7","alpha":0.53},"color2":{"color":"#a167c4","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#5e1e4d","dropShadowDistance":2}},"coloredLines":[["#ffd7e4","#ffc7d9","#ffe8f0","#ffcdde"],["#fff7ba","#ffddaa","#ffe3d0","#ffe3ba"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1708090526357","themeColours":["#FFF8BA","#B97BC3","#FFE8F0"],"assets":[]},

  // appreciation

  {"sku":"KIN_WARP_02_04","id":"1713793197558","outputs":{"title":"You make every day better Natasha"},"shadow":{"alpha":3.469446951953614e-17,"color":"#000000","scale":0.9999999999999999,"offset":{"x":0,"y":-4},"blur":8},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#2a214b","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":false,"blendMode":0,"color1":{"color":"#202221","alpha":1},"color2":{"color":"#202221","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#a54c4a","dropShadowDistance":7}},"coloredLines":[["#99e787","#99e787","#99e787","#99e787"],["#e9b29f","#e9b29f","#e9b29f","#e9b29f"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1713793197558","themeColours":["#47377f","#99e787","#e9b29f"],"assets":[]},

  {"sku":"KIN_WARP_02_02","id":"1709031690784","outputs":{"title":"Sending love & strength Connie!"},"shadow":{"alpha":3.469446951953614e-17,"color":"#00bfb8","scale":0.9999999999999999,"offset":{"x":0,"y":0},"blur":0},"glare":{"alpha":0.13,"size":0.65,"speed":1,"color":"#fff9cf","angle":-106,"smooth":0},"bg":{"flatColor":"#000000","radial":{"center":{"x":0,"y":0},"size":0.49999999999999994,"active":true,"blendMode":0,"color1":{"color":"#e4e0cb","alpha":1},"color2":{"color":"#fefbea","alpha":1},"threshold":0},"linear":{"active":false,"angle":-1.5707963267948966,"size":0.84,"blendMode":0,"color1":{"color":"#000000","alpha":0.7100000000000001},"color2":{"color":"#000000","alpha":0}},"imageURL":""},"effects":[],"colorLetters":[],"margin":20,"useDifferentFonts":false,"fonts":[{"fontFamily":"Bungee Inline","fontSize":100,"fontWeight":"400","italic":false,"active":true,"useOnce":false}],"textStyle":{"stroke":{"stroke":"#ffffff","strokeThickness":0},"dropShadow":{"dropShadow":true,"dropShadowAngle":1.57,"dropShadowColor":"#999999","dropShadowDistance":2}},"coloredLines":[["#f6c764","#dbaa41","#dbb970","#e2a932"],["#f87572","#ff8b0b","#9864c5","#65b59d"],["#7399e2","#7f97c5","#7ba2eb","#687fac"]],"texture":{"alpha":1,"url":"","scale":1,"blendMode":0},"hasGlare":true,"bgColor":"#258BBA","glareAlpha":0.08,"name":"1709031690784","themeColours":["#ffbc42","#4482cf","#c2444f"],"assets":[]},

];
