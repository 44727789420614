/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
/* -Selectors */

/* -Actions */
/* -Actions */

export const actions = {
  setEnabledFeatures: createAction("FEATURE_FETCH_SUCCESS"),
};

// get the latest update status from the API
export const setEnabledFeatures = features => dispatch => {
  dispatch(actions.setEnabledFeatures(features));
};
