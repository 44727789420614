import { noop } from "utils/clientUtils";

export const getPlayback = state => state.playback;

export const getAutoplay = playback => playback.autoplay;
export const getControls = playback => playback.controls;
export const getControlsOptions = playback => playback.controlsOptions;
export const getHasAnimations = playback => playback.hasAnimations;
export const getHasFX = playback => playback.hasFx;
export const getLoop = playback => playback.loop;
export const getMute = playback => playback.mute;
export const getName = playback => playback.name;
export const getOnEnd = playback => playback.onEnd || noop;
export const getOnStart = playback => playback.onStart || noop;
export const getOnStop = playback => playback.onStop || noop;
export const getPlaySlideshow = playback => playback.playSlideshow;
export const getPreload = playback => playback.preload;
export const getSlidesOverride = playback => playback.slidesOverride;
export const getCardSettings = playback => playback.storySettings;
export const getCardTheme = playback => playback.cardTheme;

export const getPlaybackError = state => getPlayback(state).error;
