export const themes = [
  {
    name: "cake-0",
    text: { color: "0xffffff" },
    background: {
      color: "0xFF502B",
      properties: {
        type: "Dots",
        alphaMin: 0.2,
        alphaGlobal: 0.6847826086956522,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "classic",
        color1: "0xefe7dc",
        color2: "0xff502b",
        color3: "0xff8773",
        toppings: {
          main: { type: "Chantilly", nbBalls: 5, color: "0xEFE7DC" },
          secondary: [{ type: "Sugar", color: "0xffffff" }],
        },
      },
      candle: { color: "0x0369f2" },
    },
  },
  {
    name: "cake-1",
    text: { color: "0xffffff" },
    background: {
      color: "0xff85d6",
      properties: {
        type: "Squares",
        alpha: 0.2391304347826087,
        size: { x: 0.4852941176470589, y: 0.1796875 },
        columnWidth: 68.69565217391305,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "classic",
        color1: "0xffebc2",
        color2: "0xff85d6",
        color3: "0xffcff1",
        toppings: {
          main: { type: "Cherry" },
          secondary: [
            { type: "Sprinkles", color1: "0x2332F7", color2: "0x52EA49" },
          ],
        },
      },
      candle: { color: "0xc5c5ff" },
    },
  },
  {
    name: "cake-2",
    text: { color: "0xffffff" },
    background: {
      color: "0x966446",
      properties: {
        type: "Sprites",
        alpha: 0.2,
        sizeDivider: 1.75,
        columnWidth: 80,
        color: [0.30980392156862746, 0.403921568627451, 0.21568627450980393],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "layer",
        color1: "0xb18052",
        color2: "0x966446",
        color3: "0x604639",
        toppings: { main: { type: "Leaves" }, secondary: [] },
      },
      candle: { color: "0xffc973" },
    },
  },
  {
    name: "cake-3",
    text: { color: "0xffffff" },
    background: {
      color: "0x1c215f",
      properties: {
        type: "Dots",
        alphaMin: 0.043478260869565216,
        alphaGlobal: 0.6847826086956522,
        scaleValue: 1.3369565217391304,
        alphaValue: 0.2,
        radius: 0.08532608695652173,
        columnWidth: 30.543478260869563,
        color: [0.43529411764705883, 0.6392156862745098, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "classic",
        color1: "0xf1e6d7",
        color2: "0x4752d4",
        color3: "0x92a6e7",
        toppings: {
          main: { type: "Cherry" },
          secondary: [
            { type: "Sprinkles", color1: "0xfcff00", color2: "0x07ff35" },
          ],
        },
      },
      candle: { color: "0xe667b1" },
    },
  },
  {
    name: "cake-4",
    text: { color: "0x555755" },
    background: {
      color: "0xfbf9f0",
      properties: {
        type: "Dots",
        alphaMin: 0.06521739130434782,
        alphaGlobal: 0.6847826086956522,
        scaleValue: 1.3369565217391304,
        alphaValue: 0.13043478260869565,
        radius: 0.05304347826086957,
        columnWidth: 20.76086956521739,
        color: [0.23921568627450981, 0.24313725490196078, 0.24705882352941178],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "classic",
        color1: "0xf1e6d7",
        color2: "0xb87e47",
        color3: "0xdbd7bb",
        toppings: {
          main: { type: "Leaves" },
          secondary: [{ type: "Sugar", color: "0x5f5426" }],
        },
      },
      candle: { color: "0x66aab3" },
    },
  },
  {
    name: "cake-5",
    text: { color: "0xffffff" },
    background: {
      color: "0x5e9966",
      properties: {
        type: "Squares",
        alpha: 0.18478260869565216,
        size: { x: 0.11764705882352944, y: 0.3216911764705883 },
        columnWidth: 65.76086956521739,
        color: [0.7098039215686275, 0.996078431372549, 0.7019607843137254],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "classic",
        color1: "0x73b87c",
        color2: "0x4c7e53",
        color3: "0xacdbab",
        toppings: {
          main: { type: "Chantilly", nbBalls: 6, color: "0xbeeac1" },
          secondary: [{ type: "Sugar", color: "0xd8c678" }],
        },
      },
      candle: { color: "0xe67f7b" },
    },
  },
  {
    name: "cake-6",
    text: { color: "0xffffff" },
    background: {
      color: "0x292929",
      properties: {
        type: "Dots",
        alphaMin: 0.06521739130434782,
        alphaGlobal: 0.6847826086956522,
        scaleValue: 2.0869565217391304,
        alphaValue: 0.2,
        radius: 0.05304347826086957,
        columnWidth: 20.76086956521739,
        color: [0.7647058823529411, 0.7686274509803922, 0.7764705882352941],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "classic",
        color1: "0xe4d5ad",
        color2: "0x983c08",
        color3: "0xc15d24",
        toppings: {
          main: { type: "Cherry" },
          secondary: [{ type: "Sugar", color: "0xffd10e" }],
        },
      },
      candle: { color: "0xcf5050" },
    },
  },
  {
    name: "cake-7",
    text: { color: "0xc28b45" },
    background: {
      color: "0xfff5e0",
      properties: {
        type: "Sprites",
        alpha: 0.2,
        sizeDivider: 1.75,
        columnWidth: 49.130434782608695,
        color: [0.9882352941176471, 0.9137254901960784, 0.7607843137254902],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "CakeScene",
      cake: {
        type: "classic",
        color1: "0xfde9c4",
        color2: "0xffb04a",
        color3: "0xffcc7d",
        toppings: {
          main: { type: "Cherry" },
          secondary: [
            { type: "Sprinkles", color1: "0xd3a6e4", color2: "0xb6ff07" },
          ],
        },
      },
      candle: { color: "0xe568ba" },
    },
  },
];
