/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as editorActions from "redux/editor/actions";
import { updateStoryBuilder } from "redux/storyBuilder/actions";
/* -Actions */

/* Selectors */
import * as remindersSelectors from "./selectors";
/* -Selectors */

import { localTimezoneHourOffset, toYMD } from "utils/dateUtils";

export const actions = {
  createReminder: createAction("REMINDERS_CREATE_SUCCESS"),
  removeReminder: createAction("REMINDER_REMOVE_SUCCESS"),
  setReminders: createAction("REMINDERS_FETCH_SUCCESS"),
  updateReminder: createAction("REMINDER_UPDATE_SET"),
};

export const fetchReminders = () => dispatch => {
  return axios.get(`/reminders`).then(response => {
    const reminders = response?.data?.reminders;
    dispatch(actions.setReminders(reminders));
    return reminders;
  });
};

export const fetchReminderDetails = reminderId => dispatch => {
  return axios.get(`/reminder/${reminderId}`).then(response => {
    const reminder = response?.data?.reminder;
    dispatch(actions.updateReminder(reminder));

    return reminder;
  });
};

export const fetchUpcomingReminders = () => dispatch => {
  return axios.get(`/reminders/month`).then(response => {
    const reminders = response?.data?.reminders;
    dispatch(actions.setReminders(reminders));
    return reminders;
  });
};

const formatReminderData = reminder => {
  const {
    daysBefore,
    id,
    recipientFirstName,
    recipientLastName,
    occasion,
    occasionDate,
    repeat,
  } = reminder;

  const apiFormat = {
    ...(daysBefore ? { days_before: daysBefore } : {}),
    ...(id ? { id } : {}),
    ...(recipientFirstName ? { recipient_first_name: recipientFirstName } : {}),
    ...(recipientLastName ? { recipient_last_name: recipientLastName } : {}),
    ...(occasion ? { occasion } : {}),
    ...(occasionDate ? { occasion_date: toYMD(occasionDate) } : {}),
    ...(repeat !== undefined ? { repeat } : {}),
    ...(recipientFirstName && occasion
      ? {
          title: remindersSelectors.generateReminderTitle(
            recipientFirstName,
            occasion
          ),
        }
      : {}),
    reminder_offset: localTimezoneHourOffset(),
  };

  return apiFormat;
};

export const createReminder = payload => dispatch => {
  const data = formatReminderData(payload);
  const { storyId } = payload;

  return axios
    .post(`/reminders${storyId ? `?story_id=${storyId}` : ""}`, data)
    .then(result => {
      const reminder = result?.data?.reminder;

      // fetch new API data
      dispatch(fetchReminders());

      return reminder;
    });
};

export const updateReminder = payload => dispatch => {
  const data = formatReminderData(payload);

  return axios.put(`/reminders/${data.id}`, data).then(result => {
    const reminder = result?.data?.reminder;

    dispatch(actions.updateReminder(reminder));

    // fetch new API data
    dispatch(fetchReminders());

    return reminder;
  });
};

export const removeReminder = reminderId => dispatch => {
  return axios.delete(`/reminders/${reminderId}`).then(() => {
    // remove from local store
    dispatch(actions.removeReminder(reminderId));

    // fetch new API data
    return dispatch(fetchReminders());
  });
};

export const loadReminderIntoBuilder = reminderId => (dispatch, getState) => {
  const reminder = remindersSelectors.getReminderById(
    remindersSelectors.getReminders(getState()),
    reminderId
  );

  if (reminder) {
    // set the occasion date
    const occasionDate = toYMD(remindersSelectors.getEventDate(reminder));

    const occasionName = remindersSelectors.getEventOccasion(reminder);

    const storyData = {
      occasion: occasionName,
      occasion_date: occasionDate,
      recipient_first_name:
        remindersSelectors.getEventRecipientFirstName(reminder),
      recipient_last_name:
        remindersSelectors.getEventRecipientLastName(reminder),
      reminderId,
    };
    dispatch(updateStoryBuilder(storyData));

    // set the occasion in the store both for storyBuilder and editor
    dispatch(editorActions.setOccasion(occasionName));
  }
};
