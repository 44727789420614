import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import usePageLoader from "hooks/usePageLoader";

const useLoaderLocation = () => {
  const location = useLocation();
  // `loaderLocation` is the same as `location` but delayed by page navigation to allow
  // the page loader to show before the next page is rendered
  const { loaderLocation, setLocation } = usePageLoader(true);
  useEffect(() => {
    if (location) {
      setLocation(location);
    }
  }, [location, setLocation]);

  return loaderLocation || location;
};

export default useLoaderLocation;
