/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
import * as editorActions from "redux/editor/actions";
/* -Actions */

const INITIAL_STATE = {
  pages: 1,
  results: [],
  searchTerm: "",
  selected: null,
};

const setSelected = (state, { payload }) => ({
  ...state,
  selected: payload,
});

const setSearchTerm = (state, { payload }) => ({
  ...state,
  searchTerm: payload,
});

const setSearchResults = (state, { payload }) => ({
  ...state,
  results: [...state.results, payload.results],
  pages: payload.pages,
});

const resetSearchResults = (state, { payload }) => ({
  ...INITIAL_STATE,
  searchTerm: state.searchTerm,
});

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.fetchGiphyItemSuccess]: setSelected,
  [actions.searchGiphySuccess]: setSearchResults,
  [actions.setGiphySearchTerm]: setSearchTerm,
  [actions.resetGiphySearch]: resetSearchResults,
  [editorActions.resetEditor]: reset,
  [authActions.logoutInit]: reset,
});
