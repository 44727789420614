/* Libraries */
import React from "react";
import classnames from "classnames";
/* -Libraries */

/* Components */
import IconButton from "components/IconButton";
/* -Components */

import styles from "./index.module.scss";

const IconBackButton = props => {
  const { className, icon = "arrow-left", text, ...otherProps } = props;

  return (
    <IconButton
      {...otherProps}
      icon={icon}
      iconSize="24"
      iconClassName={styles.backIcon}
      className={classnames(styles.back, className)}
    >
      {text && <span className={styles.label}>{text}</span>}
    </IconButton>
  );
};

export default React.memo(IconBackButton);
