/* Selectors */
import { getSpecialOccasionDate } from "../occasions/selectors";
/* -Selectors */

import { fromYMD, toYMD } from "utils/dateUtils";

export const getStoryBuilder = state => state.storyBuilder;

// This story ID will only ever be set in the create flow
// when a cover is selected and a card is created, we can re-use this
// story ID if the user exits the create flow and chooses another cover
export const getStoryId = story => story?.storyId;

export const getStoryOccasionType = story => story?.occasion;
export const getStoryOccasionDate = story => story?.occasion_date;
export const getStoryRecipientFirstName = story =>
  story?.recipient_first_name || "";
export const getStoryRecipientLastName = story =>
  story?.recipient_last_name || "";
export const getStoryTitleVariant = story => story?.title_variant;
export const getStoryTitleTheme = story => story?.title_theme;
export const getStoryTitleExportedId = story => story?.title_exported_id;
export const getStoryTitleVariantData = story => story?.title_variant_data;
export const getSlideData = (story, exportedId) =>
  story?.title_slide_data_object?.[
    exportedId || getStoryTitleExportedId(story)
  ] || [];

export const getStyle = story => story?.style;

// const getNextWeek = () => {
//   const now = new Date();
//   now.setDate(now.getDate() + 7);
//   return now;
// };
// default occasion date is either a holiday (e.g. christmas) or next week
export const getDefaultOccasionDate = story => {
  const occasionName = getStoryOccasionType(story);
  const occasionDate = getSpecialOccasionDate(occasionName) || null;

  return toYMD(occasionDate);
};
// default contribution date is 1 day before the occasion date
export const getDefaultCompletionDate = story => {
  const occasionDate =
    getStoryOccasionDate(story) || getDefaultOccasionDate(story);
  if (occasionDate) {
    const completionDate = fromYMD(occasionDate);
    completionDate.setDate(completionDate.getDate() - 1);
    return toYMD(completionDate);
  }

  return null;
};

// set by reminderActions.loadReminderIntoBuilder
export const getReminderId = story => story?.reminderId;
