/* Selectors */
/* -Selectors */

import { capitaliseFirstLetter } from "utils/stringUtils";

export const getId = track => track?.id;
export const getUrl = track => track?.url;
export const getName = track => track?.name;
export const getGenre = track => track?.genre;
export const getDuration = track => track?.length;
export const getPosition = track => track?.position;
export const getCover = track => track?.cover_url;

export const makeReadableGenre = (genre, separator = " & ") =>
  genre
    ?.split("_")
    .map(part => capitaliseFirstLetter(part))
    .join(separator);
