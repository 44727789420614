/* Libraries */
import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
/* -Libraries */

/* Hooks */
/* -Hooks */

// console.log(process.env.REACT_MODAL_ROOT_ELEMENT);

const modalRoot = document.getElementById(
  process.env.REACT_APP_MODALROOT_ELEMENT
);
const modalRootTop = document.getElementById(
  process.env.REACT_APP_MODALROOT_TOP_ELEMENT
);
const ModalPortal = ({ children, topLayer }) => {
  const el = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    const modalContainer = topLayer ? modalRootTop : modalRoot;
    modalContainer.appendChild(el);
    return () => modalContainer.removeChild(el);
  }, [el, topLayer]);

  return createPortal(children, el);
};

ModalPortal.propTypes = {};

export default React.memo(ModalPortal);
