import { mediaTypes } from "redux/media/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";

const type = slideTypes.LEGACY;
const legacyVariants = slideVariants[type];

export default {
  type,
  icon: "legacy",
  label: "Legacy",
  colour: "peach",
  variants: [
    {
      name: legacyVariants.DEFAULT,
      label: "Default",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Title",
      placeholder: "e.g. Our Holiday",
      disabled: true, // prevent editing
    },
    {
      key: "description",
      type: inputTypes.TEXTAREA,
      label: "Message",
      placeholder: "Write a message or story here",
      disabled: true, // prevent editing
    },
    {
      key: "photos",
      type: inputTypes.MEDIA,
      label: "Photos",
      icon: "camera",
      mediaType: mediaTypes.PHOTO,
      validation: {
        maxLength: { value: 25, message: `A maximum of 25 photos is allowed` },
      },
    },
    {
      key: "audio",
      type: inputTypes.MEDIA,
      label: "Audio",
      icon: "slide-audio",
      mediaType: mediaTypes.AUDIO,
      validation: {
        maxLength: { value: 1 },
      },
    },
    {
      key: "video",
      type: inputTypes.MEDIA,
      label: "Video",
      icon: "slide-video",
      mediaType: mediaTypes.VIDEO,
      validation: {
        maxLength: { value: 1 },
      },
    },
  ],
};
