export const getUpdateStatus = state => state.updateStatus;

// Counts
export const getUnseenSlideCount = state =>
  getUpdateStatus(state).slides_unseen;
export const getRoleRequestCount = state =>
  getUpdateStatus(state).role_requests;
export const getUnseenContributionCount = state =>
  getUpdateStatus(state).contributions_unseen;

// Dates
export const getLastStoryUpdate = state =>
  getUpdateStatus(state).last_story_update;
export const getLastContributionUpdate = state =>
  getUpdateStatus(state).last_contribution_update;
export const getLastRoleUpdate = state =>
  getUpdateStatus(state).last_role_update;
