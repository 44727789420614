/* Libraries */
import React from "react";
import classnames from "classnames";
/* -Libraries */

/* Components */
import IconCloseButton from "components/IconCloseButton";
/* -Components */

import styles from "./index.module.scss";

const ModalCloseButton = props => {
  const { className, expand, ...otherProps } = props;

  return (
    <div
      className={classnames(styles.container, {
        [styles.avoidExpandedContent]: expand
      })}
    >
      <IconCloseButton
        className={classnames(styles.close, className)}
        {...otherProps}
      />
    </div>
  );
};

export default React.memo(ModalCloseButton);
