import { themes as boatThemes } from "./themes/title/boat/data";
import { themes as curveyThemes } from "./themes/title/curvey/data";
import { themes as curveyThemesV2 } from "./themes/title/curvey2/data";
import { themes as cakeThemes } from "./themes/title/cake/data";
import { themes as heartsThemes } from "./themes/title/hearts/data";
import { themes as paperHeartThemes } from "./themes/title/paperHeart/data";
import { themes as ripplesThemes } from "./themes/title/cartoonRipples/data";
import { themes as rocketThemes } from "./themes/title/rocket/data";
import { themes as astronautThemes } from "./themes/title/astronaut/data";
import { themes as starburstThemes } from "./themes/title/cartoonStarburst/data";
import {
  themes as trailThemes,
  themesPhoto as trailThemesPhoto,
} from "./themes/title/trail/data";
import { themes as xmasThemes } from "./themes/title/xmas/data";
import { themes as exportedThemes } from "./themes/title/exported/data";
import { themesShape as flowersShapeThemes } from "./themes/title/flowers/data";
import { themesText as flowersTextThemes } from "./themes/title/flowers/data";

import * as slideSelectors from "redux/slide/selectors";
import * as configSelectors from "redux/slideConfig/selectors";
import {
  slideTypes,
  slideVariants,
} from "../../libs/kindeo-play/consts/slides";

const titleType = slideTypes.TITLE;
const titleVariants = slideVariants[titleType];

const coverVariants = {
  [titleVariants.DEFAULT]: {
    name: titleVariants.DEFAULT,
  },
  [titleVariants.EXPORTED]: {
    name: titleVariants.EXPORTED,
    themes: exportedThemes,
  },
  [titleVariants.PARTICLES_TEXT]: {
    name: titleVariants.PARTICLES_TEXT,
    themes: flowersTextThemes,
  },
  [titleVariants.PARTICLES_SHAPE]: {
    name: titleVariants.PARTICLES_SHAPE,
    themes: flowersShapeThemes,
  },
  [titleVariants.PARTICLES_SHAPE_PHOTO]: {
    name: titleVariants.PARTICLES_SHAPE_PHOTO,
    themes: flowersShapeThemes,
  },
  [titleVariants.CURVEY]: {
    name: titleVariants.CURVEY,
    themes: curveyThemes,
  },
  [titleVariants.CURVEY2]: {
    name: titleVariants.CURVEY2,
    themes: curveyThemesV2,
  },
  [titleVariants.ASTRONAUT]: {
    name: titleVariants.ASTRONAUT,
    themes: astronautThemes,
  },
  [titleVariants.BOAT]: {
    name: titleVariants.BOAT,
    themes: boatThemes,
  },
  [titleVariants.CAKE]: {
    name: titleVariants.CAKE,
    themes: cakeThemes,
  },
  [titleVariants.HEARTS]: {
    name: titleVariants.HEARTS,
    themes: heartsThemes,
  },
  [titleVariants.PAPER_HEARTS]: {
    name: titleVariants.PAPER_HEARTS,
    themes: paperHeartThemes,
  },
  [titleVariants.RIPPLES]: {
    name: titleVariants.RIPPLES,
    themes: ripplesThemes,
  },
  [titleVariants.ROCKET]: {
    name: titleVariants.ROCKET,
    themes: rocketThemes,
  },
  [titleVariants.STARBURST]: {
    name: titleVariants.STARBURST,
    themes: starburstThemes,
  },
  [titleVariants.TRAIL]: {
    name: titleVariants.TRAIL,
    themes: [...trailThemes],
  },
  [titleVariants.TRAIL_PHOTO]: {
    name: titleVariants.TRAIL_PHOTO,
    themes: [...trailThemesPhoto],
  },
  [titleVariants.XMAS]: {
    name: titleVariants.XMAS,
    themes: xmasThemes,
  },
};

export const getTheme = slide => {
  let themes;

  if (slideSelectors.getType(slide) === titleType) {
    themes = coverVariants[slideSelectors.getVariant(slide)]?.themes;
  }

  const exportedId = slideSelectors.getExportedId(slide);

  // TODO problem is we don't reset the theme
  // (1) for exported covers that don't have themes (standalone cards) the theme can be set by normal variant (eg. Title.curvey will set a theme  = "curvey-2")
  if (exportedId) {
    let themeId;

    if (slideSelectors.getRelatedCardId(slide)) {
      themeId = slideSelectors.getRelatedCardId(slide);
    } else {
      // (2) here, themeId will still be equal to "curvey-2", even though it doesn't exist in the exported covers
      themeId = slideSelectors.getTheme(slide)?.split("!")?.[0]; // because covers that use related cards use the "!" to separate the original card from the rest
    }

    // (3) checks the exported covers for a theme = "curvey-2", uh oh! :/
    let variantData = themes.find(
      variant =>
        configSelectors.getVariantExportedId(variant) ===
        (themeId || exportedId)
    );

    // (4) if undefined, no data will be associated to it... a quick fix for now is to force using the exportedId (eg. "dino-roarsome")
    if (!variantData) {
      variantData = themes.find(
        variant => configSelectors.getVariantExportedId(variant) === exportedId
      );
    }

    const animationData =
      configSelectors.getVariantExportedContent(variantData);

    return animationData;
  } else if (themes) {
    const theme = slide.theme.split("!"); // because covers that use related cards use the "!" to separate the original card from the rest
    return themes.find(t => t.name === theme[0]);
  }
};

export default coverVariants;
