/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { viewAs } from "./selectors";
/* --Actions */

const defaultViewAs = viewAs.grid_2x;
const INITIAL_STATE = {
  view_as_slides: defaultViewAs,
  view_as_group_slides: defaultViewAs,
  view_as_kindeos: defaultViewAs,
};

const setSlidesViewAs = (state, { payload }) => {
  return {
    ...state,
    view_as_slides: payload,
  };
};

const setGroupSlidesViewAs = (state, { payload }) => {
  return {
    ...state,
    view_as_group_slides: payload,
  };
};

const setKindeosViewAs = (state, { payload }) => {
  return {
    ...state,
    view_as_kindeos: payload,
  };
};

export default createReducer(INITIAL_STATE, {
  [actions.setSlidesViewAs]: setSlidesViewAs,
  [actions.setGroupSlidesViewAs]: setGroupSlidesViewAs,
  [actions.setKindeosViewAs]: setKindeosViewAs,
});
