/* Libraries */
import React from "react";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as actions from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import { errorTypes } from "./selectors";
/* -Selectors */

/* Components */
import SupportLink from "components/SupportLink";
/* -Components */

const INITIAL_STATE = { error: false, type: {}, source: null };

const resetErrors = state => INITIAL_STATE;

const errorResponses = {
  // [errorTypes.authRefresh]: {
  //   type: errorTypes.authRefresh,
  //   persistant: true,
  //   title: "Kindeo is having a moment",
  //   message: (
  //     <>
  //       We’ve had an issue connecting to our systems. Try again when you are
  //       ready.
  //     </>
  //   ),
  //   buttonText: "Try again",
  // },
  [errorTypes.server]: {
    type: errorTypes.server,
    persistant: true,
    title: "Kindeo is having a moment",
    message: (
      <>
        The Kindeo systems aren’t responding as they should. Reload this page to
        try again.
      </>
    ),
    buttonText: "Reload",
  },
  [errorTypes.network]: {
    type: errorTypes.network,
    title: "Oops!",
    message: (
      <>
        It looks like there’s an issue with your connection.
        <br />
        If you need help
        <SupportLink>let us know</SupportLink> or please try again later.
      </>
    ),
  },
  default: {
    type: "default",
    title: "Oops",
    message: (
      <>
        We had a problem handling your request.
        <br />
        Please <SupportLink>let us know what happened</SupportLink> or try again
        later.
      </>
    ),
  },
};

// payload is either string: one of errorTypes
// or object: { type: one of errorTypes, source: string }
export const createError = (state, { payload }) => {
  const type = payload?.type ? payload.type : payload;
  const source = payload?.source ? payload.source : null;
  return {
    ...state,
    error: true,
    type: errorResponses[type] || errorResponses.default,
    source: source,
  };
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.resetErrors]: resetErrors,
  [actions.createError]: createError,
  [authActions.logoutInit]: reset,
});
