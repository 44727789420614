/* Libraries */
import { addDays } from "date-fns/addDays";
import { eachWeekendOfInterval } from "date-fns/eachWeekendOfInterval";
import { isWeekend } from "date-fns/isWeekend";
import { max } from "date-fns/max";
import { min } from "date-fns/min";
import format from "date-fns/format";
/* -Libraries */

import { fromYMD, toYMD } from "utils/dateUtils";
import { formatCurrency } from "utils/numberUtils";

export const POSTAL_COUNTRIES = [{ id: "gb", name: "United Kingdom" }];
export const POSTAL_STATUSES = {
  incomplete: "incomplete",
  processing: "processing",
  printing: "printing",
  dispatched: "dispatched",
  cancelled: "cancelled",
};
export const POSTAL_DESTINATIONS = {
  RECIPIENT: "recipient",
  CREATOR: "creator",
};

export const getPostalOrder = state => state.postalOrder;

export const sendByPostEnabled = state =>
  getPostalOrder(state).send_by_post_enabled;

export const getCurrentPostalOrder = state =>
  getPostalOrder(state).currentOrder;
export const getPostalClasses = state => getPostalOrder(state).postal_classes;
export const getCardClasses = state => getPostalOrder(state).card_classes;
export const getOrderHistory = state => getPostalOrder(state).history;
export const getOrderHistorySubmitted = state =>
  getOrderHistory(state).filter(order => !orderIsStillEditable(order));

export const getOrderId = order => order?.id;
export const getOrderStoryId = order => order?.story_id;
export const getOrderAddressId = order => order?.address_id;
export const getOrderAddress = order => order?.address;
export const getOrderDestination = order => order?.destination;
export const getOrderPostalClass = order => order?.postal_class;
export const getOrderDeliveryIsScheduled = order => order?.scheduled_delivery;
export const getSendOrderNow = order =>
  getOrderDeliveryIsScheduled(order) === false;
export const getOrderDeliveryDate = order => order?.delivery_by;
export const getOrderStatus = order => order?.status;
export const getOrderHasPrintAsset = order => order?.story?.has_print_asset;
export const getOrderPrintAssetUrl = order => order?.story?.print_asset_url;

export const getOrderFormattedPricing = order => order?.pricing_formatted;
export const getCardFormattedPrice = pricing => pricing?.card;
export const getPostalFormattedPrice = pricing => pricing?.post;
export const getTotalFormattedPrice = pricing => pricing?.total;
export const getOrderCreatedOn = order => order?.created_on;

export const orderIsStillEditable = order =>
  getOrderStatus(order) === POSTAL_STATUSES.incomplete;
export const orderIsProcessing = order =>
  [POSTAL_STATUSES.processing, POSTAL_STATUSES.printing].includes(
    getOrderStatus(order)
  );
export const orderIsComplete = order =>
  getOrderStatus(order) === POSTAL_STATUSES.dispatched;

export const getPostalClassId = postalClass => postalClass?.id;
export const getPostalClassName = postalClass => postalClass?.name;
export const getPostalClassPriceRaw = postalClass => postalClass?.amount;
export const getPostalClassPriceCurrency = postalClass => postalClass?.currency;
export const getPostalClassPrice = postalClass =>
  formatCurrency(
    getPostalClassPriceRaw(postalClass),
    getPostalClassPriceCurrency(postalClass)
  );
export const getPostalClassDuration = postalClass => postalClass?.delivery_days;
export const getPostalClassById = (postalClasses, id) =>
  postalClasses.find(postalClass => getPostalClassId(postalClass) === id);

export const getCardClassId = cardClass => cardClass?.id;
export const getCardClassPriceRaw = cardClass => cardClass?.amount;
export const getCardClassCurrency = cardClass => cardClass?.currency;
export const getCardClassPrice = cardClass =>
  formatCurrency(
    getCardClassPriceRaw(cardClass),
    getCardClassCurrency(cardClass)
  );
export const getDefaultCardPrice = state =>
  getCardClassPrice(getCardClasses(state)[0]);

export const getOrderHistoryItemById = (history, id) => {
  return history.find(order => getOrderId(order) === id);
};

export const getNextNonWeekendDate = date => {
  const dateObject = fromYMD(date);
  if (isWeekend(dateObject)) {
    const weekendDays = eachWeekendOfInterval({
      start: dateObject,
      end: addDays(dateObject, 1),
    }).length;
    return addDays(dateObject, weekendDays);
  }
  return dateObject;
};

export const getPostalClassMinDeliveryDate = postalClass =>
  // postalClass?.min_delivery_date ||
  getPostalClassLikelyDeliveryDate(postalClass);

export const getPostalClassLikelyDeliveryDate = postalClass => {
  const now = new Date();
  const duration = getPostalClassDuration(postalClass);

  const addFormattedDays = (days = 0) => {
    const initialDeliveryDate = addDays(now, days);
    return toYMD(initialDeliveryDate);
    // const weekendDays = eachWeekendOfInterval({
    //   start: now,
    //   end: initialDeliveryDate,
    // }).length;
    // const adjustedDeliveryDate = addDays(initialDeliveryDate, weekendDays);
    // return toYMD(adjustedDeliveryDate);
  };

  const deliveryDate = Array.isArray(duration)
    ? [addFormattedDays(duration[0]), addFormattedDays(duration[1])]
    : addFormattedDays(duration);

  return deliveryDate;
};
export const getPostalClassDeliveryDateCopy = postalClass => {
  const formatDate = date => {
    return format(fromYMD(date), "do MMM");
  };

  const deliveryDate = getPostalClassLikelyDeliveryDate(postalClass);
  // a duration span
  if (Array.isArray(deliveryDate)) {
    return formatDate(deliveryDate[0]) + " - " + formatDate(deliveryDate[1]);
  } else {
    return formatDate(deliveryDate);
  }
};

export const earliestDeliveryDate = state => {
  const postalClasses = getPostalClasses(state);
  const minDate = postalClasses.reduce((currentMin, postalClass) => {
    const deliveryDate = getPostalClassMinDeliveryDate(postalClass);

    const classDeliveryDate = fromYMD(
      Array.isArray(deliveryDate) ? deliveryDate[0] : deliveryDate
    );
    return currentMin
      ? min([
          currentMin,
          Array.isArray(classDeliveryDate)
            ? classDeliveryDate[0]
            : classDeliveryDate,
        ])
      : classDeliveryDate;
  }, null);

  return minDate;
};
export const latestDeliveryDate = state => {
  const postalClasses = getPostalClasses(state);
  const maxDate = postalClasses.reduce((currentMax, postalClass) => {
    const deliveryDate = getPostalClassLikelyDeliveryDate(postalClass);
    const classDeliveryDate = fromYMD(
      Array.isArray(deliveryDate) ? deliveryDate[1] : deliveryDate
    );
    return currentMax
      ? max([currentMax, classDeliveryDate])
      : classDeliveryDate;
  }, null);

  return maxDate;
};
