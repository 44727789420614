/* Libraries */
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
import * as contributionsActions from "redux/contributions/actions";
import { fetchGroupCurrentRole } from "redux/currentRole/actions";
import { resetEditor, setAuthModalOpen } from "redux/editor/actions";
import { clearLocalLibrary } from "redux/media/actions";
import { fetchGroupStoryContent } from "redux/story/actions";
/* -Actions */

/* Selectors */
import * as anonymousDataSelectors from "redux/anonymousData/selectors";
import { authScenarios } from "redux/auth/selectors";
import * as currentRoleSelectors from "redux/currentRole/selectors";
import * as editorSelectors from "redux/editor/selectors";
import * as rolesSelectors from "redux/roles/selectors";
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

/* Components */
import { SimpleButton } from "components/_v2/Button";
import ContributionBuilderModal from "modals/ContributionBuilderModal";
import EmptyModal from "modals/EmptyModal";
import AuthModal from "modals/AuthModal";
/* -Components */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useModalDisplay from "hooks/useModalDisplay";
import usePrevious from "hooks/usePrevious";
/* -Hooks */

const SLIDE_STATUS = { UPDATED: "updated", ADDED: "added" };

const selector = createSelector(
  [
    anonymousDataSelectors.getContributionId,
    anonymousDataSelectors.getGroupToken,
    currentRoleSelectors.getCurrentRole,
    editorSelectors.getEditor,
    storySelectors.getStory,
  ],
  (
    anonymousContributionId,
    anonymousGroupToken,
    currentRole,
    editor,
    story
  ) => {
    return {
      anonymousContributionId,
      // content moderation is on if the role is only requested not confirmed
      contentModeration:
        storySelectors.getContentModeration(story) ||
        !rolesSelectors.getRoleIsActive(currentRole),
      groupToken: storySelectors.getGroupToken(story) || anonymousGroupToken,
      hasContributionInEditor: !editorSelectors.isEditorEmpty(editor),
      hasRole: rolesSelectors.getRoleIsActive(currentRole),
      isOwner: rolesSelectors.getRoleIsOwner(currentRole),
    };
  }
);

export const ContributionBuilderContext = createContext();

export const ContributionBuilderContextProvider = props => {
  const [showContributionBuilder, setShowContributionBuilder] = useState(false);

  const {
    anonymousContributionId,
    contentModeration,
    groupToken,
    hasContributionInEditor,
    hasRole,
    isOwner,
  } = useSelector(selector);

  const {
    doClose: closeAuth,
    doOpen: openAuth,
    isOpen: isAuthOpen,
  } = useModalDisplay(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [moderationStatus, setModerationStatus] = useState(false);
  const [contributionId, setContributionId] = useState(false);

  const dispatch = useDispatch();
  const isLoggedIn = useAuthStatus();

  const closeContributionBuilder = useCallback(() => {
    setShowContributionBuilder(false);
    setContributionId(null);
  }, []);

  const openContributionBuilder = useCallback(contributionId => {
    setContributionId(contributionId);
    setShowContributionBuilder(true);
  }, []);

  useEffect(() => {
    dispatch(setAuthModalOpen(isAuthOpen));
  }, [dispatch, isAuthOpen]);

  const closeAuthModal = useCallback(() => {
    closeAuth();
  }, [closeAuth]);

  // handle the actions performed in contribution builder modal
  const onSlideSaved = useCallback(
    (contributionId, isModerated) => {
      closeContributionBuilder();
      setShowSuccessModal(
        contributionId ? SLIDE_STATUS.UPDATED : SLIDE_STATUS.ADDED
      );

      if (hasRole) {
        dispatch(fetchGroupStoryContent(groupToken));
      }
      dispatch(contributionsActions.fetchMyStoryContributions());
      // if contribution goes into moderation
      // or this user has requested access and is contributing
      // either recreate thumbnails or create thumbnail for new contribution
      if (isModerated || !hasRole) {
        setModerationStatus(true);
      }
      // if content goes straight into slideshow,
      // create thumbnails for new slide
      else {
        setModerationStatus(false);
      }

      dispatch(resetEditor());
      dispatch(clearLocalLibrary());
    },
    [closeContributionBuilder, dispatch, groupToken, hasRole]
  );

  const onCloseContributionBuilder = useCallback(
    props => {
      // if the contribution builder is closed by a form submission but the user needs to be authed
      if (props?.contributionRequiresAuth) {
        openAuth();
      } else {
        closeContributionBuilder();
      }
    },
    [closeContributionBuilder, openAuth]
  );

  // handle authentication for anon users after contribution creation
  const isLoggedInPrev = usePrevious(isLoggedIn);
  useEffect(() => {
    // if we've just authenticated
    if (isLoggedInPrev === false && isLoggedIn) {
      // and there is an anonymous contribution from when the slide was saved
      // complete it as a contribution if the slide is in the editor
      // (i.e. if we authed by code - auth by link in a new session is
      // handled by processPostAuthData)
      if (anonymousContributionId && hasContributionInEditor) {
        dispatch(
          contributionsActions.completeContribution({
            contributionId: anonymousContributionId,
            groupToken,
          })
        ).then(result => {
          // ensure we have the new role data
          dispatch(fetchGroupCurrentRole(groupToken));

          onSlideSaved(null, contentModeration);
        });
      } else {
        closeContributionBuilder();
      }
    }
  }, [
    anonymousContributionId,
    closeContributionBuilder,
    contentModeration,
    dispatch,
    groupToken,
    hasContributionInEditor,
    isLoggedIn,
    isLoggedInPrev,
    onSlideSaved,
  ]);

  const onSlideDeleted = useCallback(() => {
    if (hasRole) {
      dispatch(fetchGroupStoryContent(groupToken));
    }
    dispatch(contributionsActions.fetchMyStoryContributions());
  }, [dispatch, groupToken, hasRole]);

  const hideContributionBuilder = useCallback(() => {
    setShowContributionBuilder(false);
    closeAuth();
    setShowSuccessModal(false);
  }, [closeAuth]);

  // Assign React state and constants to context object
  const ContributionBuilderContextObject = useMemo(() => {
    return {
      hideContributionBuilder,
      openContributionBuilder,
    };
  }, [hideContributionBuilder, openContributionBuilder]);

  return (
    <ContributionBuilderContext.Provider
      value={ContributionBuilderContextObject}
    >
      {props.children}

      <>
        {showContributionBuilder && (
          <ContributionBuilderModal
            onSlideSaved={onSlideSaved}
            onSlideDeleted={onSlideDeleted}
            onClose={onCloseContributionBuilder}
            isOpen={showContributionBuilder}
            groupToken={groupToken}
            params={{ contributionId }}
          />
        )}
        {showSuccessModal && (
          <EmptyModal
            onClose={() => {
              setShowSuccessModal(false);
            }}
            title="Message Sent"
            trackingId="Contribution Sent Modal"
            render={doClose => {
              return (
                <>
                  <p className="mb-2">
                    {moderationStatus && !isOwner
                      ? `Great news! Your message has been  ${
                          showSuccessModal === SLIDE_STATUS.UPDATED
                            ? "updated and is now pending approval"
                            : "sent for approval"
                        }.`
                      : `Your message has been ${
                          showSuccessModal === SLIDE_STATUS.UPDATED
                            ? "updated"
                            : "added"
                        }.`}
                  </p>
                  <SimpleButton
                    className="ph-2"
                    backgroundColor="teal5"
                    textColor="white"
                    onClick={() => {
                      setShowSuccessModal(false);
                      doClose();
                    }}
                  >
                    Got it
                  </SimpleButton>
                </>
              );
            }}
          />
        )}
        {isAuthOpen && (
          <AuthModal
            onClose={closeAuthModal}
            isOpen={isAuthOpen}
            params={{
              authScenario: authScenarios.CONTRIBUTION,
              contribute: true,
            }}
          />
        )}
      </>
    </ContributionBuilderContext.Provider>
  );
};

ContributionBuilderContextProvider.propTypes = {
  children: PropTypes.node,
};
