/* Libraries */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import Icon from "components/Icon";
/* -Components */

/* Hooks */
import useButtonOrLink from "hooks/useButtonOrLink";
/* -Hooks */

import styles from "./index.module.scss";

const HeaderNavLink = props => {
  const {
    className,
    icon,
    iconActive,
    iconOnly,
    iconSize = "24",
    isActive,
    showDesktopVersion,
    title,
    to,
    ...restProps
  } = props;

  const { Element, elementProps } = useButtonOrLink({ to });

  return (
    <div className={classnames("navbar-item", styles.navbarItem, className)}>
      <Element
        {...elementProps}
        {...restProps}
        className={classnames(styles.headerNavLink, "is-unstyled", {
          [styles.active]: isActive,
          [styles.desktopVersion]: showDesktopVersion,
          [styles.iconOnly]: iconOnly,
        })}
        title={title}
      >
        <Icon
          icon={isActive && iconActive ? iconActive : icon}
          size={iconSize}
          className={styles.navButton}
        />
        {iconOnly ? null : (
          <legend
            className={classnames(styles.legend, {
              [styles.desktopVersion]: showDesktopVersion,
            })}
          >
            {title}
          </legend>
        )}
      </Element>
    </div>
  );
};

HeaderNavLink.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconActive: PropTypes.string,
  iconOnly: PropTypes.bool,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isActive: PropTypes.bool,
  showDesktopVersion: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default React.memo(HeaderNavLink);
