/* Libraries */
import React, { lazy, Suspense } from "react";
import classnames from "classnames";
/* -Libraries */

/* Components */
import Loader from "components/Loader";
/* -Components */

import styles from "./index.module.scss";

const PrivacyContent = lazy(() => import("./PrivacyContent"));
const TermsContent = lazy(() => import("./TermsContent"));
const TermsCorporateContent = lazy(() => import("./TermsCorporateContent"));

const LegalContent = React.memo(props => {
  return (
    <div className={classnames(styles.legalContent, props.className)}>
      {props.children}
    </div>
  );
});

LegalContent.Terms = React.memo(props => {
  return (
    <LegalContent className={styles.termsContent}>
      <Suspense fallback={<LegalLoader />}>
        <TermsContent {...props} />
      </Suspense>
    </LegalContent>
  );
});

LegalContent.TermsCorporate = React.memo(props => {
  return (
    <LegalContent className={styles.termsContent}>
      <Suspense fallback={<LegalLoader />}>
        <TermsCorporateContent {...props} />
      </Suspense>
    </LegalContent>
  );
});

LegalContent.Privacy = React.memo(props => {
  return (
    <LegalContent className={styles.privacyContent}>
      <Suspense fallback={<LegalLoader />}>
        <PrivacyContent {...props} />
      </Suspense>
    </LegalContent>
  );
});

const LegalLoader = () => <Loader className={styles.legalLoader} />;

export default LegalContent;
