/* Libraries */
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as progressActions from "redux/progress/actions";
/* -Actions */

/* Selectors */
import * as mediaSelectors from "redux/media/selectors";
import * as progressSelectors from "redux/progress/selectors";
/* -Selectors */

/* Hooks */
import useIncrement from "./useIncrement";
import useTriggerOnChange from "./useTriggerOnChange";
/* -Hooks */

const useProvideTranscodeProgressSelector = delimitedIds => {
  return useMemo(() => {
    const ids = delimitedIds?.split(",") || [];
    return createSelector(
      [mediaSelectors.getLibrary, progressSelectors.getProgress],
      (library, progress) => {
        // updated by incrementer and by the actual transcode process
        const storedProgress = progressSelectors.getProgressById(
          progress,
          delimitedIds
        );
        // just the real transcode progress averaged over all IDs
        const averageProgress =
          ids.reduce((progressSum, mediaId) => {
            const media = mediaSelectors.getLibraryMediaById(library, mediaId);
            return progressSum + mediaSelectors.getProgress(media);
          }, 0) / ids.length;

        return {
          transcodeProgress: Math.round(averageProgress),
          displayProgress: storedProgress,
        };
      }
    );
  }, [delimitedIds]);
};

const useProcessingProgress = mediaIds => {
  const delimitedIds = Array.isArray(mediaIds) ? mediaIds.join(",") : mediaIds;

  const dispatch = useDispatch();

  const { displayProgress, transcodeProgress } = useSelector(
    useProvideTranscodeProgressSelector(delimitedIds)
  );

  const {
    doIncrement,
    progress: processingProgress,
    setProgress: setProcessingProgress,
  } = useIncrement(displayProgress);

  const doIncrementRef = useRef(doIncrement);

  // when the actual transcode process for this media updates
  // update the current state of the incrementer
  useEffect(() => {
    if (transcodeProgress) {
      setProcessingProgress(transcodeProgress);
    }
  }, [setProcessingProgress, transcodeProgress]);

  // when the incrementer updates, persist the progress to the storex
  const processingProgressChanged = useTriggerOnChange(processingProgress);
  useEffect(() => {
    if (processingProgressChanged) {
      if (processingProgressChanged < 100) {
        dispatch(
          progressActions.setProgress(delimitedIds, processingProgressChanged)
        );
      } else {
        dispatch(progressActions.setComplete(delimitedIds));
      }
    }
  }, [delimitedIds, dispatch, processingProgressChanged]);

  return {
    processingProgress,
    startProcessingProgress: doIncrementRef.current,
  };
};

export default useProcessingProgress;
