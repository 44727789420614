/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
/* -Selectors */

/* -Actions */
import { setEnabledFeatures } from "redux/enabledFeatures/actions";
/* -Actions */

import { noop } from "utils/clientUtils";

export const actions = {
  platformFetchSuccess: createAction("PLATFORM_FETCH_SUCCESS"),
};

// get the latest update status from the API
export const getPlatform = id => dispatch => {
  return axios
    .get(`/platform`)
    .then(response => {
      dispatch(actions.platformFetchSuccess(response.data.platform));
      dispatch(setEnabledFeatures(response.data.enabled_features));
    })
    .catch(noop);
};
