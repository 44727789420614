/* Libraries */
import React from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
/* -Libraries */

/* Components */
/* -Components */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Hooks */
/* -Hooks */

const DynamicRouteRedirect = props => {
  const params = useParams();
  const { route, routeParam } = props;

  return <Redirect to={route(params[routeParam])} />;
};

DynamicRouteRedirect.propTypes = {
  route: PropTypes.func.isRequired,
  routeParam: PropTypes.string.isRequired,
};

export default DynamicRouteRedirect;
