/* Libraries */
import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";
import ModalPortal from "components/ModalPortal";
import TrackElementPosition from "components/TrackElementPosition";
import MotionXYElement from "components/MotionXYElement";
import { IconButtonCircle } from "components/_v2/Button";

const EmojiPickerComponent = lazy(() => import("./EmojiPicker"));

const EmojiPicker = React.forwardRef((props, ref) => {
  const { className, isOpen, targetRef, ...restProps } = props;

  return (
    <ModalPortal>
      <TrackElementPosition
        element={targetRef}
        ax={0}
        ay={0}
        tx={-1}
        ty={0}
        oy={0}
        ox={-8}
        render={args => {
          if (isOpen) {
            return (
              <MotionXYElement
                className={classnames(styles.emojiPicker, className)}
                style={args.style}
              >
                <Suspense fallback={null}>
                  <IconButtonCircle
                    icon="cross"
                    className={styles.closeButton}
                    onClick={restProps.onBlur}
                  />
                  <EmojiPickerComponent {...restProps} />
                </Suspense>
              </MotionXYElement>
            );
          }
        }}
      />
    </ModalPortal>
  );
});

EmojiPicker.propTypes = {
  isOpen: PropTypes.bool,
  targetRef: PropTypes.object,
};

export default React.memo(EmojiPicker);
