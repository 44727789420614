import { mediaTypes } from "redux/media/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import thumbnail from "images/slides/thumbnails/audio.png";

const type = slideTypes.AUDIO;
const audioVariants = slideVariants[type];

export default {
  type,
  icon: "audio-fill",
  label: "Voice",
  descriptionBuilder: "Say it out loud with a voice clip",
  colour: "indigo5",
  thumbnail,
  variants: [
    {
      name: audioVariants.RECORDER,
      label: "Recorder",
    },
    {
      name: audioVariants.WAVES,
      label: "Waves",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Message",
      placeholder: "e.g. Our holiday",
      validation: {
        maxLength: {
          value: 30,
          message: `Please keep your message to 30 characters or less`,
        },
      },
    },
    {
      key: "subtitle",
      type: inputTypes.INPUT,
      label: "From",
      placeholder: "e.g. From your best friend",
      validation: {
        maxLength: {
          value: 30,
          message: `Please keep this to 30 characters or less`,
        },
      },
    },
    {
      key: "audio",
      errorMessage: "You need to upload at least one audio",
      type: inputTypes.MEDIA,
      mediaType: mediaTypes.AUDIO,
      label: "Recording",
      icon: "slide-audio",
      validation: {
        minLength: {
          value: 1,
          message: `Please select an voice clip for the slide`,
        },
        maxLength: { value: 1, message: `You can only add one.` },
      },
    },
  ],
};
