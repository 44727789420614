/* Hooks */
import usePrevious from "./usePrevious";
/* -Hooks */

const useTriggerOnChange = (currentValue, targetValue = null) => {
  const valuePrev = usePrevious(currentValue);
  // has currentValue changed from a value or been set for the first time
  const valueChanged = currentValue !== valuePrev && valuePrev !== undefined;
  // if a target value is supplied, check currentValue against it
  // const valueIsTarget = targetValue !== null ? currentValue === targetValue : true;

  return valueChanged ? currentValue : false;
};

export default useTriggerOnChange;
