export const roleTypes = {
  null: null,
  owner: "owner",
  member: "member",
  contributor: "contributor",
  contributorViewer: "contributor_viewer",
  viewer: "viewer",
  recipient: "recipient",
};

export const rolesFriendlyNames = {
  [roleTypes.null]: "blocked",
  [roleTypes.owner]: "owner",
  [roleTypes.contributor]: "contributor",
  [roleTypes.contributorViewer]: "Contributor and viewer",
  [roleTypes.viewer]: "viewer",
  [roleTypes.recipient]: "Recipient",
};

export const invitationStatus = {
  accepted: "accept",
  rejected: "reject",
};

export const roleStatus = {
  pending: "pending",
  requested: "requested",
  active: "active",
  rejected: "rejected",
};

export const roleTokenTypes = {
  approveToken: "approve_token",
  joinToken: "join_token",
};

export const getId = role => role?.id;
export const getType = role => role?.role_type;
export const getStatus = role => role?.status;
export const getJoinToken = role => role?.join_token;
export const getWatchedOn = role => role?.last_watched_on;
export const hasWatched = role => !!role?.last_watched_on;

export const roleExists = role => !!getId(role);

export const getTypeFriendly = role => rolesFriendlyNames[getType(role)];

export const getRoleIsOwner = role => getType(role) === roleTypes.owner;
export const getRoleIsContributor = role =>
  getType(role) === roleTypes.contributor;
export const getRoleIsRecipient = role => getType(role) === roleTypes.recipient;

export const getRoleIsViewerOnly = role => getType(role) === roleTypes.viewer;

export const getRoleIsActive = role => getStatus(role) === roleStatus.active;
export const getRoleIsPending = role => getStatus(role) === roleStatus.pending;
export const getContributorInvitations = role =>
  getRoleIsPending(role) && getRoleIsContributor(role);
export const getRoleIsRequested = role =>
  getStatus(role) === roleStatus.requested;
export const getRoleIsRejected = role =>
  getStatus(role) === roleStatus.rejected;

export const getRoleStoryId = role => role?.story_id;

export const isRoleActiveMember = role =>
  getRoleIsActive(role) &&
  (getRoleIsOwner(role) ||
    getRoleIsContributor(role) ||
    getRoleIsRecipient(role));
