/* Libraries */
import React, { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
import * as soundtrackSelectors from "redux/soundtrack/selectors";
/* -Selectors */

/* Components */
import Banner from "../Banner";
import Icon from "components/Icon";
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

const selector = createSelector(
  [soundtrackSelectors.getAction],
  soundtrackAction => {
    return {
      trackAdded: soundtrackSelectors.didAddTrack(soundtrackAction),
      trackRemoved: soundtrackSelectors.didRemoveTrack(soundtrackAction),
    };
  }
);

const SoundtrackUpdateBanner = props => {
  const { trackAdded, trackRemoved } = useSelector(selector);
  const [showBanner, showBannerSet] = useState(false);

  useEffect(() => {
    showBannerSet(trackAdded || trackRemoved);
  }, [trackAdded, trackRemoved]);

  return (
    <Banner
      className={classnames(
        styles.soundtrackUpdateBanner,
        trackAdded ? styles.trackAdded : ""
      )}
    >
      {showBanner && <BannerContent {...{ trackAdded, trackRemoved }} />}
    </Banner>
  );
};

const BannerContent = React.memo(props => {
  const { trackAdded, trackRemoved } = props;

  return (
    <>
      <h1 className={classnames("h6", styles.message)}>
        {trackAdded && (
          <>
            <Icon
              icon="check-fill"
              size="26"
              className={classnames("mr-075", styles.icon)}
            />{" "}
            Track Added
          </>
        )}
        {trackRemoved && (
          <>
            <Icon
              icon="bin"
              size="24"
              className={classnames("mr-075", styles.icon)}
            />{" "}
            Track Removed
          </>
        )}
      </h1>
    </>
  );
});

export default React.memo(SoundtrackUpdateBanner);
