/* Libraries */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
/* Libraries */

/* Actions */
import * as mediaActions from "redux/media/actions";
/* -Actions */

/* Selectors */
import * as mediaSelectors from "redux/media/selectors";
/* -Selectors */

/* Hooks */
import useIsMounted from "./useIsMounted";
/* -Hooks */

import { noop } from "utils/clientUtils";

const { AVAILABLE, FAILED } = mediaSelectors.mediaStatus;

/** Poll media whilst status is not available or failed so we know when it's ready. */
const usePollMedia = (
  mediaItems,
  onAvailable = noop,
  type = "any",
  delay = 2500
) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  useEffect(() => {
    const polls = mediaItems
      .filter(
        media =>
          media.status !== AVAILABLE &&
          media.status !== FAILED &&
          (type === "any" || media.type === type)
      )
      .map(media => {
        const timeout = setTimeout(() => {
          dispatch(mediaActions.fetchMedia(mediaSelectors.getId(media))).then(
            newMedia => {
              if (isMounted()) {
                mediaSelectors.getStatus(newMedia) === AVAILABLE &&
                  onAvailable([newMedia]);
              }
            }
          );
        }, delay);

        return timeout;
      });

    return () => polls.forEach(timeout => clearTimeout(timeout));
  }, [delay, dispatch, isMounted, mediaItems, onAvailable, type]);
};

export default usePollMedia;
