/* Libraries */
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import { SEND_METHODS } from "redux/anonymousData/selectors";
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

import { paths } from "routes/PageRoutes";

const selector = createSelector(storySelectors.getStory, story => {
  return storySelectors.getId(story);
});

// get the story ID either from the route parameters or from the anonymousData store
const useGetSendMethodUrl = (sendMethod, storyIdProp) => {
  const storyId = useSelector(selector) || storyIdProp;
  let sendOptionUrl = paths.editSend(storyId);

  switch (sendMethod) {
    case SEND_METHODS.EMAIL:
      sendOptionUrl = paths.editSendByEmail(storyId);
      break;
    case SEND_METHODS.LINK:
      sendOptionUrl = paths.editSendByLink(storyId);
      break;
    case SEND_METHODS.POST:
      sendOptionUrl = paths.editSendByPost(storyId);
      break;

    default:
      break;
  }

  return sendOptionUrl;
};

export default useGetSendMethodUrl;
