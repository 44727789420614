import audio from "./audio/config";
import credits from "./credits/config";
import date from "./date/config";
import gif from "./gif/config";
import legacy from "./legacy/config";
import location from "./location/config";
import music from "./music/config";
import news from "./news/config";
import photos from "./photos/config";
import text from "./text/config";
import title from "./title/config";
import video from "./video/config";

export const slideConfigs = [
  video,
  photos,
  audio,
  gif,
  text,
  music,
  location,
  date,
  // the remaining slide types do not appear in the type selector to create new slides
  title,
  news,
  credits,
  legacy,
];
