/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

// import analytics from "utils/analytics";

export const actions = {
  setSlidesViewAs: createAction("SET_SLIDES_VIEW_AS"),
  setGroupSlidesViewAs: createAction("SET_GROUP_SLIDES_VIEW_AS"),
  setKindeosViewAs: createAction("SET_KINDEO_VIEW_AS"),
};

export const setSlidesViewAs = payload => dispatch => {
  dispatch(actions.setSlidesViewAs(payload));
};

export const setGroupSlidesViewAs = payload => dispatch => {
  dispatch(actions.setGroupSlidesViewAs(payload));
};

export const setKindeosViewAs = payload => dispatch => {
  dispatch(actions.setKindeosViewAs(payload));
};
