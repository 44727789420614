/* Libraries */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
import CoreButton from "../CoreButton";
import Icon from "components/Icon";
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

const DoubleContentButton = props => {
  const {
    className,
    iconLeft,
    iconLeftClassName,
    iconLeftSize,
    iconRight,
    iconRightClassName,
    iconRightSize,
    subtitle,
    title,
    ...restProps
  } = props;

  return (
    <CoreButton
      {...restProps}
      className={classnames("is-full-width", className)}
      icon={iconLeft}
      iconSize={iconLeftSize}
      iconClassName={iconLeftClassName}
      size="large"
    >
      <span className={styles.content}>
        <span className={styles.copy}>
          {title}
          {subtitle}
        </span>
        {iconRight && (
          <Icon
            icon={iconRight}
            size={iconRightSize}
            className={classnames(styles.iconRight, iconRightClassName)}
          />
        )}
      </span>
    </CoreButton>
  );
};

DoubleContentButton.propTypes = {
  iconLeft: PropTypes.string,
  iconLeftClassName: PropTypes.string,
  iconLeftSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconRight: PropTypes.string,
  iconRightClassName: PropTypes.string,
  iconRightSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

export default React.memo(DoubleContentButton);
