import { padInt, secondsToHMS } from "utils/numberUtils";

export const mediaTypes = {
  AUDIO: "audio",
  PHOTO: "photo",
  TEXT: "text",
  VIDEO: "video",
};
export const mediaTypeReferences = {
  [mediaTypes.AUDIO]: "recording",
  [mediaTypes.PHOTO]: "photo",
  [mediaTypes.VIDEO]: "video",
};

export const mediaStatus = {
  AVAILABLE: "available",
  FAILED: "failed",
  PROCESSING: "processing",
  UPLOADED: "uploaded",
};

const mimeHeic = ["image/heic"];
export const mimeTypes = {
  AUDIO: [
    "audio/x-m4a", // Android Voice Recorder
    "audio/mpeg",
    "audio/mp3",
    "audio/ogg",
    "audio/wav",
    "audio/x-wav",
  ],
  AUDIO_MOBILE: ["audio/*"],
  PHOTO: ["image/jpeg", "image/png", "image/gif"],
  VIDEO: [
    "video/mp4",
    "video/mpeg",
    "video/x-ms-wmv",
    "video/quicktime",
    "video/3gpp",
  ],
};

export const captureHeight = 1280;
export const captureWidth = 720;
export const hdConstraints = {
  video: true,
  // video: {
  //   width: { ideal: captureWidth },
  //   height: { ideal: captureHeight },
  // },
};

export const mediaDeviceAvailable = navigator.mediaDevices;

export const getMedia = state => state.media;
export const getLibrary = state => getMedia(state).library;
export const getLibraryItemsByMediaType = (library, mediaType) =>
  library.filter(item => getType(item) === mediaType);
export const getLibraryAudio = library =>
  getLibraryItemsByMediaType(library, mediaTypes.AUDIO);
export const getLibraryPhotos = library =>
  getLibraryItemsByMediaType(library, mediaTypes.PHOTO);
export const getLibraryVideos = library =>
  getLibraryItemsByMediaType(library, mediaTypes.VIDEO);

export const getLimits = state => getMedia(state)?.limits;
export const getMediaLimit = (limits, mediaType) =>
  limits[mediaType]?.max_size_bytes;

export const numberOfMediaInLibrary = state => getLibrary(state).length;
export const getMediaTypeCount = state => {
  const library = getLibrary(state);
  return {
    [mediaTypes.AUDIO]: getLibraryAudio(library).length,
    [mediaTypes.PHOTO]: getLibraryPhotos(library).length,
    [mediaTypes.VIDEO]: getLibraryVideos(library).length,
  };
};

export const getMediaUploading = state => getMedia(state)?.uploading;

export const getIsHeic = imageType => mimeHeic.includes(imageType);

export const getId = media => media?.id;
export const getType = media => media?.type;
export const getStatus = media => media?.status;
export const getDuration = media => media?.duration;
export const getDurationFormatted = media =>
  secondsToHMS(getDuration(media)) || "";
export const getCreatedDate = media => {
  const date = new Date(media.created_on);
  return `${padInt(date.getDate())}.${padInt(
    date.getMonth() + 1 // JS months are zero-indexed
  )}.${date.getFullYear()}`;
};
export const getContributorName = media =>
  media?.contribution?.submitter_name || "";

export const getAspectRatio = media => media?.aspect_ratio || null;
export const isPortrait = media => media?.aspect_ratio < 1;
export const getUserId = media => media?.user_id;
export const getUrl = media => media?.url;
export const getMp4Url = media => media?.mp4_url;
export const getDownloadUrl = media =>
  (getMp4Url(media) || getUrl(media)) + "?dl=1";
export const getVideoThumbnail = media => media?.thumbnail_url;
export const getProgress = media => media?.progress;
export const isAvailable = media => getStatus(media) === mediaStatus.AVAILABLE;

export const areMediaProcessing = library =>
  !library.every(media => isAvailable(media));

export const getLibraryMediaById = (library, id) => {
  return library.find(media => getId(media) === id);
};
