/* Libraries */
import React, { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import semverCompare from "semver/functions/compare";
import semverDiff from "semver/functions/diff";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
import { getMaintenanceOn, getWebVersion } from "redux/platform/selectors";
/* -Selectors */

/* Components */
import Banner from "../Banner";
import { CoreButton } from "components/_v2/Button";
/* -Components */

/* Hooks */
/* -Hooks */

import { isValidDate } from "utils/dateUtils";

import styles from "./index.module.scss";

const selector = createSelector(
  [getMaintenanceOn, getWebVersion],
  (maintenanceDate, latestWebVersion) => {
    return {
      maintenanceDate,
      latestWebVersion,
    };
  }
);

const getCountdownTargetMs = target => {
  if (target && isValidDate(target)) {
    const now = Date.now();
    const then = new Date(target).getTime();
    return Math.floor(then - now);
  }

  return null;
};

const reload = () => {
  window.location.reload();
};

const MaintenanceBanner = props => {
  const { maintenanceDate, latestWebVersion } = useSelector(selector);

  const [showBanner, showBannerSet] = useState(true);
  const [targetMs, targetMsSet] = useState();
  const [voluntaryUpdate, voluntaryUpdateSet] = useState(false);
  const [forcedUpdate, forcedUpdateSet] = useState(false);
  const [countdownTarget, countdownTargetSet] = useState();

  const currentWebVersion = process.env.REACT_APP_VERSION;

  const dimissBanner = () => {
    showBannerSet(false);
  };

  // set countdown target when maintenance date is updated
  useEffect(() => {
    if (maintenanceDate) {
      countdownTargetSet(maintenanceDate);
    }
  }, [maintenanceDate]);
  // or when a forced update is required - 60 seconds from now
  useEffect(() => {
    if (forcedUpdate) {
      const now = Date.now();
      countdownTargetSet(new Date(now + 60 * 1000));
    }
  }, [forcedUpdate]);

  // update countdown values
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      requestAnimationFrame(() => {
        targetMsSet(getCountdownTargetMs(countdownTarget));
      });
    }, 200);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [countdownTarget, targetMs]);

  // show the banner if there is a later version available
  useEffect(() => {
    // guard against missing versions
    if (currentWebVersion && latestWebVersion) {
      // guard against unparseable versions
      try {
        // if the latest is greater than the current
        if (semverCompare(latestWebVersion, currentWebVersion) === 1) {
          // set and unset flags depending on the version diff
          // (setting to false allows us to recover from flags set by incorrect versions in the database)
          const patchUpdate =
            semverDiff(latestWebVersion, currentWebVersion) === "patch";
          voluntaryUpdateSet(patchUpdate);
          forcedUpdateSet(!patchUpdate);
        }
      } catch {}
    }
  }, [currentWebVersion, latestWebVersion]);

  // if our timer has passed 0
  if (maintenanceDate || forcedUpdate) {
    if (targetMs < 0) {
      reload();
    }
  }

  if (maintenanceDate || voluntaryUpdate || forcedUpdate) {
    return (
      <Banner
        className={styles.maintenanceBanner}
        onClose={!forcedUpdate ? dimissBanner : null}
        padBody
      >
        {showBanner && (
          <div className={classnames("container", styles.content)}>
            <div className={styles.notice}>
              {maintenanceDate && (
                <>
                  <p>
                    Kindeo is undergoing scheduled maintenance in{" "}
                    <MaintenanceCountdown
                      target={Math.round(targetMs / 1000)}
                    />{" "}
                  </p>
                </>
              )}

              {!maintenanceDate && (forcedUpdate || voluntaryUpdate) && (
                <>
                  <p>A new version of Kindeo is available!</p>
                  {forcedUpdate && (
                    <>
                      <p className={"mt-025"}>
                        If you are editing your Kindeo, make sure you hit “Save”
                        to not lose your changes
                      </p>
                      <p
                        className={classnames(styles.updateCountdown, "mt-05")}
                      >
                        Site will update in{" "}
                        <MaintenanceCountdown
                          target={Math.round(targetMs / 1000)}
                        />
                      </p>
                    </>
                  )}
                  <ReloadButton />
                </>
              )}
            </div>
          </div>
        )}
      </Banner>
    );
  }

  return null;
};

const ReloadButton = props => {
  return (
    <p className="mt-1">
      <CoreButton
        design="secondary"
        className={styles.reloadButton}
        onClick={reload}
        size="small"
        icon="download"
        iconPosition="right"
      >
        Update now
      </CoreButton>
    </p>
  );
};

const MaintenanceCountdown = React.memo(props => {
  const { target } = props;

  const minuteDivider = 60;
  const hoursDivider = 60 * minuteDivider;

  let seconds = Math.max(target, 0);
  const hours = Math.floor(seconds / hoursDivider);
  seconds = seconds - hours * hoursDivider;
  const minutes = Math.floor(seconds / minuteDivider);
  seconds = seconds - minutes * minuteDivider;

  return (
    <span className={styles.countdown}>
      {`${hours ? `${hours} ${hours > 1 ? "hours" : "hour"} ` : ""}${
        minutes ? `${minutes} ${minutes > 1 ? "mins" : "min"} ` : ""
      }${minutes < 1 ? `${seconds} ${seconds > 1 ? "secs" : "sec"}` : ""}
      `}
    </span>
  );
});

export default React.memo(MaintenanceBanner);
