/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
import { throttle } from "throttle-debounce";
/* -Libraries */

/* Selectors */
/* -Selectors */

import * as apiUtils from "utils/apiUtils";
import { noop } from "utils/clientUtils";

export const actions = {
  mediaFetchLibrarySuccess: createAction("MEDIA_FETCH_LIBRARY_SUCCESS"),
  mediaFetchLimitsSuccess: createAction("MEDIA_FETCH_LIMITS_SUCCESS"),
  mediaCreateSuccess: createAction("MEDIA_CREATE_SUCCESS"),
  mediaFetchSuccess: createAction("MEDIA_FETCH_SUCCESS"),
  mediaDismiss: createAction("MEDIA_DISMISS"),
  setMediaUploading: createAction("MEDIA_UPLOADING"),
  resetLibrary: createAction("MEDIA_RESET"),
};

export const setMediaUploading = uploading => dispatch => {
  dispatch(actions.setMediaUploading(uploading));
};

export const fetchMediaLimits = () => dispatch => {
  return axios.get("/media/limits").then(response => {
    const { limits } = response.data;
    dispatch(actions.mediaFetchLimitsSuccess(limits));
    return limits;
  });
};

export const fetchLibrary =
  (storyId, mediaType = null) =>
  (dispatch, getState) => {
    return axios
      .get(`/story/${storyId}/media`, {
        params: mediaType
          ? {
              media_type: mediaType,
            }
          : {},
      })
      .then(response => {
        const { media } = response.data;
        dispatch(fillMediaLibrary(media));
        return media;
      })
      .catch(() => {
        console.error("failed to get library for story", storyId);
      });
  };

// This populates the media store, called from fetchLibrary here and from userRoles actions
export const fillMediaLibrary = media => dispatch => {
  return dispatch(actions.mediaFetchLibrarySuccess(media));
};

// fetch an individual media item
export const fetchMedia = id => dispatch => {
  return axios.get(`/media/${id}`).then(response => {
    const { media } = response.data;

    dispatch(actions.mediaFetchSuccess(media));
    return media;
  });
};

export const fetchMediaUsage = id => () => {
  return axios.get(`/media/${id}/usage`).then(response => {
    return response.data.slides;
  });
};

export const removeMedia = mediaId => dispatch => {
  return axios.delete(`/media/${mediaId}`).then(() => {
    dispatch(dismissMedia(mediaId));
  });
};

export const dismissMedia = mediaId => dispatch => {
  dispatch(actions.mediaDismiss(mediaId));
};

export const createMedia = media => {
  const { file_size_kb, key, mediaType, mimeType, storyId } = media;

  return dispatch => {
    const mediaProps = {
      file_size_kb,
      s3_key: key,
      media_type: mediaType,
      mime_type: mimeType,
    };
    const storyProps = storyId ? { story_id: storyId } : {};

    return axios
      .post("/media", {
        ...mediaProps,
        ...storyProps,
      })
      .then(response => {
        dispatch(actions.mediaCreateSuccess(response.data.media));
        return response.data.media;
      });
  };
};
const getUploadPermissions = (mediaType, mimeType) => dispatch => {
  return axios
    .post("/media/upload", { media_type: mediaType, mime_type: mimeType })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw new Error(
        "Failed to get upload permissions",
        mediaType,
        mimeType,
        error
      );
    });
};

// throttle and call upload progress callback with percentage completion
const provideUploadProgress = uploadProgressCallback => {
  return throttle(500, progressEvent => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    uploadProgressCallback(percentCompleted);
  });
};

export const beginFileUpload =
  ({ file, mediaType, mimeType, onUploadProgress = noop }) =>
  async dispatch => {
    try {
      // get S3 upload authorization headers for the file type
      const { url: uploadUrl, fields: uploadFields } = await dispatch(
        getUploadPermissions(mediaType, mimeType)
      );

      // we can add an upload ID to store here, and use it to track progress

      // create a controller to abort the upload if needed
      const controller = new AbortController();
      // postForm to send file data to S3
      const request = axios.postForm(
        uploadUrl,
        { ...uploadFields, file },
        {
          // Clear the Kindeo-specific Authorization header for this request
          headers: { [apiUtils.AUTH_HEADER]: null },
          onUploadProgress: provideUploadProgress(onUploadProgress),
          signal: controller.signal,
        }
      );

      return {
        cancel: () => {
          controller.abort();
        },
        request,
        s3Key: uploadFields.key,
      };
    } catch (error) {
      throw new Error(error);
    }
  };

export const clearLocalLibrary = () => dispatch => {
  dispatch(actions.resetLibrary());
};
