import { paths } from "routes/PageRoutes";

export const EVENTS = {
  account: {
    updated: "Account Updated",
    paths: {
      [paths.account()]: "Viewed Account Page",
      [paths.accountAddresses()]: "Viewed Account Addresses List",
      [paths.accountAddressDetails(":id")]: "Viewed Account Address Details",
      [paths.accountProfile()]: "Viewed Account Profile",
      [paths.accountPayments()]: "Viewed Account Payment History",
      [paths.accountPaymentDetails(":id")]: "Viewed Account Payment Details",
    },
  },
  auth: {
    authFail: "Authentication Fail",
    authSuccess: "Authentication Success",
    codeRequested: "Auth Code Requested",
    linkRequested: "Auth Link Requested",
    register: "Auth Register Screen",
  },
  coverEditor: {
    coverSelected: "Cover Selected",
    viewChangeCard: "Viewed Change Card Cover",
    themeSelected: "Theme Selected",
    photoUploaded: "Cover Photo Uploaded",
    photoRemoved: "Cover Photo Removed",
    greetingTo: "Greeting To Updated",
    greetingMessage: "Greeting Message Updated",
    greetingFrom: "Greeting From Updated",
  },
  create: {
    step: "Create Step",
    kindeo: "Created Kindeo",
  },
  contributors: {
    paths: {
      [paths.group(":groupToken")]: "Viewed Group Page",
      [paths.groupSlides(":groupToken")]: "Viewed Group Page",
      [paths.groupRoles(":groupToken")]: "Viewed Group Roles Panel",
    },
  },
  edit: {
    approveAllContributions: "Approve All Contributions",
    cover: {
      createCancelled: "Create Kindeo Cancelled",
      editStart: "Edit Cover Mode",
      editCancelled: "Edit Cover Cancelled",
      editCover: "Personalise Cover Step",
      editGreeting: "Edit Greeting Step",
      saved: "Cover Saved",
    },
    paths: {
      [paths.edit(":id")]: "Viewed Slides Panel",
      [paths.editSlides(":id")]: "Viewed Slides Panel",
      [paths.editGroup(":id")]: "Viewed Group Panel",
      [paths.editSend(":id")]: "Viewed Send Panel",
      [paths.editSendByEmail(":id")]: "Viewed Send by Email Panel",
      [paths.editSendByLink(":id")]: "Viewed Send by Link Panel",
      [paths.editSendByPost(":id")]: "Viewed Send by Post Panel",
      [paths.editGroupInvite(":id")]: "Viewed Invite",
      [paths.editSettings(":id")]: "Viewed Settings Panel",
      [paths.editSoundtrack(":id")]: "Viewed Soundtracks Panel",
      [paths.editSoundtrackLibrary(":id")]: "Viewed Soundtrack Library",
    },
    slides: {
      search: "Slide Search",
      filterApplied: "Filter Applied",
      gridSizeUpdated: "Grid Size Updated",
    },
  },
  group: {
    nudgeMember: "Nudge Member",
    nudgeAll: "Nudge All Members",
    approveMember: "Approve Member",
    approveAll: "Approve All Members",
    reinviteMember: "Reinvite Member",
    deleteMemberModal: "Delete Member Confirmation Modal",
    inviteSent: "Group Link Shared",
    linkShared: "Group Link Shared",
    panelExpanded: "Group Member Panel Expanded",
    editInvitationExpanded: "Group Invitation Expanded",
    permissionChange: "Can View Toggled",
    removed: "Group Member Removed",
    finished: "Finish this Kindeo Clicked",
    search: "Group Search",
    filterApplied: "Group Filter Applied",
  },
  modals: {
    inviteQrCode: "Invite QR Code Modal",
    recipientQrCode: "Recipient QR Code Modal",
  },
  postalOrder: {
    purchased: "Posted Card Purchased",
  },
  purchase: {
    options: "Viewed Upgrade Options",
    basket: "Viewed Payment Summary",
    payment: "Viewed Payment",
    upgradeRequest: "Unlock Premium Setting Clicked",
    paywallReached: "Paywall Reached",
    cardPurchased: "Kindeo Purchased",
  },
  playlist: {
    myPlaylistAdded: "Added to My Playlist",
    myPlaylistRemoved: "Removed from My Playlist",
    playlistPlayed: "Soundtrack Played",
    playlistSelected: "Playlist Selected",
    playlistShuffled: "Playlist Shuffle Toggled",
    playlistSkipped: "Soundtrack Skipped",
    trackPlayed: "Track Played",
  },
  recipient: {
    paths: {
      [paths.recipient(":recipientToken")]: "Viewed Recipient Landing Page",
      [paths.recipientRoles(":recipientToken")]: "Viewed Recipient Group Panel",
      [paths.recipientSlides(":recipientToken")]: "Viewed Recipient Slides",
      [paths.recipientThanks(":recipientToken")]: "Viewed Thanks Panel",
    },
    playedKindeo: "Recipient Played Kindeo",
    requestAccess: "Recipient Requested Access",
    linkShared: "Recipient Link Shared",
    saveKindeo: "Recipient Save Kindeo Clicked",
    thanks: {
      removed: "Thanks Removed",
      sent: "Thanks Sent",
      skipped: "Thanks Skipped",
    },
  },
  reminders: {
    created: "Reminder Created",
    deleted: "Reminder Deleted",
    updated: "Reminder Updated",
    viewedCreate: "Viewed Create Reminder Page",
    viewedEdit: "Viewed Edit Reminder Page",
    viewedHome: "Viewed Reminders Page",
  },
  sendPanel: {
    kindeoSent: "Kindeo Sent",
    linkShared: "Recipient Link Shared",
    messageUpdated: "Recipient Message Updated",
  },
  settings: {
    contributionDateUpdated: "Contribution Date Updated",
    ownerContributionEmails: "Contribution Update Emails",
    fxConfetti: "Fx Confetti Updated",
    fxSnow: "Fx Snow Updated",
    inviteLinkReset: "Invite Link Reset",
    recipientLinkReset: "Recipient Link Reset",
    includeCredits: "Include Credits Updated",
    kindeoDeleted: "Kindeo Deleted",
    kindeoPrivate: "Kindeo Made Private",
    moderationUpdated: "Content Moderation Updated",
    recipientLinkPrivacyUpdated: "Recipient Link Privacy Updated",
    inviteLinkPrivacyUpdated: "Invite Link Privacy Updated",
    kindeoNameUpdated: "Card Title Updated",
    occasionDateUpdated: "Occasion Date Updated",
    recipientFirstNameUpdated: "Recipient First Name Updated",
    recipientLastNameUpdated: "Recipient Last Name Updated",
    visibilityUpdated: "Kindeo Visibility Updated",
    watchLinkReset: "Watch Link Reset",
    welcomeUpdated: "Invite Message Updated",
  },
  sharePanel: {
    linkShared: "Watch Link Shared",
  },
  slideBuilder: {
    audioCaptureDeleted: "Audio Capture Deleted",
    audioCaptureConfirmed: "Audio Capture Confirmed",
    audioCaptureShown: "Audio Capture Modal",
    audioCaptureStarted: "Audio Recording Started",
    audioCaptureEnded: "Audio Recording Ended",
    photoCaptureConfirmed: "Photo Capture Confirmed",
    photoCaptured: "Photo Captured",
    photoCaptureDeleted: "Photo Capture Deleted",
    photoCaptureShown: "Photo Capture Modal",
    videoCaptureDeleted: "Video Capture Deleted",
    videoCaptureConfirmed: "Video Capture Confirmed",
    videoCaptureShown: "Video Capture Modal",
    videoCaptureStarted: "Video Recording Started",
    videoCaptureEnded: "Video Recording Ended",
  },
  summary: {
    search: "Kindeo Search",
    filterApplied: "Filter Applied",
    gridSizeUpdated: "Grid Size Updated",
    memberInvite: "Group Member Invite",
  },
  groupWatchPage: {
    memberLeft: "Member Left Group",
    memberRequestAccess: "Member Requested Access",
  },
  contributionModerated: "Contribution Moderated",
  landing: {
    getStarted: "Get Started Clicked",
    setReminder: "Set Reminder Clicked",
    toWorkLanding: "Kindeo At Work Clicked",
  },
  send: {
    method: "Send Method Clicked",
  },
  sendByPost: {
    panelViewed: "Viewed Send By Post Panel",
    orderSummaryViewed: "Viewed Postal Order Summary",
    addressSaved: "Address Saved",
    orderCreated: "Postal Order Created",
  },
  mediaProcessing: {
    started: "Processing Started",
    success: "Processing Ready",
    error: "Processing Error",
  },
  mediaUpload: {
    uploadSelected: "Upload Selected",
    recordSelected: "Record Selected",
    started: "Upload Started",
    completed: "Upload Completed",
    cancelled: "Upload Cancelled",
    failed: "Upload Error",
  },
  workLanding: {
    getStarted: "Work Get Started Clicked",
    pricingChecked: "Work Pricing Input Changed",
    pricingOpened: "Open Work Pricing",
  },
  errors: {
    addContribution: "Adding Contribution",
    addMessage: "Adding Creator Message",
    addThanks: "Adding Recipient Thanks",
    authByLink: "Auth By Link",
    createCard: "Creating Card",

    acceptAllContributions: "Accept All Contributions",
    authEmail: "Authentication Email Step",
    authCode: "Authentication Code Step",
    authName: "Registration Name Step",
    businessPricing: "Business Pricing Request",
    contactForm: "Contact Form",
    createAddress: "Save New Address",
    createReminder: "Save New Reminder",
    deleteAccount: "Delete Account",
    deleteInvite: "Decline Contribution Invitation",
    deleteMedia: "Delete Media Item",
    editAddress: "Edit Address",
    editReminder: "Edit Reminder",
    hideMyEmail: "Hide My Email",
    requestAccess: "Request Access",
    sendContributor: "Send To Contributor",
    sendRecipient: "Send To Recipient",
    setupRecipient: "Set Up Recipient",
    updateAccount: "Update Account Details",
    useAuthCode: "Use Auth Code",
  },
};

export const keysSearchFilterEvents = {
  group: "group",
  slides: "slides",
  home: "home",
};

export const searchFilterEvents = {
  [keysSearchFilterEvents.group]: {
    search: EVENTS.group.search,
    filterApplied: EVENTS.group.filterApplied,
  },
  [keysSearchFilterEvents.slides]: {
    search: EVENTS.edit.slides.search,
    filterApplied: EVENTS.edit.slides.filterApplied,
    gridSizeUpdated: EVENTS.edit.slides.gridSizeUpdated,
  },
  [keysSearchFilterEvents.home]: {
    search: EVENTS.summary.search,
    filterApplied: EVENTS.summary.filterApplied,
    gridSizeUpdated: EVENTS.summary.gridSizeUpdated,
  },
};

const api = {
  loadSegment: tracking_id => {
    window.analytics._writeKey = tracking_id;
    window.analytics.load(tracking_id);
  },
  identify: (id, traits = null) => {
    window.analytics.identify(id, traits);
  },
  reset: () => {
    window.analytics.reset();
  },
  event: (event, payload) => {
    window.analytics.track(event, payload);
  },
  page: (name, payload) => {
    window.analytics.page(name, payload);
  },
  error: (event, payload) => {
    api.event("Error: " + event, payload);
  },
};
export default api;
