/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* --Actions */

/* Selectors */
import { getPaymentId } from "redux/account/selectors";
/* --Selectors */

const INITIAL_STATE = {
  payments: [],
  profile: null,
};

const createProfile = (state, { payload }) => {
  return {
    ...state,
    profile: payload,
  };
};

const setPayments = (state, { payload }) => {
  return {
    ...state,
    payments: payload,
  };
};
const appendPayments = (state, { payload }) => {
  return {
    ...state,
    payments: [...state.payments, ...payload],
  };
};

const setPaymentDetails = (state, { payload }) => {
  const paymentExists = state.payments.find(
    payment => getPaymentId(payment) === getPaymentId(payload)
  );
  return {
    ...state,
    payments: paymentExists
      ? state.payments.map(slide => {
          if (getPaymentId(slide) === getPaymentId(payload)) {
            return { ...slide, ...payload };
          }
          return slide;
        })
      : [payload, ...state.payments],
  };
};

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.paymentsFetchSuccess]: setPayments,
  [actions.paymentsFetchPageSuccess]: appendPayments,
  [actions.paymentDetailFetchSuccess]: setPaymentDetails,
  [actions.profileFetchSuccess]: createProfile,
  [authActions.logoutInit]: reset,
});
