/* Libraries */
import { createReducer, current } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
// import { getNextNonWeekendDate } from "./selectors";
import { toYMD } from "utils/dateUtils";
/* --Actions */

const INITIAL_STATE = {
  currentOrder: {
    id: null,
    address: null,
    address_id: null,
    delivery_by: null,
    destination: null,
    postal_class: null,
    scheduled_delivery: null,
    status: null,
    story_id: null,
  },
  card_classes: [],
  postal_classes: [],
  history: [],
  send_by_post_enabled: null,
};

const setPostalOptions = (state, { payload }) => {
  return {
    ...state,
    card_classes: payload.card_classes,
    postal_classes: payload.postal_classes,
    send_by_post_enabled: payload.send_by_post_enabled,
  };
};

const setHistory = (state, { payload }) => {
  return {
    ...state,
    history: payload,
  };
};

const setDetails = (state, { payload }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      ...payload,
    },
    history: [
      ...state.history.map(order => {
        return order.id === payload.id ? { ...order, ...payload } : order;
      }),
    ],
  };
};

const setStoryId = (state, { payload }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      story_id: payload,
    },
  };
};

const setAddressId = (state, { payload }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      address_id: payload,
    },
  };
};

const setAddress = (state, { payload }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      address: payload,
    },
  };
};

const setDeliverySchedule = (state, { payload }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      scheduled_delivery: !!payload,
    },
  };
};

const setDeliveryDate = (state, { payload }) => {
  // const nextSafeDate = getNextNonWeekendDate(payload);
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      delivery_by: toYMD(payload),
    },
  };
};

const setDestination = (state, { payload }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      destination: payload,
    },
  };
};

const setPostalClass = (state, { payload }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      postal_class: payload,
    },
  };
};

const resetDetails = (state, { payload: clearOnlyCompleteOrder }) => {
  const currentOrder = current(state.currentOrder);
  if (clearOnlyCompleteOrder && !currentOrder.id) {
    return state;
  }

  return {
    ...state,
    currentOrder: INITIAL_STATE.currentOrder,
  };
};

const reset = () => {
  return INITIAL_STATE;
};

export default createReducer(INITIAL_STATE, {
  [actions.setAddress]: setAddress,
  [actions.setAddressId]: setAddressId,
  [actions.setDetails]: setDetails,
  [actions.setDeliveryDate]: setDeliveryDate,
  [actions.setDeliverySchedule]: setDeliverySchedule,
  [actions.setDestination]: setDestination,
  [actions.setHistory]: setHistory,
  [actions.setPostalClass]: setPostalClass,
  [actions.setPostalOptions]: setPostalOptions,
  [actions.setStoryId]: setStoryId,
  [actions.resetDetails]: resetDetails,
  [actions.reset]: reset,
  [authActions.logoutInit]: reset,
});
