/* Libraries */
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
/* -Libraries */

/* Selectors */
import * as breakpointSelectors from "redux/breakpoint/selectors";
/* -Selectors */

const selector = createSelector(
  [
    breakpointSelectors.getBreakpoint,
    breakpointSelectors.getIsHandheld,
    breakpointSelectors.getIsMobile,
    breakpointSelectors.getIsDesktop,
  ],
  (breakpoint, isHandheld, isMobile, isDesktop) => ({
    breakpoint,
    isHandheld,
    isMobile,
    isDesktop,
  })
);

const useBreakpoint = () => {
  return useSelector(selector);
};

export default useBreakpoint;
