/* Libraries */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { GoogleOAuthProvider } from "@react-oauth/google";
/* -Libraries */

/* Components */
import AuthMonitor from "components/AuthMonitor";
import BreakpointMonitor from "components/BreakpointMonitor";
import { CookieBanner, MaintenanceBanner } from "components/Banners";
import BundleIntroductionBanner from "components/Banners/BundleIntroductionBanner";
import MobileKeyboardMonitor from "components/MobileKeyboardMonitor";
import PageHeightMonitor from "components/PageHeightMonitor";
import PlatformMonitor from "components/PlatformMonitor";
import SiteLoader from "components/SiteLoader";
import SiteLoaderBackground from "components/SiteLoaderBackground";
/* -Components */

/* Selectors */
import * as authSelectors from "redux/auth/selectors";
import * as loaderSelectors from "redux/loaders/selectors";
/* -Selectors */

/* Actions */
import { setAppLoader } from "redux/loaders/actions";
/* -Actions */

/* Hooks */
import useAppSetup from "hooks/useAppSetup";
import useImportFonts from "hooks/useImportFonts";
import useOnInteraction from "hooks/useOnInteraction";
import usePreventTouchscreenHover from "hooks/usePreventTouchscreenHover";
/* -Hooks */

/* Context */
import { BroadcastContextProvider } from "context/BroadcastContext";
import { ContributionBuilderContextProvider } from "context/ContributionBuilderContext";
import { StripeContextProvider } from "context/StripeContext";
import { ThemeContextProvider } from "context/ThemeContext";
/* -Context */

import PageRoutes, { landingPages } from "routes/PageRoutes";
import ModalRoutes from "routes/ModalRoutes";
import HeaderRoutes from "routes/HeaderRoutes";

import webGLSupported from "utils/webGLSupported";
import { getBrowserDetails } from "libs/kindeo-play/utils/clientUtils";

const ErrorsModal = lazy(() => import("modals/ErrorsModal"));
const Meta = lazy(() => import("components/Meta"));

const selector = createSelector(
  [
    authSelectors.getAuthAccessToken,
    authSelectors.getAuthUsername,
    loaderSelectors.isLoading,
  ],
  (authAccessToken, authUsername, isLoading) => {
    return {
      authAccessToken,
      authUsername,
      isLoading,
    };
  }
);

const { IS_KINDEO_BOT } = getBrowserDetails();

const App = props => {
  const { authAccessToken, authUsername, isLoading } = useSelector(selector);

  const dispatch = useDispatch();
  const isLp = landingPages.includes(document.location.pathname);
  const appWasSetup = useAppSetup({ authAccessToken, authUsername });
  useImportFonts();
  usePreventTouchscreenHover();

  // initialise on site mount - don't mount the loader if starting on LP
  // show the loader for subsequent navigation, but not initial page load
  // IS_KINDEO_BOT - for send by post, we want to hide the loader so we can screenshot the page as quickly as possible
  const [mountLoader, mountLoaderSetter] = useState(!isLp && !IS_KINDEO_BOT);
  // if route is LP, allow the loader to mount once all page has loaded
  useEffect(() => {
    if (isLp && !isLoading) {
      mountLoaderSetter(true);
    }
  }, [isLp, isLoading]);

  // hide the main loader when the app is setup
  useEffect(() => {
    dispatch(setAppLoader(!appWasSetup));
  }, [appWasSetup, dispatch]);

  return (
    <>
      {/* <React.StrictMode> */}
      <SiteContent
        appWasSetup={appWasSetup}
        webGLSupported={webGLSupported}
        isLp={isLp}
        pageLoaded={mountLoader}
      />
      <BreakpointMonitor />
      <PageHeightMonitor />
      <MobileKeyboardMonitor />
      {mountLoader && <SiteLoader />}
      {/* </React.StrictMode> */}
    </>
  );
};

const SiteContent = React.memo(props => {
  const { appWasSetup, isLp, pageLoaded, webGLSupported } = props;
  const hasInteracted = useOnInteraction();

  if (appWasSetup || isLp) {
    return (
      <StripeContextProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <BroadcastContextProvider>
            <BrowserRouter>
              <ContributionBuilderContextProvider>
                <ThemeContextProvider>
                  <HeaderRoutes webGLSupported={webGLSupported} />
                  <PageRoutes webGLSupported={webGLSupported} />

                  <ModalRoutes webGLSupported={webGLSupported} />
                  <AuthMonitor />
                  <PlatformMonitor appWasSetup={appWasSetup} />
                </ThemeContextProvider>
              </ContributionBuilderContextProvider>

              <Suspense fallback={null}>
                {!isLp && (
                  <>
                    <Meta />
                    <ErrorsModal />
                  </>
                )}
              </Suspense>
              {pageLoaded && (
                <>
                  <SiteLoaderBackground />
                  {hasInteracted && (
                    <>
                      <CookieBanner />
                      <MaintenanceBanner />
                    </>
                  )}
                  <BundleIntroductionBanner />
                </>
              )}
            </BrowserRouter>
          </BroadcastContextProvider>
        </GoogleOAuthProvider>
      </StripeContextProvider>
    );
  }

  return null;
});

export default React.memo(App);
