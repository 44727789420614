/* Libraries */
import React, { useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Components */
/* -Components */

/* Selectors */
/* -Selectors */

/* Hooks */
import useBreakpoint from "hooks/useBreakpoint";
/* -Hooks */

/* Context */
import { ThemeContext } from "context/ThemeContext";
/* -Context */

/* Images */
import { ReactComponent as KindeoLogo } from "images/kindeo-logos/logo.svg";
/* -Images */

import { paths } from "routes/PageRoutes";
import { matchAnyPath } from "utils/url";

import styles from "./index.module.scss";

const Header = props => {
  const {
    className,
    isLoggedIn,
    isLp,
    isSubNav,
    logoReplacement,
    noBorder,
    showMobileItems,
  } = props;

  const { isMobile } = useBreakpoint();

  const displayedLogo = logoReplacement || <Logo {...{ isLoggedIn, isLp }} />;

  return (
    <header
      className={classnames(styles.header, className, {
        [styles.subNav]: isSubNav,
        [styles.noBorder]: noBorder,
      })}
    >
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          {(isMobile && !showMobileItems) || isSubNav ? (
            <MobileNavLayout
              {...props}
              logo={displayedLogo}
              subNav={isSubNav}
            />
          ) : (
            <DesktopNavLayout {...props} logo={displayedLogo} />
          )}
        </nav>
      </div>
    </header>
  );
};

const Logo = React.memo(props => {
  const { isWork } = useContext(ThemeContext);
  const { pathname } = useLocation();

  // the logo should link to the business LP if we're in the business section
  // but not if we're on the business LP itself - then it should link to the
  // public LP, as it should every other time
  const isWorkLp = matchAnyPath(paths.landingBusiness(), pathname);
  const link = isWork && !isWorkLp ? paths.landingBusiness() : paths.landing();

  return (
    <Link className={styles.navbarLogo} to={link} title="Home">
      <KindeoLogo
        alt="Kindeo"
        className={classnames(styles.logo, { [styles.work]: isWork })}
      />
    </Link>
  );
});

const MobileNavLayout = React.memo(props => {
  const { isLp, leftNav, logo, rightNav, subNav, title } = props;

  if (isLp) {
    return (
      <>
        <div className="navbar-item">{logo}</div>
        <div className="navbar-menu">
          <div className="navbar-end">{rightNav || null}</div>
        </div>
      </>
    );
  }

  // Header layout for mobile:
  // Header shows logo on the left unless there is
  // leftNav content, in which case the logo appears in the center,
  // unless there is title content in which case no logo is shown
  return (
    <>
      <div className="navbar-menu">
        <div className="navbar-start">
          {leftNav || !subNav ? (
            <div className="navbar-item">{leftNav || logo}</div>
          ) : null}
        </div>
      </div>
      {title ? (
        <h2 className={classnames("navbar-item h5", styles.titleHeader)}>
          {title}
        </h2>
      ) : leftNav ? (
        <div className="navbar-item">{logo}</div>
      ) : null}
      <div className="navbar-menu">
        <div className="navbar-end">{rightNav || null}</div>
      </div>
    </>
  );
});

const DesktopNavLayout = React.memo(props => {
  const { centerLogo, leftNav, logo, rightNav, title } = props;

  return (
    <>
      {
        // if center logo, add an empty flexing navbar-menu element first
        centerLogo ? (
          <>
            <div className="navbar-menu" />
            <div className="navbar-item">{logo}</div>
          </>
        ) : // if title in the middle, logo is in a flexing navbar menu element
        // to balance the right hand buttons and center the title
        title ? (
          <div className="navbar-menu">
            <div className="navbar-item">{logo}</div>
          </div>
        ) : (
          // if no title the logo is on its own and
          // allows the right hand buttons to expand - e.g. landing page
          <div className="navbar-item">{logo}</div>
        )
      }
      {title ? (
        <div className={classnames(styles.desktopTitle, "navbar-menu")}>
          <div className={"h6 navbar-item"}>{title}</div>
        </div>
      ) : null}
      <div className={"navbar-menu"}>
        <div className="navbar-start">{leftNav || null}</div>
        <div className="navbar-end">{rightNav || null}</div>
      </div>
    </>
  );
});

export default React.memo(Header);
