/* Libraries */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as editorActions from "redux/editor/actions";
import * as mediaActions from "redux/media/actions";
/* -Actions */

/* Selectors */
import * as mediaSelectors from "redux/media/selectors";
/* -Selectors */

/* Hooks */
import useUpload from "./useUpload";
import usePollMedia from "./usePollMedia";
import usePrevious from "./usePrevious";
/* -Hooks */

import analytics, { EVENTS } from "utils/analyticsUtils";
import { roundTo2dp } from "utils/numberUtils";
import { capitaliseFirstLetter } from "utils/stringUtils";

const useProvideTranscodeProgressSelector = id => {
  return useMemo(() => {
    return createSelector(mediaSelectors.getLibrary, library => {
      const media = mediaSelectors.getLibraryMediaById(library, id);
      return {
        transcodeProgress: mediaSelectors.getProgress(media),
      };
    });
  }, [id]);
};

const useProvideMediaLibrary = mediaType => {
  return useMemo(() => {
    return createSelector(mediaSelectors.getLibrary, fullMediaLibrary => {
      const mediaLibrary = mediaSelectors.getLibraryItemsByMediaType(
        fullMediaLibrary,
        mediaType
      );
      return {
        mediaLibrary,
      };
    });
  }, [mediaType]);
};

const useLibraryUpload = storyId => {
  const {
    cancelUpload,
    mediaType,
    setMediaType,
    setUploadError,
    uploadCredentials,
    uploadError,
    uploadFiles,
    uploadsInProgress,
    uploadProgress,
  } = useUpload();
  const dispatch = useDispatch();

  const needsProcessing = [
    mediaSelectors.mediaTypes.AUDIO,
    mediaSelectors.mediaTypes.VIDEO,
  ].includes(mediaType);
  const [validation, setValidation] = useState({});
  const [processing, setProcessing] = useState(false);
  const [mediaId, setMediaId] = useState();
  const [libraryMediaType, setLibraryMediaType] = useState();
  const { transcodeProgress } = useSelector(
    useProvideTranscodeProgressSelector(mediaId)
  );
  const { mediaLibrary } = useSelector(
    useProvideMediaLibrary(libraryMediaType)
  );

  const processingPrev = usePrevious(processing);
  const [processingStartTime, setProcessingStartTime] = useState();
  useEffect(() => {
    if (mediaId && processing && !processingPrev) {
      setProcessingStartTime(Date.now());
      analytics.event(EVENTS.mediaProcessing.started, { media_id: mediaId });
    }
  }, [mediaId, processing, processingPrev]);
  useEffect(() => {
    if (mediaId && processing && transcodeProgress >= 100) {
      analytics.event(EVENTS.mediaProcessing.success, {
        media_id: mediaId,
        processing_time_seconds: roundTo2dp(
          (Date.now() - processingStartTime) / 1000
        ),
      });
      setProcessing(false);
    }
  }, [mediaId, processing, processingStartTime, transcodeProgress]);

  const makeEditorMediaAvailable = useCallback(
    media => {
      dispatch(editorActions.updateEditorMedia(media));
    },
    [dispatch]
  );

  usePollMedia(mediaLibrary, makeEditorMediaAvailable, libraryMediaType);

  const onAfterUploadSuccess = useCallback(
    file => {
      return dispatch(
        mediaActions.createMedia({
          ...file,
          storyId,
        })
      ).then(media => {
        if (media) {
          if (needsProcessing) {
            setProcessing(true);
          }
          setMediaId(mediaSelectors.getId(media));
        }
        return media;
      });
    },
    [dispatch, needsProcessing, storyId]
  );

  const uploadLibraryMedia = useCallback(
    files => {
      return uploadFiles(files, onAfterUploadSuccess).then(
        uploadedLibraryMedia => {
          if (uploadedLibraryMedia.length > 0) {
            return uploadedLibraryMedia;
          }
          throw new Error("The file failed to upload");
        }
      );
    },
    [onAfterUploadSuccess, uploadFiles]
  );

  const setUploadMediaType = useCallback(
    type => {
      setMediaType(type);
      setLibraryMediaType(type);
    },
    [setMediaType]
  );

  const logCaptureSelected = useCallback(() => {
    analytics.event(
      `${capitaliseFirstLetter(mediaType)} ${EVENTS.mediaUpload.recordSelected}`
    );
  }, [mediaType]);
  const logUploadSelected = useCallback(() => {
    analytics.event(
      `${capitaliseFirstLetter(mediaType)} ${EVENTS.mediaUpload.uploadSelected}`
    );
  }, [mediaType]);

  return {
    cancelUpload,
    logCaptureSelected,
    logUploadSelected,
    processing,
    setUploadError,
    setUploadMediaType,
    setUploadValidation: setValidation,
    uploadCredentials,
    uploadError,
    uploadLibraryMedia,
    uploadMediaType: mediaType,
    uploadProgress,
    uploadsInProgress,
    uploadValidation: validation,
  };
};

export default useLibraryUpload;
