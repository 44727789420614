export const getAddresses = state => state.addresses;

export const getAddress = (state, addressId) => {
  const addresses = getAddress(state);
  return addresses.find(address => getId(address) === addressId);
};

export const getId = address => address?.id;
export const getFirstName = address => address?.first_name || "";
export const getLastName = address => address?.last_name || "";
export const getFullName = address => {
  const firstName = getFirstName(address);
  const lastName = getLastName(address);
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else {
    return firstName || lastName;
  }
};
export const getLine1 = address => address?.address_line_1 || "";
export const getLine2 = address => address?.address_line_2 || "";
export const getCity = address => address?.city || "";
export const getPostalCode = address => address?.postcode || "";
export const getCountry = address => address?.country || "";
export const getSaved = address => !!address?.saved;

export const getAsString = (address, separator = ", ") => {
  return [
    getLine1(address),
    getLine2(address),
    getCity(address),
    getPostalCode(address),
    // getCountry(address),
  ]
    .filter(item => !!item)
    .join(separator);
};

export const getAddressById = (addresses, id) => {
  return addresses.find(address => getId(address) === id);
};

export const searchAddresses = (addresses, searchTerm) => {
  const separator = "±±";
  if (searchTerm.trim()) {
    return addresses.filter(address => {
      return (
        (getFullName(address) + separator + getAsString(address, separator))
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) !== -1
      );
    });
  }

  return addresses;
};
