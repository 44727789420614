/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
import * as editorActions from "redux/editor/actions";
/* -Actions */

const INITIAL_STATE = {
  music: {
    selected: null,
    results: [],
  },
};

const setSelected = (state, { payload }) => ({
  ...state,
  selected: payload,
});

const setSearchResults = (state, { payload }) => ({
  ...state,
  results: payload,
});

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.fetchSpotifyTrackSuccess]: setSelected,
  [actions.searchSpotifySuccess]: setSearchResults,
  [editorActions.resetEditor]: reset,
  [authActions.logoutInit]: reset,
});
