/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {};

const setPersistedData = (state, { payload }) => {
  const { id, value } = payload;
  return { ...state, [id]: value };
};

const unsetPersistedData = (state, { payload }) => {
  const { id } = payload;
  const { [id]: discard, ...retained } = state;
  return retained;
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.set]: setPersistedData,
  [actions.unset]: unsetPersistedData,
  [actions.reset]: reset,
  [authActions.logoutInit]: reset,
});
