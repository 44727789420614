/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {
  library: [],
  limits: {},
  uploading: false,
};

const setLibrary = (state, { payload }) => ({ ...state, library: payload });

const setLimits = (state, { payload }) => ({ ...state, limits: payload });

const addToLibrary = (state, { payload }) => {
  const library = [...state.library];
  const index = library.findIndex(media => payload.id === media.id);

  // If the media already exists reload it
  if (index !== -1) {
    library[index] = payload;
  } else {
    // Otherwise insert at the top of the library
    library.unshift(payload);
  }

  return {
    ...state,
    library,
  };
};

const removeFromLibrary = (state, { payload }) => {
  return {
    ...state,
    library: state.library.filter(item => item.id !== payload),
  };
};

const setMediaUploading = (state, { payload: uploading }) => ({
  ...state,
  uploading,
});

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.mediaFetchLibrarySuccess]: setLibrary,
  [actions.mediaFetchLimitsSuccess]: setLimits,
  [actions.mediaFetchSuccess]: addToLibrary,
  [actions.mediaDismiss]: removeFromLibrary,
  [actions.mediaCreateSuccess]: addToLibrary,
  [actions.setMediaUploading]: setMediaUploading,
  [actions.resetLibrary]: reset,
  [authActions.logoutInit]: reset,
});
