/* Libraries */
import React, { useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
import { PROMPTS_IDS } from "redux/prompts/selectors";
/* -Selectors */

/* Components */
import { CoreButton } from "components/_v2/Button";
import Icon from "components/Icon";
import IconCloseButton from "components/IconCloseButton";
/* -Components */

/* Hooks */
import usePrompts from "hooks/usePrompts";
/* -Hooks */

import { landingPages, paths } from "routes/PageRoutes";
import { matchAnyPath } from "utils/url";

import styles from "./index.module.scss";

const displayVariants = {
  begin: { y: "-100%", opacity: 0 },
  display: {
    y: "0%",
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.4 },
  },
  gone: {
    y: "-100%",
    opacity: 0,
    transition: { ease: "easeIn", duration: 0.4 },
  },
};

const BundleIntroductionBanner = props => {
  const { pathname } = useLocation();

  const [showPrompt, completePrompt] = usePrompts(
    PROMPTS_IDS.PRICING_BUNDLE_NOTICE,
    {
      testFn: () => {
        return !!matchAnyPath(
          pathname,
          [...landingPages, paths.edit(":id"), paths.home()],
          true
        );
      },
      props: [pathname],
    }
  );

  const onAccept = useCallback(() => {
    completePrompt();
  }, [completePrompt]);

  return (
    <AnimatePresence initial={true}>
      {showPrompt && (
        <motion.div
          className={classnames(styles.banner)}
          variants={displayVariants}
          initial={"begin"}
          animate={"display"}
          exit={"gone"}
        >
          <div className={classnames("container", styles.content)}>
            <Icon icon="info-circle" className={styles.icon} />
            <div className={classnames(styles.copy)}>
              <h1 className="h6">Introducing Bundle Pricing</h1>
              <p className="body-small mb-025">
                Save time at checkout with Kindeo bundles.
              </p>
              <CoreButton
                design="text"
                className={styles.link}
                to={paths.landingPricingBundles()}
                onClick={onAccept}
                target="pricing"
              >
                Learn more
              </CoreButton>
            </div>
            <IconCloseButton
              className={styles.dismissButton}
              onClick={onAccept}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(BundleIntroductionBanner);
