// leaves the trails as they are because themes use array indexes... we're stuck!!!
export const themes = [
  {
    name: "trail-0",
    alpha: 0.9456521739130435,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 16777215,
  },
  {
    name: "trail-1",
    alpha: 0.93,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 16764416,
  },
  {
    name: "trail-2",
    alpha: 0.93,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 16711890,
  },
  {
    name: "trail-3",
    alpha: 0.93,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 655615,
  },
  {
    name: "trail-4",
    alpha: 1,
    colorText: "#fffafa",
    color: "0xeb78a4",
    bgColor: 16747660,
  },
  {
    name: "trail-5",
    alpha: 0.9456521739130435,
    colorText: "#000000",
    color: "0xf5f3f4",
    bgColor: 9220607,
  },
  {
    name: "trail-6",
    alpha: 0.93,
    colorText: "#ff6c6e",
    color: "0x041746",
    bgColor: 4591199,
  },
  {
    name: "trail-7",
    alpha: 0.93,
    colorText: "#000000",
    color: "0xffffff",
    bgColor: 16739796,
  },
];

// leaves the trails as they are because themes use array indexes... we're stuck!!!
export const themesPhoto = [
  {
    name: "trail-0",
    alpha: 0.9456521739130435,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 16777215,
  },
  {
    name: "trail-1",
    alpha: 0.93,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 16764416,
  },
  {
    name: "trail-2",
    alpha: 0.93,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 16711890,
  },
  {
    name: "trail-3",
    alpha: 0.93,
    colorText: "#ffffff",
    color: "0x000000",
    bgColor: 655615,
  },
  {
    name: "trail-4",
    alpha: 1,
    colorText: "#fffafa",
    color: "0xeb78a4",
    bgColor: 16747660,
  },
  {
    name: "trail-5",
    alpha: 0.9456521739130435,
    colorText: "#000000",
    color: "0xf5f3f4",
    bgColor: 9220607,
  },
  {
    name: "trail-6",
    alpha: 0.93,
    colorText: "#ff6c6e",
    color: "0x041746",
    bgColor: 4591199,
  },
  {
    name: "trail-7",
    alpha: 0.93,
    colorText: "#000000",
    color: "0xffffff",
    bgColor: 16739796,
  },
];
