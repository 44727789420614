/* Libraries */
import { CoreButton } from "components/_v2/Button";
import React from "react";
/* -Libraries */

const EMAIL_ADDRESS = "support@kindeo.com";

const SupportLink = props => {
  const { children, ...otherProps } = props;
  return (
    <CoreButton
      design="text"
      href={`mailto:${EMAIL_ADDRESS}`}
      title={`Email ${EMAIL_ADDRESS}`}
      {...otherProps}
    >
      {children || EMAIL_ADDRESS}
    </CoreButton>
  );
};

export default React.memo(SupportLink);
