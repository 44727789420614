import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";

import thumbnail from "images/slides/thumbnails/date.png";

const type = slideTypes.DATE;
const dateVariants = slideVariants[type];

export default {
  type,
  icon: "date-fill",
  label: "Date",
  descriptionBuilder: "Add a date to mark the occasion",
  colour: "magenta5",
  thumbnail,
  variants: [
    {
      name: dateVariants.CLOCK,
      label: "Default",
    },
    {
      name: dateVariants.MODERN,
      label: "Modern",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Message",
      placeholder: "e.g. Born",
      validation: {
        maxLength: {
          value: 40,
          message: `Please keep your message to 40 characters or less`,
        },
      },
    },
    {
      key: "date-picker",
      type: inputTypes.DATE_PICKER,
      label: "Date",
    },
  ],
};
