/* Libraries */
import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import isEmail from "validator/es/lib/isEmail";
import classnames from "classnames";
import { useFormContext } from "react-hook-form";
import { isMobileDevice } from "kindeo-webgl/utils";
/* -Libraries */

/* Hooks */
/* -Hooks */

/* Components */
import FormFieldEmoji from "components/FormFieldEmoji";
import Icon from "components/Icon";
/* -Components */

import { noop } from "utils/clientUtils";

const eventDefaults = {
  onBlur: noop,
  onChange: noop,
  ref: noop,
};

const FormField = React.memo(props => {
  const {
    children,
    componentName,
    inputRef,
    name,
    onBlur: onBlurCallback = noop,
    onChange: onChangeCallback = noop,
    validation = {},
    preventNewLine,
    withEmoji,
    ...rest
  } = props;

  const localInputRef = useRef();

  const formTools = useFormContext();
  // get hook form tools for this input by registering it
  const hookFormProps = useMemo(() => {
    // merge hook form tools with any props passed in
    return formTools?.register(name, validation) || eventDefaults;
  }, [formTools, name, validation]);

  const onBlur = useCallback(
    e => {
      hookFormProps.onBlur(e);
      onBlurCallback(e);
    },
    [hookFormProps, onBlurCallback]
  );
  const onChange = useCallback(
    e => {
      hookFormProps.onChange(e);
      onChangeCallback(e);
    },
    [hookFormProps, onChangeCallback]
  );
  const ref = useCallback(
    e => {
      localInputRef.current = e;
      hookFormProps.ref(e);
      if (inputRef) {
        inputRef.current = e;
      }
    },
    [hookFormProps, inputRef]
  );

  const element = React.createElement(componentName, {
    ...rest,
    children,
    name,
    onBlur,
    onChange,
    ref,
  });

  if (withEmoji && !isMobileDevice) {
    return (
      <FormFieldEmoji name={name} ref={localInputRef} onChange={onChange}>
        {element}
      </FormFieldEmoji>
    );
  }

  if (name) {
    return element;
  }

  console.error("Form fields require a name");
  return null;
});

FormField.propTypes = {
  children: PropTypes.node,
  componentName: PropTypes.string.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.object }),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const Input = React.memo(props => {
  // componentName may be overwritten
  return <FormField componentName="input" {...props} />;
});

const Text = React.memo(props => {
  const { className, ...restProps } = props;

  return (
    <Input
      type="text"
      {...restProps}
      className={classnames("input", className)}
    />
  );
});

const Number = React.memo(props => {
  const { className, ...restProps } = props;

  return (
    <Input
      type="number"
      {...restProps}
      className={classnames("input", className)}
    />
  );
});

const Email = React.memo(props => {
  const { validation, ...restProps } = props;

  return (
    <Text
      autoComplete="email"
      autoCapitalize="off"
      spellCheck="false"
      {...restProps}
      type="email"
      validation={{
        ...validation,
        // prepend a validity check for email format
        validate: value => {
          if (validation?.required === false && !value) {
            return () => true;
          }

          const isValidEmail = isEmail(value);
          if (!isValidEmail) {
            return "Your email doesn’t look right";
          }
          if (validation && typeof validation.validate === "function") {
            return validation.validate(value);
          }
          return () => true;
        },
      }}
    />
  );
});

const Password = React.memo(props => {
  const { show, ...restProps } = props;

  return (
    <Text
      autoComplete="password"
      autoCapitalize="off"
      {...restProps}
      type={!show ? "password" : "text"}
    />
  );
});

const Checkbox = React.memo(props => {
  const { className, labelClassName, noLabel, ...restProps } = props;

  return (
    <>
      <Input
        {...restProps}
        className={classnames("checkbox", className)}
        type="checkbox"
      />
      {!noLabel && (
        <label htmlFor={restProps.id} className={labelClassName}>
          <span className="checkmark">
            <Icon icon="check-fill" size="16" />
          </span>
        </label>
      )}
    </>
  );
});

const Radio = React.memo(props => {
  const { className, theme, ...restProps } = props;

  return (
    <>
      <Input {...restProps} className={"radio"} type="radio" />
      <label
        className={classnames(className, theme ? `has-border-${theme}` : "")}
        htmlFor={restProps.id}
      >
        <span
          className={classnames(
            "checkmark",
            theme ? `has-background-${theme}` : ""
          )}
        ></span>
      </label>
    </>
  );
});

const Textarea = React.memo(props => {
  const {
    className,
    maxLength,
    onKeyDown = noop,
    preventNewLine,
    ...restProps
  } = props;

  const onKeyDownRef = useRef(onKeyDown);

  // ensure newlines overflow the maxLength
  const handleKeyDown = useCallback(
    e => {
      if (preventNewLine) {
        if (e.keyCode === 13) {
          e.preventDefault();
          return false;
        }

        onKeyDownRef.current(e);
      } else {
        const el = e.target;
        const nbCharSelected = el.selectionEnd - el.selectionStart;
        const hasControlKey = e.ctrlKeye || e.metaKey;
        const produceNewChar = String.fromCharCode(e.keyCode).match(/(\w|\s)/g);
        if (
          !hasControlKey &&
          produceNewChar &&
          e.target.value.length - nbCharSelected >= maxLength
        ) {
          e.preventDefault();
          return false;
        }

        onKeyDownRef.current(e);
      }
    },
    [maxLength, preventNewLine]
  );

  const handlePaste = useCallback(
    e => {
      if (!preventNewLine) {
        const el = e.target;
        el.focus();
        const nbCharSelected = el.selectionEnd - el.selectionStart;

        let paste = (e.clipboardData || window.clipboardData).getData("text");

        const totalLength = el.value.length + paste.length;
        const diff = totalLength - maxLength - nbCharSelected;

        if (diff >= paste.length) {
          e.preventDefault();
          return;
        }

        if (diff > 0) {
          paste = paste.slice(0, paste.length - diff);
        }

        if (!document.execCommand("insertText", false, paste)) {
          el.setRangeText(paste, el.selectionStart, el.selectionEnd, "end");
        }

        e.preventDefault();
      }
    },
    [maxLength, preventNewLine]
  );

  return (
    <FormField
      {...restProps}
      className={classnames("textarea", className)}
      componentName="textarea"
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      preventNewLine={preventNewLine}
      maxLength={maxLength}
    />
  );
});

const Select = React.memo(props => {
  const { children, className, inputClassName, ...restProps } = props;

  return (
    <div className={classnames("bulma-select", className)}>
      <FormField
        {...restProps}
        className={classnames("select", inputClassName)}
        componentName="select"
      >
        {React.Children.map(children, child => {
          if (child.type === "option") {
            return (
              <option value={child.props.value}>{child.props.children}</option>
            );
          }
        })}
      </FormField>
    </div>
  );
});

const DateInput = React.memo(props => {
  const { className, ...restProps } = props;

  return (
    <Input
      {...restProps}
      className={classnames("date", className)}
      type="date"
    />
  );
});

export {
  Checkbox,
  DateInput,
  Email,
  Number,
  Password,
  Radio,
  Select,
  Text,
  Textarea,
};
