/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import * as selectors from "./selectors";
/* -Selectors */

export const actions = {
  fetchGiphyImageSuccess: createAction("FETCH_GIPHY_TRACK_SUCCESS"),
  searchGiphySuccess: createAction("SEARCH_GIPHY_SUCCESS"),
  setGiphySearchTerm: createAction("SET_GIPHY_SEARCHTERM"),
  resetGiphySearch: createAction("RESET_GIPHY_RESULTS"),
};

export const fetchGiphyImage = giphyImageId => dispatch => {
  return axios
    .get(`/search/giphy_id?giphy_id=${giphyImageId}`)
    .then(response => {
      const { giphy } = response.data;
      dispatch(actions.fetchGiphyImageSuccess(giphy));
      return giphy;
    });
};

export const setGiphySearchTerm = searchTerm => dispatch => {
  dispatch(actions.setGiphySearchTerm(searchTerm));
};

export const searchGiphy = (searchTerm, more) => (dispatch, getState) => {
  if (searchTerm) {
    const state = getState();
    const currentPage = selectors.getSearchResultPages(state);
    const resultsPage = more ? currentPage + 1 : currentPage;
    const currentIds = selectors.getSearchResultIds(state);

    return axios
      .get(
        `/search/giphy?term=${searchTerm}&page=${
          more ? currentPage + 1 : currentPage
        }`
      )
      .then(response => {
        const { results } = response.data;
        const uniqueResults = results.filter(
          result => !currentIds.includes(selectors.getId(result))
        );
        dispatch(
          actions.searchGiphySuccess({
            results: uniqueResults,
            pages: resultsPage,
          })
        );
        return results;
      });
  }

  return Promise.resolve([]);
};

export const resetSearchResults = () => dispatch => {
  dispatch(actions.resetGiphySearch());
};
