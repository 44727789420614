/* Libraries */
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import { CoreButton } from "components/_v2/Button";
import { Email } from "components/FormField";
import Error from "components/FormFieldError";
import FormSubmissionError from "components/FormSubmissionError";
/* -Components */

/* Hooks */
import useIsMounted from "hooks/useIsMounted";
/* -Hooks */

/* Actions */
import { checkAccountAvailable, requestLoginLink } from "redux/auth/actions";
import { setAnonymousEmail } from "redux/anonymousData/actions";
/* -Actions */

/* Selectors */
import { getEmail } from "redux/anonymousData/selectors";
/* -Selectors */

import { noop } from "utils/clientUtils";
import { getErrorStatus } from "utils/apiUtils";
import { EVENTS } from "utils/analyticsUtils";

import styles from "./index.module.scss";

const selector = createSelector(getEmail, email => {
  return { anonymousEmail: email || "" };
});

const AuthEmailForm = props => {
  const { autoFocus, children, className, inputClassName, staticEmail } = props;

  const { anonymousEmail } = useSelector(selector);

  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const formTools = useForm({
    defaultValues: { email: anonymousEmail },
  });
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = formTools;

  const [emailValue] = watch(["email"]);
  useEffect(() => {
    dispatch(setAnonymousEmail(emailValue));
  }, [dispatch, emailValue]);

  const [formError, formErrorSetter] = useState(false);

  // reset generic form error message if there is a specific field error
  useEffect(() => {
    if (!isValid) {
      formErrorSetter(false);
    }
  }, [isValid]);

  const inputRef = useRef();
  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, [autoFocus]);

  const onSubmit = async formData => {
    formErrorSetter(false);
    const {
      onError = noop,
      onRecognised,
      onUnrecognised,
      onLocked = noop,
    } = props;

    if (staticEmail) {
      formData.email = anonymousEmail;
    }

    try {
      const noAccount = await dispatch(checkAccountAvailable(formData.email));
      if (noAccount) {
        onUnrecognised();
      } else {
        const formResult = await dispatch(requestLoginLink());
        if (isMounted()) {
          onRecognised(formResult);
        }
      }
    } catch (error) {
      onError(error);
      const errorStatus = getErrorStatus(error);

      // If account is locked
      if (errorStatus === 423) {
        onLocked();
      }

      // Otherwise something went wrong with login
      formErrorSetter(true);
    }
  };

  return (
    <FormProvider {...formTools}>
      <form className={className} onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={classnames("field", { "mv-2": staticEmail })}>
          <div className="control">
            {staticEmail ? (
              <b>{anonymousEmail}</b>
            ) : (
              <Email
                className={classnames(inputClassName, styles.emailInput)}
                placeholder="Enter email address"
                name="email"
                id="login-email"
                inputRef={inputRef}
                maxLength={250}
              />
            )}
            <Error errors={errors} fieldName="email" />
          </div>
        </div>

        <FormSubmissionError
          show={formError}
          action={EVENTS.errors.authEmail}
        />

        {children}

        <div className={classnames("mt-3", styles.buttons)}>
          <CoreButton
            design="primary"
            type="submit"
            loading={isSubmitting}
            disabled={!emailValue}
          >
            {staticEmail ? "Sign In" : "Continue"}
          </CoreButton>
        </div>
      </form>
    </FormProvider>
  );
};

AuthEmailForm.propTypes = {
  autoFocus: PropTypes.bool,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  staticEmail: PropTypes.bool,
  labelClassName: PropTypes.string,
  onError: PropTypes.func,
  onRegisterFallback: PropTypes.func,
  onRecognised: PropTypes.func,
  onUnrecognised: PropTypes.func,
};

export default AuthEmailForm;
