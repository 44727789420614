import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./index.module.scss";

/** Renderers an element with a height as a percentage of it's width. */
const Ratio = props => {
  const { animate, centered, children, className, ratio = "75%" } = props;
  const element = useRef();

  useLayoutEffect(() => {
    element.current.style.setProperty("--ratio", ratio);
  }, [element, ratio]);

  return (
    <div
      ref={element}
      className={classnames(styles.element, className, {
        [styles.animate]: animate,
      })}
    >
      <div className={centered ? styles.centeredContent : ""}>{children}</div>
    </div>
  );
};

Ratio.propTypes = {
  centered: PropTypes.bool,
  ratio: PropTypes.string,
};

export default React.memo(Ratio);
