/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
import { actions as storyActions } from "redux/story/actions";
/* -Actions */

const INITIAL_STATE = {};

const setUpdateStatus = (state, { payload }) => payload;

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [storyActions.clearStory]: reset,
  [actions.updateStatusFetchSuccess]: setUpdateStatus,
  [authActions.logoutInit]: reset
});
