import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import thumbnail from "images/slides/thumbnails/music.png";

const type = slideTypes.MUSIC;
const musicVariants = slideVariants[type];

export default {
  type,
  icon: "song-fill",
  label: "Song",
  descriptionBuilder: "Set the mood with a song snippet",
  colour: "orange5",
  thumbnail,
  variants: [
    {
      name: musicVariants.MODERN,
      label: "Modern",
    },
    {
      name: musicVariants.RETRO,
      label: "Retro",
    },
  ],
  fields: [
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Message",
      placeholder: "e.g. Your favourite song",
      validation: {
        maxLength: {
          value: 40,
          message: `Please keep your message to 40 characters or less`,
        },
      },
    },

    {
      key: "subtitle",
      type: inputTypes.INPUT,
      label: "From",
      placeholder: "e.g. From your best friend",
      validation: {
        maxLength: {
          value: 30,
          message: `Please keep this to 30 characters or less`,
        },
      },
    },
    {
      key: "music",
      type: inputTypes.MUSIC,
      label: "Music",
      icon: "slide-music",
      validation: {
        minLength: {
          value: 1,
          message: "Please add a song",
        },
      },
    },
  ],
};
