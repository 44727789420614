import { getExportedId } from "redux/slide/selectors";

export const getEditor = state => state.editor;

export const getSlide = editor => editor.slide;
export const getStory = editor => editor.story;

export const isNewSlide = editor => !getSlide(editor).id;

export const isEditorEmpty = editor => !getSlide(editor).type;

export const slideVariant = editor => getSlide(editor).variant;

export const isEditorSlideshowLoaded = editor => {
  return editor.slideshowLoaded;
};
export const isEditorBeingSubmitted = editor => editor.beingSubmitted;
export const getInitialInputName = editor => editor?.initialInputName;

export const getAuthModalOpen = state => getEditor(state)?.authModalOpen;

export const hasBeenEdited = state => getEditor(state)?.hasBeenEdited;
export const isEditingNewKindeo = state => getEditor(state)?.editingNewKindeo;
export const isEditingCover = state => getEditor(state)?.editingCover;
// export const getPreEditValues = state => getEditor(state).preEditValues;
export const getEditingFieldName = state => getEditor(state)?.editingFieldName;
export const getMobileKeyboardOpen = state =>
  getEditor(state)?.mobileKeyboardOpen;

// the editor contains a slide_data_object, not slide_data directly.
// This is an object keyed by a cover's exported_id
// and saves the slide_data content arrays for all recent cover slides
export const getSlideData = (slide, exportedId) =>
  slide?.slide_data_object?.[exportedId || getExportedId(slide)] || [];
export const getSlideDataByKey = (slide, key) =>
  getSlideData(slide).find(item => item.key === key)?.value;

export const getSlideDataObject = slide => slide?.slide_data_object || {};

// because the editor has to maintain several slideDatas in slide_data_object
// in case the exported cover and its contents are edited,
// slide_data_object is keyed by exported_id.
// The API needs a single slide_data array, so we need to remove
// the slide_data_object and replace it with the single slide_data array
// that corresponds to the current exported_id
export const transformSlideDataForApi = editor => {
  // remove slide_data_object from valid slide data
  let { slide_data_object, ...slide } = editor;

  // if there's a slide_data_object that needs to be replaced
  if (slide_data_object) {
    // extract the relevant slide_data from the slide_data_object
    const exportedId = getExportedId(editor);
    const slideData = getSlideData(editor, exportedId);

    // for each property in the slide_data,
    // make sure it hasn't been added to the primary slide object as a property
    slideData.forEach(item => {
      delete slide[item.key];
    });

    // return the slide object with the exported_id set
    // and the slide_data key/value property array
    return {
      ...slide,
      exported_id: exportedId || null,
      slide_data: slideData,
    };
  }

  // if there's no slide_data_object, just return the slide
  return slide;
};

export const createInputId = key => `input-${key}`;
