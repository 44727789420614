/* Libraries */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
/* -Libraries */

/* Selectors */
import { LANDING_PAGES } from "redux/landingPage/selectors";
import { OCCASION_TYPES } from "redux/occasions/selectors";
import { anonymousStoryId, storyTokenTypes } from "redux/story/selectors";
/* -Selectors */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useLoaderLocation from "hooks/useLoaderLocation";
import useModals from "hooks/useModals";
import usePageLoader from "hooks/usePageLoader";
import usePrevious from "hooks/usePrevious";
import useQueryParams from "hooks/useQueryParams";
/* -Hooks */

/* Components */
import DynamicRouteRedirect from "pages/DynamicRouteRedirect";
import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
/* -Components */

import { modals } from "routes/ModalRoutes";
import { getQueryParam } from "utils/queryParamUtils";

/* Pages */
const Account = lazy(() => import("pages/Account"));
const ApproveAccess = lazy(() => import("pages/ApproveAccess"));
const AuthenticateByLink = lazy(() => import("pages/AuthenticateByLink"));
const Cards = lazy(() => import("pages/Cards"));
const CreateCard = lazy(() => import("pages/CreateCard"));
const Edit = lazy(() => import("pages/Edit"));
const ErrorNotFound = lazy(() => import("pages/ErrorNotFound"));
const ForwardTo = lazy(() => import("pages/ForwardTo"));
const Group = lazy(() => import("pages/Group"));
const LandingHowItWorks = lazy(() => import("pages/LandingHowItWorks"));
const LandingPricing = lazy(() => import("pages/LandingPricing"));
const LandingBusinessPricing = lazy(
  () => import("pages/LandingBusinessPricing")
);
// const LandingWhyKindeo = lazy(() => import("pages/LandingWhyKindeo"));
const Landing = lazy(() => import("pages/Landing"));
const LegacySharingRedirect = lazy(() => import("pages/LegacySharingRedirect"));
const Recipient = lazy(() => import("pages/Recipient"));
const Reminders = lazy(() => import("pages/Reminders"));
const Review = lazy(() => import("pages/Review"));
const Shop = lazy(() => import("pages/Shop"));
const ThumbnailGenerator = lazy(() => import("pages/ThumbnailGenerator"));
const UnsupportedBrowser = lazy(() => import("pages/UnsupportedBrowser"));
/* -Pages */

export const paths = {
  // account details
  account: () => "/account",
  accountAddresses: () => `${paths.account()}/addresses`,
  accountAddressDetails: id => `${paths.accountAddresses()}/${id}`,
  accountBundles: () => `${paths.account()}/bundles`,
  accountPayments: () => `${paths.account()}/payments`,
  accountPaymentDetails: id => `${paths.account()}/payments/${id}`,
  accountProfile: () => `${paths.account()}/profile`,

  authenticateByLink: () => "/auth",
  approveRequest: roleId => `/requests/${roleId}`,
  // create flow
  createCover: () => "/create",
  createGreeting: () => "/create/greeting",
  // editor flow
  edit: id => `/edit/${id}`,
  editCover: id => `${paths.edit(id)}/cover`,
  editGreeting: id => `${paths.edit(id)}/greeting`,
  editGroup: id => `${paths.edit(id)}/group`,
  editGroupSplash: id => `${paths.editGroup(id)}/splash`,
  editGroupSetupRecipient: id => `${paths.editGroup(id)}/set-recipient`,
  editSendUpdateRecipient: id => `${paths.editGroup(id)}/update-recipient`,
  editGroupSetupInvitation: id => `${paths.editGroup(id)}/set-invitation`,
  editGroupInvite: id => `${paths.editGroup(id)}/invite`,
  editGroupInvitation: id => `${paths.editGroup(id)}/invitation`,
  editSend: id => `${paths.edit(id)}/send`,
  editSendByPost: id => `${paths.editSend(id)}/post`,
  editSendByEmail: id => `${paths.editSend(id)}/email`,
  editSendByLink: id => `${paths.editSend(id)}/link`,
  editSendSetupRecipient: id => `${paths.editSend(id)}/set-recipient`,
  editSendByPostAdd: id => `${paths.editSendByPost(id)}/add`,
  editSendByPostAddress: (id, addressId) =>
    `${paths.editSendByPost(id)}/address/${addressId}`,
  editSendByPostAddressNew: id => `${paths.editSendByPostAddress(id, "new")}`,
  editSendByPostEdit: (id, orderId) => `${paths.editSendByPost(id)}/${orderId}`,
  editSendByPostPayment: (id, orderId) =>
    `${paths.editSendByPostEdit(id, orderId)}/payment`,
  editSendByPostSuccess: (id, orderId) =>
    `${paths.editSendByPostEdit(id, orderId)}/complete`,
  editSendByPostSummary: (id, orderId) =>
    `${paths.editSendByPostEdit(id, orderId)}/summary`,
  editSettings: id => `${paths.edit(id)}/settings`,
  editSlides: id => `${paths.edit(id)}/slides`,
  editSoundtrack: id => {
    return `${paths.edit(id)}/soundtrack`;
  },
  editSoundtrackLibrary: id => {
    return `${paths.edit(id)}/soundtrack/browse`;
  },

  forwardTo: url => `/to/${url}`,
  // contributor flow
  group: (groupToken, joinToken) =>
    `/group/${groupToken}${joinToken ? `/${joinToken}` : ""}`,
  groupRoles: groupToken => `${paths.group(groupToken)}/roles`,
  groupSlides: groupToken => `${paths.group(groupToken)}/slides`,

  home: type => `/home${type ? `/${type}` : ""}`,
  // landing page variants
  landing: () => `/`,
  landingBirthday: () => `/birthday-group-card`,
  landingGroupCard: () => `/group-card`,
  landingGroupEcard: () => `/group-ecard`,
  landingLeavingCard: () => `/leaving-card`,
  landingPersonalisedCard: () => `/personalised-card`,
  landingBusiness: () => `/business`,
  // landing sub-pages
  landingHowItWorks: () => `/how-it-works`,
  landingPricing: () => `/pricing`,
  landingPricingBundles: () => `${paths.landingPricing()}/bundles`,
  landingBusinessPricing: () => `/business-pricing`,
  // landingWhyKindeo: () => `/why-kindeo`,

  login: () => `/login`,
  // recipient flow
  recipient: recipientToken => `/r/${recipientToken}`,
  recipientRoles: recipientToken => `${paths.recipient(recipientToken)}/roles`,
  recipientSlides: recipientToken =>
    `${paths.recipient(recipientToken)}/slides`,
  recipientThanks: recipientToken =>
    `${paths.recipient(recipientToken)}/thanks`,

  register: () => `/register`,
  // reminder section
  reminders: () => `/reminders`,
  reminderAdd: () => `${paths.reminderEdit("add")}`,
  reminderEdit: id => `${paths.reminders()}/${id}`,
  review: () => `/review`,
  // shop flow / pre-create
  shop: occasion => `/shop${occasion ? `/${occasion}` : ""}`,
  shopFromReminder: id => `${paths.shop()}/reminder/${id}`,

  thumbnailGenerator: () => `/thumbnail_generator`,
  unsupported: () => `/unsupported`,
};

export const createCardPaths = [paths.createCover(), paths.createGreeting()];

export const editSlidesPaths = [paths.edit(":id"), paths.editSlides(":id")];

export const editGroupPaths = [
  paths.editGroupInvite(":id"),
  paths.editGroupInvitation(":id"),
  paths.editGroup(":id"),
];
export const editGroupSetupPaths = [
  paths.editGroupSplash(":id"),
  paths.editGroupSetupRecipient(":id"),
  paths.editGroupSetupInvitation(":id"),
];

export const editCardCoverPaths = [
  paths.editCover(":id"),
  paths.editGreeting(":id"),
];

export const editSendPaths = [
  paths.editSendSetupRecipient(":id"),
  paths.editSendUpdateRecipient(":id"),
  paths.editSendByPostAddressNew(":id"),
  paths.editSendByPostAddress(":id", ":addressId"),
  paths.editSendByPostAdd(":id"),
  paths.editSendByPostEdit(":id", ":orderId"),
  paths.editSendByPostPayment(":id", ":orderId"),
  paths.editSendByPostSuccess(":id", ":orderId"),
  paths.editSendByPostSummary(":id", ":orderId"),
  paths.editSendByEmail(":id"),
  paths.editSendByLink(":id"),
  paths.editSendByPost(":id"),
  paths.editSend(":id"),
];

export const editSoundtrackPaths = [
  paths.editSoundtrack(":id"),
  paths.editSoundtrackLibrary(":id"),
];

// careful here. Edit slidesPaths should come last because it includes
// the path `/edit` which will prevent any more specific paths from being matched
// if it is encountered in the array
export const editPaths = [
  paths.editSettings(":id"),
  ...editGroupPaths,
  ...editSendPaths,
  ...editSoundtrackPaths,
  ...editCardCoverPaths,
  ...editSlidesPaths,
];

export const editPageWithoutNavBar = [
  paths.editGroupInvite(":id"),
  paths.editGroupInvitation(":id"),
  paths.editSoundtrackLibrary(":id"),
  paths.editSettings(":id"),
];

export const accountPaths = [
  paths.accountAddresses(),
  paths.accountAddressDetails(":id"),
  paths.accountBundles(),
  paths.accountPayments(),
  paths.accountPaymentDetails(":id"),
  paths.accountProfile(),
  paths.account(),
];

export const shopPaths = [
  paths.shop(),
  paths.shop(":occasion"),
  paths.shopFromReminder(":reminderId"),
];

export const reminderPaths = [
  paths.reminderAdd(),
  paths.reminderEdit(),
  paths.reminders(),
];

export const groupSlidesPaths = [
  paths.groupSlides(":groupToken"),
  paths.group(":groupToken"),
];
export const groupPaths = [
  paths.groupSlides(":groupToken"),
  paths.groupRoles(":groupToken"),
  paths.group(":groupToken"),
];

export const recipientPaths = [
  paths.recipient(":recipientToken"),
  paths.recipientRoles(":recipientToken"),
  paths.recipientSlides(":recipientToken"),
  paths.recipientThanks(":recipientToken"),
];

// Pages which show the mobile nav Bar at the bottom
export const pagesWithNavBar = [...editCardCoverPaths, ...createCardPaths];

// pages which are specifically landing pages, used to avoid mounting the page loader
export const landingPages = [
  paths.landing(),
  paths.landingBirthday(),
  paths.landingGroupCard(),
  paths.landingGroupEcard(),
  paths.landingLeavingCard(),
  paths.landingPersonalisedCard(),
  paths.landingBusiness(),
];

// routes which should not show the page loader when navigating between them
export const pagesWithoutLoader = [
  paths.landingPricing(),
  paths.landingPricingBundles(),
  paths.editSend(":id"),
  paths.editSettings(":id"),
  paths.thumbnailGenerator(),
  ...shopPaths,
  ...editCardCoverPaths,
  ...createCardPaths,
  ...accountPaths,
  ...editSoundtrackPaths,
  ...editSlidesPaths,
  ...editGroupPaths,
  ...editGroupSetupPaths,
  ...editSendPaths,
  ...groupPaths,
  ...recipientPaths,
];

const InternalRedirect = React.memo(props => {
  const { pathname, referrer, search } = props;

  return (
    <Redirect
      to={{
        pathname,
        state: { referrer },
        search,
      }}
    />
  );
});

// This simple wrapper guards against URLs changing ("home/mine"=>"home/saved")
// which triggers the page loader but the rendered path and the rendered component
// is still the same, so the page will not remount and not call stopLoader()
const LoaderRoute = React.memo(props => {
  const { children, location, match } = props;

  const path = match.path;

  const previousPath = usePrevious(match.path);
  const locationPrev = usePrevious(location);
  const { stopLoader } = usePageLoader();

  // if triggered by changes in location.pathname, check if path has changed
  useEffect(() => {
    // if something has changed, but the path remains the same, we're rendering
    // the same component - hide the page loader
    if (location !== locationPrev && path === previousPath) {
      stopLoader();
    }
  }, [path, previousPath, location, locationPrev, stopLoader]);

  return <ErrorBoundary fallback={<PageFallback />}>{children}</ErrorBoundary>;
});

const PageFallback = React.memo(() => {
  return <h1 className="h3">This page cannot be displayed</h1>;
});

// Automatically forward anonymous users away from auth-only routes to the home page
// and force the Log In modal to show
const AuthRoute = React.memo(props => {
  const { children, ...propsRest } = props;
  const { location, match } = propsRest;
  const path = match.path;
  const isLoggedIn = useAuthStatus();

  return (
    <LoaderRoute {...propsRest}>
      {isLoggedIn ? (
        React.cloneElement(children, { path })
      ) : (
        <InternalRedirect
          pathname={paths.login()}
          referrer={location.pathname}
        />
      )}
    </LoaderRoute>
  );
});

// Automatically forward authenticated users away from
// pages that only anonymous users should see
// Maintain the search string and any modal specified, unless it's login or registration
const AuthForwardingRoute = React.memo(props => {
  const { children, ...propsRest } = props;
  const location = propsRest.location;

  // check auth status on mount only.
  // Users ahould not be forwarded on if they authenticate while on this route
  const [isLoggedInOnMount] = useState(useAuthStatus());

  const { getModal } = useModals();
  const currentModalName = getModal()?.modal;
  const showingAuthModal = [modals.auth()].includes(currentModalName);
  // maintain the search string unless it's for login or registration modals
  const newSearchString = !showingAuthModal ? location.search : null;

  return (
    <LoaderRoute {...propsRest}>
      {isLoggedInOnMount ? (
        <InternalRedirect
          pathname={location.state?.referrer || paths.home()}
          search={newSearchString}
        />
      ) : (
        children
      )}
    </LoaderRoute>
  );
});

const PageRoutes = props => {
  const { webGLSupported } = props;
  const queryParams = useQueryParams();
  const loaderLocation = useLoaderLocation();
  const { linkModal } = useModals();

  // feed the loaderLocation into Switch statement which applies a delay
  // but before loaderLocation has a value fall back to live location
  return (
    <Suspense fallback={<Loader />}>
      <Switch location={loaderLocation}>
        {/* always allow forwards to other URLs */}
        <Route
          path={paths.forwardTo(":url")}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <ForwardTo />
              </LoaderRoute>
            );
          }}
        />
        {/* landing pages */}
        <Route
          path={landingPages}
          exact
          children={props => {
            const {
              location: { pathname },
            } = props;

            let variant = LANDING_PAGES.CONSUMER;
            switch (pathname) {
              case paths.landingBirthday():
                variant = LANDING_PAGES.BIRTHDAY;
                break;
              case paths.landingGroupCard():
                variant = LANDING_PAGES.GROUPCARD;
                break;
              case paths.landingGroupEcard():
                variant = LANDING_PAGES.GROUPECARD;
                break;
              case paths.landingLeavingCard():
                variant = LANDING_PAGES.LEAVING;
                break;
              case paths.landingPersonalisedCard():
                variant = LANDING_PAGES.PERSONALISED;
                break;
              case paths.landingBusiness():
                variant = LANDING_PAGES.WORK;
                break;

              default:
                break;
            }

            return (
              <LoaderRoute {...props}>
                <Landing variant={variant} />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path={paths.landingHowItWorks()}
          exact
          children={props => {
            return (
              <LoaderRoute {...props}>
                <LandingHowItWorks />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path={paths.landingPricing()}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <LandingPricing />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path={paths.landingBusinessPricing()}
          exact
          children={props => {
            return (
              <LoaderRoute {...props}>
                <LandingBusinessPricing />
              </LoaderRoute>
            );
          }}
        />
        {/* <Route
          path={paths.landingWhyKindeo()}
          exact
          children={props => {
            return (
              <LoaderRoute {...props}>
                <LandingWhyKindeo />
              </LoaderRoute>
            );
          }}
        /> */}
        {/* review Kindeo */}
        <Route path={paths.review()}>
          <Review />
        </Route>
        {/* unsupported tech can only see this route */}
        {!webGLSupported && (
          <Route>
            <UnsupportedBrowser />
          </Route>
        )}
        {/* Authenticate by link */}
        <Route
          path={paths.authenticateByLink()}
          children={props => {
            return <AuthenticateByLink />;
          }}
        />
        {/* Creator routes */}
        <Route
          path={[
            paths.shopFromReminder(":reminderId"),
            paths.shop(":occasion"),
            paths.shop(),
          ]}
          children={props => {
            // if there's a legacy ?occasion=xyz param, convert it to a new URL
            const { location } = props;
            let queryOccasion = getQueryParam(location.search, "occasion");
            // if the legacy param is for the renamed "farewell" occasion
            if (queryOccasion === "farewell") {
              queryOccasion = OCCASION_TYPES.LEAVING;
            }
            // if there was a valid queryOccasion, redirect to the new URL
            if (queryOccasion) {
              return <Redirect to={paths.shop(queryOccasion)} />;
            }

            return (
              <LoaderRoute {...props}>
                <Shop {...queryParams} />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path={paths.createCover()}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <CreateCard {...queryParams} />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path={[paths.login(), paths.register()]}
          exact
          children={props => {
            return (
              <AuthForwardingRoute {...props}>
                <Redirect to={paths.landing()} />
              </AuthForwardingRoute>
            );
          }}
        />
        <Route
          path={paths.home(":type?")}
          children={props => {
            return (
              <AuthRoute {...props}>
                <Cards />
              </AuthRoute>
            );
          }}
        />
        <Route
          path={paths.thumbnailGenerator()}
          children={props => {
            return <ThumbnailGenerator />;
          }}
        />
        <Route
          path={paths.account()}
          children={props => {
            return (
              <AuthRoute {...props}>
                <Account />
              </AuthRoute>
            );
          }}
        />
        <Route
          path={paths.reminders()}
          children={props => {
            return (
              <AuthRoute {...props}>
                <Reminders />
              </AuthRoute>
            );
          }}
        />
        <Route
          path={editPaths}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Edit />
              </LoaderRoute>
            );
          }}
        />
        {/* Sharing routes */}
        <Route
          path={paths.recipient(":recipientToken")}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Recipient />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path={paths.group(":groupToken")}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Group />
              </LoaderRoute>
            );
          }}
        />
        {/* membership request approval from email link */}
        <Route
          path={paths.approveRequest(":roleId")}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <ApproveAccess />
              </LoaderRoute>
            );
          }}
        />
        {/* Redirects from routes to open FAQ and legal modals */}
        <Route
          path="/faq(s?)"
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Redirect to={linkModal(modals.faq(), null, paths.landing())} />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path="/privacy"
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Redirect
                  to={linkModal(modals.privacy(), null, paths.landing())}
                />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path="/terms"
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Redirect
                  to={linkModal(modals.terms(), null, paths.landing())}
                />
              </LoaderRoute>
            );
          }}
        />
        <Route
          path="/corporate-terms"
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Redirect
                  to={linkModal(modals.termsCorporate(), null, paths.landing())}
                />
              </LoaderRoute>
            );
          }}
        />
        {/* Unsupported tech page direct route, testing only */}
        <Route
          path={paths.unsupported()}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <UnsupportedBrowser />
              </LoaderRoute>
            );
          }}
        />
        {/* legacy routes redirect */}
        {/* /create => /shop */}
        <Route
          path="/create"
          children={props => {
            const { location } = props;
            return (
              <LoaderRoute {...props}>
                <Redirect
                  to={
                    location.pathname.replace("/create", paths.shop()) +
                    location.search
                  }
                />
              </LoaderRoute>
            );
          }}
        />
        {/* /why-kindeo => / */}
        <Route path="/why-kindeo">
          <Redirect to={paths.landing()} />
        </Route>
        {/* /edit => /edit/:anonymousStoryId */}
        <LoaderRoute path={paths.edit("")} exact>
          <Redirect to={paths.edit(anonymousStoryId)} />
        </LoaderRoute>
        {/* unwrap/:recipient_token => group/:group_token */}
        <Route
          path="/unwrap/:token"
          children={props => {
            return (
              <LoaderRoute {...props}>
                <LegacySharingRedirect
                  tokenType={storyTokenTypes.recipientToken}
                />
              </LoaderRoute>
            );
          }}
        />
        {/* contribute/:invite_token => group/:group_token */}
        <Route
          path="/contribute/:token"
          children={props => {
            return (
              <LoaderRoute {...props}>
                <LegacySharingRedirect
                  tokenType={storyTokenTypes.inviteToken}
                />
              </LoaderRoute>
            );
          }}
        />
        {/* share/:token || watch/:watch_token => r/:recipient_token */}
        {/* /share was the previous version of /watch */}
        <Route
          path={["/share/:token", "/watch/:token"]}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <LegacySharingRedirect tokenType={storyTokenTypes.watchToken} />
              </LoaderRoute>
            );
          }}
        />
        {/* stories -> home */}
        <Route
          path="/stories"
          children={props => {
            return (
              <LoaderRoute {...props}>
                <Redirect to={paths.home()} />
              </LoaderRoute>
            );
          }}
        />
        {/* summary => home */}
        <Route
          path={"/summary/:type?"}
          children={props => {
            return (
              <LoaderRoute {...props}>
                <DynamicRouteRedirect route={paths.home} routeParam="type" />
              </LoaderRoute>
            );
          }}
        />
        {/* old landing pages -> customer landing page */}
        <Route
          path={[
            "/birthday-video-messages",
            "/wedding-photography",
            "/anniversaries",
            "/events",
            "/trip",
            "/work-celebrations",
          ]}
          render={() => <Redirect to={paths.landing()} />}
        />
        {/* catch-all 404 */}
        <Route
          children={props => {
            return (
              <LoaderRoute {...props}>
                <ErrorNotFound page />
              </LoaderRoute>
            );
          }}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(PageRoutes);
