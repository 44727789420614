export const preloadImage = src => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = reject;

    try {
      image.src = src;
    } catch (e) {
      reject();
    }
  });
};

export const getRootClasses = () => {
  return document.documentElement.classList;
};

export const addRootClass = classname => {
  const classArray = !Array.isArray(classname)
    ? classname.split(" ")
    : classname;
  getRootClasses().add(...classArray);
};

export const removeRootClass = classname => {
  const classArray = !Array.isArray(classname)
    ? classname.split(" ")
    : classname;
  getRootClasses().remove(...classArray);
};

export const hasRootClass = classname => {
  return getRootClasses().contains(classname);
};

const getCssContextElement = element => {
  let contextElement;
  if (element) {
    contextElement =
      typeof element === "string" ? document.querySelector(element) : element;
  }
  if (!contextElement) {
    contextElement = document.querySelector(":root");
  }

  return contextElement;
};
export const setCssVar = (name, value, element) => {
  const contextElement = getCssContextElement(element);
  requestAnimationFrame(() => {
    contextElement.style.setProperty(name, value);
  });
};
export const removeCssVar = (name, element) => {
  const contextElement = getCssContextElement(element);
  requestAnimationFrame(() => {
    contextElement.style.removeProperty(name);
  });
};
