export const themes = [
  {
    name: "hearts-0",
    text: { color: "0xffffff" },
    background: {
      color: "0xFF502B",
      properties: {
        type: "Dots",
        alphaMin: 0.2,
        alphaGlobal: 0.6847826086956522,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "HeartsScene",
      colorsHearts: ["0xff4d4d", "0xf2825a", "0xff3374", "0xff0051"],
      colorsParticles: ["0xa80022", "0xd60014", "0xff0000", "0xf7492e"],
    },
  },
  {
    name: "hearts-1",
    text: { color: "0x393c54" },
    background: {
      color: "0xdef3f5",
      properties: {
        type: "Squares",
        alpha: 0.2,
        size: { x: 0.1, y: 0.1 },
        columnWidth: 59.891304347826086,
        color: [0.5764705882352941, 0.6705882352941176, 0.8392156862745098],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "HeartsScene",
      colorsHearts: ["0xff4d4d", "0xf2825a", "0xff3374", "0xff0051"],
      colorsParticles: ["0xa80022", "0xd60014", "0xff0000", "0xf7492e"],
    },
  },
  {
    name: "hearts-2",
    text: { color: "0x2f5383" },
    background: {
      color: "0xe3efff",
      properties: {
        type: "Dots",
        alphaMin: 0.5217391304347826,
        alphaGlobal: 0.6086956521739131,
        scaleValue: 3,
        alphaValue: 0.6608695652173914,
        radius: 0.01,
        columnWidth: 16.847826086956523,
        color: [0.4549019607843137, 0.611764705882353, 0.9372549019607843],
      },
    },
    scene: {
      miniSceneColor: 3101571,
      type: "HeartsScene",
      colorsHearts: ["0xc7ddec", "0x879dd6", "0x6d7eac", "0x97b8f2"],
      colorsParticles: ["0xc2d3ff", "0xe5fc00", "0x99b9ff", "0x3500bc"],
    },
  },
  {
    name: "hearts-3",
    text: { color: "0xf09d4f" },
    background: {
      color: "0xfffaf2",
      properties: {
        type: "Squares",
        alpha: 0.17391304347826086,
        size: { x: 0.47058823529411775, y: 0.16498161764705888 },
        columnWidth: 60.8695652173913,
        color: [0.9490196078431372, 0.792156862745098, 0.44313725490196076],
      },
    },
    scene: {
      miniSceneColor: 16771536,
      type: "HeartsScene",
      colorsHearts: ["0xffaf00", "0xffc300", "0xe6a10a"],
      colorsParticles: ["0xe9c918", "0xaf9e3b", "0xdfbe04", "0xc3801a"],
    },
  },
  // {
  //   name: "hearts-4",
  //   text: { color: "0xff0000" },
  //   background: {
  //     color: "0xff9882",
  //     properties: {
  //       type: "Dots",
  //       alphaMin: 0.6630434782608695,
  //       alphaGlobal: 0.5434782608695652,
  //       scaleValue: 1.6630434782608696,
  //       alphaValue: 0.44347826086956527,
  //       radius: 0.01,
  //       columnWidth: 21.73913043478261,
  //       color: [1, 0, 0],
  //     },
  //   },
  //   scene: {
  //     miniSceneColor: 16749951,
  //     type: "HeartsScene",
  //     colorsHearts: ["0xff0000", "0xe60909", "0xff0004"],
  //     colorsParticles: ["0xff0004", "0xe60909"],
  //   },
  // },
  {
    name: "hearts-5",
    text: { color: "0xffffff" },
    background: {
      color: "0x2b2bff",
      properties: {
        type: "Dots",
        alphaMin: 0.6630434782608695,
        alphaGlobal: 0.5434782608695652,
        scaleValue: 1.6630434782608696,
        alphaValue: 0.44347826086956527,
        radius: 0.01,
        columnWidth: 21.73913043478261,
        color: [1, 0, 0],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "HeartsScene",
      colorsHearts: ["0xff0000", "0xe60909", "0xff0004"],
      colorsParticles: ["0xff0004", "0xe60909"],
    },
  },
  {
    name: "hearts-6",
    text: { color: "0xff8c00" },
    background: {
      color: "0x24408e",
      properties: {
        type: "Squares",
        alpha: 0.18478260869565216,
        size: { x: 0.2058823529411764, y: 0.02941176470588225 },
        columnWidth: 100,
        color: [1, 0.5490196078431373, 0],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "HeartsScene",
      colorsHearts: ["0xe40303", "0xffed00", "0x008026", "0x732982"],
      colorsParticles: ["0x732982", "0xff8c00", "0x008026", "0x24408e"],
    },
  },
  {
    name: "hearts-7",
    text: { color: "0xcbf15a" },
    background: {
      color: "0x0c133c",
      properties: {
        type: "Squares",
        alpha: 0.41304347826086957,
        size: { x: 0.02, y: 0.54 },
        columnWidth: 20.76086956521739,
        color: [0.9725490196078431, 0.8823529411764706, 0.6431372549019608],
      },
    },
    scene: {
      miniSceneColor: 791356,
      type: "HeartsScene",
      colorsHearts: ["0xf50ad5", "0xcbf15a", "0xff9736", "0xff0051"],
      colorsParticles: ["0x5ff503", "0xeb336a", "0x21cbff", "0x655ff2"],
    },
  },
];
