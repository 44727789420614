/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

export const actions = {
  clear: createAction("ANONDATA_CLEAR"),
  setAuthScenario: createAction("ANONDATA_SET_AUTHSCENARIO"),
  setBundleDetails: createAction("ANONDATA_SET_BUNDLEDETAILS"),
  setContributionDetails: createAction("ANONDATA_SET_CONTRIBUTION"),
  setDetails: createAction("ANONDATA_SET_DETAILS"),
  setDidPlayAsRecipient: createAction("ANONDATA_SET_RECIPIENTPLAY"),
  setEmail: createAction("ANONDATA_SET_EMAIL"),
  setFirstName: createAction("ANONDATA_SET_FIRST_NAME"),
  setGroupToken: createAction("ANONDATA_SET_GROUPTOKEN"),
  setJoinToken: createAction("ANONDATA_SET_JOINTOKEN"),
  setLastName: createAction("ANONDATA_SET_LAST_NAME"),
  setProductTier: createAction("ANONDATA_SET_PRODUCTTIER"),
  setRecipientToken: createAction("ANONDATA_SET_RECIPIENTTOKEN"),
  setSendMethod: createAction("ANONDATA_SET_SENDMETHOD"),
  setSharedStoryId: createAction("ANONDATA_SET_SHARED_ID"),
  setStoryId: createAction("ANONDATA_SET_STORYID"),
  setThanksDetails: createAction("ANONDATA_SET_THANKS"),
};

export const setAnonymousAuthScenario = authScenario => dispatch => {
  return dispatch(actions.setAuthScenario({ authScenario }));
};

export const setAnonymousDetails = payload => dispatch => {
  const { email, firstName, lastName } = payload;
  return dispatch(actions.setDetails({ email, firstName, lastName }));
};

export const setAnonymousEmail = email => dispatch => {
  return dispatch(actions.setEmail({ email }));
};

export const setAnonymousGroupToken = groupToken => dispatch => {
  return dispatch(actions.setGroupToken({ groupToken }));
};

export const setAnonymousFirstName = firstName => dispatch => {
  return dispatch(actions.setFirstName({ firstName }));
};

export const setAnonymousLastName = lastName => dispatch => {
  return dispatch(actions.setLastName({ lastName }));
};

export const setAnonymousStoryId = storyId => dispatch => {
  return dispatch(actions.setStoryId({ storyId }));
};

export const setAnonymousSharedStoryId = storyId => dispatch => {
  return dispatch(actions.setSharedStoryId({ storyId }));
};

export const setAnonymousDidPlayAsRecipient =
  didPlayAsRecipient => dispatch => {
    return dispatch(actions.setDidPlayAsRecipient({ didPlayAsRecipient }));
  };

export const setAnonymousJoinToken = joinToken => dispatch => {
  return dispatch(actions.setJoinToken({ joinToken }));
};

export const setAnonymousRecipientToken = recipientToken => dispatch => {
  return dispatch(actions.setRecipientToken({ recipientToken }));
};

export const setAnonymousThanksDetails =
  (recipientToken, recipientThanks) => dispatch => {
    return dispatch(
      actions.setThanksDetails({
        recipientThanks,
        recipientToken,
      })
    );
  };

export const setAnonymousContributionDetails =
  (contributionId, groupToken) => dispatch => {
    return dispatch(
      actions.setContributionDetails({ contributionId, groupToken })
    );
  };

export const setAnonymousSendMethod = sendMethod => dispatch => {
  return dispatch(actions.setSendMethod({ sendMethod }));
};

export const setAnonymousProductTier = productTier => dispatch => {
  return dispatch(actions.setProductTier({ productTier }));
};

export const setAnonymousBundleDetails =
  (bundleType, bundleQuantity) => dispatch => {
    return dispatch(actions.setBundleDetails({ bundleType, bundleQuantity }));
  };

export const clearAnonymousData = () => dispatch => {
  return dispatch(actions.clear());
};
