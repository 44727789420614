/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as storyActions from "redux/story/actions";
/* -Actions */

/* Selectors */
import * as selectors from "./selectors";
import * as tracksSelectors from "redux/tracks/selectors";
/* -Selectors */

import analytics, { EVENTS } from "utils/analyticsUtils";

export const actions = {
  addToSoundtrack: createAction("SOUNDTRACK_ADD_TRACK"),
  removeFromSoundtrackById: createAction("SOUNDTRACK_REMOVE_TRACK"),
  setSelected: createAction("SOUNDTRACK_SET_SELECTED"),
  setSoundtrackAction: createAction("SOUNDTRACK_SET_ACTION"),
  setLibrary: createAction("SOUNDTRACK_SET_LIBRARY"),
};

export const fetchTracks = () => dispatch => {
  return axios.get(`/tracks`).then(response => {
    const tracks = response?.data?.tracks;
    if (tracks) {
      dispatch(actions.setLibrary(tracks));
      return tracks;
    }
  });
};

export const fetchSoundtrack = storyId => (dispatch, getState) => {
  if (!storyId) {
    // default to the current story ID
    storyId = dispatch(storyActions.getCurrentStoryId());
  }

  return axios.get(`/story/${storyId}/soundtrack`).then(response => {
    const soundtrack = response?.data?.soundtrack;
    dispatch(actions.setSelected(soundtrack));
    return soundtrack;
  });
};

export const addToSoundtrack = trackId => dispatch => {
  const storyId = dispatch(storyActions.getCurrentStoryId());

  return axios
    .post(`/story/${storyId}/soundtrack`, { track_id: trackId })
    .then(() => {
      // add stub to local store
      const trackName = dispatch(getTrackName(trackId));
      dispatch(actions.addToSoundtrack({ id: trackId, name: trackName }));
      dispatch(markSoundtrackChanged(true));

      // analytics
      analytics.event(EVENTS.playlist.myPlaylistAdded, {
        name: trackName.toLowerCase(),
      });

      // fetch new API data
      return dispatch(fetchSoundtrack());
    });
};

export const removeFromSoundtrack = trackId => dispatch => {
  const storyId = dispatch(storyActions.getCurrentStoryId());

  return axios.delete(`/story/${storyId}/soundtrack/${trackId}`).then(() => {
    // remove from local store
    dispatch(actions.removeFromSoundtrackById(trackId));
    dispatch(markSoundtrackChanged(false));

    // analytics
    const trackName = dispatch(getTrackName(trackId));
    analytics.event(EVENTS.playlist.myPlaylistRemoved, {
      name: trackName.toLowerCase(),
    });

    // fetch new API data
    return dispatch(fetchSoundtrack());
  });
};

const getTrackName = trackId => (dispatch, getState) => {
  const track = selectors.getTrackById(getState(), trackId);

  return tracksSelectors.getName(track);
};

let soundtrackChangeTimeout = null;
const markSoundtrackChanged = added => dispatch => {
  dispatch(
    actions.setSoundtrackAction(added ? { added: true } : { removed: true })
  );
  // discard any previous timeout reset
  window.clearTimeout(soundtrackChangeTimeout);
  // set a timeout to reset
  soundtrackChangeTimeout = window.setTimeout(() => {
    dispatch(actions.setSoundtrackAction({}));
  }, 3000);
};

export const orderTracks = trackIds => dispatch => {
  const storyId = dispatch(storyActions.getCurrentStoryId());

  return axios
    .put(`/story/${storyId}/soundtrack`, {
      tracks: trackIds,
    })
    .catch(() => {
      return false;
    })
    .then(() => {
      // fetch new API data
      return dispatch(fetchSoundtrack());
    });
};
