export const PersistedIds = {
  InviteEmails: "InviteEmails",
  // send to recipient
  SendRecipientMessage: "SendRecipientMessage",
  SendRecipientName: "SendRecipientName",
  SendRecipientEmail: "SendRecipientEmail",
  SendRecipientSetReminder: "SendRecipientSetReminder",
  SendRecipientReminderDate: "SendRecipientReminderDate",
  SendRecipientDaysBefore: "SendRecipientDaysBefore",
  // send methods
  SendMethod: "SendMethod",
};

const getPersisted = state => state.persisted;

export const getPersistedData = (state, ids) => {
  const getItem = id => {
    return getPersisted(state)[id];
  };

  if (Array.isArray(ids)) {
    return ids.map(id => getItem(id));
  }
  return getItem(ids);
};
