/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
/* -Actions */

const INITIAL_STATE = {};

const setUpdateStatus = (state, { payload }) => payload;

export default createReducer(INITIAL_STATE, {
  [actions.platformFetchSuccess]: setUpdateStatus
});
