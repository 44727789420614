/* Libraries */
import { useEffect, useState } from "react";
const { useLocation } = require("react-router-dom");
/* -Libraries */

const { getQueryParams } = require("utils/queryParamUtils");

const useQueryParams = () => {
  const { search } = useLocation();

  const [queryParams, setQueryParams] = useState(getQueryParams(search));

  useEffect(() => {
    // compare the current version of the query params to the new version
    // which, as theyre both objects, needs to be stringified
    setQueryParams(currentParams => {
      const newParams = getQueryParams(search);
      if (JSON.stringify(currentParams) !== JSON.stringify(newParams)) {
        return newParams;
      }
      return currentParams;
    });
  }, [search]);

  return queryParams;
};

export default useQueryParams;
