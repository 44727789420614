export const promiseSequence = (values, mapper) => {
  const reducer = (acc, v) =>
    acc.then(a => mapper(v).then(result => a.push(result) && acc));
  return values.reduce(reducer, Promise.resolve([]));
};

/* 
 * similar method than the one above, + the possibility
8* of rejecting the promises. Eg. in Photo slide where there is some inslide loading,
 * that we need to cancel when the slide hides.
*/
export const promiseSequenceWithReject = (values, mapper) => {
  const rejectors = [];
  const reducer = (acc, v) => {
    const p = new Promise((resolve, reject) => {
      rejectors.push(reject);
      acc
        .then(a => {
          mapper(v).then(result => {
            resolve(result);
          });
        })
        .catch(err => {});
    });

    p.catch(err => {});

    return p;
  };

  const reject = () => {
    rejectors.forEach(rejector => {
      rejector();
    });

    rejectors.length = 0;
  };

  const promise = values.reduce(reducer, Promise.resolve([]));
  promise.reject = reject;
  return promise;
};
