export function getLocaleFromCurrency(currency) {
  switch (currency.toLowerCase()) {
    case "gbp":
      return "en-GB";
    case "usd":
      return "en-US";
    case "eur":
      return "en-IE";
    default:
      return null;
  }
}

export function asCurrency(value) {
  const number = parseFloat(value);
  return isNaN(number) ? value : number.toFixed(2);
}

export function formatCurrency(amount, currency, convert_cents = true) {
  const amount_converted = convert_cents ? amount / 100 : amount;

  if (currency) {
    const numberFormat = Intl.NumberFormat(getLocaleFromCurrency(currency), {
      style: "currency",
      currency,
    });

    return numberFormat.format(amount_converted);
  }

  return amount_converted;
}

export function secondsToTimeUnits(seconds) {
  let timeUnits = { h: 0, m: 0, s: 0 };

  seconds = parseOrFallback(seconds, 0);
  if (seconds) {
    timeUnits.h = Math.floor(seconds / 3600);
    timeUnits.m = Math.floor((seconds % 3600) / 60);
    timeUnits.s = Math.floor(seconds % 60);
  }

  return timeUnits;
}

export function secondsToHMS(seconds) {
  const units = secondsToTimeUnits(seconds);

  return `${units.h ? padInt(units.h) + ":" : ""}${padInt(units.m)}:${padInt(
    units.s
  )}`;
}
export function secondsToReadableHMS(seconds) {
  const units = secondsToTimeUnits(seconds);

  return `${units.h ? units.h + " hr " : ""}${
    units.m ? units.m + " min " : ""
  }${units.s} sec`;
}

export function parseOrFallback(value, fallback = 0) {
  const number = parseFloat(value, 10);
  return !isNaN(number) ? number : fallback;
}

// pad a given int to a given length with a given character
export function padInt(number, minLength = 2, padChar = "0") {
  const value = parseOrFallback(number, 0);
  const padding = padChar.repeat(minLength);
  return (padding + value).slice(-Math.max(minLength, value.toString().length));
}

// verify all values are integers
export function theseAreInts(...params) {
  return !isNaN(params.reduce((sum, param) => sum + parseInt(param, 10), 0));
}

export function roundTo2dp(num) {
  return Math.round(num * 100) / 100;
}
