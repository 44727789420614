/* Selectors */
/* -Selectors */

import { formatCurrency } from "utils/numberUtils";

export const TRANSACTION_TYPES = { FREE: "FREE", PAID: "PAID" };
export const CARD_UPGRADE_SUCCESS_CODES = {
  [TRANSACTION_TYPES.FREE]: "redeemed",
  [TRANSACTION_TYPES.PAID]: "payment-success",
};
export const POSTALORDER_SUCCESS_CODE = "posted";
export const TRANSACTION_ID_PARAMETERS = {
  stripe: "stripe_session_id",
  credit: "credit_type",
};

export const PRODUCT_TIERS = {
  FREE: "free",
  STARTER: "starter",
  UNLIMITED: "unlimited",
};

export const PAYMENT_TYPES = {
  BUNDLE: "bundle",
  CAPACITY: "product_tier",
  POSTAL: "postal_order",
};

export const DELIVERY_TYPES = {
  DIGITAL: "digital",
  POSTAL: "postal",
};

export const getPayment = state => state.payment;

export const getAllProducts = state => getPayment(state).products;
export const getDigitalProducts = state =>
  getAllProducts(state)[DELIVERY_TYPES.DIGITAL];
export const getPostalProducts = state =>
  getAllProducts(state)[DELIVERY_TYPES.POSTAL];

export const getAllUpgradeOptions = state => getPayment(state).upgradeOptions;
export const getDigitalUpgradeOptions = state =>
  getAllUpgradeOptions(state)[DELIVERY_TYPES.DIGITAL];
export const getPostalUpgradeOptions = state =>
  getAllUpgradeOptions(state)[DELIVERY_TYPES.POSTAL];

export const getPaymentClientSecret = payment => payment?.paymentClientSecret;
export const getPaywallDisplay = payment => payment?.paywallDisplay;
export const getPaymentReturnPath = payment => payment?.paymentReturnPath;

export const getProductByName = (products, productName) =>
  products.find(product => getProductName(product) === productName);

export const getProductName = product => product?.name;
export const getProductLabel = product => product?.label;
export const getProductPriceRaw = product => product?.amount || 0;
export const getProductCurrency = product => product?.currency || "";
export const getProductPrice = product =>
  formatCurrency(getProductPriceRaw(product), getProductCurrency(product));
export const getProductCurrencySymbol = product =>
  product?.currency_symbol || "";
export const getProductSlideLimit = product => {
  const slideLimit = product?.slide_limit;
  if (slideLimit) {
    return slideLimit;
  }
  // unlimited slides is described by the API as null which is less helpful than Infinity
  if (slideLimit === null) {
    return Infinity;
  }
  return 0;
};
export const getSelectedUpgradeTier = state => getPayment(state).upgradeTier;
export const getSelectedUpgradeProduct = state => {
  const upgradeTier = getSelectedUpgradeTier(state);
  const products = getDigitalUpgradeOptions(state);
  return getProductByName(products, upgradeTier);
};
export const getSelectedCreditType = state => getPayment(state)?.creditType;

export const productIsUnlimited = product =>
  getProductSlideLimit(product) === Infinity;

export const getUnlimitedProducts = state => ({
  [DELIVERY_TYPES.POSTAL]: getPostalProducts(state).find(productIsUnlimited),
  [DELIVERY_TYPES.DIGITAL]: getDigitalProducts(state).find(productIsUnlimited),
});

export const getLocaleCurrency = state => getPayment(state).localeCurrency;
export const getLocaleCurrencySymbol = state =>
  getPayment(state).localeCurrencySymbol;
