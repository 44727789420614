/* Libraries */
import React, { useCallback, useContext } from "react";
/* -Libraries */

/* Selectors */
import { mediaTypes } from "redux/media/selectors";
/* -Selectors */

/* Components */
import PhotoCapture from "components/PhotoLibrary/PhotoCapture";
import VideoCapture from "components/VideoLibrary/VideoCapture";
/* -Components */

/* Context */
import { SlideBuilderContext } from "context/SlideBuilderContext";
/* -Context */

const MediaCapture = props => {
  const {
    closeCapture,
    currentMediaType,
    goToCustomise,
    onDiscardCapture: onDiscard,
    onUploadCapture: onUpload,
  } = useContext(SlideBuilderContext);

  const onContinue = useCallback(() => {
    goToCustomise();
    closeCapture();
  }, [closeCapture, goToCustomise]);

  if (!currentMediaType) return null;

  if (currentMediaType === mediaTypes.PHOTO) {
    return (
      <PhotoCapture
        {...{
          onBack: closeCapture,
          onDiscard,
          onContinue,
          onUpload,
        }}
      />
    );
  }
  if (currentMediaType === mediaTypes.VIDEO) {
    return (
      <VideoCapture
        {...{
          onBack: closeCapture,
          onDiscard,
          onContinue,
          onUpload,
        }}
      />
    );
  }
};

MediaCapture.propTypes = {};

export default MediaCapture;
