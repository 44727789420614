/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
/* -Actions */

const mobileBreakpoints = ["mobile", "tablet", "desktop", "widescreen"];

const INITIAL_STATE = {
  isMobile: true,
  isHandheld: true,
  isDesktop: false,
  current: mobileBreakpoints[0],
};

const setBreakpoint = (state, { payload }) => {
  const { breakpoint = "" } = payload;
  return {
    current: breakpoint,
    isDesktop: mobileBreakpoints.indexOf(breakpoint.toLowerCase()) >= 2,
    isMobile: mobileBreakpoints[0] === breakpoint.toLowerCase(),
    isHandheld: mobileBreakpoints.includes(breakpoint.toLowerCase()),
  };
};

export default createReducer(INITIAL_STATE, {
  [actions.setBreakpoint]: setBreakpoint,
});
