/* Selectors */
import * as editorSelectors from "redux/editor/selectors";
import { mediaTypes } from "redux/media/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
/* Selectors */

export const inputTypes = {
  AGE: "age",
  ALIGN_H: "alignment-horizontal",
  ALIGN_V: "alignment-vertical",
  BUTTON: "button",
  COLOURS: "coloursSet",
  DATE_PICKER: "date-picker",
  GIF: "giphy",
  INPUT: "input",
  LOCATION: "location",
  MAP: "mapDesign",
  MEDIA: "media",
  MUSIC: "music",
  SELECT: "select",
  TAGLIST: "taglist",
  TEXTAREA: "textarea",
  THEME: "theme",
  YEAR_PICKER: "year-picker",
};
export const inputGroupNames = {
  MEDIA: "media",
  TEXT: "text",
  TEXT_ADJUNCT: "text_adjunct",
  THEME: "theme",
  RELATED_CARDS: "related_cards",
  RELATED_CARD_IDS: "related_card_ids",
  CARD: "card",
  GREETING: "greeting",
};
export const inputGroups = {
  [inputGroupNames.MEDIA]: [inputTypes.MEDIA],
  [inputGroupNames.TEXT]: [
    inputTypes.AGE,
    inputTypes.INPUT,
    inputTypes.TEXTAREA,
    inputTypes.ALIGN_H,
    inputTypes.ALIGN_V,
  ],
  [inputGroupNames.TEXT_ADJUNCT]: [inputTypes.ALIGN_H, inputTypes.ALIGN_V],
  [inputGroupNames.THEME]: [inputTypes.COLOURS, inputTypes.THEME],
};

export const slideStatus = {
  PENDING: "pending",
  MODERATED: "moderated",
  ACTIVE: "active",
};

export const getSlide = state =>
  editorSelectors.getSlide(editorSelectors.getEditor(state));

export const getCreated = slide => slide?.created_on;
export const getCreator = slide => slide?.creator;
export const getDate = slide => slide?.date;
export const getDescription = slide => slide?.description;
export const getId = slide => slide?.id;
export const getLocationLat = slide => slide?.location_lat;
export const getLocationLon = slide => slide?.location_lon;
export const getLocationName = slide => slide?.location_name;
export const getLocationNeLat = slide => slide?.location_ne_lat;
export const getLocationNeLon = slide => slide?.location_ne_lon;
export const getLocationSwLat = slide => slide?.location_sw_lat;
export const getLocationSwLon = slide => slide?.location_sw_lon;
export const getLocationZoom = slide => slide?.location_zoom;
export const getMedia = slide => slide?.media;
export const getPosition = slide => slide?.position;
export const getSpotifyTrackId = slide => slide?.spotify_track_id;
export const getGiphyId = slide => slide?.giphy_id;
export const getStoryId = slide => slide?.story_id;
export const getSubtitle = slide => slide?.subtitle;
export const getThumbnailId = slide => slide?.thumbnail_id;
export const getThumbnailUrl = slide => slide?.thumbnail_url;
export const isGeneratingThumbnail = slide => !!slide?.generatingThumbnail;
export const getTitle = slide => slide?.title;
export const getType = slide => slide?.type;
export const getUpdated = slide => slide?.updated_on;
export const getTheme = slide => slide?.theme;
export const getRelatedCardId = slide => slide?.related_card_id;
export const getVariant = slide => slide?.variant;
export const getExportedId = slide => slide?.exported_id;
export const getSlideData = slide => slide?.slide_data || [];
export const isWebpReady = slide => slide?.is_webp_ready;

export const variantIsExported = variantName =>
  variantName === slideVariants[slideTypes.TITLE].EXPORTED;

// for scrolling to an item
export const createSlideDomId = id => (id ? "messageid-" + id : null);
export const getSlideDOMId = slide => createSlideDomId(getId(slide));

export const getSlideDataByKey = (slide, key) =>
  getSlideData(slide).find(item => item.key === key)?.value;

export const getIsUnseen = slide => !slide?.seen;
export const getAllTexts = slide => {
  const texts = [];
  const description = getDescription(slide);
  description && texts.push(description);

  const subtitle = getSubtitle(slide);
  subtitle && texts.push(subtitle);

  const title = getTitle(slide);
  title && texts.push(title);

  const locationName = getLocationName(slide);
  locationName && texts.push(locationName);

  const typeName = getType(slide);
  typeName && texts.push(typeName);

  const variantName = getVariant(slide);
  variantName && texts.push(variantName);

  const slideData = getSlideData(slide);
  slideData.forEach(data => texts.push(data.value));

  return texts;
};

export const getCreatorId = slide => slide?.role_id;
export const getCreatorName = slide => slide?.creator?.first_name;
export const getCreatorFullName = slide => {
  return (
    slide?.creator?.first_name +
    (slide?.creator?.last_name ? ` ${slide?.creator?.last_name}` : "")
  );
};
export const getCreatorLastName = slide => slide?.creator?.last_name || "";
export const getCreatorEmail = slide => slide?.creator?.email;

export const isCreditSlide = slide => getType(slide) === slideTypes.CREDITS;
export const isTitleSlide = slide => getType(slide) === slideTypes.TITLE;
export const isEmptySlide = slide => !getType(slide);
export const isNewSlide = slide => !getId(slide);
export const isContribution = slide => !getId(slide);

export const isModerated = slide => slide?.status === slideStatus.MODERATED;

export const canBeDeleted = slide =>
  ![slideTypes.TITLE, slideTypes.CREDITS].includes(getType(slide));

export const getById = (slides, slideId) =>
  slides.find(slide => getId(slide) === slideId);

export const getSlideMediaLength = slide => getMedia(slide)?.length || 0;

export const getMediaTypeForSlide = slideType => {
  let mediaType;
  switch (slideType) {
    case slideTypes.AUDIO:
      mediaType = mediaTypes.AUDIO;
      break;
    case slideTypes.PHOTOS:
    case slideTypes.TITLE:
      mediaType = mediaTypes.PHOTO;
      break;
    case slideTypes.VIDEO:
      mediaType = mediaTypes.VIDEO;
      break;
    default:
      break;
  }

  return mediaType;
};
