/* Libraries */
import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import classnames from "classnames";
/* -Libraries */

/* Components */
/* -Components */

/* Selectors */
import * as loaderSelectors from "redux/loaders/selectors";
/* -Selectors */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

const selector = createSelector(loaderSelectors.isLoading, isLoading => {
  return {
    isLoading,
  };
});

// This whole component is a kludge to avoid rendering issues with the SiteLoader spinner in iOS15
// When the URL changes, Safari reserves space at the bottom of the screen to allow the URL bar to
// show by truncating any fixed position elements
// The SiteLoader is position: fixed, height:var(--full-height);
// This component, in the DOM at the same level as the content, position: absolute, height: var(--full-height);
// spans the same height as the page content and somehow convinces iOS not to truncate the fixed Loader

// How did I find this out? Who can even tell?

const SiteLoaderBackground = props => {
  const { isLoading } = useSelector(selector);

  return (
    <div
      className={classnames(styles.siteLoaderBg, {
        "is-pointer-events-none": !isLoading,
      })}
    />
  );
};

export default React.memo(SiteLoaderBackground);
