import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import thumbnail from "images/slides/thumbnails/news.png";

const type = slideTypes.NEWS;
const newsVariants = slideVariants[type];

export default {
  type,
  icon: "news",
  label: "News",
  colour: "mint",
  thumbnail,
  variants: [
    {
      name: newsVariants.PAPER,
      label: "Paper",
    },
  ],
  fields: [
    {
      key: "date",
      type: inputTypes.YEAR_PICKER,
      label: "Choose a year",
      defaultValue: new Date().getFullYear(),
    },
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Headline",
      placeholder: "Add a headline",
      validation: {
        required: `Please add a headline`,
        maxLength: {
          value: 40,
          message: `Please keep your headline to 40 characters or less`,
        },
      },
    },
    {
      key: "subtitle",
      type: inputTypes.INPUT,
      label: "Story",
      placeholder: "Add a story",
      validation: {
        maxLength: {
          value: 80,
          message: `Please keep this to 80 characters or less`,
        },
      },
    },
  ],
};
