/* Selectors */
import {
  OCCASION_TYPES,
  getOccasionByType,
  getOccasionLabel,
} from "redux/occasions/selectors";
import * as storySelectors from "redux/story/selectors";
import { fromYMD } from "utils/dateUtils";
/* -Selectors */

export const getReminders = state => state.reminders;

export const getReminderId = reminder => reminder?.id;
export const getReminderRepeat = reminder => reminder?.repeat;
export const getReminderDate = reminder => reminder?.reminder_date;
export const getReminderDaysBefore = reminder => reminder?.days_before;
export const getReminderFromStoryId = reminder => reminder?.from_story_id;

export const getStoryIdsFromReminder = reminder =>
  reminder?.stories?.map(story => storySelectors.getId(story));

export const getEventTitle = reminder => reminder?.title;
export const getEventRecipientFirstName = reminder =>
  reminder?.recipient_first_name;
export const getEventRecipientLastName = reminder =>
  reminder?.recipient_last_name;
export const getEventOccasion = reminder => reminder?.occasion;
export const getEventDate = reminder => reminder?.occasion_date;
export const getEventYear = reminder =>
  fromYMD(getEventDate(reminder)).getFullYear();
export const getEventMonth = reminder =>
  fromYMD(getEventDate(reminder)).getMonth();

export const getReminderById = (reminders, reminderId) =>
  reminders?.find(reminder => getReminderId(reminder) === reminderId);

export const getGroupYear = reminderGroup => reminderGroup?.year;
export const getGroupMonth = reminderGroup => reminderGroup?.month;
export const getGroupReminders = reminderGroup => reminderGroup?.reminders;

export const groupRemindersByMonth = reminders =>
  reminders?.reduce((reminderGroups, reminder) => {
    const currentGroup = reminderGroups[reminderGroups.length - 1];
    const eventYear = getEventYear(reminder);
    const eventMonth = getEventMonth(reminder);
    if (
      eventYear !== getGroupYear(currentGroup) ||
      eventMonth !== getGroupMonth(currentGroup)
    ) {
      return [
        ...reminderGroups,
        { year: eventYear, month: eventMonth, reminders: [reminder] },
      ];
    }

    return [
      ...reminderGroups.slice(0, -1),
      {
        ...currentGroup,
        reminders: [...getGroupReminders(currentGroup), reminder],
      },
    ];
  }, []);

const upcomingReminderCount = 2;
const upcomingReminderDays = 30;
export const getUpcomingReminders = reminders => {
  const upcomingDateLimit = new Date();
  upcomingDateLimit.setDate(upcomingDateLimit.getDate() + upcomingReminderDays);

  return reminders.reduce((reminderList, reminder) => {
    // if we already have enough
    if (reminderList.length >= upcomingReminderCount) {
      return reminderList;
    }
    // if the reminder date is too far ahead
    if (new Date(getReminderDate(reminder)) > upcomingDateLimit) {
      return reminderList;
    }
    // if the reminder has already been used to create a kindeo
    if (getStoryIdsFromReminder(reminder)?.length > 0) {
      return reminderList;
    }

    return [...reminderList, reminder];
  }, []);
};

export const generateReminderTitle = (name, occasionName) => {
  const occasion = getOccasionByType(occasionName);
  const occasionLabel =
    occasionName === OCCASION_TYPES.CUSTOM
      ? "Kindeo"
      : getOccasionLabel(occasion);

  return `${name}'s ${occasionLabel}`;
};
