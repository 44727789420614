/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
import { actions } from "./actions";
import { actions as promptsActions } from "redux/prompts/actions";
/* -Actions */

const INITIAL_STATE = {
  authScenario: null,
  bundleType: null,
  bundleQuantity: null,
  contributionId: null,
  didPlayAsRecipient: false,
  email: null,
  groupToken: null,
  joinToken: null,
  firstName: null,
  lastName: null,
  productTier: null,
  recipientThanks: null,
  recipientToken: null,
  sendMethod: null,
  sharedStoryId: null,
  storyId: null,
  promptsCompleted: [], // See comments in prompts/actions completePrompt()
};

const updatePromptsCompleted = (state, { payload }) => {
  const { prompts } = payload;

  return {
    ...state,
    promptsCompleted: prompts,
  };
};

const setAuthScenario = (state, { payload }) => ({
  ...state,
  authScenario: payload.authScenario,
});

const setProfileDetails = (state, { payload }) => ({
  ...state,
  email: payload.email,
  firstName: payload.firstName,
  lastName: payload.lastName,
});

const setEmail = (state, { payload }) => ({
  ...state,
  email: payload.email,
});

const setFirstName = (state, { payload }) => ({
  ...state,
  firstName: payload.firstName,
});

const setLastName = (state, { payload }) => ({
  ...state,
  lastName: payload.lastName,
});

const setContributionDetails = (state, { payload }) => ({
  ...state,
  contributionId: payload.contributionId,
  groupToken: payload.groupToken,
});

const setGroupToken = (state, { payload }) => ({
  ...state,
  groupToken: payload.groupToken,
});

const setJoinToken = (state, { payload }) => ({
  ...state,
  joinToken: payload.joinToken,
});

const setStoryId = (state, { payload }) => ({
  ...state,
  storyId: payload.storyId,
});

const setSharedStoryId = (state, { payload }) => ({
  ...state,
  sharedStoryId: payload.storyId,
});

const setDidPlayAsRecipient = (state, { payload }) => ({
  ...state,
  didPlayAsRecipient: payload.didPlayAsRecipient,
});

const setRecipientToken = (state, { payload }) => ({
  ...state,
  recipientToken: payload.recipientToken,
});

const setThanksDetails = (state, { payload }) => ({
  ...state,
  recipientThanks: payload.recipientThanks,
  recipientToken: payload.recipientToken,
});

const setSendMethod = (state, { payload }) => ({
  ...state,
  sendMethod: payload.sendMethod,
});

const setProductTier = (state, { payload }) => ({
  ...state,
  productTier: payload.productTier,
});

const setBundleDetails = (state, { payload }) => ({
  ...state,
  bundleType: payload.bundleType,
  bundleQuantity: payload.bundleQuantity,
});

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.clear]: reset,
  [actions.setAuthScenario]: setAuthScenario,
  [actions.setBundleDetails]: setBundleDetails,
  [actions.setContributionDetails]: setContributionDetails,
  [actions.setDetails]: setProfileDetails,
  [actions.setDidPlayAsRecipient]: setDidPlayAsRecipient,
  [actions.setEmail]: setEmail,
  [actions.setFirstName]: setFirstName,
  [actions.setGroupToken]: setGroupToken,
  [actions.setJoinToken]: setJoinToken,
  [actions.setLastName]: setLastName,
  [actions.setProductTier]: setProductTier,
  [actions.setRecipientToken]: setRecipientToken,
  [actions.setSendMethod]: setSendMethod,
  [actions.setStoryId]: setStoryId,
  [actions.setSharedStoryId]: setSharedStoryId,
  [actions.setThanksDetails]: setThanksDetails,
  [promptsActions.updatePromptsCompleted]: updatePromptsCompleted,
  [authActions.logoutInit]: reset,
});
