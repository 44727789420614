export const OCCASION_TYPES = {
  CUSTOM: "custom",
  ALBUM: "album",
  ANNIVERSARY: "anniversary",
  APPRECIATION: "appreciation",
  BIRTHDAY: "birthday",
  CHRISTMAS: "christmas",
  CONGRATS: "congrats",
  LEAVING: "leaving",
  FATHERS: "fathers",
  GET_WELL: "get_well",
  MOTHERS: "mothers",
  THANKS: "thanks",
  TRIP: "trip",
  VALENTINES: "valentines",
  WEDDING: "wedding",
  WELCOME: "welcome",
  WORK_ANNIVERSARY: "work_anniversary",
};

const occasions = [
  {
    name: OCCASION_TYPES.BIRTHDAY,
    reference: "a Birthday",
    label: "Birthday",
    recipientPlaceholder: "Alex",
    generateTitle: name => `Happy Birthday ${name}`,
    generateSubTitle: () => ``,
    colour: "teal4",
    colourLighter: "teal5",
    reminderColours: ["teal3", "teal7"],
    icon: "occasion-birthday",
    iconFilled: "occasion-birthday-fill",
    defaultGiphySearch: "birthday",
    includeInPartialList: true,
  },
  {
    name: OCCASION_TYPES.WELCOME,
    reference: "a welcome",
    label: "Welcome",
    recipientPlaceholder: "Deena",
    generateTitle: name => `Welcome ${name}!`,
    colour: "indigo4",
    colourLighter: "indigo5",
    reminderColours: ["indigo3", "indigo6"],
    icon: "occasion-welcome",
    iconFilled: "occasion-welcome-fill",
    defaultGiphySearch: "welcome",
    includeInPartialList: true,
  },
  {
    name: OCCASION_TYPES.WORK_ANNIVERSARY,
    reference: "a work anniversary",
    label: "Work Anniversary",
    recipientPlaceholder: "Maggie",
    generateTitle: name => `Happy Anniversary ${name}!`,
    colour: "pink4",
    colourLighter: "pink5",
    reminderColours: ["pink3", "pink6"],
    icon: "occasion-anniversary",
    iconFilled: "occasion-anniversary-fill",
    defaultGiphySearch: "anniversary",
    includeInPartialList: true,
  },
  {
    name: OCCASION_TYPES.THANKS,
    reference: "a Thank You",
    label: "Thank You",
    recipientPlaceholder: "Jaimie",
    generateTitle: name => `Thank You ${name}!`,
    generateSubTitle: () => ``,
    colour: "blue4",
    colourLighter: "blue5",
    reminderColours: ["blue3", "blue6"],
    icon: "thanks",
    iconFilled: "occasion-thanks-fill",
    defaultGiphySearch: "thanks",
    includeInPartialList: true,
  },
  {
    name: OCCASION_TYPES.CONGRATS,
    reference: "a congratulations",
    label: "Congratulations",
    recipientPlaceholder: "Alberte",
    generateTitle: name => `Congratulations ${name}!`,
    colour: "yellow4",
    colourLighter: "yellow5",
    reminderColours: ["yellow3", "yellow6"],
    icon: "occasion-congrats",
    iconFilled: "occasion-congrats-fill",
    defaultGiphySearch: "congratulations",
    includeInPartialList: true,
  },
  {
    name: OCCASION_TYPES.APPRECIATION,
    reference: "an appreciation",
    label: "Appreciation",
    recipientPlaceholder: "Renata",
    generateTitle: name => `You're amazing, ${name}!`,
    colour: "yellow4",
    colourLighter: "yellow5",
    reminderColours: ["yellow3", "yellow6"],
    icon: "high-five-1",
    iconFilled: "high-five-1",
    defaultGiphySearch: "amazing",
    includeInPartialList: true,
  },
  {
    name: OCCASION_TYPES.GET_WELL,
    reference: "a get well",
    label: "Get Well",
    recipientPlaceholder: "Maia",
    generateTitle: name => `Get well soon, ${name}!`,
    colour: "yellow4",
    colourLighter: "yellow5",
    reminderColours: ["yellow3", "yellow6"],
    icon: "flower",
    iconFilled: "flower",
    defaultGiphySearch: "get well",
    includeInPartialList: true,
  },
  {
    name: OCCASION_TYPES.LEAVING,
    reference: "a leaving",
    label: "Leaving",
    recipientPlaceholder: "Bryan",
    generateTitle: name => `Good luck ${name}!`,
    colour: "orange4",
    colourLighter: "orange5",
    reminderColours: ["orange3", "orange6"],
    icon: "farewell",
    iconFilled: "farewell-fill",
    defaultGiphySearch: "leaving",
    includeInPartialList: true,
  },

  {
    name: OCCASION_TYPES.ANNIVERSARY,
    reference: "an anniversary",
    label: "Anniversary",
    recipientPlaceholder: "The Wilsons",
    generateTitle: name => `Happy Anniversary ${name}!`,
    colour: "pink4",
    colourLighter: "pink5",
    reminderColours: ["pink3", "pink6"],
    icon: "occasion-anniversary",
    iconFilled: "occasion-anniversary-fill",
    defaultGiphySearch: "anniversary",
    includeInPartialList: false,
  },
  {
    name: OCCASION_TYPES.WEDDING,
    reference: "a wedding",
    label: "Wedding",
    recipientPlaceholder: "Jake & Latoya",
    generateTitle: name => `${name} ${new Date().getFullYear()}!`,
    generateSubTitle: () => ``,
    colour: "purple4",
    colourLighter: "purple5",
    reminderColours: ["purple3", "purple6"],
    icon: "occasion-wedding",
    iconFilled: "occasion-wedding-fill",
    defaultGiphySearch: "wedding",
    includeInPartialList: false,
  },
  {
    name: OCCASION_TYPES.VALENTINES,
    reference: "a Valentines",
    label: "Valentines",
    recipientPlaceholder: "Alex",
    generateTitle: name => `Happy Valentines ${name}!`,
    generateSubTitle: () => ``,
    colour: "red4",
    colourLighter: "red5",
    reminderColours: ["red3", "red6"],
    icon: "occasion-valentines",
    iconFilled: "occasion-valentines-fill",
    defaultGiphySearch: "valentines",
    includeInPartialList: false,
  },
  {
    name: OCCASION_TYPES.MOTHERS,
    reference: "a Mother’s Day",
    label: "Mother’s Day",
    // UK version
    // recipientPlaceholder: "Mum",
    // US version
    recipientPlaceholder: "Mom",
    generateTitle: name => `Happy Mother’s Day`,
    colour: "red4",
    colourLighter: "red5",
    reminderColours: ["red3", "red6"],
    icon: "occasion-valentines",
    iconFilled: "occasion-valentines-fill",
    defaultGiphySearch: "mother",
    includeInPartialList: false,
    disabled: false,
  },
  {
    name: OCCASION_TYPES.FATHERS,
    reference: "a Fathers’s day",
    label: "Father's Day",
    recipientPlaceholder: "Dad",
    generateTitle: name => `Happy Father's Day`,
    colour: "indigo4",
    colourLighter: "indigo5",
    reminderColours: ["indigo3", "indigo6"],
    icon: "occasion-valentines",
    iconFilled: "occasion-valentines-fill",
    defaultGiphySearch: "father",
    includeInPartialList: false,

    // show a label in occasion button
    new: true,
    disabled: false,
  },
  {
    name: OCCASION_TYPES.CHRISTMAS,
    reference: "a Christmas",
    label: "Christmas",
    recipientPlaceholder: "Nicholas",
    generateTitle: name => `Happy Christmas ${name}!`,
    colour: "green4",
    colourLighter: "green5",
    reminderColours: ["green4", "green6"],
    icon: "occasion-christmas",
    iconFilled: "occasion-christmas-fill",
    defaultGiphySearch: "christmas",
    includeInPartialList: false,
  },

  {
    name: OCCASION_TYPES.CUSTOM,
    reference: "a special",
    label: "Any Occasion",
    recipientPlaceholder: "My favourite",
    generateTitle: name => `For ${name}`,
    colour: "neutral4",
    colourLighter: "neutral6",
    reminderColours: ["neutral4", "neutral8"],
    icon: "occasion-more",
    iconFilled: "occasion-more-fill",
    defaultGiphySearch: "celebrate",
    includeInPartialList: false,
  },
  {
    name: OCCASION_TYPES.TRIP,
    reference: "a trip",
    label: "Trip",
    recipientPlaceholder: "The crew",
    generateTitle: name => `For ${name}!`,
    colour: "green4",
    colourLighter: "green5",
    reminderColours: ["green3", "green6"],
    icon: "theme-trip",
    iconFilled: "theme-trip",
    defaultGiphySearch: "holiday",
    includeInPartialList: false,

    disabled: true,
  },
  {
    name: OCCASION_TYPES.ALBUM,
    reference: "an album",
    label: "Album",
    recipientPlaceholder: "The Wilsons",
    generateTitle: name => `Our year ${name} ${new Date().getFullYear()}!`,
    colour: "magenta4",
    colourLighter: "magenta5",
    reminderColours: ["magenta3", "magenta6"],
    icon: "theme-album",
    iconFilled: "theme-album",
    defaultGiphySearch: "photo album",
    includeInPartialList: false,

    disabled: true,
  },
  // {
  //   name: "graduation",
  //   reference: "a graduation",
  //   label: "Graduation",
  //  recipientPlaceholder:"Mathilda",
  //   image: congratsImage,
  //   colour: "yellow4",
  //   colourLighter: "yellow4",
  //   icon: "theme-graduation",
  //   iconFilled: "theme-graduation",
  // },
];

export const recipientNamePlaceholder = "%RECIPIENT_NAME%";
export const recipientNameFallback = "name";

export const getOccasions = () => occasions;
export const getActiveOccasions = currentOccasionName =>
  occasions.filter(
    occasion =>
      !getOccasionIsDisabled(occasion) ||
      getOccasionType(occasion) === currentOccasionName
  );
export const getMajorOccasions = currentOccasionName =>
  getActiveOccasions(currentOccasionName).filter(occasion =>
    includeOccasionInPartialList(occasion)
  );
export const getMinorOccasions = currentOccasionName =>
  getActiveOccasions(currentOccasionName).filter(
    occasion => !includeOccasionInPartialList(occasion)
  );

export const getOccasionType = occasion => occasion?.name;
export const getOccasionReference = occasion => occasion?.reference || "";
export const getOccasionLabel = occasion => occasion?.label;
export const getOccasionRecipientPlaceholder = occasion =>
  occasion?.recipientPlaceholder;
export const getOccasionTitle = (occasion, name) =>
  occasion?.generateTitle(name || recipientNameFallback);
export const getOccasionSubTitle = occasion => occasion?.generateSubTitle?.();
export const getOccasionColour = occasion => occasion?.colour;
export const getOccasionColourLighter = occasion => occasion?.colourLighter;
export const getOccasionReminderColours = occasion => occasion?.reminderColours;
export const getOccasionIcon = occasion => occasion?.icon;
export const getOccasionIconFilled = occasion => occasion?.iconFilled;
export const getOccasionGiphySearch = occasion => occasion?.defaultGiphySearch;
export const includeOccasionInPartialList = occasion =>
  occasion?.includeInPartialList;

export const getOccasionIsNew = occasion => occasion?.new;
export const getOccasionIsDisabled = occasion => occasion?.disabled;

export const getOccasionByType = type =>
  getOccasions().find(occasion => getOccasionType(occasion) === type);

export const getSpecialOccasionDate = occasionName => {
  const occasionDate = new Date();

  switch (true) {
    case occasionName === OCCASION_TYPES.CHRISTMAS:
      occasionDate.setMonth(11, 25);
      return occasionDate;

    case occasionName === OCCASION_TYPES.VALENTINES:
      occasionDate.setMonth(1, 14);
      return occasionDate;

    case occasionName === OCCASION_TYPES.MOTHERS:
      // UK version
      // occasionDate.setMonth(2, 10);
      // US version
      occasionDate.setMonth(4, 12);
      // French version
      // occasionDate.setMonth(4, 26);
      return occasionDate;

    case occasionName === OCCASION_TYPES.FATHERS:
      occasionDate.setMonth(5, 16);
      return occasionDate;

    default:
      return null;
  }
};

export function replaceRecipientNamePlaceholder(
  value = "",
  recipientName = "",
  fallback = recipientNameFallback
) {
  return value.replace(recipientNamePlaceholder, recipientName || fallback);
}
