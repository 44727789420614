/* Libraries */
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
/* -Libraries */

/* Actions */
import { setMobileKeyboardOpen } from "redux/editor/actions";
/* -Actions */

/* Hooks */
import useOnResize from "hooks/useOnResize";
/* -Hooks */
import { isMobileDevice } from "kindeo-webgl/utils";
import { addRootClass, removeRootClass, setCssVar } from "utils/domUtils";

// write the app's remaining display height to a CSS variable
const setPageDisplayHeight = height => {
  setCssVar("--display-height", height + "px");
};

const MobileKeyboardMonitor = props => {
  const dispatch = useDispatch();
  const viewportHeight = useRef(window.innerHeight);

  const onResize = useCallback(
    e => {
      if (e) {
        if (isMobileDevice) {
          const calculatedRatio = e?.target.height / viewportHeight.current;

          // if the viewport has just decreased by 25% or more, the keyboard has taken that space
          if (calculatedRatio <= 0.75) {
            dispatch(setMobileKeyboardOpen(true));
            addRootClass("mobile-keyboard-open");
            setPageDisplayHeight(e.target.height);

            // add a one-time event listener for the next blur event which will dismiss the keyboard
            // the resize event for the visualViewport when the keyboard closes is delayed by
            // several hundred ms, so we can't rely on that
            document.addEventListener(
              "blur",
              () => {
                dispatch(setMobileKeyboardOpen(false));
                removeRootClass("mobile-keyboard-open");
                setPageDisplayHeight(window.innerHeight);
              },
              { capture: true, passive: true, once: true }
            );
          }

          // if the viewport has just increased by 25% or more, the keyboard has been removed
          // this is only if the blur handler above doesn't fire - I'm looking at Android here
          else if (calculatedRatio >= 1.25) {
            dispatch(setMobileKeyboardOpen(false));
            removeRootClass("mobile-keyboard-open");
            setPageDisplayHeight(window.innerHeight);
          }

          viewportHeight.current = e.target.height;
        }
      }
    },
    [dispatch]
  );

  useOnResize(onResize, 100, {}, window.visualViewport || window);

  useEffect(() => {
    setPageDisplayHeight(window.visualViewport.height);
  }, []);

  return null;
};

export default React.memo(MobileKeyboardMonitor);
