/* Libraries */
import React from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import ConfirmationModal from "modals/ConfirmationModal";
/* -Components */

/* Hooks */
/* -Hooks */

/* Selectors */
/* -Selectors */

/* Actions */
/* -Actions */

const DeleteRecordingModal = props => {
  const { message, onClose, onDelete, params, title } = props;

  return (
    <ConfirmationModal
      trackingId={"Delete Capture Modal"}
      {...params}
      {...{
        cancelBtnText: "Cancel",
        confirmBtnText: "Yes, delete it",
        message,
        onCancel: onClose,
        onConfirm: onDelete,
        onClose,
        title,
      }}
      isDestructive
      isOpen
    />
  );
};

DeleteRecordingModal.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default React.memo(DeleteRecordingModal);
