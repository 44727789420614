import {
  PRODUCT_TIERS,
  PAYMENT_TYPES,
  DELIVERY_TYPES,
} from "redux/payment/selectors";
import { formatCurrency } from "utils/numberUtils";

export const paymentsPerPage = 10;

export const getAccount = state => state.account;

export const getProfile = state => getAccount(state).profile;

export const getName = profile =>
  [getFirstName(profile), getLastName(profile)].join(" ");
export const getFirstName = profile => profile?.first_name;
export const getLastName = profile => profile?.last_name;
export const getEmail = profile => profile?.email;
export const getHideMyEmail = profile => profile?.hide_my_email;
export const isVerified = profile => profile?.email_verified;
export const getUsername = profile => profile?.username;
export const getStoryCount = profile => profile?.stories_saved;

export const getDigitalCreditsAvailable = profile =>
  profile?.credits_digital_available;
export const getDigitalCreditsUsed = profile => profile?.credits_digital_used;
export const getPostalCreditsAvailable = profile =>
  profile?.credits_postal_available;
export const getPostalCreditsUsed = profile => profile?.credits_postal_used;
export const hasBundleHistory = profile =>
  getDigitalCreditsAvailable(profile) + getDigitalCreditsUsed(profile) > 0 ||
  getPostalCreditsAvailable(profile) + getPostalCreditsUsed(profile) > 0;

export const isLoaded = profile => !!getUsername(profile);

export const getPayments = state => getAccount(state).payments;

export const getPaymentId = payment => payment?.id;
export const getPaymentCurrency = payment => payment?.currency;
export const getPaymentTotalAmount = payment => payment.amount_total / 100;
export const getPaymentSubtotalAmount = payment =>
  payment.amount_subtotal / 100;
export const getPaymentDiscountAmount = payment =>
  payment.amount_discount / 100;
export const getPaymentShippingAmount = payment =>
  payment.amount_shipping / 100;
export const getPaymentTaxAmount = payment => payment.amount_tax / 100;
export const getPaymentCardLast4Digits = payment => payment.card_last4;
export const getPaymentCardBrand = payment => payment.card_brand;
export const getPaymentDate = payment => payment?.created_on;
export const getPaymentOrderNo = payment => payment.id.split("-").pop();
export const getPaymentStory = payment => payment?.story;
export const getPaymentStoryId = payment => payment?.story_id;
export const getPaymentType = payment => payment?.payment_type;
export const getPaymentPostalOrder = payment => payment?.postal_order;
export const getPaymentProductTier = payment => payment?.product_tier;
export const getPaymentProductUgrade = payment => payment?.is_upgrade;
export const getPaymentBundleQuantity = payment => payment?.bundle_quantity;
export const getPaymentBundleType = payment => payment?.bundle_type;
export const getPaymentUsedCredit = payment => payment?.used_credit_type;

export const getPaymentProductName = payment => {
  let productName;
  switch (getPaymentType(payment)) {
    case PAYMENT_TYPES.CAPACITY:
      const purchasedProduct = getPaymentProductTier(payment);

      if (getPaymentProductUgrade(payment)) {
        productName = `Upgrade to ${purchasedProduct}`;
      } else {
        switch (purchasedProduct) {
          case PRODUCT_TIERS.STARTER:
            productName = "Starter";
            break;
          case PRODUCT_TIERS.UNLIMITED:
            productName = "Unlimited";
            break;
          default:
            productName = "Purchased item";
            break;
        }
      }
      break;
    case PAYMENT_TYPES.POSTAL:
      productName = "Send by post";
      break;
    case PAYMENT_TYPES.BUNDLE:
      if (getPaymentBundleType(payment) === DELIVERY_TYPES.DIGITAL) {
        productName = "Digital only";
      } else if (getPaymentBundleType(payment) === DELIVERY_TYPES.POSTAL) {
        productName = "Send by post";
      }
      break;
    default:
      productName = "Purchased item";
      break;
  }

  return productName;
};

export const getPaymentById = (payments, id) =>
  payments.find(payment => getPaymentId(payment) === id);

export const getFormatedPrice = (payment, value) => {
  const currency = getPaymentCurrency(payment);

  return formatCurrency(value, currency, false);
};
