/* Libraries */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import { BadgeText } from "components/_v2/BadgeText";
import Collapse from "components/Collapse";
/* -Components */

import styles from "./index.module.scss";

const FormFieldError = props => {
  const { children, className, error, errors, fieldName } = props;

  if (!fieldName && !error) {
    console.error("FormFieldError requires a fieldName or an error prop.");
    return null;
  }

  const currentError = fieldName ? errors[fieldName] : error;

  return (
    <Collapse show={!!currentError}>
      <>
        {currentError && (
          <BadgeText
            icon="alert-circle"
            size="medium"
            type="error"
            text={currentError.message}
            className={classnames("m-0 pt-025", styles.error, className)}
            alignLeft
          />
        )}
        {children}
      </>
    </Collapse>
  );
};

FormFieldError.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  fieldName: PropTypes.string,
};

export default FormFieldError;
