/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import { storyTokenTypes } from "redux/story/selectors";
/* -Selectors */

/* Actions */
import { fillMediaLibrary } from "redux/media/actions";
import { clearCurrentRole, setCurrentRole } from "redux/currentRole/actions";
import { getErrorStatus } from "utils/apiUtils";
/* -Actions */

export const actions = {
  updateUserRole: createAction("USERROLES_UPDATE"),
  userRolesFetched: createAction("USERROLES_FETCH_SUCCESS"),
};

export const fetchUserRoles = () => dispatch => {
  return axios.get(`/roles`).then(response => {
    const { roles } = response.data;
    dispatch(actions.userRolesFetched(roles));
    return roles;
  });
};

// fetch latest user role
// clear the current role
export const deleteStoryRole =
  (role_id, reassign_media = true) =>
  dispatch => {
    return axios
      .delete(`story/role/${role_id}`, {
        data: { reassign_media },
      })
      .then(() => {
        dispatch(fetchUserRoles());
        dispatch(clearCurrentRole());
      });
  };

export const getRoleMedia = roleId => dispatch => {
  return axios
    .get(`/role/${roleId}/media`)
    .then(result => {
      const { media } = result.data;
      dispatch(fillMediaLibrary(media));

      return media;
    })
    .catch(() => {
      console.error("failed to get library for role", roleId);
    });
};

// request a recipient role for an Open Kindeo ("save" to your home page)
export const saveKindeoAsRecipient = recipientToken => dispatch => {
  let newRole;

  return axios
    .post(`/story/${storyTokenTypes.recipientToken}/${recipientToken}/save`)
    .then(result => {
      newRole = result.data.role;
      dispatch(setCurrentRole(newRole));
      return dispatch(fetchUserRoles());
    })
    .then(() => {
      return newRole;
    })
    .catch(error => {
      // swallow the error result if the user already has a role
      if (getErrorStatus(error) === 403) {
        return null;
      }
      // return an error if there was any other problem
      throw error;
    });
};
