import { merge } from "../../../utils/deepMerge";

const noop = () => {};
const API = {
  env: {
    exportedCoversAssetsPath: "",
  },
  analytics: {},
  slideConfig: {
    getVariantColour: noop,
  },
  gif: {
    getOriginalMp4Version: noop,
    getOriginalStaticVersion: noop,
  },
  media: {
    mediaTypes: {},
  },
  music: {
    getTrackCover: noop,
  },
  occasions: {
    OCCASION_TYPES: {},
  },
  slide: {
    getExportedId: noop,
    getGiphyId: noop,
    getDescription: noop,
    getSlideData: noop,
    getSpotifyTrackId: noop,
    getSubtitle: noop,
    getTitle: noop,
    variantIsExported: noop,
  },
};

export const override = (api) => {
  merge(API, api);
};

export default API;
