/* LIbraries */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
/* -LIbraries */

/* Actions */
import { setLoaderAnimation, setPageLoader } from "redux/loaders/actions";
/* -Actions */

/* Selectors */
import * as loaderSelectors from "redux/loaders/selectors";
/* -Selectors */

/* Hooks */
import usePrevious from "hooks/usePrevious";
/* -Hooks */

import { pagesWithoutLoader } from "routes/PageRoutes";
import { matchAnyPath } from "utils/url";

const selector = createSelector(
  [loaderSelectors.isLoading, loaderSelectors.isPageLoading],
  (isLoading, isPageLoading) => {
    return {
      isLoading,
      isPageLoading,
    };
  }
);

const usePageLoader = respondToNavigation => {
  const dispatch = useDispatch();
  // the current display state of the loader
  const { isLoading, isPageLoading } = useSelector(selector);

  // the location which this hooks returns as a value
  const [loaderLocation, setLoaderLocation] = useState();
  // a copy of a pending location so that we can change loaderLocation in our own time
  const [delayedLoaderLocation, setDelayedLoaderLocation] = useState();
  // a flag to pause the update to loaderLocation until the loader is shown
  const [waitForLoader, setWaitForLoader] = useState(false);
  // a flag to indicate we should navigate without showing the loader
  const [navigateWithoutLoader, setNavigateWithoutLoader] = useState(false);

  // we need to keep track of the pathname changing which indicates page navigation
  // the location object will change when modals are shown or the state is changed
  const { pathname } = loaderLocation || {};
  const previousPathname = usePrevious(pathname);
  const previousDelayedLoaderLocation = usePrevious(delayedLoaderLocation);

  const optionallyShowLoader = useCallback(
    nextPathname => {
      // if the path has changed
      if (previousPathname !== nextPathname) {
        // this works out whether to show the page loader when navigating to the next page
        // these flags tell us whether the previous or the next page require a loader to be shown
        const prevPathHasNoLoader = !!matchAnyPath(
          previousPathname,
          pagesWithoutLoader
        );
        const nextPathHasNoLoader = !!matchAnyPath(
          nextPathname,
          pagesWithoutLoader
        );

        // does neither the previous page nor the next page uses the loader
        if (prevPathHasNoLoader && nextPathHasNoLoader) {
          setNavigateWithoutLoader(true);
        } else {
          setWaitForLoader(true);
          dispatch(setPageLoader(true));
        }
      }
    },
    [dispatch, previousPathname]
  );

  // wait for changes to delayedLoaderLocation, which will only happen
  // during page navigation, and show the page loader
  useEffect(() => {
    if (
      respondToNavigation &&
      delayedLoaderLocation &&
      delayedLoaderLocation !== previousDelayedLoaderLocation
    ) {
      optionallyShowLoader(delayedLoaderLocation.pathname);
    }
  }, [
    delayedLoaderLocation,
    optionallyShowLoader,
    previousDelayedLoaderLocation,
    respondToNavigation,
  ]);

  // wait for the loader to start before applying delayedLoaderLocation
  useEffect(() => {
    if (isLoading && waitForLoader) {
      setWaitForLoader(false);

      // when the loader is visible, scroll to the top of the page
      // before applying the new location
      window.setTimeout(() => {
        window.scrollTo(0, 0);
        setLoaderLocation(delayedLoaderLocation);
      }, loaderSelectors.pageLoaderFadeDuration);
    }
  }, [delayedLoaderLocation, isLoading, waitForLoader]);
  // respond to flag to navigate without showing the loader
  useEffect(() => {
    if (navigateWithoutLoader) {
      setNavigateWithoutLoader(false);
      setLoaderLocation(delayedLoaderLocation);
    }
  }, [delayedLoaderLocation, navigateWithoutLoader]);

  // simple method to hide the current page loader
  const stopLoader = useCallback(() => {
    // this additional delay ensures that if a hard reload happens on a page with no data loading
    // (e.g. home page) stopLoader() is not called before `setPageLoader(true)`
    window.setTimeout(() => {
      dispatch(setPageLoader(false));
      dispatch(setLoaderAnimation(null));
    }, loaderSelectors.pageLoaderFadeDuration);
  }, [dispatch]);

  // simple method to show the page loader with an optional animation
  // animation defaults to droplet
  const startLoader = useCallback(
    animation => {
      // if a valid animation has been provided, set it in the loader state
      if (
        Object.values(loaderSelectors.LOADER_ANIMATIONS).includes(animation)
      ) {
        dispatch(setLoaderAnimation(animation));
      }
      dispatch(setPageLoader(true));
    },
    [dispatch]
  );

  // if location.pathname changes then this is a page navigation which we should delay
  // otherwise update loaderLocation immediately
  const setLocation = useCallback(
    location => {
      if (location.pathname !== previousPathname) {
        setDelayedLoaderLocation(location);
      } else {
        setLoaderLocation(location);
      }
    },
    [previousPathname]
  );

  // return the loading status and a method to stop the loader
  // also return the local copy of the location object which trails the live location
  return {
    isLoading,
    isPageLoading,
    loaderLocation,
    setLocation,
    startLoader,
    stopLoader,
  };
};

export default usePageLoader;
