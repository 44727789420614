/* Libraries */
import React, { lazy, Suspense } from "react";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
/* -Components */

/* Hooks */
/* -Hooks */

const BannerComponent = lazy(() => import("./banner"));

const Banner = props => {
  return (
    <Suspense fallback={null}>
      <BannerComponent {...props} />
    </Suspense>
  );
};

export default React.memo(Banner);
