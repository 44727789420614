/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import * as accountSelectors from "redux/account/selectors";
import * as anonymousDataSelectors from "redux/anonymousData/selectors";
import * as authSelectors from "redux/auth/selectors";
import {
  authScenarios,
  authTokenTypes,
  verifyTypes,
} from "redux/auth/selectors";
import * as editorSelectors from "redux/editor/selectors";
import { PersistedIds } from "redux/persisted/selectors";
import { roleTokenTypes } from "redux/roles/selectors";
/* -Selectors */

/* Actions */
import { fetchProfile } from "redux/account/actions";
import { trackEvent } from "redux/analytics/actions";
import { completeContribution } from "redux/contributions/actions";
import {
  fetchGroupCurrentRole,
  fetchRecipientCurrentRole,
  markKindeoAsWatchedByRecipient,
} from "redux/currentRole/actions";
import { resetErrors } from "redux/errors/actions";
import { setPaymentDetails } from "redux/payment/actions";
import { setPersistedData } from "redux/persisted/actions";
import {
  adoptStory,
  fetchStory,
  setRecipientThankyouMessage,
} from "redux/story/actions";
import { fetchStoryRolesForRecipient } from "redux/storyRoles/actions";
import { saveKindeoAsRecipient } from "redux/userRoles/actions";
/* -Actions */

import analytics, { EVENTS } from "utils/analyticsUtils";
import * as apiUtils from "utils/apiUtils";
import { noop } from "utils/clientUtils";

const RENEW_AUTH_ENDPOINT = `/auth/refresh`;

export const actions = {
  loginSuccess: createAction("AUTH_LOGIN_SUCCESS"),
  logoutInit: createAction("AUTH_LOGOUT_INIT"),
  registerSuccess: createAction("AUTH_REGISTER_SUCCESS"),
  renewSuccess: createAction("AUTH_RENEW_SUCCESS"),
  setContextId: createAction("AUTH_SET_CONTEXTID"),
  setUsername: createAction("AUTH_SET_USERNAME"),
};

export const initAutoRenew = () => dispatch => {
  // install an interceptor for responses to handle
  // errors requiring re-authentication
  return axios.interceptors.response.use(null, error => {
    if (shouldAttemptAutoRenew(error)) {
      return dispatch(handleAutoRenew(error));
    }
    if (shouldBackoffAndRetry(error)) {
      return dispatch(handleBackoff(error));
    }
    // if (shouldWarnOfNetwork(error)) {
    //   return dispatch(createError(errorTypes.network));
    // }
    return Promise.reject(error);
  });
};

// Does this error require re-authentication
const shouldAttemptAutoRenew = error => {
  return (
    apiUtils.errorRequiresReauth(error) &&
    apiUtils.errorHasAuthHeaders(error) &&
    error.config.url !== RENEW_AUTH_ENDPOINT
  );
};

// Does this error require time before next request
const shouldBackoffAndRetry = error => {
  return apiUtils.errorRequiresBackoff(error);
};

// Does this error indicate a network denial - VPN?
// const shouldWarnOfNetwork = error => {
//   // previous request is in error.config
//   const wasPatch = error.config.method.toLowerCase() === "patch";
//   return apiUtils.errorHasNoCode(error) && wasPatch;
// };

// renew the API authentication and re-send the request
const handleAutoRenew = error => (dispatch, getState) => {
  return dispatch(renewAuth()).then(
    ({ payload: { [authTokenTypes.accessToken]: accessToken } }) => {
      const repeatRequest = error.config;
      // renewAuth sets the default authentication headers for new requests,
      // but because this request already exists we need to update the header
      repeatRequest.headers[apiUtils.AUTH_HEADER] = accessToken;
      return axios.request(repeatRequest);
    }
  );
};

// re-send the request after a backoff period
const handleBackoff = error => () => {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      const repeatRequest = error.config;
      resolve(axios.request(repeatRequest));
    }, 1000);
  });
};

// update the default authentication headers for new requests
export const setAuthHeader = accessToken =>
  (axios.defaults.headers.common[apiUtils.AUTH_HEADER] = accessToken || "");

// request a verify token of the specified type
export const requestVerification = payload => dispatch => {
  const { auth_scenario, use_code, verify_type } = payload;
  const analyticsParams = { auth_type: verify_type, auth_scenario };
  const analyticsEvent = use_code
    ? EVENTS.auth.codeRequested
    : EVENTS.auth.linkRequested;
  analytics.event(analyticsEvent, analyticsParams);

  return axios.post("/auth", payload).then(response => {
    dispatch(actions.setContextId(response.data.auth_context_id));
  });
};

// request a verify token of the specified type
export const requestSwitchDevice = payload => dispatch => {
  const { storyId } = payload;
  return axios.post("/auth/switch", { story_id: storyId }).then(response => {
    return response.data;
  });
};

export const resetAuthContext = () => dispatch => {
  dispatch(actions.setContextId(null));
};

// gather the appropriate auth verification data for different scenarios
const getVerificationData = state => {
  const authScenario = anonymousDataSelectors.getAuthScenario(state);
  const bundleQuantity = anonymousDataSelectors.getBundleQuantity(state);
  const bundleType = anonymousDataSelectors.getBundleType(state);
  const contributionId = anonymousDataSelectors.getContributionId(state);
  const email = anonymousDataSelectors.getEmail(state);
  const groupToken = anonymousDataSelectors.getGroupToken(state);
  const joinToken = anonymousDataSelectors.getJoinToken(state);
  const productTier = anonymousDataSelectors.getProductTier(state);
  const recipientToken = anonymousDataSelectors.getRecipientToken(state);
  const recipientThanks = anonymousDataSelectors.getRecipientThanks(state);
  const sendMethod = anonymousDataSelectors.getSendMethod(state);
  const storyId = anonymousDataSelectors.getStoryId(state);

  // always include email and auth_scenario
  let verificationData = {
    auth_scenario: authScenario,
    email,
  };

  switch (authScenario) {
    // creator adopting story
    case authScenarios.ANON_SAVE_EXIT:
    case authScenarios.CREATOR_PAYMENT:
    case authScenarios.CREATOR_SAVE:
    case authScenarios.CREATOR_SEND:
    case authScenarios.CREATOR_SETTINGS:
    case authScenarios.CREATOR_INVITE:
      verificationData = {
        ...verificationData,
        story_id: storyId,
      };

      if (authScenario === authScenarios.CREATOR_SEND && sendMethod) {
        verificationData = {
          ...verificationData,
          send_method: sendMethod,
        };
      }

      if (authScenario === authScenarios.CREATOR_PAYMENT && productTier) {
        verificationData = {
          ...verificationData,
          product_tier: productTier,
          ...(sendMethod ? { send_method: sendMethod } : {}),
        };
      }
      break;

    // contribution
    case authScenarios.CONTRIBUTION:
      verificationData = {
        ...verificationData,
        ...{
          contribution_id: contributionId,
          group_token: groupToken,
        },
      };
      break;

    // pre-authorised join link, for either open or private Kindeo
    case authScenarios.PRIVATE_JOIN:
      verificationData = {
        ...verificationData,
        join_token: joinToken,
        ...(recipientToken
          ? {
              recipient_token: recipientToken,
            }
          : {}),
        ...(groupToken
          ? {
              group_token: groupToken,
            }
          : {}),
      };
      break;

    // recipient save and recipient thanks
    case authScenarios.RECIPIENT_SAVE:
    case authScenarios.RECIPIENT_THANKS:
      verificationData = {
        ...verificationData,
        recipient_token: recipientToken,
        ...(recipientThanks
          ? {
              thanks_message: recipientThanks,
            }
          : {}),
      };
      break;

    // bundle purchase
    case authScenarios.BUNDLE_PAYMENT:
      verificationData = {
        ...verificationData,
        bundle_quantity: bundleQuantity,
        bundle_type: bundleType,
      };
      break;

    default:
      break;
  }

  return verificationData;
};

export const requestRegistrationVerification =
  useCode => (dispatch, getState) => {
    const state = getState();
    const firstName = anonymousDataSelectors.getFirstName(state);
    const lastName = anonymousDataSelectors.getLastName(state);

    return dispatch(
      requestVerification({
        ...getVerificationData(state),
        first_name: firstName,
        last_name: lastName,
        verify_type: verifyTypes.REGISTER,
        use_code: useCode,
      })
    );
  };
// registration with auth code for email verification
export const requestRegistrationCode = payload => dispatch => {
  return dispatch(requestRegistrationVerification(true));
};
// registration with magic link for email verification
export const requestRegistrationLink = payload => dispatch => {
  return dispatch(requestRegistrationVerification(false));
};

export const register = payload => (dispatch, getState) => {
  const state = getState();
  const defaultEmail = anonymousDataSelectors.getEmail(state);
  const defaultFirstName = anonymousDataSelectors.getFirstName(state);
  const defaultLastName = anonymousDataSelectors.getLastName(state);
  const {
    authToken,
    code,
    email = defaultEmail,
    joinToken,
    firstName = defaultFirstName,
    lastName = defaultLastName,
  } = payload;

  const requestParams = {
    email,
    first_name: firstName,
    last_name: lastName,
    ...(joinToken ? { [roleTokenTypes.joinToken]: joinToken } : {}),
    ...(code ? { verify_code: code } : {}),
    ...(authToken ? { auth_token: authToken } : {}),
  };

  return axios
    .post("/auth/register", requestParams)
    .then(response => {
      const { auth } = response.data;

      return dispatch(postRegisterActions(auth));
    })
    .catch(e => {
      analytics.event(EVENTS.auth.authFail, {
        auth_type: verifyTypes.REGISTER,
      });
      throw e;
    });
};

export const checkAccountAvailable = email => dispatch => {
  return axios.get(`/auth/check/${email}`).then(response => {
    return response.data.account.available;
  });
};

export const requestLoginVerification = useCode => (dispatch, getState) => {
  const state = getState();

  return dispatch(
    requestVerification({
      ...getVerificationData(state),
      verify_type: verifyTypes.LOGIN,
      use_code: useCode,
    })
  );
};
// login with auth code for email verification
export const requestLoginCode = payload => dispatch => {
  return dispatch(requestLoginVerification(true));
};
// login with auth code for email verification
export const requestLoginLink = payload => dispatch => {
  return dispatch(requestLoginVerification(false));
};

export const login = payload => (dispatch, getState) => {
  const defaultEmail = anonymousDataSelectors.getEmail(getState());
  const { code, email = defaultEmail, authToken } = payload;

  const requestParams = {
    email,
    ...(code ? { verify_code: code } : {}),
    ...(authToken ? { auth_token: authToken } : {}),
  };
  return axios
    .post("/auth/login", requestParams)
    .then(response => {
      return dispatch(postLoginActions(response.data.auth));
    })
    .catch(e => {
      analytics.event(EVENTS.auth.authFail, {
        auth_type: verifyTypes.LOGIN,
      });
      throw e;
    });
};

export const authWithGoogle = authToken => dispatch => {
  return axios
    .post("/auth/google", {
      token: authToken.credential,
    })
    .then(response => {
      const { auth, new_user } = response.data;
      const actions = new_user ? postRegisterActions : postLoginActions;
      return dispatch(actions(auth));
    })
    .catch(e => {
      analytics.event(EVENTS.auth.authFail, {
        auth_type: verifyTypes.GOOGLE,
      });
      throw e;
    });
};

const postRegisterActions = authObject => (dispatch, getState) => {
  // set auth data in stores
  dispatch(actions.registerSuccess(authObject));
  // add analytics tracking data
  dispatch(
    trackEvent("Account Created", {
      lifecyclestage: "lead",
    })
  );
  analytics.event(EVENTS.auth.authSuccess, {
    auth_type: verifyTypes.REGISTER,
  });

  // get profile data for stores
  return dispatch(setProfileData());
};

const postLoginActions = authObject => (dispatch, getState) => {
  dispatch(actions.loginSuccess(authObject));

  analytics.event(EVENTS.auth.authSuccess, {
    auth_type: verifyTypes.LOGIN,
  });

  // get profile data for stores
  return dispatch(setProfileData());
};

const setProfileData = () => dispatch => {
  return dispatch(fetchProfile()).then(profile => {
    const username = accountSelectors.getUsername(profile);
    return dispatch(actions.setUsername(username));
  });
};

export const logout = () => (dispatch, getState) => {
  const refreshToken = authSelectors.getAuthRefreshToken(getState());
  return axios
    .post("/auth/logout", {
      [authTokenTypes.refreshToken]: refreshToken,
    })
    .then(response => {
      setAuthHeader("");
      analytics.reset();
      return dispatch(actions.logoutInit());
    });
};

export const processPostAuthData = forwardTo => (dispatch, getState) => {
  const state = getState();

  const authScenario = anonymousDataSelectors.getAuthScenario(state);
  const contributionId = anonymousDataSelectors.getContributionId(state);
  const hasContributionInEditor = !editorSelectors.isEditorEmpty(
    editorSelectors.getEditor(state)
  );
  const didPlayAsRecipient =
    anonymousDataSelectors.getDidPlayAsRecipient(state);
  const groupToken = anonymousDataSelectors.getGroupToken(state);
  const recipientToken = anonymousDataSelectors.getRecipientToken(state);
  const recipientThanks = anonymousDataSelectors.getRecipientThanks(state);
  const storyId = anonymousDataSelectors.getStoryId(state);
  const productTier = anonymousDataSelectors.getProductTier(state);
  const sendMethod = anonymousDataSelectors.getSendMethod(state);

  switch (authScenario) {
    case authScenarios.CONTRIBUTION:
      // if there is a pending contribution ID
      // and if that contribution is not in the editor - i.e. we have
      // a fresh session and we are authed using a link,
      // complete the pending contribution
      // (authing with a code in the same session, the contribution is
      // completed in ContributionBuilderContext to show specifc success modals)
      if (contributionId && !hasContributionInEditor) {
        return dispatch(
          completeContribution({ contributionId, groupToken })
        ).then(contributionResult => {
          // ensure we have the new role data
          dispatch(fetchGroupCurrentRole(groupToken));
          return contributionResult;
        });
      }
      break;

    case authScenarios.ANON_SAVE_EXIT:
    case authScenarios.CREATOR_SAVE:
    case authScenarios.CREATOR_SEND:
    case authScenarios.CREATOR_SETTINGS:
    case authScenarios.CREATOR_INVITE:
      // if there is an anonymous story ID
      // adopt the story ID (and fetch user roles)
      if (storyId) {
        return dispatch(adoptStory(storyId)).then(() => {
          return dispatch(fetchStory(storyId));
        });
      }
      break;
    // Also need to adopt when saving story in payment flow
    case authScenarios.CREATOR_PAYMENT:
      // if there is an anonymous story ID
      // adopt the story ID (and fetch user roles)
      if (storyId) {
        return dispatch(adoptStory(storyId)).then(() => {
          // and set the current payment tier
          if (productTier) {
            dispatch(setPaymentDetails(productTier));
          }
          // set the send method if provided
          if (sendMethod) {
            dispatch(setPersistedData(PersistedIds.SendMethod, sendMethod));
          }
          return dispatch(fetchStory(storyId));
        });
      }
      break;

    case authScenarios.PRIVATE_JOIN:
      // after authenticating, accepting a join token is done
      // in SharedAuthFlow
      break;

    case authScenarios.RECIPIENT_THANKS:
    case authScenarios.RECIPIENT_SAVE:
      if (recipientToken) {
        return dispatch(saveKindeoAsRecipient(recipientToken))
          .catch(noop)
          .finally(() => {
            let returnPromise = Promise.resolve();

            // if there's a thank you message,
            // and if this hasn't been prompted by login using auth code
            // in same session as message was written...
            // then send thanks from here
            if (recipientThanks) {
              returnPromise = dispatch(
                setRecipientThankyouMessage(recipientToken, recipientThanks)
              );
            }
            // if we played the Kindeo while not having a role, mark it as played now
            if (didPlayAsRecipient) {
              dispatch(markKindeoAsWatchedByRecipient());
            }
            dispatch(fetchStoryRolesForRecipient(recipientToken));
            dispatch(fetchRecipientCurrentRole(recipientToken));

            return returnPromise;
          });
      }
      break;

    // if there's nothing to process
    default:
  }
  return Promise.resolve();
};

// a persistent promise, may be returned from several different requests to renewAuth
let reAuthPromise = null;
let reAuthInProgress = false;

// requests new authentication token from the API and then resolve a promise
export const renewAuth = payload => (dispatch, getState) => {
  // Get the refresh token
  const refreshToken = authSelectors.getAuthRefreshToken(getState());
  // if a re-auth is not already in progress, make an auth request to the API and return that promise
  if (!reAuthInProgress) {
    // prevent multiple re-auth requests happening
    reAuthInProgress = true;
    reAuthPromise = axios
      .post(RENEW_AUTH_ENDPOINT, {
        [authTokenTypes.refreshToken]: refreshToken,
      })
      .then(response => {
        // ensure the flag is cleared on success
        reAuthInProgress = false;
        dispatch(resetErrors());
        return dispatch(actions.renewSuccess(response.data.auth));
      })
      .catch(e => {
        reAuthInProgress = false;
        const authFailStatus = apiUtils.getErrorStatus(e);
        // if the auth call has failed because of an API error,
        // don't log out and try again, just do nothing
        if (![400, 401, 403].includes(authFailStatus)) {
          if (payload?.isRetry) {
            // return dispatch(createError(errorTypes.authRefresh));
            return;
          }
          return dispatch(renewAuth({ isRetry: true }));
        } else {
          // ensure a logout on genuine auth failure
          return dispatch(actions.logoutInit());
        }
      });
  }

  // return the auth promise
  // if a re-auth request is already in progress, this returns the existing promise
  return reAuthPromise;
};

export const fetchAuthContextStatus = contextId => () => {
  return axios.get(`/auth/context/${contextId}`).then(response => {
    return response.data.auth_context;
  });
};

export const deleteAccount = () => dispatch => {
  return axios.delete("/account").then(() => {
    // analytics.trackEvent("Deleted Profile");
    setAuthHeader("");
    return dispatch(actions.logoutInit());
  });
};
