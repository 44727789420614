/* Libraries */
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Components */
import Icon from "components/Icon";
/* -Components */

/* Selectors */
/* -Selectors */

/* Hooks */
/* -Hooks */

import { paths } from "routes/PageRoutes";
import styles from "./index.module.scss";

const BackNavItem = props => {
  const { showHomeLink } = props;
  const history = useHistory();
  const doNavigation = useCallback(() => {
    showHomeLink ? history.push(paths.home()) : history.goBack();
  }, [history, showHomeLink]);

  return (
    <button
      onClick={doNavigation}
      className={classnames(
        "button is-ghost",
        styles.settingsNav,
        styles.backNavEdit
      )}
    >
      <Icon icon="chevron" size="24" className={styles.backButton} />{" "}
      {showHomeLink ? "Home" : "Back"}
    </button>
  );
};

export default React.memo(BackNavItem);
