/* Libraries */
import React, { createContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";
/* -Libraries */

// This should only ever run once
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const StripeContext = createContext();

export const StripeContextProvider = React.memo(props => {
  return (
    <StripeContext.Provider value={stripePromise}>
      {props.children}
    </StripeContext.Provider>
  );
});

StripeContextProvider.propTypes = {
  children: PropTypes.element,
};
