/* Libraries */
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
/* -Components */

/* Hooks */
/* -Hooks */

const useButtonOrLink = props => {
  const { to } = props;

  let Element = "button";
  let elementProps = {};

  if (to) {
    Element = Link;
    elementProps = { to };
  } else {
    Element = "button";
    elementProps = { type: "button" };
  }

  return { Element, elementProps };
};

useButtonOrLink.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string }),
  ]),
};

export default useButtonOrLink;
