/**
  Choose the color themes for slides (if needed)
 */

import {
  durationOnPhoto,
  durationTransition,
} from "./photos/hover/photo-consts";
import { durationTransition as durationTransitionTitleFun } from "./title/cartoon/consts";

import { durationOnPhoto as durationOnPhotoSimple } from "./photos/simple/consts";
import { durationOnPhoto as durationOnPhotoPolaroid } from "./photos/polaroids/consts";
import { durationOnPhoto as durationOnPhotoFS } from "./photos/fullscreen/consts";
import { audioDuration } from "./music/default/consts";
import { getHeaderText } from "./news/paper/utils";
import { getMediaDuration } from "../utils/getMediaDuration";
import { durationAnimation as durationAnimationRocket } from "./title/rocket/consts";
import { durationAnimation as durationAnimationCake } from "./title/cake/consts";
import { durationAnimation as durationAnimationAstronaut } from "./title/astronaut/consts";
import { durationAnimation as durationAnimationHearts } from "./title/hearts/consts";
import { durationAnimation as durationAnimationBoat } from "./title/boat/consts";
import { durationAnimation as durationAnimationXmas } from "./title/xmas/consts";
import { durationAnimation as durationPaperHearts } from "./title/paperHeart/consts";
import { delayBeforeTextAppears } from "./title/trail/consts";
import { getReadingTimeSeconds } from "kindeo-webgl/utils";
import { slideTypes, slideVariants } from "../../../../consts/slides";
import API from "../API";

const audioType = slideTypes.AUDIO;
const creditType = slideTypes.CREDITS;
const dateType = slideTypes.DATE;
const locationType = slideTypes.LOCATION;
const musicType = slideTypes.MUSIC;
const newsType = slideTypes.NEWS;
const photoType = slideTypes.PHOTOS;
const textType = slideTypes.TEXT;
const gifType = slideTypes.GIF;
const titleType = slideTypes.TITLE;
const videoType = slideTypes.VIDEO;

const defaultPhotosDuration = {
  [slideVariants[photoType].HOVER]: durationOnPhoto,
  [slideVariants[photoType].POLAROIDS]: durationOnPhotoPolaroid,
  [slideVariants[photoType].SIMPLE]: durationOnPhotoSimple,
};

const durationsTitleBadge = {
  [slideVariants[titleType].CAKE]: durationAnimationCake,
  [slideVariants[titleType].ROCKET]: durationAnimationRocket,
  [slideVariants[titleType].ASTRONAUT]: durationAnimationAstronaut,
  [slideVariants[titleType].HEARTS]: durationAnimationHearts,
  [slideVariants[titleType].BOAT]: durationAnimationBoat,
  [slideVariants[titleType].XMAS]: durationAnimationXmas,
  [slideVariants[titleType].PAPER_HEARTS]: durationPaperHearts,
};

const slidesDurations = {
  [titleType]: slide => {
    if (slide.variant !== slideVariants[titleType].DEFAULT) {
      const descr = API.slide.getDescription(slide) || "";
      const title = API.slide.getTitle(slide) || "";

      const readingTimeTitle = getReadingTimeSeconds(title);
      const readingTimeDescription = getReadingTimeSeconds(descr);
      let totalDuration = readingTimeTitle;

      if (
        slide.variant === slideVariants[titleType].RIPPLES ||
        slide.variant === slideVariants[titleType].STARBURST
      ) {
        totalDuration += durationTransitionTitleFun;

        if (!!descr) {
          totalDuration += 1 + readingTimeDescription;
        }
        totalDuration += durationTransitionTitleFun;
      } else if (
        Object.keys(durationsTitleBadge).find(
          keyBadge => keyBadge === slide.variant
        )
      ) {
        totalDuration += durationsTitleBadge[slide.variant];
      } else if (slide.variant === slideVariants[titleType].TRAIL) {
        return readingTimeTitle + readingTimeDescription + 2;
      } else if (slide.variant === slideVariants[titleType].TRAIL_PHOTO) {
        const media = slide.media?.[0];

        const durationPhoto = media ? getMediaDuration(media, 3) : 0;

        return Math.max(
          durationPhoto,
          readingTimeTitle +
            readingTimeDescription +
            (media ? delayBeforeTextAppears : 0.1) +
            2
        );
      } else if (
        slide.variant === slideVariants[titleType].PARTICLES_SHAPE_PHOTO ||
        slide.variant === slideVariants[titleType].PARTICLES_SHAPE ||
        slide.variant === slideVariants[titleType].PARTICLES_TEXT
      ) {
        const minDur =
          slide.variant === slideVariants[titleType].PARTICLES_SHAPE ||
          slide.variant === slideVariants[titleType].PARTICLES_SHAPE_PHOTO
            ? 6
            : 7;
        const media = slide.media?.[0];

        let durationPhoto = media ? getMediaDuration(media, 0) : 0;

        return Math.max(readingTimeTitle + 2, minDur, durationPhoto); // TODO improve that based on a variable from the Flowers class, check the updateDuration function there
      }

      return totalDuration;
    }
    // texts reading duration
    const t =
      (API.slide.getSubtitle(slide) || "") + (API.slide.getTitle(slide) || "");
    // if hasText, add 2 second for the animation in the slide (see title.default)
    const hasText = !!t.trim();

    const media = slide.media?.[0];

    let durationPhoto = media ? getMediaDuration(media, 0) : 0;

    const readingDuration = getReadingTimeSeconds(t);
    const animationFadeoutDuration = 0.5;

    const isGif = durationPhoto > 0;

    const extraDuration = isGif ? 0 : 1;

    return (
      (!hasText
        ? Math.max(readingDuration, durationPhoto)
        : Math.max(
            2.25 + readingDuration,
            durationPhoto - animationFadeoutDuration
          ) + animationFadeoutDuration) + extraDuration
    );
  },
  [dateType]: () => {
    return 4;
  },
  [audioType]: slide => {
    return slide.media[0]?.duration;
  },
  [videoType]: slide => {
    const t =
      (API.slide.getSubtitle(slide) || "") + (API.slide.getTitle(slide) || "");
    const durText = !!t.trim() ? getReadingTimeSeconds(t) : 0;
    const durVideo = slide.media[0]?.duration;

    if (
      slide.variant === slideVariants[videoType].FILM ||
      slide.variant === slideVariants[videoType].SIMPLE
    ) {
      let delay = 0.0;
      if (durText > 0) {
        delay = Math.max(0.5, durText - durVideo);
      }
      return (
        delay +
        durVideo +
        (slide.variant === slideVariants[videoType].FULLSCREEN ? 0.5 : 0.2)
      );
    } else {
      return durVideo + durText;
    }
  },
  [locationType]: () => 6,
  [musicType]: () => audioDuration,
  [newsType]: slide => {
    const t =
      getHeaderText() +
      (API.slide.getSubtitle(slide) || "") +
      (API.slide.getTitle(slide) || "");
    return !!t.trim() ? getReadingTimeSeconds(t) : 0;
  },
  [photoType]: slide => {
    const t =
      (API.slide.getSubtitle(slide) || "") + (API.slide.getTitle(slide) || "");
    const durText = !!t.trim() ? getReadingTimeSeconds(t) : 0;

    let dur = 0;
    let defaultDuration =
      defaultPhotosDuration[slide.variant] || durationOnPhotoFS;

    const extraPerPhoto =
      slide.variant === slideVariants[photoType].HOVER &&
      slide.media?.length < 2
        ? durationTransition
        : 0;

    if (slide.media?.length) {
      dur = slide.media.reduce((prev, curr) => {
        return prev + extraPerPhoto + getMediaDuration(curr, defaultDuration);
      }, 0);
    }
    return durText + dur;
  },
  [textType]: slide => {
    return getReadingTimeSeconds(
      (API.slide.getSubtitle(slide) || "") +
        (API.slide.getTitle(slide) || "") +
        (API.slide.getDescription(slide) || "")
    );
  },
  [creditType]: slide => {
    const delimiter = "±±";
    const description = API.slide.getDescription(slide) || "";
    let names = (description && description.split(delimiter)) || [];
    const animationToText = 2;
    const fadeInLogo = 1;
    const stayOnLogo = 2;
    const fadeOutLogo = 0.5;
    const durationScroll = names.length * 0.3 + 0.5; // roughly 0.5 because it doesn't start to scoll instanstly
    const duration =
      animationToText + durationScroll + fadeInLogo + stayOnLogo + fadeOutLogo;
    return duration;
  },
  [gifType]: slide => {
    const { title = "" } = slide;

    //! if change here, change giphy slide too

    const durationReading = title ? getReadingTimeSeconds(title) : 0;
    const durationMedia = Math.max(slide.giphy_duration || 3, 3);
    const duration = Math.max(durationMedia, durationReading);

    return duration;
  },
};

export const getDurations = slides => {
  return (
    slides.reduce((prev, curr) => {
      const typeSlide = slidesDurations[curr.type];
      if (typeSlide) {
        if (typeof typeSlide === "function") {
          return prev + typeSlide(curr);
        } else if (
          typeSlide[curr.variant] &&
          typeof typeSlide[curr.variant] === "function"
        ) {
          return prev + typeSlide[curr.variant](curr);
        }
      }

      if (API.env.nodeEnv !== "production") {
        console.warn(
          `Duration not setup for variant ${curr.variant} of type ${curr.type}`
        );
      }

      return prev;
    }, 0) +
    (slides.length - 1) * 0.8
  );
};

export const secondsToReadableDuration = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secondsLeft = seconds - hours * 3600 - minutes * 60;
  return `${hours > 0 ? `${hours}:` : ""}${
    minutes < 10 ? `0${minutes}:` : `${minutes}:`
  }${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
};
