/* Libraries */
import React from "react";
import classnames from "classnames";
/* -Libraries */

/* Hooks */
import useButtonOrLink from "hooks/useButtonOrLink";
/* -Hooks */

/* Components */
import Icon from "components/Icon";
/* -Components */

import styles from "./index.module.scss";

const IconButton = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    color,
    icon,
    iconClassName,
    iconSize,
    onClick,
    overlay,
    to,
    ...restProps
  } = props;

  const { Element, elementProps } = useButtonOrLink({ to });

  return (
    <Element
      {...elementProps}
      {...restProps}
      ref={ref}
      className={classnames(
        `button is-unstyled`,
        styles.button,
        color ? `has-text-${color}` : "",
        {
          [styles.isOverlay]: !!overlay,
        },
        className
      )}
      onClick={onClick}
    >
      <Icon
        icon={icon}
        className={classnames(styles.icon, iconClassName)}
        size={iconSize}
      />
      {children}
    </Element>
  );
});

export default React.memo(IconButton);
