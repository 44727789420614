/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

/* -Actions */
/* -Actions */

export const actions = {
  updateStatusFetchSuccess: createAction("UPDATESTATUS_FETCH_SUCCESS"),
};

// get the latest update status from the API
export const getUpdateStatus = id => (dispatch, getState) => {
  const story = storySelectors.getStory(getState());
  // default to the current story ID
  id = id || storySelectors.getId(story);

  return axios.get(`/story/${id}/updates`).then(response => {
    dispatch(actions.updateStatusFetchSuccess(response.data.updates));
  });
};
