/* Libraries */
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import classnames from "classnames";
/* -Libraries */

/* Hooks */
import { useModalTrack } from "hooks/useAnalytics";
import usePageClipping from "hooks/usePageClipping";
import useModals from "hooks/useModals";
import usePrevious from "hooks/usePrevious";
/* -Hooks */

import { noop } from "utils/clientUtils";

import styles from "./index.module.scss";

const Modal = props => {
  const {
    afterClose = noop,
    children,
    className,
    clipped,
    doClose = noop,
    duration = 350,
    getModalRef = noop,
    isOpen,
    modalOnModal,
    narrow,
    overlayClassName,
    shouldCloseOnOverlayClick = true,
    trackingId,
    trackingProps = {},
    veryNarrow,
    wide,
    ...restProps
  } = props;

  const clipPage = usePageClipping(trackingId);
  useEffect(() => {
    clipPage(isOpen && !modalOnModal);

    return () => {
      if (!modalOnModal) {
        clipPage(false);
      }
    };
  }, [clipPage, isOpen, modalOnModal, trackingId]);

  // Analytics
  useModalTrack(trackingId, trackingProps, isOpen);

  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(
    shouldCloseOnOverlayClick
  );

  useEffect(() => {
    if (isOpen & shouldCloseOnOverlayClick) {
      setCloseOnOverlayClick(false);

      const timeout = window.setTimeout(() => {
        setCloseOnOverlayClick(true);
      }, duration);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [duration, isOpen, shouldCloseOnOverlayClick]);

  // detect whether this modal has been closed or whether it has been replaced by another modal
  const isOpenPrev = usePrevious(isOpen);
  const [requestedClose, setRequestedClose] = useState(false);
  useEffect(() => {
    if (!modalOnModal && isOpenPrev && !isOpen) {
      setRequestedClose(true);
    }
  }, [isOpen, isOpenPrev, modalOnModal]);

  // after the modal closes, call any cleanup
  // and if the close was requested then remove the URL params
  const { closeModal, getModal } = useModals();
  const modalName = useRef(getModal().modal);
  const onAfterClose = useCallback(() => {
    afterClose();
    if (requestedClose) {
      closeModal(modalName.current);
    }
  }, [afterClose, closeModal, requestedClose]);

  return (
    <ReactModal
      {...restProps}
      isOpen={isOpen}
      className={classnames(styles.modal, className, {
        [styles.clipped]: clipped,
        [styles.narrow]: narrow,
        [styles.veryNarrow]: veryNarrow,
        [styles.wide]: wide,
      })}
      closeTimeoutMS={duration}
      overlayClassName={classnames(styles.modalOverlay, overlayClassName)}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onRequestClose={doClose}
      onAfterClose={onAfterClose}
      contentRef={getModalRef}
      contentElement={(props, children) => (
        <section {...props}>{children}</section>
      )}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  afterClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  clipped: PropTypes.bool,
  getModalRef: PropTypes.func,
  doClose: PropTypes.func,
  duration: PropTypes.number,
  isOpen: PropTypes.bool,
  modalOnModal: PropTypes.bool, // aka other modals shouldn't close when this does
  narrow: PropTypes.bool,
  overlayClassName: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
  trackingId: PropTypes.string.isRequired,
  trackingProps: PropTypes.object,
  veryNarrow: PropTypes.bool,
  wide: PropTypes.bool,
};

export default React.memo(Modal);
