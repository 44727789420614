/* Libraries */
import { createReducer, current } from "@reduxjs/toolkit";
import isMatch from "lodash/isMatch";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
import * as actions from "./actions";
/* -Actions */

/* Selectors */
import { getType } from "redux/media/selectors";
import * as editorSelectors from "./selectors";
/* -Selectors */

const INITIAL_STATE = {
  authModalOpen: false,
  beingSubmitted: false,
  editingCover: false,
  editingFieldName: null,
  editingNewKindeo: false,
  hasBeenEdited: false,
  initialInputName: null,
  mobileKeyboardOpen: false,
  // preEditValues: {},
  slide: {
    id: undefined,
    date: null,
    description: null,
    exported_id: null,
    giphy_id: null,
    location_name: null,
    location_lat: null,
    location_lon: null,
    location_ne_lat: null,
    location_ne_lon: null,
    location_sw_lat: null,
    location_sw_lon: null,
    location_zoom: null,
    media: [],
    position: null,
    related_card_id: null,
    // the editor contains a slide_data_object, not slide_data directly.
    // This is an object keyed by a cover's exported_id
    // and saves the slide_data content arrays for all recent cover slides
    slide_data_object: {},
    spotify_track_id: null,
    subtitle: null,
    theme: null,
    title: null,
    title_age: null,
    type: null,
    variant: null,
  },
  slideshowLoaded: false,
  story: {
    greeting_from: null,
    greeting_to: null,
    greeting_message: null,
    occasion: null,
  },
};

const editStoryProperty = (state, { payload }) => {
  return {
    ...state,
    story: {
      ...state.story,
      ...payload,
    },
  };
};

const setOccasion = (state, { payload }) => {
  return {
    ...state,
    story: {
      ...state.story,
      occasion: payload,
    },
  };
};

const setType = (state, { payload }) => {
  return {
    state,
    slide: {
      ...state.slide,
      type: payload,
    },
  };
};

const setTheme = (state, { payload }) => {
  return {
    ...state,
    slide: {
      ...state.slide,
      theme: payload,
      related_card_id: null,
    },
  };
};

const setRelatedCardId = (state, { payload }) => {
  return {
    ...state,
    slide: {
      ...state.slide,
      theme: null,
      related_card_id: payload,
    },
  };
};

const setVariant = (state, { payload }) => {
  return {
    ...state,
    slide: {
      ...state.slide,
      variant: payload,
    },
  };
};

const setExportedId = (state, { payload }) => {
  return {
    ...state,
    slide: {
      ...state.slide,
      exported_id: payload,
    },
  };
};

const editSlideProperty = (state, { payload }) => {
  if (!payload) return;
  return {
    ...state,
    slide: { ...state.slide, ...payload },
  };
};

const clearSlideData = state => {
  return {
    ...state,
    slide: {
      ...state.slide,
      slide_data_object: INITIAL_STATE.slide.slide_data_object,
    },
  };
};

const setSlideData = (state, { payload }) => {
  if (!payload?.exportedId) {
    console.error("setSlideData: payload missing exportedId", payload);
    return;
  }

  const { exportedId, slideData: newSlideData } = payload;

  return {
    ...state,
    slide: {
      ...state.slide,
      slide_data_object: {
        ...state.slide.slide_data_object,
        [exportedId]: newSlideData,
      },
    },
  };
};

const editSlideData = (state, { payload }) => {
  const { exportedId, slideData } = payload;
  if (!exportedId || !slideData) {
    console.error(
      "editSlideData: payload missing exportedId or slideData",
      payload
    );
    return;
  }

  // convert the simple object into an array of key/value pairs
  const slideDataKeyValuePairs = Object.entries(slideData).map(pair => {
    return { key: pair[0], value: pair[1] };
  });

  const slide = editorSelectors.getSlide(state);
  const currentSlideDataRef = editorSelectors.getSlideData(slide, exportedId);
  // the ref might not be an Immer draft object,
  // it may be a default value of an empty array

  let currentSlideDataArray;
  try {
    currentSlideDataArray = current(currentSlideDataRef);
  } catch {
    currentSlideDataArray = currentSlideDataRef;
  }

  const dataIsUnchanged = isMatch(
    currentSlideDataArray,
    slideDataKeyValuePairs
  );

  if (dataIsUnchanged) return;

  const mergedSlideData = currentSlideDataArray.map(currentPair => {
    const updatedKeyValue = slideDataKeyValuePairs.find(
      slidePair => slidePair.key === currentPair.key
    );

    return updatedKeyValue ? updatedKeyValue : currentPair;
  });

  return {
    ...state,
    slide: {
      ...state.slide,
      slide_data_object: {
        ...state.slide.slide_data_object,
        [exportedId]: mergedSlideData,
      },
    },
  };
};

const editSlideStyle = (state, { payload }) => {
  if (!payload) return;
  return {
    ...state,
    slide: { ...state.slide, style: { ...state.slide.style, ...payload } },
  };
};

const setSlideMedia = (state, { payload }) => {
  const media = [];
  payload.forEach(item => {
    media.push({ ...item, position: media.length + 1 });
  });
  return {
    ...state,
    slide: { ...state.slide, media },
  };
};

const setGiphyId = (state, { payload }) => {
  return {
    ...state,
    slide: { ...state.slide, giphy_id: payload },
  };
};

const setSpotifyTrack = (state, { payload }) => {
  return {
    ...state,
    slide: { ...state.slide, spotify_track_id: payload },
  };
};

const clearSlideMediaType = (state, { payload: mediaType }) => {
  return {
    ...state,
    slide: {
      ...state.slide,
      media: state.slide.media.filter(item => getType(item) !== mediaType),
    },
  };
};

const setEditorBeingSubmitted = (state, { payload }) => {
  return { ...state, beingSubmitted: payload };
};

const setEditorSlideshowLoaded = (state, { payload }) => {
  return { ...state, slideshowLoaded: payload };
};

const setAuthModalOpen = (state, { payload }) => ({
  ...state,
  authModalOpen: payload.authModalOpen,
});

const setEditsMade = (state, { payload }) => ({
  ...state,
  hasBeenEdited: payload,
});

const setEditingNewKindeo = (state, { payload }) => {
  return {
    ...state,
    editingNewKindeo: payload,
  };
};

const setEditingCover = (state, { payload }) => {
  return {
    ...state,
    editingCover: payload,
  };
};

const setInitialInputName = (state, { payload }) => ({
  ...state,
  initialInputName: payload,
});

const setEditingFieldName = (state, { payload }) => ({
  ...state,
  editingFieldName: payload,
});

const setMobileKeyboardOpen = (state, { payload }) => {
  return {
    ...state,
    mobileKeyboardOpen: payload,
  };
};

const clearEditorData = state => ({
  ...INITIAL_STATE,
  editingNewKindeo: state.editingNewKindeo,
  editingCover: state.editingCover,
});

// const setPreEditValues = (state, { payload }) => ({
//   ...state,
//   preEditValues: payload,
// });

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.clearSlideMediaType]: clearSlideMediaType,
  [actions.clearSlideData]: clearSlideData,
  [actions.clearEditorData]: clearEditorData,
  [actions.editSlideData]: editSlideData,
  [actions.editSlideProperty]: editSlideProperty,
  [actions.editSlideStyle]: editSlideStyle,
  [actions.editStoryProperty]: editStoryProperty,
  [actions.setAuthModalOpenValue]: setAuthModalOpen,
  [actions.setEditorBeingSubmitted]: setEditorBeingSubmitted,
  [actions.setEditsMade]: setEditsMade,
  [actions.setEditorSlideshowLoaded]: setEditorSlideshowLoaded,
  [actions.setGiphyId]: setGiphyId,
  [actions.setOccasion]: setOccasion,
  // [actions.setPreEditValues]: setPreEditValues,
  [actions.setType]: setType,
  [actions.setRelatedCardId]: setRelatedCardId,
  [actions.setEditorSlideData]: setSlideData,
  [actions.setSlideMedia]: setSlideMedia,
  [actions.setSpotifyTrack]: setSpotifyTrack,
  [actions.setTheme]: setTheme,
  [actions.setVariant]: setVariant,
  [actions.setExportedId]: setExportedId,
  [actions.setInitialInputName]: setInitialInputName,
  [actions.setEditingNewKindeo]: setEditingNewKindeo,
  [actions.setEditingCover]: setEditingCover,
  [actions.setEditingFieldName]: setEditingFieldName,
  [actions.setMobileKeyboardOpen]: setMobileKeyboardOpen,
  [actions.resetEditor]: reset,
  [authActions.logoutInit]: reset,
});
