/* Libraries */
import { actions } from "./actions";
import { createReducer, current } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import {
  getId,
  getThumbnailUrl,
  isGeneratingThumbnail,
} from "redux/slide/selectors";
import { getSlidesCount, getStorySlideById } from "redux/story/selectors";
/* -Selectors */

const INITIAL_STATE = {
  slides: [],
};

const setStory = (state, { payload }) => {
  // do all this to ensure that current generatingThumbnail state is preserved
  const currentStory = current(state);
  const currentSlideCount = getSlidesCount(currentStory);
  const slides = payload.slides
    .sort((a, b) => a.position - b.position)
    .map(slide => {
      const stateSlide = getStorySlideById(currentStory, getId(slide));
      if (currentSlideCount > 0 && isGeneratingThumbnail(stateSlide)) {
        return {
          ...slide,
          generatingThumbnail: true,
          thumbnail_url: getThumbnailUrl(stateSlide),
        };
      }
      return slide;
    });

  return {
    hasChanged: false,
    ...payload,
    slides,
    unwrap_shown: state.unwrap_shown,
  };
};

const modifyStory = (state, { payload }) => ({
  ...state,
  hasChanged: false,
  ...payload,
});

const setStoryChanged = state => ({
  ...state,
  hasChanged: true,
});

const setSlide = (state, { payload }) => {
  return {
    ...state,
    slides: state.slides.map(slide => {
      if (getId(slide) === getId(payload)) {
        return { ...slide, ...payload };
      }
      return slide;
    }),
  };
};

const setOrder = (state, { payload }) => {
  const orderedSlides = payload.slides;
  return {
    ...state,
    slides: orderedSlides.map(slide => {
      const originalSlide = state.slides.find(oSlide => oSlide.id === slide.id);
      return { ...originalSlide, position: slide.position };
    }),
  };
};

const markSlideSeen = (state, { payload }) => {
  return {
    ...state,
    slides: state.slides.map(slide =>
      slide.id === payload ? { ...slide, seen: true } : slide
    ),
  };
};

const clearSlideThumbnail = (state, { payload }) => ({
  ...state,
  slides: state.slides.map(slide =>
    slide.id === payload
      ? {
          ...slide,
          thumbnail_url: null,
          thumbnail_id: null,
          generatingThumbnail: true,
        }
      : slide
  ),
});

// guard against errors here because slides may have been cleared from state
// by exiting creator flow for this story before the thumb was generated
const setThumbnailComplete = (state, { payload }) => {
  if (state.slides) {
    return {
      ...state,
      slides: state.slides.map(slide => {
        if (getId(slide) === getId(payload)) {
          return { ...slide, ...payload, generatingThumbnail: false };
        }
        return slide;
      }),
    };
  }
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.clearStory]: reset,
  [actions.clearSlideThumbnail]: clearSlideThumbnail,
  [actions.storyFetchSuccess]: setStory,
  [actions.updateSlide]: setSlide,
  [actions.slideSeenSuccess]: markSlideSeen,
  [actions.createThumbnailSuccess]: setThumbnailComplete,
  [actions.updateLocalStory]: modifyStory,
  [actions.storyOrderSuccess]: setOrder,
  [actions.setStoryChanged]: setStoryChanged,
  [authActions.logoutInit]: reset,
});
