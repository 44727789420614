//https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
// a non robust way (but sufficient for how it's used in our project at the moment) to deep merge 2 objects

export const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge(target[key], source[key]));
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};
