/* Libraries */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import Modal from "components/Modal";
import ModalContents from "components/ModalContents";
import ModalHeader from "components/ModalHeader";
/* -Components */

/* Hooks */
import useModalDisplay from "hooks/useModalDisplay";
import { noop } from "utils/clientUtils";
/* -Hooks */

const EmptyModal = props => {
  const { doClose, duration, isOpen, trackingId } = useModalDisplay();

  const { onClose = noop, render = noop, title } = props;

  const onCloseModal = useCallback(() => {
    onClose();
    doClose();
  }, [doClose, onClose]);

  return (
    <Modal
      trackingId={trackingId || title}
      {...{ doClose: onCloseModal, duration, isOpen, veryNarrow: true }}
    >
      <ModalHeader title={title} onClose={onCloseModal} />
      <ModalContents className="has-text-centered">
        {render(doClose)}
      </ModalContents>
    </Modal>
  );
};

EmptyModal.propTypes = {
  onClose: PropTypes.func,
  render: PropTypes.func,
  title: PropTypes.string,
  trackingId: PropTypes.string,
};

export default React.memo(EmptyModal);
