/* Libraries */
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
/* -Components */

/* Hooks */
import useModals from "hooks/useModals";
/* -Hooks */

import { modals } from "routes/ModalRoutes";
import styles from "./index.module.scss";

const TermsAgreement = props => {
  const { className, onClick } = props;

  const { linkModal } = useModals();

  return (
    <div className={classnames(styles.termsAgreement, "body-small", className)}>
      By using Kindeo, you are agreeing to our{" "}
      <Link
        className="is-unstyled is-teal6 is-bold"
        to={linkModal(modals.terms())}
        onClick={onClick}
      >
        Terms
      </Link>{" "}
      &amp;{" "}
      <Link
        className="is-unstyled is-teal6 is-bold"
        to={linkModal(modals.privacy())}
        onClick={onClick}
      >
        Privacy Policy
      </Link>
      .
    </div>
  );
};

TermsAgreement.propTypes = { onClick: PropTypes.func };

export default React.memo(TermsAgreement);
