/* Libraries */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
/* -Libraries */

/* Components */
import Icon from "components/Icon";
/* -Components */

/* Hooks */
import useButtonOrLink from "hooks/useButtonOrLink";
/* -Hooks */

import styles from "./index.module.scss";

const IconButton = React.forwardRef((props, ref) => {
  const {
    borderless,
    children,
    className,
    icon,
    iconClassName,
    iconSize,
    iconOnly,
    to,
    ...otherProps
  } = props;

  const { Element, elementProps } = useButtonOrLink({ to });

  return (
    <Element
      {...elementProps}
      {...otherProps}
      className={classnames(
        styles.iconButton,
        "is-unstyled button",
        iconOnly ? `${styles.borderless} p-05` : "",
        className,
        { [styles.borderless]: borderless || iconOnly }
      )}
      ref={ref}
    >
      <Icon className={iconClassName} icon={icon} size={iconSize} />
      {children}
    </Element>
  );
});

IconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.string,
  iconOnly: PropTypes.bool,
  to: PropTypes.string,
};

export default React.memo(IconButton);
