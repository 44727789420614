/* Libraries */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import IconBackButton from "components/IconBackButton";
import IconCloseButton from "components/IconCloseButton";
/* -Components */

import styles from "./index.module.scss";

const ModalHeader = props => {
  const { className, onBack, renderBackButton, onClose, title } = props;

  return (
    <div className={classnames(styles.container, className)}>
      <h1 className={styles.title}>{title}</h1>
      {renderBackButton
        ? renderBackButton({ className: styles.back })
        : onBack && (
            <IconBackButton
              className={styles.back}
              onClick={onBack}
              text="Back"
            />
          )}
      {onClose && (
        <IconCloseButton className={styles.close} onClick={onClose} />
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  renderBackButton: PropTypes.func,
  title: PropTypes.node,
};

export default React.memo(ModalHeader);
