/* Libraries */
import React from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import ConfirmationModal from "modals/ConfirmationModal";
/* -Components */

const CancelUploadConfirmationModal = props => {
  const { isOpen, onCancel, onClose, onConfirm } = props;

  return (
    <ConfirmationModal
      title={"Hold on a sec"}
      message="Are you sure you want to leave? If you exit now, your upload will be lost"
      trackingId="Cancel Media Upload"
      confirmBtnText="Yes, exit"
      cancelBtnText="Cancel"
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
      isDestructive
      modalOnModal
    />
  );
};

CancelUploadConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default React.memo(CancelUploadConfirmationModal);
