/* Libraries */
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
/* -Libraries */

/* Hooks */
import usePrevious from "./usePrevious";
import useTabFocus from "./useTabFocus";
/* -Hooks */

import { matchAnyPath } from "utils/url";
import analytics from "utils/analyticsUtils";

export const usePageTrack = (trackingId, properties) => {
  const tabFocused = useTabFocus();

  const trackPage = useCallback(() => {
    if (trackingId && tabFocused) {
      analytics.page(trackingId, properties);
    }
  }, [properties, tabFocused, trackingId]);

  const trackingIdPrev = usePrevious(trackingId);
  useEffect(() => {
    if (trackingIdPrev !== trackingId) {
      trackPage();
    }
  }, [trackingId, trackingIdPrev, trackPage]);
};

export const useEventTrack = trackingId => {
  useEffect(() => {
    trackingId && analytics.event(trackingId);
  }, [trackingId]);
};

export const useCurrentPathTrack = (analyticsMessages, params) => {
  const tabFocused = useTabFocus();
  const { pathname } = useLocation();

  const trackPath = useCallback(() => {
    if (tabFocused) {
      const result = matchAnyPath(pathname, Object.keys(analyticsMessages));
      if (result) {
        const message = analyticsMessages[result.path];
        message && analytics.event(message, params);
      }
    }
  }, [analyticsMessages, tabFocused, params, pathname]);

  // when mounting, track pathname
  const pathnamePrev = usePrevious(pathname);
  useEffect(() => {
    if (!pathnamePrev && pathname !== pathnamePrev) {
      trackPath();
    }
  }, [pathname, pathnamePrev, trackPath]);
};

export const useModalTrack = (trackingId, trackingProps, isOpen) => {
  const tabFocused = useTabFocus();

  const trackModal = useCallback(() => {
    if (trackingId && isOpen && tabFocused) {
      analytics.event(trackingId, trackingProps);
    }
  }, [isOpen, tabFocused, trackingId, trackingProps]);

  const isOpenPrev = usePrevious(isOpen);
  useEffect(() => {
    if (isOpen !== isOpenPrev) {
      trackModal();
    }
  }, [isOpen, isOpenPrev, trackModal]);
};
