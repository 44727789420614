/* Libraries */
import React, { lazy, Suspense, useEffect, useState } from "react";
import classnames from "classnames";
/* -Libraries */

/* Components */
/* -Components */

import styles from "./index.module.scss";

const AddMediaPanel = lazy(() => import("./AddMedia"));
const CustomisePanel = lazy(() => import("./Customise"));
const LibraryPanel = lazy(() => import("./Library"));
const TypeSelectorPanel = lazy(() => import("./TypeSelector"));

export const panels = {
  TYPESELECTOR: "typeselector",
  ADDMEDIA: "addmedia",
  LIBRARY: "library",
  CUSTOMISE: "customise",
};

const SlidePanel = React.memo(
  React.forwardRef((props, ref) => {
    const { children, className } = props;
    const [preventInteraction, preventInteractionSetter] = useState(true);

    useEffect(() => {
      const timeout = window.setTimeout(() => {
        preventInteractionSetter(false);
      }, 600);

      return () => {
        window.clearTimeout(timeout);
      };
    }, []);

    return (
      <div
        className={classnames(styles.panel, className, {
          [styles.preventInteraction]: preventInteraction,
        })}
      >
        {children}
      </div>
    );
  })
);

SlidePanel.Customise = React.memo(
  React.forwardRef((props, ref) => {
    const { className, ...restProps } = props;

    return (
      <SlidePanel className={classnames(styles.customise, className)}>
        <Suspense fallback={null}>
          <CustomisePanel {...restProps} />
        </Suspense>
      </SlidePanel>
    );
  })
);

SlidePanel.AddMedia = React.memo(
  React.forwardRef((props, ref) => {
    const { className, ...restProps } = props;

    return (
      <SlidePanel className={classnames(styles.addMedia, className)}>
        <Suspense fallback={null}>
          <AddMediaPanel {...restProps} />
        </Suspense>
      </SlidePanel>
    );
  })
);

SlidePanel.Library = React.memo(
  React.forwardRef((props, ref) => {
    const { className, ...restProps } = props;

    return (
      <SlidePanel className={classnames(styles.library, className)}>
        <Suspense fallback={null}>
          <LibraryPanel className={styles.libraryPanel} {...restProps} />
        </Suspense>
      </SlidePanel>
    );
  })
);

SlidePanel.TypeSelector = React.memo(
  React.forwardRef((props, ref) => {
    const { className, ...restProps } = props;

    return (
      <div className={styles.typeSelectorContainer}>
        <div className={styles.typeSelectorTitle}>
          <h4 className="h5 ph-1 has-text-centered">Add your message</h4>
        </div>
        <SlidePanel
          className={classnames(className, styles.typeSelector)}
          ref={ref}
        >
          <Suspense fallback={null}>
            <TypeSelectorPanel {...restProps} />
          </Suspense>
        </SlidePanel>
      </div>
    );
  })
);

export default SlidePanel;
