export const themes = [
  {
    name: "rocket-1",
    text: { color: "0xffffff" },
    background: {
      color: "0x400049",
      properties: {
        type: "Dots",
        alphaMin: 0.2,
        alphaGlobal: 0.07608695652173914,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.06380434782608695,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 71, g: 0, b: 81 },
          { r: 71, g: 0, b: 81 },
        ],
        colorsBackLayer: [
          { r: 17.920349121093743, g: 164.58064777271167, b: 191.150390625 },
          { r: 149.16619194878479, g: 9.49713134765626, b: 168.837890625 },
        ],
        colorsMidLayer: [
          {
            r: 110.79483145254632,
            g: 15.526611328125005,
            b: 124.21289062499999,
          },
          { r: 71, g: 0, b: 81 },
        ],
        colorsFrontLayer: [
          { r: 127.91196469907412, g: 0, b: 145.927734375 },
          { r: 70.91095890410958, g: 0, b: 80.8828125 },
        ],
        shadowOffset: 0.05,
        shadowOpacity: 0.14999999999999997,
        deformationFrequency: 2.5000000000000004,
        deformationAmplitude: 2.4000000000000004,
        deformationSpeed: 3,
        effect: {},
      },
      scene: {
        type: "RocketScene",
        ship: { color: 16720326 },
        trail: { color: "#fcb8ff" },
      },
    },
  },
  // {
  //   text: { color: "0xffffff" },
  //   background: {
  //     color: "0x011836",
  //     properties: {
  //       type: "Dots",
  //       alphaMin: 0.2,
  //       alphaGlobal: 0,
  //       scaleValue: 1.532608695652174,
  //       alphaValue: 0.43478260869565216,
  //       radius: 0.05304347826086957,
  //       columnWidth: 30.543478260869563,
  //       color: [1, 1, 1],
  //     },
  //   },
  //   scene: {
  //     miniSceneColor: 16777215,
  //     type: "PaperCutScene",
  //     layers: {
  //       colorsBaseLayer: [
  //         { r: 17.91412353515625, g: 111.5456793501571, b: 114.650390625 },
  //         { r: 1, g: 28, b: 64 },
  //       ],
  //       colorsBackLayer: [
  //         { r: 136.962890625, g: 133.0182668325063, b: 4.28009033203125 },
  //         { r: 27.987121582031246, g: 121.025390625, b: 37.500494137325816 },
  //       ],
  //       colorsMidLayer: [
  //         {
  //           r: 72.99999999999999,
  //           g: 144.21621621621617,
  //           b: 165.99999999999997,
  //         },
  //         { r: 217, g: 139, b: 182 },
  //       ],
  //       colorsFrontLayer: [
  //         { r: 87.90142801645642, g: 88.552734375, b: 27.6727294921875 },
  //         { r: 1, g: 28, b: 64 },
  //       ],
  //       shadowOffset: 0.28,
  //       shadowOpacity: 0.14999999999999997,
  //       deformationFrequency: 2.1,
  //       deformationAmplitude: 3.3000000000000003,
  //       deformationSpeed: 3,
  //       effect: {},
  //     },
  //     scene: {
  //       type: "RocketScene",
  //       ship: { color: 16762880 },
  //       trail: { color: "#fce979" },
  //     },
  //   },
  // },
  {
    name: "rocket-2",
    text: { color: "0xffffff" },
    background: {
      color: "0x9fd3f5",
      properties: {
        type: "Squares",
        alpha: 0.13043478260869565,
        size: { x: 0.2, y: 0.2 },
        columnWidth: 100,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 86.55743408203126, g: 154.29803466796886, b: 200.712890625 },
          { r: 119.53685302734374, g: 179.05615006331567, b: 219.837890625 },
        ],
        colorsBackLayer: [
          { r: 155.88431396484373, g: 207.07517264229918, b: 242.150390625 },
          { r: 129.09375, g: 203.80734890109903, b: 255 },
        ],
        colorsMidLayer: [
          { r: 187.0701416015625, g: 221.64660805503098, b: 245.337890625 },
          { r: 164.6480712890625, g: 214.42142562027823, b: 248.525390625 },
        ],
        colorsFrontLayer: [
          { r: 168.90076904296876, g: 215.79589132791045, b: 247.927734375 },
          { r: 164.15625, g: 218.2954545454546, b: 255 },
        ],
        shadowOffset: 0.26,
        shadowOpacity: 0.04999999999999996,
        deformationFrequency: 1.5999999999999999,
        deformationAmplitude: 2,
        deformationSpeed: 3,
        effect: {},
      },
      scene: {
        type: "RocketScene",
        ship: { color: 6054655 },
        trail: { color: "#cdba50" },
      },
    },
  },
  {
    name: "rocket-3",
    text: { color: "0x8a4939" },
    background: {
      color: "0xfff1b6",
      properties: {
        type: "Dots",
        alphaMin: 0.2,
        alphaGlobal: 0,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 255, g: 230, b: 126 },
          { r: 255, g: 230, b: 126 },
        ],
        colorsBackLayer: [
          { r: 255, g: 245.94966216216213, b: 126 },
          { r: 229.400390625, g: 222.19828533793603, b: 126.17021484375 },
        ],
        colorsMidLayer: [
          { r: 242.150390625, g: 225.43215871411695, b: 155.88431396484373 },
          { r: 245.337890625, g: 224.7266550463299, b: 141.069287109375 },
        ],
        colorsFrontLayer: [
          { r: 255, g: 230.24229452054794, b: 125.90625 },
          { r: 255, g: 248.890625, b: 223.125 },
        ],
        shadowOffset: 0.020000000000000004,
        shadowOpacity: 0.14999999999999997,
        deformationFrequency: 1.5999999999999999,
        deformationAmplitude: 3.3000000000000003,
        deformationSpeed: 3,
        effect: {},
      },
      scene: {
        type: "RocketScene",
        ship: { color: 16751104 },
        trail: { color: "#f9947b" },
      },
    },
  },
];
