/* Libraries */
import React, { useEffect } from "react";
/* -Libraries */

/* Actions */
/* -Actions */

/* Hooks */
import useOnResize from "hooks/useOnResize";
import { setCssVar } from "utils/domUtils";
/* -Hooks */

// write the app's available pixel height to a CSS variable
const setPageHeight = height => {
  setCssVar("--full-height", height + "px");
};

const onResize = e => {
  if (e) {
    setPageHeight(e.target.innerHeight);
  }
};

const PageHeightMonitor = props => {
  useOnResize(onResize, 100);

  useEffect(() => {
    setPageHeight(window.innerHeight);
  }, []);

  return null;
};

export default React.memo(PageHeightMonitor);
