/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
/* -Actions */

const INITIAL_STATE = {
  playing: false
};

const playKindeo = (state, { payload }) => {
  return {
    ...state,
    playing: true
  };
};

const stopKindeo = (state, { payload }) => ({
  ...state,
  playing: false
});

export default createReducer(INITIAL_STATE, {
  [actions.play]: playKindeo,
  [actions.stop]: stopKindeo
});
