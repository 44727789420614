/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import * as rolesSelectors from "redux/roles/selectors";
/* -Selectors */

const INITIAL_STATE = [];

const setRoles = (state, { payload }) => payload;

const updateRole = (state, { payload }) => [
  ...state.map(role => {
    return rolesSelectors.getId(role) !== rolesSelectors.getId(payload)
      ? role
      : { ...role, ...payload };
  }),
];

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.userRolesFetched]: setRoles,
  [actions.updateUserRole]: updateRole,
  [authActions.logoutInit]: reset,
});
