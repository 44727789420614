// different audio channels, added here so it's easier
// but channels can be added dynamically if needed
export const channelsIds = {
  music: "music",
  sfx: "sfx",
  audio: "audio",
};

export const getIsPlaying = state => {
  return state.webgl.playing;
};
