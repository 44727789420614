export const themes = [
  {
    name: "astronaut-0",
    text: { color: "0x6b93db" },
    background: {
      color: "0xf8f7f3",
      properties: {
        type: "Dots",
        alphaMin: 0.2,
        alphaGlobal: 0,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 251, g: 255, b: 186 },
          { r: 123.1283935546875, g: 137.11546150826118, b: 193.142578125 },
        ],
        colorsBackLayer: [
          { r: 251, g: 255, b: 186 },
          { r: 148, g: 234.2627252252253, b: 255 },
        ],
        colorsMidLayer: [
          { r: 251, g: 255, b: 186 },
          { r: 255, g: 133.875, b: 228.93357263513533 },
        ],
        colorsFrontLayer: [
          { r: 255, g: 255, b: 255 },
          { r: 255, g: 255, b: 255 },
        ],
        shadowOffset: 0.030000000000000002,
        shadowOpacity: 0.21999999999999997,
        deformationFrequency: 1.0999999999999999,
        deformationAmplitude: 1.4,
        deformationSpeed: 3,
        effect: {},
      },
      scene: { type: "AstronautScene" },
    },
  },
  {
    name: "astronaut-1",
    text: { color: "0xe5e5e5" },
    background: {
      color: "0x000000",
      properties: {
        type: "Dots",
        alphaMin: 0.2,
        alphaGlobal: 0,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 251, g: 255, b: 186 },
          { r: 123.1283935546875, g: 137.11546150826118, b: 193.142578125 },
        ],
        colorsBackLayer: [
          { r: 251, g: 255, b: 186 },
          { r: 148, g: 234.2627252252253, b: 255 },
        ],
        colorsMidLayer: [
          { r: 251, g: 255, b: 186 },
          { r: 193.142578125, g: 49.49278564453124, b: 162.22875454052098 },
        ],
        colorsFrontLayer: [
          { r: 65.642578125, g: 65.642578125, b: 65.642578125 },
          { r: 0, g: 0, b: 0 },
        ],
        shadowOffset: 3.469446951953614e-18,
        shadowOpacity: 0,
        deformationFrequency: 2.9000000000000004,
        deformationAmplitude: 2.8000000000000003,
        deformationSpeed: 3,
        effect: { type: "HueEffect" },
      },
      scene: { type: "AstronautScene" },
    },
  },
  // {
  //   name: "astronaut-3",
  //   text: { color: "0x2b00ff" },
  //   background: {
  //     color: "0xf57f7f",
  //     properties: {
  //       type: "Dots",
  //       alphaMin: 0.2,
  //       alphaGlobal: 0,
  //       scaleValue: 1.532608695652174,
  //       alphaValue: 0.43478260869565216,
  //       radius: 0.05304347826086957,
  //       columnWidth: 30.543478260869563,
  //       color: [1, 1, 1],
  //     },
  //   },
  //   scene: {
  //     miniSceneColor: 16777215,
  //     type: "PaperCutScene",
  //     layers: {
  //       colorsBaseLayer: [
  //         { r: 31.530405405405663, g: 0, b: 255 },
  //         { r: 31.999999999999886, g: 0, b: 255 },
  //       ],
  //       colorsBackLayer: [
  //         { r: 32, g: 0, b: 255 },
  //         { r: 31.999999999999886, g: 0, b: 255 },
  //       ],
  //       colorsMidLayer: [
  //         { r: 32, g: 0, b: 255 },
  //         { r: 60.08789062499999, g: 57.275390625, b: 79.6875 },
  //       ],
  //       colorsFrontLayer: [
  //         { r: 255, g: 133.875, b: 133.875 },
  //         { r: 255, g: 134, b: 134 },
  //       ],
  //       shadowOffset: 3.469446951953614e-18,
  //       shadowOpacity: 0,
  //       deformationFrequency: 0.39999999999999997,
  //       deformationAmplitude: 0,
  //       deformationSpeed: 0,
  //       effect: {},
  //     },
  //     scene: { type: "AstronautScene" },
  //   },
  // },
  // {
  //   name: "astronaut-4",
  //   text: { color: "0xff8686" },
  //   background: {
  //     color: "0x000000",
  //     properties: {
  //       type: "Dots",
  //       alphaMin: 0.2,
  //       alphaGlobal: 0,
  //       scaleValue: 1.532608695652174,
  //       alphaValue: 0.43478260869565216,
  //       radius: 0.05304347826086957,
  //       columnWidth: 30.543478260869563,
  //       color: [1, 1, 1],
  //     },
  //   },
  //   scene: {
  //     miniSceneColor: 16777215,
  //     type: "PaperCutScene",
  //     layers: {
  //       colorsBaseLayer: [
  //         { r: 31.530405405405663, g: 0, b: 255 },
  //         { r: 31.999999999999886, g: 0, b: 255 },
  //       ],
  //       colorsBackLayer: [
  //         { r: 32, g: 0, b: 255 },
  //         { r: 31.999999999999886, g: 0, b: 255 },
  //       ],
  //       colorsMidLayer: [
  //         { r: 32, g: 0, b: 255 },
  //         { r: 153.25624999999994, g: 138.65625, b: 255 },
  //       ],
  //       colorsFrontLayer: [
  //         { r: 255, g: 133.875, b: 133.875 },
  //         { r: 255, g: 134, b: 134 },
  //       ],
  //       shadowOffset: 3.469446951953614e-18,
  //       shadowOpacity: 0,
  //       deformationFrequency: 1.2,
  //       deformationAmplitude: 4,
  //       deformationSpeed: 3,
  //       effect: { type: "HueEffect" },
  //     },
  //     scene: { type: "AstronautScene" },
  //   },
  // },
  {
    name: "astronaut-2",
    text: { color: "0x5f3131" },
    background: {
      color: "0xfba73a",
      properties: {
        type: "Dots",
        alphaMin: 0.29347826086956524,
        alphaGlobal: 0.3695652173913043,
        scaleValue: 1.7934782608695652,
        alphaValue: 0.6695652173913045,
        radius: 0.01,
        columnWidth: 48.15217391304348,
        color: [1, 0.2549019607843137, 0.15294117647058825],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 255, g: 229.3707770270271, b: 121.125 },
          { r: 255, g: 226.3577280405406, b: 149.8125 },
        ],
        colorsBackLayer: [
          { r: 255, g: 223.92950450450445, b: 121 },
          { r: 255, g: 159, b: 33 },
        ],
        colorsMidLayer: [
          { r: 255, g: 174.7094594594595, b: 50.999999999999986 },
          { r: 247.330078125, g: 153.0772645692568, b: 29.370446777343748 },
        ],
        colorsFrontLayer: [
          { r: 255, g: 158.58225388601045, b: 33.46874999999999 },
          { r: 255, g: 172.125, b: 63.75 },
        ],
        shadowOffset: 3.469446951953614e-18,
        shadowOpacity: 0.11999999999999997,
        deformationFrequency: 3,
        deformationAmplitude: 2,
        deformationSpeed: 3,
        effect: {},
      },
      scene: { type: "AstronautScene" },
    },
  },
  {
    name: "astronaut-3",
    text: { color: "0xffffff" },
    background: {
      color: "0x2939af",
      properties: {
        type: "Dots",
        alphaMin: 0.2,
        alphaGlobal: 0,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 31.530405405405663, g: 0, b: 255 },
          { r: 31.999999999999886, g: 0, b: 255 },
        ],
        colorsBackLayer: [
          { r: 32, g: 0, b: 255 },
          { r: 31.999999999999886, g: 0, b: 255 },
        ],
        colorsMidLayer: [
          { r: 32, g: 0, b: 255 },
          { r: 32, g: 0, b: 255 },
        ],
        colorsFrontLayer: [
          { r: 255, g: 133.875, b: 133.875 },
          { r: 50, g: 65, b: 178 },
        ],
        shadowOffset: 3.469446951953614e-18,
        shadowOpacity: 0,
        deformationFrequency: 0.39999999999999997,
        deformationAmplitude: 0,
        deformationSpeed: 0,
        effect: {},
      },
      scene: { type: "AstronautScene" },
    },
  },
  {
    name: "astronaut-4",
    text: { color: "0x363636" },
    background: {
      color: "0x9ddae5",
      properties: {
        type: "Dots",
        alphaMin: 0.29347826086956524,
        alphaGlobal: 0.3695652173913043,
        scaleValue: 1.7934782608695652,
        alphaValue: 0.6695652173913045,
        radius: 0.01,
        columnWidth: 48.15217391304348,
        color: [1, 1, 1],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 197.625, g: 231.91810344827582, b: 255 },
          { r: 122, g: 174, b: 209 },
        ],
        colorsBackLayer: [
          { r: 139.7918918918919, g: 122, b: 209 },
          { r: 71.02957763671876, g: 136.39596873316262, b: 180.392578125 },
        ],
        colorsMidLayer: [
          {
            r: 142.04234619140627,
            g: 188.41085815429682,
            b: 225.01757812500003,
          },
          { r: 0, g: 70.52092633928568, b: 84.767578125 },
        ],
        colorsFrontLayer: [
          { r: 136.651611328125, g: 205.34674567145277, b: 218.642578125 },
          { r: 161.2738037109375, g: 223.38050842285148, b: 234.580078125 },
        ],
        shadowOffset: 0.1,
        shadowOpacity: 0.18,
        deformationFrequency: 2.5000000000000004,
        deformationAmplitude: 2.1,
        deformationSpeed: 1.0999999999999999,
        effect: {},
      },
      scene: { type: "AstronautScene" },
    },
  },
];
