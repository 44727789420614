/* Libraries */
import React from "react";
import classnames from "classnames";
/* -Libraries */

import styles from "./index.module.scss";

const ModalContents = props => {
  const { children, className, fullHeight, noPadding, noMinHeight } = props;

  return (
    <div
      className={classnames(styles.modalContents, className, {
        "p-0": noPadding
      })}
    >
      <div
        className={classnames(
          "columns is-mobile is-centered m-0",
          styles.contentColumnWrapper,
          {
            [styles.fullHeight]: fullHeight,
            [styles.noMinHeight]: noMinHeight
          }
        )}
      >
        <div
          className={classnames("column", styles.contentColumn, {
            "p-0": noPadding
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModalContents);
