import { merge } from "../utils/deepMerge";

export const slideTypes = {
  AUDIO: "audio",
  CREDITS: "credits",
  DATE: "date",
  GIF: "giphy",
  LEGACY: "legacy",
  LOCATION: "location",
  MUSIC: "music",
  NEWS: "news",
  PHOTOS: "photos",
  PLACEHOLDER: "placeholder",
  TEXT: "text",
  TITLE: "title",
  VIDEO: "video",
};

export const slideVariants = {
  [slideTypes.AUDIO]: {
    RECORDER: "recorder",
    WAVES: "waves",
  },
  [slideTypes.CREDITS]: {
    DEFAULT: "default",
  },
  [slideTypes.DATE]: {
    CLOCK: "clock",
    MODERN: "modern",
  },
  [slideTypes.GIF]: {
    SIMPLE: "simple",
  },
  [slideTypes.LEGACY]: {
    DEFAULT: "default",
  },
  [slideTypes.LOCATION]: {
    POSTCARD: "postcard",
  },
  [slideTypes.MUSIC]: {
    MODERN: "modern",
    RETRO: "retro",
  },
  [slideTypes.NEWS]: {
    PAPER: "paper",
  },
  [slideTypes.PHOTOS]: {
    FULLSCREEN: "fullscreen",
    POLAROIDS: "polaroid",
    HOVER: "hover",
    SIMPLE: "simple",
  },
  [slideTypes.TEXT]: {
    LETTER: "letter",
    TYPEWRITER: "typewriter",
    XMAS: "xmas",
    GREETINGS: "greetings",
    SIMPLE: "simple",
  },
  [slideTypes.TITLE]: {
    EXPORTED: "exported",
    DEFAULT: "default",
    CAKE: "cake",
    RIPPLES: "ripples",
    STARBURST: "starburst",
    ROCKET: "rocket",
    ASTRONAUT: "spaceman",
    HEARTS: "hearts",
    PAPER_HEARTS: "paper_heart",
    BOAT: "boat",
    XMAS: "xmas",
    // CARTOON: "cartoon",
    TRAIL: "trail",
    TRAIL_PHOTO: "trail_photo",
    CURVEY: "curvey",
    CURVEY2: "curvey2",
    PARTICLES_SHAPE: "particles_shape",
    PARTICLES_SHAPE_PHOTO: "particles_shape_photo",
    PARTICLES_TEXT: "particles_text",
  },
  [slideTypes.PLACEHOLDER]: {
    DEFAULT: "default",
  },
  [slideTypes.VIDEO]: {
    FILM: "negative",
    FULLSCREEN: "fullscreen",
    TV: "tv",
    SIMPLE: "simple",
  },
};

// const getView = (id) => {
//   return import(id).then((result) => result.default)
// }

export const mapsVariantData = {
  [slideTypes.AUDIO]: {
    data: {
      transition: {
        direction: "horizontal",
        mirrorSecondary: true,
      },
    },
    [slideVariants[slideTypes.AUDIO].RECORDER]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/audio/recorder/Audio.recorder"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.AUDIO].WAVES]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/audio/waves/Audio.waves").then(
          result => result.default
        ),
    },
  },
  [slideTypes.CREDITS]: {
    [slideVariants[slideTypes.CREDITS].DEFAULT]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/credits/default/Credits.default"
        ).then(result => result.default),
    },
  },
  [slideTypes.DATE]: {
    data: {
      transition: {
        direction: "horizontal",
      },
    },
    [slideVariants[slideTypes.DATE].CLOCK]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/date/default/Date.default").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.DATE].MODERN]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/date/modern/Date.modern").then(
          result => result.default
        ),
    },
  },
  [slideTypes.GIF]: {
    data: {
      transition: {
        direction: "vertical",
        mirrorMain: true, // searching for hasDarkGradient ? go to transitionsDirection.js
      },
    },
    [slideVariants[slideTypes.GIF].SIMPLE]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/gif/simple/Gif.simple").then(
          result => result.default
        ),
    },
  },
  [slideTypes.LEGACY]: {
    [slideVariants[slideTypes.LEGACY].DEFAULT]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/legacy/default/Legacy.default"
        ).then(result => result.default),
    },
  },
  [slideTypes.LOCATION]: {
    data: {
      transition: {
        direction: "vertical",
        mirrorMain: true,
      },
    },
    [slideVariants[slideTypes.LOCATION].POSTCARD]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/location/default/Location.default"
        ).then(result => result.default),
    },
  },
  [slideTypes.MUSIC]: {
    [slideVariants[slideTypes.MUSIC].MODERN]: {
      color: "0xffffff",
      data: {
        transition: {
          direction: "vertical",
          mirrorMain: true,
          // searching for hasDarkGradient ? go to transitionsDirection.js
        },
      },
      view: () =>
        import("../webgl/scenes/kindeo/views/music/modern/Music.modern").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.MUSIC].RETRO]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/music/default/Music.default").then(
          result => result.default
        ),
    },
  },
  [slideTypes.NEWS]: {
    data: {
      transition: {
        direction: "vertical",
      },
    },
    [slideVariants[slideTypes.NEWS].PAPER]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/news/paper/News.paper").then(
          result => result.default
        ),
    },
  },
  [slideTypes.PHOTOS]: {
    [slideVariants[slideTypes.PHOTOS].FULLSCREEN]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/photos/fullscreen/Photos.fullscreen"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.PHOTOS].POLAROIDS]: {
      color: "0xffffff",
      data: {
        transition: {
          // searching for hasDarkGradient ? go to transitionsDirection.js
          direction: "horizontal",
          mirrorSecondary: true,
        },
      },
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/photos/polaroids/Photos.polaroids"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.PHOTOS].HOVER]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/photos/hover/Photos.hover").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.PHOTOS].SIMPLE]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/photos/simple/Photos.simple").then(
          result => result.default
        ),
    },
  },
  [slideTypes.TEXT]: {
    data: {
      transition: {
        direction: "vertical",
      },
    },
    [slideVariants[slideTypes.TEXT].LETTER]: {
      color: "0xffffff",
      data: {
        transition: {
          mirrorMain: true,
        },
      },
      view: () =>
        import("../webgl/scenes/kindeo/views/text/letter/Text.letter").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TEXT].TYPEWRITER]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/text/typewriter/Text.typewriter"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.TEXT].XMAS]: {
      color: "0xffffff",
      data: {
        transition: {
          mirrorMain: true,
        },
      },
      view: () =>
        import("../webgl/scenes/kindeo/views/text/letter/Text.letterxmas").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TEXT].GREETINGS]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/text/greetings/Text.greetings"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.TEXT].SIMPLE]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/text/simple/Text.simple").then(
          result => result.default
        ),
    },
  },

  [slideTypes.TITLE]: {
    [slideVariants[slideTypes.TITLE].EXPORTED]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/title/exported/Title.exported"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.TITLE].DEFAULT]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/default/Title.default").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].CAKE]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/badge/Title.badge").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].RIPPLES]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/cartoon/Title.cartoon").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].STARBURST]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/cartoon/Title.cartoon").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].ROCKET]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/badge/Title.badge").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].ASTRONAUT]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/badge/Title.badge").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].HEARTS]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/badge/Title.badge").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].PAPER_HEARTS]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/badge/Title.badge").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].BOAT]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/badge/Title.badge").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].XMAS]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/badge/Title.badge").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].TRAIL]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/trail/Title.trail").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].TRAIL_PHOTO]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/title/trail/Title.trailPhoto"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.TITLE].CURVEY]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/curvey/Title.curvey").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].CURVEY2]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/curvey2/Title.curvey2").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].PARTICLES_SHAPE]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/flowers/Title.flowers").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.TITLE].PARTICLES_SHAPE_PHOTO]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/title/flowers/Title.flowersPhoto"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.TITLE].PARTICLES_TEXT]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/title/flowers/Title.flowers").then(
          result => result.default
        ),
    },
  },
  [slideTypes.PLACEHOLDER]: {
    [slideVariants[slideTypes.PLACEHOLDER].DEFAULT]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/placeholder/Placeholder.default"
        ).then(result => result.default),
    },
  },
  [slideTypes.VIDEO]: {
    data: {},
    [slideVariants[slideTypes.VIDEO].FILM]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/video/negative/Video.negative"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.VIDEO].FULLSCREEN]: {
      color: "0xffffff",
      view: () =>
        import(
          "../webgl/scenes/kindeo/views/video/fullscreen/Video.fullscreen"
        ).then(result => result.default),
    },
    [slideVariants[slideTypes.VIDEO].TV]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/video/tv/Video.tv").then(
          result => result.default
        ),
    },
    [slideVariants[slideTypes.VIDEO].SIMPLE]: {
      color: "0xffffff",
      view: () =>
        import("../webgl/scenes/kindeo/views/video/simple/Video.simple").then(
          result => result.default
        ),
    },
  },
};

const placeholderVariant = {
  name: "placeholder",
  view: () =>
    import("../webgl/scenes/kindeo/views/placeholder/Placeholder.default").then(
      result => result.default
    ),
  label: "Placeholder",
};

export const getSlideVariant = (type, variant) => {
  const objType = mapsVariantData[type];
  const objVariant = objType?.[variant] || placeholderVariant;

  const data = merge(objType.data || {}, objVariant.data || {});

  data.name = variant;

  return { data, view: objVariant.view };
};
