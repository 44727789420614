/* Selectors */
/* -Selectors */

export const DEFAULT_CARD_THEME = {
  schema_version: "1.0",
  colours: [
    // "#FFFFFF",
    // "#000000",
    "#485992",
    "#43B179",
    "#9C6EFD",
    "#F97A52",
    "#72D8AE",
    "#EF758E",
    "#FFD14D",
    "#3ac9d2",
  ],
};

export const getCardTheme = state => state.cardTheme;

export const getCardThemeVersion = theme => theme?.schema_version;
export const getCardThemeColours = theme =>
  theme?.colours || theme?.colors || [];
export const getCardThemeColourByIndex = (theme, index) => {
  return getCardThemeColours(theme)[
    (getCardThemeColours(theme).length + index) %
      getCardThemeColours(theme).length
  ];
};

export const validateTheme = theme => {
  if (!theme) {
    return false;
  }
  if (!getCardThemeVersion(theme)) {
    return false;
  }
  if (!getCardThemeColours(theme)?.length) {
    return false;
  }
  return true;
};
