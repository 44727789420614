// DO NOT TOUCH THE FOLLOWING COMMENT, AND DO NOT TOUCH THE CATEGORY COMMENTS
// BECAUSE THEY'RE USED TO AUTOGENERATE THE COVERS FROM THE TOOLS
// imports
import KIN_LIGHT_01_06_1720705131854_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_06_1720705131854.gif";
import KIN_LIGHT_01_06_1720705131854_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_06_1720705131854.json";
import KIN_LIGHT_01_06_1720705131854_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_06_1720705131854.mp4";
import KIN_LIGHT_01_02_1720703807326_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_02_1720703807326.gif";
import KIN_LIGHT_01_02_1720703807326_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_02_1720703807326.json";
import KIN_LIGHT_01_02_1720703807326_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_02_1720703807326.mp4";
import KIN_LIGHT_01_05_1720705002213_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_05_1720705002213.gif";
import KIN_LIGHT_01_05_1720705002213_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_05_1720705002213.json";
import KIN_LIGHT_01_05_1720705002213_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_05_1720705002213.mp4";
import KIN_PHOTSQ_01_03_1720700198525_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_03_1720700198525.gif";
import KIN_PHOTSQ_01_03_1720700198525_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_03_1720700198525.json";
import KIN_PHOTSQ_01_03_1720700198525_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_03_1720700198525.mp4";
import KIN_PHOTSQ_01_02_1720700381865_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_02_1720700381865.gif";
import KIN_PHOTSQ_01_02_1720700381865_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_02_1720700381865.json";
import KIN_PHOTSQ_01_02_1720700381865_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_02_1720700381865.mp4";
import KIN_PHOTSQ_01_01_1720700554517_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_01_1720700554517.gif";
import KIN_PHOTSQ_01_01_1720700554517_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_01_1720700554517.json";
import KIN_PHOTSQ_01_01_1720700554517_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_01_1720700554517.mp4";
import KIN_PHOTSQ_01_06_1719240218168_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_06_1719240218168.json";
import KIN_PHOTSQ_01_05_1719238809567_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_05_1719238809567.json";
import KIN_PHOTSQ_01_04_1719238694579_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_04_1719238694579.gif";
import KIN_PHOTSQ_01_04_1719238694579_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_04_1719238694579.json";
import KIN_PHOTSQ_01_04_1719238694579_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_04_1719238694579.mp4";
import KIN_PHOTSQ_01_03_1719235096480_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_03_1719235096480.json";
import KIN_PHOTSQ_01_02_1719234385265_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_02_1719234385265.json";
import KIN_PHOTSQ_01_01_1719220401637_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_01_1719220401637.json";
import KIN_PHOTSQ_01_01_1719220401637_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_01_1719220401637.gif";
import KIN_PHOTSQ_01_01_1719220401637_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PHOTSQ_01_01_1719220401637.mp4";
import KIN_LIGHT_01_06_1719838375150_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_06_1719838375150.gif";
import KIN_LIGHT_01_06_1719838375150_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_06_1719838375150.json";
import KIN_LIGHT_01_06_1719838375150_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_06_1719838375150.mp4";
import KIN_LIGHT_01_05_1719838199274_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_05_1719838199274.json";
import KIN_LIGHT_01_04_1719838028254_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_04_1719838028254.json";
import KIN_LIGHT_01_03_1719837523361_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_03_1719837523361.json";
import KIN_LIGHT_01_02_1719835334640_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_02_1719835334640.json";
import KIN_LIGHT_01_01_1719834813900_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LIGHT_01_01_1719834813900.json";
import KIN_RAIN_01_04_1718191167020_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_04_1718191167020.gif";
import KIN_RAIN_01_04_1718191167020_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_04_1718191167020.json";
import KIN_RAIN_01_04_1718191167020_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_04_1718191167020.mp4";
import KIN_FOIL_02_05_1718026309006_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_05_1718026309006.gif";
import KIN_FOIL_02_05_1718026309006_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_05_1718026309006.json";
import KIN_FOIL_02_05_1718026309006_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_05_1718026309006.mp4";
import KIN_FOIL_01_06_1718026047956_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_06_1718026047956.gif";
import KIN_FOIL_01_06_1718026047956_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_06_1718026047956.json";
import KIN_FOIL_01_06_1718026047956_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_06_1718026047956.mp4";
import KIN_FOIL_02_04_1718018027596_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_04_1718018027596.gif";
import KIN_FOIL_02_04_1718018027596_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_04_1718018027596.json";
import KIN_FOIL_02_04_1718018027596_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_04_1718018027596.mp4";
import KIN_FOIL_01_04_1718018438426_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_04_1718018438426.gif";
import KIN_FOIL_01_04_1718018438426_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_04_1718018438426.json";
import KIN_FOIL_01_04_1718018438426_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_04_1718018438426.mp4";
import KIN_RAIN_01_02_1718190941183_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_02_1718190941183.gif";
import KIN_RAIN_01_02_1718190941183_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_02_1718190941183.json";
import KIN_RAIN_01_02_1718190941183_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_02_1718190941183.mp4";
import KIN_FOIL_02_02_1718028748917_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_02_1718028748917.gif";
import KIN_FOIL_02_02_1718028748917_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_02_1718028748917.json";
import KIN_FOIL_02_02_1718028748917_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_02_1718028748917.mp4";
import KIN_FOIL_01_04_1718028047733_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_04_1718028047733.gif";
import KIN_FOIL_01_04_1718028047733_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_04_1718028047733.json";
import KIN_FOIL_01_04_1718028047733_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_04_1718028047733.mp4";
import KIN_RAIN_01_05_1718191453194_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_05_1718191453194.gif";
import KIN_RAIN_01_05_1718191453194_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_05_1718191453194.json";
import KIN_RAIN_01_05_1718191453194_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_05_1718191453194.mp4";
import KIN_FOIL_02_03_1718015771438_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_03_1718015771438.gif";
import KIN_FOIL_02_03_1718015771438_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_03_1718015771438.json";
import KIN_FOIL_02_03_1718015771438_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_03_1718015771438.mp4";
import KIN_FOIL_01_06_1718015187875_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_06_1718015187875.gif";
import KIN_FOIL_01_06_1718015187875_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_06_1718015187875.json";
import KIN_FOIL_01_06_1718015187875_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_06_1718015187875.mp4";
import KIN_RAIN_01_03_1718191266549_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_03_1718191266549.gif";
import KIN_RAIN_01_03_1718191266549_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_03_1718191266549.json";
import KIN_RAIN_01_03_1718191266549_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_03_1718191266549.mp4";
import KIN_FOIL_02_02_1718026550484_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_02_1718026550484.gif";
import KIN_FOIL_02_02_1718026550484_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_02_1718026550484.json";
import KIN_FOIL_02_02_1718026550484_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_02_1718026550484.mp4";
import KIN_FOIL_01_01_1718026678590_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_01_1718026678590.gif";
import KIN_FOIL_01_01_1718026678590_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_01_1718026678590.json";
import KIN_FOIL_01_01_1718026678590_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_01_1718026678590.mp4";
import KIN_RAIN_01_06_1718190738385_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_06_1718190738385.gif";
import KIN_RAIN_01_06_1718190738385_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_06_1718190738385.json";
import KIN_RAIN_01_06_1718190738385_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_06_1718190738385.mp4";
import KIN_FOIL_02_01_1718028537381_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_01_1718028537381.gif";
import KIN_FOIL_02_01_1718028537381_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_01_1718028537381.json";
import KIN_FOIL_02_01_1718028537381_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_01_1718028537381.mp4";
import KIN_FOIL_01_05_1718028134873_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_05_1718028134873.gif";
import KIN_FOIL_01_05_1718028134873_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_05_1718028134873.json";
import KIN_FOIL_01_05_1718028134873_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_05_1718028134873.mp4";
import KIN_RAIN_01_05_1718191541913_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_05_1718191541913.gif";
import KIN_RAIN_01_05_1718191541913_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_05_1718191541913.json";
import KIN_RAIN_01_05_1718191541913_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_05_1718191541913.mp4";
import KIN_FOIL_02_03_1718027833957_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_03_1718027833957.gif";
import KIN_FOIL_02_03_1718027833957_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_03_1718027833957.json";
import KIN_FOIL_02_03_1718027833957_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_03_1718027833957.mp4";
import KIN_FOIL_01_02_1718027530241_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_02_1718027530241.gif";
import KIN_FOIL_01_02_1718027530241_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_02_1718027530241.json";
import KIN_FOIL_01_02_1718027530241_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_02_1718027530241.mp4";
import KIN_RAIN_01_06_1718189204089_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_06_1718189204089.json";
import KIN_RAIN_01_03_1718190142656_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_03_1718190142656.json";
import KIN_RAIN_01_02_1718190206408_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_02_1718190206408.gif";
import KIN_RAIN_01_02_1718190206408_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_02_1718190206408.json";
import KIN_RAIN_01_02_1718190206408_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_02_1718190206408.mp4";
import KIN_FOIL_02_05_1717773461536_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_05_1717773461536.gif";
import KIN_FOIL_02_05_1717773461536_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_05_1717773461536.json";
import KIN_FOIL_02_05_1717773461536_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_05_1717773461536.mp4";
import KIN_FOIL_01_03_1718014608747_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_03_1718014608747.gif";
import KIN_FOIL_01_03_1718014608747_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_03_1718014608747.json";
import KIN_FOIL_01_03_1718014608747_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_03_1718014608747.mp4";
import KIN_RAIN_01_01_1718190561781_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_01_1718190561781.gif";
import KIN_RAIN_01_01_1718190561781_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_01_1718190561781.json";
import KIN_RAIN_01_01_1718190561781_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RAIN_01_01_1718190561781.mp4";
import KIN_FOIL_02_06_1718027112755_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_06_1718027112755.gif";
import KIN_FOIL_02_06_1718027112755_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_06_1718027112755.json";
import KIN_FOIL_02_06_1718027112755_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_02_06_1718027112755.mp4";
import KIN_FOIL_01_03_1718027377403_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_03_1718027377403.gif";
import KIN_FOIL_01_03_1718027377403_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_03_1718027377403.json";
import KIN_FOIL_01_03_1718027377403_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FOIL_01_03_1718027377403.mp4";
import KIN_STAR_01_02_1717430262797_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1717430262797.gif";
import KIN_STAR_01_02_1717430262797_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1717430262797.json";
import KIN_STAR_01_02_1717430262797_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1717430262797.mp4";
import KIN_RIPP_01_01_1717430625295_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1717430625295.gif";
import KIN_RIPP_01_01_1717430625295_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1717430625295.json";
import KIN_RIPP_01_01_1717430625295_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1717430625295.mp4";
import KIN_TRAIL_01_03_1717426733778_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_03_1717426733778.gif";
import KIN_TRAIL_01_03_1717426733778_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_03_1717426733778.json";
import KIN_TRAIL_01_03_1717426733778_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_03_1717426733778.mp4";
import KIN_GRUMPY_01_03_1717427326649_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_03_1717427326649.gif";
import KIN_GRUMPY_01_03_1717427326649_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_03_1717427326649.json";
import KIN_GRUMPY_01_03_1717427326649_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_03_1717427326649.mp4";
import KIN_FLEUR_01_06_1717407029189_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_06_1717407029189.gif";
import KIN_FLEUR_01_06_1717407029189_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_06_1717407029189.json";
import KIN_FLEUR_01_06_1717407029189_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_06_1717407029189.mp4";
import KIN_EGG_01_03_1717427019514_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_03_1717427019514.gif";
import KIN_EGG_01_03_1717427019514_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_03_1717427019514.json";
import KIN_EGG_01_03_1717427019514_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_03_1717427019514.mp4";
import KIN_FUZZ_02_05_1717426151794_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_05_1717426151794.gif";
import KIN_FUZZ_02_05_1717426151794_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_05_1717426151794.json";
import KIN_FUZZ_02_05_1717426151794_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_05_1717426151794.mp4";
import KIN_FUZZ_01_03_1717425838197_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1717425838197.gif";
import KIN_FUZZ_01_03_1717425838197_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1717425838197.json";
import KIN_FUZZ_01_03_1717425838197_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1717425838197.mp4";
import KIN_FUZZ_02_06_1717170957031_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_06_1717170957031.json";
import KIN_FUZZ_02_05_1717171063664_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_05_1717171063664.gif";
import KIN_FUZZ_02_05_1717171063664_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_05_1717171063664.json";
import KIN_FUZZ_02_05_1717171063664_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_05_1717171063664.mp4";
import KIN_FUZZ_02_04_1717171564353_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_04_1717171564353.json";
import KIN_FUZZ_02_03_1717170345344_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_03_1717170345344.json";
import KIN_FUZZ_02_02_1717170091780_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_02_1717170091780.json";
import KIN_FUZZ_02_01_1717168407414_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_02_01_1717168407414.json";
import KIN_FUZZ_01_06_1717166112331_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_06_1717166112331.json";
import KIN_FUZZ_01_05_1717166665932_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_05_1717166665932.json";
import KIN_FUZZ_01_04_1717166334390_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_04_1717166334390.json";
import KIN_BLOONZ_02_03_1717081136243_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_03_1717081136243.gif";
import KIN_BLOONZ_02_03_1717081136243_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_03_1717081136243.json";
import KIN_BLOONZ_02_03_1717081136243_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_03_1717081136243.mp4";
import KIN_BLOONZ_02_01_1717081019156_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_01_1717081019156.gif";
import KIN_BLOONZ_02_01_1717081019156_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_01_1717081019156.json";
import KIN_BLOONZ_02_01_1717081019156_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_01_1717081019156.mp4";
import KIN_BLOONZ_01_01_1717080759183_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_01_1717080759183.gif";
import KIN_BLOONZ_01_01_1717080759183_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_01_1717080759183.json";
import KIN_BLOONZ_01_01_1717080759183_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_01_1717080759183.mp4";
import KIN_BLOONZ_02_04_1717082038613_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1717082038613.gif";
import KIN_BLOONZ_02_04_1717082038613_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1717082038613.json";
import KIN_BLOONZ_02_04_1717082038613_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1717082038613.mp4";
import KIN_BLOONZ_01_02_1717081621361_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_02_1717081621361.gif";
import KIN_BLOONZ_01_02_1717081621361_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_02_1717081621361.json";
import KIN_BLOONZ_01_02_1717081621361_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_02_1717081621361.mp4";
import KIN_BLOONZ_02_05_1717080354766_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_05_1717080354766.gif";
import KIN_BLOONZ_02_05_1717080354766_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_05_1717080354766.json";
import KIN_BLOONZ_02_05_1717080354766_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_05_1717080354766.mp4";
import KIN_BLOONZ_01_06_1717081306897_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1717081306897.gif";
import KIN_BLOONZ_01_06_1717081306897_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1717081306897.json";
import KIN_BLOONZ_01_06_1717081306897_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1717081306897.mp4";
import KIN_BLOONZ_01_03_1717080466468_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_03_1717080466468.gif";
import KIN_BLOONZ_01_03_1717080466468_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_03_1717080466468.json";
import KIN_BLOONZ_01_03_1717080466468_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_03_1717080466468.mp4";
import KIN_BLOONZ_02_02_1717081410629_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_02_1717081410629.gif";
import KIN_BLOONZ_02_02_1717081410629_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_02_1717081410629.json";
import KIN_BLOONZ_02_02_1717081410629_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_02_1717081410629.mp4";
import KIN_JUGGLE_02_01_1716888998740_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_JUGGLE_02_01_1716888998740.gif";
import KIN_JUGGLE_02_01_1716888998740_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_JUGGLE_02_01_1716888998740.json";
import KIN_JUGGLE_02_01_1716888998740_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_JUGGLE_02_01_1716888998740.mp4";
import KIN_WAVES_01_04_1716913109135_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_04_1716913109135.gif";
import KIN_WAVES_01_04_1716913109135_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_04_1716913109135.json";
import KIN_WAVES_01_04_1716913109135_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_04_1716913109135.mp4";
import KIN_WAVES_01_06_1716913711822_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_06_1716913711822.gif";
import KIN_WAVES_01_06_1716913711822_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_06_1716913711822.json";
import KIN_WAVES_01_06_1716913711822_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_06_1716913711822.mp4";
import KIN_WAVES_01_05_1716913232942_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_05_1716913232942.gif";
import KIN_WAVES_01_05_1716913232942_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_05_1716913232942.json";
import KIN_WAVES_01_05_1716913232942_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_05_1716913232942.mp4";
import KIN_WAVES_01_03_1716912874983_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_03_1716912874983.gif";
import KIN_WAVES_01_03_1716912874983_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_03_1716912874983.json";
import KIN_WAVES_01_03_1716912874983_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_03_1716912874983.mp4";
import KIN_WAVES_01_02_1716912595705_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_02_1716912595705.gif";
import KIN_WAVES_01_02_1716912595705_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_02_1716912595705.json";
import KIN_WAVES_01_02_1716912595705_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_02_1716912595705.mp4";
import KIN_LAVA_01_01_1716914643177_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1716914643177.gif";
import KIN_LAVA_01_01_1716914643177_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1716914643177.json";
import KIN_LAVA_01_01_1716914643177_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1716914643177.mp4";
import KIN_LAVA_01_05_1716914379627_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_05_1716914379627.gif";
import KIN_LAVA_01_05_1716914379627_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_05_1716914379627.json";
import KIN_LAVA_01_05_1716914379627_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_05_1716914379627.mp4";
import KIN_LAVA_01_03_1716916332280_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_03_1716916332280.gif";
import KIN_LAVA_01_03_1716916332280_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_03_1716916332280.json";
import KIN_LAVA_01_03_1716916332280_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_03_1716916332280.mp4";
import KIN_LAVA_01_06_1716915994293_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_06_1716915994293.gif";
import KIN_LAVA_01_06_1716915994293_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_06_1716915994293.json";
import KIN_LAVA_01_06_1716915994293_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_06_1716915994293.mp4";
import KIN_BLOONZ_01_06_1716555574459_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1716555574459.gif";
import KIN_BLOONZ_01_06_1716555574459_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1716555574459.json";
import KIN_BLOONZ_01_06_1716555574459_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1716555574459.mp4";
import KIN_BLOONZ_01_01_1716554905320_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_01_1716554905320.gif";
import KIN_BLOONZ_01_01_1716554905320_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_01_1716554905320.json";
import KIN_BLOONZ_01_01_1716554905320_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_01_1716554905320.mp4";
import KIN_BLOONZ_02_04_1716555062196_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1716555062196.gif";
import KIN_BLOONZ_02_04_1716555062196_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1716555062196.json";
import KIN_BLOONZ_02_04_1716555062196_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1716555062196.mp4";
import KIN_BLOONZ_01_04_1716560606812_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_04_1716560606812.gif";
import KIN_BLOONZ_01_04_1716560606812_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_04_1716560606812.json";
import KIN_BLOONZ_01_04_1716560606812_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_04_1716560606812.mp4";
import KIN_BLOONZ_02_01_1716561162498_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_01_1716561162498.gif";
import KIN_BLOONZ_02_01_1716561162498_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_01_1716561162498.json";
import KIN_BLOONZ_02_01_1716561162498_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_01_1716561162498.mp4";
import KIN_BLOONZ_01_05_1716555296233_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_05_1716555296233.gif";
import KIN_BLOONZ_01_05_1716555296233_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_05_1716555296233.json";
import KIN_BLOONZ_01_05_1716555296233_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_05_1716555296233.mp4";
import KIN_BLOONZ_02_03_1716555676669_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_03_1716555676669.gif";
import KIN_BLOONZ_02_03_1716555676669_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_03_1716555676669.json";
import KIN_BLOONZ_02_03_1716555676669_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_03_1716555676669.mp4";
import KIN_BLOONZ_02_05_1716560922230_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_05_1716560922230.gif";
import KIN_BLOONZ_02_05_1716560922230_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_05_1716560922230.json";
import KIN_BLOONZ_02_05_1716560922230_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_05_1716560922230.mp4";
import KIN_BLOONZ_01_02_1716561056840_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_02_1716561056840.gif";
import KIN_BLOONZ_01_02_1716561056840_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_02_1716561056840.json";
import KIN_BLOONZ_01_02_1716561056840_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_02_1716561056840.mp4";
import KIN_FLEUR_01_03_1716212605619_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_03_1716212605619.gif";
import KIN_FLEUR_01_03_1716212605619_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_03_1716212605619.json";
import KIN_FLEUR_01_03_1716212605619_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_03_1716212605619.mp4";
import KIN_FLEUR_01_01_1716212542664_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_01_1716212542664.gif";
import KIN_FLEUR_01_01_1716212542664_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_01_1716212542664.json";
import KIN_FLEUR_01_01_1716212542664_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_01_1716212542664.mp4";
import KIN_FLEUR_01_05_1716212690055_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_05_1716212690055.gif";
import KIN_FLEUR_01_05_1716212690055_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_05_1716212690055.json";
import KIN_FLEUR_01_05_1716212690055_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_05_1716212690055.mp4";
import KIN_FLEUR_01_04_1716212885645_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_04_1716212885645.gif";
import KIN_FLEUR_01_04_1716212885645_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_04_1716212885645.json";
import KIN_FLEUR_01_04_1716212885645_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_04_1716212885645.mp4";
import KIN_FLEUR_01_02_1716212767461_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_02_1716212767461.gif";
import KIN_FLEUR_01_02_1716212767461_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_02_1716212767461.json";
import KIN_FLEUR_01_02_1716212767461_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_02_1716212767461.mp4";
import KIN_FLEUR_01_06_1715952454685_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_06_1715952454685.json";
import KIN_FLEUR_01_05_1715952322408_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_05_1715952322408.json";
import KIN_FLEUR_01_04_1715952143771_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_04_1715952143771.gif";
import KIN_FLEUR_01_04_1715952143771_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_04_1715952143771.json";
import KIN_FLEUR_01_04_1715952143771_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_04_1715952143771.mp4";
import KIN_FLEUR_01_03_1715951957106_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_03_1715951957106.json";
import KIN_FLEUR_01_02_1715951839604_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_02_1715951839604.json";
import KIN_FLEUR_01_01_1715951243770_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_01_1715951243770.json";
import KIN_FLEUR_01_06_1716212951499_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_06_1716212951499.gif";
import KIN_FLEUR_01_06_1716212951499_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_06_1716212951499.json";
import KIN_FLEUR_01_06_1716212951499_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLEUR_01_06_1716212951499.mp4";
import KIN_BLOONZ_02_06_1716470475503_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_06_1716470475503.json";
import KIN_BLOONZ_02_05_1716468970813_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_05_1716468970813.json";
import KIN_BLOONZ_02_04_1716468557922_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1716468557922.json";
import KIN_BLOONZ_02_03_1716468453740_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_03_1716468453740.json";
import KIN_BLOONZ_02_02_1716468338295_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_02_1716468338295.json";
import KIN_BLOONZ_02_04_1716468557922_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1716468557922.gif";
import KIN_BLOONZ_02_01_1716468165100_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_01_1716468165100.json";
import KIN_BLOONZ_02_04_1716468557922_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_02_04_1716468557922.mp4";
import KIN_BLOONZ_01_06_1716385203101_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1716385203101.gif";
import KIN_BLOONZ_01_06_1716385203101_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1716385203101.json";
import KIN_BLOONZ_01_06_1716385203101_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_06_1716385203101.mp4";
import KIN_BLOONZ_01_05_1716385087830_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_05_1716385087830.json";
import KIN_BLOONZ_01_04_1716384755410_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_04_1716384755410.json";
import KIN_BLOONZ_01_03_1716384683317_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_03_1716384683317.json";
import KIN_BLOONZ_01_02_1716384398009_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_02_1716384398009.json";
import KIN_BLOONZ_01_01_1716378186477_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BLOONZ_01_01_1716378186477.json";
import KIN_RETRO_01_02_1715707847561_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_02_1715707847561.gif";
import KIN_RETRO_01_02_1715707847561_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_02_1715707847561.json";
import KIN_RETRO_01_02_1715707847561_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_02_1715707847561.mp4";
import KIN_RETRO_01_03_1715707974555_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_03_1715707974555.gif";
import KIN_RETRO_01_03_1715707974555_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_03_1715707974555.json";
import KIN_RETRO_01_03_1715707974555_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_03_1715707974555.mp4";
import KIN_RETRO_01_06_1715706010944_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_06_1715706010944.gif";
import KIN_RETRO_01_06_1715706010944_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_06_1715706010944.json";
import KIN_RETRO_01_06_1715706010944_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_06_1715706010944.mp4";
import KIN_RETRO_01_05_1715710838375_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_05_1715710838375.gif";
import KIN_RETRO_01_05_1715710838375_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_05_1715710838375.json";
import KIN_RETRO_01_05_1715710838375_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_05_1715710838375.mp4";
import KIN_RETRO_01_03_1715710953775_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_03_1715710953775.gif";
import KIN_RETRO_01_03_1715710953775_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_03_1715710953775.json";
import KIN_RETRO_01_03_1715710953775_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_03_1715710953775.mp4";
import KIN_RETRO_01_01_1715707426466_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_01_1715707426466.gif";
import KIN_RETRO_01_01_1715707426466_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_01_1715707426466.json";
import KIN_RETRO_01_01_1715707426466_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_01_1715707426466.mp4";
import KIN_RETRO_01_06_1715694795595_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_06_1715694795595.json";
import KIN_RETRO_01_05_1715694367964_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_05_1715694367964.json";
import KIN_RETRO_01_04_1715679156132_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_04_1715679156132.json";
import KIN_RETRO_01_03_1715620048476_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_03_1715620048476.json";
import KIN_RETRO_01_02_1715619239370_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_02_1715619239370.json";
import KIN_RETRO_01_01_1714490793343_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_01_1714490793343.gif";
import KIN_RETRO_01_01_1714490793343_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_01_1714490793343.json";
import KIN_RETRO_01_01_1714490793343_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_01_1714490793343.mp4";
import KIN_RETRO_01_04_1715708156987_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_04_1715708156987.gif";
import KIN_RETRO_01_04_1715708156987_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_04_1715708156987.json";
import KIN_RETRO_01_04_1715708156987_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RETRO_01_04_1715708156987.mp4";
import KIN_LAVA_01_05_1715271458696_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_05_1715271458696.gif";
import KIN_LAVA_01_05_1715271458696_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_05_1715271458696.json";
import KIN_LAVA_01_05_1715271458696_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_05_1715271458696.mp4";
import KIN_LAVA_01_01_1715271588380_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1715271588380.gif";
import KIN_LAVA_01_01_1715271588380_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1715271588380.json";
import KIN_LAVA_01_01_1715271588380_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1715271588380.mp4";
import KIN_WAVES_01_01_1715271007383_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_01_1715271007383.gif";
import KIN_WAVES_01_01_1715271007383_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_01_1715271007383.json";
import KIN_WAVES_01_01_1715271007383_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_01_1715271007383.mp4";
import KIN_WAVES_01_06_1715271160945_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_06_1715271160945.gif";
import KIN_WAVES_01_06_1715271160945_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_06_1715271160945.json";
import KIN_WAVES_01_06_1715271160945_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_06_1715271160945.mp4";
import KIN_LOVE_01_03_1715274465463_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_03_1715274465463.gif";
import KIN_LOVE_01_03_1715274465463_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_03_1715274465463.json";
import KIN_LOVE_01_03_1715274465463_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_03_1715274465463.mp4";
import KIN_WAVES_01_06_1714039730611_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_06_1714039730611.json";
import KIN_WAVES_01_05_1714120377730_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_05_1714120377730.json";
import KIN_WAVES_01_04_1714120398177_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_04_1714120398177.json";
import KIN_WAVES_01_03_1714120681955_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_03_1714120681955.gif";
import KIN_WAVES_01_03_1714120681955_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_03_1714120681955.json";
import KIN_WAVES_01_03_1714120681955_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_03_1714120681955.mp4";
import KIN_WAVES_01_02_1714120997103_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_02_1714120997103.json";
import KIN_WAVES_01_01_1714121044033_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WAVES_01_01_1714121044033.json";
import KIN_LOVE_01_06_1714486653612_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_06_1714486653612.json";
import KIN_LOVE_01_05_1714486567765_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_05_1714486567765.json";
import KIN_LOVE_01_04_1714486440311_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_04_1714486440311.json";
import KIN_LOVE_01_03_1714486237129_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_03_1714486237129.json";
import KIN_LOVE_01_02_1714486205209_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_02_1714486205209.json";
import KIN_LOVE_01_01_1714401990049_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_01_1714401990049.gif";
import KIN_LOVE_01_01_1714401990049_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_01_1714401990049.json";
import KIN_LOVE_01_01_1714401990049_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_01_1714401990049.mp4";
import KIN_LAVA_01_06_1714126683983_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_06_1714126683983.json";
import KIN_LAVA_01_05_1714127004822_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_05_1714127004822.json";
import KIN_LAVA_01_04_1714126301246_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_04_1714126301246.json";
import KIN_LAVA_01_03_1714122945946_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_03_1714122945946.json";
import KIN_LAVA_01_01_1714123409133_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1714123409133.gif";
import KIN_LAVA_01_01_1714123409133_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1714123409133.json";
import KIN_LAVA_01_01_1714123409133_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LAVA_01_01_1714123409133.mp4";
import KIN_LOVE_01_02_1715274346302_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_02_1715274346302.gif";
import KIN_LOVE_01_02_1715274346302_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_02_1715274346302.json";
import KIN_LOVE_01_02_1715274346302_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_LOVE_01_02_1715274346302.mp4";
import KIN_STAR_01_04_1713789429984_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1713789429984.gif";
import KIN_STAR_01_04_1713789429984_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1713789429984.json";
import KIN_STAR_01_04_1713789429984_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1713789429984.mp4";
import KIN_SIMPLE_01_04_1713789288739_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_04_1713789288739.gif";
import KIN_SIMPLE_01_04_1713789288739_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_04_1713789288739.json";
import KIN_SIMPLE_01_04_1713789288739_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_04_1713789288739.mp4";
import KIN_RIPP_01_05_1713790337915_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1713790337915.gif";
import KIN_RIPP_01_05_1713790337915_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1713790337915.json";
import KIN_RIPP_01_05_1713790337915_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1713790337915.mp4";
import KIN_PUP_01_04_1713789020841_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_04_1713789020841.gif";
import KIN_PUP_01_04_1713789020841_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_04_1713789020841.json";
import KIN_PUP_01_04_1713789020841_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_04_1713789020841.mp4";
import KIN_FLOWER_01_01_1713788692078_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_01_1713788692078.gif";
import KIN_FLOWER_01_01_1713788692078_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_01_1713788692078.json";
import KIN_FLOWER_01_01_1713788692078_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_01_1713788692078.mp4";
import KIN_BEEDAY_01_01_1713790113912_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BEEDAY_01_01_1713790113912.gif";
import KIN_BEEDAY_01_01_1713790113912_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BEEDAY_01_01_1713790113912.json";
import KIN_BEEDAY_01_01_1713790113912_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BEEDAY_01_01_1713790113912.mp4";
import KIN_TRAIL_01_04_1713776350007_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_04_1713776350007.gif";
import KIN_TRAIL_01_04_1713776350007_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_04_1713776350007.json";
import KIN_TRAIL_01_04_1713776350007_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_04_1713776350007.mp4";
import KIN_TOAST_01_01_1713788217971_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_01_1713788217971.gif";
import KIN_TOAST_01_01_1713788217971_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_01_1713788217971.json";
import KIN_TOAST_01_01_1713788217971_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_01_1713788217971.mp4";
import KIN_STAR_01_03_1713787166412_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1713787166412.gif";
import KIN_STAR_01_03_1713787166412_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1713787166412.json";
import KIN_STAR_01_03_1713787166412_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1713787166412.mp4";
import KIN_SIMPLE_01_05_1713787303627_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_05_1713787303627.gif";
import KIN_SIMPLE_01_05_1713787303627_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_05_1713787303627.json";
import KIN_SIMPLE_01_05_1713787303627_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_05_1713787303627.mp4";
import KIN_FUZZ_01_02_1713775937028_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_02_1713775937028.gif";
import KIN_FUZZ_01_02_1713775937028_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_02_1713775937028.json";
import KIN_FUZZ_01_02_1713775937028_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_02_1713775937028.mp4";
import KIN_FLOWER_01_06_1713788397899_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_06_1713788397899.gif";
import KIN_FLOWER_01_06_1713788397899_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_06_1713788397899.json";
import KIN_FLOWER_01_06_1713788397899_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_06_1713788397899.mp4";
import KIN_WRKANI_01_05_1713439017612_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_05_1713439017612.gif";
import KIN_WRKANI_01_05_1713439017612_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_05_1713439017612.json";
import KIN_WRKANI_01_05_1713439017612_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_05_1713439017612.mp4";
import KIN_TRAIL_01_02_1713438038438_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_02_1713438038438.gif";
import KIN_TRAIL_01_02_1713438038438_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_02_1713438038438.json";
import KIN_TRAIL_01_02_1713438038438_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_02_1713438038438.mp4";
import KIN_TOAST_01_03_1713438474577_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1713438474577.gif";
import KIN_TOAST_01_03_1713438474577_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1713438474577.json";
import KIN_TOAST_01_03_1713438474577_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1713438474577.mp4";
import KIN_STAR_01_01_1713438680168_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_01_1713438680168.gif";
import KIN_STAR_01_01_1713438680168_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_01_1713438680168.json";
import KIN_STAR_01_01_1713438680168_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_01_1713438680168.mp4";
import KIN_SIMPLE_01_03_1713438234339_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_03_1713438234339.gif";
import KIN_SIMPLE_01_03_1713438234339_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_03_1713438234339.json";
import KIN_SIMPLE_01_03_1713438234339_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_03_1713438234339.mp4";
import KIN_TRAIL_02_01_1704923815179_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_02_01_1704923815179.gif";
import KIN_TRAIL_02_01_1704923815179_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_02_01_1704923815179.json";
import KIN_TRAIL_02_01_1704923815179_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_02_01_1704923815179.mp4";
import unicorn_clouds_birthday_1713361421329_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/unicorn_clouds_birthday_1713361421329.gif";
import unicorn_clouds_birthday_1713361421329_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/unicorn_clouds_birthday_1713361421329.json";
import unicorn_clouds_birthday_1713361421329_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/unicorn_clouds_birthday_1713361421329.mp4";
import KIN_TRAIL_01_02_1713359980530_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_02_1713359980530.gif";
import KIN_TRAIL_01_02_1713359980530_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_02_1713359980530.json";
import KIN_TRAIL_01_02_1713359980530_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_02_1713359980530.mp4";
import KIN_TOAST_01_04_1713361191836_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_04_1713361191836.gif";
import KIN_TOAST_01_04_1713361191836_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_04_1713361191836.json";
import KIN_TOAST_01_04_1713361191836_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_04_1713361191836.mp4";
import KIN_SIMPLE_01_05_1713361000369_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_05_1713361000369.gif";
import KIN_SIMPLE_01_05_1713361000369_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_05_1713361000369.json";
import KIN_SIMPLE_01_05_1713361000369_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_05_1713361000369.mp4";
import KIN_FUZZ_01_03_1713357946823_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1713357946823.gif";
import KIN_FUZZ_01_03_1713357946823_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1713357946823.json";
import KIN_FUZZ_01_03_1713357946823_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1713357946823.mp4";
import KIN_FLOWER_01_04_1713359806965_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_04_1713359806965.gif";
import KIN_FLOWER_01_04_1713359806965_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_04_1713359806965.json";
import KIN_FLOWER_01_04_1713359806965_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_04_1713359806965.mp4";
import PIC_TRAIL_01_08_1713428895945h_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_08_1713428895945h.json";
import PIC_TRAIL_01_06_1713428895945g_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_06_1713428895945g.json";
import PIC_TRAIL_01_04_1713453076324e_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_04_1713453076324e.json";
import PIC_TRAIL_01_03_1713453076324d_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_03_1713453076324d.json";
import PIC_TRAIL_01_01_1713453076324b_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_01_1713453076324b.json";
import PIC_TRAIL_01_01_1713452880197h_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_01_1713452880197h.json";
import PIC_TRAIL_01_03_1713452880197f_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_03_1713452880197f.json";
import PIC_TRAIL_01_06_1713452880197d_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_06_1713452880197d.json";
import PIC_TRAIL_01_07_1713452880197c_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_07_1713452880197c.json";
import PIC_TRAIL_01_07_1713453076324_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_07_1713453076324.gif";
import PIC_TRAIL_01_07_1713453076324_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_07_1713453076324.json";
import PIC_TRAIL_01_07_1713453076324_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_07_1713453076324.mp4";
import PIC_TRAIL_01_08_1713452880197_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_08_1713452880197.gif";
import PIC_TRAIL_01_08_1713452880197_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_08_1713452880197.json";
import PIC_TRAIL_01_08_1713452880197_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_08_1713452880197.mp4";
import PIC_TRAIL_01_08_1713429125870_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_08_1713429125870.json";
import PIC_TRAIL_01_07_1713429076457_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_07_1713429076457.json";
import PIC_TRAIL_01_06_1713428895945_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_06_1713428895945.json";
import PIC_TRAIL_01_04_1713428223673_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_04_1713428223673.gif";
import PIC_TRAIL_01_04_1713428223673_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_04_1713428223673.json";
import PIC_TRAIL_01_04_1713428223673_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_04_1713428223673.mp4";
import PIC_TRAIL_01_03_1713427014683_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_03_1713427014683.json";
import PIC_TRAIL_01_01_1713284309699_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/PIC_TRAIL_01_01_1713284309699.json";
import KIN_DRAGON_01_06_1712076172804_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_06_1712076172804.gif";
import KIN_DRAGON_01_06_1712076172804_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_06_1712076172804.json";
import KIN_DRAGON_01_06_1712076172804_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_06_1712076172804.mp4";
import KIN_DRAGON_01_05_1712076009200_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_05_1712076009200.json";
import KIN_DRAGON_01_04_1712075842972_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_04_1712075842972.json";
import KIN_DRAGON_01_03_1712075682087_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_03_1712075682087.json";
import KIN_DRAGON_01_02_1712075403469_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_02_1712075403469.json";
import KIN_DRAGON_01_01_1712074501725_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_DRAGON_01_01_1712074501725.json";
import KIN_VINYL_01_06_1712244251202_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_06_1712244251202.json";
import KIN_VINYL_01_05_1712244135010_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_05_1712244135010.json";
import KIN_VINYL_01_04_1712244016752_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_04_1712244016752.json";
import KIN_VINYL_01_03_1712243875134_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_03_1712243875134.json";
import KIN_VINYL_01_02_1712243352660_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_02_1712243352660.json";
import KIN_VINYL_01_02_1712243352660_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_02_1712243352660.gif";
import KIN_VINYL_01_02_1712243352660_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_02_1712243352660.mp4";
import KIN_VINYL_01_01_1712243144334_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_VINYL_01_01_1712243144334.json";
import KIN_FUZZ_01_03_1710425131390_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1710425131390.json";
import KIN_FUZZ_01_03_1710425131390_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1710425131390.gif";
import KIN_FUZZ_01_03_1710425131390_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_03_1710425131390.mp4";
import KIN_FUZZ_01_02_1710424233177_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_02_1710424233177.json";
import KIN_FUZZ_01_01_1708086137253_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_01_1708086137253.gif";
import KIN_FUZZ_01_01_1708086137253_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_01_1708086137253.json";
import KIN_FUZZ_01_01_1708086137253_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FUZZ_01_01_1708086137253.mp4";
import KIN_SIMPLE_01_06_1710957904512_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_06_1710957904512.gif";
import KIN_SIMPLE_01_06_1710957904512_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_06_1710957904512.json";
import KIN_SIMPLE_01_06_1710957904512_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_06_1710957904512.mp4";
import KIN_SIMPLE_01_05_1710957845113_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_05_1710957845113.json";
import KIN_SIMPLE_01_04_1710957777694_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_04_1710957777694.json";
import KIN_SIMPLE_01_03_1710957724769_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_03_1710957724769.json";
import KIN_SIMPLE_01_02_1710957667663_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_02_1710957667663.json";
import KIN_SIMPLE_01_01_1710957601832_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_SIMPLE_01_01_1710957601832.json";
import KIN_FLOWER_01_06_1711038236271_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_06_1711038236271.gif";
import KIN_FLOWER_01_06_1711038236271_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_06_1711038236271.json";
import KIN_FLOWER_01_06_1711038236271_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_06_1711038236271.mp4";
import KIN_FLOWER_01_05_1711038056587_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_05_1711038056587.json";
import KIN_FLOWER_01_04_1711037883577_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_04_1711037883577.json";
import KIN_FLOWER_01_03_1711037715638_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_03_1711037715638.json";
import KIN_FLOWER_01_02_1711037483728_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_02_1711037483728.json";
import KIN_FLOWER_01_01_1711037042960_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_FLOWER_01_01_1711037042960.json";
import KIN_JUGGLE_01_01_1708099639178_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_JUGGLE_01_01_1708099639178.gif";
import KIN_JUGGLE_01_01_1708099639178_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_JUGGLE_01_01_1708099639178.json";
import KIN_JUGGLE_01_01_1708099639178_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_JUGGLE_01_01_1708099639178.mp4";
import KIN_EGG_01_04_1709825282295_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_04_1709825282295.json";
import KIN_EGG_01_03_1709824864086_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_03_1709824864086.json";
import KIN_EGG_01_02_1709824586381_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_02_1709824586381.json";
import KIN_EGG_01_03_1709824864086_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_03_1709824864086.gif";
import KIN_EGG_01_01_1709579418451_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_01_1709579418451.json";
import KIN_EGG_01_03_1709824864086_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_EGG_01_03_1709824864086.mp4";
import KIN_WRKANI_03_04_1709051859823_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_03_04_1709051859823.json";
import KIN_WRKANI_03_03_1709051292331_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_03_03_1709051292331.json";
import KIN_WRKANI_03_02_1709050387554_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_03_02_1709050387554.json";
import KIN_WRKANI_03_01_1708601003845_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_03_01_1708601003845.gif";
import KIN_WRKANI_03_01_1708601003845_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_03_01_1708601003845.json";
import KIN_WRKANI_03_01_1708601003845_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_03_01_1708601003845.mp4";
import KIN_WRKANI_02_04_1709137833270_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_02_04_1709137833270.json";
import KIN_WRKANI_02_03_1709137676172_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_02_03_1709137676172.json";
import KIN_WRKANI_02_02_1709137335498_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_02_02_1709137335498.json";
import KIN_WRKANI_02_01_1708599509810_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_02_01_1708599509810.gif";
import KIN_WRKANI_02_01_1708599509810_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_02_01_1708599509810.json";
import KIN_WRKANI_02_01_1708599509810_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_02_01_1708599509810.mp4";
import KIN_WRKANI_01_06_1708456586409_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_06_1708456586409.json";
import KIN_WRKANI_01_04_1708456327669_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_04_1708456327669.json";
import KIN_WRKANI_01_03_1708456099922_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_03_1708456099922.json";
import KIN_WRKANI_01_02_1708456022489_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_02_1708456022489.json";
import KIN_WRKANI_01_01_1708455749335_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_WRKANI_01_01_1708455749335.json";
import KIN_TOAST_01_03_1708975205664_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1708975205664.gif";
import KIN_TOAST_01_03_1708975205664_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1708975205664.json";
import KIN_TOAST_01_03_1708975205664_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1708975205664.mp4";
import KIN_BEEDAY_01_01_1708512594758_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BEEDAY_01_01_1708512594758.gif";
import KIN_BEEDAY_01_01_1708512594758_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BEEDAY_01_01_1708512594758.json";
import KIN_BEEDAY_01_01_1708512594758_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_BEEDAY_01_01_1708512594758.mp4";
import KIN_RIPP_01_06_1708095819724_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_06_1708095819724.gif";
import KIN_RIPP_01_06_1708095819724_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_06_1708095819724.json";
import KIN_RIPP_01_06_1708095819724_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_06_1708095819724.mp4";
import KIN_TRAIL_01_06_1708091010743_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_06_1708091010743.gif";
import KIN_TRAIL_01_06_1708091010743_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_06_1708091010743.json";
import KIN_TRAIL_01_06_1708091010743_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_06_1708091010743.mp4";
import KIN_STAR_01_02_1708091249339_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1708091249339.gif";
import KIN_STAR_01_02_1708091249339_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1708091249339.json";
import KIN_STAR_01_02_1708091249339_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1708091249339.mp4";
import KIN_GRUMPY_01_07_1708097400597_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_07_1708097400597.gif";
import KIN_GRUMPY_01_07_1708097400597_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_07_1708097400597.json";
import KIN_GRUMPY_01_07_1708097400597_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_07_1708097400597.mp4";
import KIN_GRUMPY_01_06_1706631579381_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_06_1706631579381.gif";
import KIN_GRUMPY_01_06_1706631579381_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_06_1706631579381.json";
import KIN_GRUMPY_01_06_1706631579381_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_06_1706631579381.mp4";
import KIN_GRUMPY_01_08_1706632929458_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_08_1706632929458.gif";
import KIN_GRUMPY_01_08_1706632929458_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_08_1706632929458.json";
import KIN_GRUMPY_01_08_1706632929458_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_08_1706632929458.mp4";
import KIN_PUP_01_08_1707220385643_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_08_1707220385643.gif";
import KIN_PUP_01_08_1707220385643_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_08_1707220385643.json";
import KIN_PUP_01_08_1707220385643_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_08_1707220385643.mp4";
import KIN_GRUMPY_01_04_1706630386942_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_04_1706630386942.gif";
import KIN_GRUMPY_01_04_1706630386942_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_04_1706630386942.json";
import KIN_GRUMPY_01_04_1706630386942_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_04_1706630386942.mp4";
import KIN_PUP_01_05_1707222704695_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_05_1707222704695.gif";
import KIN_PUP_01_05_1707222704695_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_05_1707222704695.json";
import KIN_PUP_01_05_1707222704695_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_05_1707222704695.mp4";
import KIN_GRUMPY_01_05_1706630934532_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_05_1706630934532.gif";
import KIN_GRUMPY_01_05_1706630934532_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_05_1706630934532.json";
import KIN_GRUMPY_01_05_1706630934532_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_05_1706630934532.mp4";
import KIN_PUP_01_07_1707230108404_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_07_1707230108404.gif";
import KIN_PUP_01_07_1707230108404_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_07_1707230108404.json";
import KIN_PUP_01_07_1707230108404_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_07_1707230108404.mp4";
import KIN_GRUMPY_01_07_1706633310879_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_07_1706633310879.gif";
import KIN_GRUMPY_01_07_1706633310879_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_07_1706633310879.json";
import KIN_GRUMPY_01_07_1706633310879_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_07_1706633310879.mp4";
import KIN_PUP_01_02_1707223022554_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_02_1707223022554.gif";
import KIN_PUP_01_02_1707223022554_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_02_1707223022554.json";
import KIN_PUP_01_02_1707223022554_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_02_1707223022554.mp4";
import KIN_GRUMPY_01_02_1706629944548_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_02_1706629944548.gif";
import KIN_GRUMPY_01_02_1706629944548_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_02_1706629944548.json";
import KIN_GRUMPY_01_02_1706629944548_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_02_1706629944548.mp4";
import KIN_PUP_01_08_1707220882153_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_08_1707220882153.json";

import KIN_PUP_01_07_1707220318607_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_07_1707220318607.json";
import KIN_PUP_01_06_1707220261947_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_06_1707220261947.json";
import KIN_PUP_01_05_1707220209666_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_05_1707220209666.json";
import KIN_PUP_01_04_1707220151583_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_04_1707220151583.json";
import KIN_PUP_01_03_1707220008848_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_03_1707220008848.json";
import KIN_PUP_01_02_1707219925651_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_02_1707219925651.json";
import KIN_PUP_01_01_1707154743962_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_01_1707154743962.gif";
import KIN_PUP_01_01_1707154743962_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_01_1707154743962.json";
import KIN_PUP_01_01_1707154743962_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_PUP_01_01_1707154743962.mp4";
import KIN_GRUMPY_01_08_1707219361975_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_08_1707219361975.json";
import KIN_GRUMPY_01_07_1707219299310_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_07_1707219299310.json";
import KIN_GRUMPY_01_06_1707219253791_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_06_1707219253791.gif";
import KIN_GRUMPY_01_06_1707219253791_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_06_1707219253791.json";
import KIN_GRUMPY_01_06_1707219253791_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_06_1707219253791.mp4";
import KIN_GRUMPY_01_05_1707219210013_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_05_1707219210013.json";
import KIN_GRUMPY_01_04_1707219188063_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_04_1707219188063.json";
import KIN_GRUMPY_01_03_1707219103031_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_03_1707219103031.json";
import KIN_GRUMPY_01_02_1707219049476_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_02_1707219049476.json";
import KIN_GRUMPY_01_01_1706624984594_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_01_1706624984594.json";
import KIN_GRUMPY_01_03_1706629987261_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_03_1706629987261.gif";
import KIN_GRUMPY_01_03_1706629987261_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_03_1706629987261.json";
import KIN_GRUMPY_01_03_1706629987261_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_03_1706629987261.mp4";
import KIN_TRAIL_01_01_1705929056555_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_01_1705929056555.gif";
import KIN_TRAIL_01_01_1705929056555_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_01_1705929056555.json";
import KIN_TRAIL_01_01_1705929056555_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TRAIL_01_01_1705929056555.mp4";
import KIN_TOAST_01_06_1706697866964_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_06_1706697866964.gif";
import KIN_TOAST_01_06_1706697866964_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_06_1706697866964.json";
import KIN_TOAST_01_06_1706697866964_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_06_1706697866964.mp4";
import KIN_TOAST_01_05_1706697675053_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_05_1706697675053.gif";
import KIN_TOAST_01_05_1706697675053_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_05_1706697675053.json";
import KIN_TOAST_01_05_1706697675053_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_05_1706697675053.mp4";
import KIN_GRUMPY_01_08_1706633174896_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_08_1706633174896.gif";
import KIN_GRUMPY_01_08_1706633174896_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_08_1706633174896.json";
import KIN_GRUMPY_01_08_1706633174896_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_GRUMPY_01_08_1706633174896.mp4";
import KIN_TOAST_01_03_1706200484846_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1706200484846.gif";
import KIN_TOAST_01_03_1706200484846_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1706200484846.json";
import KIN_TOAST_01_03_1706200484846_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_03_1706200484846.mp4";
import KIN_TOAST_01_04_1706201376355_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_04_1706201376355.gif";
import KIN_TOAST_01_04_1706201376355_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_04_1706201376355.json";
import KIN_TOAST_01_04_1706201376355_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_04_1706201376355.mp4";
import KIN_TOAST_01_02_1706200238879_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_02_1706200238879.gif";
import KIN_TOAST_01_02_1706200238879_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_02_1706200238879.json";
import KIN_TOAST_01_02_1706200238879_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_02_1706200238879.mp4";
import KIN_TOAST_01_01_1706199537719_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_TOAST_01_01_1706199537719.json";
import KIN_RIPP_01_02_1706120193991_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_02_1706120193991.gif";
import KIN_RIPP_01_02_1706120193991_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_02_1706120193991.json";
import KIN_RIPP_01_02_1706120193991_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_02_1706120193991.mp4";
import KIN_RIPP_01_05_1706119852986_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1706119852986.gif";
import KIN_RIPP_01_05_1706119852986_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1706119852986.json";
import KIN_RIPP_01_05_1706119852986_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1706119852986.mp4";
import KIN_RIPP_01_05_1705956351303_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1705956351303.gif";
import KIN_RIPP_01_05_1705956351303_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1705956351303.json";
import KIN_RIPP_01_05_1705956351303_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1705956351303.mp4";
import KIN_RIPP_01_01_1706119104225_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1706119104225.gif";
import KIN_RIPP_01_01_1706119104225_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1706119104225.json";
import KIN_RIPP_01_01_1706119104225_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1706119104225.mp4";
import KIN_RIPP_01_03_1706118577510_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_03_1706118577510.gif";
import KIN_RIPP_01_03_1706118577510_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_03_1706118577510.json";
import KIN_RIPP_01_03_1706118577510_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_03_1706118577510.mp4";
import KIN_RIPP_01_02_1706118913919_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_02_1706118913919.gif";
import KIN_RIPP_01_02_1706118913919_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_02_1706118913919.json";
import KIN_RIPP_01_02_1706118913919_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_02_1706118913919.mp4";
import KIN_RIPP_01_04_1706119328432_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_04_1706119328432.gif";
import KIN_RIPP_01_04_1706119328432_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_04_1706119328432.json";
import KIN_RIPP_01_04_1706119328432_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_04_1706119328432.mp4";
import KIN_RIPP_01_01_1705857585095_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1705857585095.gif";
import KIN_RIPP_01_01_1705857585095_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1705857585095.json";
import KIN_RIPP_01_01_1705857585095_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_01_1705857585095.mp4";
import KIN_RIPP_01_05_1706119647505_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1706119647505.gif";
import KIN_RIPP_01_05_1706119647505_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1706119647505.json";
import KIN_RIPP_01_05_1706119647505_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_RIPP_01_05_1706119647505.mp4";
import KIN_STAR_01_02_1706118416827_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1706118416827.gif";
import KIN_STAR_01_02_1706118416827_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1706118416827.json";
import KIN_STAR_01_02_1706118416827_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1706118416827.mp4";
import KIN_STAR_01_03_1706118289742_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1706118289742.gif";
import KIN_STAR_01_03_1706118289742_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1706118289742.json";
import KIN_STAR_01_03_1706118289742_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1706118289742.mp4";
import KIN_STAR_01_03_1705928508849_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1705928508849.gif";
import KIN_STAR_01_03_1705928508849_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1705928508849.json";
import KIN_STAR_01_03_1705928508849_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1705928508849.mp4";
import KIN_STAR_01_02_1706117462994_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1706117462994.gif";
import KIN_STAR_01_02_1706117462994_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1706117462994.json";
import KIN_STAR_01_02_1706117462994_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1706117462994.mp4";
import KIN_STAR_01_04_1706117162466_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1706117162466.gif";
import KIN_STAR_01_04_1706117162466_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1706117162466.json";
import KIN_STAR_01_04_1706117162466_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1706117162466.mp4";
import KIN_STAR_01_03_1706117268326_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1706117268326.gif";
import KIN_STAR_01_03_1706117268326_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1706117268326.json";
import KIN_STAR_01_03_1706117268326_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1706117268326.mp4";
import KIN_STAR_01_01_1706117703249_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_01_1706117703249.gif";
import KIN_STAR_01_01_1706117703249_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_01_1706117703249.json";
import KIN_STAR_01_01_1706117703249_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_01_1706117703249.mp4";
import KIN_STAR_01_04_1705854062686_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1705854062686.gif";
import KIN_STAR_01_04_1705854062686_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1705854062686.json";
import KIN_STAR_01_04_1705854062686_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1705854062686.mp4";
import KIN_STAR_01_03_1705495064593_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1705495064593.gif";
import KIN_STAR_01_03_1705495064593_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1705495064593.json";
import KIN_STAR_01_03_1705495064593_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_03_1705495064593.mp4";
import KIN_STAR_01_02_1705513441318_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_02_1705513441318.json";
import KIN_STAR_01_01_1705512850917_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_01_1705512850917.json";
import KIN_STAR_01_04_1706117900353_gif from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1706117900353.gif";
import KIN_STAR_01_04_1706117900353_json from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1706117900353.json";
import KIN_STAR_01_04_1706117900353_mp4 from "../../../../../webgl/scenes/kindeo/assets/covers_exported/auto_generated/KIN_STAR_01_04_1706117900353.mp4";

import { mediaTypes } from "redux/media/selectors";
import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import { OCCASION_TYPES } from "redux/occasions/selectors";

import albumCurvey6GifThumb from "@kindeo/assets/videos/thumbnails/album/curvey-6.gif";
import albumCurvey6VideoThumb from "@kindeo/assets/videos/thumbnails/album/curvey-6.mp4";
import albumRipples5GifThumb from "@kindeo/assets/videos/thumbnails/album/ripples-5.gif";
import albumRipples5VideoThumb from "@kindeo/assets/videos/thumbnails/album/ripples-5.mp4";
import albumSimple0GifThumb from "@kindeo/assets/videos/thumbnails/album/simple-1.gif";
import albumSimple0VideoThumb from "@kindeo/assets/videos/thumbnails/album/simple-1.mp4";
import albumStarburst0GifThumb from "@kindeo/assets/videos/thumbnails/album/starburst-0.gif";
import albumStarburst0VideoThumb from "@kindeo/assets/videos/thumbnails/album/starburst-0.mp4";
import albumTrail2PicGifThumb from "@kindeo/assets/videos/thumbnails/album/trail-2-photo.gif";
import albumTrail2PicVideoThumb from "@kindeo/assets/videos/thumbnails/album/trail-2-photo.mp4";

import workAnniversaryStarburst0Gif from "@kindeo/assets/videos/thumbnails/work-anniversary/starburst-0.gif";
import workAnniversaryStarburst0Mp4 from "@kindeo/assets/videos/thumbnails/work-anniversary/starburst-0.mp4";
import workAnniversaryCurvey5Gif from "@kindeo/assets/videos/thumbnails/work-anniversary/curvey-5.gif";
import workAnniversaryCurvey5Mp4 from "@kindeo/assets/videos/thumbnails/work-anniversary/curvey-5.mp4";
import workAnniversaryCakeGifThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/cake-1.gif";
import workAnniversaryCakeVideoThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/cake-1.mp4";

import anniversaryCakeGifThumb from "@kindeo/assets/videos/thumbnails/anniversary/cake-1.gif";
import anniversaryCakeVideoThumb from "@kindeo/assets/videos/thumbnails/anniversary/cake-1.mp4";
import anniversaryCurveyGifThumb2 from "@kindeo/assets/videos/thumbnails/anniversary/curvey-3.gif";
import anniversaryCurveyVideoThumb2 from "@kindeo/assets/videos/thumbnails/anniversary/curvey-3.mp4";
import anniversaryFlowersShapeGifThumb from "@kindeo/assets/videos/thumbnails/anniversary/flowers-shape-4.gif";
import anniversaryFlowersShapeVideoThumb from "@kindeo/assets/videos/thumbnails/anniversary/flowers-shape-4.mp4";
import workAnniversaryFlowersShapeGifThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/flowers-shape-4.gif";
import workAnniversaryFlowersShapeVideoThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/flowers-shape-4.mp4";
import anniversaryHeartsGifThumb from "@kindeo/assets/videos/thumbnails/anniversary/hearts-2.gif";
import anniversaryHeartsVideoThumb from "@kindeo/assets/videos/thumbnails/anniversary/hearts-2.mp4";
import anniversaryRipplesGifThumb from "@kindeo/assets/videos/thumbnails/anniversary/ripples-5.gif";
import anniversaryRipplesVideoThumb from "@kindeo/assets/videos/thumbnails/anniversary/ripples-5.mp4";
import anniversarySimpleGifThumb from "@kindeo/assets/videos/thumbnails/anniversary/simple-1.gif";
import anniversarySimpleVideoThumb from "@kindeo/assets/videos/thumbnails/anniversary/simple-1.mp4";
import anniversaryStarburstGifThumb from "@kindeo/assets/videos/thumbnails/anniversary/starburst-0.gif";
import anniversaryStarburstVideoThumb from "@kindeo/assets/videos/thumbnails/anniversary/starburst-0.mp4";

import workAnniversaryHeartsGifThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/hearts-2.gif";
import workAnniversaryHeartsVideoThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/hearts-2.mp4";
import workAnniversarySimpleGifThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/simple-1.gif";
import workAnniversarySimpleVideoThumb from "@kindeo/assets/videos/thumbnails/work-anniversary/simple-1.mp4";

import anniversaryTrailGifThumb from "@kindeo/assets/videos/thumbnails/anniversary/trail-0-woman.gif";
import anniversaryTrailVideoThumb from "@kindeo/assets/videos/thumbnails/anniversary/trail-0-woman.mp4";

// import brewtiful_birthdayVideoThumb from "@kindeo/assets/covers_exported/brewtiful_birthday/brewtiful_birthday.mp4";
// import brewtiful_birthdayGifThumb from "@kindeo/assets/covers_exported/brewtiful_birthday/brewtiful_birthday.gif";
// import brewtiful_birthday_data from "@kindeo/assets/covers_exported/brewtiful_birthday/brewtiful_birthday.json";

// import beerilliant_birthdayVideoThumb from "@kindeo/assets/covers_exported/beerilliant_birthday/beerilliant_birthday.mp4";
// import beerilliant_birthdayGifThumb from "@kindeo/assets/covers_exported/beerilliant_birthday/beerilliant_birthday.gif";
// import beerilliant_birthday_data from "@kindeo/assets/covers_exported/beerilliant_birthday/beerilliant_birthday.json";

import gif1713362807735 from "@kindeo/assets/videos/thumbnails/1713362807735.gif";
import mp41713362807735 from "@kindeo/assets/videos/thumbnails/1713362807735.mp4";

import gif1701189402547 from "@kindeo/assets/videos/thumbnails/1701189402547.gif";
import mp41701189402547 from "@kindeo/assets/videos/thumbnails/1701189402547.mp4";
import gif1701190355562 from "@kindeo/assets/videos/thumbnails/1701190355562.gif";
import mp41701190355562 from "@kindeo/assets/videos/thumbnails/1701190355562.mp4";
import gif1709031690783 from "@kindeo/assets/videos/thumbnails/1709031690783.gif";
import mp41709031690783 from "@kindeo/assets/videos/thumbnails/1709031690783.mp4";
import gif1709031690784 from "@kindeo/assets/videos/thumbnails/1709031690784.gif";
import mp41709031690784 from "@kindeo/assets/videos/thumbnails/1709031690784.mp4";
import gif1713793197558 from "@kindeo/assets/videos/thumbnails/1713793197558.gif";
import mp41713793197558 from "@kindeo/assets/videos/thumbnails/1713793197558.mp4";
import gif1701192692014 from "@kindeo/assets/videos/thumbnails/1701192692014.gif";
import mp41701192692014 from "@kindeo/assets/videos/thumbnails/1701192692014.mp4";
import gif1701193065349 from "@kindeo/assets/videos/thumbnails/1701193065349.gif";
import mp41701193065349 from "@kindeo/assets/videos/thumbnails/1701193065349.mp4";
import gif1701193660064 from "@kindeo/assets/videos/thumbnails/1701193660064.gif";
import mp41701193660064 from "@kindeo/assets/videos/thumbnails/1701193660064.mp4";
import gif1705957011407 from "@kindeo/assets/videos/thumbnails/1705957011407.gif";
import mp41705957011407 from "@kindeo/assets/videos/thumbnails/1705957011407.mp4";

// christmas
import gif1701295397786 from "@kindeo/assets/videos/thumbnails/1701295397786.gif";
import mp41701295397786 from "@kindeo/assets/videos/thumbnails/1701295397786.mp4";
import gif1701678198757 from "@kindeo/assets/videos/thumbnails/1701678198757.gif";
import mp41701678198757 from "@kindeo/assets/videos/thumbnails/1701678198757.mp4";
import gif1701679568062 from "@kindeo/assets/videos/thumbnails/1701679568062.gif";
import mp41701679568062 from "@kindeo/assets/videos/thumbnails/1701679568062.mp4";
import gif1701680613621 from "@kindeo/assets/videos/thumbnails/1701680613621.gif";
import mp41701680613621 from "@kindeo/assets/videos/thumbnails/1701680613621.mp4";
import gif1701895694738 from "@kindeo/assets/videos/thumbnails/1701895694738.gif";
import mp41701895694738 from "@kindeo/assets/videos/thumbnails/1701895694738.mp4";
import gif1701944003068 from "@kindeo/assets/videos/thumbnails/1701944003068.gif";
import mp41701944003068 from "@kindeo/assets/videos/thumbnails/1701944003068.mp4";

import gif1702031024584 from "@kindeo/assets/videos/thumbnails/1702031024584.gif";
import mp41702031024584 from "@kindeo/assets/videos/thumbnails/1702031024584.mp4";
import gif1702031219168 from "@kindeo/assets/videos/thumbnails/1702031219168.gif";
import mp41702031219168 from "@kindeo/assets/videos/thumbnails/1702031219168.mp4";
import gif1702031889156 from "@kindeo/assets/videos/thumbnails/1702031889156.gif";
import mp41702031889156 from "@kindeo/assets/videos/thumbnails/1702031889156.mp4";
import gif1702032074564 from "@kindeo/assets/videos/thumbnails/1702032074564.gif";
import mp41702032074564 from "@kindeo/assets/videos/thumbnails/1702032074564.mp4";
import gif1702032568233 from "@kindeo/assets/videos/thumbnails/1702032568233.gif";
import mp41702032568233 from "@kindeo/assets/videos/thumbnails/1702032568233.mp4";
import gif1702054350835 from "@kindeo/assets/videos/thumbnails/1702054350835.gif";
import mp41702054350835 from "@kindeo/assets/videos/thumbnails/1702054350835.mp4";
import gif1702055169110 from "@kindeo/assets/videos/thumbnails/1702055169110.gif";
import mp41702055169110 from "@kindeo/assets/videos/thumbnails/1702055169110.mp4";
import gif1713447219280 from "@kindeo/assets/videos/thumbnails/1713447219280.gif";
import mp41713447219280 from "@kindeo/assets/videos/thumbnails/1713447219280.mp4";
import gif1702055776753 from "@kindeo/assets/videos/thumbnails/1702055776753.gif";
import mp41702055776753 from "@kindeo/assets/videos/thumbnails/1702055776753.mp4";
import gif1702057169868 from "@kindeo/assets/videos/thumbnails/1702057169868.gif";
import mp41702057169868 from "@kindeo/assets/videos/thumbnails/1702057169868.mp4";
import gif1702058034876 from "@kindeo/assets/videos/thumbnails/1702058034876.gif";
import mp41702058034876 from "@kindeo/assets/videos/thumbnails/1702058034876.mp4";
import gif1702398823609 from "@kindeo/assets/videos/thumbnails/1702398823609.gif";
import mp41702398823609 from "@kindeo/assets/videos/thumbnails/1702398823609.mp4";
import gif1708090526357 from "@kindeo/assets/videos/thumbnails/1708090526357.gif";
import mp41708090526357 from "@kindeo/assets/videos/thumbnails/1708090526357.mp4";

import birthday_40_cheekyVideoThumb from "@kindeo/assets/covers_exported/birthday_40_cheeky/birthday_40_cheeky.mp4";
import birthday_40_cheekyGifThumb from "@kindeo/assets/covers_exported/birthday_40_cheeky/birthday_40_cheeky.gif";
import birthday_40_cheeky_data from "@kindeo/assets/covers_exported/birthday_40_cheeky/birthday_40_cheeky.json";

import birthday_50_cheekyVideoThumb from "@kindeo/assets/covers_exported/birthday_50_cheeky/birthday_50_cheeky.mp4";
import birthday_50_cheekyGifThumb from "@kindeo/assets/covers_exported/birthday_50_cheeky/birthday_50_cheeky.gif";
import birthday_50_cheeky_data from "@kindeo/assets/covers_exported/birthday_50_cheeky/birthday_50_cheeky.json";

import dictionary_birthdayVideoThumb from "@kindeo/assets/covers_exported/dictionary_birthday/dictionary_birthday.mp4";
import dictionary_birthdayGifThumb from "@kindeo/assets/covers_exported/dictionary_birthday/dictionary_birthday.gif";
import dictionary_birthday_data from "@kindeo/assets/covers_exported/dictionary_birthday/dictionary_birthday.json";

import magician_levitationVideoThumb from "@kindeo/assets/covers_exported/magician_levitation/magician_levitation.mp4";
import magician_levitationGifThumb from "@kindeo/assets/covers_exported/magician_levitation/magician_levitation.gif";
import magician_levitation_data from "@kindeo/assets/covers_exported/magician_levitation/magician_levitation.json";

import unicorn_clouds_birthdayVideoThumb from "@kindeo/assets/covers_exported/unicorn_clouds_birthday/unicorn_clouds_birthday.mp4";
import unicorn_clouds_birthdayGifThumb from "@kindeo/assets/covers_exported/unicorn_clouds_birthday/unicorn_clouds_birthday.gif";
import unicorn_clouds_birthday_data from "@kindeo/assets/covers_exported/unicorn_clouds_birthday/unicorn_clouds_birthday.json";

// import bums_1VideoThumb from "@kindeo/assets/covers_exported/bums_1/bums_1.mp4";
// import bums_1GifThumb from "@kindeo/assets/covers_exported/bums_1/bums_1.gif";
// import bums_1_data from "@kindeo/assets/covers_exported/bums_1/bums_1.json";

import trail1704464303769 from "@kindeo/assets/covers_exported/trails2/1704464303769.json";
import trail1704464303769mp4 from "@kindeo/assets/covers_exported/trails2/1704464303769.mp4";
import trail1704464303769gif from "@kindeo/assets/covers_exported/trails2/1704464303769.gif";
import trail1704464410051 from "@kindeo/assets/covers_exported/trails2/1704464410051.json";
import trail1704464410051mp4 from "@kindeo/assets/covers_exported/trails2/1704464410051.mp4";
import trail1704464410051gif from "@kindeo/assets/covers_exported/trails2/1704464410051.gif";
import trail1704466938730 from "@kindeo/assets/covers_exported/trails2/1704466938730.json";
import trail1704467109860 from "@kindeo/assets/covers_exported/trails2/1704467109860.json";
import trail1704467247973 from "@kindeo/assets/covers_exported/trails2/1704467247973.json";
import trail1704468169402 from "@kindeo/assets/covers_exported/trails2/1704468169402.json";
import trail1704469461273 from "@kindeo/assets/covers_exported/trails2/1704469461273.json";
import trail1704470147702 from "@kindeo/assets/covers_exported/trails2/1704470147702.json";

import KIN_TRAIL_01_01_1704918360756gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_01_1704918360756.gif";
import KIN_TRAIL_01_01_1704918360756 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_01_1704918360756.json";
import KIN_TRAIL_01_01_1704918360756mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_01_1704918360756.mp4";
import KIN_TRAIL_01_01_1704923387130gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_01_1704923387130.gif";
import KIN_TRAIL_01_01_1704923387130 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_01_1704923387130.json";
import KIN_TRAIL_01_01_1704923387130mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_01_1704923387130.mp4";
import KIN_TRAIL_01_02_1704918840170gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_02_1704918840170.gif";
import KIN_TRAIL_01_02_1704918840170 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_02_1704918840170.json";
import KIN_TRAIL_01_02_1704918840170mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_02_1704918840170.mp4";
import KIN_TRAIL_01_03_1704923088263gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_03_1704923088263.gif";
import KIN_TRAIL_01_03_1704923088263 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_03_1704923088263.json";
import KIN_TRAIL_01_03_1704923088263mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_03_1704923088263.mp4";
import KIN_TRAIL_01_04_1704924628103 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_04_1704924628103.json";
import KIN_TRAIL_01_04_1704924628103gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_04_1704924628103.gif";
import KIN_TRAIL_01_04_1704924628103mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_01_04_1704924628103.mp4";
import KIN_TRAIL_02_01_1704918499855gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_01_1704918499855.gif";
import KIN_TRAIL_02_01_1704918499855 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_01_1704918499855.json";
import KIN_TRAIL_02_01_1704918499855mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_01_1704918499855.mp4";
import KIN_TRAIL_02_01_1704923815179 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_01_1704923815179.json";
import KIN_TRAIL_02_02_1704924098070gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_02_1704924098070.gif";
import KIN_TRAIL_02_02_1704924098070 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_02_1704924098070.json";
import KIN_TRAIL_02_02_1704924098070mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_02_1704924098070.mp4";
import KIN_TRAIL_02_03_1704919391244gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_03_1704919391244.gif";
import KIN_TRAIL_02_03_1704919391244 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_03_1704919391244.json";
import KIN_TRAIL_02_03_1704919391244mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_03_1704919391244.mp4";
import KIN_TRAIL_02_04_1704923218152gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_04_1704923218152.gif";
import KIN_TRAIL_02_04_1704923218152 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_04_1704923218152.json";
import KIN_TRAIL_02_04_1704923218152mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_04_1704923218152.mp4";
import KIN_TRAIL_02_04_1704925045956gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_04_1704925045956.gif";
import KIN_TRAIL_02_04_1704925045956 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_04_1704925045956.json";
import KIN_TRAIL_02_04_1704925045956mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_02_04_1704925045956.mp4";
import KIN_TRAIL_03_01_1704923630727gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_01_1704923630727.gif";
import KIN_TRAIL_03_01_1704923630727 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_01_1704923630727.json";
import KIN_TRAIL_03_01_1704923630727mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_01_1704923630727.mp4";
import KIN_TRAIL_03_02_1704918923246 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_02_1704918923246.json";
import KIN_TRAIL_03_02_1704918923246gif from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_02_1704918923246.gif";
import KIN_TRAIL_03_02_1704918923246mp4 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_02_1704918923246.mp4";
import KIN_TRAIL_03_03_1704923937904 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_03_1704923937904.json";
import KIN_TRAIL_03_04_1704924849342 from "@kindeo/assets/covers_exported/trails2/KIN_TRAIL_03_04_1704924849342.json";

import fathers_vader_day_cardVideoThumb from "@kindeo/assets/covers_exported/fathers_vader_day_card/fathers_vader_day_card.mp4";
import fathers_vader_day_cardGifThumb from "@kindeo/assets/covers_exported/fathers_vader_day_card/fathers_vader_day_card.gif";
import fathers_vader_day_card_data from "@kindeo/assets/covers_exported/fathers_vader_day_card/fathers_vader_day_card.json";

// import fathers_day_we_re_muffinVideoThumb from "@kindeo/assets/covers_exported/fathers_day_we_re_muffin/fathers_day_we_re_muffin.mp4";
// import fathers_day_we_re_muffinGifThumb from "@kindeo/assets/covers_exported/fathers_day_we_re_muffin/fathers_day_we_re_muffin.gif";
// import fathers_day_we_re_muffin_data from "@kindeo/assets/covers_exported/fathers_day_we_re_muffin/fathers_day_we_re_muffin.json";

import dad_lemonsVideoThumb from "@kindeo/assets/covers_exported/dad_lemons/dad_lemons.mp4";
import dad_lemonsGifThumb from "@kindeo/assets/covers_exported/dad_lemons/dad_lemons.gif";
import dad_lemons_data from "@kindeo/assets/covers_exported/dad_lemons/dad_lemons.json";

import hedgehog_birthdayVideoThumb from "@kindeo/assets/covers_exported/hedgehog_birthday/hedgehog_birthday.mp4";
import hedgehog_birthdayGifThumb from "@kindeo/assets/covers_exported/hedgehog_birthday/hedgehog_birthday.gif";
import hedgehog_birthday_data from "@kindeo/assets/covers_exported/hedgehog_birthday/hedgehog_birthday.json";

import oldiVideoThumb from "@kindeo/assets/covers_exported/oldi/oldi.mp4";
import oldiGifThumb from "@kindeo/assets/covers_exported/oldi/oldi.gif";
import oldi_data from "@kindeo/assets/covers_exported/oldi/oldi.json";

import borat_niceVideoThumb from "@kindeo/assets/covers_exported/borat_nice/borat_nice.mp4";
import borat_niceGifThumb from "@kindeo/assets/covers_exported/borat_nice/borat_nice.gif";
import borat_nice_data from "@kindeo/assets/covers_exported/borat_nice/borat_nice.json";

import theoffice_roastVideoThumb from "@kindeo/assets/covers_exported/theoffice_roast/theoffice_roast.mp4";
import theoffice_roastGifThumb from "@kindeo/assets/covers_exported/theoffice_roast/theoffice_roast.gif";
import theoffice_roast_data from "@kindeo/assets/covers_exported/theoffice_roast/theoffice_roast.json";

import birthdayCake1GifThumb from "@kindeo/assets/videos/thumbnails/birthday/cake-1.gif";
import birthdayCake1VideoThumb from "@kindeo/assets/videos/thumbnails/birthday/cake-1.mp4";
import birthdayFlowersShape4GifThumb from "@kindeo/assets/videos/thumbnails/birthday/flowers-shape-4.gif";
import birthdayFlowersShape4VideoThumb from "@kindeo/assets/videos/thumbnails/birthday/flowers-shape-4.mp4";
import birthdayFlowersText0GifThumb from "@kindeo/assets/videos/thumbnails/birthday/flowers-text-0.gif";
import birthdayFlowersText0VideoThumb from "@kindeo/assets/videos/thumbnails/birthday/flowers-text-0.mp4";
import birthdayHearts2GifThumb from "@kindeo/assets/videos/thumbnails/birthday/hearts-1.gif";
import birthdayHearts2VideoThumb from "@kindeo/assets/videos/thumbnails/birthday/hearts-1.mp4";
import birthdayRipples5GifThumb from "@kindeo/assets/videos/thumbnails/birthday/ripples-2.gif";
import birthdayRipples5VideoThumb from "@kindeo/assets/videos/thumbnails/birthday/ripples-2.mp4";
import birthdaySimple1GifThumb from "@kindeo/assets/videos/thumbnails/birthday/default-0.gif";
import birthdaySimple1VideoThumb from "@kindeo/assets/videos/thumbnails/birthday/default-0.mp4";
import birthdayStarburst0GifThumb from "@kindeo/assets/videos/thumbnails/birthday/starburst-0.gif";
import birthdayStarburst0VideoThumb from "@kindeo/assets/videos/thumbnails/birthday/starburst-0.mp4";
import birthdayTrail0PicGifThumb from "@kindeo/assets/videos/thumbnails/birthday/trail-0-photo-woman.gif";
import birthdayTrail0PicVideoThumb from "@kindeo/assets/videos/thumbnails/birthday/trail-0-photo-woman.mp4";

import christmasCurveyVideoThumb from "@kindeo/assets/videos/thumbnails/christmas/curvey.mp4";
import christmasCurveyGifThumb from "@kindeo/assets/videos/thumbnails/christmas/curvey.gif";
import christmasCake1VideoThumb from "@kindeo/assets/videos/thumbnails/christmas/cake-1.mp4";
import christmasHearts5VideoThumb from "@kindeo/assets/videos/thumbnails/christmas/hearts-5.mp4";
import christmasRipples2VideoThumb from "@kindeo/assets/videos/thumbnails/christmas/ripples-2.mp4";
import christmasSimple1VideoThumb from "@kindeo/assets/videos/thumbnails/christmas/simple-1.mp4";
import christmasStarburst1VideoThumb from "@kindeo/assets/videos/thumbnails/christmas/starburst-1.mp4";
import christmasTrail3PicVideoThumb from "@kindeo/assets/videos/thumbnails/christmas/trail-3-pic.mp4";
import christmasCake1GifThumb from "@kindeo/assets/videos/thumbnails/christmas/cake-1.gif";
import christmasHearts5GifThumb from "@kindeo/assets/videos/thumbnails/christmas/hearts-5.gif";
import christmasRipples2GifThumb from "@kindeo/assets/videos/thumbnails/christmas/ripples-2.gif";
import christmasSimple1GifThumb from "@kindeo/assets/videos/thumbnails/christmas/simple-1.gif";
import christmasStarburst1GifThumb from "@kindeo/assets/videos/thumbnails/christmas/starburst-1.gif";
import christmasTrail3PicGifThumb from "@kindeo/assets/videos/thumbnails/christmas/trail-3-pic.gif";
import christmasXmas2VideoThumb from "@kindeo/assets/videos/thumbnails/christmas/xmas-2.mp4";
import christmasXmas2GifThumb from "@kindeo/assets/videos/thumbnails/christmas/xmas-2.gif";

import congratsCurvey0GifThumb from "@kindeo/assets/videos/thumbnails/congrats/curvey-0.gif";
import congratsCurvey0VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/curvey-0.mp4";
import congratsCurvey1GifThumb from "@kindeo/assets/videos/thumbnails/congrats/curvey-1.gif";
import congratsCurvey1VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/curvey-1.mp4";
import congratsFlowersText0GifThumb from "@kindeo/assets/videos/thumbnails/congrats/flowers-text-0.gif";
import congratsFlowersText0VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/flowers-text-0.mp4";
import congratsHearts1GifThumb from "@kindeo/assets/videos/thumbnails/congrats/hearts-0.gif";
import congratsHearts1VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/hearts-0.mp4";
import congratsRipples4GifThumb from "@kindeo/assets/videos/thumbnails/congrats/ripples-5.gif";
import congratsRipples4VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/ripples-5.mp4";
import congratsRocket0GifThumb from "@kindeo/assets/videos/thumbnails/congrats/rocket-1.gif";
import congratsRocket0VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/rocket-1.mp4";
import congratsSimple1GifThumb from "@kindeo/assets/videos/thumbnails/congrats/simple-1.gif";
import congratsSimple1VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/simple-1.mp4";
import congratsStarburst1GifThumb from "@kindeo/assets/videos/thumbnails/congrats/starburst-0.gif";
import congratsStarburst1VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/starburst-0.mp4";
import congratsTrail6GifThumb from "@kindeo/assets/videos/thumbnails/congrats/trail-5.gif";
import congratsTrail6VideoThumb from "@kindeo/assets/videos/thumbnails/congrats/trail-5.mp4";

import customAstronaut1GifThumb from "@kindeo/assets/videos/thumbnails/custom/astronaut-0.gif";
import customAstronaut1VideoThumb from "@kindeo/assets/videos/thumbnails/custom/astronaut-0.mp4";
import customBoat4GifThumb from "@kindeo/assets/videos/thumbnails/custom/boat-3.gif";
import customBoat4VideoThumb from "@kindeo/assets/videos/thumbnails/custom/boat-3.mp4";
import customCake7GifThumb from "@kindeo/assets/videos/thumbnails/custom/cake-6.gif";
import customCake7VideoThumb from "@kindeo/assets/videos/thumbnails/custom/cake-6.mp4";
import customCurvey1GifThumb from "@kindeo/assets/videos/thumbnails/custom/curvey-1.gif";
import customCurvey1VideoThumb from "@kindeo/assets/videos/thumbnails/custom/curvey-1.mp4";
import customFlowersShape0GifThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-shape-0.gif";
import customFlowersShape0VideoThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-shape-0.mp4";
import customFlowersShape0PhotoGifThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-shape-0-photo.gif";
import customFlowersShape0PhotoVideoThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-shape-0-photo.mp4";

import getWellFlowersShape4PhotoGifThumb from "@kindeo/assets/videos/thumbnails/get-well/flowers-shape-4-photo.gif";
import getWellFlowersShape4PhotoVideoThumb from "@kindeo/assets/videos/thumbnails/get-well/flowers-shape-4-photo.mp4";

import customFlowersShape1GifThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-shape-1.gif";
import customFlowersShape1VideoThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-shape-1.mp4";
import customFlowersText0GifThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-text-0.gif";
import customFlowersText0VideoThumb from "@kindeo/assets/videos/thumbnails/custom/flowers-text-0.mp4";
import customHearts6GifThumb from "@kindeo/assets/videos/thumbnails/custom/hearts-6.gif";
import customHearts6VideoThumb from "@kindeo/assets/videos/thumbnails/custom/hearts-6.mp4";
import customRipples3GifThumb from "@kindeo/assets/videos/thumbnails/custom/ripples-4.gif";
import customRipples3VideoThumb from "@kindeo/assets/videos/thumbnails/custom/ripples-4.mp4";
import customRocket1GifThumb from "@kindeo/assets/videos/thumbnails/custom/rocket-1.gif";
import customRocket1VideoThumb from "@kindeo/assets/videos/thumbnails/custom/rocket-1.mp4";
import customSimple1GifThumb from "@kindeo/assets/videos/thumbnails/custom/simple-0.gif";
import customSimple1VideoThumb from "@kindeo/assets/videos/thumbnails/custom/simple-0.mp4";
import customStarburst2GifThumb from "@kindeo/assets/videos/thumbnails/custom/starburst-1.gif";
import customStarburst2VideoThumb from "@kindeo/assets/videos/thumbnails/custom/starburst-1.mp4";
import customTrail2PhotoGifThumb from "@kindeo/assets/videos/thumbnails/custom/trail-2-photo.gif";
import customTrail2PhotoVideoThumb from "@kindeo/assets/videos/thumbnails/custom/trail-2-photo.mp4";
import appreciationTrail5PhotoGifThumb from "@kindeo/assets/videos/thumbnails/appreciation/trail-5-photo.gif";
import appreciationTrail5PhotoVideoThumb from "@kindeo/assets/videos/thumbnails/appreciation/trail-5-photo.mp4";
// import customXmas4GifThumb from "@kindeo/assets/videos/thumbnails/custom/xmas-4.gif";
// import customXmas4VideoThumb from "@kindeo/assets/videos/thumbnails/custom/xmas-4.mp4";

import farewellAstronaut3GifThumb from "@kindeo/assets/videos/thumbnails/farewell/astronaut-2.gif";
import farewellAstronaut3VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/astronaut-2.mp4";
import farewellBoat1GifThumb from "@kindeo/assets/videos/thumbnails/farewell/boat-0.gif";
import farewellBoat1VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/boat-0.mp4";
// import farewellCurvey0GifThumb from "@kindeo/assets/videos/thumbnails/farewell/curvey-0.gif";
// import farewellCurvey0VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/curvey-0.mp4";
import farewellCurvey2GifThumb from "@kindeo/assets/videos/thumbnails/farewell/curvey-2.gif";
import farewellCurvey2VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/curvey-2.mp4";
import farewellCurvey7GifThumb from "@kindeo/assets/videos/thumbnails/farewell/curvey-7.gif";
import farewellCurvey7VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/curvey-7.mp4";
import farewellSimple1GifThumb from "@kindeo/assets/videos/thumbnails/farewell/simple-1.gif";
import farewellSimple1VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/simple-1.mp4";
import farewellStarburst3GifThumb from "@kindeo/assets/videos/thumbnails/farewell/starburst-2.gif";
import farewellStarburst3VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/starburst-2.mp4";
import farewellTrail2PhotoGifThumb from "@kindeo/assets/videos/thumbnails/farewell/trail-2-photo-woman.gif";
import farewellTrail2PhotoVideoThumb from "@kindeo/assets/videos/thumbnails/farewell/trail-2-photo-woman.mp4";
// import farewellTrail3GifThumb from "@kindeo/assets/videos/thumbnails/farewell/trail-3.gif";
// import farewellTrail3VideoThumb from "@kindeo/assets/videos/thumbnails/farewell/trail-3.mp4";

import motherCurvey1VideoThumb from "@kindeo/assets/videos/thumbnails/mother/curvey-1.mp4";
import motherTrail6VideoThumb from "@kindeo/assets/videos/thumbnails/mother/trail-6.mp4";
import motherStarburst0VideoThumb from "@kindeo/assets/videos/thumbnails/mother/starburst-0.mp4";
import motherRipples2VideoThumb from "@kindeo/assets/videos/thumbnails/mother/ripples-2.mp4";
import motherDefault6VideoThumb from "@kindeo/assets/videos/thumbnails/mother/default-6.mp4";
import motherFlowersText3VideoThumb from "@kindeo/assets/videos/thumbnails/mother/flowers-text-3.mp4";
import motherHearts1VideoThumb from "@kindeo/assets/videos/thumbnails/mother/hearts-1.mp4";
import motherPaperHeart0VideoThumb from "@kindeo/assets/videos/thumbnails/mother/paperheart-0.mp4";
import motherCurvey1GifThumb from "@kindeo/assets/videos/thumbnails/mother/curvey-1.gif";
import motherTrail6GifThumb from "@kindeo/assets/videos/thumbnails/mother/trail-6.gif";
import motherStarburst0GifThumb from "@kindeo/assets/videos/thumbnails/mother/starburst-0.gif";
import motherRipples2GifThumb from "@kindeo/assets/videos/thumbnails/mother/ripples-2.gif";
import motherDefault6GifThumb from "@kindeo/assets/videos/thumbnails/mother/default-6.gif";
import motherFlowersText3GifThumb from "@kindeo/assets/videos/thumbnails/mother/flowers-text-3.gif";
import motherHearts1GifThumb from "@kindeo/assets/videos/thumbnails/mother/hearts-1.gif";
import motherPaperHeart0GifThumb from "@kindeo/assets/videos/thumbnails/mother/paperheart-0.gif";

import thanksCake6GifThumb from "@kindeo/assets/videos/thumbnails/thanks/cake-5.gif";
import thanksCake6VideoThumb from "@kindeo/assets/videos/thumbnails/thanks/cake-5.mp4";
import thanksFlowersShape4GifThumb from "@kindeo/assets/videos/thumbnails/thanks/flowers-shape-4.gif";
import thanksFlowersShape4VideoThumb from "@kindeo/assets/videos/thumbnails/thanks/flowers-shape-4.mp4";
import thanksFlowersText3GifThumb from "@kindeo/assets/videos/thumbnails/thanks/flowers-text-3.gif";
import thanksFlowersText3VideoThumb from "@kindeo/assets/videos/thumbnails/thanks/flowers-text-3.mp4";
import thanksHearts7GifThumb from "@kindeo/assets/videos/thumbnails/thanks/hearts-7.gif";
import thanksHearts7VideoThumb from "@kindeo/assets/videos/thumbnails/thanks/hearts-7.mp4";
import thanksRipples2GifThumb from "@kindeo/assets/videos/thumbnails/thanks/ripples-2.gif";
import thanksRipples2VideoThumb from "@kindeo/assets/videos/thumbnails/thanks/ripples-2.mp4";
import thanksSimple1GifThumb from "@kindeo/assets/videos/thumbnails/thanks/simple-1.gif";
import thanksSimple1VideoThumb from "@kindeo/assets/videos/thumbnails/thanks/simple-1.mp4";
import thanksTrail2PicGifThumb from "@kindeo/assets/videos/thumbnails/thanks/trail-1-pic.gif";
import thanksTrail2PicVideoThumb from "@kindeo/assets/videos/thumbnails/thanks/trail-1-pic.mp4";

import tripBoat1GifThumb from "@kindeo/assets/videos/thumbnails/trip/boat-1.gif";
import tripBoat1VideoThumb from "@kindeo/assets/videos/thumbnails/trip/boat-1.mp4";
import tripCurvey6GifThumb from "@kindeo/assets/videos/thumbnails/trip/curvey-6.gif";
import tripCurvey6VideoThumb from "@kindeo/assets/videos/thumbnails/trip/curvey-6.mp4";
import tripCurvey8GifThumb from "@kindeo/assets/videos/thumbnails/trip/curvey-8.gif";
import tripCurvey8VideoThumb from "@kindeo/assets/videos/thumbnails/trip/curvey-8.mp4";
import tripRipples2GifThumb from "@kindeo/assets/videos/thumbnails/trip/ripples-2.gif";
import tripRipples2VideoThumb from "@kindeo/assets/videos/thumbnails/trip/ripples-2.mp4";
import tripRocket2GifThumb from "@kindeo/assets/videos/thumbnails/trip/rocket-2.gif";
import tripRocket2VideoThumb from "@kindeo/assets/videos/thumbnails/trip/rocket-2.mp4";
import tripSimple1GifThumb from "@kindeo/assets/videos/thumbnails/trip/simple-1.gif";
import tripSimple1VideoThumb from "@kindeo/assets/videos/thumbnails/trip/simple-1.mp4";
import tripStarburst1GifThumb from "@kindeo/assets/videos/thumbnails/trip/starburst-1.gif";
import tripStarburst1VideoThumb from "@kindeo/assets/videos/thumbnails/trip/starburst-1.mp4";
import tripTrail6PicGifThumb from "@kindeo/assets/videos/thumbnails/trip/trail-5-pic.gif";
import tripTrail6PicVideoThumb from "@kindeo/assets/videos/thumbnails/trip/trail-5-pic.mp4";

import valentineCurvey7GifThumb from "@kindeo/assets/videos/thumbnails/valentine/curvey-7.gif";
import valentineCurvey7VideoThumb from "@kindeo/assets/videos/thumbnails/valentine/curvey-7.mp4";
import valentineHearts1GifThumb from "@kindeo/assets/videos/thumbnails/valentine/hearts-1.gif";
import valentineHearts1VideoThumb from "@kindeo/assets/videos/thumbnails/valentine/hearts-1.mp4";
import valentinePaperHeart0GifThumb from "@kindeo/assets/videos/thumbnails/valentine/paperheart-0.gif";
import valentinePaperHeart0VideoThumb from "@kindeo/assets/videos/thumbnails/valentine/paperheart-0.mp4";
import valentinePaperHeart1GifThumb from "@kindeo/assets/videos/thumbnails/valentine/paperheart-1.gif";
import valentinePaperHeart1VideoThumb from "@kindeo/assets/videos/thumbnails/valentine/paperheart-1.mp4";
import valentineSimple7GifThumb from "@kindeo/assets/videos/thumbnails/valentine/simple-7.gif";
import valentineSimple7VideoThumb from "@kindeo/assets/videos/thumbnails/valentine/simple-7.mp4";
import valentineTrail4GifThumb from "@kindeo/assets/videos/thumbnails/valentine/trail-4.gif";
import valentineTrail4VideoThumb from "@kindeo/assets/videos/thumbnails/valentine/trail-4.mp4";

import weddingCake5GifThumb from "@kindeo/assets/videos/thumbnails/wedding/cake-4.gif";
import weddingCake5VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/cake-4.mp4";
import weddingCurvey2GifThumb from "@kindeo/assets/videos/thumbnails/wedding/curvey-2.gif";
import weddingCurvey2VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/curvey-2.mp4";
import weddingCurvey5GifThumb from "@kindeo/assets/videos/thumbnails/wedding/curvey-5.gif";
import weddingCurvey5VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/curvey-5.mp4";
import weddingFlowersShape3GifThumb from "@kindeo/assets/videos/thumbnails/wedding/flowers-shape-3.gif";
import weddingFlowersShape3VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/flowers-shape-3.mp4";
import weddingFlowersText0GifThumb from "@kindeo/assets/videos/thumbnails/wedding/flowers-text-0.gif";
import weddingFlowersText0VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/flowers-text-0.mp4";
import weddingHearts1GifThumb from "@kindeo/assets/videos/thumbnails/wedding/hearts-0.gif";
import weddingHearts1VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/hearts-0.mp4";
import weddingRipples5GifThumb from "@kindeo/assets/videos/thumbnails/wedding/ripples-6.gif";
import weddingRipples5VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/ripples-6.mp4";
import weddingSimple1GifThumb from "@kindeo/assets/videos/thumbnails/wedding/simple-1.gif";
import weddingSimple1VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/simple-1.mp4";
import weddingStarburst4GifThumb from "@kindeo/assets/videos/thumbnails/wedding/starburst-3.gif";
import weddingStarburst4VideoThumb from "@kindeo/assets/videos/thumbnails/wedding/starburst-3.mp4";
import weddingTrail8PicGifThumb from "@kindeo/assets/videos/thumbnails/wedding/trail-7-pic.gif";
import weddingTrail8PicVideoThumb from "@kindeo/assets/videos/thumbnails/wedding/trail-7-pic.mp4";

import welcomeCurvey0GifThumb from "@kindeo/assets/videos/thumbnails/welcome/curvey-0.gif";
import welcomeCurvey0VideoThumb from "@kindeo/assets/videos/thumbnails/welcome/curvey-0.mp4";
import welcomeRocket3GifThumb from "@kindeo/assets/videos/thumbnails/welcome/rocket-3.gif";
import welcomeRocket3VideoThumb from "@kindeo/assets/videos/thumbnails/welcome/rocket-3.mp4";
import welcomeStarburst2GifThumb from "@kindeo/assets/videos/thumbnails/welcome/starburst-1.gif";
import welcomeStarburst2VideoThumb from "@kindeo/assets/videos/thumbnails/welcome/starburst-1.mp4";
// import welcomeTrail3PicGifThumb from "@kindeo/assets/videos/thumbnails/welcome/trail-3-pic.gif";
// import welcomeTrail3PicVideoThumb from "@kindeo/assets/videos/thumbnails/welcome/trail-3-pic.mp4";
import welcomeTrail3PhotoGifThumb from "@kindeo/assets/videos/thumbnails/welcome/trail-3-photo.gif";
import welcomeTrail3PhotoVideoThumb from "@kindeo/assets/videos/thumbnails/welcome/trail-3-photo.mp4";

import { themes as boatThemes } from "components/Slideshow/themes/title/boat/data";
import { themes as curveyThemes } from "components/Slideshow/themes/title/curvey/data";
import { themes as curveyThemes2 } from "components/Slideshow/themes/title/curvey2/data";
import { themes as cakeThemes } from "components/Slideshow/themes/title/cake/data";
import { themes as heartsThemes } from "components/Slideshow/themes/title/hearts/data";
import { themes as paperHeartThemes } from "components/Slideshow/themes/title/paperHeart/data";
import { themes as ripplesThemes } from "components/Slideshow/themes/title/cartoonRipples/data";
import { themes as rocketThemes } from "components/Slideshow/themes/title/rocket/data";
import { themes as astronautThemes } from "components/Slideshow/themes/title/astronaut/data";
import { themes as starburstThemes } from "components/Slideshow/themes/title/cartoonStarburst/data";
import { themesPhoto as trailThemes } from "components/Slideshow/themes/title/trail/data";
import { themes as xmasThemes } from "components/Slideshow/themes/title/xmas/data";
import { themesShape as flowersShapeThemes } from "components/Slideshow/themes/title/flowers/data";
import { themesText as flowersTextThemes } from "components/Slideshow/themes/title/flowers/data";
import { themes as exportedThemes } from "components/Slideshow/themes/title/exported/data";

/* const exportedCovers = [
  [brewtiful_birthday, brewtiful_birthday_data, "Brew-tiful Birthday"],
  [beerilliant_birthday, beerilliant_birthday_data, "Beer-illiant Birthday"],
  [birthday_40_cheeky, birthday_40_cheeky_data, "You're 40"],
  [birthday_50_cheeky, birthday_50_cheeky_data, "You're 50"],
  [dictionary_birthday, dictionary_birthday_data, "Birthday Definition"],
  [doctor_dance, doctor_dance_data, "Doctor Dance"],
  [oldi, oldi_data, "Oldi"],
  [smile, smile_data, "Smile"],
  [sloth_suprise, sloth_suprise_data, "Sloth Surprise"],
  [borat_nice, borat_nice_data, "Nice"],
  [theoffice_roast, theoffice_roast_data, "Roasted"],
]; */

// const filterExportedSlides = occasion => {
//   return exportedCovers.filter(
//     ([mp4, coverData]) =>
//       coverData.occasions.indexOf(occasion) > -1 ||
//       coverData.occasions.indexOf("all") > -1
//   );
// };

const type = slideTypes.TITLE;
const titleVariants = slideVariants[type];

const baseVariantConfig = {
  [titleVariants.DEFAULT]: {
    name: titleVariants.DEFAULT,
    label: "Simple",
  },
  [titleVariants.EXPORTED]: {
    name: titleVariants.EXPORTED,
    label: "Exported",
    themes: exportedThemes,
  },
  [titleVariants.PARTICLES_TEXT]: {
    name: titleVariants.PARTICLES_TEXT,
    label: "Flowers - Text",
    themes: flowersTextThemes,
  },
  [titleVariants.PARTICLES_SHAPE]: {
    name: titleVariants.PARTICLES_SHAPE,
    label: "Flowers - Shape",
    themes: flowersShapeThemes,
  },
  [titleVariants.PARTICLES_SHAPE_PHOTO]: {
    name: titleVariants.PARTICLES_SHAPE_PHOTO,
    label: "Flowers - Shape",
    themes: flowersShapeThemes,
  },
  [titleVariants.CURVEY]: {
    name: titleVariants.CURVEY,
    label: "Curvey",
    themes: curveyThemes,
  },
  [titleVariants.CURVEY2]: {
    name: titleVariants.CURVEY2,
    label: "Curvey",
    themes: curveyThemes2,
  },
  [titleVariants.ASTRONAUT]: {
    name: titleVariants.ASTRONAUT,
    label: "Astronaut",
    themes: astronautThemes,
  },
  [titleVariants.BOAT]: {
    name: titleVariants.BOAT,
    label: "Boat",
    themes: boatThemes,
  },
  [titleVariants.CAKE]: {
    name: titleVariants.CAKE,
    label: "Cake",
    themes: cakeThemes,
  },
  [titleVariants.HEARTS]: {
    name: titleVariants.HEARTS,
    label: "Hearts",
    themes: heartsThemes,
  },
  [titleVariants.PAPER_HEARTS]: {
    name: titleVariants.PAPER_HEARTS,
    label: "Paper Hearts",
    themes: paperHeartThemes,
  },
  [titleVariants.RIPPLES]: {
    name: titleVariants.RIPPLES,
    label: "Ripples",
    themes: ripplesThemes,
  },
  [titleVariants.ROCKET]: {
    name: titleVariants.ROCKET,
    label: "Rocket",
    themes: rocketThemes,
  },
  [titleVariants.STARBURST]: {
    name: titleVariants.STARBURST,
    label: "Starburst",
    themes: starburstThemes,
  },
  [titleVariants.TRAIL_PHOTO]: {
    name: titleVariants.TRAIL_PHOTO,
    label: "Trail",
    themes: trailThemes,
  },
  [titleVariants.XMAS]: {
    name: titleVariants.XMAS,
    label: "Xmas",
    themes: xmasThemes,
  },
};

const getExportedCover = ({ data, video, gif }, occasion, themes = []) => {
  const cover = data;

  let relatedCardIds;
  if (themes.length) {
    relatedCardIds = themes.map(associatedCover => associatedCover.exported_id);
    relatedCardIds.unshift(cover.exported_id);
  }

  if (!cover) {
    debugger;
  }
  return {
    ...baseVariantConfig[titleVariants.EXPORTED],
    occasion: occasion,
    exportedContent: cover,
    videoThumb: video,
    gifThumb: gif,
    getExportedContent: () => Promise.resolve(cover),
    label: cover.exported_sku,
    defaultCard: cover.exported_id,
    exportedId: cover.exported_id,
    relatedCardIds: relatedCardIds ? [...relatedCardIds] : undefined,
  };
};

export default {
  type,
  icon: "write",
  label: "Title",
  variants: [
    // specifying an occasion means the variant only shows as an option for that occasion
    // variants without occasions show up for all

    // APPRECIATION
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_02_1716561056840_json,
          video: KIN_BLOONZ_01_02_1716561056840_mp4,
          gif: KIN_BLOONZ_01_02_1716561056840_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716384755410_json,
          KIN_BLOONZ_01_05_1716385087830_json,
          KIN_BLOONZ_01_06_1716385203101_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_01_1718190561781_json,
          video: KIN_RAIN_01_01_1718190561781_mp4,
          gif: KIN_RAIN_01_01_1718190561781_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_RAIN_01_02_1718190941183_json,
          KIN_RAIN_01_03_1718190142656_json,
          KIN_RAIN_01_04_1718191167020_json,
          KIN_RAIN_01_05_1718191541913_json,
          KIN_RAIN_01_06_1718189204089_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_FUZZ_01_02_1713775937028_json,
          video: KIN_FUZZ_01_02_1713775937028_mp4,
          gif: KIN_FUZZ_01_02_1713775937028_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_FUZZ_01_01_1708086137253_json,
          KIN_FUZZ_01_03_1710425131390_json,
          KIN_FUZZ_01_04_1717166334390_json,
          KIN_FUZZ_01_05_1717166665932_json,
          KIN_FUZZ_01_06_1717166112331_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_06_1718027112755_json,
          video: KIN_FOIL_02_06_1718027112755_mp4,
          gif: KIN_FOIL_02_06_1718027112755_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_02_1718026550484_json,
          KIN_FOIL_02_03_1718027833957_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_05_1717773461536_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_02_1716912595705_json,
          video: KIN_WAVES_01_02_1716912595705_mp4,
          gif: KIN_WAVES_01_02_1716912595705_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_WAVES_01_01_1715271007383_json,
          KIN_WAVES_01_03_1716912874983_json,
          KIN_WAVES_01_04_1716913109135_json,
          KIN_WAVES_01_05_1716913232942_json,
          KIN_WAVES_01_06_1716913711822_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LAVA_01_06_1716915994293_json,
          video: KIN_LAVA_01_06_1716915994293_mp4,
          gif: KIN_LAVA_01_06_1716915994293_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_LAVA_01_05_1714127004822_json,
          KIN_LAVA_01_04_1714126301246_json,
          KIN_LAVA_01_03_1714122945946_json,
          KIN_LAVA_01_01_1714123409133_json,
          KIN_LAVA_01_01_1715271588380_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_02_1717081410629_json,
          video: KIN_BLOONZ_02_02_1717081410629_mp4,
          gif: KIN_BLOONZ_02_02_1717081410629_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_04_1716468557922_json,
          KIN_BLOONZ_02_05_1716560922230_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_06_1716212951499_json,
          video: KIN_FLEUR_01_06_1716212951499_mp4,
          gif: KIN_FLEUR_01_06_1716212951499_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_FLEUR_01_01_1715951243770_json,
          KIN_FLEUR_01_02_1715951839604_json,
          KIN_FLEUR_01_03_1715951957106_json,
          KIN_FLEUR_01_04_1715952143771_json,
          KIN_FLEUR_01_05_1715952322408_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_04_1715708156987_json,
          video: KIN_RETRO_01_04_1715708156987_mp4,
          gif: KIN_RETRO_01_04_1715708156987_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_RETRO_01_06_1715694795595_json,
          KIN_RETRO_01_05_1715694367964_json,
          KIN_RETRO_01_03_1715620048476_json,
          KIN_RETRO_01_02_1715619239370_json,
          KIN_RETRO_01_01_1714490793343_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LOVE_01_02_1715274346302_json,
          video: KIN_LOVE_01_02_1715274346302_mp4,
          gif: KIN_LOVE_01_02_1715274346302_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_LOVE_01_06_1714486653612_json,
          KIN_LOVE_01_05_1714486567765_json,
          KIN_LOVE_01_04_1714486440311_json,
          KIN_LOVE_01_03_1714486237129_json,
          KIN_LOVE_01_01_1714401990049_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_04_1713776350007_json,
          video: KIN_TRAIL_01_04_1713776350007_mp4,
          gif: KIN_TRAIL_01_04_1713776350007_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_TRAIL_01_01_1704923387130,
          KIN_TRAIL_01_02_1704918840170,
          KIN_TRAIL_01_03_1704923088263,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_03_1713787166412_json,
          video: KIN_STAR_01_03_1713787166412_mp4,
          gif: KIN_STAR_01_03_1713787166412_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_SIMPLE_01_05_1713787303627_json,
          video: KIN_SIMPLE_01_05_1713787303627_mp4,
          gif: KIN_SIMPLE_01_05_1713787303627_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_SIMPLE_01_06_1710957904512_json,
          KIN_SIMPLE_01_04_1710957777694_json,
          KIN_SIMPLE_01_03_1710957724769_json,
          KIN_SIMPLE_01_02_1710957667663_json,
          KIN_SIMPLE_01_01_1710957601832_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_03_1718027377403_json,
          video: KIN_FOIL_01_03_1718027377403_mp4,
          gif: KIN_FOIL_01_03_1718027377403_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_04_1718028047733_json,
          KIN_FOIL_01_05_1718028134873_json,
          KIN_FOIL_01_06_1718026047956_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_01_1713788217971_json,
          video: KIN_TOAST_01_01_1713788217971_mp4,
          gif: KIN_TOAST_01_01_1713788217971_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_TOAST_01_02_1706200238879_json,
          KIN_TOAST_01_03_1708975205664_json,
          KIN_TOAST_01_04_1706201376355_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLOWER_01_06_1713788397899_json,
          video: KIN_FLOWER_01_06_1713788397899_mp4,
          gif: KIN_FLOWER_01_06_1713788397899_gif,
        },
        OCCASION_TYPES.APPRECIATION,
        [
          KIN_FLOWER_01_05_1711038056587_json,
          KIN_FLOWER_01_04_1711037883577_json,
          KIN_FLOWER_01_03_1711037715638_json,
          KIN_FLOWER_01_02_1711037483728_json,
          KIN_FLOWER_01_01_1711037042960_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.APPRECIATION,
      defaultThemeIndex: 5,
      videoThumb: appreciationTrail5PhotoVideoThumb,
      gifThumb: appreciationTrail5PhotoGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.APPRECIATION,
      defaultThemeIndex: "1713793197558",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_03", "KIN_WARP_02_04"],
      videoThumb: mp41713793197558,
      gifThumb: gif1713793197558,
    },

    // CUSTOM
    {
      ...getExportedCover(
        {
          data: KIN_LIGHT_01_05_1720705002213_json,
          video: KIN_LIGHT_01_05_1720705002213_mp4,
          gif: KIN_LIGHT_01_05_1720705002213_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_LIGHT_01_01_1719834813900_json,
          KIN_LIGHT_01_02_1719835334640_json,
          KIN_LIGHT_01_03_1719837523361_json,
          KIN_LIGHT_01_04_1719838028254_json,
          KIN_LIGHT_01_06_1719838375150_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PHOTSQ_01_01_1720700554517_json,
          video: KIN_PHOTSQ_01_01_1720700554517_mp4,
          gif: KIN_PHOTSQ_01_01_1720700554517_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_PHOTSQ_01_06_1719240218168_json,
          KIN_PHOTSQ_01_05_1719238809567_json,
          KIN_PHOTSQ_01_04_1719238694579_json,
          KIN_PHOTSQ_01_03_1719235096480_json,
          KIN_PHOTSQ_01_02_1719234385265_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_05_1716560922230_json,
          video: KIN_BLOONZ_02_05_1716560922230_mp4,
          gif: KIN_BLOONZ_02_05_1716560922230_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_04_1716468557922_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_05_1718191541913_json,
          video: KIN_RAIN_01_05_1718191541913_mp4,
          gif: KIN_RAIN_01_05_1718191541913_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_RAIN_01_01_1718190561781_json,
          KIN_RAIN_01_02_1718190941183_json,
          KIN_RAIN_01_03_1718190142656_json,
          KIN_RAIN_01_04_1718191167020_json,
          KIN_RAIN_01_06_1718189204089_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_03_1718027833957_json,
          video: KIN_FOIL_02_03_1718027833957_mp4,
          gif: KIN_FOIL_02_03_1718027833957_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_02_1718026550484_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_05_1717773461536_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_03_1716912874983_json,
          video: KIN_WAVES_01_03_1716912874983_mp4,
          gif: KIN_WAVES_01_03_1716912874983_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_WAVES_01_01_1715271007383_json,
          KIN_WAVES_01_02_1716912595705_json,
          KIN_WAVES_01_04_1716913109135_json,
          KIN_WAVES_01_05_1716913232942_json,
          KIN_WAVES_01_06_1716913711822_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_01_1715707426466_json,
          video: KIN_RETRO_01_01_1715707426466_mp4,
          gif: KIN_RETRO_01_01_1715707426466_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_RETRO_01_06_1715694795595_json,
          KIN_RETRO_01_05_1715694367964_json,
          KIN_RETRO_01_04_1715679156132_json,
          KIN_RETRO_01_03_1715620048476_json,
          KIN_RETRO_01_02_1715619239370_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PUP_01_07_1707230108404_json,
          video: KIN_PUP_01_07_1707230108404_mp4,
          gif: KIN_PUP_01_07_1707230108404_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_PUP_01_08_1707220882153_json,
          KIN_PUP_01_06_1707220261947_json,
          KIN_PUP_01_05_1707220209666_json,
          KIN_PUP_01_04_1707220151583_json,
          KIN_PUP_01_03_1707220008848_json,
          KIN_PUP_01_02_1707219925651_json,
          KIN_PUP_01_01_1707154743962_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_03_1717080466468_json,
          video: KIN_BLOONZ_01_03_1717080466468_mp4,
          gif: KIN_BLOONZ_01_03_1717080466468_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_04_1716560606812_json,
          KIN_BLOONZ_01_05_1716385087830_json,
          KIN_BLOONZ_01_06_1716385203101_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_07_1706633310879_json,
          video: KIN_GRUMPY_01_07_1706633310879_mp4,
          gif: KIN_GRUMPY_01_07_1706633310879_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_06_1707219253791_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_04_1707219188063_json,
          KIN_GRUMPY_01_03_1707219103031_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_02_1706118913919_json,
          video: KIN_RIPP_01_02_1706118913919_mp4,
          gif: KIN_RIPP_01_02_1706118913919_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
          KIN_RIPP_01_05_1706119852986_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_03_1706117268326_json,
          video: KIN_STAR_01_03_1706117268326_mp4,
          gif: KIN_STAR_01_03_1706117268326_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_02_1704918840170,
          video: KIN_TRAIL_01_02_1704918840170mp4,
          gif: KIN_TRAIL_01_02_1704918840170gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_TRAIL_01_01_1704923387130,
          KIN_TRAIL_01_03_1704923088263,
          KIN_TRAIL_01_04_1704924628103,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_02_1718027530241_json,
          video: KIN_FOIL_01_02_1718027530241_mp4,
          gif: KIN_FOIL_01_02_1718027530241_gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_03_1718027377403_json,
          KIN_FOIL_01_04_1718028047733_json,
          KIN_FOIL_01_05_1718028134873_json,
          KIN_FOIL_01_06_1718026047956_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: "1702031024584",
      relatedCards: ["KIN_WARP_01_01", "KIN_WARP_01_02", "KIN_WARP_01_04"],
      videoThumb: mp41702031024584,
      gifThumb: gif1702031024584,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_TEXT],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 0,
      videoThumb: customFlowersText0VideoThumb,
      gifThumb: customFlowersText0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: "1702031219168",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_03", "KIN_WARP_02_04"],
      videoThumb: mp41702031219168,
      gifThumb: gif1702031219168,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE_PHOTO],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 0,
      videoThumb: customFlowersShape0PhotoVideoThumb,
      gifThumb: customFlowersShape0PhotoGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_03_02_1704918923246,
          video: KIN_TRAIL_03_02_1704918923246mp4,
          gif: KIN_TRAIL_03_02_1704918923246gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_TRAIL_03_01_1704923630727,
          KIN_TRAIL_03_03_1704923937904,
          KIN_TRAIL_03_04_1704924849342,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 2,
      videoThumb: customTrail2PhotoVideoThumb,
      gifThumb: customTrail2PhotoGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 5,
      videoThumb: customHearts6VideoThumb,
      gifThumb: customHearts6GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_02_03_1704919391244,
          video: KIN_TRAIL_02_03_1704919391244mp4,
          gif: KIN_TRAIL_02_03_1704919391244gif,
        },
        OCCASION_TYPES.CUSTOM,
        [
          KIN_TRAIL_02_01_1704923815179,
          KIN_TRAIL_02_02_1704924098070,
          KIN_TRAIL_02_04_1704923218152,
        ]
      ),
    },

    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 0,
      videoThumb: customSimple1VideoThumb,
      gifThumb: customSimple1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.ROCKET],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 0,
      videoThumb: customRocket1VideoThumb,
      gifThumb: customRocket1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.BOAT],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 3,
      videoThumb: customBoat4VideoThumb,
      gifThumb: customBoat4GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 1,
      videoThumb: customCurvey1VideoThumb,
      gifThumb: customCurvey1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 2,
      videoThumb: customRipples3VideoThumb,
      gifThumb: customRipples3GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CAKE],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 6,
      videoThumb: customCake7VideoThumb,
      gifThumb: customCake7GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 0,
      videoThumb: customFlowersShape0VideoThumb,
      gifThumb: customFlowersShape0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.ASTRONAUT],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 0,
      videoThumb: customAstronaut1VideoThumb,
      gifThumb: customAstronaut1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.XMAS],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 3,
      // videoThumb: customXmas4VideoThumb,
      // gifThumb: customXmas4GifThumb,
      // disabled xmas variant
      disabled: true,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 1,
      videoThumb: customStarburst2VideoThumb,
      gifThumb: customStarburst2GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE_PHOTO],
      occasion: OCCASION_TYPES.CUSTOM,
      defaultThemeIndex: 1,
      videoThumb: customFlowersShape1VideoThumb,
      gifThumb: customFlowersShape1GifThumb,
    },

    // album

    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.ALBUM,
      defaultThemeIndex: 6,
      videoThumb: albumCurvey6VideoThumb,
      gifThumb: albumCurvey6GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.ALBUM,
      defaultThemeIndex: 3,
      videoThumb: albumRipples5VideoThumb,
      gifThumb: albumRipples5GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.ALBUM,
      defaultThemeIndex: 0,
      videoThumb: albumTrail2PicVideoThumb,
      gifThumb: albumTrail2PicGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.ALBUM,
      defaultThemeIndex: 1,
      videoThumb: albumSimple0VideoThumb,
      gifThumb: albumSimple0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.ALBUM,
      defaultThemeIndex: 0,
      videoThumb: albumStarburst0VideoThumb,
      gifThumb: albumStarburst0GifThumb,
    },

    // ANNIVERSARY
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_04_1706117900353_json,
          video: KIN_STAR_01_04_1706117900353_mp4,
          gif: KIN_STAR_01_04_1706117900353_gif,
        },
        OCCASION_TYPES.ANNIVERSARY,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_03_1706117268326_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: 0,
      videoThumb: anniversaryFlowersShapeVideoThumb,
      gifThumb: anniversaryFlowersShapeGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: 3,
      videoThumb: anniversaryStarburstVideoThumb,
      gifThumb: anniversaryStarburstGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: "1702055169110",
      relatedCards: ["KIN_WARP_01_01", "KIN_WARP_01_02", "KIN_WARP_01_03"],
      videoThumb: mp41702055169110,
      gifThumb: gif1702055169110,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: "1713447219280",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_02", "KIN_WARP_02_04"],
      videoThumb: mp41713447219280,
      gifThumb: gif1713447219280,
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_05_1706119647505_json,
          video: KIN_RIPP_01_05_1706119647505_mp4,
          gif: KIN_RIPP_01_05_1706119647505_gif,
        },
        OCCASION_TYPES.ANNIVERSARY,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
        ]
      ),
    },

    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: 3,
      videoThumb: anniversaryRipplesVideoThumb,
      gifThumb: anniversaryRipplesGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_02_01_1704923815179_json,
          video: KIN_TRAIL_02_01_1704923815179_mp4,
          gif: KIN_TRAIL_02_01_1704923815179_gif,
        },
        OCCASION_TYPES.ANNIVERSARY,
        []
      ),
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: 7,
      videoThumb: anniversarySimpleVideoThumb,
      gifThumb: anniversarySimpleGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CAKE],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: 4,
      videoThumb: anniversaryCakeVideoThumb,
      gifThumb: anniversaryCakeGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.ANNIVERSARY,
      defaultThemeIndex: 0,
      videoThumb: anniversaryHeartsVideoThumb,
      gifThumb: anniversaryHeartsGifThumb,
    },

    // BIRTHDAY
    {
      ...getExportedCover(
        {
          data: KIN_LIGHT_01_06_1719838375150_json,
          video: KIN_LIGHT_01_06_1719838375150_mp4,
          gif: KIN_LIGHT_01_06_1719838375150_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_LIGHT_01_01_1719834813900_json,
          KIN_LIGHT_01_02_1719835334640_json,
          KIN_LIGHT_01_03_1719837523361_json,
          KIN_LIGHT_01_04_1719838028254_json,
          KIN_LIGHT_01_05_1719838199274_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PHOTSQ_01_04_1719238694579_json,
          video: KIN_PHOTSQ_01_04_1719238694579_mp4,
          gif: KIN_PHOTSQ_01_04_1719238694579_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_PHOTSQ_01_06_1719240218168_json,
          KIN_PHOTSQ_01_05_1719238809567_json,
          KIN_PHOTSQ_01_03_1719235096480_json,
          KIN_PHOTSQ_01_02_1719234385265_json,
          KIN_PHOTSQ_01_01_1719220401637_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FUZZ_01_01_1708086137253_json,
          video: KIN_FUZZ_01_01_1708086137253_mp4,
          gif: KIN_FUZZ_01_01_1708086137253_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_FUZZ_01_02_1710424233177_json,
          KIN_FUZZ_01_03_1710425131390_json,
          KIN_FUZZ_01_04_1717166334390_json,
          KIN_FUZZ_01_05_1717166665932_json,
          KIN_FUZZ_01_06_1717166112331_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_04_1716468557922_json,
          video: KIN_BLOONZ_02_04_1716468557922_mp4,
          gif: KIN_BLOONZ_02_04_1716468557922_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_05_1716468970813_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_03_1718014608747_json,
          video: KIN_FOIL_01_03_1718014608747_mp4,
          gif: KIN_FOIL_01_03_1718014608747_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_04_1718028047733_json,
          KIN_FOIL_01_05_1718028134873_json,
          KIN_FOIL_01_06_1718026047956_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_LAVA_01_01_1714123409133_json,
          video: KIN_LAVA_01_01_1714123409133_mp4,
          gif: KIN_LAVA_01_01_1714123409133_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_LAVA_01_06_1714126683983_json,
          KIN_LAVA_01_05_1714127004822_json,
          KIN_LAVA_01_04_1714126301246_json,
          KIN_LAVA_01_03_1714122945946_json,
          KIN_LAVA_01_01_1715271588380_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_04_1715952143771_json,
          video: KIN_FLEUR_01_04_1715952143771_mp4,
          gif: KIN_FLEUR_01_04_1715952143771_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_FLEUR_01_01_1715951243770_json,
          KIN_FLEUR_01_02_1715951839604_json,
          KIN_FLEUR_01_03_1715951957106_json,
          KIN_FLEUR_01_05_1715952322408_json,
          KIN_FLEUR_01_06_1715952454685_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_01_1714490793343_json,
          video: KIN_RETRO_01_01_1714490793343_mp4,
          gif: KIN_RETRO_01_01_1714490793343_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_RETRO_01_06_1715694795595_json,
          KIN_RETRO_01_05_1715694367964_json,
          KIN_RETRO_01_04_1715679156132_json,
          KIN_RETRO_01_03_1715620048476_json,
          KIN_RETRO_01_02_1715619239370_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_02_1718190206408_json,
          video: KIN_RAIN_01_02_1718190206408_mp4,
          gif: KIN_RAIN_01_02_1718190206408_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_RAIN_01_01_1718190561781_json,
          KIN_RAIN_01_03_1718190142656_json,
          KIN_RAIN_01_04_1718191167020_json,
          KIN_RAIN_01_05_1718191541913_json,
          KIN_RAIN_01_06_1718189204089_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_03_1714120681955_json,
          video: KIN_WAVES_01_03_1714120681955_mp4,
          gif: KIN_WAVES_01_03_1714120681955_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_WAVES_01_06_1714039730611_json,
          KIN_WAVES_01_05_1714120377730_json,
          KIN_WAVES_01_04_1714120398177_json,
          KIN_WAVES_01_02_1714120997103_json,
          KIN_WAVES_01_01_1714121044033_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LOVE_01_01_1714401990049_json,
          video: KIN_LOVE_01_01_1714401990049_mp4,
          gif: KIN_LOVE_01_01_1714401990049_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_LOVE_01_06_1714486653612_json,
          KIN_LOVE_01_05_1714486567765_json,
          KIN_LOVE_01_04_1714486440311_json,
          KIN_LOVE_01_03_1714486237129_json,
          KIN_LOVE_01_02_1714486205209_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: PIC_TRAIL_01_04_1713428223673_json,
          video: PIC_TRAIL_01_04_1713428223673_mp4,
          gif: PIC_TRAIL_01_04_1713428223673_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          PIC_TRAIL_01_08_1713429125870_json,
          PIC_TRAIL_01_07_1713429076457_json,
          PIC_TRAIL_01_06_1713428895945_json,
          PIC_TRAIL_01_03_1713427014683_json,
          PIC_TRAIL_01_01_1713284309699_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_SIMPLE_01_06_1710957904512_json,
          video: KIN_SIMPLE_01_06_1710957904512_mp4,
          gif: KIN_SIMPLE_01_06_1710957904512_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_SIMPLE_01_05_1710957845113_json,
          KIN_SIMPLE_01_04_1710957777694_json,
          KIN_SIMPLE_01_03_1710957724769_json,
          KIN_SIMPLE_01_02_1710957667663_json,
          KIN_SIMPLE_01_01_1710957601832_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_05_1717773461536_json,
          video: KIN_FOIL_02_05_1717773461536_mp4,
          gif: KIN_FOIL_02_05_1717773461536_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_02_1718026550484_json,
          KIN_FOIL_02_03_1718027833957_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FUZZ_02_05_1717171063664_json,
          video: KIN_FUZZ_02_05_1717171063664_mp4,
          gif: KIN_FUZZ_02_05_1717171063664_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_FUZZ_02_01_1717168407414_json,
          KIN_FUZZ_02_02_1717170091780_json,
          KIN_FUZZ_02_03_1717170345344_json,
          KIN_FUZZ_02_04_1717171564353_json,
          KIN_FUZZ_02_06_1717170957031_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_06_1716385203101_json,
          video: KIN_BLOONZ_01_06_1716385203101_mp4,
          gif: KIN_BLOONZ_01_06_1716385203101_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716384755410_json,
          KIN_BLOONZ_01_05_1716385087830_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLOWER_01_06_1711038236271_json,
          video: KIN_FLOWER_01_06_1711038236271_mp4,
          gif: KIN_FLOWER_01_06_1711038236271_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_FLOWER_01_05_1711038056587_json,
          KIN_FLOWER_01_04_1711037883577_json,
          KIN_FLOWER_01_03_1711037715638_json,
          KIN_FLOWER_01_02_1711037483728_json,
          KIN_FLOWER_01_01_1711037042960_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: trail1704464410051,
      videoThumb: trail1704464410051mp4,
      gifThumb: trail1704464410051gif,
      getExportedContent: () => Promise.resolve(trail1704464410051),
      label: trail1704464410051.exported_sku,
      defaultCard: trail1704464410051.exported_id,
      exportedId: trail1704464410051.exported_id,
      relatedCardIds: [
        trail1704464410051.exported_id,
        trail1704467247973.exported_id,
        trail1704466938730.exported_id,
        trail1704467109860.exported_id,
      ],
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 6,
      videoThumb: birthdayTrail0PicVideoThumb,
      gifThumb: birthdayTrail0PicGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: "1709031690783",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_03", "KIN_WARP_02_04"],
      videoThumb: mp41709031690783,
      gifThumb: gif1709031690783,
    },
    {
      ...getExportedCover(
        {
          data: KIN_VINYL_01_02_1712243352660_json,
          video: KIN_VINYL_01_02_1712243352660_mp4,
          gif: KIN_VINYL_01_02_1712243352660_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_VINYL_01_01_1712243144334_json,
          KIN_VINYL_01_03_1712243875134_json,
          KIN_VINYL_01_04_1712244016752_json,
          KIN_VINYL_01_05_1712244135010_json,
          KIN_VINYL_01_06_1712244251202_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_DRAGON_01_06_1712076172804_json,
          video: KIN_DRAGON_01_06_1712076172804_mp4,
          gif: KIN_DRAGON_01_06_1712076172804_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_DRAGON_01_05_1712076009200_json,
          KIN_DRAGON_01_04_1712075842972_json,
          KIN_DRAGON_01_03_1712075682087_json,
          KIN_DRAGON_01_02_1712075403469_json,
          KIN_DRAGON_01_01_1712074501725_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PHOTSQ_01_01_1719220401637_json,
          video: KIN_PHOTSQ_01_01_1719220401637_mp4,
          gif: KIN_PHOTSQ_01_01_1719220401637_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_PHOTSQ_01_06_1719240218168_json,
          KIN_PHOTSQ_01_05_1719238809567_json,
          KIN_PHOTSQ_01_04_1719238694579_json,
          KIN_PHOTSQ_01_03_1719235096480_json,
          KIN_PHOTSQ_01_02_1719234385265_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_EGG_01_03_1709824864086_json,
          video: KIN_EGG_01_03_1709824864086_mp4,
          gif: KIN_EGG_01_03_1709824864086_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_EGG_01_01_1709579418451_json,
          KIN_EGG_01_02_1709824586381_json,
          KIN_EGG_01_04_1709825282295_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_03_1708975205664_json,
          video: KIN_TOAST_01_03_1708975205664_mp4,
          gif: KIN_TOAST_01_03_1708975205664_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_TOAST_01_01_1706199537719_json,
          KIN_TOAST_01_02_1706200238879_json,
          KIN_TOAST_01_04_1706201376355_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PUP_01_01_1707154743962_json,
          video: KIN_PUP_01_01_1707154743962_mp4,
          gif: KIN_PUP_01_01_1707154743962_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_PUP_01_08_1707220882153_json,
          KIN_PUP_01_07_1707220318607_json,
          KIN_PUP_01_06_1707220261947_json,
          KIN_PUP_01_05_1707220209666_json,
          KIN_PUP_01_04_1707220151583_json,
          KIN_PUP_01_03_1707220008848_json,
          KIN_PUP_01_02_1707219925651_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_03_1705495064593_json,
          video: KIN_STAR_01_03_1705495064593_mp4,
          gif: KIN_STAR_01_03_1705495064593_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_06_1707219253791_json,
          video: KIN_GRUMPY_01_06_1707219253791_mp4,
          gif: KIN_GRUMPY_01_06_1707219253791_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_07_1707219299310_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_04_1707219188063_json,
          KIN_GRUMPY_01_03_1707219103031_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: "1701190355562",
      relatedCards: ["KIN_WARP_02_02", "KIN_WARP_02_03", "KIN_WARP_02_04"],
      videoThumb: mp41701190355562,
      gifThumb: gif1701190355562,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE_PHOTO],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 4,
      videoThumb: birthdayFlowersShape4VideoThumb,
      gifThumb: birthdayFlowersShape4GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_04_1705854062686_json,
          video: KIN_STAR_01_04_1705854062686_mp4,
          gif: KIN_STAR_01_04_1705854062686_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_03_1706117268326_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_01_1705857585095_json,
          video: KIN_RIPP_01_01_1705857585095_mp4,
          gif: KIN_RIPP_01_01_1705857585095_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        [
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
          KIN_RIPP_01_05_1706119852986_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 0,
      videoThumb: birthdaySimple1VideoThumb,
      gifThumb: birthdaySimple1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 0,
      videoThumb: birthdayStarburst0VideoThumb,
      gifThumb: birthdayStarburst0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_TEXT],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 0,
      videoThumb: birthdayFlowersText0VideoThumb,
      gifThumb: birthdayFlowersText0GifThumb,
    },

    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: trail1704464303769,
      videoThumb: trail1704464303769mp4,
      gifThumb: trail1704464303769gif,
      getExportedContent: () => Promise.resolve(trail1704464303769),
      label: trail1704464303769.exported_sku,
      exportedId: trail1704464303769.exported_id,
      relatedCardIds: [
        trail1704464303769.exported_id,
        trail1704468169402.exported_id,
        trail1704469461273.exported_id,
        trail1704470147702.exported_id,
      ],
    },
    {
      ...getExportedCover(
        {
          data: KIN_BEEDAY_01_01_1708512594758_json,
          video: KIN_BEEDAY_01_01_1708512594758_mp4,
          gif: KIN_BEEDAY_01_01_1708512594758_gif,
        },
        OCCASION_TYPES.BIRTHDAY,
        []
      ),
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 1,
      videoThumb: birthdayRipples5VideoThumb,
      gifThumb: birthdayRipples5GifThumb,
    },

    {
      ...baseVariantConfig[titleVariants.CAKE],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 1,
      videoThumb: birthdayCake1VideoThumb,
      gifThumb: birthdayCake1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: "1701189402547",
      relatedCards: ["KIN_WARP_01_02", "KIN_WARP_01_03", "KIN_WARP_01_04"],
      videoThumb: mp41701189402547,
      gifThumb: gif1701189402547,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: unicorn_clouds_birthday_data,
      videoThumb: unicorn_clouds_birthdayVideoThumb,
      gifThumb: unicorn_clouds_birthdayGifThumb,
      getExportedContent: () => Promise.resolve(unicorn_clouds_birthday_data),
      label: "Birthday Unicorn",
      exportedId: unicorn_clouds_birthday_data.exported_id,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: birthday_40_cheeky_data,
      videoThumb: birthday_40_cheekyVideoThumb,
      gifThumb: birthday_40_cheekyGifThumb,
      getExportedContent: () => Promise.resolve(birthday_40_cheeky_data),
      label: "You're 40", // overwrite base Exported label
      exportedId: birthday_40_cheeky_data.exported_id,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.BIRTHDAY,
      defaultThemeIndex: 1,
      videoThumb: birthdayHearts2VideoThumb,
      gifThumb: birthdayHearts2GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: birthday_50_cheeky_data,
      videoThumb: birthday_50_cheekyVideoThumb,
      gifThumb: birthday_50_cheekyGifThumb,
      getExportedContent: () => Promise.resolve(birthday_50_cheeky_data),
      label: "You're 50", // overwrite base Exported label
      exportedId: birthday_50_cheeky_data.exported_id,
    },
    // disabled ones
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: dictionary_birthday_data,
      videoThumb: dictionary_birthdayVideoThumb,
      gifThumb: dictionary_birthdayGifThumb,
      getExportedContent: () => Promise.resolve(dictionary_birthday_data),
      label: "Birthday Definition", // overwrite base Exported label
      exportedId: dictionary_birthday_data.exported_id,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: hedgehog_birthday_data,
      videoThumb: hedgehog_birthdayVideoThumb,
      gifThumb: hedgehog_birthdayGifThumb,
      getExportedContent: () => Promise.resolve(hedgehog_birthday_data),
      label: "Hedge-hugs on your Birthday",
      exportedId: hedgehog_birthday_data.exported_id,
      disabled: true,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: theoffice_roast_data,
      videoThumb: theoffice_roastVideoThumb,
      gifThumb: theoffice_roastGifThumb,
      getExportedContent: () => Promise.resolve(theoffice_roast_data),
      label: "Roasted", // overwrite base Exported label
      exportedId: theoffice_roast_data.exported_id,
      disabled: true,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: magician_levitation_data,
      videoThumb: magician_levitationVideoThumb,
      gifThumb: magician_levitationGifThumb,
      getExportedContent: () => Promise.resolve(magician_levitation_data),
      label: "Wake up",
      exportedId: magician_levitation_data.exported_id,
      disabled: true,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: oldi_data,
      videoThumb: oldiVideoThumb,
      gifThumb: oldiGifThumb,
      getExportedContent: () => Promise.resolve(oldi_data),
      label: "Oldi", // overwrite base Exported label
      exportedId: oldi_data.exported_id,
      disabled: true,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.BIRTHDAY,
      exportedContent: borat_nice_data,
      videoThumb: borat_niceVideoThumb,
      gifThumb: borat_niceGifThumb,
      getExportedContent: () => Promise.resolve(borat_nice_data),
      label: "Nice", // overwrite base Exported label
      exportedId: borat_nice_data.exported_id,
      disabled: true,
    },

    // WORK_ANNIVERSARY
    {
      ...getExportedCover(
        {
          data: KIN_LIGHT_01_06_1720705131854_json,
          video: KIN_LIGHT_01_06_1720705131854_mp4,
          gif: KIN_LIGHT_01_06_1720705131854_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_LIGHT_01_01_1719834813900_json,
          KIN_LIGHT_01_02_1719835334640_json,
          KIN_LIGHT_01_03_1719837523361_json,
          KIN_LIGHT_01_04_1719838028254_json,
          KIN_LIGHT_01_05_1719838199274_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PHOTSQ_01_03_1720700198525_json,
          video: KIN_PHOTSQ_01_03_1720700198525_mp4,
          gif: KIN_PHOTSQ_01_03_1720700198525_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_PHOTSQ_01_06_1719240218168_json,
          KIN_PHOTSQ_01_05_1719238809567_json,
          KIN_PHOTSQ_01_04_1719238694579_json,
          KIN_PHOTSQ_01_02_1719234385265_json,
          KIN_PHOTSQ_01_01_1719220401637_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_06_1718026047956_json,
          video: KIN_FOIL_01_06_1718026047956_mp4,
          gif: KIN_FOIL_01_06_1718026047956_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_03_1718027377403_json,
          KIN_FOIL_01_04_1718028047733_json,
          KIN_FOIL_01_05_1718028134873_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_03_1717081136243_json,
          video: KIN_BLOONZ_02_03_1717081136243_mp4,
          gif: KIN_BLOONZ_02_03_1717081136243_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_04_1716468557922_json,
          KIN_BLOONZ_02_05_1716560922230_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_04_1716913109135_json,
          video: KIN_WAVES_01_04_1716913109135_mp4,
          gif: KIN_WAVES_01_04_1716913109135_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_WAVES_01_01_1715271007383_json,
          KIN_WAVES_01_02_1716912595705_json,
          KIN_WAVES_01_03_1716912874983_json,
          KIN_WAVES_01_05_1716913232942_json,
          KIN_WAVES_01_06_1716913711822_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_03_1716212605619_json,
          video: KIN_FLEUR_01_03_1716212605619_mp4,
          gif: KIN_FLEUR_01_03_1716212605619_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_FLEUR_01_01_1715951243770_json,
          KIN_FLEUR_01_02_1715951839604_json,
          KIN_FLEUR_01_04_1715952143771_json,
          KIN_FLEUR_01_05_1715952322408_json,
          KIN_FLEUR_01_06_1715952454685_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_02_1715707847561_json,
          video: KIN_RETRO_01_02_1715707847561_mp4,
          gif: KIN_RETRO_01_02_1715707847561_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_RETRO_01_06_1715694795595_json,
          KIN_RETRO_01_05_1715694367964_json,
          KIN_RETRO_01_04_1715679156132_json,
          KIN_RETRO_01_03_1715620048476_json,
          KIN_RETRO_01_01_1714490793343_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_04_1718191167020_json,
          video: KIN_RAIN_01_04_1718191167020_mp4,
          gif: KIN_RAIN_01_04_1718191167020_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_RAIN_01_01_1718190561781_json,
          KIN_RAIN_01_02_1718190941183_json,
          KIN_RAIN_01_03_1718190142656_json,
          KIN_RAIN_01_05_1718191541913_json,
          KIN_RAIN_01_06_1718189204089_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LAVA_01_05_1715271458696_json,
          video: KIN_LAVA_01_05_1715271458696_mp4,
          gif: KIN_LAVA_01_05_1715271458696_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_LAVA_01_06_1714126683983_json,
          KIN_LAVA_01_04_1714126301246_json,
          KIN_LAVA_01_03_1714122945946_json,
          KIN_LAVA_01_01_1714123409133_json,
          KIN_LAVA_01_01_1715271588380_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_WRKANI_03_01_1708601003845_json,
          video: KIN_WRKANI_03_01_1708601003845_mp4,
          gif: KIN_WRKANI_03_01_1708601003845_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_WRKANI_03_02_1709050387554_json,
          KIN_WRKANI_03_03_1709051292331_json,
          KIN_WRKANI_03_04_1709051859823_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_02_1713438038438_json,
          video: KIN_TRAIL_01_02_1713438038438_mp4,
          gif: KIN_TRAIL_01_02_1713438038438_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_TRAIL_01_01_1704923387130,
          KIN_TRAIL_01_03_1704923088263,
          KIN_TRAIL_01_04_1704924628103,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 6,
      videoThumb: anniversaryTrailVideoThumb,
      gifThumb: anniversaryTrailGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_SIMPLE_01_03_1713438234339_json,
          video: KIN_SIMPLE_01_03_1713438234339_mp4,
          gif: KIN_SIMPLE_01_03_1713438234339_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_SIMPLE_01_06_1710957904512_json,
          KIN_SIMPLE_01_05_1710957845113_json,
          KIN_SIMPLE_01_04_1710957777694_json,
          KIN_SIMPLE_01_02_1710957667663_json,
          KIN_SIMPLE_01_01_1710957601832_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_05_1718026309006_json,
          video: KIN_FOIL_02_05_1718026309006_mp4,
          gif: KIN_FOIL_02_05_1718026309006_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_02_1718026550484_json,
          KIN_FOIL_02_03_1718027833957_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 3,
      videoThumb: anniversaryCurveyVideoThumb2,
      gifThumb: anniversaryCurveyGifThumb2,
      random: 2,
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_06_1716555574459_json,
          video: KIN_BLOONZ_01_06_1716555574459_mp4,
          gif: KIN_BLOONZ_01_06_1716555574459_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716384755410_json,
          KIN_BLOONZ_01_05_1716385087830_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_03_1713438474577_json,
          video: KIN_TOAST_01_03_1713438474577_mp4,
          gif: KIN_TOAST_01_03_1713438474577_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_TOAST_01_01_1706199537719_json,
          KIN_TOAST_01_02_1706200238879_json,
          KIN_TOAST_01_04_1706201376355_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 0,
      videoThumb: workAnniversaryStarburst0Mp4,
      gifThumb: workAnniversaryStarburst0Gif,
    },
    {
      ...getExportedCover(
        {
          data: KIN_WRKANI_02_01_1708599509810_json,
          video: KIN_WRKANI_02_01_1708599509810_mp4,
          gif: KIN_WRKANI_02_01_1708599509810_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_WRKANI_02_02_1709137335498_json,
          KIN_WRKANI_02_03_1709137676172_json,
          KIN_WRKANI_02_04_1709137833270_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 4,
      videoThumb: workAnniversaryFlowersShapeVideoThumb,
      gifThumb: workAnniversaryFlowersShapeGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_03_1706629987261_json,
          video: KIN_GRUMPY_01_03_1706629987261_mp4,
          gif: KIN_GRUMPY_01_03_1706629987261_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_07_1707219299310_json,
          KIN_GRUMPY_01_06_1707219253791_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_04_1707219188063_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 0,
      videoThumb: workAnniversarySimpleVideoThumb,
      gifThumb: workAnniversarySimpleGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_01_1713438680168_json,
          video: KIN_STAR_01_01_1713438680168_mp4,
          gif: KIN_STAR_01_01_1713438680168_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_03_1706117268326_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 7,
      videoThumb: workAnniversaryCurvey5Mp4,
      gifThumb: workAnniversaryCurvey5Gif,
    },
    {
      ...getExportedCover(
        {
          data: KIN_WRKANI_01_05_1713439017612_json,
          video: KIN_WRKANI_01_05_1713439017612_mp4,
          gif: KIN_WRKANI_01_05_1713439017612_gif,
        },
        OCCASION_TYPES.WORK_ANNIVERSARY,
        [
          KIN_WRKANI_01_01_1708455749335_json,
          KIN_WRKANI_01_02_1708456022489_json,
          KIN_WRKANI_01_03_1708456099922_json,
          KIN_WRKANI_01_04_1708456327669_json,
          KIN_WRKANI_01_06_1708456586409_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CAKE],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 1,
      videoThumb: workAnniversaryCakeVideoThumb,
      gifThumb: workAnniversaryCakeGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.WORK_ANNIVERSARY,
      defaultThemeIndex: 2,
      videoThumb: workAnniversaryHeartsVideoThumb,
      gifThumb: workAnniversaryHeartsGifThumb,
    },

    // CHRISTMAS
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: "1701895694738",
      relatedCards: [
        "1701944003068",
        "1701680613621",
        "1701295397786",
        "1701679568062",
        "1701678198757",
      ],
      videoThumb: mp41701895694738,
      gifThumb: gif1701895694738,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: "1701678198757",
      relatedCards: [
        "1701944003068",
        "1701680613621",
        "1701895694738",
        "1701295397786",
        "1701679568062",
      ],
      videoThumb: mp41701678198757,
      gifThumb: gif1701678198757,
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 1,
      videoThumb: christmasRipples2VideoThumb,
      gifThumb: christmasRipples2GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: "1701295397786",
      relatedCards: [
        // "KIN_WARP_04_01",
        "1701944003068",
        "1701680613621",
        "1701895694738",
        "1701678198757",
        "1701679568062",
      ],
      videoThumb: mp41701295397786,
      gifThumb: gif1701295397786,
    },

    {
      ...baseVariantConfig[titleVariants.XMAS],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 1,
      videoThumb: christmasXmas2VideoThumb,
      gifThumb: christmasXmas2GifThumb,
    },

    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: "1701679568062",
      relatedCards: [
        "1701944003068",
        "1701680613621",
        "1701895694738",
        "1701295397786",
        "1701678198757",
      ],
      videoThumb: mp41701679568062,
      gifThumb: gif1701679568062,
    },

    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 2,
      videoThumb: christmasTrail3PicVideoThumb,
      gifThumb: christmasTrail3PicGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: "1701680613621",
      relatedCards: [
        "1701944003068",
        "1701895694738",
        "1701295397786",
        "1701679568062",
        "1701678198757",
      ],
      videoThumb: mp41701680613621,
      gifThumb: gif1701680613621,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: "1701944003068",
      relatedCards: [
        "1701680613621",
        "1701895694738",
        "1701295397786",
        "1701679568062",
        "1701678198757",
      ],
      videoThumb: mp41701944003068,
      gifThumb: gif1701944003068,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 0,
      videoThumb: christmasStarburst1VideoThumb,
      gifThumb: christmasStarburst1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 4,
      videoThumb: christmasHearts5VideoThumb,
      gifThumb: christmasHearts5GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 0,
      videoThumb: christmasCurveyVideoThumb,
      gifThumb: christmasCurveyGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CAKE],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 0,
      videoThumb: christmasCake1VideoThumb,
      gifThumb: christmasCake1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.CHRISTMAS,
      defaultThemeIndex: 0,
      videoThumb: christmasSimple1VideoThumb,
      gifThumb: christmasSimple1GifThumb,
    },

    // CONGRATS
    {
      ...getExportedCover(
        {
          data: KIN_PUP_01_02_1707223022554_json,
          video: KIN_PUP_01_02_1707223022554_mp4,
          gif: KIN_PUP_01_02_1707223022554_gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_PUP_01_08_1707220882153_json,
          KIN_PUP_01_07_1707220318607_json,
          KIN_PUP_01_06_1707220261947_json,
          KIN_PUP_01_05_1707220209666_json,
          KIN_PUP_01_04_1707220151583_json,
          KIN_PUP_01_03_1707220008848_json,
          KIN_PUP_01_01_1707154743962_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_04_1706201376355_json,
          video: KIN_TOAST_01_04_1706201376355_mp4,
          gif: KIN_TOAST_01_04_1706201376355_gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_TOAST_01_01_1706199537719_json,
          KIN_TOAST_01_02_1706200238879_json,
          KIN_TOAST_01_03_1706200484846_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_04_1706119328432_json,
          video: KIN_RIPP_01_04_1706119328432_mp4,
          gif: KIN_RIPP_01_04_1706119328432_gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_05_1706119852986_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_02_1706629944548_json,
          video: KIN_GRUMPY_01_02_1706629944548_mp4,
          gif: KIN_GRUMPY_01_02_1706629944548_gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_07_1707219299310_json,
          KIN_GRUMPY_01_06_1707219253791_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_04_1707219188063_json,
          KIN_GRUMPY_01_03_1707219103031_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_01_1706117703249_json,
          video: KIN_STAR_01_01_1706117703249_mp4,
          gif: KIN_STAR_01_01_1706117703249_gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_03_1706117268326_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_02_1706200238879_json,
          video: KIN_TOAST_01_02_1706200238879_mp4,
          gif: KIN_TOAST_01_02_1706200238879_gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_TOAST_01_01_1706199537719_json,
          KIN_TOAST_01_03_1706200484846_json,
          KIN_TOAST_01_04_1706201376355_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_01_1704923387130,
          video: KIN_TRAIL_01_01_1704923387130mp4,
          gif: KIN_TRAIL_01_01_1704923387130gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_TRAIL_01_02_1704918840170,
          KIN_TRAIL_01_03_1704923088263,
          KIN_TRAIL_01_04_1704924628103,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: "1702398823609",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_02", "KIN_WARP_02_04"],
      videoThumb: mp41702398823609,
      gifThumb: gif1702398823609,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_TEXT],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 0,
      videoThumb: congratsFlowersText0VideoThumb,
      gifThumb: congratsFlowersText0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 0,
      videoThumb: congratsHearts1VideoThumb,
      gifThumb: congratsHearts1GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_03_01_1704923630727,
          video: KIN_TRAIL_03_01_1704923630727mp4,
          gif: KIN_TRAIL_03_01_1704923630727gif,
        },
        OCCASION_TYPES.CONGRATS,
        [
          KIN_TRAIL_03_02_1704918923246,
          KIN_TRAIL_03_03_1704923937904,
          KIN_TRAIL_03_04_1704924849342,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: "1702054350835",
      relatedCards: ["KIN_WARP_04_02", "KIN_WARP_04_03", "KIN_WARP_04_04"],
      videoThumb: mp41702054350835,
      gifThumb: gif1702054350835,
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 5,
      videoThumb: congratsTrail6VideoThumb,
      gifThumb: congratsTrail6GifThumb,
    },

    {
      ...baseVariantConfig[titleVariants.ROCKET],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 0,
      videoThumb: congratsRocket0VideoThumb,
      gifThumb: congratsRocket0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 1,
      videoThumb: congratsCurvey1VideoThumb,
      gifThumb: congratsCurvey1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 0,
      videoThumb: congratsStarburst1VideoThumb,
      gifThumb: congratsStarburst1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 0,
      videoThumb: congratsSimple1VideoThumb,
      gifThumb: congratsSimple1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 3,
      videoThumb: congratsRipples4VideoThumb,
      gifThumb: congratsRipples4GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.CONGRATS,
      defaultThemeIndex: 0,
      videoThumb: congratsCurvey0VideoThumb,
      gifThumb: congratsCurvey0GifThumb,
    },

    // GET_WELL
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_05_1716555296233_json,
          video: KIN_BLOONZ_01_05_1716555296233_mp4,
          gif: KIN_BLOONZ_01_05_1716555296233_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716384755410_json,
          KIN_BLOONZ_01_06_1716385203101_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_01_1718026678590_json,
          video: KIN_FOIL_01_01_1718026678590_mp4,
          gif: KIN_FOIL_01_01_1718026678590_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_03_1718027377403_json,
          KIN_FOIL_01_04_1718028047733_json,
          KIN_FOIL_01_05_1718028134873_json,
          KIN_FOIL_01_06_1718026047956_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LAVA_01_03_1716916332280_json,
          video: KIN_LAVA_01_03_1716916332280_mp4,
          gif: KIN_LAVA_01_03_1716916332280_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_LAVA_01_06_1714126683983_json,
          KIN_LAVA_01_05_1714127004822_json,
          KIN_LAVA_01_04_1714126301246_json,
          KIN_LAVA_01_01_1714123409133_json,
          KIN_LAVA_01_01_1715271588380_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_02_1716212767461_json,
          video: KIN_FLEUR_01_02_1716212767461_mp4,
          gif: KIN_FLEUR_01_02_1716212767461_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_FLEUR_01_01_1715951243770_json,
          KIN_FLEUR_01_03_1715951957106_json,
          KIN_FLEUR_01_04_1715952143771_json,
          KIN_FLEUR_01_05_1715952322408_json,
          KIN_FLEUR_01_06_1715952454685_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_03_1718191266549_json,
          video: KIN_RAIN_01_03_1718191266549_mp4,
          gif: KIN_RAIN_01_03_1718191266549_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_RAIN_01_01_1718190561781_json,
          KIN_RAIN_01_02_1718190941183_json,
          KIN_RAIN_01_04_1718191167020_json,
          KIN_RAIN_01_05_1718191541913_json,
          KIN_RAIN_01_06_1718189204089_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_05_1715710838375_json,
          video: KIN_RETRO_01_05_1715710838375_mp4,
          gif: KIN_RETRO_01_05_1715710838375_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_RETRO_01_06_1715694795595_json,
          KIN_RETRO_01_04_1715679156132_json,
          KIN_RETRO_01_03_1715620048476_json,
          KIN_RETRO_01_02_1715619239370_json,
          KIN_RETRO_01_01_1714490793343_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_05_1717080354766_json,
          video: KIN_BLOONZ_02_05_1717080354766_mp4,
          gif: KIN_BLOONZ_02_05_1717080354766_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_04_1716468557922_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_06_1715271160945_json,
          video: KIN_WAVES_01_06_1715271160945_mp4,
          gif: KIN_WAVES_01_06_1715271160945_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_WAVES_01_05_1714120377730_json,
          KIN_WAVES_01_04_1714120398177_json,
          KIN_WAVES_01_03_1714120681955_json,
          KIN_WAVES_01_02_1714120997103_json,
          KIN_WAVES_01_01_1714121044033_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LOVE_01_03_1715274465463_json,
          video: KIN_LOVE_01_03_1715274465463_mp4,
          gif: KIN_LOVE_01_03_1715274465463_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_LOVE_01_06_1714486653612_json,
          KIN_LOVE_01_05_1714486567765_json,
          KIN_LOVE_01_04_1714486440311_json,
          KIN_LOVE_01_02_1714486205209_json,
          KIN_LOVE_01_01_1714401990049_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_02_1718026550484_json,
          video: KIN_FOIL_02_02_1718026550484_mp4,
          gif: KIN_FOIL_02_02_1718026550484_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_03_1718027833957_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_05_1717773461536_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLOWER_01_01_1713788692078_json,
          video: KIN_FLOWER_01_01_1713788692078_mp4,
          gif: KIN_FLOWER_01_01_1713788692078_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_FLOWER_01_06_1713788397899_json,
          KIN_FLOWER_01_05_1711038056587_json,
          KIN_FLOWER_01_04_1711037883577_json,
          KIN_FLOWER_01_03_1711037715638_json,
          KIN_FLOWER_01_02_1711037483728_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PUP_01_04_1713789020841_json,
          video: KIN_PUP_01_04_1713789020841_mp4,
          gif: KIN_PUP_01_04_1713789020841_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_PUP_01_08_1707220882153_json,
          KIN_PUP_01_07_1707220318607_json,
          KIN_PUP_01_06_1707220261947_json,
          KIN_PUP_01_05_1707222704695_json,
          KIN_PUP_01_03_1707220008848_json,
          KIN_PUP_01_02_1707219925651_json,
          KIN_PUP_01_01_1707154743962_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE_PHOTO],
      occasion: OCCASION_TYPES.GET_WELL,
      defaultThemeIndex: 4,
      videoThumb: getWellFlowersShape4PhotoVideoThumb,
      gifThumb: getWellFlowersShape4PhotoGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_SIMPLE_01_04_1713789288739_json,
          video: KIN_SIMPLE_01_04_1713789288739_mp4,
          gif: KIN_SIMPLE_01_04_1713789288739_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_SIMPLE_01_06_1710957904512_json,
          KIN_SIMPLE_01_05_1713787303627_json,
          KIN_SIMPLE_01_03_1710957724769_json,
          KIN_SIMPLE_01_02_1710957667663_json,
          KIN_SIMPLE_01_01_1710957601832_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.GET_WELL,
      defaultThemeIndex: "1709031690784",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_03", "KIN_WARP_02_04"],
      videoThumb: mp41709031690784,
      gifThumb: gif1709031690784,
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_04_1713789429984_json,
          video: KIN_STAR_01_04_1713789429984_mp4,
          gif: KIN_STAR_01_04_1713789429984_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_03_1706117268326_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BEEDAY_01_01_1713790113912_json,
          video: KIN_BEEDAY_01_01_1713790113912_mp4,
          gif: KIN_BEEDAY_01_01_1713790113912_gif,
        },
        OCCASION_TYPES.GET_WELL,
        []
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_05_1713790337915_json,
          video: KIN_RIPP_01_05_1713790337915_mp4,
          gif: KIN_RIPP_01_05_1713790337915_gif,
        },
        OCCASION_TYPES.GET_WELL,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
        ]
      ),
    },
    // LEAVING
    {
      ...getExportedCover(
        {
          data: KIN_LIGHT_01_02_1720703807326_json,
          video: KIN_LIGHT_01_02_1720703807326_mp4,
          gif: KIN_LIGHT_01_02_1720703807326_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_LIGHT_01_01_1719834813900_json,
          KIN_LIGHT_01_03_1719837523361_json,
          KIN_LIGHT_01_04_1719838028254_json,
          KIN_LIGHT_01_05_1719838199274_json,
          KIN_LIGHT_01_06_1719838375150_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PHOTSQ_01_02_1720700381865_json,
          video: KIN_PHOTSQ_01_02_1720700381865_mp4,
          gif: KIN_PHOTSQ_01_02_1720700381865_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_PHOTSQ_01_06_1719240218168_json,
          KIN_PHOTSQ_01_05_1719238809567_json,
          KIN_PHOTSQ_01_04_1719238694579_json,
          KIN_PHOTSQ_01_03_1719235096480_json,
          KIN_PHOTSQ_01_01_1719220401637_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_02_1717081621361_json,
          video: KIN_BLOONZ_01_02_1717081621361_mp4,
          gif: KIN_BLOONZ_01_02_1717081621361_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716560606812_json,
          KIN_BLOONZ_01_05_1716385087830_json,
          KIN_BLOONZ_01_06_1716385203101_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FUZZ_01_03_1710425131390_json,
          video: KIN_FUZZ_01_03_1710425131390_mp4,
          gif: KIN_FUZZ_01_03_1710425131390_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_FUZZ_01_01_1708086137253_json,
          KIN_FUZZ_01_02_1710424233177_json,
          KIN_FUZZ_01_04_1717166334390_json,
          KIN_FUZZ_01_05_1717166665932_json,
          KIN_FUZZ_01_06_1717166112331_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_05_1718191453194_json,
          video: KIN_RAIN_01_05_1718191453194_mp4,
          gif: KIN_RAIN_01_05_1718191453194_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_RAIN_01_01_1718190561781_json,
          KIN_RAIN_01_02_1718190941183_json,
          KIN_RAIN_01_03_1718190142656_json,
          KIN_RAIN_01_04_1718191167020_json,
          KIN_RAIN_01_06_1718189204089_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_04_1716212885645_json,
          video: KIN_FLEUR_01_04_1716212885645_mp4,
          gif: KIN_FLEUR_01_04_1716212885645_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_FLEUR_01_01_1715951243770_json,
          KIN_FLEUR_01_02_1715951839604_json,
          KIN_FLEUR_01_03_1715951957106_json,
          KIN_FLEUR_01_05_1715952322408_json,
          KIN_FLEUR_01_06_1715952454685_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_06_1715706010944_json,
          video: KIN_RETRO_01_06_1715706010944_mp4,
          gif: KIN_RETRO_01_06_1715706010944_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_RETRO_01_05_1715694367964_json,
          KIN_RETRO_01_04_1715679156132_json,
          KIN_RETRO_01_03_1715620048476_json,
          KIN_RETRO_01_02_1715619239370_json,
          KIN_RETRO_01_01_1714490793343_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LAVA_01_05_1716914379627_json,
          video: KIN_LAVA_01_05_1716914379627_mp4,
          gif: KIN_LAVA_01_05_1716914379627_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_LAVA_01_06_1714126683983_json,
          KIN_LAVA_01_04_1714126301246_json,
          KIN_LAVA_01_03_1714122945946_json,
          KIN_LAVA_01_01_1714123409133_json,
          KIN_LAVA_01_01_1715271588380_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_01_1715271007383_json,
          video: KIN_WAVES_01_01_1715271007383_mp4,
          gif: KIN_WAVES_01_01_1715271007383_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_WAVES_01_06_1714039730611_json,
          KIN_WAVES_01_05_1714120377730_json,
          KIN_WAVES_01_04_1714120398177_json,
          KIN_WAVES_01_03_1714120681955_json,
          KIN_WAVES_01_02_1714120997103_json,
          KIN_WAVES_01_01_1714121044033_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: PIC_TRAIL_01_07_1713453076324_json,
          video: PIC_TRAIL_01_07_1713453076324_mp4,
          gif: PIC_TRAIL_01_07_1713453076324_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          PIC_TRAIL_01_08_1713428895945h_json,
          PIC_TRAIL_01_06_1713428895945g_json,
          PIC_TRAIL_01_04_1713453076324e_json,
          PIC_TRAIL_01_03_1713453076324d_json,
          PIC_TRAIL_01_01_1713453076324b_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_06_1718015187875_json,
          video: KIN_FOIL_01_06_1718015187875_mp4,
          gif: KIN_FOIL_01_06_1718015187875_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_03_1718027377403_json,
          KIN_FOIL_01_04_1718028047733_json,
          KIN_FOIL_01_05_1718028134873_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PUP_01_05_1707222704695_json,
          video: KIN_PUP_01_05_1707222704695_mp4,
          gif: KIN_PUP_01_05_1707222704695_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_PUP_01_08_1707220882153_json,
          KIN_PUP_01_07_1707220318607_json,
          KIN_PUP_01_06_1707220261947_json,
          KIN_PUP_01_04_1707220151583_json,
          KIN_PUP_01_03_1707220008848_json,
          KIN_PUP_01_02_1707219925651_json,
          KIN_PUP_01_01_1707154743962_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: 2,
      videoThumb: farewellTrail2PhotoVideoThumb,
      gifThumb: farewellTrail2PhotoGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_01_1716561162498_json,
          video: KIN_BLOONZ_02_01_1716561162498_mp4,
          gif: KIN_BLOONZ_02_01_1716561162498_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_04_1716468557922_json,
          KIN_BLOONZ_02_05_1716468970813_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_05_1706630934532_json,
          video: KIN_GRUMPY_01_05_1706630934532_mp4,
          gif: KIN_GRUMPY_01_05_1706630934532_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_07_1707219299310_json,
          KIN_GRUMPY_01_06_1707219253791_json,
          KIN_GRUMPY_01_04_1707219188063_json,
          KIN_GRUMPY_01_03_1707219103031_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_03_1706118577510_json,
          video: KIN_RIPP_01_03_1706118577510_mp4,
          gif: KIN_RIPP_01_03_1706118577510_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_04_1706119328432_json,
          KIN_RIPP_01_05_1706119852986_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_01_1704918360756,
          video: KIN_TRAIL_01_01_1704918360756mp4,
          gif: KIN_TRAIL_01_01_1704918360756gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_TRAIL_01_02_1704918840170,
          KIN_TRAIL_01_03_1704923088263,
          KIN_TRAIL_01_04_1704924628103,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_04_1706117162466_json,
          video: KIN_STAR_01_04_1706117162466_mp4,
          gif: KIN_STAR_01_04_1706117162466_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_03_1706117268326_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_03_1718015771438_json,
          video: KIN_FOIL_02_03_1718015771438_mp4,
          gif: KIN_FOIL_02_03_1718015771438_gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_02_1718026550484_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_05_1717773461536_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: "1701192692014",
      relatedCards: [
        "KIN_WARP_01_01",
        "KIN_WARP_01_02",
        "KIN_WARP_01_03",
        // "KIN_WARP_01_04",
      ],
      videoThumb: mp41701192692014,
      gifThumb: gif1701192692014,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: 0,
      videoThumb: farewellSimple1VideoThumb,
      gifThumb: farewellSimple1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: "1701193065349",
      relatedCards: [
        "KIN_WARP_02_01",
        "KIN_WARP_02_02",
        "KIN_WARP_02_03",
        // "KIN_WARP_02_04",
      ],
      videoThumb: mp41701193065349,
      gifThumb: gif1701193065349,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_02_01_1704918499855,
          video: KIN_TRAIL_02_01_1704918499855mp4,
          gif: KIN_TRAIL_02_01_1704918499855gif,
        },
        OCCASION_TYPES.LEAVING,
        [
          KIN_TRAIL_02_02_1704924098070,
          KIN_TRAIL_02_03_1704919391244,
          KIN_TRAIL_02_04_1704923218152,
        ]
      ),
    },

    {
      ...baseVariantConfig[titleVariants.BOAT],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: 0,
      videoThumb: farewellBoat1VideoThumb,
      gifThumb: farewellBoat1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: "1701193660064",
      relatedCards: [
        // "KIN_WARP_04_01",
        "KIN_WARP_04_02",
        "KIN_WARP_04_03",
        "KIN_WARP_04_04",
      ],
      videoThumb: mp41701193660064,
      gifThumb: gif1701193660064,
    },
    // {
    //   ...baseVariantConfig[titleVariants.ASTRONAUT],
    //   occasion: OCCASION_TYPES.LEAVING,
    //   defaultThemeIndex: 0,
    //   videoThumb: farewellAstronaut0VideoThumb,
    //   gifThumb: farewellAstronaut0GifThumb,
    // },
    {
      ...baseVariantConfig[titleVariants.ASTRONAUT],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: 2,
      videoThumb: farewellAstronaut3VideoThumb,
      gifThumb: farewellAstronaut3GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: 2,
      videoThumb: farewellStarburst3VideoThumb,
      gifThumb: farewellStarburst3GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: 7,
      videoThumb: farewellCurvey7VideoThumb,
      gifThumb: farewellCurvey7GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.LEAVING,
      defaultThemeIndex: 2,
      videoThumb: farewellCurvey2VideoThumb,
      gifThumb: farewellCurvey2GifThumb,
    },

    // MOTHERS
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_04_1716560606812_json,
          video: KIN_BLOONZ_01_04_1716560606812_mp4,
          gif: KIN_BLOONZ_01_04_1716560606812_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_05_1716385087830_json,
          KIN_BLOONZ_01_06_1716385203101_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_04_1718028047733_json,
          video: KIN_FOIL_01_04_1718028047733_mp4,
          gif: KIN_FOIL_01_04_1718028047733_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_03_1718027377403_json,
          KIN_FOIL_01_05_1718028134873_json,
          KIN_FOIL_01_06_1718026047956_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_05_1716212690055_json,
          video: KIN_FLEUR_01_05_1716212690055_mp4,
          gif: KIN_FLEUR_01_05_1716212690055_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_FLEUR_01_01_1715951243770_json,
          KIN_FLEUR_01_02_1715951839604_json,
          KIN_FLEUR_01_03_1715951957106_json,
          KIN_FLEUR_01_04_1715952143771_json,
          KIN_FLEUR_01_06_1715952454685_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_JUGGLE_01_01_1708099639178_json,
          video: KIN_JUGGLE_01_01_1708099639178_mp4,
          gif: KIN_JUGGLE_01_01_1708099639178_gif,
        },
        OCCASION_TYPES.MOTHERS,
        []
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_02_1718190941183_json,
          video: KIN_RAIN_01_02_1718190941183_mp4,
          gif: KIN_RAIN_01_02_1718190941183_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_RAIN_01_01_1718190561781_json,
          KIN_RAIN_01_03_1718190142656_json,
          KIN_RAIN_01_04_1718191167020_json,
          KIN_RAIN_01_05_1718191541913_json,
          KIN_RAIN_01_06_1718189204089_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 6,
      videoThumb: motherTrail6VideoThumb,
      gifThumb: motherTrail6GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_04_1717082038613_json,
          video: KIN_BLOONZ_02_04_1717082038613_mp4,
          gif: KIN_BLOONZ_02_04_1717082038613_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_05_1716560922230_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_07_1708097400597_json,
          video: KIN_GRUMPY_01_07_1708097400597_mp4,
          gif: KIN_GRUMPY_01_07_1708097400597_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_06_1707219253791_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_04_1706630386942_json,
          KIN_GRUMPY_01_03_1707219103031_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_02_1718028748917_json,
          video: KIN_FOIL_02_02_1718028748917_mp4,
          gif: KIN_FOIL_02_02_1718028748917_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_03_1718027833957_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_05_1717773461536_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 1,
      videoThumb: motherRipples2VideoThumb,
      gifThumb: motherRipples2GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 1,
      videoThumb: motherCurvey1VideoThumb,
      gifThumb: motherCurvey1GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_02_1708091249339_json,
          video: KIN_STAR_01_02_1708091249339_mp4,
          gif: KIN_STAR_01_02_1708091249339_gif,
        },
        OCCASION_TYPES.MOTHERS,
        []
      ),
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 10,
      videoThumb: motherDefault6VideoThumb,
      gifThumb: motherDefault6GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 1,
      videoThumb: motherHearts1VideoThumb,
      gifThumb: motherHearts1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 0,
      videoThumb: motherStarburst0VideoThumb,
      gifThumb: motherStarburst0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_TEXT],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 3,
      videoThumb: motherFlowersText3VideoThumb,
      gifThumb: motherFlowersText3GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_06_1708091010743_json,
          video: KIN_TRAIL_01_06_1708091010743_mp4,
          gif: KIN_TRAIL_01_06_1708091010743_gif,
        },
        OCCASION_TYPES.MOTHERS,
        [
          KIN_TRAIL_01_04_1704924628103,
          KIN_TRAIL_01_03_1704923088263,
          KIN_TRAIL_01_02_1704918840170,
          KIN_TRAIL_01_01_1704923387130,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.PAPER_HEARTS],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: 0,
      videoThumb: motherPaperHeart0VideoThumb,
      gifThumb: motherPaperHeart0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.MOTHERS,
      defaultThemeIndex: "1708090526357",
      relatedCards: [
        "KIN_WARP_02_01",
        "KIN_WARP_02_02",
        "KIN_WARP_02_03",
        "KIN_WARP_02_04",
      ],
      videoThumb: mp41708090526357,
      gifThumb: gif1708090526357,
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_06_1708095819724_json,
          video: KIN_RIPP_01_06_1708095819724_mp4,
          gif: KIN_RIPP_01_06_1708095819724_gif,
        },
        OCCASION_TYPES.MOTHERS,
        []
      ),
    },

    // FATHERS
    {
      ...getExportedCover(
        {
          data: KIN_FUZZ_02_05_1717426151794_json,
          video: KIN_FUZZ_02_05_1717426151794_mp4,
          gif: KIN_FUZZ_02_05_1717426151794_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_FUZZ_02_01_1717168407414_json,
          KIN_FUZZ_02_02_1717170091780_json,
          KIN_FUZZ_02_03_1717170345344_json,
          KIN_FUZZ_02_04_1717171564353_json,
          KIN_FUZZ_02_06_1717170957031_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_03_1715710953775_json,
          video: KIN_RETRO_01_03_1715710953775_mp4,
          gif: KIN_RETRO_01_03_1715710953775_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_RETRO_01_06_1715694795595_json,
          KIN_RETRO_01_05_1715694367964_json,
          KIN_RETRO_01_04_1715679156132_json,
          KIN_RETRO_01_02_1715619239370_json,
          KIN_RETRO_01_01_1714490793343_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_06_1717081306897_json,
          video: KIN_BLOONZ_01_06_1717081306897_mp4,
          gif: KIN_BLOONZ_01_06_1717081306897_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_BLOONZ_01_01_1716378186477_json,
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716560606812_json,
          KIN_BLOONZ_01_05_1716385087830_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RAIN_01_06_1718190738385_json,
          video: KIN_RAIN_01_06_1718190738385_mp4,
          gif: KIN_RAIN_01_06_1718190738385_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_RAIN_01_01_1718190561781_json,
          KIN_RAIN_01_02_1718190941183_json,
          KIN_RAIN_01_03_1718190142656_json,
          KIN_RAIN_01_04_1718191167020_json,
          KIN_RAIN_01_05_1718191541913_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_01_1718028537381_json,
          video: KIN_FOIL_02_01_1718028537381_mp4,
          gif: KIN_FOIL_02_01_1718028537381_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_FOIL_02_02_1718026550484_json,
          KIN_FOIL_02_03_1718027833957_json,
          KIN_FOIL_02_04_1718018027596_json,
          KIN_FOIL_02_05_1717773461536_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_JUGGLE_02_01_1716888998740_json,
          video: KIN_JUGGLE_02_01_1716888998740_mp4,
          gif: KIN_JUGGLE_02_01_1716888998740_gif,
        },
        OCCASION_TYPES.FATHERS,
        []
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_03_1716555676669_json,
          video: KIN_BLOONZ_02_03_1716555676669_mp4,
          gif: KIN_BLOONZ_02_03_1716555676669_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_04_1716468557922_json,
          KIN_BLOONZ_02_05_1716468970813_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_03_1717426733778_json,
          video: KIN_TRAIL_01_03_1717426733778_mp4,
          gif: KIN_TRAIL_01_03_1717426733778_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_TRAIL_01_01_1704923387130,
          KIN_TRAIL_01_02_1704918840170,
          KIN_TRAIL_01_04_1704924628103,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_03_1717427326649_json,
          video: KIN_GRUMPY_01_03_1717427326649_mp4,
          gif: KIN_GRUMPY_01_03_1717427326649_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_07_1707219299310_json,
          KIN_GRUMPY_01_06_1707219253791_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_04_1706630386942_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_06_1717407029189_json,
          video: KIN_FLEUR_01_06_1717407029189_mp4,
          gif: KIN_FLEUR_01_06_1717407029189_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_FLEUR_01_01_1716212542664_json,
          KIN_FLEUR_01_02_1715951839604_json,
          KIN_FLEUR_01_03_1715951957106_json,
          KIN_FLEUR_01_04_1715952143771_json,
          KIN_FLEUR_01_05_1715952322408_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_05_1718028134873_json,
          video: KIN_FOIL_01_05_1718028134873_mp4,
          gif: KIN_FOIL_01_05_1718028134873_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_03_1718027377403_json,
          KIN_FOIL_01_04_1718028047733_json,
          KIN_FOIL_01_06_1718026047956_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_EGG_01_03_1717427019514_json,
          video: KIN_EGG_01_03_1717427019514_mp4,
          gif: KIN_EGG_01_03_1717427019514_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_EGG_01_01_1709579418451_json,
          KIN_EGG_01_02_1709824586381_json,
          KIN_EGG_01_04_1709825282295_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_02_1717430262797_json,
          video: KIN_STAR_01_02_1717430262797_mp4,
          gif: KIN_STAR_01_02_1717430262797_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_03_1706117268326_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_FUZZ_01_03_1717425838197_json,
          video: KIN_FUZZ_01_03_1717425838197_mp4,
          gif: KIN_FUZZ_01_03_1717425838197_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_FUZZ_01_01_1708086137253_json,
          KIN_FUZZ_01_02_1713775937028_json,
          KIN_FUZZ_01_04_1717166334390_json,
          KIN_FUZZ_01_05_1717166665932_json,
          KIN_FUZZ_01_06_1717166112331_json,
        ]
      ),
    },

    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.FATHERS,
      exportedContent: dad_lemons_data,
      videoThumb: dad_lemonsVideoThumb,
      gifThumb: dad_lemonsGifThumb,
      getExportedContent: () => Promise.resolve(dad_lemons_data),
      label: "Muffin Without You", // overwrite base Exported label
      exportedId: dad_lemons_data.exported_id,
    },
    {
      ...baseVariantConfig[titleVariants.EXPORTED],
      occasion: OCCASION_TYPES.FATHERS,
      exportedContent: fathers_vader_day_card_data,
      videoThumb: fathers_vader_day_cardVideoThumb,
      gifThumb: fathers_vader_day_cardGifThumb,
      getExportedContent: () => Promise.resolve(fathers_vader_day_card_data),
      label: "Vader's Day", // overwrite base Exported label
      exportedId: fathers_vader_day_card_data.exported_id,
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_01_1717430625295_json,
          video: KIN_RIPP_01_01_1717430625295_mp4,
          gif: KIN_RIPP_01_01_1717430625295_gif,
        },
        OCCASION_TYPES.FATHERS,
        [
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
          KIN_RIPP_01_05_1706119852986_json,
        ]
      ),
    },

    // THANKS
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_01_1717080759183_json,
          video: KIN_BLOONZ_01_01_1717080759183_mp4,
          gif: KIN_BLOONZ_01_01_1717080759183_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716560606812_json,
          KIN_BLOONZ_01_05_1716385087830_json,
          KIN_BLOONZ_01_06_1716385203101_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_05_1716913232942_json,
          video: KIN_WAVES_01_05_1716913232942_mp4,
          gif: KIN_WAVES_01_05_1716913232942_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_WAVES_01_01_1715271007383_json,
          KIN_WAVES_01_02_1716912595705_json,
          KIN_WAVES_01_03_1716912874983_json,
          KIN_WAVES_01_04_1716913109135_json,
          KIN_WAVES_01_06_1716913711822_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RETRO_01_03_1715707974555_json,
          video: KIN_RETRO_01_03_1715707974555_mp4,
          gif: KIN_RETRO_01_03_1715707974555_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_RETRO_01_06_1715694795595_json,
          KIN_RETRO_01_05_1715694367964_json,
          KIN_RETRO_01_04_1715679156132_json,
          KIN_RETRO_01_02_1715619239370_json,
          KIN_RETRO_01_01_1714490793343_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LAVA_01_01_1715271588380_json,
          video: KIN_LAVA_01_01_1715271588380_mp4,
          gif: KIN_LAVA_01_01_1715271588380_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_LAVA_01_06_1714126683983_json,
          KIN_LAVA_01_05_1714127004822_json,
          KIN_LAVA_01_04_1714126301246_json,
          KIN_LAVA_01_03_1714122945946_json,
          KIN_LAVA_01_01_1714123409133_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: PIC_TRAIL_01_08_1713452880197_json,
          video: PIC_TRAIL_01_08_1713452880197_mp4,
          gif: PIC_TRAIL_01_08_1713452880197_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          PIC_TRAIL_01_07_1713452880197c_json,
          PIC_TRAIL_01_06_1713452880197d_json,
          PIC_TRAIL_01_03_1713452880197f_json,
          PIC_TRAIL_01_01_1713452880197h_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_04_1706630386942_json,
          video: KIN_GRUMPY_01_04_1706630386942_mp4,
          gif: KIN_GRUMPY_01_04_1706630386942_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_07_1707219299310_json,
          KIN_GRUMPY_01_06_1707219253791_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_03_1707219103031_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_01_1706119104225_json,
          video: KIN_RIPP_01_01_1706119104225_mp4,
          gif: KIN_RIPP_01_01_1706119104225_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
          KIN_RIPP_01_05_1706119852986_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_02_1706117462994_json,
          video: KIN_STAR_01_02_1706117462994_mp4,
          gif: KIN_STAR_01_02_1706117462994_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_03_1706117268326_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_04_1716555062196_json,
          video: KIN_BLOONZ_02_04_1716555062196_mp4,
          gif: KIN_BLOONZ_02_04_1716555062196_gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_BLOONZ_02_01_1716468165100_json,
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_05_1716468970813_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_03_1704923088263,
          video: KIN_TRAIL_01_03_1704923088263mp4,
          gif: KIN_TRAIL_01_03_1704923088263gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_TRAIL_01_01_1704923387130,
          KIN_TRAIL_01_02_1704918840170,
          KIN_TRAIL_01_04_1704924628103,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: "1702032568233",
      relatedCards: ["KIN_WARP_03_01", "KIN_WARP_03_03", "KIN_WARP_03_04"],
      videoThumb: mp41702032568233,
      gifThumb: gif1702032568233,
    },

    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: 4,
      videoThumb: thanksFlowersShape4VideoThumb,
      gifThumb: thanksFlowersShape4GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_TEXT],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: 3,
      videoThumb: thanksFlowersText3VideoThumb,
      gifThumb: thanksFlowersText3GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_02_04_1704923218152,
          video: KIN_TRAIL_02_04_1704923218152mp4,
          gif: KIN_TRAIL_02_04_1704923218152gif,
        },
        OCCASION_TYPES.THANKS,
        [
          KIN_TRAIL_02_01_1704923815179,
          KIN_TRAIL_02_02_1704924098070,
          KIN_TRAIL_02_03_1704919391244,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: 6,
      videoThumb: thanksHearts7VideoThumb,
      gifThumb: thanksHearts7GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: "1702031889156",
      relatedCards: ["KIN_WARP_01_01", "KIN_WARP_01_03", "KIN_WARP_01_04"],
      videoThumb: mp41702031889156,
      gifThumb: gif1702031889156,
    },

    {
      ...baseVariantConfig[titleVariants.CAKE],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: 5,
      videoThumb: thanksCake6VideoThumb,
      gifThumb: thanksCake6GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: "1702032074564",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_02", "KIN_WARP_02_04"],
      videoThumb: mp41702032074564,
      gifThumb: gif1702032074564,
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: 0,
      videoThumb: thanksTrail2PicVideoThumb,
      gifThumb: thanksTrail2PicGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: 1,
      videoThumb: thanksRipples2VideoThumb,
      gifThumb: thanksRipples2GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.THANKS,
      defaultThemeIndex: 0,
      videoThumb: thanksSimple1VideoThumb,
      gifThumb: thanksSimple1GifThumb,
    },

    // TRIP

    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 8,
      videoThumb: tripCurvey8VideoThumb,
      gifThumb: tripCurvey8GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 0,
      videoThumb: tripSimple1VideoThumb,
      gifThumb: tripSimple1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.BOAT],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 1,
      videoThumb: tripBoat1VideoThumb,
      gifThumb: tripBoat1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 0,
      videoThumb: tripStarburst1VideoThumb,
      gifThumb: tripStarburst1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 6,
      videoThumb: tripCurvey6VideoThumb,
      gifThumb: tripCurvey6GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 1,
      videoThumb: tripRipples2VideoThumb,
      gifThumb: tripRipples2GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 5,
      videoThumb: tripTrail6PicVideoThumb,
      gifThumb: tripTrail6PicGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.ROCKET],
      occasion: OCCASION_TYPES.TRIP,
      defaultThemeIndex: 1,
      videoThumb: tripRocket2VideoThumb,
      gifThumb: tripRocket2GifThumb,
    },

    // VALENTINES
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_02_04_1718018027596_json,
          video: KIN_FOIL_02_04_1718018027596_mp4,
          gif: KIN_FOIL_02_04_1718018027596_gif,
        },
        OCCASION_TYPES.VALENTINES,
        [
          KIN_FOIL_02_01_1718028537381_json,
          KIN_FOIL_02_02_1718026550484_json,
          KIN_FOIL_02_03_1718027833957_json,
          KIN_FOIL_02_05_1717773461536_json,
          KIN_FOIL_02_06_1718027112755_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_PUP_01_08_1707220385643_json,
          video: KIN_PUP_01_08_1707220385643_mp4,
          gif: KIN_PUP_01_08_1707220385643_gif,
        },
        OCCASION_TYPES.VALENTINES,
        []
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_06_1706697866964_json,
          video: KIN_TOAST_01_06_1706697866964_mp4,
          gif: KIN_TOAST_01_06_1706697866964_gif,
        },
        OCCASION_TYPES.VALENTINES,
        []
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_05_1706119647505_json,
          video: KIN_RIPP_01_05_1706119647505_mp4,
          gif: KIN_RIPP_01_05_1706119647505_gif,
        },
        OCCASION_TYPES.VALENTINES,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
          // KIN_TRAIL_01_02_1704918840170,
          // KIN_TRAIL_01_03_1704923088263,
          // KIN_TRAIL_01_04_1704924628103,
        ]
      ),
    },
    // Issue with this card showing multiple themes
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.VALENTINES,
      defaultThemeIndex: "1705957011407",
      relatedCards: [],
      videoThumb: mp41705957011407,
      gifThumb: gif1705957011407,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_01_1705929056555_json,
          video: KIN_TRAIL_01_01_1705929056555_mp4,
          gif: KIN_TRAIL_01_01_1705929056555_gif,
        },
        OCCASION_TYPES.VALENTINES,
        []
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_08_1706633174896_json,
          video: KIN_GRUMPY_01_08_1706633174896_mp4,
          gif: KIN_GRUMPY_01_08_1706633174896_gif,
        },
        OCCASION_TYPES.VALENTINES,
        []
      ),
      // disabled: false,
    },
    {
      ...getExportedCover(
        {
          data: KIN_FOIL_01_04_1718018438426_json,
          video: KIN_FOIL_01_04_1718018438426_mp4,
          gif: KIN_FOIL_01_04_1718018438426_gif,
        },
        OCCASION_TYPES.VALENTINES,
        [
          KIN_FOIL_01_01_1718026678590_json,
          KIN_FOIL_01_02_1718027530241_json,
          KIN_FOIL_01_03_1718027377403_json,
          KIN_FOIL_01_05_1718028134873_json,
          KIN_FOIL_01_06_1718026047956_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.VALENTINES,
      defaultThemeIndex: 4,
      videoThumb: valentineTrail4VideoThumb,
      gifThumb: valentineTrail4GifThumb,
    },

    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_03_1705928508849_json,
          video: KIN_STAR_01_03_1705928508849_mp4,
          gif: KIN_STAR_01_03_1705928508849_gif,
        },
        OCCASION_TYPES.VALENTINES,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },

    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_05_1705956351303_json,
          video: KIN_RIPP_01_05_1705956351303_mp4,
          gif: KIN_RIPP_01_05_1705956351303_gif,
        },
        OCCASION_TYPES.VALENTINES,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_05_1706697675053_json,
          video: KIN_TOAST_01_05_1706697675053_mp4,
          gif: KIN_TOAST_01_05_1706697675053_gif,
        },
        OCCASION_TYPES.VALENTINES,
        []
      ),
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.VALENTINES,
      defaultThemeIndex: 1,
      videoThumb: valentineHearts1VideoThumb,
      gifThumb: valentineHearts1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.PAPER_HEARTS],
      occasion: OCCASION_TYPES.VALENTINES,
      defaultThemeIndex: 1,
      videoThumb: valentinePaperHeart1VideoThumb,
      gifThumb: valentinePaperHeart1GifThumb,
    },
    // Why was this duplicate here? All identical properties to the other one in valentines
    // {
    //   ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
    //   occasion: OCCASION_TYPES.VALENTINES,
    //   defaultThemeIndex: 4,
    //   videoThumb: valentineTrail4VideoThumb,
    //   gifThumb: valentineTrail4GifThumb,
    // },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.VALENTINES,
      defaultThemeIndex: 7,
      videoThumb: valentineCurvey7VideoThumb,
      gifThumb: valentineCurvey7GifThumb,
      disabled: true,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.VALENTINES,
      defaultThemeIndex: 7,
      videoThumb: valentineSimple7VideoThumb,
      gifThumb: valentineSimple7GifThumb,
      disabled: true,
    },
    {
      ...baseVariantConfig[titleVariants.PAPER_HEARTS],
      occasion: OCCASION_TYPES.VALENTINES,
      defaultThemeIndex: 0,
      videoThumb: valentinePaperHeart0VideoThumb,
      gifThumb: valentinePaperHeart0GifThumb,
    },

    // WEDDING
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_02_01_1717081019156_json,
          video: KIN_BLOONZ_02_01_1717081019156_mp4,
          gif: KIN_BLOONZ_02_01_1717081019156_gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_BLOONZ_02_02_1716468338295_json,
          KIN_BLOONZ_02_03_1716468453740_json,
          KIN_BLOONZ_02_04_1716468557922_json,
          KIN_BLOONZ_02_05_1716560922230_json,
          KIN_BLOONZ_02_06_1716470475503_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLEUR_01_01_1716212542664_json,
          video: KIN_FLEUR_01_01_1716212542664_mp4,
          gif: KIN_FLEUR_01_01_1716212542664_gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_FLEUR_01_02_1715951839604_json,
          KIN_FLEUR_01_03_1715951957106_json,
          KIN_FLEUR_01_04_1715952143771_json,
          KIN_FLEUR_01_05_1715952322408_json,
          KIN_FLEUR_01_06_1715952454685_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_08_1706632929458_json,
          video: KIN_GRUMPY_01_08_1706632929458_mp4,
          gif: KIN_GRUMPY_01_08_1706632929458_gif,
        },
        OCCASION_TYPES.WEDDING,
        []
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_03_1706200484846_json,
          video: KIN_TOAST_01_03_1706200484846_mp4,
          gif: KIN_TOAST_01_03_1706200484846_gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_TOAST_01_01_1706199537719_json,
          KIN_TOAST_01_02_1706200238879_json,
          KIN_TOAST_01_04_1706201376355_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_05_1706119852986_json,
          video: KIN_RIPP_01_05_1706119852986_mp4,
          gif: KIN_RIPP_01_05_1706119852986_gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_02_1706120193991_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_03_1706118289742_json,
          video: KIN_STAR_01_03_1706118289742_mp4,
          gif: KIN_STAR_01_03_1706118289742_gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_02_1705513441318_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_02_02_1704924098070,
          video: KIN_TRAIL_02_02_1704924098070mp4,
          gif: KIN_TRAIL_02_02_1704924098070gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_TRAIL_02_01_1704923815179,
          KIN_TRAIL_02_03_1704919391244,
          KIN_TRAIL_02_04_1704923218152,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: "1702055776753",
      relatedCards: ["KIN_WARP_02_01", "KIN_WARP_02_02", "KIN_WARP_02_04"],
      videoThumb: mp41702055776753,
      gifThumb: gif1702055776753,
    },

    {
      ...baseVariantConfig[titleVariants.PARTICLES_SHAPE_PHOTO],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 3,
      videoThumb: weddingFlowersShape3VideoThumb,
      gifThumb: weddingFlowersShape3GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_BLOONZ_01_01_1716554905320_json,
          video: KIN_BLOONZ_01_01_1716554905320_mp4,
          gif: KIN_BLOONZ_01_01_1716554905320_gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_BLOONZ_01_02_1716384398009_json,
          KIN_BLOONZ_01_03_1716384683317_json,
          KIN_BLOONZ_01_04_1716384755410_json,
          KIN_BLOONZ_01_05_1716385087830_json,
          KIN_BLOONZ_01_06_1716385203101_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.PARTICLES_TEXT],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 0,
      videoThumb: weddingFlowersText0VideoThumb,
      gifThumb: weddingFlowersText0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: "1702057169868",
      relatedCards: ["KIN_WARP_04_01", "KIN_WARP_04_03", "KIN_WARP_04_04"],
      videoThumb: mp41702057169868,
      gifThumb: gif1702057169868,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_04_1704924628103,
          video: KIN_TRAIL_01_04_1704924628103mp4,
          gif: KIN_TRAIL_01_04_1704924628103gif,
        },
        OCCASION_TYPES.WEDDING,
        [
          KIN_TRAIL_01_01_1704923387130,
          KIN_TRAIL_01_02_1704918840170,
          KIN_TRAIL_01_03_1704923088263,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.RIPPLES],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 4,
      videoThumb: weddingRipples5VideoThumb,
      gifThumb: weddingRipples5GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 5,
      videoThumb: weddingTrail8PicVideoThumb,
      gifThumb: weddingTrail8PicGifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 3,
      videoThumb: weddingStarburst4VideoThumb,
      gifThumb: weddingStarburst4GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 5,
      videoThumb: weddingCurvey5VideoThumb,
      gifThumb: weddingCurvey5GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 2,
      videoThumb: weddingCurvey2VideoThumb,
      gifThumb: weddingCurvey2GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.HEARTS],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 0,
      videoThumb: weddingHearts1VideoThumb,
      gifThumb: weddingHearts1GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CAKE],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 4,
      videoThumb: weddingCake5VideoThumb,
      gifThumb: weddingCake5GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.DEFAULT],
      occasion: OCCASION_TYPES.WEDDING,
      defaultThemeIndex: 0,
      videoThumb: weddingSimple1VideoThumb,
      gifThumb: weddingSimple1GifThumb,
    },

    // WELCOME
    {
      ...getExportedCover(
        {
          data: KIN_FUZZ_01_03_1713357946823_json,
          video: KIN_FUZZ_01_03_1713357946823_mp4,
          gif: KIN_FUZZ_01_03_1713357946823_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_FUZZ_01_01_1708086137253_json,
          KIN_FUZZ_01_02_1713775937028_json,
          KIN_FUZZ_01_04_1717166334390_json,
          KIN_FUZZ_01_05_1717166665932_json,
          KIN_FUZZ_01_06_1717166112331_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_WAVES_01_06_1716913711822_json,
          video: KIN_WAVES_01_06_1716913711822_mp4,
          gif: KIN_WAVES_01_06_1716913711822_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_WAVES_01_01_1715271007383_json,
          KIN_WAVES_01_02_1716912595705_json,
          KIN_WAVES_01_03_1716912874983_json,
          KIN_WAVES_01_04_1716913109135_json,
          KIN_WAVES_01_05_1716913232942_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_LAVA_01_01_1716914643177_json,
          video: KIN_LAVA_01_01_1716914643177_mp4,
          gif: KIN_LAVA_01_01_1716914643177_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_LAVA_01_06_1714126683983_json,
          KIN_LAVA_01_05_1714127004822_json,
          KIN_LAVA_01_04_1714126301246_json,
          KIN_LAVA_01_03_1714122945946_json,
          KIN_LAVA_01_01_1714123409133_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_RIPP_01_02_1706120193991_json,
          video: KIN_RIPP_01_02_1706120193991_mp4,
          gif: KIN_RIPP_01_02_1706120193991_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_RIPP_01_01_1706119104225_json,
          KIN_RIPP_01_03_1706118577510_json,
          KIN_RIPP_01_04_1706119328432_json,
          KIN_RIPP_01_05_1706119852986_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_01_02_1713359980530_json,
          video: KIN_TRAIL_01_02_1713359980530_mp4,
          gif: KIN_TRAIL_01_02_1713359980530_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_TRAIL_01_01_1704923387130,
          KIN_TRAIL_01_03_1704923088263,
          KIN_TRAIL_01_04_1713776350007_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_FLOWER_01_04_1713359806965_json,
          video: KIN_FLOWER_01_04_1713359806965_mp4,
          gif: KIN_FLOWER_01_04_1713359806965_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_FLOWER_01_06_1713788397899_json,
          KIN_FLOWER_01_05_1711038056587_json,
          KIN_FLOWER_01_03_1711037715638_json,
          KIN_FLOWER_01_02_1711037483728_json,
          KIN_FLOWER_01_01_1711037042960_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.TRAIL_PHOTO],
      occasion: OCCASION_TYPES.WELCOME,
      defaultThemeIndex: 3,
      videoThumb: welcomeTrail3PhotoVideoThumb,
      gifThumb: welcomeTrail3PhotoGifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_GRUMPY_01_06_1706631579381_json,
          video: KIN_GRUMPY_01_06_1706631579381_mp4,
          gif: KIN_GRUMPY_01_06_1706631579381_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_GRUMPY_01_08_1707219361975_json,
          KIN_GRUMPY_01_07_1707219299310_json,
          KIN_GRUMPY_01_05_1707219210013_json,
          KIN_GRUMPY_01_04_1707219188063_json,
          KIN_GRUMPY_01_03_1707219103031_json,
          KIN_GRUMPY_01_02_1707219049476_json,
          KIN_GRUMPY_01_01_1706624984594_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.WELCOME,
      defaultThemeIndex: "1702058034876",
      relatedCards: ["KIN_WARP_02_02", "KIN_WARP_02_03", "KIN_WARP_02_04"],
      videoThumb: mp41702058034876,
      gifThumb: gif1702058034876,
    },
    {
      ...getExportedCover(
        {
          data: KIN_STAR_01_02_1706118416827_json,
          video: KIN_STAR_01_02_1706118416827_mp4,
          gif: KIN_STAR_01_02_1706118416827_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_STAR_01_01_1705512850917_json,
          KIN_STAR_01_03_1706117268326_json,
          KIN_STAR_01_04_1706117900353_json,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_SIMPLE_01_05_1713361000369_json,
          video: KIN_SIMPLE_01_05_1713361000369_mp4,
          gif: KIN_SIMPLE_01_05_1713361000369_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_SIMPLE_01_06_1710957904512_json,
          KIN_SIMPLE_01_04_1710957777694_json,
          KIN_SIMPLE_01_03_1710957724769_json,
          KIN_SIMPLE_01_02_1710957667663_json,
          KIN_SIMPLE_01_01_1710957601832_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.STARBURST],
      occasion: OCCASION_TYPES.WELCOME,
      defaultThemeIndex: 1,
      videoThumb: welcomeStarburst2VideoThumb,
      gifThumb: welcomeStarburst2GifThumb,
    },
    {
      ...getExportedCover(
        {
          data: KIN_TRAIL_02_04_1704925045956,
          video: KIN_TRAIL_02_04_1704925045956mp4,
          gif: KIN_TRAIL_02_04_1704925045956gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_TRAIL_02_01_1704923815179,
          KIN_TRAIL_02_02_1704924098070,
          KIN_TRAIL_02_03_1704919391244,
        ]
      ),
    },
    {
      ...getExportedCover(
        {
          data: KIN_TOAST_01_04_1713361191836_json,
          video: KIN_TOAST_01_04_1713361191836_mp4,
          gif: KIN_TOAST_01_04_1713361191836_gif,
        },
        OCCASION_TYPES.WELCOME,
        [
          KIN_TOAST_01_01_1713788217971_json,
          KIN_TOAST_01_02_1706200238879_json,
          KIN_TOAST_01_03_1708975205664_json,
        ]
      ),
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY],
      occasion: OCCASION_TYPES.WELCOME,
      defaultThemeIndex: 3,
      videoThumb: welcomeCurvey0VideoThumb,
      gifThumb: welcomeCurvey0GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.ROCKET],
      occasion: OCCASION_TYPES.WELCOME,
      defaultThemeIndex: 2,
      videoThumb: welcomeRocket3VideoThumb,
      gifThumb: welcomeRocket3GifThumb,
    },
    {
      ...baseVariantConfig[titleVariants.CURVEY2],
      occasion: OCCASION_TYPES.WELCOME,
      defaultThemeIndex: "1713362807735",
      relatedCards: ["KIN_WARP_01_02", "KIN_WARP_01_03", "KIN_WARP_01_04"],
      videoThumb: mp41713362807735,
      gifThumb: gif1713362807735,
    },
    {
      ...getExportedCover(
        {
          data: unicorn_clouds_birthday_1713361421329_json,
          video: unicorn_clouds_birthday_1713361421329_mp4,
          gif: unicorn_clouds_birthday_1713361421329_gif,
        },
        OCCASION_TYPES.WELCOME,
        []
      ),
    },
  ],
  fields: [
    {
      key: "photos",
      type: inputTypes.MEDIA,
      label: "Photo",
      icon: "add-photo",
      mediaType: mediaTypes.PHOTO,
      validation: {
        maxLength: {
          value: 1,
          message: `You can only choose one photo`,
        },
      },
      variants: [
        titleVariants.DEFAULT,
        titleVariants.TRAIL_PHOTO,
        titleVariants.PARTICLES_SHAPE_PHOTO,
      ],
    },
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Edit title",
      placeholder: "e.g. Happy Birthday Claire",
      validation: {
        required: "Add a title to your Kindeo",
        validate: v => v.trim().length > 0 || "A title is required",
        maxLength: {
          value: 40,
          message: `Please keep this under 40 characters`,
        },
      },
      variants: [titleVariants.CURVEY2],
    },
    {
      key: "title",
      type: inputTypes.INPUT,
      label: "Edit title",
      placeholder: "e.g. Happy Birthday Claire",
      validation: {
        required: "Add a title to your Kindeo",
        validate: v => v.trim().length > 0 || "A title is required",
        maxLength: {
          value: 80,
          message: `Please keep this under 80 characters`,
        },
      },
      variants: Object.values(titleVariants).filter(
        titleVariant => titleVariant !== titleVariants.CURVEY2
      ),
    },
    {
      key: "title_position",
      type: inputTypes.ALIGN_H,
      options: ["Left", "Right"],
      defaultValue: "Left".toLowerCase(),
      label: "Position",
      variants: [titleVariants.TRAIL, titleVariants.TRAIL_PHOTO],
    },
    {
      key: "title_position",
      type: inputTypes.ALIGN_V,
      options: ["Middle", "Top", "Bottom"],
      defaultValue: "Middle".toLowerCase(),
      label: "Position",
      variants: [titleVariants.DEFAULT],
    },

    // UNDEFINED
  ],
};
