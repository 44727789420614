import classnames from "classnames";
import React from "react";
import styles from "./index.module.scss";

export const SimpleButton = React.memo(
  ({
    children,
    backgroundColor,
    textColor,
    className,
    danger,
    loading,
    multiline,
    onClick,
    primary,
    text,
    ...restProps
  }) => {
    const bgColor =
      backgroundColor ||
      (danger ? "delete" : primary ? "primary" : "secondary");
    const color = textColor || primary || danger ? "white" : "black";

    return (
      <button
        type="button"
        {...restProps}
        className={classnames(
          `button is-${bgColor} has-text-${color}`,
          styles.button,
          className,
          {
            [styles.multiline]: multiline,
            [styles.danger]: danger,
            "is-loading": loading,
            [styles.loading]: loading,
          }
        )}
        onClick={onClick}
      >
        {text}
        {children}
      </button>
    );
  }
);
