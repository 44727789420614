/* Libraries */
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Queue from "queue";
import { useThrottledCallback } from "use-debounce";
/* -Libraries */

/* Actions */
import * as mediaActions from "redux/media/actions";
/* -Actions */

/* Selectors */
/* -Selectors */

/* Hooks */
import useIsMounted from "./useIsMounted";
/* -Hooks */

import analytics, { EVENTS } from "utils/analyticsUtils";
import { roundTo2dp } from "utils/numberUtils";
import { capitaliseFirstLetter } from "utils/stringUtils";

const uploadQueue = new Queue({
  // autostart: true,
  concurrency: 5,
});

const useUpload = () => {
  const [currentMediaType, setCurrentMediaType] = useState();

  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const [uploadCancelled, setUploadCancelled] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadsInProgress, setUploadsInProgress] = useState(0);
  const [uploadError, setUploadError] = useState();

  // the total size of all files in the upload queue
  const totalUploadSizeRef = useRef(0);
  // an array, index linked to the upload array, of the percentage upload
  // progress of each file and its percentage size of the whole upload
  const individualUploadProgressRef = useRef([]);
  // cancellation controls for each upload, referenced by the S3 key
  const uploadCancelControlsRef = useRef({});
  // an array of the results of each upload
  const uploadResultsRef = useRef([]);

  // add a listener to the queue, only once
  useEffect(() => {
    // after each upload, add to uploadResults
    uploadQueue.addEventListener("success", e => {
      const { result } = e.detail;
      if (result.length) {
        uploadResultsRef.current.push(result[0]);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(mediaActions.setMediaUploading(uploadsInProgress));
  }, [dispatch, uploadsInProgress]);

  // calculate the full upload size across all files
  const getFullUploadSize = useCallback(fileArray => {
    return fileArray.reduce(
      (runningTotal, file) => runningTotal + file?.size || 0,
      0
    );
  }, []);

  // initialise all variables
  const onUploadStart = useCallback(
    fileArray => {
      const uploadSize = getFullUploadSize(fileArray);
      totalUploadSizeRef.current = uploadSize;
      individualUploadProgressRef.current = fileArray.map(file => {
        return {
          percentSize: file.size / uploadSize,
          percentProgress: 0,
        };
      });
      uploadCancelControlsRef.current = {};
      uploadResultsRef.current = [];
      setUploadCancelled(false);
      setUploadError(null);
      setUploadProgress(0);
      setUploadsInProgress(fileArray.length);
    },
    [getFullUploadSize]
  );

  const onUploadError = useCallback(
    errorMsg => {
      // if the upload was cancelled don't show an error
      // even if the cancelled state hasn't yet propagated,
      if (!uploadCancelled && errorMsg !== "canceled") {
        setUploadError(errorMsg);
      }
      setUploadsInProgress(0);
    },
    [uploadCancelled]
  );

  const cancelUpload = useCallback(() => {
    // prevent upload errors being shown after an upload has been cancelled
    setUploadCancelled(true);
    setUploadProgress(0);
    setUploadsInProgress(0);
    totalUploadSizeRef.current = 0;

    // clear any remaining queued uploads
    uploadQueue.end();

    // take this out of the current execution stack
    // so that the state has time to be set
    window.setTimeout(() => {
      Object.values(uploadCancelControlsRef.current).forEach(cancel =>
        cancel()
      );
    }, 100);
  }, []);

  // begin uploading a file, save a reference to the cancellation control
  const uploadIndividualFile = useCallback(
    async (file, onAfterUploadSuccess, onUploadProgress) => {
      const logUploadEvent = (event, property) => {
        analytics.event(`${capitaliseFirstLetter(currentMediaType)} ${event}`, {
          file_name: file.name,
          file_size_mb: roundTo2dp(file.size / (1024 * 1024)),
          ...(property || {}),
        });
      };

      // Edge saves JPG files with the extension ".jfif"
      // rename them here to avoid problems with cloud processing
      if (/\.jfif$/i.test(file.name)) {
        const fileContents = await file.arrayBuffer();
        file = new File([fileContents], file.name.replace(/\.jfif$/i, ".jpg"), {
          type: "image/jpeg",
        });
      }

      // start the file upload
      logUploadEvent(EVENTS.mediaUpload.started);
      let uploadStartTime = Date.now();
      const { cancel, s3Key, request } = await dispatch(
        mediaActions.beginFileUpload({
          file,
          mediaType: currentMediaType,
          mimeType: file.type,
          onUploadProgress,
        })
      );

      // store the upload cancellation control
      uploadCancelControlsRef.current[s3Key] = cancel;

      // return the request promise
      return (
        request
          // and when it resolves run any after upload success actions
          .then(() => {
            if (isMounted()) {
              // remove the upload cancellation control
              delete uploadCancelControlsRef.current[s3Key];

              // pass back new file attributes with S3 key
              const fileAttributes = {
                key: s3Key,
                mediaType: currentMediaType,
                mimeType: file.type,
                file_size_kb: roundTo2dp(file.size / 1024),
              };

              logUploadEvent(EVENTS.mediaUpload.completed, {
                s3_path: s3Key,
                // seconds to 2dp
                upload_time_seconds: roundTo2dp(
                  (Date.now() - uploadStartTime) / 1000
                ),
              });
              return (
                onAfterUploadSuccess
                  ? onAfterUploadSuccess(fileAttributes)
                  : Promise.resolve(fileAttributes)
              )
                .catch(e => {
                  throw new Error(
                    e?.message ||
                      `There was an issue uploading your ${currentMediaType}. Please contact support if the problem persists.`
                  );
                })
                .finally(() => {
                  if (isMounted()) {
                    setUploadsInProgress(current => current - 1);
                  }
                });
            }
          })
          .catch(err => {
            // remove the upload cancellation control
            delete uploadCancelControlsRef.current[s3Key];

            if (uploadCancelled || err === "canceled") {
              logUploadEvent(EVENTS.mediaUpload.cancelled);
            } else {
              let errorMessage;
              if (typeof err === "string") {
                errorMessage = err;
              } else {
                errorMessage = err?.message || "Upload failed";
              }
              logUploadEvent(EVENTS.mediaUpload.failed, {
                error: errorMessage,
              });
              if (isMounted()) {
                onUploadError(errorMessage);
              }

              throw new Error(err);
            }
          })
      );
    },
    [currentMediaType, dispatch, isMounted, onUploadError, uploadCancelled]
  );

  // update the total upload progress by summing the progress of each file
  // throttled to avoid too many updates to state
  const updateUploadProgress = useThrottledCallback(
    () => {
      const totalProgress = Math.round(
        individualUploadProgressRef.current.reduce(
          (runningTotal, uploadProgress) =>
            runningTotal +
            uploadProgress.percentSize * uploadProgress.percentProgress,
          0
        )
      );
      setUploadProgress(totalProgress);
    },
    500,
    { leading: false, trailing: true }
  );

  // returned a function that will update the progress for a particular upload
  const provideOnProgress = useCallback(
    fileIndex => {
      return progress => {
        individualUploadProgressRef.current[fileIndex].percentProgress =
          progress;
        updateUploadProgress();
      };
    },
    [updateUploadProgress]
  );

  const uploadFiles = useCallback(
    async (files, onAfterUploadSuccess) => {
      const isMultiple = files instanceof FileList;
      let fileArray = isMultiple ? Array.from(files) : [files];

      onUploadStart(fileArray);

      // use for..in because this loop has async actions and we need to pause
      // execution while each file starts uploading and it's request
      // is added to the uploadPromises array
      // however avoid non-numeric keys to restrict our iteration to array indexes
      // (there was a problem where a 'move' method was included with individual image uploads)
      for (const fileIndex in fileArray) {
        if (!isNaN(parseInt(fileIndex, 10))) {
          uploadQueue.push(() => {
            return uploadIndividualFile(
              fileArray[fileIndex],
              onAfterUploadSuccess,
              provideOnProgress(fileIndex)
            );
          });
        }
      }

      // start the queue processing and when it completes return the results
      return uploadQueue
        .start()
        .then(() => {
          return uploadResultsRef.current;
        })
        .catch(e => {
          throw e;
        });
    },
    [onUploadStart, provideOnProgress, uploadIndividualFile]
  );

  return {
    cancelUpload,
    mediaType: currentMediaType,
    setMediaType: setCurrentMediaType,
    setUploadError,
    uploadError,
    uploadFiles,
    uploadsInProgress,
    uploadProgress,
  };
};

export default useUpload;
