/* Libraries */
import React from "react";
import ReactCollapse from "react-collapse";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";
/* -Libraries */

import styles from "./index.module.scss";

const Collapse = props => {
  const { noAnimation, show } = props;

  // prevent collapse and expand animations getting stuck if called too frequently
  const [showValue] = useDebounce(show, 100);

  return (
    <ReactCollapse
      isOpened={showValue}
      theme={{ collapse: !noAnimation ? styles.collapse : "" }}
    >
      {props.children}
    </ReactCollapse>
  );
};

Collapse.propTypes = {
  noAnimation: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  show: PropTypes.bool,
};

export default React.memo(Collapse);
