export const getGif = state => state.gif;

export const getSelected = state => getGif(state)?.selected;
export const getSearchTerm = state => getGif(state)?.searchTerm;
export const getSearchResults = state => getGif(state)?.results || [];
export const getSearchResultPages = state => getGif(state)?.pages;

export const getId = gif => gif?.id;
export const getOriginalMp4Version = gif => gif?.original_mp4;
export const getOriginalStaticVersion = gif => gif?.original_still;
export const getFixedWidthVersion = gif => gif?.fixed_width;

export const getVersionHeight = version => version?.height;
export const getVersionWidth = version => version?.width;
export const getVersionSize = version => version?.size;
export const getVersionUrl = version => version?.url;

export const getSearchResultIds = state =>
  getSearchResults(state).map(result => getId(result));
