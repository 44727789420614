/* Libraries */
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import { getPrompts, getPromptsQueue, PROMPTS_IDS } from "./selectors";
import { getPromptsCompleted } from "redux/anonymousData/selectors";
import * as accountSelectors from "redux/account/selectors";
/* -Selectors */

/* Actions */
import * as accountActions from "redux/account/actions";
/* -Actions */

import { PROMPTS } from "./selectors";

export const actions = {
  updateCurrentPrompt: createAction("PROMPTS_UPDATE_CURRENT"),
  updatePrompts: createAction("PROMPTS_UPDATE"),
  updatePromptsCompleted: createAction("PROMPTS_UPDATE_COMPLETED"), // listened by anonymousData/reducer
  updatePromptsQueue: createAction("PROMPTS_QUEUE_UPDATE"),
};

/**
 * Fetch the default prompts and filter out the ones that have already been completed
 */
export const fetchPromptsAnonymous = () => (dispatch, getState) => {
  dispatch(accountActions.fetchPrompts()).then(defaultPrompts => {
    const state = getState();
    let promptsCompleted = getPromptsCompleted(state);

    const prompts = defaultPrompts.filter(
      t => promptsCompleted.indexOf(t) === -1
    );

    dispatch(setPrompts(prompts || []));
  });
};

/**
 * Set the store's prompts to the given prompts, and update the current prompt to show
 * @param [prompts] - An array of prompts.
 */
export const setPrompts =
  (prompts = []) =>
  (dispatch, getState) => {
    // check if the prompts coming from the server is implemented
    if (process.env.NODE_ENV !== "production") {
      prompts.forEach(p => {
        const promptsImplemented = Object.values(PROMPTS_IDS);
        if (promptsImplemented.indexOf(p) === -1) {
          console.warn(
            `Prompt not implemented - ${p} - Add a key to PROMPTS_IDS{}`
          );
        }
      });
    }
    dispatch(
      actions.updatePrompts({
        prompts,
      })
    );
    dispatch(setCurrentPrompt());
  };

/**
 * Update the current prompt to show in the webpage
 * Called when:
 * - the prompts queue gets updated (a new element in the DOM wants to show a prompt - see components that use `usePrompts()`)
 * - the backend sends a list of prompts (profilestore on init -> setPrompts() -> setCurrentPrompts)
 * - the user completes a prompt (completePrompt -> profilestore.updatePrompts -> setPrompts() -> setCurrentPrompts)
 */
const setCurrentPrompt = () => (dispatch, getState) => {
  // the prompts list is a list of prompts the user will have to see at some point
  // the promptsQueue is an ordered list (by priority to show)
  const state = getState();
  let prompts = getPrompts(state);
  let promptsQueue = getPromptsQueue(state);

  // loop through the queue and stop as soon as promptqueue.id === prompts.id (highest priority)
  let newPrompt = null;
  for (let i = 0; i < promptsQueue.length; i++) {
    const promptQueue = promptsQueue[i];
    if (newPrompt) break;
    for (let k = 0; k < prompts.length; k++) {
      const prompt = prompts[k];

      if (promptQueue.id === prompt) {
        newPrompt = prompt;
        break;
      }
    }
  }

  // update the store and trigger the front update
  dispatch(
    actions.updateCurrentPrompt({
      currentPrompt: newPrompt || "",
    })
  );
};

export const completePrompt = id => (dispatch, getState) => {
  // leave that here because could be useful to collect an array of ids that need to be
  // checked when they depend from others (look for `.alsoComplete` in PROMPTS{})
  /*
    let prompts = getPrompts(getState());
    prompts = prompts.filter(p => p !== id);
    let prompt = PROMPTS[id];
    if (prompt.alsoComplete) {
      prompt.alsoComplete.forEach(extraIdToRemove => {
        let i = prompts.indexOf(extraIdToRemove);
        if (i > -1) {
          prompts.splice(i, 1);
        }
      });
    }
  */

  /**
   * If the user is anonymous, we're going to update the _completedPrompts in the store (the localstorage)
   * instead of the profile, and remove the completed prompt from the prompts list
   */
  const isLoggedIn = accountSelectors.isLoaded(
    accountSelectors.getProfile(getState())
  );
  if (isLoggedIn) {
    // authentificated user
    dispatch(accountActions.updatePrompts(id));
  } else {
    // anonymous user
    // add the new completed prompt to the list of already completed prompts
    // this list will serve two purpose:
    // 1. as an anonymous: when getting the default tooltips list, it will filter it with the ones already completed (see fetchPromptsAnonymous)
    // 2. when the user will log in (finally!!!), the promptsCompleted list will be sent to the API to keep track of what the user went through
    let promptsCompleted = [...getPromptsCompleted(getState()), id];
    dispatch(
      actions.updatePromptsCompleted({
        prompts: promptsCompleted,
      })
    );

    // remove the current id from the list of prompts to show
    let prompts = getPrompts(getState());
    prompts = prompts.filter(p => p !== id);
    dispatch(setPrompts(prompts || []));
  }
};

/**
 *
 * addToPromptQueue and removeFromPromptQueue
 *
 * Update the prompts' queue list, and order it by priority.
 * Called by usePrompts hook (useEffect -> add to queue, return () => remove from queue)
 * After it updates the queue, check if it needs to display a new current Prompt (`setCurrentPrompt`)
 */
export const addToPromptQueue = id => (dispatch, getState) => {
  let promptsQueue = [...getPromptsQueue(getState())];

  if (promptsQueue.find(prompt => prompt?.id === id)) return;

  const promptNew = PROMPTS[id];
  promptsQueue.push(promptNew);
  promptsQueue.sort((a, b) => a.priority - b.priority);

  dispatch(
    actions.updatePromptsQueue({
      queue: promptsQueue,
    })
  );
  dispatch(setCurrentPrompt());
};

export const removeFromPromptQueue = id => (dispatch, getState) => {
  let promptsQueue = getPromptsQueue(getState());

  let found = false;
  // filter what prompts will stay in the queue
  const newPromptsQueue = promptsQueue.filter(p => {
    let staysIn = p.id !== id;
    // found is the one that needs to be removed
    // if at the end nothing gets removed, no need to update the queue.
    found = found || p.id === id;
    return staysIn;
  });

  if (!found) return;

  dispatch(
    actions.updatePromptsQueue({
      queue: newPromptsQueue,
    })
  );
  dispatch(setCurrentPrompt());
};
