/* Libraries */
import React, { useLayoutEffect, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

import iconSheet from "fonts/icons.svg";

const getIconName = icon => "icon-" + icon.replace(/^icon-/i, "");
const getIconSize = size => (/^\d+$/.test(size) ? size + "px" : size);

const Icon = props => {
  const { className, icon, size = "24px", ...restProps } = props;
  const iconNode = useRef();

  // ensure the name starts with 'icon-'
  const iconName = getIconName(icon);
  // ensure icon has a size with units
  const iconSize = getIconSize(size);

  useLayoutEffect(() => {
    iconNode.current.style.setProperty("--size", iconSize);
  }, [iconSize]);

  return (
    <svg
      className={classnames(styles.icon, iconName, className)}
      ref={iconNode}
      fillRule="evenodd"
      {...restProps}
    >
      <use href={`${iconSheet}#${iconName}`} />
    </svg>
  );
};

export const IconWithShadow = React.memo(props => {
  const { className, ...restProps } = props;
  return (
    <>
      <span className={classnames(className, styles.shadowContainer)}>
        <Icon {...restProps} className={classnames(styles.shadow)} />
        <Icon {...restProps} className={classnames(styles.foreground)} />
      </span>
    </>
  );
});

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(Icon);
