import { useCallback, useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";

const useTimeoutState = (delay = 4000) => {
  const isMounted = useIsMounted();
  const [isActive, isActiveSet] = useState(false);
  const setToActive = useCallback(value => {
    isActiveSet(value !== undefined ? value : true);
  }, []);

  useEffect(() => {
    let timeoutId;
    if (isActive) {
      timeoutId = setTimeout(() => {
        if (isMounted()) {
          isActiveSet(false);
        }
      }, delay);
    }

    const removeTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    return removeTimeout;
  }, [delay, isActive, isMounted]);

  return [isActive, setToActive];
};

export default useTimeoutState;
