/* Libraries */
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Hooks */
import useButtonOrLink from "hooks/useButtonOrLink";
/* -Hooks */

import styles from "./index.module.scss";

const disableLink = e => {
  e.preventDefault();
};

export const InverseButton = React.memo(props => {
  const {
    buttonRef,
    children,
    className,
    colour = "black",
    colourText,
    danger,
    disabled,
    filled,
    loading,
    onClick,
    size,
    text,
    to,
    ...restProps
  } = props;

  const { Element, elementProps } = useButtonOrLink({ to });

  return (
    <Element
      ref={buttonRef}
      {...elementProps}
      {...restProps}
      className={classnames(
        "button",
        `is-${colour}`,
        styles.button,
        className,
        {
          [`has-text-${colourText}`]: !!colourText,
          "is-outlined": !filled,
          [styles.danger]: danger,
          [styles.small + " is-small"]: size === "small",
          [styles.big]: size === "big",
          "is-loading": loading,
        }
      )}
      disabled={disabled}
      onClick={disabled ? disableLink : onClick}
    >
      {text}
      {children}
    </Element>
  );
});

InverseButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colour: PropTypes.string,
  danger: PropTypes.bool,
  filled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string }),
  ]),
};
