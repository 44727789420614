/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import { invitationStatus, roleTokenTypes } from "redux/roles/selectors";
/* -Selectors */

/* Actions */
/* -Actions */

export const actions = {
  updatePendingUserRole: createAction("USERROLESPENDING_UPDATE"),
  pendingUserRolesFetched: createAction("USERROLESPENDING_FETCH_SUCCESS"),
};

export const fetchUserRolesPending = () => dispatch => {
  return axios.get(`/roles/pending`).then(response => {
    const { pending } = response.data;
    dispatch(actions.pendingUserRolesFetched(pending));
    return pending;
  });
};

// accept or reject pending invitations to a kindeo
export const acceptRole = joinToken =>
  setRoleStatus(joinToken, invitationStatus.accepted);

export const rejectRole = joinToken =>
  setRoleStatus(joinToken, invitationStatus.rejected);

const setRoleStatus = (joinToken, status) => () => {
  return axios
    .post(`/role/${roleTokenTypes.joinToken}/${joinToken}/${status}`)
    .then(result => {
      const { role } = result.data;

      return role;
    });
};
