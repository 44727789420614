/* Libraries */
import { createAction } from "@reduxjs/toolkit";
import { slideTypes } from "libs/kindeo-play/consts/slides";
/* -Libraries */

/* Selectors */
import {
  getCardTheme,
  getCardThemeColourByIndex,
} from "redux/cardTheme/selectors";
import * as currentRoleSelectors from "redux/currentRole/selectors";
import * as rolesSelectors from "redux/roles/selectors";
import { getType } from "redux/slide/selectors";
/* -Selectors */

/* Actions */
import { setAnonymousDidPlayAsRecipient } from "redux/anonymousData/actions";
import * as currentRoleActions from "redux/currentRole/actions";
import * as soundtrackActions from "redux/soundtrack/actions";
/* -Actions */

export const actions = {
  update: createAction("PLAYBACK_UPDATE"),
};

// accept settings, start playback
export const startPlayback = payload => async (dispatch, getState) => {
  const { recipientPage, slideIndices, slidesOverride, ...restProps } = payload;
  const currentState = getState();
  const currentRole = currentRoleSelectors.getCurrentRole(currentState);

  // make sure the tracks from the soundtrack are loaded before starting playback
  let tracks = await dispatch(soundtrackActions.fetchSoundtrack());

  // are we showing a credits slide
  let isCreditsSlide = false;
  if (slidesOverride) {
    isCreditsSlide = getType(slidesOverride[0]) === slideTypes.CREDITS;
  }

  // get the right cardTheme for these slides
  let cardTheme = { ...getCardTheme(currentState) };

  // TODO: what if we're showing slides 3 to 12 - we need to translate that
  // into a reordered array of colours
  if (Array.isArray(slideIndices)) {
    // theme.colours = theme.colours;
  }
  // slideIndices can be an individual slide index
  // in which case set a single theme colour
  // except if this is a credits slide which needs all colours
  else if (!isNaN(parseInt(slideIndices, 10)) && !isCreditsSlide) {
    cardTheme.colours = [getCardThemeColourByIndex(cardTheme, slideIndices)];
  }
  // theme.colours should be full set if no indices specified
  else {
  }

  const defaultEventHandler = {
    onEnd: () => {
      dispatch(stopPlayback());
    },
  };

  // allow default event handers be overridden
  dispatch(
    updatePlayback({
      ...defaultEventHandler,
      ...restProps,
      cardTheme,
      playSlideshow: true,
      slidesOverride,
      tracks,
    })
  );

  if (recipientPage) {
    // if we have a role (i.e. invited, not simply on an open Kindeo)
    if (
      rolesSelectors.getRoleIsRecipient(currentRole) &&
      rolesSelectors.getRoleIsActive(currentRole)
    ) {
      dispatch(currentRoleActions.markKindeoAsWatchedByRecipient());
    } else {
      dispatch(setAnonymousDidPlayAsRecipient(true));
    }
  }
};

// accept settings, by default clear overrides, stop playback
export const stopPlayback = payload => dispatch => {
  dispatch(
    updatePlayback({
      slidesOverride: null,
      slideIndices: null,
      ...payload,
      playSlideshow: false,
    })
  );
};

// update all playback settings
export const updatePlayback = payload => dispatch => {
  dispatch(actions.update(payload));
};

export const setPlaybackError = error => dispatch => {
  dispatch(actions.update({ error }));
};
