export const themes = [
  {
    name: "paperheart-0",
    text: { color: "0xc92f5b" },
    background: {
      color: "0xf4dee4",
      properties: {
        type: "Dots",
        alphaMin: 0,
        alphaGlobal: 0,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 0.41568627450980394, 0.592156862745098],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 255, g: 180, b: 200 },
          { r: 255, g: 180, b: 200 },
        ],
        colorsBackLayer: [
          { r: 247.23046875, g: 193.1488037109375, b: 207.31304931640628 },
          { r: 255, g: 183.28125, b: 201.87499999999997 },
        ],
        colorsMidLayer: [
          { r: 255, g: 180.09375, b: 199.80592105263156 },
          { r: 254, g: 212, b: 223 },
        ],
        colorsFrontLayer: [
          { r: 255, g: 149.8125, b: 177.36160714285722 },
          { r: 255, g: 223.125, b: 231.51315789473682 },
        ],
        shadowOffset: 0.09,
        shadowOpacity: 0.01999999999999997,
        deformationFrequency: 0.49999999999999994,
        deformationAmplitude: 0,
        deformationSpeed: 0,
        effect: {},
      },
      scene: {
        type: "PaperHeartScene",
        heart: {
          colors: ["0xc92f5b", "0xa81b42", "0xc62d59", "0xd23864"],
          animated: true,
        },
      },
    },
  },
  {
    name: "paperheart-1",
    text: { color: "0x333131" },
    background: {
      color: "0xfff5fe",
      properties: {
        type: "Dots",
        alphaMin: 0,
        alphaGlobal: 0,
        scaleValue: 1.532608695652174,
        alphaValue: 0.43478260869565216,
        radius: 0.05304347826086957,
        columnWidth: 30.543478260869563,
        color: [1, 0.41568627450980394, 0.592156862745098],
      },
    },
    scene: {
      miniSceneColor: 16777215,
      type: "PaperCutScene",
      layers: {
        colorsBaseLayer: [
          { r: 255, g: 255, b: 255 },
          { r: 255, g: 255, b: 255 },
        ],
        colorsBackLayer: [
          { r: 255, g: 255, b: 255 },
          { r: 255, g: 255, b: 255 },
        ],
        colorsMidLayer: [
          { r: 254.93870192307693, g: 255, b: 251.8125 },
          { r: 255, g: 255, b: 255 },
        ],
        colorsFrontLayer: [
          { r: 255, g: 255, b: 255 },
          { r: 255, g: 255, b: 255 },
        ],
        shadowOffset: 3.469446951953614e-18,
        shadowOpacity: 0,
        deformationFrequency: 0,
        deformationAmplitude: 2.9000000000000004,
        deformationSpeed: 0.7000000000000001,
        effect: {},
      },
      scene: {
        type: "PaperHeartScene",
        heart: {
          colors: ["0xff0048", "0xd8013c", "0xff0049", "0xf00044"],
          animated: true,
        },
      },
    },
  },
];
