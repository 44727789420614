/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
import Queue from "queue";
/* -Libraries */

/* Selectors */
import { getCardTheme } from "redux/cardTheme/selectors";
import * as editorSelectors from "redux/editor/selectors";
import { mediaTypes } from "redux/media/selectors";
import { generateReminderTitle } from "redux/reminders/selectors";
import * as rolesSelectors from "redux/roles/selectors";
import { roleTokenTypes } from "redux/roles/selectors";
import * as slideSelectors from "redux/slide/selectors";
import * as storySelectors from "./selectors";
import { storyTokenTypes } from "./selectors";
import * as updateStatusSelectors from "redux/updateStatus/selectors";
/* -Selectors */

/* -Actions */
import { fetchMyStoryContributions } from "redux/contributions/actions";
import {
  fetchGroupCurrentRole,
  fetchRecipientCurrentRole,
} from "redux/currentRole/actions";
import * as errorActions from "redux/errors/actions";
import { beginFileUpload } from "redux/media/actions";
import { setSlideThumbnail } from "redux/slide/actions";
import { fetchStoryRoles } from "redux/storyRoles/actions";
/* -Actions */

import analytics, { EVENTS } from "utils/analyticsUtils";
import { getErrorMessage } from "utils/apiUtils";
import { noop } from "utils/clientUtils";
import { localTimezoneHourOffset, toYMD } from "utils/dateUtils";
import { promiseSequence } from "utils/promiseSequence";
import { getTheme } from "components/Slideshow/data-covers";
import { slideTypes } from "libs/kindeo-play/consts/slides";
import {
  getPrintResolution,
  thumbnail,
} from "libs/kindeo-play/webgl/scenes/kindeo/consts";

export const actions = {
  clearStory: createAction("STORY_CLEAR"),
  clearSlideThumbnail: createAction("SLIDE_THUMBNAIL_CLEAR"),
  storyFetchSuccess: createAction("STORY_FETCH_SUCCESS"),
  updateLocalStory: createAction("STORY_MODIFY_SUCCESS"),
  createThumbnailSuccess: createAction("STORY_THUMBNAIL_SUCCESS"),
  updateSlide: createAction("STORY_SLIDE_UPDATE"),
  slideSeenSuccess: createAction("STORY_SLIDE_SEEN"),
  storyDeleteSuccess: createAction("STORY_DELETE_SUCCESS"),
  storyOrderSuccess: createAction("STORY_ORDER_SUCCESS"),
  setStoryChanged: createAction("STORY_CHANGED_SET"),
};

export const getCurrentStoryId = () => (dispatch, getState) => {
  return storySelectors.getId(storySelectors.getStory(getState()));
};

export const clearStory = () => dispatch => {
  dispatch(actions.clearStory());
};

const fetchStoryContent = (token_type, token, joinToken) => dispatch => {
  return axios
    .get(
      `/story/${token_type}/${token}${
        joinToken ? `?${roleTokenTypes.joinToken}=${joinToken}` : ""
      }`
    )
    .then(response => {
      dispatch(actions.storyFetchSuccess(response.data.story));
      return response.data.story;
    });
};
export const fetchRecipientStoryContent = token => dispatch => {
  return dispatch(fetchStoryContent(storyTokenTypes.recipientToken, token));
};
export const fetchGroupStoryContent = (token, joinToken) => dispatch => {
  return dispatch(
    fetchStoryContent(storyTokenTypes.groupToken, token, joinToken)
  );
};

export const fetchStory = id => (dispatch, getState) => {
  if (!id) {
    id = dispatch(getCurrentStoryId());
  }

  return axios.get(`/story/${id}`).then(response => {
    dispatch(actions.storyFetchSuccess(response.data.story));
    return response.data.story;
  });
};

export const fetchStorySettings = id => (dispatch, getState) => {
  if (!id) {
    id = dispatch(getCurrentStoryId());
  }

  return axios.get(`/story/${id}/settings`).then(response => {
    dispatch(actions.storyFetchSuccess(response.data.story));
    return response.data.story;
  });
};

const areDatesEquivalent = (date1, date2) => {
  return new Date(date1).getTime() === new Date(date2).getTime();
};

// check if local story data is current or out of date
const haveLatestData = () => (dispatch, getState) => {
  const currentState = getState();
  const story = storySelectors.getStory(currentState);
  const lastLocalStoryUpdate = storySelectors.getLastStoryUpdate(story);
  const lastLocalRoleUpdate = storySelectors.getLastRoleUpdate(story);

  let storyDataUnchanged,
    roleDataUnchanged = true;

  if (lastLocalStoryUpdate || lastLocalRoleUpdate) {
    if (lastLocalStoryUpdate) {
      const lastRemoteStoryUpdate =
        updateStatusSelectors.getLastStoryUpdate(currentState);
      storyDataUnchanged = areDatesEquivalent(
        lastRemoteStoryUpdate,
        lastLocalStoryUpdate
      );
    }
    if (lastLocalRoleUpdate) {
      const lastRemoteRoleUpdate =
        updateStatusSelectors.getLastRoleUpdate(currentState);
      roleDataUnchanged = areDatesEquivalent(
        lastRemoteRoleUpdate,
        lastLocalRoleUpdate
      );
    }

    return storyDataUnchanged && roleDataUnchanged;
  } else {
    return false;
  }
};

// ensure local story data is current or get new data
export const ensureLatestStoryData = id => (dispatch, getState) => {
  if (!dispatch(haveLatestData())) {
    // default to the current story ID
    if (!id) {
      id = dispatch(getCurrentStoryId());
    }
    return dispatch(fetchStory(id));
  }
};

// ensure local story data is current or get new data
export const ensureLatestRecipientStory = recipientToken => dispatch => {
  if (!dispatch(haveLatestData())) {
    return dispatch(fetchRecipientCurrentRole(recipientToken)).then(
      response => {
        return dispatch(fetchRecipientStoryContent(recipientToken));
      }
    );
  }

  return Promise.resolve();
};

// ensure local story data is current or get new data
export const ensureLatestGroupStory = groupToken => dispatch => {
  if (!dispatch(haveLatestData())) {
    return dispatch(fetchGroupCurrentRole(groupToken)).then(response => {
      const { role } = response;

      const isPermittedRole =
        rolesSelectors.getRoleIsContributor(role) ||
        rolesSelectors.getRoleIsOwner(role);
      // if the role is fully qualified get the full story data
      // otherwise refresh the summary to get latest update times
      if (isPermittedRole && rolesSelectors.getRoleIsActive(role)) {
        dispatch(fetchMyStoryContributions());
      }
      return dispatch(fetchGroupStoryContent(groupToken));
    });
  }

  return Promise.resolve();
};

// used by the API to decide if the Kindeo has been watched by the recipient
export const markKindeoReceived = (recipientToken, joinToken) => dispatch => {
  return axios
    .post(
      `/story/recipient_token/${recipientToken}/received${
        joinToken ? `?join_token=${joinToken}` : ""
      }`
    )
    .catch(noop);
};

export const updateLocalSlideData = payload => dispatch => {
  return dispatch(actions.updateSlide(payload));
};

//Update an existing slide
export const updateSlide =
  (payload, generateThumbnail = true) =>
  (dispatch, getState) => {
    // If the occasion has been modified
    // need to update API with new story data as well as new slide
    const state = getState();
    const editorOccasion = storySelectors.getStoryOccasionType(
      editorSelectors.getStory(editorSelectors.getEditor(state))
    );
    const storyOccasion = storySelectors.getStoryOccasionType(
      storySelectors.getStory(state)
    );
    const newStoryOccasion = editorOccasion && editorOccasion !== storyOccasion;

    if (newStoryOccasion) {
      dispatch(modifyStoryDetails({ occasion: editorOccasion }));
    }

    const slideId = slideSelectors.getId(payload);

    return axios.put(`slide/${slideId}`, { ...payload }).then(result => {
      const updatedSlide = result.data.slide;
      const slideType = slideSelectors.getType(updatedSlide);

      const theme = getTheme(updatedSlide);

      analytics.event("Slide Updated", {
        type: slideType,
        media_count: slideSelectors.getSlideMediaLength(updatedSlide),
        ...(slideType === slideTypes.TITLE
          ? {
              occasion: editorOccasion?.toLowerCase(),
              variant: slideSelectors.getVariant(updatedSlide).toLowerCase(),
              exported_cover:
                theme?.exported_sku ||
                slideSelectors.getExportedId(updatedSlide),
            }
          : {}),
      });
      dispatch(updateLocalSlideData(updatedSlide));
      dispatch(actions.setStoryChanged());
      // get the new thumbnail, but don't hold up the UI for it
      if (generateThumbnail) {
        // send parameter forces an update to the slide in API
        dispatch(createThumbnail(updatedSlide)).then(slide => {
          dispatch(actions.createThumbnailSuccess(slide));
        });
      }

      return updatedSlide;
    });
  };

export const updateTitleSlide =
  (payload, generateThumbnail) => (dispatch, getState) => {
    const { exportedId, id, slide_data, theme, variant, ...rest } = payload;
    return dispatch(
      updateSlide(
        {
          ...rest,
          id,
          exported_id: exportedId,
          slide_data,
          theme,
          variant,
        },
        generateThumbnail
      )
    );
  };

export const markSlideAsSeen = payload => dispatch => {
  const { slide_id } = payload;
  return axios
    .put(`/slide/${slide_id}/seen`)
    .then(() => {
      dispatch(actions.slideSeenSuccess(slide_id));
    })
    .catch(noop);
};

//Create a new slide
export const newSlide = payload => (dispatch, getState) => {
  const storyId = dispatch(getCurrentStoryId());

  const slide = {
    ...payload,
    media: payload.media.map((item, index) => {
      return {
        ...item,
        position: item.position !== undefined ? item.position : index,
      };
    }),
    position: null,
  };

  return axios
    .post(`/slide?story_id=${storyId}`, { ...slide })
    .then(response => {
      analytics.event("Slide Created", {
        type: slideSelectors.getType(payload),
        variant: slideSelectors.getVariant(payload),
        exported_cover: slideSelectors.getExportedId(payload),
        media_count: slideSelectors.getMedia(payload)?.length,
      });

      // get the new thumbnail, but don't hold up the UI for it
      return dispatch(fetchStory(storyId)).then(() => {
        if (storySelectors.isAtPaywall(getState())) {
          analytics.event(EVENTS.purchase.paywallReached);
        }

        dispatch(actions.setStoryChanged());
        dispatch(createThumbnail(response.data.slide)).then(slide => {
          dispatch(actions.createThumbnailSuccess(slide));
        });
      });
    })
    .catch(e => {
      analytics.error(EVENTS.errors.addMessage, {
        message: getErrorMessage(e),
      });
      return Promise.reject(e);
    });
};

//Remove a slide by ID
export const removeSlideById = slideId => (dispatch, getState) => {
  const story = storySelectors.getStory(getState());
  const deletedSlide = storySelectors.getStorySlideById(story, slideId);

  return axios.delete(`/slide/${slideId}`).then(() => {
    analytics.event("Slide Deleted", {
      type: slideSelectors.getType(deletedSlide),
    });

    return dispatch(fetchStory(storySelectors.getId(story))).then(() => {
      return dispatch(actions.setStoryChanged());
    });
  });
};

//Change order
export const orderSlides = payload => (dispatch, getState) => {
  const storyId = dispatch(getCurrentStoryId());
  const slides = payload.map((slideId, index) => {
    return {
      id: slideId,
      position: index + 1,
    };
  });

  return axios.put(`/story/${storyId}/slides`, { slides }).then(() => {
    dispatch(actions.setStoryChanged());
    return dispatch(actions.storyOrderSuccess({ slides }));
  });
};

export const modifyStoryDetails = payload => (dispatch, getState) => {
  const story = storySelectors.getStory(getState());
  payload = JSON.parse(JSON.stringify(payload));

  const atPaywallBefore = storySelectors.isAtPaywall(getState());

  dispatch(actions.setStoryChanged());

  return axios
    .put(`/story/${story.id}`, { ...payload })
    .then(result => {
      const { story } = result.data;
      return dispatch(actions.updateLocalStory(story));
    })
    .then(response => {
      const atPaywallAfter = storySelectors.isAtPaywall(getState());

      if (!atPaywallBefore && atPaywallAfter) {
        analytics.event(EVENTS.purchase.paywallReached);
      }

      return response;
    })
    .catch(err => {
      dispatch(errorActions.createError({ source: "modifyStoryDetails" }));
      return Promise.reject(err);
    });
};

export const updateFx = payload => (dispatch, getState) => {
  const story = storySelectors.getStory(getState());

  dispatch(actions.setStoryChanged());
  return axios
    .put(`/story/${story.id}/fx`, { ...payload })
    .then(response => {
      dispatch(actions.updateLocalStory(payload));

      return response.data;
    })
    .catch(err => {
      dispatch(errorActions.createError({ source: "updateFx" }));
      return Promise.reject(err);
    });
};

export const setInviteSetupComplete = () => (dispatch, getState) => {
  return dispatch(modifyStoryDetails({ setup_invite_complete: true }));
};
export const setSendSetupComplete = () => (dispatch, getState) => {
  return dispatch(modifyStoryDetails({ setup_send_complete: true }));
};

export const createMissingThumbnails =
  (payload, callback = actions.createThumbnailSuccess) =>
  (dispatch, getState) => {
    if (!payload) {
      payload = storySelectors.getSlides(storySelectors.getStory(getState()));
    }
    // get missing slides that aren't already generating
    const missingSlides = payload.filter(slide => {
      return (
        !slideSelectors.getThumbnailUrl(slide) &&
        !slideSelectors.isGeneratingThumbnail(slide)
      );
    });

    // export all the thumbnails from the app before actually sending them to the server
    return promiseSequence(missingSlides, slide => {
      // send parameter preventing us from updating slide in API
      return dispatch(createThumbnail(slide)).then(updatedSlide => {
        dispatch(callback(updatedSlide));
      });
    });
  };

export const createFirstThumbnail = payload => dispatch => {
  // We only care about the first thumbnail
  const firstSlide = payload.slides[0];

  if (
    firstSlide &&
    !slideSelectors.getThumbnailUrl(firstSlide) &&
    !slideSelectors.isGeneratingThumbnail(firstSlide)
  ) {
    return dispatch(createThumbnail(firstSlide)).then(updatedSlide => {
      return dispatch(actions.createThumbnailSuccess(updatedSlide));
    });
  }

  return Promise.resolve();
};

// (async load pixi app here instead of adding it as static dependency
// because this file is always included in main bundle via redux)
const getPixiReady = () => {
  return import("utils/export-pixiapp-setup")
    .then(({ default: setupPromise }) => setupPromise)
    .then(pixiapp => {
      return pixiapp.preload().then(() => {
        return Promise.resolve(pixiapp);
      });
    });
};

// queue for creating thumbnails so that no more than one is captured at a time
const thumbnailQueue = new Queue({ autostart: true, concurrency: 1 });

// second parameter allows us to update the slide data in the API
// after creating a thumbnail (when updating a slide, not when creating slide or story)
export const createThumbnail =
  (slide, updateSlideData, isOnRefresh) => (dispatch, getState) => {
    const cardTheme = getCardTheme(getState());

    dispatch(actions.clearSlideThumbnail(slideSelectors.getId(slide)));

    // if present, replace the editor's slide_data_object of all
    // recently edited covers with the current cover's slide_data array
    let slideData = editorSelectors.transformSlideDataForApi(slide);
    slideData.thumbOnRefresh = isOnRefresh;

    const theme = getTheme(slide);

    let img;

    // wrap this in a promise so that we know when the queued capture
    // has been completed
    const thumbnailPromise = new Promise((resolve, reject) => {
      return getPixiReady().then(pixiapp => {
        const performThumbnailCapture = () => {
          // get the thumbail from the app. if it doesn't exist, it will create one for this slide
          return pixiapp
            .exportThumbnail(
              { ...slideData, theme },
              {
                width: thumbnail.widthPrint,
                height: thumbnail.heightPrint,
                resolution:
                  slide.type === slideTypes.TITLE
                    ? thumbnail.resolutionHigh
                    : thumbnail.resolution,
                keepDimensions: true,
                needsBlob: true,
                cardTheme,
              }
            )
            .then(blob => {
              img = blob;
              resolve();
            })
            .catch(e => {
              reject(e);
            });
        };

        thumbnailQueue.push(performThumbnailCapture);
      });
    });

    return thumbnailPromise
      .then(async () => {
        const { s3Key, request } = await dispatch(
          beginFileUpload({
            file: img,
            mediaType: mediaTypes.PHOTO,
            mimeType: "image/png",
          })
        );
        return request.then(() => {
          return s3Key;
        });
      })
      .then(uploadedFileS3Key => {
        return dispatch(setSlideThumbnail(slideData.id, uploadedFileS3Key));
      })
      .then(thumbnailProps => {
        const newSlide = {
          ...slideData,
          ...thumbnailProps,
          // and make sure the generating flag from clearSlideThumbnail is unset
          generatingThumbnail: false,
        };
        dispatch(updateLocalSlideData(newSlide));

        return newSlide;
      });
  };

export const createThumbnailPrint = slideData => (dispatch, getState) => {
  const theme = getTheme(slideData);

  return getPixiReady()
    .then(pixiapp => {
      // get the thumbail from the app. if it doesn't exist, it will create one for this slide
      return pixiapp.exportThumbnail(
        { ...slideData, theme },
        {
          width: thumbnail.widthPrint,
          height: thumbnail.heightPrint,
          resolution: getPrintResolution(slideData),
          keepDimensions: false,
        }
      );
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const deleteStory = story_id => dispatch => {
  return axios.delete(`/story/${story_id}`);
};

// take ownership of an anonymous story (used when authenticating after creating a story)
export const adoptStory = story_id => dispatch => {
  return axios.post(`/story/${story_id}/adopt`);
};

// reset the anonymous member link for a private story
export const resetLink = isRecipientLink => (dispatch, getState) => {
  const storyId = dispatch(getCurrentStoryId());

  return axios
    .post(`/story/${storyId}/token`, {
      token_type: isRecipientLink
        ? storyTokenTypes.recipientToken
        : storyTokenTypes.groupToken,
    })
    .then(result => {
      dispatch(actions.updateLocalStory(result.data));
    });
};

export const markStoryGroupDataAsCurrent = () => (dispatch, getState) => {
  const latestGroupUpdate = updateStatusSelectors.getLastRoleUpdate(getState());

  dispatch(actions.updateLocalStory({ last_role_update: latestGroupUpdate }));
};

// Convert a legacy sharing token into a group token for redirection
export const convertSharingToken = (tokenType, token) => () => {
  return axios.get(`/story/${tokenType}/${token}/convert`).then(result => {
    return result.data.response;
  });
};

export const sendToRecipient =
  ({ name, email, recipientMessage, setReminder, occasionDate, daysBefore }) =>
  (dispatch, getState) => {
    const story = storySelectors.getStory(getState());
    const storyId = storySelectors.getId(story);
    const storyOccasion = storySelectors.getStoryOccasionType(story);

    const submissionData = {
      recipient_email: email,
      recipient_name: name,
      set_reminder: !!setReminder,
      recipient_message: recipientMessage,
      ...(setReminder
        ? {
            reminder_days_before: daysBefore,
            reminder_occasion_date: toYMD(occasionDate),
            reminder_offset: localTimezoneHourOffset(),
            reminder_title: generateReminderTitle(name, storyOccasion),
          }
        : {}),
    };

    return axios.post(`/story/${storyId}/send`, submissionData).then(() => {
      dispatch(fetchStoryRoles());
    });
  };

export const nudgeAllRoles = story_id => (dispatch, getState) => {
  return axios.put(`/story/${story_id}/group/nudge_all`).then(response => {
    dispatch(fetchStoryRoles());

    return response.data?.nudged;
  });
};

export const approveAllRoles = story_id => (dispatch, getState) => {
  return axios.put(`/story/${story_id}/group/approve_all`).then(response => {
    dispatch(fetchStoryRoles());
    return response.data?.approved;
  });
};

// Thank you messages
export const setRecipientThankyouMessage =
  (recipientToken, message) => dispatch => {
    const payload = { thanks_message: message };
    return axios
      .post(`/story/recipient_token/${recipientToken}/thanks`, payload)
      .then(response => {
        dispatch(actions.updateLocalStory(payload));
        return true;
      })
      .catch(e => {
        analytics.error(EVENTS.errors.addThanks, {
          message: getErrorMessage(e),
        });
        return Promise.reject(e);
      });
  };

export const deleteRecipientThankyouMessage = recipientToken => dispatch => {
  return axios
    .delete(`/story/recipient_token/${recipientToken}/thanks`)
    .then(response => {
      dispatch(actions.updateLocalStory({ thanks_message: "" }));
      return true;
    });
};

export const fetchStoryReminders = () => dispatch => {
  const storyId = dispatch(getCurrentStoryId());

  if (storyId) {
    return axios
      .get(`/story/${storyId}/reminders`)
      .then(response => {
        return response.data.reminders;
      })
      .catch(e => {
        return [];
      });
  }
};

export const regenerateLandscapeCover = () => (dispatch, getState) => {
  const story = storySelectors.getStory(getState());
  const titleSlide = storySelectors.getTitleSlide(story);

  return dispatch(createThumbnail(titleSlide, undefined, true))
    .then(() => {
      return dispatch(modifyStoryDetails({ has_landscape_cover: false }));
    })
    .then(() => {
      return dispatch(fetchStory());
    })
    .then(story => {
      return storySelectors.getEditingStoryCover(story);
    });
};

export const generatePrintCover = data => (dispatch, getState) => {
  return dispatch(createThumbnailPrint(data));
};
