/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import * as contributionsActions from "redux/contributions/actions";
import * as currentRoleActions from "redux/currentRole/actions";
import * as storyActions from "redux/story/actions";
import * as updateStatusActions from "redux/updateStatus/actions";
/* -Actions */

/* Selectors */
import { roleStatus, roleTokenTypes } from "redux/roles/selectors";
import * as storySelectors from "redux/story/selectors";
import * as updateStatusSelectors from "redux/updateStatus/selectors";
/* -Selectors */

import { getErrorData, getErrorStatus } from "utils/apiUtils";

export const actions = {
  storyRolesFetchSuccess: createAction("STORYROLES_FETCH_SUCCESS"),
  storyRoleFetchSuccess: createAction("STORYROLES_FETCH_ONE_SUCCESS"),
  storyRoleAddSuccess: createAction("STORYROLES_ADD_SUCCESS"),
  storyRoleRemoveSuccess: createAction("STORYROLES_REMOVE_SUCCESS"),
};

const fetchSharedStoryRoles = (roleType, token) => (dispatch, getState) => {
  return axios.get(`/story/${roleType}/${token}/group`).then(response => {
    const { roles } = response.data;

    dispatch(actions.storyRolesFetchSuccess(roles));
    return roles;
  });
};
export const fetchStoryRolesForGroup = groupToken => (dispatch, getState) => {
  return dispatch(
    fetchSharedStoryRoles(storySelectors.storyTokenTypes.groupToken, groupToken)
  );
};
export const fetchStoryRolesForRecipient =
  recipientToken => (dispatch, getState) => {
    return dispatch(
      fetchSharedStoryRoles(
        storySelectors.storyTokenTypes.recipientToken,
        recipientToken
      )
    );
  };

export const fetchStoryRoles = () => (dispatch, getState) => {
  const story = storySelectors.getStory(getState());
  const storyId = storySelectors.getId(story);

  return axios.get(`/story/${storyId}/group`).then(response => {
    const { roles } = response.data;

    dispatch(actions.storyRolesFetchSuccess(roles));
    return roles;
  });
};

export const fetchStoryRole = roleId => dispatch => {
  return axios.get(`/role/${roleId}`).then(response => {
    const { role } = response.data;
    dispatch(actions.storyRoleFetchSuccess(role));

    return role;
  });
};

export const addStoryRole = email => (dispatch, getState) => {
  const story = storySelectors.getStory(getState());
  const storyId = storySelectors.getId(story);

  return axios
    .post(`/role?story_id=${storyId}`, { join_email: email })
    .then(response => {
      const { role } = response.data;
      dispatch(actions.storyRoleAddSuccess(role));
      return role;
    });
};

export const removeStoryRole = roleId => (dispatch, getState) => {
  return axios.delete(`/role/${roleId}`).then(response => {
    const story = storySelectors.getStory(getState());
    const storyId = storySelectors.getId(story);

    dispatch(actions.storyRoleRemoveSuccess(roleId));
    dispatch(updateStatusActions.getUpdateStatus(storyId));
    dispatch(storyActions.fetchStory(storyId));
    dispatch(contributionsActions.fetchContributions(storyId));

    return;
  });
};

export const nudgeStoryRole = roleId => (dispatch, getState) => {
  return axios.post(`/role/${roleId}/nudge`).then(response => {
    // update the new role status
    const { role } = response.data;
    dispatch(actions.storyRoleFetchSuccess(role));
    return role;
  });
};

/*
  These endpoints are only for the story owner and set the status of the role
 */
export const approveStoryRole = roleId => (dispatch, getState) => {
  return setRoleStatus(roleId, roleStatus.active).then(() => {
    const storyId = storySelectors.getId(storySelectors.getStory(getState()));

    // approving a role which has already added contributions will clear the credits slide
    // which will now have to be recreated
    dispatch(storyActions.fetchStory(storyId)).then(() => {
      dispatch(storyActions.createMissingThumbnails());
    });
    dispatch(contributionsActions.fetchContributions(storyId));
    dispatch(fetchStoryRole(roleId));
    return dispatch(updateStatusActions.getUpdateStatus(storyId));
  });
};
export const rejectStoryRole = roleId => () => {
  return setRoleStatus(roleId, roleStatus.rejected);
};
const setRoleStatus = (roleId, status) => {
  return axios.put(`/role/${roleId}`, {
    status,
  });
};

// approve a storyRole request but without story owner auth
// and load the role data returned into currentRole to display data
export const approveStoryRoleRequest = (roleId, approveToken) => dispatch => {
  return axios
    .post(
      `/role/${roleId}/approve?${roleTokenTypes.approveToken}=${approveToken}`
    )
    .then(result => {
      const { role } = result.data;
      dispatch(currentRoleActions.actions.currentRoleFetched(role));

      return role;
    });
};

// Send verified invitations link by email
export const sendVerifiedInvitations =
  ({ emails, roleType }) =>
  (dispatch, getState) => {
    const story = storySelectors.getStory(getState());
    return axios
      .post(`/role?story_id=${storySelectors.getId(story)}`, {
        join_emails: emails,
        role_type: roleType,
      })
      .then(result => {
        dispatch(fetchStoryRoles());
        return result.data;
      })
      .catch(error => {
        // if this was a validation error
        if (getErrorStatus(error) === 422) {
          // if the validation error was about the join_emails field
          // we have hit the quantity limit
          if (
            JSON.stringify(getErrorData(error)).indexOf("join_emails") !== -1
          ) {
            return Promise.reject(
              "Kindeo can only send up to 50 invitations at one time."
            );
          }
        }

        throw error;
      });
  };

// check if local story data is current or out of date
const haveLatestData = () => (dispatch, getState) => {
  const currentState = getState();

  const story = storySelectors.getStory(currentState);
  const lastLocalUpdate = storySelectors.getLastRoleUpdate(story);
  const lastRemoteUpdate =
    updateStatusSelectors.getLastRoleUpdate(currentState);

  if (lastRemoteUpdate && lastLocalUpdate) {
    return (
      new Date(lastLocalUpdate).getTime() ===
      new Date(lastRemoteUpdate).getTime()
    );
  } else {
    return false;
  }
};

// ensure local cpontribution data is current or get new data
export const ensureLatestRoleData = id => (dispatch, getState) => {
  if (!dispatch(haveLatestData())) {
    return dispatch(fetchStoryRoles()).then(() => {
      dispatch(storyActions.markStoryGroupDataAsCurrent());
    });
  }
};
