/* Libraries */
import { useEffect, useState } from "react";
/* -Libraries */

/* Actions */
/* -Actions */

/* Hooks */
/* --Hooks */

const useTabFocus = () => {
  const [tabIsVisible, setTabIsVisible] = useState(!document.hidden);

  function updateTabVisibility() {
    setTabIsVisible(!document.hidden);
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", updateTabVisibility, {
      capture: true,
      passive: true,
    });
    return () => {
      document.removeEventListener("visibilitychange", updateTabVisibility, {
        capture: true,
        passive: true,
      });
    };
  }, []);

  return tabIsVisible;
};

export default useTabFocus;
