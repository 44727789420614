export const themes = [
  {
    name: "starburst-0",
    fadeoutCenter: 1,
    fadeoutOutter: 1,
    colorsStarburst: [0.921, 1, 0.984, 0.074, 0.952, 0.839, 0.262, 0.878, 0.8],
    colorsText: [
      "#d8107c",
      "#cc3486",
      "#f540a2",
      "#d8107c",
      "#cc3486",
      "#f540a2",
    ],
  },
  {
    name: "starburst-1",
    fadeoutCenter: 1,
    fadeoutOutter: 1,
    colorsStarburst: [0.98, 0.835, 0.38, 0.976, 0.749, 0.105, 1, 0.8, 0.227],
    colorsText: [
      "#2767c4",
      "#6392fc",
      "#3078f9",
      "#2767c4",
      "#6392fc",
      "#3078f9",
    ],
  },
  {
    name: "starburst-2",
    fadeoutCenter: 1,
    fadeoutOutter: 1,
    colorsStarburst: [1, 0.784, 0.901, 1, 0.615, 0.831, 0.878, 0.541, 0.737],
    colorsText: [
      "#2767c4",
      "#6392fc",
      "#3078f9",
      "#2767c4",
      "#6392fc",
      "#3078f9",
    ],
  },
  {
    name: "starburst-3",
    fadeoutCenter: 1,
    fadeoutOutter: 1,
    colorsStarburst: [1, 0.917, 0.839, 1, 0.8, 0.603, 1, 0.847, 0.698],
    colorsText: [
      "#d8107c",
      "#cc3486",
      "#f540a2",
      "#d8107c",
      "#cc3486",
      "#f540a2",
    ],
  },
  {
    name: "starburst-4",
    fadeoutCenter: 1,
    fadeoutOutter: 1,
    colorsStarburst: [
      0.976, 0.772, 0.909, 0.937, 0.086, 0.411, 0.937, 0.086, 0.411,
    ],
    colorsText: [
      "#f5ffb7",
      "#ffffff",
      "#ffed5f",
      "#f5ffb7",
      "#ffffff",
      "#ffed5f",
    ],
  },
  {
    name: "starburst-5",
    fadeoutCenter: 0.89,
    fadeoutOutter: 1,
    colorsStarburst: [
      0.07, 0.203, 0.345, 0.137, 0.219, 0.478, 0.227, 0.223, 0.592,
    ],
    colorsText: [
      "#cfdcff",
      "#dccfff",
      "#f5cfff",
      "#ffcff5",
      "#ffcfdc",
      "#ffcfdc",
    ],
  },
  // {
  //   name: "starburst-6",
  //   fadeoutCenter: 0.94,
  //   fadeoutOutter: 1,
  //   colorsStarburst: [1, 1, 1, 1, 1, 1, 0.003, 0, 1],
  //   colorsText: [
  //     "#f8060a",
  //     "#db0a0e",
  //     "#b80f77",
  //     "#c5017a",
  //     "#ff57be",
  //     "#f535ac",
  //   ],
  // },
  // {
  //   name: "starburst-7",
  //   fadeoutCenter: 0.89,
  //   fadeoutOutter: 1,
  //   colorsStarburst: [0.101, 0.235, 0.756, 0.019, 0.156, 0.58, 0, 0, 0.2],
  //   colorsText: [
  //     "#06f81f",
  //     "#06f81f",
  //     "#06f81f",
  //     "#06f81f",
  //     "#06f81f",
  //     "#06f81f",
  //   ],
  // },
];
