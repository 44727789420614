/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

export const actions = {
  fetchSpotifyTrackSuccess: createAction("FETCH_SPOTIFY_TRACK_SUCCESS"),
  searchSpotifySuccess: createAction("SEARCH_SPOTIFY_SUCCESS"),
};

export const fetchSpotifyTrack = spotifyTrackId => dispatch => {
  return axios
    .get(`/search/spotify?spotify_track_id=${spotifyTrackId}`)
    .then(response => {
      dispatch(actions.fetchSpotifyTrackSuccess(response.data.track));
      return response.data.track;
    });
};

export const searchMusic = searchTerm => dispatch => {
  return axios
    .get(`/search/spotify?term=${searchTerm}&limit=10`)
    .then(response => {
      dispatch(actions.searchSpotifySuccess(response.data.results));
      return response.data.results;
    });
};
