/* Libraries */
import React, { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
import * as accountSelectors from "redux/account/selectors";
import { authScenarios } from "redux/auth/selectors";
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

/* Components */
import Banner from "../Banner";
/* -Components */

/* Hooks */
import useModals from "hooks/useModals";
/* -Hooks */

import { modals } from "routes/ModalRoutes";
import styles from "./index.module.scss";

const selector = createSelector(
  [accountSelectors.getProfile, storySelectors.getStory],
  (profile, story) => {
    return {
      isLoggedIn: accountSelectors.isLoaded(profile),
      storyHasChanged: storySelectors.getHasChanged(story),
    };
  }
);

const SaveProgressBanner = props => {
  const { isLoggedIn } = useSelector(selector);
  const [showBanner, showBannerSet] = useState(false);
  const { linkModal } = useModals();

  useEffect(() => {
    if (!isLoggedIn) {
      const timeout = window.setTimeout(() => {
        showBannerSet(true);
      }, 750);
      return () => {
        window.clearTimeout(timeout);
      };
    } else {
      showBannerSet(false);
    }
  }, [isLoggedIn]);

  return (
    <Banner className={styles.saveProgressBanner}>
      {showBanner && (
        <>
          <h1 className="mr-3 has-text-white is-size-2 is-semibold">
            Sign in to save this Kindeo
          </h1>
          <Link
            to={linkModal(modals.auth(), {
              authScenario: authScenarios.CREATOR_SAVE,
            })}
            className={classnames("button is-white has-text-mint5")}
            title="Sign in to save this Kindeo"
          >
            Sign In
          </Link>
        </>
      )}
    </Banner>
  );
};

export default React.memo(SaveProgressBanner);
