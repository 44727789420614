/* Libraries */
import React, { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
import * as playbackSelectors from "redux/playback/selectors";
/* -Selectors */

/* Components */
import Banner from "../Banner";
import Icon from "components/Icon";
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

const selector = createSelector(
  [playbackSelectors.getPlaybackError],
  playbackError => {
    return playbackError;
  }
);

const PlaybackErrorBanner = props => {
  const { errorType } = props;

  const playbackError = useSelector(selector);
  const [showBanner, showBannerSet] = useState(false);

  useEffect(() => {
    showBannerSet(playbackError === errorType);
  }, [errorType, playbackError]);

  return (
    <Banner className={classnames(styles.playbackErrorBanner)}>
      {showBanner && <BannerContent />}
    </Banner>
  );
};

const BannerContent = React.memo(props => {
  return (
    <>
      <h1 className={classnames("h6", styles.message)}>
        <Icon
          icon="warning"
          size="24"
          className={classnames("mr-075", styles.icon)}
        />{" "}
        There was a problem playing this animation.
      </h1>
    </>
  );
});

export default React.memo(PlaybackErrorBanner);
