/* Libraries */
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
/* -Libraries */

export const MESSAGE_IDS = {
  AUTH: "auth",
  INFO: "info",
  REDIRECT: "redirect",
};

const canUseBroadcast = !!window.BroadcastChannel;
const channelName = "kindeoBroadcast";
const generateMessageId = () => Math.random().toString(36).substring(2, 9);

export const BroadcastContext = createContext();

export const BroadcastContextProvider = props => {
  const channel = useRef();
  const [message, setMessage] = useState();
  const [messageId, setMessageId] = useState();
  const [messageType, setMessageType] = useState();

  const onMessage = useCallback(event => {
    const messageData = event.data;
    setMessageId(generateMessageId());
    setMessage(messageData?.message);
    setMessageType(messageData?.type);
  }, []);
  const onMessageError = useCallback(event => {
    setMessageId(generateMessageId());
    setMessage(null);
    setMessageType(null);
    console.error("Broadcast event not recognised", event);
  }, []);

  const sendMessage = useCallback((type, message) => {
    if (canUseBroadcast) {
      channel.current.postMessage({ message, type });
    }
  }, []);

  useEffect(() => {
    if (canUseBroadcast) {
      channel.current = new BroadcastChannel(channelName);
      const channelInstance = channel.current;

      channelInstance.addEventListener("message", onMessage);
      channelInstance.addEventListener("messageerror", onMessageError);

      return () => {
        channelInstance.close();
      };
    }
  }, [onMessage, onMessageError]);

  // Assign React state and constants to context object
  const BroadcastContextObject = useMemo(() => {
    return {
      message,
      messageId,
      messageType,
      sendMessage,
    };
  }, [message, messageId, messageType, sendMessage]);

  return (
    <BroadcastContext.Provider value={BroadcastContextObject}>
      {props.children}
    </BroadcastContext.Provider>
  );
};

BroadcastContextProvider.propTypes = {
  children: PropTypes.element,
};
