import React, { Fragment } from "react";

export const splitHtmlLines = copy => {
  if (!copy) {
    return "";
  }

  const result = (
    <>
      {copy.split("\n").map((line, index) => {
        return index ? (
          <Fragment key={index}>
            <br />
            {line}
          </Fragment>
        ) : (
          <Fragment key={index}>{line}</Fragment>
        );
      })}
    </>
  );

  return result;
};

export const capitaliseFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
