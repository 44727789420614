import { slideTypes, slideVariants } from "libs/kindeo-play/consts/slides";
import { inputTypes } from "redux/slide/selectors";
import { OCCASION_TYPES } from "redux/occasions/selectors";

import thumbnail from "images/slides/thumbnails/text.png";

const type = slideTypes.TEXT;
const textVariants = slideVariants[type];

export default {
  type,
  icon: "text-fill",
  label: "Text",
  descriptionBuilder: "Say it plain and simple with text",
  colour: "yellow5",
  thumbnail,
  variants: [
    {
      name: textVariants.XMAS,
      label: "Christmas",
      // disabled until christmas season
      // cannot be chosen, but can be edited
      // disabled: true,
      occasion: OCCASION_TYPES.CHRISTMAS,
    },
    {
      name: textVariants.SIMPLE,
      label: "Simple",
    },
    {
      name: textVariants.TYPEWRITER,
      label: "Typewriter",
    },
    {
      name: textVariants.LETTER,
      label: "Letter",
    },
    {
      name: textVariants.GREETINGS,
      label: "Greetings",
      disabled: true,
    },
  ],
  fields: [
    {
      key: "description",
      type: inputTypes.TEXTAREA,
      label: "Message",
      placeholder: "Write your message here",
      validation: {
        required: "Please add a message",
        maxLength: {
          value: 300,
          message: `Please keep your message under 300 characters`,
        },
      },
    },
    {
      key: "subtitle",
      type: inputTypes.INPUT,
      label: "From",
      placeholder: "e.g. Your best friend",
      validation: {
        maxLength: {
          value: 30,
          message: `Please keep this to 30 characters or less`,
        },
      },
    },
  ],
};
