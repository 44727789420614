/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {
  accepted: false
};

const acceptCookies = (state, { payload }) => {
  return { ...state, accepted: payload };
};

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.acceptCookies]: acceptCookies,
  [authActions.logoutInit]: reset
});
