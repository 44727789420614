/* Libraries */
import { actions } from "./actions";
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

/* Selectors */
import * as reminderSelectors from "./selectors";
/* -Selectors */

const INITIAL_STATE = [];

const setReminders = (state, { payload }) => {
  if (Array.isArray(payload)) {
    return payload;
  }

  return state;
};

const setReminderDetails = (state, { payload }) => {
  const reminderId = reminderSelectors.getReminderId(payload);
  if (reminderId) {
    if (reminderSelectors.getReminderById(state, reminderId)) {
      return state.map(reminder =>
        reminderSelectors.getReminderId(reminder) === reminderId
          ? { ...reminder, ...payload }
          : reminder
      );
    }

    return [...state, payload];
  }

  return state;
};

const removeReminder = (state, { payload }) => {
  return [
    ...state.filter(
      reminder => reminderSelectors.getReminderId(reminder) !== payload
    ),
  ];
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.setReminders]: setReminders,
  [actions.updateReminder]: setReminderDetails,
  [actions.removeReminder]: removeReminder,
  [authActions.logoutInit]: reset,
});
