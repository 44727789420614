/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
import pick from "lodash/pick";
/* -Libraries */

/* Selectors */
import * as selectors from "./selectors";
import * as addressSelectors from "redux/addresses/selectors";
import * as storySelectors from "redux/story/selectors";
/* -Selectors */

/* Actions */
import { actions as paymentActions } from "redux/payment/actions";
import { getCurrentStoryId } from "redux/story/actions";
/* -Actions */
import analytics, { EVENTS } from "utils/analyticsUtils";
import { DELIVERY_TYPES, PAYMENT_TYPES } from "redux/payment/selectors";

export const actions = {
  setDetails: createAction("POSTALORDER_DETAILS_SET"),
  setHistory: createAction("POSTALORDER_HISTORY_SET"),
  setStoryId: createAction("POSTALORDER_STORYID_SET"),
  setAddress: createAction("POSTALORDER_ADDRESSID_SET"),
  setAddressId: createAction("POSTALORDER_ADDRESS_SET"),
  setDeliverySchedule: createAction("POSTALORDER_DELIVERYSCHEDULE_SET"),
  setDeliveryDate: createAction("POSTALORDER_DELIVERYDATE_SET"),
  setDestination: createAction("POSTALORDER_DESTINATION_SET"),
  setPostalClass: createAction("POSTALORDER_POSTALCLASS_SET"),
  setPostalOptions: createAction("POSTALORDER_OPTIONS_SET"),
  resetDetails: createAction("POSTALORDER_DETAILS_RESET"),
  reset: createAction("POSTALORDER_RESET"),
};

// sanitise order details properties
const shapeOrderDetails = orderDetails => {
  return pick(orderDetails, [
    "id",
    "address_id",
    "delivery_by",
    "destination",
    "postal_class",
    "scheduled_delivery",
    "status",
    "story_id",
  ]);
};

export const fetchPostalOrderClasses = () => async (dispatch, getState) => {
  const storyId = storySelectors.getId(storySelectors.getStory(getState()));

  return axios
    .get(`/locale/postal` + (storyId ? `?story_id=${storyId}` : ""))
    .then(response => {
      const postalOptions = response.data;
      dispatch(actions.setPostalOptions(postalOptions));
    });
};

export const fetchOrderDetails = orderId => async dispatch => {
  return axios.get(`/postal_orders/${orderId}`).then(response => {
    const { postal_order } = response.data;
    dispatch(actions.setDetails(postal_order));
    return Promise.resolve(postal_order);
  });
};

export const fetchPostalOrderHistory = kindeoId => async dispatch => {
  return axios.get(`/postal_orders?story_id=${kindeoId}`).then(response => {
    const { postal_orders } = response.data;
    dispatch(actions.setHistory(postal_orders));
    return Promise.resolve(postal_orders);
  });
};

export const initiatePostalOrderCheckoutSession =
  (storyId, orderId) => dispatch => {
    return axios
      .post(`/payments`, {
        payment_type: "postal_order",
        postal_order_id: orderId,
        story_id: storyId,
      })
      .then(response => {
        const { client_secret } = response.data;
        dispatch(paymentActions.setClientSecret(client_secret));
        return client_secret;
      });
  };

export const redeemBundleCredit = (storyId, postalOrderId) => dispatch => {
  return axios.post(`/payments`, {
    payment_type: PAYMENT_TYPES.POSTAL,
    postal_order_id: postalOrderId,
    story_id: storyId,
    use_credit_type: DELIVERY_TYPES.POSTAL,
  });
};

export const createCurrentOrder = () => async (dispatch, getState) => {
  const state = getState();
  const storyId = dispatch(getCurrentStoryId());
  const orderDetails = selectors.getCurrentPostalOrder(state);
  // const sendOrderNow = selectors.getSendOrderNow(orderDetails);
  const sendOrderNow = true;
  const postalClass = selectors.getPostalClassById(
    selectors.getPostalClasses(state),
    selectors.getOrderPostalClass(orderDetails)
  );
  // calculate a delivery date if sent now
  const asapDeliveryDate =
    selectors.getPostalClassLikelyDeliveryDate(postalClass);

  return axios
    .post("/postal_orders", {
      ...shapeOrderDetails(orderDetails),
      scheduled_delivery: !sendOrderNow,
      ...{
        delivery_by: sendOrderNow
          ? Array.isArray(asapDeliveryDate)
            ? asapDeliveryDate[1]
            : asapDeliveryDate
          : selectors.getOrderDeliveryDate(orderDetails),
      },
      story_id: storyId,
    })
    .then(response => {
      const { postal_order } = response.data;

      analytics.event(EVENTS.sendByPost.orderCreated, {
        to: selectors.getOrderDestination(postal_order),
        postal_method: selectors.getOrderPostalClass(postal_order),
      });

      dispatch(actions.setDetails(postal_order));
      return Promise.resolve(postal_order);
    });
};

export const updateCurrentOrder = () => async (dispatch, getState) => {
  const state = getState();
  const orderDetails = selectors.getCurrentPostalOrder(state);
  const orderId = selectors.getOrderId(orderDetails);
  // const sendOrderNow = selectors.getSendOrderNow(orderDetails);
  const sendOrderNow = true;
  const postalClass = selectors.getPostalClassById(
    selectors.getPostalClasses(state),
    selectors.getOrderPostalClass(orderDetails)
  );
  // calculate a delivery date if sent now
  const asapDeliveryDate =
    selectors.getPostalClassLikelyDeliveryDate(postalClass);

  return axios
    .put(`/postal_orders/${orderId}`, {
      ...shapeOrderDetails(orderDetails),
      scheduled_delivery: !sendOrderNow,
      ...{
        delivery_by: sendOrderNow
          ? Array.isArray(asapDeliveryDate)
            ? asapDeliveryDate[1]
            : asapDeliveryDate
          : selectors.getOrderDeliveryDate(orderDetails),
      },
    })
    .then(response => {
      const { postal_order } = response.data;
      dispatch(actions.setDetails(postal_order));
      return Promise.resolve(postal_order);
    });
};

export const setOrderAddress = address => async dispatch => {
  dispatch(actions.setAddress(address));
  dispatch(actions.setAddressId(addressSelectors.getId(address)));
};

export const setOrderAddressById = addressId => async (dispatch, getState) => {
  const addresses = addressSelectors.getAddresses(getState());
  const address = addressSelectors.getAddressById(addresses, addressId);
  dispatch(setOrderAddress(address));
};

export const clearPostalOrderDetails = clearOnlyCompleteOrder => dispatch => {
  dispatch(actions.resetDetails(clearOnlyCompleteOrder));
};
