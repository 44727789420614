/* Selectors */
import { getEditingFieldName } from "redux/editor/selectors";
/* Selectors */

export const getBoundingBoxes = state => state.boundingBox.boxes;

export const getX = box => box?.x;
export const getX2 = box => getX(box) + getWidth(box);
export const getY = box => box?.y;
export const getY2 = box => getY(box) + getHeight(box);
export const getMidY = box => getY(box) + getHeight(box) / 2;
export const getMidX = box => getX(box) + getWidth(box) / 2;
export const getHeight = box => box?.height;
export const getWidth = box => box?.width;
export const getKey = box => box?.key;
export const getType = box => box?.type;

export const getCurrentEditingBox = state =>
  getBoundingBoxes(state).find(
    box => getKey(box) === getEditingFieldName(state)
  );

// TODO: I know this is a stupid fix
// at the moment Trail2 can have widths beyond 100%
// Egg can have text starting at -0.7%
// when Clem is back, we will get that sorted
const validValue = value => !isNaN(parseInt(value));
// && parseInt(value) >= -5 && parseInt(value) <= 105;
export const isValidBox = box =>
  validValue(getX(box)) &&
  validValue(getY(box)) &&
  validValue(getWidth(box)) &&
  validValue(getHeight(box)) &&
  getKey(box) !== undefined;

const clamp0to100 = num => Math.min(Math.max(num, 0), 100);
export const getClampedBox = box => {
  return {
    ...box,
    x: clamp0to100(getX(box)), // values between 0 and 100
    y: clamp0to100(getY(box)),
    width: Math.min(getWidth(box), 100 - getX(box)), // make sure width and height don't exceed 100
    height: Math.min(getHeight(box), 100 - getY(box)),
  };
};

export const getOuterMetrics = state => {
  return getBoundingBoxes(state).reduce(
    (currentBounds, box) => {
      if (isFullScreen(box)) {
        return currentBounds;
      }

      const boxX = getX(box);
      // if the new x is smaller, set the x value and increase width by the difference
      if (boxX < getX(currentBounds)) {
        currentBounds.width =
          getWidth(currentBounds) + (getX(currentBounds) - boxX);
        currentBounds.x = boxX;
      }

      const boxY = getY(box);
      // if the new y is smaller, set the y value and increase height by the difference
      if (boxY < getY(currentBounds)) {
        currentBounds.height =
          getHeight(currentBounds) + (getY(currentBounds) - boxY);
        currentBounds.y = boxY;
      }

      return currentBounds;
    },
    { x: 50, y: 50, height: 0, width: 0 }
  );
};

export const isFullScreen = box =>
  getX(box) <= 0 &&
  getY(box) <= 0 &&
  getWidth(box) >= 100 &&
  getHeight(box) >= 100;
