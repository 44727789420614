/* Libraries */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import classnames from "classnames";
/* -Libraries */

/* Actions */
import {
  authWithGoogle,
  requestLoginCode,
  requestLoginLink,
  requestRegistrationCode,
  requestRegistrationLink,
} from "redux/auth/actions";
/* -Actions */

/* Selectors */
import * as anonSelectors from "redux/anonymousData/selectors";
import { authScenarios } from "redux/auth/selectors";
import { googleAuthEnabled } from "redux/enabledFeatures/selectors";
/* -Selectors */

/* Components */
import AuthCodeForm from "forms/AuthCodeForm";
import AuthEmailForm from "forms/AuthEmailForm";
import { BadgeText } from "components/_v2/BadgeText";
import { TextButton } from "components/_v2/Button";
import Collapse from "components/Collapse";
import Icon from "components/Icon";
import LegalContent from "components/LegalContent";
import ModalContentTitle from "components/ModalContentTitle";
import NoOrphan from "components/NoOrphan";
import RegisterNameForm from "forms/RegisterNameForm";
import ResendAuthLink from "components/ResendAuthLink";
import SupportLink from "components/SupportLink";
import TermsAgreement from "components/TermsAgreement";
/* -Components */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import usePostAuthProcess from "hooks/usePostAuthProcess";
/* -Hooks */

import analytics, { EVENTS } from "utils/analyticsUtils";

import styles from "./index.module.scss";

const selector = googleAuthEnabled;

export const EmailContent = props => {
  const {
    autoFocus,
    closeAuthFlow,
    goToLinkSent,
    goToLocked,
    goToName,
    params,
    setRegistration,
    staticEmail,
  } = props;

  const googleAuthEnabled = useSelector(selector);

  const dispatch = useDispatch();

  const onRecognised = useCallback(() => {
    setRegistration(false);
    goToLinkSent();
  }, [goToLinkSent, setRegistration]);
  const onUnrecognised = useCallback(() => {
    setRegistration(true);
    goToName();
  }, [goToName, setRegistration]);

  const { authScenario, unlockDigitalSend } = params || {};

  let title = "Please enter your email to continue.";
  if (params?.contribute) {
    title = "To add your contribution enter your email below.";
  } else {
    switch (authScenario) {
      case authScenarios.CREATOR_INVITE:
        title =
          "To invite friends, please sign in or register using your email.";
        break;
      case authScenarios.ANON_SAVE_EXIT:
        title =
          "Please sign in or register using your email to save this Kindeo.";
        break;
      case authScenarios.CREATOR_SETTINGS:
        title = "Please sign in or register using your email to view settings.";
        break;
      case authScenarios.CREATOR_SEND:
        title =
          "Please sign in or register using your email to send this card.";
        break;
      case authScenarios.CREATOR_PAYMENT:
        title = unlockDigitalSend
          ? "Please enter your email to continue."
          : "Please sign in or register using your email to unlock more messages.";
        break;
      case authScenarios.BUNDLE_PAYMENT:
        title = "Please enter your email to continue to payment.";
        break;

      default:
        break;
    }
  }

  const [googleAuthError, setGoogleAuthError] = useState(false);
  const onGoogleLogin = useCallback(
    authToken => {
      setGoogleAuthError(false);
      dispatch(authWithGoogle(authToken));
    },
    [dispatch]
  );
  const onGoogleLoginError = useCallback(() => {
    setGoogleAuthError(true);
  }, []);

  // Handle Social sign on success
  const isLoggedIn = useAuthStatus();
  const { postAuthComplete, redirectRoute } = usePostAuthProcess(
    isLoggedIn,
    params?.forwardTo
  );

  useEffect(() => {
    if (postAuthComplete && closeAuthFlow) {
      closeAuthFlow();
    }
  }, [closeAuthFlow, postAuthComplete]);

  if (postAuthComplete && redirectRoute) {
    return <Redirect to={redirectRoute} />;
  }

  return (
    <>
      <p className="mb-1_5 has-text-centered">
        <NoOrphan>{title}</NoOrphan>
      </p>

      <AuthEmailForm
        {...{
          autoFocus,
          onLocked: goToLocked,
          onRecognised,
          onUnrecognised,
          staticEmail,
        }}
      />

      {googleAuthEnabled && (
        <div className={styles.googleLogin}>
          <p className="body-small mt-2 mb-1 has-text-neutral7">OR</p>
          <GoogleLogin
            onSuccess={onGoogleLogin}
            onError={onGoogleLoginError}
            type="standard"
            size="large"
            theme="outline"
            shape="pill"
            width="450px"
            ux_mode="popup"
            logo_alignment="center"
          />

          <Collapse show={googleAuthError}>
            <p className="pt-1">
              <BadgeText type="error" text="Your Google login failed" />
            </p>
          </Collapse>
        </div>
      )}

      {props.children}
    </>
  );
};

const anonSelector = anonSelectors.getEmail;

export const NameContent = props => {
  const {
    autoFocus,
    goToLinkSent,
    goToEmail,
    // params
  } = props;

  // Analytics
  useEffect(() => {
    analytics.event(EVENTS.auth.register);
  }, []);

  // let modalTitle;
  // switch (true) {
  //   case !!params.title:
  //     modalTitle = params.title;
  //     break;
  //   case !!params.share:
  //     modalTitle = "Register to share your Kindeo";
  //     break;
  //   case !!params.send:
  //     modalTitle = "To send this Kindeo please Register";
  //     break;
  //   case !!params.settings:
  //     modalTitle = "To manage settings please Register";
  //     break;
  //   case !!params.group:
  //     modalTitle = "To invite contributors please Register";
  //     break;
  //   case !!params.contribute:
  //     modalTitle = "Register to send your contribution";
  //     break;
  //   case !!params.contributions:
  //     modalTitle = "Register to receive contributions";
  //     break;
  //   case !!params.recipientSave:
  //     modalTitle = "Register to save this Kindeo";
  //     break;
  //   case !!params.recipientThanks:
  //     modalTitle = "Register to send your thanks";
  //     break;
  //   default:
  //     modalTitle = "Register to manage your Kindeos";
  // }

  return (
    <>
      <p className="mb-1_5 has-text-centered">
        Looks like you’re new to Kindeo, please{" "}
        <br className="is-hidden-mobile	" /> tell us your name to get started.
      </p>

      <RegisterNameForm
        autoFocus={autoFocus}
        onGoToEmail={goToEmail}
        onLinkSent={goToLinkSent}
      />

      {props.children}
    </>
  );
};

export const AccountLockedContent = () => {
  return (
    <>
      <div className="has-text-centered">
        <Icon
          icon={"icon-privacy-private"}
          className={"has-text-centered"}
          size="38"
        />
      </div>
      <ModalContentTitle>Account locked</ModalContentTitle>

      <div className="has-text-centered">
        Due to suspicious activity, this account has been temporarily locked. If
        this problem persists, please contact <SupportLink />.
      </div>
    </>
  );
};

export const LinkSentContent = props => {
  const { goToVerifyCode, registration } = props;
  const email = useSelector(anonSelector);
  const dispatch = useDispatch();
  const [isSendingCode, setIsSendingCode] = useState(false);

  const onRequestCode = useCallback(async () => {
    const requestCode = registration
      ? requestRegistrationCode
      : requestLoginCode;
    setIsSendingCode(true);
    dispatch(requestCode())
      .then(() => {
        goToVerifyCode();
      })
      .finally(() => {
        setIsSendingCode(false);
      });
  }, [dispatch, goToVerifyCode, registration]);

  const onResendLink = useCallback(async () => {
    const requestLink = registration
      ? requestRegistrationLink
      : requestLoginLink;
    await dispatch(requestLink());
  }, [dispatch, registration]);

  return (
    <>
      <p className="has-text-centered">
        We've sent an email to <b>{email}</b>. Please click on the link in the
        email to confirm it's you.
      </p>
      <ResendAuthLink className="is-full-width mb-1" onResend={onResendLink} />

      <TextButton
        color="teal6"
        className={classnames("is-full-width", styles.textLink, {
          "is-loading": isSendingCode,
        })}
        onClick={onRequestCode}
        underlined={false}
      >
        Get one-time passcode instead
      </TextButton>
    </>
  );
};

export const AuthCodeContent = props => {
  const {
    autoFocus,
    closeAuthFlow,
    goToLocked,
    onSuccess,
    params,
    registration,
  } = props;

  // Handle auth code success
  const isLoggedIn = useAuthStatus();
  const { postAuthComplete, redirectRoute } = usePostAuthProcess(
    isLoggedIn,
    params?.forwardTo
  );

  useEffect(() => {
    if (postAuthComplete && closeAuthFlow) {
      closeAuthFlow();
    }
  }, [closeAuthFlow, postAuthComplete]);

  if (postAuthComplete && redirectRoute) {
    return <Redirect to={redirectRoute} />;
  }

  if (registration) {
    return (
      <AuthCodeForm.Register
        buttonClassName="is-success"
        autoFocus={autoFocus}
        resendClassName={styles.resendAuthCode}
        onSuccess={onSuccess}
        onLocked={goToLocked}
      />
    );
  } else {
    return (
      <AuthCodeForm.Login
        buttonClassName="is-success"
        autoFocus={autoFocus}
        resendClassName={styles.resendAuthCode}
        onSuccess={onSuccess}
        onLocked={goToLocked}
      />
    );
  }
};

export const LegalNoticeContent = React.memo(props => {
  const { linkDelegate } = props;

  return (
    <TermsAgreement className="mv-2 has-text-centered" onClick={linkDelegate} />
  );
});

export const TermsContent = React.memo(props => {
  const { linkDelegate } = props;

  return (
    <LegalContent.Terms
      className="has-background-white"
      linkDelegate={linkDelegate}
    />
  );
});

export const PrivacyContent = React.memo(props => {
  const { linkDelegate } = props;

  return (
    <LegalContent.Privacy
      className="has-background-white"
      linkDelegate={linkDelegate}
    />
  );
});
