/* Libraries */
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Selectors */
/* -Selectors */

/* Components */
import { CoreButton } from "components/_v2/Button";
import { Header } from ".";
import HeaderNavLink from "./HeaderNavLink";
import MobileNavigation from "./MobileNavigation";
/* -Components */

/* Hooks */
import useAuthStatus from "hooks/useAuthStatus";
import useBreakpoint from "hooks/useBreakpoint";
import useModals from "hooks/useModals";
/* -Hooks */

/* Context */
import { ThemeContext } from "context/ThemeContext";
/* -Context */

import { paths } from "routes/PageRoutes";
import { modals } from "routes/ModalRoutes";
import { matchAnyPath } from "utils/url";

import styles from "./index.module.scss";

const HeaderLanding = React.memo(props => {
  const { isMobile } = useBreakpoint();
  const { isWork } = useContext(ThemeContext);

  return (
    <Header
      rightNav={
        <>
          {isMobile ? (
            <>
              {!isWork && (
                <div className="navbar-item mr-05">
                  <ForBusinessButton className="is-small" />
                </div>
              )}
              <MobileNavigation>
                <HeaderLinks {...props} />
              </MobileNavigation>
            </>
          ) : (
            <HeaderLinks {...props} />
          )}
        </>
      }
      {...props}
    />
  );
});

const HeaderLinks = React.memo(props => {
  return (
    <>
      <HeaderTextLinks {...props} />
      <hr className={styles.lpNavDivider} />
      <HeaderIconButtons {...props} />
    </>
  );
});

const HeaderTextLinks = React.memo(props => {
  const { pathname } = useLocation();

  // don't show these additional links on search term landing pages
  const hideSupportingLinks = matchAnyPath(pathname, [
    paths.landingBirthday(),
    // paths.landingGroupCard(),
    // paths.landingGroupEcard(),
    paths.landingLeavingCard(),
    paths.landingPersonalisedCard(),
  ]);

  if (!hideSupportingLinks) {
    return (
      <nav className={styles.lpHeaderLinks}>
        <NavLink
          to={paths.shop()}
          className={active =>
            classnames(styles.lpLink, active && styles.active)
          }
        >
          Shop
        </NavLink>
        <NavLink
          to={paths.landingPricing()}
          className={active =>
            classnames(styles.lpLink, active && styles.active)
          }
        >
          Pricing
        </NavLink>
      </nav>
    );
  }

  return null;
});

const HeaderIconButtons = React.memo(props => {
  const isLoggedIn = useAuthStatus();

  return (
    <>
      {isLoggedIn && (
        <>
          <HeaderNavLink to={paths.home()} icon="card" title="My Cards" />
          <HeaderNavLink to={paths.account()} icon="account" title="Account" />
        </>
      )}
      <NavigationActions showLogin={!isLoggedIn} withSpacing={isLoggedIn} />
    </>
  );
});

const NavigationActions = React.memo(props => {
  const { showLogin, withSpacing } = props;
  const { linkModal } = useModals();
  const { isWork } = useContext(ThemeContext);
  const { isMobile } = useBreakpoint();

  const showBusinessLink = !isWork;

  if (showLogin || showBusinessLink) {
    return (
      <div
        className={classnames(styles.lpNavActions, {
          [styles.lpNavSpacing]: withSpacing,
        })}
      >
        {showLogin && (
          <CoreButton
            to={linkModal(modals.auth())}
            design={isWork ? "primary-work" : "secondary"}
          >
            Sign in
          </CoreButton>
        )}
        {showBusinessLink && !isMobile && <ForBusinessButton />}
      </div>
    );
  }

  return null;
});

const ForBusinessButton = ({ className }) => {
  return (
    <CoreButton
      className={className}
      to={paths.landingBusiness()}
      design="primary-work"
    >
      For business
    </CoreButton>
  );
};

export { HeaderLanding };
