/* Libraries */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
import { BadgeText } from "components/_v2/BadgeText";
import { InverseButton } from "components/_v2/Button";
/* -Components */

/* Hooks */
/* -Hooks */

import styles from "./index.module.scss";

const StaleSlideHandler = props => {
  const { contentModeration, disabled, doClose, reloadData, slideDeleted } =
    props;

  const [isReloading, isReloadingSet] = useState(false);
  const startLoading = useCallback(() => {
    isReloadingSet(true);
    reloadData();
  }, [reloadData]);

  if (disabled) {
    return null;
  }

  // if this slide has been deleted by someone else,
  // don't reload the data - it's no longer there
  if (slideDeleted) {
    return (
      <>
        <BadgeText
          icon="warning"
          color="black"
          size="medium"
          className={styles.staleWarning}
          text={<>This slide has just been deleted and cannot be edited.</>}
          alignLeft
        />
        <InverseButton className={styles.actionBtn} onClick={doClose} filled>
          Close
        </InverseButton>
      </>
    );
  }

  // if content moderation is on and the data is stale
  // this slide data will now have moved to contributions and out of slides
  // don't reload the data - it's no longer there
  if (contentModeration) {
    return (
      <>
        <BadgeText
          icon="warning"
          color="black"
          size="medium"
          className={styles.staleWarning}
          text={
            <>
              There is a newer version of this slide, please review it in your
              contributions section.
            </>
          }
          alignLeft
        />
        <InverseButton className={styles.actionBtn} onClick={doClose} filled>
          Close
        </InverseButton>
      </>
    );
  }

  return (
    <>
      <BadgeText
        icon="warning"
        color="black"
        size="medium"
        className={styles.staleWarning}
        text={
          <>
            There is a newer version of this slide, please reload the slide
            before making any changes.
          </>
        }
        alignLeft
      ></BadgeText>
      <InverseButton
        className={styles.actionBtn}
        onClick={startLoading}
        loading={isReloading}
        filled
      >
        Reload Slide
      </InverseButton>
    </>
  );
};

StaleSlideHandler.propTypes = {
  contentModeration: PropTypes.bool,
  disabled: PropTypes.bool,
  doClose: PropTypes.func,
  reloadData: PropTypes.func,
};

export default React.memo(StaleSlideHandler);
