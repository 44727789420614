import { parse, stringify } from "qs";

export const parseQueryString = queryString =>
  parse(queryString, { ignoreQueryPrefix: true });

export const stringifyQueryString = params =>
  stringify(params, { addQueryPrefix: true });

export const getQueryParams = queryString => parseQueryString(queryString);

export const getQueryParam = (queryString, key) =>
  getQueryParams(queryString)[key];

export const setQueryParams = (queryString, params) =>
  stringifyQueryString({ ...parseQueryString(queryString), ...params });
