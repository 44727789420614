/* Libraries */
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
/* -Libraries */

/* Actions */
import { haveLatestEditorData } from "redux/editor/actions";
/* -Actions */

import * as apiUtils from "utils/apiUtils";

const useHasLatestEditorData = slideId => {
  const dispatch = useDispatch();

  const [hasLatestData, hasLatestDataSet] = useState(true);
  const [slideDeleted, slideDeletedSet] = useState(false);

  // if this is an existing contribution check it's the latest data
  // if new return that we have latest data
  const checkLatestData = useCallback(() => {
    if (slideId) {
      return dispatch(haveLatestEditorData(slideId))
        .then(result => {
          hasLatestDataSet(result);
          return result;
        })
        .catch(e => {
          const status = apiUtils.getErrorStatus(e);
          slideDeletedSet(status === 404);
          hasLatestDataSet(false);
          return false;
        });
    }

    return Promise.resolve(true);
  }, [slideId, dispatch]);

  useEffect(() => {
    checkLatestData();

    const interval = setInterval(checkLatestData, 5000);

    return () => clearInterval(interval);
  }, [checkLatestData]);

  return { checkLatestData, hasLatestData, slideDeleted };
};

export default useHasLatestEditorData;
