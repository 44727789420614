import { useCallback, useState } from "react";

/*
  Refs don't always get set properly when the parent renders conditionally
  https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node

  ```
  const [ref, setRef] = useConditionalRef(null);
  
  <div ref={setRefEditButton} />
  ```
*/

export default function useConditionalRef(defaultValue) {
  const [ref, _setRef] = useState();

  const setRef = useCallback(e => {
    _setRef(e);
  }, []);

  return [ref, setRef];
}
