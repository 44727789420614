/* Libraries */
import { useCallback, useEffect, useRef, useState } from "react";
/* -Libraries */

const useIncrement = initial => {
  // the latest actual reported progress
  const [reportedProgress, setReportedProgress] = useState(initial || 0);
  // the display-friendly version of progress
  const [displayProgress, setDisplayProgress] = useState(initial || 0);
  // a store for the incrementing counter
  const [calcProgress, setCalcProgress] = useState(initial || 0);
  const incrementTimeout = useRef();

  const doIncrement = useCallback(() => {
    // reset in case this is triggered by timeout and change to reportedProgress
    clearTimeout(incrementTimeout);

    // go no further than 100
    if (calcProgress >= 100) return;

    // adjust the rate down if we're approaching the end
    const progressStep = calcProgress < 66 ? 0.3 : 0.1;

    // if reportedProgress is higher than calculated, use the higher figure
    const newProgress = Math.max(calcProgress, reportedProgress) + progressStep;
    setCalcProgress(newProgress);

    // calculate a display-friendly version of progress
    const displayProgress = Math.round(
      (Math.atan(newProgress / 100) / (Math.PI / 4)) * 100
    );
    setDisplayProgress(displayProgress);
  }, [calcProgress, reportedProgress]);

  // when calcProgress is updated, schedule the next increment
  useEffect(() => {
    if (calcProgress) {
      incrementTimeout.current = window.setTimeout(doIncrement, 200);
    }
  }, [calcProgress, doIncrement]);

  // clean up
  useEffect(() => {
    return () => {
      window.clearTimeout(incrementTimeout.current);
    };
  }, []);

  return {
    doIncrement,
    progress: displayProgress,
    setProgress: setReportedProgress
  };
};

export default useIncrement;
