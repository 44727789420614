export const AUTH_HEADER = "Authorization";

const getErrorResponse = error => {
  return error ? error.response : undefined;
};

export const getErrorData = error => {
  const response = getErrorResponse(error);
  return response ? response.data : undefined;
};

const getErrorMeta = error => {
  const data = getErrorData(error);
  return data && data.meta ? data.meta : data;
};

export const getErrorMessage = error => {
  if (error.message) {
    return error.message;
  }

  const meta = getErrorMeta(error);
  const errors = meta.errors || [meta.error];
  const messages = errors ? errors.map(error => error.message) : [];
  return messages.join("\n");
};

export const getErrorStatus = error => {
  const response = getErrorResponse(error);
  return response ? response.status : undefined;
};

export const convertShallowObjectToFormData = object => {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
};

export const errorRequiresReauth = error => {
  return getErrorStatus(error) === 419;
};

export const errorRequiresBackoff = error => {
  return getErrorStatus(error) === 429;
};

export const errorHasNoCode = error => {
  return getErrorStatus(error) === undefined;
};

export const errorHasAuthHeaders = error => {
  const errorHasAuthHeaders = !!(
    error.config &&
    error.config.headers &&
    error.config.headers[AUTH_HEADER]
  );
  return errorHasAuthHeaders;
};
