import classnames from "classnames";
import React from "react";
import styles from "./index.module.scss";

export const InlineButton = React.memo(
  ({
    children,
    disabled,
    secondary,
    className,
    loading,
    onClick,
    text,
    ...restProps
  }) => {
    return (
      <button
        type="button"
        {...restProps}
        disabled={disabled}
        className={classnames(
          `button is-small`,
          styles.button,
          className,
          !secondary ? "is-green4" : "is-teal4",
          {
            "is-loading": loading,
            [styles.loading]: loading,
          }
        )}
        onClick={onClick}
      >
        {text}
        {children}
      </button>
    );
  }
);
