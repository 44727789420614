/* Libraries */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
/* -Selectors */

/* Components */
import NavBarButton from "components/NavBarButton";
/* -Components */

/* Hooks */
import useTriggerOnChange from "hooks/useTriggerOnChange";
/* -Hooks */

import styles from "./index.module.scss";
import Collapse from "components/Collapse";

const MobileNavigation = props => {
  const { children } = props;

  const [menuOpen, menuOpenSet] = useState(false);
  const toggleMenu = useCallback(() => {
    menuOpenSet(current => !current);
  }, []);
  const closeMenu = useCallback(() => {
    menuOpenSet(false);
  }, []);

  const { pathname } = useLocation();

  const pathnameChanged = useTriggerOnChange(pathname);
  useEffect(() => {
    if (pathnameChanged) {
      closeMenu();
    }
  }, [closeMenu, pathnameChanged]);

  return (
    <>
      <NavBarButton
        className="mb-0 mr-1"
        onClick={toggleMenu}
        icon="menu"
        iconSize="24"
      />
      <div
        className={classnames(styles.dropdown, { [styles.visible]: menuOpen })}
      >
        <Collapse show={menuOpen}>
          <div className={styles.dropdownWrapper}>{children}</div>
        </Collapse>
      </div>
    </>
  );
};

MobileNavigation.propTypes = {};

export default React.memo(MobileNavigation);
