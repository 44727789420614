/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import * as authSelectors from "redux/auth/selectors";
import { authTokenTypes, verifyTypes } from "redux/auth/selectors";
import { getPayments, paymentsPerPage } from "./selectors";
/* -Selectors */

/* Actions */
import { requestVerification } from "redux/auth/actions";
import { setPrompts } from "redux/prompts/actions";
/* -Actions */

import { parseOrFallback } from "utils/numberUtils";
// import analytics from "utils/analytics";

const ACCOUNT_ENDPOINT = "account";
const ACCOUNT_ENDPOINT_SETTINGS = "account/settings";

export const actions = {
  profileFetchSuccess: createAction("PROFILE_FETCH_SUCCESS"),
  profileUpdateSuccess: createAction("PROFILE_UPDATE_SUCCESS"),
  paymentsFetchSuccess: createAction("PAYMENTS_FETCH_SUCCESS"),
  paymentsFetchPageSuccess: createAction("PAYMENTS_FETCHPAGE_SUCCESS"),
  paymentDetailFetchSuccess: createAction("PAYMENT_DETAIL_FETCH_SUCCESS"),
};

export const fetchProfile = () => (dispatch, getState) => {
  // default to the current username if one isn't supplied
  return axios.get(ACCOUNT_ENDPOINT).then(response => {
    dispatch(actions.profileFetchSuccess(response.data.account));
    dispatch(setPrompts(response.data.account.tooltips || []));

    return response.data.account;
  });
};

export const fetchPrompts = () => (dispatch, getState) => {
  return axios.get("/account/tooltips").then(response => {
    return response.data?.tooltips;
  });
};

export const updatePrompts = ids => (dispatch, getState) => {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }
  if (ids.length > 0) {
    return axios.put("/account/tooltips", { complete: ids }).then(response => {
      dispatch(setPrompts(response.data.tooltips || []));
    });
  }

  return Promise.resolve();
};

export const updateProfile = payload => (dispatch, getState) => {
  const refreshToken = authSelectors.getAuthRefreshToken(getState());
  return axios
    .put(ACCOUNT_ENDPOINT, {
      [authTokenTypes.refreshToken]: refreshToken,
      ...payload,
    })
    .then(response => {
      const { account, auth } = response.data;
      // analytics.trackEvent("Updated Profile");
      dispatch(actions.profileUpdateSuccess(auth));
      dispatch(actions.profileFetchSuccess(account));
    });
};

export const setHideMyEmail = hideMyEmail => dispatch => {
  return axios.put(ACCOUNT_ENDPOINT_SETTINGS, {
    hide_my_email: hideMyEmail,
  });
};

// registration with auth code for email verification
export const requestUpdateProfileCode = payload => (dispatch, getState) => {
  const { email, name } = payload;

  return dispatch(
    requestVerification({
      email,
      name,
      use_code: true,
      verify_type: verifyTypes.EMAILCHANGE,
    })
  );
};

export const updateProfileWithCode = payload => (dispatch, getState) => {
  const { code, email, firstName, lastName } = payload;

  return dispatch(
    updateProfile({
      verify_code: code,
      email,
      first_name: firstName,
      last_name: lastName,
    })
  );
};

// previous payments
export const fetchPayments = offset => (dispatch, getState) => {
  const offsetParam = offset ? `&offset=${parseOrFallback(offset, 0)}` : "";
  return axios
    .get(`/payments?limit=${paymentsPerPage}${offsetParam}`)
    .then(response => {
      const { payments } = response.data;
      if (!offset) {
        dispatch(actions.paymentsFetchSuccess(payments));
      } else {
        dispatch(actions.paymentsFetchPageSuccess(payments));
      }

      return payments;
    });
};
export const fetchPagedPayments = offset => (dispatch, getState) => {
  const paymentCount = getPayments(getState()).length;
  return dispatch(fetchPayments(paymentCount));
};

export const fetchPaymentDetails = id => dispatch => {
  return axios.get(`/payments/${id}`).then(response => {
    const { payment } = response.data;
    dispatch(actions.paymentDetailFetchSuccess(payment));
    return payment;
  });
};

// Request resend of receipt email
export const resendReceipt = paymentId => () => {
  return axios.put(`/payments/receipt/${paymentId}`).then(response => {
    return response.data;
  });
};
