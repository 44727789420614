/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Selectors */
import {
  getContributions,
  getContributionById,
  getLastContributionUpdate,
} from "./selectors";
import { getProfile, isLoaded } from "redux/account/selectors";
import * as storySelectors from "redux/story/selectors";
import { storyTokenTypes } from "redux/story/selectors";
import * as slideSelectors from "redux/slide/selectors";
import * as updateStatusSelectors from "redux/updateStatus/selectors";
/* -Selectors */

/* Actions */
import * as storyActions from "redux/story/actions";
/* -Actions */

import analytics, { EVENTS } from "utils/analyticsUtils";
import { getErrorMessage } from "utils/apiUtils";
import { noop } from "utils/clientUtils";
import { waitFrame } from "kindeo-webgl/utils";

export const actions = {
  contributionsSeenSuccess: createAction("CONTRIBUTIONS_SEEN_SUCCESS"),
  contributionsFetchSuccess: createAction("CONTRIBUTIONS_FETCH_SUCCESS"),
  createThumbnailSuccess: createAction("CONTRIBUTIONS_THUMBNAIL_SUCCESS"),
  acceptContribution: createAction("CONTRIBUTIONS_ACCEPTED"),
  clearContributionThumbnail: createAction("CONTRIBUTON_THUMBNAIL_CLEAR"),
  updateContribution: createAction("CONTRIBUTION_UPDATE"),
};

export const fetchContributions = storyId => (dispatch, getState) => {
  const currentState = getState();
  const isAuthenticated = isLoaded(getProfile(currentState));

  if (isAuthenticated) {
    return axios.get(`/story/${storyId}/contributions`).then(response => {
      dispatch(actions.contributionsFetchSuccess(response.data));
      return response.data.contributions;
    });
  }

  return getContributions(currentState);
};

export const fetchMyStoryContributions = () => (dispatch, getState) => {
  const currentState = getState();
  const isAuthenticated = isLoaded(getProfile(currentState));
  const storyId = storySelectors.getId(storySelectors.getStory(currentState));

  if (isAuthenticated && storyId) {
    return axios.get(`/slides?story_id=${storyId}`).then(response => {
      dispatch(
        actions.contributionsFetchSuccess({
          contributions: response.data.slides,
        })
      );
      return response.data.slides;
    });
  }

  return Promise.resolve([]);
};

export const deleteMyStoryContribution = slideId => (dispatch, getState) => {
  const deletedSlide = getContributionById(
    getContributions(getState()),
    slideId
  );

  return axios.delete(`/slide/${slideId}`).then(() => {
    analytics.event("Contribution Deleted", {
      type: slideSelectors.getType(deletedSlide),
    });
  });
};

export const updateContributors =
  ({ storyId, contributions }) =>
  () => {
    return axios.post(`/story/${storyId}/share`, {
      contributions,
    });
  };

export const createContributionThumbnail = payload => (dispatch, getState) => {
  /**
   * about the waitFrame:
   * it's a last resort solution (in a rush, as often), to fix the problem of slide's thumbnails that would appear with a black background.
   * This black background issue started to happen from that commit https://bitbucket.org/kindeo/kindeo-web-v4/commits/03c54146ec6007825793f3ebcac46729c234e372.
   * Somehow, it messed up the lifecycle events of the Slideshow (pixi.js), and the resizing functions:
   * Instead of closing the current slide slideshow (-> resize(1, 1)) and then starts the thumbnail slideshow (resize(600, 800)), it's doing the inverse (calls the resize(1, 1) during the thumbnail creation). (in the same frame, surely)
   * The waitFrame ensure that the slideshow is properly closed before the thumb generation by waiting 2 frames.
   */
  return waitFrame(2).then(() => {
    return dispatch(storyActions.createThumbnail(payload)).then(slide => {
      dispatch(actions.createThumbnailSuccess(slide));
      return slide;
    });
  });
};

// call storyActions.createMissingThumbnails
// but with callback to update contributions list
export const createMissingContributionsThumbnails =
  payload => (dispatch, getState) => {
    if (!payload?.length) {
      payload = getContributions(getState());
    }

    return dispatch(
      storyActions.createMissingThumbnails(
        payload,
        actions.createThumbnailSuccess
      )
    );
  };

export const markContributionAsSeen = payload => dispatch => {
  const { slide_id } = payload;
  return axios
    .put(`/slide/${slide_id}/seen`)
    .then(() => {
      dispatch(actions.contributionsSeenSuccess({ contribution_id: slide_id }));
    })
    .catch(noop);
};

// check if local contribution listing data is current or out of date
const haveLatestData = () => (dispatch, getState) => {
  const currentState = getState();
  const lastLocalUpdate = getLastContributionUpdate(currentState);
  const lastRemoteUpdate =
    updateStatusSelectors.getLastContributionUpdate(currentState);

  if (lastRemoteUpdate && lastLocalUpdate) {
    return (
      new Date(lastLocalUpdate).getTime() ===
      new Date(lastRemoteUpdate).getTime()
    );
  } else {
    return false;
  }
};

// ensure local contribution data is current or get new data
export const ensureLatestContributions = id => (dispatch, getState) => {
  if (!dispatch(haveLatestData())) {
    const story = storySelectors.getStory(getState());
    // default to the current story ID
    id = id || storySelectors.getId(story);
    return dispatch(fetchContributions(id));
  }
};

//Remove a contribution by ID
export const removeById = slideId => (dispatch, getState) => {
  const currentState = getState();
  const deletedSlide = getContributionById(
    getContributions(currentState),
    slideId
  );
  const story = storySelectors.getStory(currentState);

  return axios.delete(`slide/${slideId}`).then(() => {
    analytics.event("Contributed Slide Deleted", {
      type: slideSelectors.getType(deletedSlide),
    });

    return dispatch(fetchContributions(storySelectors.getId(story))).catch(
      noop
    );
  });
};

export const acceptContribution = slideId => dispatch => {
  return axios
    .put(`/slide/${slideId}/approve`)
    .then(() => {
      dispatch(actions.acceptContribution(slideId));
    })
    .catch(noop);
};

export const acceptAllContributions = storyId => dispatch => {
  return axios.put(`/story/${storyId}/slides/approve_all`).then(() => {
    dispatch(afterAcceptContributions(storyId));
  });
};

export const afterAcceptContributions = storyId => dispatch => {
  dispatch(fetchContributions(storyId));
  return dispatch(storyActions.fetchStory(storyId)).then(() => {
    return dispatch(storyActions.createMissingThumbnails());
  });
};

export const rejectContribution = slideId => dispatch => {
  return axios
    .put(`/slide/${slideId}/reject`)
    .then(() => {})
    .catch(noop);
};

// Add a new slide to be a pending contribution
export const addPendingContribution = payload => dispatch => {
  const { groupToken, ...slideData } = payload;

  return axios
    .post(
      `/contribution?${storyTokenTypes.groupToken}=${groupToken}`,
      slideData
    )
    .then(response => {
      return response.data.contribution_id;
    });
};

// Complete a pending contribution
export const completeContribution = payload => dispatch => {
  const { contributionId, groupToken } = payload;

  return axios
    .put(`/contribution/${contributionId}/send`)
    .then(response => {
      const receivedContribution = {
        ...response.data.contribution,
      };
      dispatch(createContributionThumbnail(receivedContribution));

      analytics.event("Slide Created", {
        type: slideSelectors.getType(receivedContribution),
        variant: slideSelectors.getVariant(receivedContribution),
        media_count: slideSelectors.getMedia(receivedContribution)?.length,
        contribution: true,
      });

      // Refresh contribution count
      if (groupToken) {
        dispatch(storyActions.fetchGroupStoryContent(groupToken));
      }

      return receivedContribution;
    })
    .catch(e => {
      analytics.error(EVENTS.errors.addContribution, {
        message: getErrorMessage(e),
      });
      return Promise.reject(e);
    });
};

export const addContribution = payload => dispatch => {
  const { groupToken, ...slideData } = payload;

  return dispatch(addPendingContribution({ groupToken, ...slideData })).then(
    contributionId => {
      return dispatch(completeContribution({ contributionId, groupToken }));
    }
  );
};

// recreate a contributions thumbnail
export const recreateContributionThumbnail =
  contribution => (dispatch, getState) => {
    // clear the current thumbnail to start the loader
    dispatch(
      actions.clearContributionThumbnail(slideSelectors.getId(contribution))
    );
    // get the new thumbnail
    return dispatch(createContributionThumbnail(contribution));
  };

export const updateContributionData = payload => dispatch => {
  return dispatch(actions.updateContribution(payload));
};
