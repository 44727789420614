/* Libraries */
import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
/* -Actions */

/* Selectors */
import * as selectors from "./selectors";
import { noop } from "utils/clientUtils";
/* -Selectors */

export const actions = {
  addressesFetched: createAction("ADDRESSES_FETCH_SUCCESS"),
  addressEdited: createAction("ADDRESSES_EDIT_SUCCESS"),
  addressAdded: createAction("ADDRESSES_ADD_SUCCESS"),
  addressRemoved: createAction("ADDRESSES_REMOVE_SUCCESS"),
};

export const fetchAddresses = () => dispatch => {
  return axios
    .get(`/addresses/saved`)
    .then(response => {
      const { addresses } = response.data;

      dispatch(actions.addressesFetched(addresses));
      return addresses;
    })
    .catch(noop);
};

export const fetchAddressDetails = addressId => (dispatch, getState) => {
  const state = getState();
  return axios.get(`/addresses/${addressId}`).then(response => {
    const { address } = response.data;
    const currentAddresses = selectors.getAddresses(state);
    if (selectors.getAddressById(currentAddresses, selectors.getId(address))) {
      dispatch(actions.addressEdited(address));
    } else {
      dispatch(actions.addressAdded(address));
    }

    return address;
  });
};

export const updateAddress = (id, address) => dispatch => {
  return axios.put(`/addresses/${id}`, { ...address }).then(response => {
    const { address: updatedAddress } = response.data;
    dispatch(actions.addressEdited(updatedAddress));

    return updatedAddress;
  });
};

export const addAddress = address => (dispatch, getState) => {
  return axios.post(`/addresses`, { ...address }).then(response => {
    const { address: addedAddress } = response.data;
    dispatch(actions.addressAdded(addedAddress));
    return addedAddress;
  });
};

export const removeAddress = addressId => dispatch => {
  return axios.delete(`/addresses/${addressId}`).then(response => {
    return dispatch(actions.addressRemoved(addressId));
  });
};

export const addressAutocomplete = query => dispatch => {
  return axios
    .get(`/addresses/suggest/${encodeURIComponent(query)}`)
    .then(response => {
      const { suggestions } = response.data;
      return suggestions;
    });
};

export const addressGetDetails = resultId => dispatch => {
  return axios
    .get(`/addresses/retrieve/${encodeURIComponent(resultId)}`)
    .then(response => {
      const { address } = response.data;
      return address;
    });
};
