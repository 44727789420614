/* Libraries */
import { createReducer } from "@reduxjs/toolkit";
/* -Libraries */

/* Actions */
import { actions } from "./actions";
import { actions as authActions } from "redux/auth/actions";
/* -Actions */

const INITIAL_STATE = {
  boxes: [],
};

const setBoundingBoxes = (state, { payload }) => {
  return {
    boxes: payload,
  };
};

const reset = state => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [actions.setBoundingBoxes]: setBoundingBoxes,
  [actions.resetBoundingBoxes]: reset,
  [authActions.logoutInit]: reset,
});
