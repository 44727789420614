/* Libraries */
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
/* -Libraries */

import {
  getQueryParam,
  parseQueryString,
  setQueryParams,
  stringifyQueryString,
} from "utils/queryParamUtils";

const MODAL_KEY = "modal";
const MODAL_PARAMS_KEY = "modal-params";

const doCloseModal = (modalName, state, history, location) => {
  // remove name of modal to close from state
  const currentModalName = getModalName(location.search);
  // if named modal is open, remove it from search params
  if (modalName === currentModalName || !modalName) {
    history.replace({
      ...location,
      search: setModalParams(
        removeModalParams(location.search),
        undefined,
        undefined
      ),
      state,
    });
  }
};

const doOpenModal = (
  modal,
  params,
  navigate,
  history,
  location,
  search,
  hash
) => {
  const modalLink = getModalLink(modal, params, location, search, hash);
  // normal navigate when URL changes
  if (navigate) {
    // the hash sometimes end up in the location.pathname instead of the hash...
    // make sure it gets trimmed out of the pathname, and added as a hash if needed
    // -> avoids a Route update
    const { pathname: path, ...restProps } = modalLink;
    const pathname = path.replace(hash, "");

    history.push({
      ...restProps,
      pathname,
      hash,
    });
  } else {
    const { pathname, search } = modalLink;
    window.history.pushState(params, "", pathname + search);
  }
};

const getModalLink = (modal, params, location, search, hash) => {
  let linkProps =
    typeof location === "object"
      ? location
      : { pathname: location, search, hash };

  return {
    ...linkProps,
    search: setModalParams(linkProps.search, modal, params),
  };
};

const removeModalParams = queryString => {
  if (queryString) {
    let currentParams = parseQueryString(queryString);
    delete currentParams[MODAL_PARAMS_KEY];
    return stringifyQueryString(currentParams);
  }
  return "";
};

const setModalParams = (queryString, value, params) => {
  let modalQueryParams = {
    [MODAL_KEY]: value,
  };
  if (params) {
    modalQueryParams[MODAL_PARAMS_KEY] = params;
  }
  return setQueryParams(queryString, modalQueryParams);
};

const getModalName = queryString => getQueryParam(queryString, MODAL_KEY);

const getModalParams = queryString => {
  return getQueryParam(queryString, MODAL_PARAMS_KEY) || {};
};

const getModalDetails = queryString => {
  return {
    modal: getModalName(queryString),
    params: getModalParams(queryString),
  };
};

const useModals = () => {
  const history = useHistory();
  const location = useLocation();

  const openModal = useCallback(
    (modal, params, route, navigate = true) => {
      params = params || {};
      doOpenModal(
        modal,
        params,
        navigate,
        history,
        route || location.pathname,
        location.search,
        location.hash
      );
    },
    [history, location.hash, location.pathname, location.search]
  );

  const linkModal = useCallback(
    (modal, params, route) => {
      const {
        hash = location.hash,
        search = location.search,
        ...otherParams
      } = params || {};
      return getModalLink(
        modal,
        otherParams,
        route || location.pathname,
        search,
        hash
      );
    },
    [location.hash, location.pathname, location.search]
  );

  const closeModal = useCallback(
    (modalName, state) => {
      doCloseModal(modalName, state, history, location);
    },
    [history, location]
  );

  const getModal = useCallback(
    search => {
      return getModalDetails(search || location.search);
    },
    [location.search]
  );

  const getParams = useCallback(
    search => {
      return getModalParams(search || location.search);
    },
    [location.search]
  );

  return {
    openModal,
    linkModal,
    closeModal,
    getModal,
    getParams,
    removeParams: removeModalParams,
    setParams: setModalParams,
  };
};

export default useModals;
