/* Libraries */
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useForm, FormProvider } from "react-hook-form";
import PropTypes from "prop-types";
/* -Libraries */

/* Actions */
import {
  setAnonymousFirstName,
  setAnonymousLastName,
} from "redux/anonymousData/actions";
import { register, requestRegistrationLink } from "redux/auth/actions";
/* -Actions */

/* Components */
import { InverseButton, TextButton } from "components/_v2/Button";
import { Text } from "components/FormField";
import FieldError from "components/FormFieldError";
import FormSubmissionError from "components/FormSubmissionError";
/* -Components */

/* Selectors */
import {
  getEmail,
  getFirstName,
  getLastName,
} from "redux/anonymousData/selectors";
/* -Selectors */

/* Hooks */
import useIsMounted from "hooks/useIsMounted";
/* -Hooks */

import { noop } from "utils/clientUtils";
import { EVENTS } from "utils/analyticsUtils";

import styles from "./index.module.scss";

const selector = createSelector(
  [getEmail, getFirstName, getLastName],
  (email, firstName, lastName) => {
    return {
      anonymousEmail: email || "",
      anonymousFirstName: firstName || "",
      anonymousLastName: lastName || "",
    };
  }
);

const RegisterNameForm = props => {
  const { autoFocus, inputClassName, onGoToEmail, joinToken } = props;

  const { anonymousEmail, anonymousFirstName, anonymousLastName } =
    useSelector(selector);

  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const formTools = useForm({
    defaultValues: {
      firstName: anonymousFirstName,
      lastName: anonymousLastName,
    },
  });
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = formTools;
  const [formError, formErrorSetter] = useState(false);

  const [firstNameValue, lastNameValue] = watch(["firstName", "lastName"]);
  useEffect(() => {
    dispatch(setAnonymousFirstName(firstNameValue));
  }, [dispatch, firstNameValue]);

  useEffect(() => {
    dispatch(setAnonymousLastName(lastNameValue));
  }, [dispatch, lastNameValue]);

  // reset generic form error message if there is a specific field error
  useEffect(() => {
    if (!isValid) {
      formErrorSetter(false);
    }
  }, [isValid]);

  const inputRef = useRef();
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const onSubmit = async formData => {
    formErrorSetter(false);
    const {
      onLinkSent,
      onError = noop,
      onRegistered,
      onSubmit = noop,
      onSuccess = noop,
    } = props;
    const { firstName, lastName } = formData;

    if (anonymousEmail && firstName && lastName) {
      try {
        onSubmit(formData);
        if (isMounted()) {
          let registrationResult;
          // if registering with a join token we shortcut past auth link/code
          if (joinToken) {
            dispatch(register({ joinToken })).then(result => {
              onRegistered(result);
            });
          } else {
            await dispatch(requestRegistrationLink());
            onLinkSent();
          }
          if (isMounted()) {
            onSuccess(registrationResult);
          }
        }
      } catch (error) {
        onError(error);

        formErrorSetter(true);
      }
    }
  };

  return (
    <FormProvider {...formTools}>
      <form
        className={props.className}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="field mb-1">
          <div className="control">
            <Text
              className={classnames(
                inputClassName,
                "mb-05",
                styles.emailDisplay
              )}
              value={anonymousEmail}
              name="email"
              id="register-email"
              title={anonymousEmail}
              disabled
            />
            <TextButton
              onClick={onGoToEmail}
              color="teal6"
              className={styles.changeEmail}
            >
              Not you?
            </TextButton>
            <FieldError errors={errors} fieldName="name" />
          </div>
        </div>
        <div className="field mb-2">
          <div className="control">
            <label htmlFor="register-name">Enter your name</label>

            <div className="columns is-mobile">
              <div className="column">
                <Text
                  className={classnames(inputClassName, "mb-1")}
                  placeholder="First name"
                  name="firstName"
                  id="register-first-name"
                  validation={{
                    required: "Please type in your first name",
                  }}
                  maxLength={50}
                  inputRef={inputRef}
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
              <div className="column">
                <Text
                  className={classnames(inputClassName, "mb-1")}
                  placeholder="Last name"
                  name="lastName"
                  id="register-last-name"
                  validation={{
                    required: "Please type in your last name",
                  }}
                  maxLength={50}
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>

            <FieldError errors={errors} fieldName="name" />
          </div>
        </div>

        <FormSubmissionError
          show={!!formError}
          action={EVENTS.errors.authName}
        />

        {props.children}

        <div className="buttons is-centered mt-1_5">
          <InverseButton
            className="is-full-width"
            type="submit"
            colour="teal4"
            loading={isSubmitting}
            disabled={!firstNameValue || !lastNameValue || isSubmitting}
            filled
          >
            Continue
          </InverseButton>
        </div>
      </form>
    </FormProvider>
  );
};

RegisterNameForm.propTypes = {
  autoFocus: PropTypes.bool,
  smartLabel: PropTypes.bool,
  labelClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onGoToEmail: PropTypes.func,
  onSuccess: PropTypes.func,
  onLoginFallback: PropTypes.func,
};

export default React.memo(RegisterNameForm);
