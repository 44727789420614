/* Libraries */
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
/* -Libraries */

/* Components */
import Roundel from "components/Roundel";
/* -Components */

import styles from "./index.module.scss";

const IconButtonCircle = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;

  return (
    <Roundel.Icon
      elementRef={ref}
      className={classnames(styles.buttonContainer, className)}
      {...restProps}
    />
  );
});

IconButtonCircle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(IconButtonCircle);
