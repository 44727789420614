/* Libraries */
import { useCallback, useState } from "react";
/* -Libraries */

function useModalDisplay(initialOpen = true) {
  const duration = 350;

  const [isOpen, setIsOpen] = useState(initialOpen);

  const doOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const doClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { duration, isOpen, doClose, doOpen };
}

export default useModalDisplay;
