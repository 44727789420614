import * as accountSelectors from "../account/selectors";
import analytics from "../../utils/analyticsUtils";

export const trackProfile =
  (props = {}) =>
  (dispatch, getState) => {
    const profile = accountSelectors.getProfile(getState());
    const username = accountSelectors.getUsername(profile);

    analytics.identify(username, {
      email: accountSelectors.getEmail(profile),
      name: accountSelectors.getName(profile),
      test_user: process.env.REACT_APP_STAGE !== "prod",
      ...props,
    });
  };

// Utility function to track an event with optional user props
export const trackEvent =
  (event, identifyProps = {}, eventProps = {}) =>
  dispatch => {
    analytics.event(event, eventProps);

    // If we have identify props then add them to the user
    if (Object.keys(identifyProps).length > 0) {
      dispatch(trackProfile(identifyProps));
    }
  };
